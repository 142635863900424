import React, { useState } from "react";
import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ClickableLineText,
  CollapsableTableItem,
  LargeLineText,
  LineText,
  LineTextEmphasized,
} from "./reports_styles";
import { primary_dark_color_lighter } from "../../constants/color_constants";

const GroupItem = styled("td")`
  cursor: pointer;
  display: flex; /* This sets the content to be in a row */
  justify-content: space-between;
  align-items: flex-start; /* Adjust as needed to vertically center the content */

  background-color: #f0f0f0;
  border-radius: 10px 0 0 10px;
  height: 100%;
`;

const ParentAccountItem = styled("td")`
  cursor: pointer;
  display: flex; /* This sets the content to be in a row */
  justify-content: space-between;
  border-radius: 5px;
`;

const FundsTotalItem = styled("td")`
  cursor: pointer;
  border-radius: 0 10px 10px 0;
  background-color: ${(props) => (props.shade ? "#f0f0f0" : "white")};
`;

const Row = styled("tr")`
  height: auto; /* Ensures the row height adjusts properly */
`;

const formatCurrencyUSD = (num) => {
  const float = parseFloat(num / 100);
  const formatted = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(float);
  return formatted;
};

const drillDown = ({ relevantLines, filterTransactionsToInspect }) => {
  filterTransactionsToInspect({ relevantLines });
};

export const EquityColumns = ({
  balanceSheetObject,
  fundsDetailsToDisplay,
  showFunds,
}) => {
  const total = true;
  const columns = balanceSheetObject.find(
    (type) => type.accountTypeName === "Equity",
  ).columns;

  console.log(balanceSheetObject);
  return (
    <React.Fragment>
      {!showFunds && (
        <Row>
          <LargeLineText>Equity Accounts</LargeLineText>
        </Row>
      )}
      {!showFunds &&
        columns
          .filter((_col, index) => index !== columns.length - 1)
          .map((group, groupIndex) => {
            return group.funds.map((fund, fundIndex) => {
              return (
                <React.Fragment>
                  <Row
                    key={`${fund.fundNumber}-equity-${groupIndex}-${fundIndex}`}>
                    <td>
                      <LineText>{fund.fundNumber}</LineText>
                    </td>
                    <td>
                      <LineText style={{ textAlign: "right" }}>
                        {formatCurrencyUSD(fund.total)}
                      </LineText>
                    </td>
                  </Row>
                  {fund.subFunds?.map((subFund, subFundIndex) => {
                    return (
                      <Row
                        key={`${fund.fundNumber}-equity-${groupIndex}-${fundIndex}-${subFundIndex}`}>
                        <td>
                          <LineText>{subFund.fundNumber}</LineText>
                        </td>
                        <td>
                          <LineText style={{ textAlign: "right" }}>
                            {formatCurrencyUSD(subFund.total)}
                          </LineText>
                        </td>
                      </Row>
                    );
                  })}
                </React.Fragment>
              );
            });
          })}
      {!showFunds && (
        <Row>
          <td>
            <LineTextEmphasized>Total Equity</LineTextEmphasized>
          </td>
          <td>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total)}
            </LineText>
          </td>
        </Row>
      )}
      <Row>
        {showFunds && <LargeLineText>Equity Accounts</LargeLineText>}
        {showFunds &&
          columns
            .filter(
              //remove the last column, since it's just a toal
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem total={total}>
                        <ClickableLineText
                          onClick={() => drillDown(fund)}
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() => drillDown(fund)}
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  total={total}
                  style={{ borderRadius: "0 1rem 1rem 0" }}
                  key={`${fundGroup.fundGroupName}-equity`}>
                  <ClickableLineText
                    onClick={() => drillDown(fundGroup)}
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}

        {showFunds && (
          <FundsTotalItem>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total)}
            </LineText>
          </FundsTotalItem>
        )}
      </Row>
    </React.Fragment>
  );
};

export const NetIncomeColumns = ({
  incomeStatementData,
  fundsDetailsToDisplay,
  showFunds,
}) => {
  const total = true;
  const columns = incomeStatementData.find(
    (type) => type.accountTypeName === "Net Income",
  ).columns;

  console.log(incomeStatementData);
  return (
    <React.Fragment>
      <Row>
        {showFunds && <LargeLineText>Net Income</LargeLineText>}
        {showFunds &&
          columns
            .filter(
              //remove the last column, since it's just a toal
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem total={total}>
                        <ClickableLineText
                          onClick={() => drillDown(fund)}
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              onClick={() => drillDown(subFund)}
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() => drillDown(fund)}
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  total={total}
                  style={{ borderRadius: "0 1rem 1rem 0" }}
                  key={`${fundGroup.fundGroupName}-equity`}>
                  <ClickableLineText
                    onClick={() => drillDown(fundGroup)}
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}

        {showFunds && (
          <FundsTotalItem>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total)}
            </LineText>
          </FundsTotalItem>
        )}
      </Row>
    </React.Fragment>
  );
};

const AccountTypeRows = ({
  accountType,
  balanceSheetObject,
  fundsDetailsToDisplay,
  showFunds,
  filterTransactionsToInspect,
}) => {
  const AccountColumnsComponent = ({ columns, accountIndex, shade, total }) => {
    return (
      <React.Fragment>
        {showFunds &&
          columns
            .filter(
              //remove the last column, since it's just a toal
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem
                        total={total}
                        shouldShow={showFunds}
                        shade={shade}>
                        <ClickableLineText
                          onClick={() =>
                            drillDown({
                              relevantLines: fund.relevantLines,
                              filterTransactionsToInspect,
                            })
                          }
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            shade={shade}
                            shouldShow={showFunds}
                            key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              onClick={() =>
                                drillDown({
                                  relevantLines: subFund.relevantLines,
                                  filterTransactionsToInspect,
                                })
                              }
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      shade={shade}
                      shouldShow={showFunds}
                      key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() =>
                          drillDown({
                            relevantLines: fund.relevantParentLines,
                            filterTransactionsToInspect,
                          })
                        }
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  shade={shade}
                  total={total}
                  shouldShow={showFunds}
                  key={`${fundGroup.fundGroupName}-${accountIndex}`}>
                  <ClickableLineText
                    onClick={() =>
                      drillDown({
                        relevantLines: fundGroup.relevantLines,
                        filterTransactionsToInspect,
                      })
                    }
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}
        {
          <FundsTotalItem shade={shade}>
            <LineText
              style={{
                textAlign: "right",
                marginTop: "0.25rem",
                marginBottom: "0.25rem",
                marginRight: "0.5rem",
              }}>
              {formatCurrencyUSD(columns[columns.length - 1].total)}
            </LineText>
          </FundsTotalItem>
        }
      </React.Fragment>
    );
  };

  const AccountAllRows = ({ account, accountIndex }) => {
    const [rollUpSubAccounts, setRollUpSubAccounts] = useState(false);
    const toggleSubAccounts = () => {
      setRollUpSubAccounts(!rollUpSubAccounts);
    };
    const ParentAccountRow = ({ account }) => {
      return (
        <Row>
          <ParentAccountItem onClick={() => toggleSubAccounts()}>
            <LineText>{`${account.accountNumber}-${account.accountName}`}</LineText>
            {rollUpSubAccounts ? (
              <ExpandLess style={{ color: primary_dark_color_lighter }} />
            ) : (
              <ExpandMore style={{ color: primary_dark_color_lighter }} />
            )}
          </ParentAccountItem>
          {rollUpSubAccounts ? (
            <AccountColumnsComponent
              accountIndex={accountIndex}
              columns={account.totalRowColumns}
            />
          ) : (
            <AccountColumnsComponent
              accountIndex={accountIndex}
              columns={account.columns}
            />
          )}
        </Row>
      );
    };
    const SubAccountRow = ({ account, subAccountIndex }) => {
      return (
        <Row key={`${accountIndex}-${subAccountIndex}`}>
          <td>
            <LineText
              style={{
                paddingLeft: "8%",
              }}>{`${account.accountNumber}-${account.accountName}`}</LineText>
          </td>
          <AccountColumnsComponent
            accountIndex={accountIndex}
            columns={account.columns}
          />
        </Row>
      );
    };
    const AccountRow = ({ account }) => {
      return (
        <Row>
          <td>
            <LineText>{`${account.accountNumber}-${account.accountName}`}</LineText>
          </td>
          <AccountColumnsComponent
            accountIndex={accountIndex}
            columns={account.columns}
          />
        </Row>
      );
    };
    const hasSubAccounts = account.subAccounts?.length > 0;
    return (
      <React.Fragment>
        {hasSubAccounts ? (
          <React.Fragment>
            <ParentAccountRow account={account} accountIndex={accountIndex} />
            {!rollUpSubAccounts &&
              account.subAccounts.map((subAccount, subAccountIndex) => (
                <SubAccountRow
                  key={`${subAccount.accountNumber}-${accountIndex}${subAccountIndex}`}
                  account={subAccount}
                  subAccountIndex={`${accountIndex}${subAccountIndex}`}
                />
              ))}
          </React.Fragment>
        ) : (
          <AccountRow account={account} accountIndex={accountIndex} />
        )}
      </React.Fragment>
    );
  };

  const GroupRows = ({ group, groupIndex, numberOfGroups }) => {
    const [collapsedGroup, setCollapsedGroup] = useState(false);
    const groupTotalColumns = group.columns;
    const groupAccountRows = group.accounts;
    return (
      <React.Fragment>
        {numberOfGroups > 1 && (
          <Row>
            {/* <td
            style={{
              backgroundColor: "#f0f0f0",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}> */}
            <GroupItem onClick={() => setCollapsedGroup(!collapsedGroup)}>
              <LineText>{group.accountGroupName}</LineText>
              {collapsedGroup ? (
                <ExpandLess style={{ color: primary_dark_color_lighter }} />
              ) : (
                <ExpandMore style={{ color: primary_dark_color_lighter }} />
              )}
            </GroupItem>
            {/* </td> */}
            {!collapsedGroup && (
              <AccountColumnsComponent
                shade={true}
                accountIndex={groupIndex}
                columns={groupTotalColumns}
              />
            )}
            {collapsedGroup && (
              // groupTotalColumns.map((column, index) => (
              //   <td key={index}>{formatCurrencyUSD(column.total)}</td>
              // ))}
              <AccountColumnsComponent
                accountIndex={groupIndex}
                columns={groupTotalColumns}
              />
            )}
          </Row>
        )}
        {!collapsedGroup &&
          groupAccountRows.map((account, accountIndex) => (
            <AccountAllRows
              key={`${account.accountName}-${account.accountNumber}`}
              account={account}
              accountIndex={`${groupIndex}${accountIndex}`}
            />
          ))}
      </React.Fragment>
    );
  };

  const groupsInType = balanceSheetObject.find(
    (groups) =>
      groups.accountTypeName.toLowerCase() === accountType.toLowerCase(),
  );
  if (!groupsInType) {
    return null;
  } else {
    console.log(balanceSheetObject, groupsInType);
    const typeTotalColumns = groupsInType.columns;
    const numberOfGroups = groupsInType.groups.length;

    return (
      <React.Fragment>
        <Row>
          <td>
            <LargeLineText>{accountType}</LargeLineText>
          </td>
          {/* {typeTotalColumns.map((column, index) => (
          <td key={index}>{column.total}</td>
        ))} */}
        </Row>
        {groupsInType.groups.map((group, groupIndex) => (
          <GroupRows
            key={`${group.groupName}-${groupIndex}`}
            group={group}
            groupIndex={groupIndex}
            numberOfGroups={numberOfGroups}
          />
        ))}
        <Row>
          <td>
            <LineTextEmphasized>{`Total ${accountType}`}</LineTextEmphasized>
          </td>
          <AccountColumnsComponent
            accountIndex={accountType}
            key={`Total ${accountType}`}
            columns={typeTotalColumns}
            total={true}
          />
        </Row>
      </React.Fragment>
    );
  }
};

export default AccountTypeRows;
