import React, { useContext } from "react";
import {
  CollapsableSubFundTableHeaderDiv,
  CollapsableTableHeaderDiv,
  ExpandButton,
  GroupHeaderLabelDiv,
  StyledGroupHeaderText,
  StyledHeaderText,
  StyledTableHeaderDiv,
  StyledTableHeaderRowDiv,
} from "./reports_styles";
import { Tooltip } from "@mui/material";
import {
  extremeShortenText,
  findFundById,
  shortenText,
} from "../../utilities/general_util";
import { ExpandMore } from "@mui/icons-material";
import UserContext from "../../assets/user_context";

const TableHeader = ({
  fundsDetailsToDisplay,
  showFunds,
  setShowFunds,
  toggleFundVisibility,
  numberOfFundsShown,
}) => {
  const { funds, fundsHierarchy } = useContext(UserContext);
  //When showFunds is changed to false,
  return (
    <thead>
      <StyledTableHeaderRowDiv>
        <StyledTableHeaderDiv attribute="Account">
          <StyledHeaderText attribute="bold">Account</StyledHeaderText>
        </StyledTableHeaderDiv>

        {fundsHierarchy.groups.map((group, groupIndex) =>
          group.funds.map((fund, fundIndex) => (
            <React.Fragment key={`${groupIndex}-${fundIndex}`}>
              {/* Group/Fund Header */}
              {(fundsDetailsToDisplay[groupIndex].show || fundIndex === 0) &&
                showFunds && (
                  <CollapsableTableHeaderDiv
                    shouldShow={showFunds}
                    firstInGroup={fundIndex === 0}>
                    {fundIndex === 0 && showFunds && (
                      <GroupHeaderLabelDiv
                        style={showFunds ? {} : { color: "blue" }}
                        shouldShow={showFunds}
                        onClick={() =>
                          toggleFundVisibility({
                            groupIndex,
                            fundIndex: null,
                          })
                        }>
                        <Tooltip title={`Click to expand ${group.groupName}`}>
                          {showFunds && (
                            <StyledGroupHeaderText>
                              {shortenText(group.groupName)}
                            </StyledGroupHeaderText>
                          )}
                        </Tooltip>
                        {showFunds && (
                          <ExpandMore
                            style={{
                              transform: fundsDetailsToDisplay[groupIndex].show
                                ? "rotate(-90deg)"
                                : "rotate(0deg)",
                              color: "white",
                              transition: "transform 0.3s ease", // Smooth transition for rotation
                            }}
                          />
                        )}
                      </GroupHeaderLabelDiv>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <StyledHeaderText
                        style={
                          showFunds
                            ? { padding: "0.4rem" }
                            : { display: "none" }
                        }>
                        {fundsDetailsToDisplay[groupIndex].show
                          ? findFundById({ fundId: fund.id, funds }).fundName
                          : group.groupName}
                      </StyledHeaderText>
                      {fundsDetailsToDisplay[groupIndex].show &&
                        fund.subFunds?.length > 0 &&
                        showFunds && (
                          <ExpandMore
                            style={{
                              transform: fundsDetailsToDisplay[groupIndex]
                                .funds[fundIndex].show
                                ? "rotate(-90deg)"
                                : "rotate(0deg)",
                              color: "white",
                              transition: "transform 0.3s ease", // Smooth transition for rotation
                            }}
                            onClick={() =>
                              toggleFundVisibility({ groupIndex, fundIndex })
                            }
                          />
                        )}
                    </div>
                  </CollapsableTableHeaderDiv>
                )}
              {/* SubFunds Header (conditionally rendered) */}

              {/* SubFunds List */}
              {fund?.subFunds?.length > 0 &&
                fund.subFunds.map((subFund, subFundIndex) => (
                  <>
                    {fundsDetailsToDisplay[groupIndex].show &&
                      fundsDetailsToDisplay[groupIndex].funds[fundIndex]
                        .show && (
                        <CollapsableSubFundTableHeaderDiv
                          key={`${groupIndex}-${fundIndex}-${subFundIndex}`}
                          shouldShow={showFunds}
                          showFunds={showFunds}
                          isSubFund={true}>
                          {showFunds && (
                            <Tooltip
                              title={
                                numberOfFundsShown < 10
                                  ? null
                                  : findFundById({
                                      fundId: subFund,
                                      funds,
                                    }).fundName
                              }>
                              <StyledHeaderText
                                style={
                                  showFunds
                                    ? { padding: "0.4rem" }
                                    : { display: "none" }
                                }>
                                {numberOfFundsShown < 10
                                  ? findFundById({
                                      fundId: subFund,
                                      funds,
                                    }).fundName
                                  : extremeShortenText(
                                      findFundById({
                                        fundId: subFund,
                                        funds,
                                      }).fundName,
                                    )}
                              </StyledHeaderText>
                            </Tooltip>
                          )}
                        </CollapsableSubFundTableHeaderDiv>
                      )}
                  </>
                ))}
            </React.Fragment>
          )),
        )}

        <StyledTableHeaderDiv
          style={{ paddingLeft: "40px", paddingRight: "30px" }}>
          <ExpandButton
            id="expand-button"
            showFunds={showFunds}
            onClick={() => setShowFunds((prevValue) => !prevValue)}
          />
          <StyledHeaderText attribute="bold">Total</StyledHeaderText>
        </StyledTableHeaderDiv>
      </StyledTableHeaderRowDiv>
    </thead>
  );
};

export default TableHeader;
