import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  primary_dark_color,
  primary_color,
} from "../../constants/color_constants";

export const PageWrapper = styled.div`
  width: 91vw;
  margin-left: 1vw;
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 91vw;
  margin-left: 1vw;
`;

export const TitleText = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
`;

export const LineText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  text-align: left;
  margin-right: 0.5rem;
`;

export const EmphasizedLineText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: left;
  margin-right: 0.5rem;
`;

export const ClickableLineText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: left;
  cursor: pointer;
  &:hover {
    transition: 0.3s ease-in-out;
    color: ${primary_color};
    text-shadow:
      -1px -1px 0 black,
      1px -1px 0 black,
      -1px 1px 0 black,
      1px 1px 0 black;
  }
  margin-right: 0.5rem;
  /* border: 2px solid ${primary_dark_color}; */
  /* border-radius: 5px; */
  /* padding: 3px; */
  border-bottom: 2px solid grey;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const NumberText = styled.p`
  font-size: 2.4rem;
  font-family: "MontserratMed";
  color: ${primary_color};
  text-align: left;
  margin: 0;
  margin-right: 0.5rem;
  width: 5%;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  padding-bottom: 40px;
  max-height: 90vh;
  overflow-y: auto;
`;

export const LightTitleText = styled.p`
  font-size: 1.7rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
`;

export const ChangesCompareDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const TransactionDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem;
`;

export const DetailsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
`;

export const LightText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: ${primary_color};
  text-align: left;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  white-space: nowrap;
`;

export const LightBoldText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: left;
  margin: 0;
  margin-bottom: 0.5rem;
`;
