import React from "react";
import {
  RowWrapper,
  DrillingTableHeader,
  StyledTableHeaderRowDiv,
  DrillingTableItem,
  TransactionLineText,
  DrillingHeaderText,
} from "../reports_styles";
import {
  findAccountById,
  returnCurrency,
} from "../../../utilities/general_util";
import dayjs from "dayjs";

const TransactionsTable = ({ txBeingExamined, contacts, accounts }) => {
  return (
    <div style={{ overflowY: "auto", height: "80vh" }}>
      <table style={{ width: "100%", tableLayout: "auto" }}>
        <thead>
          <StyledTableHeaderRowDiv style={{ position: "sticky" }}>
            <DrillingTableHeader>
              <DrillingHeaderText>Date</DrillingHeaderText>
            </DrillingTableHeader>
            <DrillingTableHeader>
              <DrillingHeaderText>Contact</DrillingHeaderText>
            </DrillingTableHeader>
            <DrillingTableHeader>
              <DrillingHeaderText>Account</DrillingHeaderText>
            </DrillingTableHeader>
            <DrillingTableHeader>
              <DrillingHeaderText>Debit</DrillingHeaderText>
            </DrillingTableHeader>
            <DrillingTableHeader>
              <DrillingHeaderText>Credit</DrillingHeaderText>
            </DrillingTableHeader>
          </StyledTableHeaderRowDiv>
        </thead>
        <tbody>
          {txBeingExamined?.length > 0
            ? txBeingExamined
                .sort((a, b) => {
                  const dateA = dayjs(a.date.$d || a.date);
                  const dateB = dayjs(b.date.$d || b.date);
                  return dateA.isBefore(dateB) ? 1 : -1;
                })
                .map((tx) => {
                  if (tx.type === "simple") {
                    const date = dayjs(tx.date.$d || tx.date);
                    return (
                      <RowWrapper key={`row-${tx.id}-account-${tx.line}`}>
                        <DrillingTableItem
                          key={`row-${tx.id}-date-${tx.line}-td`}>
                          <TransactionLineText
                            key={`row-${tx.id}-date-${tx.line}`}>
                            {date.format("MM/DD/YYYY")}
                          </TransactionLineText>
                        </DrillingTableItem>
                        <DrillingTableItem
                          key={`row-${tx.id}-contact-${tx.line}-td`}>
                          <TransactionLineText
                            style={{ width: "100%" }}
                            key={`row-${tx.id}-contact-${tx.line}`}>
                            {contacts.find(
                              (contact) => contact.id === tx.contact,
                            )?.shortName || "Journal Entry"}
                          </TransactionLineText>
                        </DrillingTableItem>
                        <DrillingTableItem
                          key={`row-${tx.id}-account-${tx.line}-td`}>
                          <TransactionLineText
                            style={{ width: "100%" }}
                            key={`row-${tx.id}-account-${tx.line}`}>
                            {`${findAccountById({ accountId: tx.account, accounts }).accountNumber} - ${
                              findAccountById({
                                accountId: tx.account,
                                accounts,
                              }).accountName
                            }`}
                          </TransactionLineText>
                        </DrillingTableItem>
                        <DrillingTableItem
                          key={`row-${tx.id}-debit-${tx.line}-td`}>
                          {tx.sign === "debit" ? (
                            <TransactionLineText
                              style={{ textAlign: "right" }}
                              key={`row-${tx.id}-debit-${tx.line}`}>
                              {returnCurrency(tx.amount)}
                            </TransactionLineText>
                          ) : null}
                        </DrillingTableItem>
                        <DrillingTableItem
                          key={`row-${tx.id}-credit-${tx.line}-td`}>
                          {tx.sign === "credit" ? (
                            <TransactionLineText
                              style={{ textAlign: "right" }}
                              key={`row-${tx.id}-credit-${tx.line}`}>
                              {returnCurrency(tx.amount)}
                            </TransactionLineText>
                          ) : null}
                        </DrillingTableItem>
                      </RowWrapper>
                    );
                  } else {
                    const date = dayjs(tx.date.$d || tx.date);
                    return (
                      //Complex transaction
                      <tr key={`row-${tx.id}-account-${tx.line}`}>
                        <td key={`row-${tx.id}-date-${tx.line}-td`}>
                          <TransactionLineText>
                            {date.format("MM/DD/YYYY")}
                          </TransactionLineText>
                        </td>
                        <td key={`row-${tx.id}-date-${tx.line}-td`}>
                          <TransactionLineText>
                            Complex Transaction
                          </TransactionLineText>
                        </td>
                      </tr>
                    );
                  }
                })
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsTable;
