import React, { useEffect, useState } from "react";
import { CenteringDiv, SpecialTitleText, TitleText } from "./component_styles";
import { LineText } from "./foundation/table_styles";
import Logo from "../assets/logos/LogoDark.svg";
import Confetti from "react-dom-confetti";
import styled from "@emotion/styled";
import StyledButton from "../assets/buttons";
import { useNavigate } from "react-router-dom";

const StyledLogo = styled.img`
  margin-bottom: 40px;
  width: 250px;
  margin-top: 15px;
`;

const WelcomeToTheClub = () => {
  const [pop, setPop] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPop(true);
    }, 500);
  }, []);

  const navigate = useNavigate();

  const confettiStyle = {
    angle: 90,
    spread: 360,
    startVelocity: "55",
    elementCount: "132",
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "13px",
    perspective: "973px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <CenteringDiv>
      <Confetti active={pop} config={confettiStyle} />
      <SpecialTitleText>Welcome to the Family!</SpecialTitleText>
      <LineText>Thank you for diving into Boost with us. 🤗</LineText>
      <LineText>
        With you on the team, we will accelerate this products transformation
        and impact the non-profit world.
      </LineText>
      <div style={{ height: "70px" }} />
      <StyledLogo src={Logo} />
      <StyledButton
        primary
        onClick={() => navigate("/foundation/chart-of-accounts")}>
        Thanks, now let's get started
      </StyledButton>
    </CenteringDiv>
  );
};

export default WelcomeToTheClub;
