import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../assets/user_context";
import { useAuth } from "../../../services/use-auth";
import { findAccountType } from "./utils";
import {
  TextField,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import StyledButton from "../../../assets/buttons";
import AddAccount from "./add_account";
import styled from "@emotion/styled";
import { TitleText } from "../table_styles";
import { LineText } from "../../component_styles";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";
import { primary_dark_color } from "../../../constants/color_constants";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 50%;
`;

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  align-items: center;
`;

export const AddOrChangeAccount = (props) => {
  const { org, accountsHierarchy, accounts } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  const [accountEntryData, setAccountEntryData] = useState({
    type: props.accountNumberToAdd
      ? findAccountType(props.accountNumberToAdd)
      : props.options.type
      ? props.options.type
      : "",
    accountName: props.possibleAccountNameToAdd
      ? props.possibleAccountNameToAdd
      : "",
    accountNumber: props.accountNumberToAdd ? props.accountNumberToAdd : "",
    isRegister: false,
    group: props.options?.group ? props.options.group : "",
  });
  const matchOption = props.accountNumberToAdd && accounts.length > 0;
  const [matchDataEntry, setMatchDataEntry] = useState({
    accountNumber: "",
    changeAll: false,
  });
  const [accountEntryErrors, setAccountEntryErrors] = useState({
    type: false,
    accountName: false,
    accountNumber: false,
    group: false,
  });
  const [accountEntryFeedback, setAccountEntryFeedback] = useState({
    type: "",
    accountName: "",
    accountNumber: "",
    group: "",
  });

  const [existingAccountNumbers, setExistingAccountNumbers] = useState(
    accounts ? accounts.map((account) => account.accountNumber) : [],
  );
  const [attemptingToAdd, setAttemptingToAdd] = useState(false);

  useEffect(() => {
    setExistingAccountNumbers(
      accounts ? accounts.map((account) => account.accountNumber) : [],
    );
  }, [accounts]);

  const handleChangeMatch = (event) => {
    if (event.target.name === "changeAll" && event.target.checked) {
      setMatchDataEntry({
        ...matchDataEntry,
        [event.target.name]: event.target.checked,
      });
    } else {
      setMatchDataEntry({
        ...matchDataEntry,
        [event.target.name]: parseInt(event.target.value),
      });
    }
  };

  const checkIfAccountNumberTaken = (accountNumber) => {
    const accountNumberEntry = accountNumber.toString();
    const alreadyTaken = existingAccountNumbers.find(
      (accountNumber) => accountNumber === accountNumberEntry,
    );
    return alreadyTaken;
  };

  useEffect(() => {
    if (accountEntryData.accountNumber !== "") {
      const alreadyTaken = checkIfAccountNumberTaken(
        accountEntryData.accountNumber,
      );
      if (alreadyTaken) {
        setAccountEntryErrors({
          ...accountEntryErrors,
          accountNumber: true,
        });
        setAccountEntryFeedback({
          ...accountEntryFeedback,
          accountNumber: "Account number already exists",
        });
      } else {
        setAccountEntryErrors({
          ...accountEntryErrors,
          accountNumber: false,
        });
        setAccountEntryFeedback({
          ...accountEntryFeedback,
          accountNumber: "",
        });
      }
    }
  }, [accountEntryData.accountNumber, existingAccountNumbers, accounts]);

  const handleMatch = async () => {
    props.updateAccountNumber(
      matchDataEntry.accountNumber,
      matchDataEntry.changeAll,
    );
    props.setShowModal(false);
  };

  useEffect(() => {
    if (accountsHierarchy) {
      console.log(accountsHierarchy);
    }
    if (accountsHierarchy?.types?.length > 0) {
      console.log(accountsHierarchy.types);
    }

    // console.log(
    //   accountsHierarchy.types.filter(
    //     (type) => type.typeName === accountEntryData.type,
    //   ),
    // );
    if (
      accountsHierarchy?.types?.length > 0 &&
      accountsHierarchy.types.filter(
        (type) => type.type === accountEntryData.type,
      ).length > 0 &&
      accountsHierarchy.types.find(
        (type) => type.type === accountEntryData.type,
      ).groups.length > 0
    ) {
      console.log(
        "Group: ",
        accountsHierarchy.types.find(
          (type) => type.type === accountEntryData.type,
        ).groups,
      );
    }
  }, [accountEntryData.type, accountsHierarchy]);

  //log matchDataEntry to console when it updates
  useEffect(() => {
    console.log(matchDataEntry);
  }, [matchDataEntry]);

  return (
    <VerticalDiv>
      {matchOption && (
        <TitleText style={{ color: primary_dark_color }}>
          Add or Match Account
        </TitleText>
      )}
      <HorizontalDiv>
        {matchOption && (
          <VerticalDiv
            style={attemptingToAdd ? { display: "none" } : { width: "25vw" }}>
            <TitleText
              style={{ fontSize: "1.8rem", color: primary_dark_color }}>
              Match to an Account
            </TitleText>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                pb: 3,
                pl: 2,
                pr: 2,
              }}
              noValidate
              autoComplete="off">
              <FormControl fullWidth>
                <Autocomplete
                  id="accountNumber"
                  name="accountNumber"
                  value={accounts.find(
                    (account) =>
                      account.accountNumber === matchDataEntry.accountNumber,
                  )} // retrieve the account object based on accountNumber
                  getOptionLabel={(option) => option.accountName} // display the accountName
                  onChange={(event, newValue) => {
                    setMatchDataEntry({
                      ...matchDataEntry,
                      accountNumber: newValue
                        ? parseInt(newValue.accountNumber)
                        : "",
                      accountName: newValue ? newValue.accountName : "",
                    });
                  }}
                  options={accounts}
                  getOptionSelected={(option, value) =>
                    option.accountNumber === value.accountNumber
                  } // to select the correct option when the component is rendered
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginBottom: "20px", width: "340px" }}
                      name="accountNumber"
                      color="secondary"
                      variant="outlined"
                      label="Match to Account"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={matchDataEntry.changeAll}
                      onChange={handleChangeMatch}
                      name="changeAll"
                      color="secondary"
                    />
                  }
                  label={
                    matchDataEntry.accountNumber === ""
                      ? "Change all accounts"
                      : `Change all transactions with account number ${accountEntryData.accountNumber} to ${matchDataEntry.accountNumber}`
                  }
                />
              </FormControl>
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}>
              <StyledButton
                primary
                fontSize={"1.3rem"}
                onClick={() => handleMatch()}>
                Match Account Number
              </StyledButton>
            </div>
          </VerticalDiv>
        )}
        {attemptingToAdd && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <LoadingLogoStyled />
            <LineText>Adding Account...</LineText>
          </div>
        )}
        <VerticalDiv style={attemptingToAdd ? { display: "none" } : {}}>
          <AddAccount
            accountNumberToAdd={props.accountNumberToAdd}
            possibleAccountNameToAdd={props.possibleAccountNameToAdd}
            setShowModal={props.setShowModal}
            accounts={accounts}
            org={org}
            db={db}
            accountsHierarchy={accountsHierarchy}
            options={props.options}
            clickedAdd={() => setAttemptingToAdd(true)}
            reenableSubmit={() => setAttemptingToAdd(false)}
            disabledAddButton={attemptingToAdd}
          />
        </VerticalDiv>
      </HorizontalDiv>
    </VerticalDiv>
  );
};
