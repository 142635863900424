import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import StyledButton from "../../assets/buttons";
import { TitleText } from "./onboarding_styles";
import {
  updateDoc,
  doc,
  collection,
  addDoc,
  writeBatch,
} from "firebase/firestore";
import { CenteringDiv } from "../../assets/containers";
import { RowWrapper } from "../../assets/containers";
import {
  defaultAccounts,
  defaultAccountsHierarchy,
  defaultFunds,
  defaultFundsHierarchy,
} from "./defaultsForExpert";
import SignOutModal from "../../nav/signOut";

export const AddOrg = ({ db, userData, user, closeModal }) => {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    orgName: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Your organization needs a name"),
    orgType: Yup.string()
      .required("Required for proper setup")
      .transform((value) => (value === "" ? null : value)),
    taxId: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .matches(/^\d{9}$/, "Tax ID must be exactly 9 digits"),
    financialYearStart: Yup.string()
      .required("Enter the month your FY starts")
      .transform((value) => (value === "" ? null : value)),
    phone: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),
    website: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .url("Must be a valid URL"),
    streetAddress: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .max(35, "Must be 35 characters or less"),
    city: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .max(25, "Must be 25 characters or less"),
    state: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .min(2, "Must be 2 letters")
      .max(2, "Must be 2 letters"),
    zip: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
      .notRequired(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      orgName: "",
      orgType: "",
      taxId: "",
      financialYearStart: "",
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      website: "",
    },
  });

  const onSubmit = async (values) => {
    const setDefaultSetup = async (db, orgID, retryCount = 3) => {
      let attempts = 0;
      let success = false;

      while (attempts < retryCount && !success) {
        try {
          const batch = writeBatch(db);

          const orgRef = doc(db, "orgs", orgID);
          const accountsHierarchyRef = doc(
            db,
            "orgs",
            orgID,
            "organizationAndSettings",
            "accountsHierarchy",
          );
          const fundsHierarchyRef = doc(
            db,
            "orgs",
            orgID,
            "organizationAndSettings",
            "fundsHierarchy",
          );

          batch.set(accountsHierarchyRef, defaultAccountsHierarchy);
          batch.set(fundsHierarchyRef, defaultFundsHierarchy);

          defaultAccounts.forEach((account) => {
            const accountRef = doc(db, "orgs", orgID, "accounts", account.id);
            batch.set(accountRef, account);
          });

          defaultFunds.forEach((fund) => {
            const fundRef = doc(db, "orgs", orgID, "funds", fund.id);
            batch.set(fundRef, fund);
          });

          batch.update(orgRef, { onboarded: true }, { merge: true });

          await batch.commit();
          success = true;
        } catch (error) {
          attempts++;
          console.error(`Attempt ${attempts} failed:`, error);
          if (attempts >= retryCount) {
            throw new Error(`Failed after ${attempts} attempts`);
          }
        }
      }
    };

    const addOrg = async (values) => {
      const today = new Date();
      const newOrgData = {
        ...values,
        users: {
          [user.uid]: {
            roles: ["admin", "subscriber"],
            username: userData.username,
            email: userData.email,
          },
        },
        onboarded: false,
        creationDate: today,
      };
      const orgs = userData.orgs ? userData.orgs : [];
      console.log("Adding org: ", newOrgData);
      const orgsRef = collection(db, "orgs");
      const docRef = await addDoc(orgsRef, newOrgData);
      const orgID = docRef.id;
      console.log("Added org: ", orgID);
      orgs.push({
        ...values,
        orgID: orgID,
      });
      console.log("Orgs: ", orgs);
      await setDefaultSetup(db, orgID);
      await updateDoc(
        doc(db, "users", user.uid),
        {
          orgs: orgs,
          lastOrgUsed: orgID,
        },
        { merge: true },
      );
      if (closeModal) closeModal();
    };

    await addOrg(values);
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CenteringDiv>
          <TitleText>Create a New Organization</TitleText>
          <div style={{ height: "17px" }} />
          <Grid
            container
            style={{
              margin: 0,
              paddingLeft: 30,
              paddingRight: 30,
              width: "100%",
            }}
            spacing={2}>
            <Grid item xs={7}>
              <Controller
                name="orgName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Org Name"
                    required
                    fullWidth
                    error={!!errors.orgName}
                    helperText={errors.orgName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="orgType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Org Type"
                    required
                    fullWidth
                    error={!!errors.orgType}
                    helperText={errors.orgType?.message}>
                    <MenuItem value={"church"}>Church</MenuItem>
                    <MenuItem value={"generalNP"}>General Nonprofit</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="taxId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Tax ID"
                    fullWidth
                    error={!!errors.taxId}
                    helperText={errors.taxId?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="financialYearStart"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Financial Year Start"
                    required
                    fullWidth
                    error={!!errors.financialYearStart}
                    helperText={errors.financialYearStart?.message}>
                    <MenuItem value={"jan"}>January</MenuItem>
                    <MenuItem value={"feb"}>February</MenuItem>
                    <MenuItem value={"mar"}>March</MenuItem>
                    <MenuItem value={"apr"}>April</MenuItem>
                    <MenuItem value={"may"}>May</MenuItem>
                    <MenuItem value={"jun"}>June</MenuItem>
                    <MenuItem value={"jul"}>July</MenuItem>
                    <MenuItem value={"aug"}>August</MenuItem>
                    <MenuItem value={"sep"}>September</MenuItem>
                    <MenuItem value={"oct"}>October</MenuItem>
                    <MenuItem value={"nov"}>November</MenuItem>
                    <MenuItem value={"dec"}>December</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="streetAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Street Address"
                    fullWidth
                    error={!!errors.streetAddress}
                    helperText={errors.streetAddress?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    fullWidth
                    error={!!errors.city}
                    helperText={errors.city?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    fullWidth
                    error={!!errors.state}
                    helperText={errors.state?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Zip"
                    fullWidth
                    error={!!errors.zip}
                    helperText={errors.zip?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone Number"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="website"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Business Website"
                    fullWidth
                    error={!!errors.website}
                    helperText={errors.website?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div style={{ height: "25px" }} />
          <StyledButton
            bright
            width="165px"
            height="36px"
            fontSize="1.2rem"
            type="submit">
            Create Org
          </StyledButton>
          <p style={{ color: "white", fontFamily: "MontserratMed" }}>
            Or ask the admin of an existing organization to add you.
          </p>
        </CenteringDiv>
      </form>
    </ThemeProvider>
  );
};
