import {
  LineText,
  NavBack,
  TitleText,
  TopRowDiv,
} from "../component_styles.jsx";
import EnterTransaction from "./enter_transaction/enter_transaction.jsx";
import styled from "@emotion/styled";
import Transactions from "./transactions.js";
import {
  primary_color,
  primary_dark_color,
  secondary_highlight_color,
} from "../../constants/color_constants.jsx";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../assets/user_context.jsx";
import { Close, Title, UploadFileOutlined } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { ModalBox, ModalInner } from "../onboarding/onboarding_styles.jsx";
import ImportTransactionsWorkflow from "../../utilities/mass_import/transaction_import/normal_import_workflow.jsx";
import { ButtonText } from "../banking/banking/banking_styles.jsx";
import { ClickableDiv } from "../foundation/table_styles.jsx";

const PageWrapper = styled.div`
  /* width: 91vw; */
  margin-left: 1vw;
`;

const TransactionsEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${secondary_highlight_color};
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  border: 1px solid ${primary_color};
  margin-bottom: 20px;
`;

const JournalEntry = () => {
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);
  const { orgRoles, experimental, userData } = useContext(UserContext);

  const [showTxTable, setShowTxTable] = useState(true);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [journalEntryData, setJournalEntryData] = useState(null);

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer
      const enterTxHeight =
        document.querySelector("#transaction-entry-wrapper")?.offsetHeight || 0;
      const bankingToolsHeight =
        document.querySelector("#banking-tools-wrapper")?.offsetHeight || 0;
      const titleHeight =
        document.querySelector("#page-title")?.offsetHeight || 0;
      const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
      const extra = experimental ? 230 : 190;
      const height =
        vh -
        (enterTxHeight +
          bankingToolsHeight +
          titleHeight +
          footerHeight +
          extra);
      const heightString = height.toString().concat("px");
      if (height <= 0) {
        setShowTxTable(false);
      } else {
        setShowTxTable(true);
      }

      console.log(
        `Recalc Tx table height, viewHeight:${vh}, enterTxHeight:${enterTxHeight}, bankingToolsHeight:${bankingToolsHeight}, titleHeight:${titleHeight}, footerHeight:${footerHeight}, height:${heightString}`,
      );
      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "enter-transactions-wrapper") {
          calculateHeight();
        }
      }
    });

    // Observe the target element
    const enterTxWrapper = document.querySelector(
      "#enter-transactions-wrapper",
    );
    if (enterTxWrapper) {
      resizeObserver.observe(enterTxWrapper);
    }

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
      if (enterTxWrapper) {
        resizeObserver.unobserve(enterTxWrapper);
      }
    };
  }, [experimental]);

  return (
    <PageWrapper>
      {orgRoles.some((role) => role === "admin" || role === "bookkeeper") ||
      userData.superAdmin ? (
        <>
          <Modal
            open={openUploadModal}
            onClose={() => setOpenUploadModal(false)}>
            <ModalBox>
              <ModalInner>
                <Close
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenUploadModal(false);
                  }}
                  fontSize="large"
                />
                <ImportTransactionsWorkflow
                  location="journalEntry"
                  closeModal={() => setOpenUploadModal(false)}
                />
              </ModalInner>
            </ModalBox>
          </Modal>
          <TransactionsEntryWrapper id="transaction-entry-wrapper">
            <EnterTransaction
              setOpenUploadModal={setOpenUploadModal}
              journalEntryData={journalEntryData}
            />
          </TransactionsEntryWrapper>
        </>
      ) : (
        <div id="enter-transactions-wrapper">
          <TitleText style={{ color: "grey", padding: "30px" }}>
            {`Unable to enter transactions as ${orgRoles.join(" ")}.`}
          </TitleText>
        </div>
      )}

      {showTxTable && (
        <Transactions
          height={tableWrapperHeight}
          setJournalEntryData={setJournalEntryData}
        />
      )}
    </PageWrapper>
  );
};

export default JournalEntry;
