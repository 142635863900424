export const experimentalOrgId = "PCWFsbPPblsX5fmQV083";

export const experimentalOrgData = {
  streetaAddress: "",
  chosenPath: "power-startingBalance",
  city: "",
  orgType: "generalNP",
  orgName: "Impact Development Ventures",
  lastProcessed: {
    seconds: 1703267568,
    nanoseconds: 199000000,
  },
  phone: "",
  website: "",
  state: "",
  taxId: "",
  users: {
    rhu2KpVGN0g3M5HtJSFfbmy31b52: {
      roles: ["admin", "subscriber"],
      username: "Jeff Davidson",
      email: "jeff@impactourworld.com",
    },
  },
  onboarded: true,
  financialYearStart: "apr",
  subscriptionCurrent: true,
  zip: "",
};

export const experimentalAccounts = [
  {
    accountNumber: "3100",
    accountName: "Grant Fund",
    accountType: "Equity",
    group: "Fund-Linked Accounts",
    id: "0y8FCh2cefSnhhZJxEJ8",
  },
  {
    isRegister: false,
    disabled: false,
    accountName: "Services",
    isPayableReceivable: false,
    accountType: "Expenses",
    accountNumber: "5130",
    id: "3nLtYOnsMUhZchI6ODb4",
  },
  {
    disabled: false,
    isRegister: false,
    isPayableReceivable: false,
    accountType: "Expenses",
    accountName: "Building Maintenance",
    accountNumber: "6100",
    id: "61etq94A1WKwv9iYhK6r",
  },
  {
    accountNumber: "4000",
    accountName: "Donations Income",
    isRegister: false,
    accountType: "Income",
    disabled: false,
    isPayableReceivable: false,
    id: "6JnJdulQfxVjCHsRAowz",
  },
  {
    disabled: false,
    accountName: "Salaries",
    isRegister: false,
    isPayableReceivable: false,
    accountType: "Expenses",
    accountNumber: "5000",
    id: "9j8bIW5jBY8rMzU31j9h",
  },
  {
    accountNumber: "6000",
    accountType: "Expenses",
    isPayableReceivable: false,
    accountName: "Grant Expenses",
    disabled: false,
    isRegister: false,
    id: "BLRxrwoLIlKY6bs6hFFg",
  },
  {
    disabled: false,
    accountName: "Staff Appreciation",
    accountNumber: "5110",
    accountType: "Expenses",
    isPayableReceivable: false,
    isRegister: false,
    id: "GC7FsrkuC9p0y6FHMeXf",
  },
  {
    isPayableReceivable: false,
    disabled: false,
    accountNumber: "1010",
    isRegister: false,
    accountName: "Savings",
    accountType: "Assets",
    startingBalance: true,
    id: "HcENBgtb85Cej4nruFVV",
  },
  {
    accountType: "Expenses",
    isPayableReceivable: false,
    accountName: "Employer Taxes",
    accountNumber: "5010",
    isRegister: false,
    disabled: false,
    id: "IVcNfMTIBeAzUkqpU1Eg",
  },
  {
    accountName: "Building Fund",
    group: "Fund-Linked Accounts",
    accountNumber: "3200",
    accountType: "Equity",
    id: "WWfUVTCdovlEqYuKWBzO",
  },
  {
    accountType: "Liabilities",
    startingBalance: true,
    isPayableReceivable: false,
    isRegister: true,
    disabled: false,
    accountName: "Credit Card",
    accountNumber: "2000",
    id: "WdxB1CUpp7MroxSNTAoE",
  },
  {
    accountType: "Expenses",
    disabled: false,
    isRegister: false,
    isPayableReceivable: false,
    accountName: "Rent",
    accountNumber: "5100",
    id: "YpmAvvqO0ydpUqv83hF8",
  },
  {
    accountName: "Grant Income",
    isRegister: false,
    disabled: false,
    accountType: "Income",
    isPayableReceivable: false,
    accountNumber: "4100",
    id: "b4NSrV18NpQIG3O81Wih",
  },
  {
    isPayableReceivable: false,
    accountName: "Employer Benefits",
    disabled: false,
    accountType: "Expenses",
    accountNumber: "5020",
    isRegister: false,
    id: "fX3btsviWqR00GywOQ5W",
  },
  {
    accountNumber: "3000",
    accountType: "Equity",
    group: "Fund-Linked Accounts",
    accountName: "General Fund",
    id: "ipkDGFt1ehywSC7UPjZ8",
  },
  {
    accountNumber: "1000",
    accountType: "Assets",
    isPayableReceivable: false,
    startingBalance: true,
    disabled: false,
    isRegister: true,
    accountName: "Checking",
    id: "mQ9Z5KPcchYeOP2p2qsV",
  },
  {
    accountType: "Liabilities",
    isPayableReceivable: false,
    accountName: "Payroll Liabilities",
    disabled: false,
    startingBalance: true,
    isRegister: false,
    accountNumber: "2100",
    id: "o1KVIlm3AFXVX00vXMXW",
  },
  {
    accountType: "Income",
    isPayableReceivable: false,
    accountNumber: "4200",
    accountName: "Interest",
    disabled: false,
    isRegister: false,
    id: "vDIp8A77y0rS4KyNwaSM",
  },
  {
    isRegister: false,
    isPayableReceivable: false,
    accountName: "Subscriptions",
    accountNumber: "5120",
    accountType: "Expenses",
    disabled: false,
    id: "wTNOOyhANlDzE2HHXUZd",
  },
];

export const experimentalFunds = [
  {
    isRestricted: true,
    fundNumber: "3100",
    fundName: "Grant Fund",
    id: "0y8FCh2cefSnhhZJxEJ8",
  },
  {
    isRestricted: true,
    fundNumber: "3200",
    fundName: "Building Fund",
    id: "WWfUVTCdovlEqYuKWBzO",
  },
  {
    fundNumber: "3000",
    isRestricted: false,
    fundName: "General Fund",
    id: "ipkDGFt1ehywSC7UPjZ8",
  },
];

export const experimentalFundsHierarchy = {
  groups: [
    {
      groupName: "General",
      funds: [
        {
          id: "ipkDGFt1ehywSC7UPjZ8",
        },
        {
          subFunds: [],
          id: "WWfUVTCdovlEqYuKWBzO",
        },
      ],
    },
    {
      funds: [
        {
          id: "0y8FCh2cefSnhhZJxEJ8",
        },
      ],
      groupName: "Grants",
    },
  ],
};

export const experimentalAccountsHierarchy = {
  types: [
    {
      groups: [
        {
          accounts: [
            {
              id: "mQ9Z5KPcchYeOP2p2qsV",
            },

            {
              id: "HcENBgtb85Cej4nruFVV",
            },
          ],
          groupName: "Cash",
        },
      ],
      type: "Assets",
    },
    {
      groups: [
        {
          accounts: [
            {
              id: "WdxB1CUpp7MroxSNTAoE",
            },
          ],
          groupName: "Credit Cards",
        },
        {
          groupName: "Holding",
          accounts: [
            {
              id: "o1KVIlm3AFXVX00vXMXW",
            },
          ],
        },
      ],
      type: "Liabilities",
    },
    {
      groups: [
        {
          accounts: [
            {
              id: "6JnJdulQfxVjCHsRAowz",
            },
            {
              id: "b4NSrV18NpQIG3O81Wih",
            },
            {
              id: "vDIp8A77y0rS4KyNwaSM",
            },
          ],
          groupName: "Income",
        },
      ],
      type: "Income",
    },
    {
      type: "Expenses",
      groups: [
        {
          groupName: "Staff",
          accounts: [
            {
              id: "9j8bIW5jBY8rMzU31j9h",
            },
            {
              id: "IVcNfMTIBeAzUkqpU1Eg",
            },
            {
              id: "fX3btsviWqR00GywOQ5W",
            },
            {
              id: "GC7FsrkuC9p0y6FHMeXf",
            },
          ],
        },
        {
          groupName: "General",
          accounts: [
            {
              id: "YpmAvvqO0ydpUqv83hF8",
            },
            {
              id: "wTNOOyhANlDzE2HHXUZd",
            },
            {
              id: "3nLtYOnsMUhZchI6ODb4",
            },
            {
              id: "BLRxrwoLIlKY6bs6hFFg",
            },
            {
              id: "61etq94A1WKwv9iYhK6r",
            },
          ],
        },
      ],
    },
    {
      type: "Equity",
      groups: [
        {
          groupName: "Fund-Linked Accounts",
          accounts: [
            {
              id: "ipkDGFt1ehywSC7UPjZ8",
            },
            {
              subAccounts: [],
              id: "0y8FCh2cefSnhhZJxEJ8",
            },
            {
              subAccounts: [],
              id: "WWfUVTCdovlEqYuKWBzO",
            },
          ],
        },
      ],
    },
  ],
};

export const experimentalContacts = [
  {
    shortName: "Dive Software",
    companyName: "Dive Software",
    oldMatchName: false,
    type: "Vendor",
    id: "65g38BLuK4213O183cWh",
  },
  {
    companyName: "Payroll Company",
    type: "Vendor",
    oldMatchName: false,
    shortName: "Payroll Company",
    id: "Iat2RXc1OK1JG21s26Gd",
  },
  {
    companyName: "Credit Card Company",
    shortName: "Credit Card Company",
    type: "Vendor",
    oldMatchName: false,
    id: "OCJw6UtozTdsRAdP96vS",
  },
  {
    type: "Vendor",
    oldMatchName: false,
    companyName: "Property Management",
    shortName: "Property Management",
    id: "OiksEVPKL7DJvbwWFYQ0",
  },
  {
    oldMatchName: false,
    type: "Vendor",
    shortName: "Supplies Store",
    companyName: "Supplies Store",
    id: "W5yEd1Ik3IJh3gyIMa12",
  },
  {
    oldMatchName: false,
    shortName: "EmpowerT3",
    companyName: "EmpowerT3",
    type: "Vendor",
    id: "ZFlSRmK3Nkqyq11BpVc1",
  },
  {
    type: "Vendor",
    companyName: "Hardware Store",
    oldMatchName: false,
    shortName: "Hardware Store",
    id: "aOstuF6UV7KLp7KATd15",
  },
  {
    oldMatchName: false,
    companyName: "Coffee Shop",
    type: "Vendor",
    shortName: "Coffee Shop",
    id: "denmDL0Cc3abQPyTmas3",
  },
  {
    companyName: "Bank Transfer",
    type: "Vendor",
    oldMatchName: false,
    shortName: "Bank Transfer",
    id: "eXfWDW0DotIL5FQTibmx",
  },
  {
    companyName: "Benefits Provider",
    oldMatchName: false,
    type: "Vendor",
    shortName: "Benefits Provider",
    id: "jN4Os0oEAeUkQkvyRqo3",
  },
  {
    companyName: "None",
    oldMatchName: "",
    shortName: "None",
    type: "Vendor",
    id: "kAyGtMSBQkBEW8qEIHpt",
  },
  {
    type: "Vendor",
    shortName: "Bank Deposit",
    oldMatchName: false,
    companyName: "Bank Deposit",
    id: "rxfzXZnbPqHD7pzI0lfp",
  },
];

export const experimentalTransactions = [
  {
    memo: "Starting Balances",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 272000000,
    },
    id: "QTdg4KCMh5RU0yd3eY3n",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    journalEntry: false,
    date: "2022-12-31T08:00:00.000Z",
    lines: [
      {
        comment: "",
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV", //Change this to the correct account from "mOSaFIxq2cQySjdHdZpl"
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 200000,
        contact: "kAyGtMSBQkBEW8qEIHpt",
      },
      {
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        amount: 100000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        comment: "",
        amount: 50000,
        sign: "debit",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 500000,
        contact: "kAyGtMSBQkBEW8qEIHpt",
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        comment: "",
      },
      {
        sign: "debit",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        amount: 300000,
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "HcENBgtb85Cej4nruFVV",
      },
      {
        sign: "debit",
        amount: 200000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "HcENBgtb85Cej4nruFVV",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        comment: "",
      },
      {
        contact: "kAyGtMSBQkBEW8qEIHpt",
        amount: 700000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        account: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        account: "0y8FCh2cefSnhhZJxEJ8",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        sign: "credit",
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 400000,
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        sign: "credit",
        contact: "kAyGtMSBQkBEW8qEIHpt",
        comment: "",
        account: "WWfUVTCdovlEqYuKWBzO",
        amount: 250000,
      },
    ],
    batch: true,
    imported: true,
    checkNumber: false,
  },
  {
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 675000000,
    },
    checkNumber: false,
    id: "SxPlplxnuJbcAtBCimGB",
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Rent Payment",
    batch: true,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 80000,
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "YpmAvvqO0ydpUqv83hF8",
        sign: "debit",
      },
      {
        amount: 80000,
        contact: "OiksEVPKL7DJvbwWFYQ0",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
      },
    ],
    journalEntry: false,
    date: "2023-01-05T08:00:00.000Z",
  },
  {
    checkNumber: false,
    lines: [
      {
        contact: "denmDL0Cc3abQPyTmas3",
        account: "GC7FsrkuC9p0y6FHMeXf",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 1000,
        comment: "",
      },
      {
        contact: "denmDL0Cc3abQPyTmas3",
        comment: "",
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 1000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 817000000,
    },
    addTimestamp: false,
    journalEntry: false,
    imported: true,
    memo: "Imported on 12/22/2023",
    batch: true,
    id: "PzNpVxLGw2rp02HyoOSq",
    date: "2023-01-10T08:00:00.000Z",
  },
  {
    lines: [
      {
        amount: 50000,
        contact: "aOstuF6UV7KLp7KATd15",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        sign: "debit",
        account: "61etq94A1WKwv9iYhK6r",
      },
      {
        sign: "credit",
        amount: 50000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "aOstuF6UV7KLp7KATd15",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
    ],
    batch: true,
    addTimestamp: false,
    imported: true,
    id: "IKCHCJ7uI2eIGbjnbtdd",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 935000000,
    },
    memo: "Building Maintenance",
    date: "2023-01-12T08:00:00.000Z",
    checkNumber: false,
    journalEntry: false,
  },
  {
    checkNumber: false,
    batch: true,
    date: "2023-01-14T08:00:00.000Z",
    lines: [
      {
        amount: 200000,
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "general donations",
        account: "6JnJdulQfxVjCHsRAowz",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "building donations",
        account: "6JnJdulQfxVjCHsRAowz",
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 100000,
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 200000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        amount: 100000,
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "CGeVPQbTcsZGQgvAnpqH",
    addTimestamp: false,
    journalEntry: false,
    memo: "Donations Deposit",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 790000000,
    },
  },
  {
    memo: "Supplies for Grant",
    batch: true,
    date: "2023-01-15T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 766000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "iYW7RGnM2vvf3dB66gys",
    checkNumber: false,
    journalEntry: false,
    addTimestamp: false,
    imported: true,
    lines: [
      {
        amount: 40000,
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "BLRxrwoLIlKY6bs6hFFg",
        sign: "debit",
        comment: "",
        contact: "W5yEd1Ik3IJh3gyIMa12",
      },
      {
        sign: "credit",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 40000,
        comment: "",
      },
    ],
  },
  {
    memo: "Accounting Services",
    journalEntry: false,
    id: "n1HntSgTCqGhXcd8yzpf",
    date: "2023-01-20T08:00:00.000Z",
    lines: [
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
        sign: "debit",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        account: "3nLtYOnsMUhZchI6ODb4",
      },
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        amount: 25000,
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    imported: true,
    addTimestamp: false,
    batch: true,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 931000000,
    },
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 782000000,
    },
    addTimestamp: false,
    imported: true,
    id: "ryvaWKhWiTJZetHx7wP7",
    date: "2023-01-20T08:00:00.000Z",
    journalEntry: false,
    lines: [
      {
        amount: 3000,
        comment: "",
        sign: "debit",
        contact: "65g38BLuK4213O183cWh",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "wTNOOyhANlDzE2HHXUZd",
      },
      {
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "65g38BLuK4213O183cWh",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    memo: "Best Software Ever",
    batch: true,
    checkNumber: false,
  },
  {
    date: "2023-01-25T08:00:00.000Z",
    memo: "Monthly Salaries",
    addTimestamp: false,
    id: "mdJ1yGoRzq9mXv8BKRPP",
    batch: true,
    checkNumber: false,
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 870000000,
    },
    lines: [
      {
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 100000,
        account: "9j8bIW5jBY8rMzU31j9h",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        sign: "debit",
      },
      {
        account: "IVcNfMTIBeAzUkqpU1Eg",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 10000,
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "fX3btsviWqR00GywOQ5W",
        comment: "",
        amount: 10000,
      },
      {
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 110000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "o1KVIlm3AFXVX00vXMXW",
        sign: "credit",
        amount: 10000,
        comment: "",
      },
    ],
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    journalEntry: false,
    batch: true,
    memo: "Monthly Interest",
    checkNumber: false,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        amount: 500,
        account: "vDIp8A77y0rS4KyNwaSM",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
      },
      {
        amount: 500,
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
    ],
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-01-28T08:00:00.000Z",
    addTimestamp: false,
    id: "Ml9B2jZngnEU08YPsmOW",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 993000000,
    },
  },
  {
    memo: "Monthly Transfer",
    journalEntry: false,
    lines: [
      {
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "HcENBgtb85Cej4nruFVV",
        contact: "eXfWDW0DotIL5FQTibmx",
        comment: "",
        amount: 20000,
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        amount: 20000,
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    date: "2023-01-28T08:00:00.000Z",
    checkNumber: false,
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "aRA6ExUxnOwhATVUldhf",
    addTimestamp: false,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 414000000,
    },
  },
  {
    checkNumber: false,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        contact: "OCJw6UtozTdsRAdP96vS",
        comment: "",
        sign: "debit",
        amount: 29000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
      {
        contact: "OCJw6UtozTdsRAdP96vS",
        amount: 29000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    date: "2023-01-28T08:00:00.000Z",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 863000000,
    },
    batch: true,
    memo: "Paying Off Credit Card",
    journalEntry: false,
    id: "muzHN7tb4y1oV5eqeZtn",
  },
  {
    journalEntry: false,
    addTimestamp: false,
    date: "2023-01-28T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 5000000,
    },
    checkNumber: false,
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    id: "vGpSKm8Es3RkVpQq2QHa",
    memo: "Grant Deposit",
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "b4NSrV18NpQIG3O81Wih",
        comment: "",
        amount: 50000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        sign: "debit",
        amount: 50000,
      },
    ],
  },
  {
    batch: true,
    checkNumber: false,
    lines: [
      {
        amount: 10000,
        sign: "debit",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        account: "o1KVIlm3AFXVX00vXMXW",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        contact: "jN4Os0oEAeUkQkvyRqo3",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    addTimestamp: false,
    journalEntry: false,
    date: "2023-01-28T08:00:00.000Z",
    imported: true,
    memo: "Benefits Payment",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 397000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "zNJL7taCYxii2SJSS4fX",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267544,
      nanoseconds: 798000000,
    },
    checkNumber: false,
    memo: "Rent Payment",
    date: "2023-02-05T08:00:00.000Z",
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        sign: "debit",
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        amount: 80000,
        account: "YpmAvvqO0ydpUqv83hF8",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 80000,
      },
    ],
    batch: true,
    addTimestamp: false,
    journalEntry: false,
    id: "WbWtYtd8MHzchky6bKcX",
  },
  {
    addTimestamp: false,
    memo: "Imported on 12/22/2023",
    id: "sBzSkRTygN2eRs8y9Xhs",
    journalEntry: false,
    batch: true,
    lines: [
      {
        comment: "",
        account: "GC7FsrkuC9p0y6FHMeXf",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 1000,
        contact: "denmDL0Cc3abQPyTmas3",
      },
      {
        sign: "credit",
        amount: 1000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "denmDL0Cc3abQPyTmas3",
      },
    ],
    date: "2023-02-10T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 776000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    imported: true,
  },
  {
    date: "2023-02-12T08:00:00.000Z",
    addTimestamp: false,
    batch: true,
    memo: "Building Maintenance",
    id: "xxtn73ZODygQHVqjp3p6",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    journalEntry: false,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 979000000,
    },
    lines: [
      {
        comment: "",
        account: "61etq94A1WKwv9iYhK6r",
        sign: "debit",
        amount: 50000,
        contact: "aOstuF6UV7KLp7KATd15",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        contact: "aOstuF6UV7KLp7KATd15",
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        amount: 50000,
      },
    ],
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    checkNumber: false,
    id: "w4YItbSgVe2sczykfCju",
    date: "2023-02-14T08:00:00.000Z",
    imported: true,
    addTimestamp: false,
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "6JnJdulQfxVjCHsRAowz",
        comment: "general donations",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 200000,
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "6JnJdulQfxVjCHsRAowz",
        amount: 100000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "building donations",
        sign: "credit",
      },
      {
        comment: "",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 200000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        amount: 100000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    memo: "Donations Deposit",
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 259000000,
    },
  },
  {
    memo: "Supplies for Grant",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lines: [
      {
        amount: 40000,
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "BLRxrwoLIlKY6bs6hFFg",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        sign: "debit",
      },
      {
        contact: "W5yEd1Ik3IJh3gyIMa12",
        sign: "credit",
        amount: 40000,
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    checkNumber: false,
    imported: true,
    batch: true,
    date: "2023-02-15T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 926000000,
    },
    addTimestamp: false,
    id: "tx5n5fzwwRAy91BPFLEk",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-02-20T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 261000000,
    },
    addTimestamp: false,
    imported: true,
    journalEntry: false,
    checkNumber: false,
    lines: [
      {
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "wTNOOyhANlDzE2HHXUZd",
        comment: "",
      },
      {
        comment: "",
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    id: "qWN4KJKueYMKk2UnMLIs",
    memo: "Best Software Ever",
    batch: true,
  },
  {
    imported: true,
    date: "2023-02-20T08:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 979000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    memo: "Accounting Services",
    lines: [
      {
        comment: "",
        sign: "debit",
        amount: 25000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "3nLtYOnsMUhZchI6ODb4",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
      },
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
        sign: "credit",
      },
    ],
    id: "sJhXcmdvwl3iMypkgB2Y",
    batch: true,
    checkNumber: false,
    journalEntry: false,
  },
  {
    journalEntry: false,
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 476000000,
    },
    date: "2023-02-25T08:00:00.000Z",
    checkNumber: false,
    memo: "Monthly Salaries",
    lines: [
      {
        amount: 100000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "9j8bIW5jBY8rMzU31j9h",
        sign: "debit",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        comment: "",
        sign: "debit",
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "IVcNfMTIBeAzUkqpU1Eg",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        comment: "",
        amount: 10000,
        sign: "debit",
        account: "fX3btsviWqR00GywOQ5W",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        amount: 110000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
      },
      {
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 10000,
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "o1KVIlm3AFXVX00vXMXW",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    id: "ahLgZA40tEb3RGNuHB0z",
    addTimestamp: false,
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    batch: true,
    memo: "Benefits Payment",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267537,
      nanoseconds: 174000000,
    },
    imported: true,
    checkNumber: false,
    lines: [
      {
        contact: "jN4Os0oEAeUkQkvyRqo3",
        account: "o1KVIlm3AFXVX00vXMXW",
        amount: 10000,
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        contact: "jN4Os0oEAeUkQkvyRqo3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        amount: 10000,
      },
    ],
    id: "5U20U4HHlstqFrTXXPeV",
    date: "2023-02-28T08:00:00.000Z",
  },
  {
    batch: true,
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    memo: "Monthly Transfer",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 895000000,
    },
    date: "2023-02-28T08:00:00.000Z",
    addTimestamp: false,
    lines: [
      {
        comment: "",
        sign: "debit",
        contact: "eXfWDW0DotIL5FQTibmx",
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 20000,
        account: "HcENBgtb85Cej4nruFVV",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 20000,
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "credit",
      },
    ],
    id: "EitBIO9xZMwGMLO6EbN1",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 278000000,
    },
    date: "2023-02-28T08:00:00.000Z",
    imported: true,
    id: "FbNcoTNn1YqdkCiL72oj",
    journalEntry: false,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "b4NSrV18NpQIG3O81Wih",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
        comment: "",
        sign: "credit",
      },
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 50000,
        comment: "",
      },
    ],
    batch: true,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Grant Deposit",
    checkNumber: false,
  },
  {
    checkNumber: false,
    addTimestamp: false,
    memo: "Monthly Interest",
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    date: "2023-02-28T08:00:00.000Z",
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        amount: 500,
        comment: "",
        account: "vDIp8A77y0rS4KyNwaSM",
      },
      {
        sign: "debit",
        amount: 500,
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 992000000,
    },
    id: "P9Nt8ykoulD1l5PNd7C7",
    imported: true,
  },
  {
    checkNumber: false,
    lines: [
      {
        comment: "",
        sign: "debit",
        amount: 29000,
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OCJw6UtozTdsRAdP96vS",
      },
      {
        contact: "OCJw6UtozTdsRAdP96vS",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 29000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        comment: "",
      },
    ],
    memo: "Paying Off Credit Card",
    journalEntry: false,
    addTimestamp: false,
    id: "frYalztuR0izqU3Nguug",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 522000000,
    },
    date: "2023-02-28T08:00:00.000Z",
    batch: true,
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    memo: "Rent Payment",
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 398000000,
    },
    date: "2023-03-05T08:00:00.000Z",
    checkNumber: false,
    id: "LoVOR647MC4xAOhcYlz5",
    addTimestamp: false,
    imported: true,
    lines: [
      {
        comment: "",
        account: "YpmAvvqO0ydpUqv83hF8",
        sign: "debit",
        amount: 80000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
      },
      {
        amount: 80000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
  },
  {
    imported: true,
    id: "VQMoA9oYvappf63sRUNW",
    memo: "Imported on 12/22/2023",
    date: "2023-03-10T08:00:00.000Z",
    checkNumber: false,
    addTimestamp: false,
    batch: true,
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267544,
      nanoseconds: 159000000,
    },
    lines: [
      {
        account: "GC7FsrkuC9p0y6FHMeXf",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        sign: "debit",
        comment: "",
        amount: 1000,
      },
      {
        sign: "credit",
        amount: 1000,
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        comment: "",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267544,
      nanoseconds: 971000000,
    },
    addTimestamp: false,
    imported: true,
    date: "2023-03-12T08:00:00.000Z",
    memo: "Building Maintenance",
    lines: [
      {
        sign: "debit",
        amount: 50000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "aOstuF6UV7KLp7KATd15",
        comment: "",
        account: "61etq94A1WKwv9iYhK6r",
      },
      {
        sign: "credit",
        comment: "",
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "aOstuF6UV7KLp7KATd15",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 50000,
      },
    ],
    id: "RHMMIVVcgWIeSaaQKI9x",
    checkNumber: false,
  },
  {
    id: "K96k4R2TDQlUlPJsapkQ",
    checkNumber: false,
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    memo: "Donations Deposit",
    date: "2023-03-14T07:00:00.000Z",
    journalEntry: false,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 783000000,
    },
    lines: [
      {
        comment: "general donations",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 200000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "6JnJdulQfxVjCHsRAowz",
        sign: "credit",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "building donations",
        amount: 100000,
        account: "6JnJdulQfxVjCHsRAowz",
        sign: "credit",
      },
      {
        sign: "debit",
        amount: 200000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        amount: 100000,
      },
    ],
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        contact: "W5yEd1Ik3IJh3gyIMa12",
        account: "BLRxrwoLIlKY6bs6hFFg",
        amount: 40000,
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "debit",
      },
      {
        sign: "credit",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    batch: true,
    id: "cZJ7QXDVxUIctUWW1qUP",
    memo: "Supplies for Grant",
    imported: true,
    date: "2023-03-15T07:00:00.000Z",
    addTimestamp: false,
    journalEntry: false,
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 374000000,
    },
  },
  {
    id: "5zagRBx19665mq8Pe8B9",
    date: "2023-03-20T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 908000000,
    },
    checkNumber: false,
    imported: true,
    addTimestamp: false,
    memo: "Best Software Ever",
    journalEntry: false,
    lines: [
      {
        account: "wTNOOyhANlDzE2HHXUZd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "65g38BLuK4213O183cWh",
        amount: 3000,
        sign: "debit",
        comment: "",
      },
      {
        account: "WdxB1CUpp7MroxSNTAoE",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "65g38BLuK4213O183cWh",
        amount: 3000,
        sign: "credit",
      },
    ],
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    id: "OTnZjmpRvUFbVWXpWuYm",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 75000000,
    },
    batch: true,
    memo: "Accounting Services",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    imported: true,
    lines: [
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        amount: 25000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        account: "3nLtYOnsMUhZchI6ODb4",
      },
      {
        amount: 25000,
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
    ],
    date: "2023-03-20T07:00:00.000Z",
    addTimestamp: false,
    checkNumber: false,
  },
  {
    id: "vjIUqqe3EiiKsr8ytapX",
    batch: true,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    memo: "Monthly Salaries",
    lines: [
      {
        amount: 100000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "9j8bIW5jBY8rMzU31j9h",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "debit",
      },
      {
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "IVcNfMTIBeAzUkqpU1Eg",
        amount: 10000,
        comment: "",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        sign: "debit",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "fX3btsviWqR00GywOQ5W",
        comment: "",
      },
      {
        amount: 110000,
        sign: "credit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "o1KVIlm3AFXVX00vXMXW",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
      },
    ],
    date: "2023-03-25T07:00:00.000Z",
    journalEntry: false,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 826000000,
    },
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 428000000,
    },
    imported: true,
    id: "5JTZ3CfzA5Rh3eo5i4yi",
    journalEntry: false,
    date: "2023-03-28T07:00:00.000Z",
    memo: "Paying Off Credit Card",
    checkNumber: false,
    batch: true,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "OCJw6UtozTdsRAdP96vS",
        sign: "debit",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 29000,
      },
      {
        sign: "credit",
        contact: "OCJw6UtozTdsRAdP96vS",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        amount: 29000,
      },
    ],
  },
  {
    imported: true,
    memo: "Monthly Transfer",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 715000000,
    },
    journalEntry: false,
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "eXfWDW0DotIL5FQTibmx",
        amount: 20000,
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        sign: "debit",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 20000,
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
      },
    ],
    addTimestamp: false,
    date: "2023-03-28T07:00:00.000Z",
    id: "GRkr7PMP053VObfTLJvQ",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    checkNumber: false,
  },
  {
    journalEntry: false,
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 473000000,
    },
    date: "2023-03-28T07:00:00.000Z",
    addTimestamp: false,
    lines: [
      {
        sign: "debit",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "o1KVIlm3AFXVX00vXMXW",
        contact: "jN4Os0oEAeUkQkvyRqo3",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        sign: "credit",
        comment: "",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    memo: "Benefits Payment",
    checkNumber: false,
    id: "jX7hTEZrYQfABaTwh93v",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
  },
  {
    addTimestamp: false,
    id: "qvu7XpmjkZKm8MD5sR49",
    journalEntry: false,
    batch: true,
    imported: true,
    memo: "Monthly Interest",
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "vDIp8A77y0rS4KyNwaSM",
        amount: 500,
      },
      {
        amount: 500,
        account: "HcENBgtb85Cej4nruFVV",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
      },
    ],
    date: "2023-03-28T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 951000000,
    },
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 229000000,
    },
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        sign: "credit",
        account: "b4NSrV18NpQIG3O81Wih",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
      },
      {
        sign: "debit",
        amount: 50000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
      },
    ],
    memo: "Grant Deposit",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "ujlOB3y0E4eodK5pRAE9",
    imported: true,
    addTimestamp: false,
    journalEntry: false,
    batch: true,
    date: "2023-03-28T07:00:00.000Z",
  },
  {
    date: "2023-04-05T07:00:00.000Z",
    memo: "Rent Payment",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267546,
      nanoseconds: 21000000,
    },
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    imported: true,
    journalEntry: false,
    lines: [
      {
        sign: "debit",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "YpmAvvqO0ydpUqv83hF8",
        amount: 80000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        amount: 80000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
      },
    ],
    id: "0Ur656hKLnFo3Gbyn2YM",
  },
  {
    batch: true,
    memo: "Imported on 12/22/2023",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267544,
      nanoseconds: 153000000,
    },
    id: "0Cqdpj6piAd9klWT5Usc",
    addTimestamp: false,
    journalEntry: false,
    date: "2023-04-10T07:00:00.000Z",
    checkNumber: false,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 1000,
        contact: "denmDL0Cc3abQPyTmas3",
        comment: "",
        sign: "debit",
        account: "GC7FsrkuC9p0y6FHMeXf",
      },
      {
        amount: 1000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 481000000,
    },
    lines: [
      {
        account: "61etq94A1WKwv9iYhK6r",
        sign: "debit",
        amount: 50000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "aOstuF6UV7KLp7KATd15",
        comment: "",
      },
      {
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 50000,
      },
    ],
    batch: true,
    journalEntry: false,
    checkNumber: false,
    memo: "Building Maintenance",
    date: "2023-04-12T23:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "dZIZiIvlMSjgyds3Nml8",
    addTimestamp: false,
    imported: true,
  },
  {
    memo: "Donations Deposit",
    addTimestamp: false,
    date: "2023-04-14T07:00:00.000Z",
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "SCjXX5YXCoHv5AWMD03M",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 234000000,
    },
    lines: [
      {
        comment: "general donations",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 200000,
        account: "6JnJdulQfxVjCHsRAowz",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "6JnJdulQfxVjCHsRAowz",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 100000,
        sign: "credit",
        comment: "building donations",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        amount: 200000,
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 100000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    checkNumber: false,
    journalEntry: false,
    batch: true,
  },
  {
    date: "2023-04-15T23:00:00.000Z",
    checkNumber: false,
    addTimestamp: false,
    batch: true,
    memo: "Supplies for Grant",
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        account: "BLRxrwoLIlKY6bs6hFFg",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        sign: "debit",
      },
      {
        comment: "",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    id: "7UDvOUQmJH7lsoK4isFZ",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 105000000,
    },
    imported: true,
  },
  {
    imported: true,
    addTimestamp: false,
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-04-20T23:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 683000000,
    },
    id: "FF0eN2qAGVaD91saqR3c",
    journalEntry: false,
    checkNumber: false,
    lines: [
      {
        amount: 3000,
        account: "wTNOOyhANlDzE2HHXUZd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "65g38BLuK4213O183cWh",
        sign: "debit",
      },
      {
        sign: "credit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "65g38BLuK4213O183cWh",
        amount: 3000,
      },
    ],
    memo: "Best Software Ever",
  },
  {
    memo: "Accounting Services",
    batch: true,
    checkNumber: false,
    addTimestamp: false,
    journalEntry: false,
    imported: true,
    date: "2023-04-20T23:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "WRgBZFusl9lEWVB8QJuD",
    lines: [
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "debit",
        account: "3nLtYOnsMUhZchI6ODb4",
        amount: 25000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        sign: "credit",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
        comment: "",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 578000000,
    },
  },
  {
    addTimestamp: false,
    memo: "Monthly Salaries",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 261000000,
    },
    checkNumber: false,
    date: "2023-04-25T07:00:00.000Z",
    lines: [
      {
        comment: "",
        account: "9j8bIW5jBY8rMzU31j9h",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 100000,
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        account: "IVcNfMTIBeAzUkqpU1Eg",
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 10000,
      },
      {
        sign: "debit",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "fX3btsviWqR00GywOQ5W",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 110000,
        comment: "",
        sign: "credit",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        sign: "credit",
        account: "o1KVIlm3AFXVX00vXMXW",
        amount: 10000,
      },
    ],
    imported: true,
    journalEntry: false,
    id: "VycjGgsr1QcOPShewFwc",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 524000000,
    },
    date: "2023-04-28T07:00:00.000Z",
    imported: true,
    batch: true,
    memo: "Benefits Payment",
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lines: [
      {
        sign: "debit",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "o1KVIlm3AFXVX00vXMXW",
        comment: "",
        contact: "jN4Os0oEAeUkQkvyRqo3",
      },
      {
        amount: 10000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    id: "33r6CO59ijb8N4qLGC0c",
    checkNumber: false,
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 795000000,
    },
    id: "HxwykyHS9BDVWKMw3IYR",
    date: "2023-04-28T07:00:00.000Z",
    checkNumber: false,
    journalEntry: false,
    batch: true,
    addTimestamp: false,
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Monthly Interest",
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 500,
        comment: "",
        account: "vDIp8A77y0rS4KyNwaSM",
        sign: "credit",
      },
      {
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 500,
      },
    ],
  },
  {
    lines: [
      {
        comment: "",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OCJw6UtozTdsRAdP96vS",
        amount: 29000,
        account: "WdxB1CUpp7MroxSNTAoE",
      },
      {
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 29000,
        contact: "OCJw6UtozTdsRAdP96vS",
      },
    ],
    journalEntry: false,
    id: "Xam9wbPaquPIESIZUpzL",
    addTimestamp: false,
    checkNumber: false,
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 230000000,
    },
    date: "2023-04-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    memo: "Paying Off Credit Card",
  },
  {
    checkNumber: false,
    id: "lFe9P3YJbCkCMKcaFzYa",
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
        account: "b4NSrV18NpQIG3O81Wih",
      },
      {
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 50000,
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 776000000,
    },
    addTimestamp: false,
    date: "2023-04-28T07:00:00.000Z",
    imported: true,
    batch: true,
    memo: "Grant Deposit",
  },
  {
    memo: "Monthly Transfer",
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "obNj8M1kWThcYy72J5uO",
    addTimestamp: false,
    journalEntry: false,
    imported: true,
    date: "2023-04-28T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267544,
      nanoseconds: 116000000,
    },
    batch: true,
    lines: [
      {
        sign: "debit",
        amount: 20000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
      },
      {
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
        amount: 20000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
  },
  {
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 254000000,
    },
    memo: "Rent Payment",
    batch: true,
    lines: [
      {
        sign: "debit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "YpmAvvqO0ydpUqv83hF8",
        amount: 80000,
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        comment: "",
        amount: 80000,
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    date: "2023-05-05T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    journalEntry: false,
    imported: true,
    id: "F398GYXc9bcKx7edD1Qv",
  },
  {
    id: "YahLJ7TvGRAjl6pGDUy8",
    lastChangeTimestamp: {
      seconds: 1703267546,
      nanoseconds: 660000000,
    },
    addTimestamp: false,
    imported: true,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 1000,
        sign: "debit",
        contact: "denmDL0Cc3abQPyTmas3",
        account: "GC7FsrkuC9p0y6FHMeXf",
        comment: "",
      },
      {
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "denmDL0Cc3abQPyTmas3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 1000,
      },
    ],
    memo: "Imported on 12/22/2023",
    journalEntry: false,
    date: "2023-05-10T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    checkNumber: false,
  },
  {
    addTimestamp: false,
    journalEntry: false,
    batch: true,
    imported: true,
    lines: [
      {
        account: "61etq94A1WKwv9iYhK6r",
        sign: "debit",
        contact: "aOstuF6UV7KLp7KATd15",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        amount: 50000,
      },
      {
        contact: "aOstuF6UV7KLp7KATd15",
        comment: "",
        amount: 50000,
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    date: "2023-05-12T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    id: "56QrQNkXmXvETwcH31Vn",
    memo: "Building Maintenance",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 66000000,
    },
  },
  {
    batch: true,
    date: "2023-05-14T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 143000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        sign: "credit",
        amount: 200000,
        account: "6JnJdulQfxVjCHsRAowz",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "general donations",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        account: "6JnJdulQfxVjCHsRAowz",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "WWfUVTCdovlEqYuKWBzO",
        sign: "credit",
        comment: "building donations",
        amount: 100000,
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        amount: 200000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
      },
      {
        amount: 100000,
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    addTimestamp: false,
    imported: true,
    journalEntry: false,
    id: "fE0oTCh546k1tDOStE1Q",
    checkNumber: false,
    memo: "Donations Deposit",
  },
  {
    id: "RiCq2iGB3QbEfNge8WTp",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    journalEntry: false,
    date: "2023-05-15T07:00:00.000Z",
    imported: true,
    memo: "Supplies for Grant",
    addTimestamp: false,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "BLRxrwoLIlKY6bs6hFFg",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        comment: "",
        amount: 40000,
        sign: "debit",
      },
      {
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        fund: "0y8FCh2cefSnhhZJxEJ8",
      },
    ],
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 574000000,
    },
  },
  {
    lines: [
      {
        account: "wTNOOyhANlDzE2HHXUZd",
        contact: "65g38BLuK4213O183cWh",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 3000,
      },
      {
        amount: 3000,
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        sign: "credit",
        contact: "65g38BLuK4213O183cWh",
      },
    ],
    memo: "Best Software Ever",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 278000000,
    },
    checkNumber: false,
    journalEntry: false,
    addTimestamp: false,
    id: "WwpzAlC7TRjK662LFCRR",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    date: "2023-05-20T07:00:00.000Z",
  },
  {
    date: "2023-05-20T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267537,
      nanoseconds: 670000000,
    },
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "k60228ys7jNT3uwPfvyn",
    imported: true,
    checkNumber: false,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "3nLtYOnsMUhZchI6ODb4",
        amount: 25000,
        comment: "",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "debit",
      },
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 25000,
      },
    ],
    addTimestamp: false,
    batch: true,
    memo: "Accounting Services",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 552000000,
    },
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "ApmM1Z1ZOkmX7c9uOSor",
    addTimestamp: false,
    batch: true,
    lines: [
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 100000,
        account: "9j8bIW5jBY8rMzU31j9h",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "IVcNfMTIBeAzUkqpU1Eg",
        sign: "debit",
        amount: 10000,
      },
      {
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 10000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "fX3btsviWqR00GywOQ5W",
      },
      {
        sign: "credit",
        comment: "",
        amount: 110000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        sign: "credit",
        account: "o1KVIlm3AFXVX00vXMXW",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
      },
    ],
    journalEntry: false,
    checkNumber: false,
    memo: "Monthly Salaries",
    date: "2023-05-25T07:00:00.000Z",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 984000000,
    },
    addTimestamp: false,
    memo: "Monthly Interest",
    batch: true,
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    lines: [
      {
        comment: "",
        account: "vDIp8A77y0rS4KyNwaSM",
        amount: 500,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 500,
      },
    ],
    date: "2023-05-28T07:00:00.000Z",
    id: "4uAYcmEYo8xRFxIsK3P2",
    journalEntry: false,
  },
  {
    imported: true,
    id: "BgnfDgs0YpxAcG00POka",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 965000000,
    },
    checkNumber: false,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
        account: "b4NSrV18NpQIG3O81Wih",
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
      },
      {
        amount: 50000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "debit",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
      },
    ],
    batch: true,
    memo: "Grant Deposit",
    date: "2023-05-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
  },
  {
    id: "gJ0pceUuZvelCMXS9yuJ",
    checkNumber: false,
    journalEntry: false,
    date: "2023-05-28T07:00:00.000Z",
    lines: [
      {
        account: "o1KVIlm3AFXVX00vXMXW",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        comment: "",
        sign: "debit",
        contact: "jN4Os0oEAeUkQkvyRqo3",
      },
      {
        comment: "",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 282000000,
    },
    imported: true,
    memo: "Benefits Payment",
  },
  {
    id: "oedTdBz0fi70G7SO4Ni0",
    checkNumber: false,
    imported: true,
    date: "2023-05-28T07:00:00.000Z",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 687000000,
    },
    lines: [
      {
        amount: 20000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "debit",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "eXfWDW0DotIL5FQTibmx",
        comment: "",
        amount: 20000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        sign: "credit",
      },
    ],
    batch: true,
    memo: "Monthly Transfer",
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "sQxX6uuSlsu5W6FZPfhf",
    imported: true,
    batch: true,
    checkNumber: false,
    lines: [
      {
        sign: "debit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 29000,
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "OCJw6UtozTdsRAdP96vS",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 29000,
        contact: "OCJw6UtozTdsRAdP96vS",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 616000000,
    },
    journalEntry: false,
    memo: "Paying Off Credit Card",
    addTimestamp: false,
    date: "2023-05-28T07:00:00.000Z",
  },
  {
    memo: "Rent Payment",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 811000000,
    },
    addTimestamp: false,
    id: "l8Zt3mCrnJGCw0WY27pC",
    lines: [
      {
        contact: "OiksEVPKL7DJvbwWFYQ0",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 80000,
        comment: "",
        account: "YpmAvvqO0ydpUqv83hF8",
        sign: "debit",
      },
      {
        amount: 80000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
      },
    ],
    imported: true,
    date: "2023-06-05T07:00:00.000Z",
    checkNumber: false,
    batch: true,
  },
  {
    batch: true,
    checkNumber: false,
    id: "JPnbgnuYTdLYdlXy1oZe",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 598000000,
    },
    date: "2023-06-10T07:00:00.000Z",
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        account: "GC7FsrkuC9p0y6FHMeXf",
        amount: 1000,
        contact: "denmDL0Cc3abQPyTmas3",
      },
      {
        sign: "credit",
        contact: "denmDL0Cc3abQPyTmas3",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 1000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    addTimestamp: false,
    memo: "Imported on 12/22/2023",
    journalEntry: false,
  },
  {
    addTimestamp: false,
    date: "2023-06-12T07:00:00.000Z",
    batch: true,
    journalEntry: false,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 643000000,
    },
    memo: "Building Maintenance",
    lines: [
      {
        account: "61etq94A1WKwv9iYhK6r",
        contact: "aOstuF6UV7KLp7KATd15",
        sign: "debit",
        amount: 50000,
        comment: "",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        amount: 50000,
        contact: "aOstuF6UV7KLp7KATd15",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        fund: "WWfUVTCdovlEqYuKWBzO",
        sign: "credit",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "DdeWhs1onjAhyvTBdokV",
    checkNumber: false,
  },
  {
    id: "wrqILyDxXnAHUCQaBjYN",
    imported: true,
    memo: "Donations Deposit",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 994000000,
    },
    date: "2023-06-14T07:00:00.000Z",
    lines: [
      {
        comment: "general donations",
        account: "6JnJdulQfxVjCHsRAowz",
        amount: 200000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        account: "6JnJdulQfxVjCHsRAowz",
        comment: "building donations",
        sign: "credit",
        amount: 100000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 200000,
      },
      {
        amount: 100000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    journalEntry: false,
    checkNumber: false,
  },
  {
    batch: true,
    memo: "Supplies for Grant",
    checkNumber: false,
    date: "2023-06-15T07:00:00.000Z",
    imported: true,
    addTimestamp: false,
    id: "EWRalvNLhAStgFXnJYRv",
    lines: [
      {
        comment: "",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        amount: 40000,
        sign: "debit",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "BLRxrwoLIlKY6bs6hFFg",
      },
      {
        sign: "credit",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        amount: 40000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "W5yEd1Ik3IJh3gyIMa12",
      },
    ],
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 515000000,
    },
  },
  {
    checkNumber: false,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 531000000,
    },
    id: "2un4dX9qp35Z1BeOgcmr",
    memo: "Best Software Ever",
    batch: true,
    journalEntry: false,
    imported: true,
    date: "2023-06-20T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        account: "wTNOOyhANlDzE2HHXUZd",
        amount: 3000,
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "65g38BLuK4213O183cWh",
      },
      {
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "65g38BLuK4213O183cWh",
        sign: "credit",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
  },
  {
    memo: "Accounting Services",
    id: "GbUEdJPmiCuoAFAUo3rD",
    batch: true,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-06-20T07:00:00.000Z",
    lines: [
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        account: "3nLtYOnsMUhZchI6ODb4",
        amount: 25000,
        sign: "debit",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 25000,
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
      },
    ],
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 819000000,
    },
    checkNumber: false,
    journalEntry: false,
  },
  {
    batch: true,
    memo: "Monthly Salaries",
    addTimestamp: false,
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 479000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-06-25T07:00:00.000Z",
    imported: true,
    lines: [
      {
        account: "9j8bIW5jBY8rMzU31j9h",
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 100000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        sign: "debit",
        comment: "",
        amount: 10000,
        account: "IVcNfMTIBeAzUkqpU1Eg",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        account: "fX3btsviWqR00GywOQ5W",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "debit",
        comment: "",
        amount: 10000,
      },
      {
        amount: 110000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 10000,
        account: "o1KVIlm3AFXVX00vXMXW",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
    ],
    journalEntry: false,
    id: "hNnYRu9fvs8TtfXbfUl5",
  },
  {
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 83000000,
    },
    id: "044Wxj37magPKnsm529i",
    imported: true,
    journalEntry: false,
    memo: "Benefits Payment",
    date: "2023-06-28T07:00:00.000Z",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    lines: [
      {
        account: "o1KVIlm3AFXVX00vXMXW",
        amount: 10000,
        contact: "jN4Os0oEAeUkQkvyRqo3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        sign: "debit",
      },
      {
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
      },
    ],
  },
  {
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "vDIp8A77y0rS4KyNwaSM",
        amount: 500,
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "HcENBgtb85Cej4nruFVV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 500,
        sign: "debit",
        comment: "",
      },
    ],
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 317000000,
    },
    date: "2023-06-28T07:00:00.000Z",
    checkNumber: false,
    imported: true,
    id: "F1Ec8smTt31hioHdfmjn",
    addTimestamp: false,
    memo: "Monthly Interest",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    batch: true,
    checkNumber: false,
    imported: true,
    memo: "Paying Off Credit Card",
    addTimestamp: false,
    date: "2023-06-28T07:00:00.000Z",
    id: "FKTYHN7oZCpCNSM5njGJ",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lines: [
      {
        amount: 29000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "OCJw6UtozTdsRAdP96vS",
        sign: "debit",
      },
      {
        amount: 29000,
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "OCJw6UtozTdsRAdP96vS",
        comment: "",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 276000000,
    },
  },
  {
    checkNumber: false,
    id: "oiQ9OhIBFlp1i4bpl9xG",
    addTimestamp: false,
    imported: true,
    batch: true,
    memo: "Monthly Transfer",
    journalEntry: false,
    date: "2023-06-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 668000000,
    },
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "HcENBgtb85Cej4nruFVV",
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "debit",
        amount: 20000,
        comment: "",
      },
      {
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "credit",
        comment: "",
        amount: 20000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
    ],
  },
  {
    id: "s51lO1UPQxBGOxb4FEQE",
    addTimestamp: false,
    journalEntry: false,
    memo: "Grant Deposit",
    lines: [
      {
        comment: "",
        sign: "credit",
        account: "b4NSrV18NpQIG3O81Wih",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
      },
      {
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        amount: 50000,
        fund: "0y8FCh2cefSnhhZJxEJ8",
      },
    ],
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 586000000,
    },
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    date: "2023-06-28T07:00:00.000Z",
  },
  {
    journalEntry: false,
    imported: true,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 160000000,
    },
    id: "uqE0LrFLgbjW9NcqOHnP",
    addTimestamp: false,
    batch: true,
    date: "2023-07-05T07:00:00.000Z",
    memo: "Rent Payment",
    lines: [
      {
        amount: 80000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "YpmAvvqO0ydpUqv83hF8",
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        sign: "debit",
      },
      {
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 80000,
        contact: "OiksEVPKL7DJvbwWFYQ0",
      },
    ],
  },
  {
    checkNumber: false,
    journalEntry: false,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 422000000,
    },
    batch: true,
    date: "2023-07-10T07:00:00.000Z",
    addTimestamp: false,
    id: "qKQlGCxjCXVIbUBgTaVb",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Imported on 12/22/2023",
    lines: [
      {
        comment: "",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "GC7FsrkuC9p0y6FHMeXf",
        amount: 1000,
        contact: "denmDL0Cc3abQPyTmas3",
      },
      {
        contact: "denmDL0Cc3abQPyTmas3",
        comment: "",
        amount: 1000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        sign: "credit",
      },
    ],
  },
  {
    memo: "Building Maintenance",
    checkNumber: false,
    batch: true,
    date: "2023-07-12T07:00:00.000Z",
    lines: [
      {
        contact: "aOstuF6UV7KLp7KATd15",
        account: "61etq94A1WKwv9iYhK6r",
        amount: 50000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        sign: "debit",
      },
      {
        amount: 50000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
      },
    ],
    id: "y7bwVwhgB96cpkzSk3P0",
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 75000000,
    },
    imported: true,
  },
  {
    date: "2023-07-14T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 707000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    lines: [
      {
        amount: 200000,
        comment: "general donations",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
        account: "6JnJdulQfxVjCHsRAowz",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "6JnJdulQfxVjCHsRAowz",
        amount: 100000,
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "building donations",
      },
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        amount: 200000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 100000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    imported: true,
    journalEntry: false,
    id: "WPdCDuXL65dd8U4zgfUv",
    checkNumber: false,
    memo: "Donations Deposit",
    addTimestamp: false,
  },
  {
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 220000000,
    },
    checkNumber: false,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        amount: 40000,
        account: "BLRxrwoLIlKY6bs6hFFg",
        sign: "debit",
        comment: "",
      },
      {
        sign: "credit",
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-07-15T07:00:00.000Z",
    id: "BvVtkm822vtpqquMfIwV",
    journalEntry: false,
    batch: true,
    addTimestamp: false,
    memo: "Supplies for Grant",
  },
  {
    date: "2023-07-20T07:00:00.000Z",
    journalEntry: false,
    checkNumber: false,
    memo: "Best Software Ever",
    lines: [
      {
        sign: "debit",
        contact: "65g38BLuK4213O183cWh",
        account: "wTNOOyhANlDzE2HHXUZd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 3000,
        comment: "",
      },
      {
        comment: "",
        amount: 3000,
        account: "WdxB1CUpp7MroxSNTAoE",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "65g38BLuK4213O183cWh",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 145000000,
    },
    batch: true,
    addTimestamp: false,
    id: "NWindiU9lhmSXDXUFjL4",
    imported: true,
  },
  {
    lines: [
      {
        account: "3nLtYOnsMUhZchI6ODb4",
        amount: 25000,
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "debit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        comment: "",
        amount: 25000,
        sign: "credit",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
      },
    ],
    addTimestamp: false,
    date: "2023-07-20T07:00:00.000Z",
    memo: "Accounting Services",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 65000000,
    },
    checkNumber: false,
    id: "nXnwn54WT0Xwvp2ypB3e",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
  },
  {
    batch: true,
    checkNumber: false,
    imported: true,
    date: "2023-07-25T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 451000000,
    },
    lines: [
      {
        sign: "debit",
        account: "9j8bIW5jBY8rMzU31j9h",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 100000,
      },
      {
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 10000,
        account: "IVcNfMTIBeAzUkqpU1Eg",
      },
      {
        amount: 10000,
        sign: "debit",
        account: "fX3btsviWqR00GywOQ5W",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
      },
      {
        sign: "credit",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 110000,
      },
      {
        account: "o1KVIlm3AFXVX00vXMXW",
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 10000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Monthly Salaries",
    journalEntry: false,
    id: "7uXtjlDNP8cZZJhrpnN6",
    addTimestamp: false,
  },
  {
    date: "2023-07-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    imported: true,
    batch: true,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 824000000,
    },
    journalEntry: false,
    id: "GmGyE9im7BtY3RzmiDwG",
    memo: "Monthly Interest",
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        amount: 500,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "vDIp8A77y0rS4KyNwaSM",
        comment: "",
      },
      {
        sign: "debit",
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "HcENBgtb85Cej4nruFVV",
        amount: 500,
      },
    ],
  },
  {
    journalEntry: false,
    batch: true,
    id: "bECjeyUm9HyY7eI5yWTd",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 356000000,
    },
    imported: true,
    date: "2023-07-28T07:00:00.000Z",
    checkNumber: false,
    addTimestamp: false,
    memo: "Paying Off Credit Card",
    lines: [
      {
        amount: 29000,
        contact: "OCJw6UtozTdsRAdP96vS",
        comment: "",
        sign: "debit",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 29000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OCJw6UtozTdsRAdP96vS",
        comment: "",
        sign: "credit",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    id: "hL0AEg0Qccql3JzWHOvu",
    addTimestamp: false,
    checkNumber: false,
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 222000000,
    },
    batch: true,
    lines: [
      {
        account: "o1KVIlm3AFXVX00vXMXW",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 10000,
        contact: "jN4Os0oEAeUkQkvyRqo3",
        sign: "debit",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        amount: 10000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
      },
    ],
    date: "2023-07-28T07:00:00.000Z",
    memo: "Benefits Payment",
  },
  {
    date: "2023-07-28T07:00:00.000Z",
    lines: [
      {
        sign: "credit",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
        comment: "",
        account: "b4NSrV18NpQIG3O81Wih",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        amount: 50000,
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "0y8FCh2cefSnhhZJxEJ8",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    addTimestamp: false,
    id: "vVaf7cRBEENDS3wgOWyI",
    journalEntry: false,
    batch: true,
    memo: "Grant Deposit",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 334000000,
    },
    imported: true,
    checkNumber: false,
  },
  {
    lines: [
      {
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
        amount: 20000,
        sign: "debit",
        account: "HcENBgtb85Cej4nruFVV",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        amount: 20000,
        contact: "eXfWDW0DotIL5FQTibmx",
      },
    ],
    journalEntry: false,
    id: "wZPMgrnoDDHS2TNngzSZ",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Monthly Transfer",
    date: "2023-07-28T07:00:00.000Z",
    checkNumber: false,
    addTimestamp: false,
    batch: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 330000000,
    },
    imported: true,
  },
  {
    addTimestamp: false,
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267537,
      nanoseconds: 881000000,
    },
    batch: true,
    id: "gmAH8xSHkqACAIsbvLQ3",
    date: "2023-08-05T07:00:00.000Z",
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        comment: "",
        account: "YpmAvvqO0ydpUqv83hF8",
        amount: 80000,
        sign: "debit",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        amount: 80000,
        comment: "",
      },
    ],
    imported: true,
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Rent Payment",
  },
  {
    batch: true,
    id: "ib570TOIKiO1k38xkRMy",
    journalEntry: false,
    lines: [
      {
        amount: 1000,
        account: "GC7FsrkuC9p0y6FHMeXf",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        sign: "debit",
      },
      {
        contact: "denmDL0Cc3abQPyTmas3",
        comment: "",
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 1000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    imported: true,
    date: "2023-08-10T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    memo: "Imported on 12/22/2023",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 6000000,
    },
  },
  {
    memo: "Building Maintenance",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 303000000,
    },
    addTimestamp: false,
    id: "0Cdx4FOphBfz8VJvLLzw",
    imported: true,
    date: "2023-08-12T07:00:00.000Z",
    lines: [
      {
        account: "61etq94A1WKwv9iYhK6r",
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
        amount: 50000,
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 50000,
        contact: "aOstuF6UV7KLp7KATd15",
        sign: "credit",
        comment: "",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
    ],
    journalEntry: false,
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267546,
      nanoseconds: 92000000,
    },
    lines: [
      {
        comment: "general donations",
        amount: 200000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "6JnJdulQfxVjCHsRAowz",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "6JnJdulQfxVjCHsRAowz",
        sign: "credit",
        amount: 100000,
        comment: "building donations",
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        sign: "debit",
        amount: 200000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        comment: "",
        amount: 100000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
    checkNumber: false,
    journalEntry: false,
    imported: true,
    batch: true,
    id: "St8ti63PJNQfJIJpuncx",
    memo: "Donations Deposit",
    date: "2023-08-14T07:00:00.000Z",
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    checkNumber: false,
    date: "2023-08-15T07:00:00.000Z",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 665000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "BLRxrwoLIlKY6bs6hFFg",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        amount: 40000,
        sign: "debit",
        comment: "",
      },
      {
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        amount: 40000,
      },
    ],
    batch: true,
    addTimestamp: false,
    memo: "Supplies for Grant",
    id: "S2b5uLHt8txe18pPVJkr",
    journalEntry: false,
  },
  {
    batch: true,
    date: "2023-08-20T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 238000000,
    },
    journalEntry: false,
    imported: true,
    checkNumber: false,
    id: "m9mXwzcSjCS9lLjJZYNy",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        sign: "debit",
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "wTNOOyhANlDzE2HHXUZd",
        contact: "65g38BLuK4213O183cWh",
      },
      {
        comment: "",
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        sign: "credit",
      },
    ],
    memo: "Best Software Ever",
    addTimestamp: false,
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "3nLtYOnsMUhZchI6ODb4",
        sign: "debit",
        amount: 25000,
      },
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
        sign: "credit",
        account: "WdxB1CUpp7MroxSNTAoE",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
      },
    ],
    checkNumber: false,
    id: "mMxx6DKJcwabmCV9VmyM",
    memo: "Accounting Services",
    journalEntry: false,
    batch: true,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 797000000,
    },
    addTimestamp: false,
    date: "2023-08-20T07:00:00.000Z",
  },
  {
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 814000000,
    },
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        comment: "",
        account: "9j8bIW5jBY8rMzU31j9h",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 100000,
      },
      {
        comment: "",
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "IVcNfMTIBeAzUkqpU1Eg",
      },
      {
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "fX3btsviWqR00GywOQ5W",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 10000,
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 110000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "o1KVIlm3AFXVX00vXMXW",
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "credit",
      },
    ],
    batch: true,
    addTimestamp: false,
    memo: "Monthly Salaries",
    id: "0Gga9h9dbzxICp6l4Bw6",
    date: "2023-08-25T07:00:00.000Z",
    imported: true,
  },
  {
    imported: true,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 148000000,
    },
    date: "2023-08-28T07:00:00.000Z",
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
        comment: "",
        account: "b4NSrV18NpQIG3O81Wih",
        sign: "credit",
      },
      {
        amount: 50000,
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    batch: true,
    checkNumber: false,
    memo: "Grant Deposit",
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "4fBFiPd1e80B015O1K3b",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        sign: "debit",
        account: "o1KVIlm3AFXVX00vXMXW",
        comment: "",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        comment: "",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "jN4Os0oEAeUkQkvyRqo3",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 305000000,
    },
    imported: true,
    journalEntry: false,
    date: "2023-08-28T07:00:00.000Z",
    checkNumber: false,
    id: "by9uSpIOWzCyXflUBIRO",
    addTimestamp: false,
    memo: "Benefits Payment",
    batch: true,
  },
  {
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "cKQzkAswfVOoQ65ykCfV",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 460000000,
    },
    imported: true,
    memo: "Paying Off Credit Card",
    date: "2023-08-28T07:00:00.000Z",
    checkNumber: false,
    journalEntry: false,
    lines: [
      {
        comment: "",
        sign: "debit",
        contact: "OCJw6UtozTdsRAdP96vS",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 29000,
      },
      {
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "OCJw6UtozTdsRAdP96vS",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 29000,
      },
    ],
    addTimestamp: false,
  },
  {
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 899000000,
    },
    id: "j87hYpIbIQw1nvHqI6go",
    memo: "Monthly Interest",
    addTimestamp: false,
    batch: true,
    checkNumber: false,
    date: "2023-08-28T07:00:00.000Z",
    lines: [
      {
        account: "vDIp8A77y0rS4KyNwaSM",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        amount: 500,
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        account: "HcENBgtb85Cej4nruFVV",
        comment: "",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 500,
      },
    ],
  },
  {
    date: "2023-08-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "eXfWDW0DotIL5FQTibmx",
        comment: "",
        account: "HcENBgtb85Cej4nruFVV",
        amount: 20000,
        sign: "debit",
      },
      {
        amount: 20000,
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "eXfWDW0DotIL5FQTibmx",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
      },
    ],
    batch: true,
    id: "zq8i4vLtWr2Y8TH0rtdH",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 781000000,
    },
    imported: true,
    checkNumber: false,
    addTimestamp: false,
    memo: "Monthly Transfer",
  },
  {
    addTimestamp: false,
    checkNumber: false,
    date: "2023-09-05T07:00:00.000Z",
    memo: "Rent Payment",
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 45000000,
    },
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 80000,
        account: "YpmAvvqO0ydpUqv83hF8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        comment: "",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        sign: "credit",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        amount: 80000,
      },
    ],
    id: "ZDy6UXpJ6iahOw2jjMSu",
    imported: true,
    journalEntry: false,
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 262000000,
    },
    addTimestamp: false,
    batch: true,
    checkNumber: false,
    memo: "Imported on 12/22/2023",
    lines: [
      {
        sign: "debit",
        account: "GC7FsrkuC9p0y6FHMeXf",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        amount: 1000,
        comment: "",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        comment: "",
        amount: 1000,
        contact: "denmDL0Cc3abQPyTmas3",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-09-10T07:00:00.000Z",
    id: "n1nQogIcEcGXiwFdh3Ii",
    journalEntry: false,
  },
  {
    lines: [
      {
        sign: "debit",
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
        account: "61etq94A1WKwv9iYhK6r",
        amount: 50000,
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 50000,
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 33000000,
    },
    addTimestamp: false,
    batch: true,
    journalEntry: false,
    imported: true,
    memo: "Building Maintenance",
    id: "deirb98gGKzGU97KhgRa",
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-09-12T07:00:00.000Z",
  },
  {
    batch: true,
    memo: "Donations Deposit",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    addTimestamp: false,
    lines: [
      {
        account: "6JnJdulQfxVjCHsRAowz",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "general donations",
        sign: "credit",
        amount: 200000,
      },
      {
        account: "6JnJdulQfxVjCHsRAowz",
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 100000,
        comment: "building donations",
      },
      {
        sign: "debit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 200000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
      {
        amount: 100000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        sign: "debit",
      },
    ],
    id: "KKrsk6rYbdjpQ4GTaTFg",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 727000000,
    },
    journalEntry: false,
    checkNumber: false,
    date: "2023-09-14T07:00:00.000Z",
  },
  {
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Supplies for Grant",
    checkNumber: false,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        comment: "",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
        sign: "debit",
        account: "BLRxrwoLIlKY6bs6hFFg",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        amount: 40000,
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        sign: "credit",
      },
    ],
    id: "smDgG19wzvP5W4bb9mgp",
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 776000000,
    },
    batch: true,
    imported: true,
    date: "2023-09-15T07:00:00.000Z",
  },
  {
    batch: true,
    memo: "Accounting Services",
    journalEntry: false,
    addTimestamp: false,
    date: "2023-09-20T07:00:00.000Z",
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        account: "3nLtYOnsMUhZchI6ODb4",
        sign: "debit",
        comment: "",
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        comment: "",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        amount: 25000,
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    id: "CjfA4npTtOamIeoiVzFU",
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 840000000,
    },
  },
  {
    checkNumber: false,
    memo: "Best Software Ever",
    imported: true,
    date: "2023-09-20T07:00:00.000Z",
    lines: [
      {
        contact: "65g38BLuK4213O183cWh",
        comment: "",
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        account: "wTNOOyhANlDzE2HHXUZd",
      },
      {
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        sign: "credit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "N4NXVcARDCCorwJVIfyF",
    journalEntry: false,
    batch: true,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 68000000,
    },
  },
  {
    addTimestamp: false,
    journalEntry: false,
    lines: [
      {
        amount: 100000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "9j8bIW5jBY8rMzU31j9h",
        sign: "debit",
      },
      {
        sign: "debit",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        account: "IVcNfMTIBeAzUkqpU1Eg",
        contact: "Iat2RXc1OK1JG21s26Gd",
      },
      {
        account: "fX3btsviWqR00GywOQ5W",
        comment: "",
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "credit",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 110000,
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        account: "o1KVIlm3AFXVX00vXMXW",
        amount: 10000,
      },
    ],
    date: "2023-09-25T07:00:00.000Z",
    memo: "Monthly Salaries",
    checkNumber: false,
    id: "htkY8dWkI3DzyzjLMgwP",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 203000000,
    },
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    date: "2023-09-28T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267539,
      nanoseconds: 759000000,
    },
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        sign: "credit",
        account: "vDIp8A77y0rS4KyNwaSM",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 500,
      },
      {
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
        amount: 500,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "HcENBgtb85Cej4nruFVV",
      },
    ],
    id: "80CowZiAfvFQLmi3pYct",
    imported: true,
    checkNumber: false,
    addTimestamp: false,
    memo: "Monthly Interest",
    journalEntry: false,
  },
  {
    checkNumber: false,
    journalEntry: false,
    memo: "Paying Off Credit Card",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    batch: true,
    date: "2023-09-28T07:00:00.000Z",
    id: "As28ikE65h0JocAObNO8",
    lines: [
      {
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 29000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        comment: "",
        contact: "OCJw6UtozTdsRAdP96vS",
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 29000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "OCJw6UtozTdsRAdP96vS",
      },
    ],
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 88000000,
    },
    addTimestamp: false,
  },
  {
    id: "GLYLc7z6bsNagyqdNtV5",
    lines: [
      {
        amount: 20000,
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        amount: 20000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        comment: "",
        contact: "eXfWDW0DotIL5FQTibmx",
      },
    ],
    memo: "Monthly Transfer",
    checkNumber: false,
    batch: true,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 770000000,
    },
    journalEntry: false,
    date: "2023-09-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 745000000,
    },
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    id: "SfRCvjiGnZukeiUwyjQZ",
    memo: "Grant Deposit",
    lines: [
      {
        sign: "credit",
        account: "b4NSrV18NpQIG3O81Wih",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
      },
      {
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
        comment: "",
        sign: "debit",
      },
    ],
    journalEntry: false,
    checkNumber: false,
    date: "2023-09-28T07:00:00.000Z",
    addTimestamp: false,
  },
  {
    lines: [
      {
        sign: "debit",
        account: "o1KVIlm3AFXVX00vXMXW",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        sign: "credit",
        amount: 10000,
        comment: "",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    memo: "Benefits Payment",
    checkNumber: false,
    imported: true,
    batch: true,
    date: "2023-09-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 338000000,
    },
    id: "cq5V5UAj2eJGeLzpxqhb",
    addTimestamp: false,
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Rent Payment",
    lines: [
      {
        account: "YpmAvvqO0ydpUqv83hF8",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 80000,
      },
      {
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        amount: 80000,
      },
    ],
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 794000000,
    },
    checkNumber: false,
    date: "2023-10-05T07:00:00.000Z",
    id: "OT6tIExZv1rgYMT9Gxr3",
    imported: true,
    batch: true,
    journalEntry: false,
  },
  {
    checkNumber: false,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 648000000,
    },
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    date: "2023-10-10T07:00:00.000Z",
    memo: "Imported on 12/22/2023",
    lines: [
      {
        account: "GC7FsrkuC9p0y6FHMeXf",
        amount: 1000,
        comment: "",
        sign: "debit",
        contact: "denmDL0Cc3abQPyTmas3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        amount: 1000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        sign: "credit",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    id: "XC1P1E0v15Kh4N2emXBB",
    batch: true,
  },
  {
    batch: true,
    addTimestamp: false,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 168000000,
    },
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "aOstuF6UV7KLp7KATd15",
        amount: 50000,
        comment: "",
        sign: "debit",
        account: "61etq94A1WKwv9iYhK6r",
      },
      {
        sign: "credit",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        contact: "aOstuF6UV7KLp7KATd15",
        amount: 50000,
        fund: "WWfUVTCdovlEqYuKWBzO",
      },
    ],
    memo: "Building Maintenance",
    id: "WS8UxyKpExAnqC07PfOC",
    date: "2023-10-12T07:00:00.000Z",
    journalEntry: false,
    imported: true,
  },
  {
    addTimestamp: false,
    date: "2023-10-14T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    imported: true,
    batch: true,
    memo: "Donations Deposit",
    id: "ifAPlCJJcBewI8guC2LB",
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 539000000,
    },
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "6JnJdulQfxVjCHsRAowz",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
        comment: "general donations",
        amount: 200000,
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "6JnJdulQfxVjCHsRAowz",
        comment: "building donations",
        sign: "credit",
        amount: 100000,
      },
      {
        amount: 200000,
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
      {
        amount: 100000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "rxfzXZnbPqHD7pzI0lfp",
      },
    ],
  },
  {
    batch: true,
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 873000000,
    },
    lines: [
      {
        account: "BLRxrwoLIlKY6bs6hFFg",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        sign: "debit",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        amount: 40000,
        comment: "",
      },
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 40000,
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        contact: "W5yEd1Ik3IJh3gyIMa12",
      },
    ],
    date: "2023-10-15T07:00:00.000Z",
    checkNumber: false,
    memo: "Supplies for Grant",
    addTimestamp: false,
    id: "QkX2M017GuEM4Gpc6R5T",
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    batch: true,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        comment: "",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        amount: 25000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "3nLtYOnsMUhZchI6ODb4",
        sign: "debit",
      },
      {
        account: "WdxB1CUpp7MroxSNTAoE",
        comment: "",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 25000,
      },
    ],
    date: "2023-10-20T07:00:00.000Z",
    journalEntry: false,
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 28000000,
    },
    imported: true,
    memo: "Accounting Services",
    id: "MMG8DaJeqIThcikAPhIm",
  },
  {
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 118000000,
    },
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        contact: "65g38BLuK4213O183cWh",
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        comment: "",
        account: "wTNOOyhANlDzE2HHXUZd",
      },
      {
        sign: "credit",
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    journalEntry: false,
    memo: "Best Software Ever",
    id: "PGHydd81vCYMoISs2Xap",
    date: "2023-10-20T07:00:00.000Z",
    batch: true,
    checkNumber: false,
    imported: true,
  },
  {
    date: "2023-10-25T07:00:00.000Z",
    checkNumber: false,
    lines: [
      {
        sign: "debit",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 100000,
        account: "9j8bIW5jBY8rMzU31j9h",
      },
      {
        sign: "debit",
        account: "IVcNfMTIBeAzUkqpU1Eg",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
      },
      {
        account: "fX3btsviWqR00GywOQ5W",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 10000,
      },
      {
        comment: "",
        amount: 110000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
        account: "o1KVIlm3AFXVX00vXMXW",
      },
    ],
    id: "IVV0I3A1yDAjLiLYIObn",
    imported: true,
    batch: true,
    addTimestamp: false,
    memo: "Monthly Salaries",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 265000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
  },
  {
    lines: [
      {
        contact: "eXfWDW0DotIL5FQTibmx",
        amount: 20000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        sign: "debit",
        comment: "",
        account: "HcENBgtb85Cej4nruFVV",
      },
      {
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "eXfWDW0DotIL5FQTibmx",
        amount: 20000,
      },
    ],
    journalEntry: false,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Monthly Transfer",
    imported: true,
    batch: true,
    date: "2023-10-28T07:00:00.000Z",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 4000000,
    },
    id: "12ksXBWcz8mCgrnGHGZn",
    checkNumber: false,
  },
  {
    checkNumber: false,
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 333000000,
    },
    addTimestamp: false,
    batch: true,
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
        comment: "",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        account: "b4NSrV18NpQIG3O81Wih",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "debit",
        comment: "",
        amount: 50000,
      },
    ],
    memo: "Grant Deposit",
    date: "2023-10-28T07:00:00.000Z",
    id: "Bs7MFERoZ58KnQFzez6x",
  },
  {
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "OCJw6UtozTdsRAdP96vS",
        sign: "debit",
        account: "WdxB1CUpp7MroxSNTAoE",
        comment: "",
        amount: 29000,
      },
      {
        comment: "",
        amount: 29000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "credit",
        contact: "OCJw6UtozTdsRAdP96vS",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 305000000,
    },
    id: "BwNrlDoSsnzXZHrXUiGT",
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    date: "2023-10-28T07:00:00.000Z",
    checkNumber: false,
    addTimestamp: false,
    journalEntry: false,
    memo: "Paying Off Credit Card",
    batch: true,
  },
  {
    batch: true,
    addTimestamp: false,
    id: "HXsjmB3JpqhcSQLwD1kK",
    date: "2023-10-28T07:00:00.000Z",
    memo: "Monthly Interest",
    journalEntry: false,
    lines: [
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 500,
        sign: "credit",
        account: "vDIp8A77y0rS4KyNwaSM",
      },
      {
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 500,
        account: "HcENBgtb85Cej4nruFVV",
      },
    ],
    imported: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 434000000,
    },
  },
  {
    id: "ivFF8GJvXQIo68Fsbalo",
    imported: true,
    memo: "Benefits Payment",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 735000000,
    },
    addTimestamp: false,
    checkNumber: false,
    lines: [
      {
        amount: 10000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "o1KVIlm3AFXVX00vXMXW",
        sign: "debit",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        comment: "",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        sign: "credit",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        amount: 10000,
      },
    ],
    journalEntry: false,
    batch: true,
    date: "2023-10-28T07:00:00.000Z",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    batch: true,
    journalEntry: false,
    date: "2023-11-05T07:00:00.000Z",
    lines: [
      {
        amount: 80000,
        comment: "",
        sign: "debit",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "YpmAvvqO0ydpUqv83hF8",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        comment: "",
        contact: "OiksEVPKL7DJvbwWFYQ0",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 80000,
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    addTimestamp: false,
    checkNumber: false,
    imported: true,
    memo: "Rent Payment",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    id: "Qq08nICiyN7y3zgMyCJp",
    lastChangeTimestamp: {
      seconds: 1703267542,
      nanoseconds: 811000000,
    },
  },
  {
    imported: true,
    batch: true,
    id: "R5mKQ8LNOEJGyi3aJXXQ",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 332000000,
    },
    memo: "Imported on 12/22/2023",
    checkNumber: false,
    date: "2023-11-10T08:00:00.000Z",
    journalEntry: false,
    lines: [
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "denmDL0Cc3abQPyTmas3",
        account: "GC7FsrkuC9p0y6FHMeXf",
        amount: 1000,
        comment: "",
        sign: "debit",
      },
      {
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "WdxB1CUpp7MroxSNTAoE",
        sign: "credit",
        contact: "denmDL0Cc3abQPyTmas3",
        amount: 1000,
      },
    ],
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    imported: true,
    date: "2023-11-12T08:00:00.000Z",
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "aOstuF6UV7KLp7KATd15",
        account: "61etq94A1WKwv9iYhK6r",
        amount: 50000,
        comment: "",
        sign: "debit",
      },
      {
        sign: "credit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        contact: "aOstuF6UV7KLp7KATd15",
        amount: 50000,
      },
    ],
    journalEntry: false,
    id: "WtgzKmt2h9op2vjhw509",
    addTimestamp: false,
    batch: true,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Building Maintenance",
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 208000000,
    },
  },
  {
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 583000000,
    },
    batch: true,
    memo: "Donations Deposit",
    id: "AW2kqqnXKqpoZWKZvPeF",
    date: "2023-11-14T08:00:00.000Z",
    checkNumber: false,
    lines: [
      {
        sign: "credit",
        comment: "general donations",
        amount: 200000,
        account: "6JnJdulQfxVjCHsRAowz",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        comment: "building donations",
        sign: "credit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 100000,
        fund: "WWfUVTCdovlEqYuKWBzO",
        account: "6JnJdulQfxVjCHsRAowz",
      },
      {
        comment: "",
        amount: 200000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "debit",
      },
      {
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 100000,
        sign: "debit",
        fund: "WWfUVTCdovlEqYuKWBzO",
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    imported: true,
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
  },
  {
    lines: [
      {
        amount: 40000,
        sign: "debit",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        contact: "W5yEd1Ik3IJh3gyIMa12",
        account: "BLRxrwoLIlKY6bs6hFFg",
        comment: "",
      },
      {
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        comment: "",
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 40000,
        contact: "W5yEd1Ik3IJh3gyIMa12",
      },
    ],
    batch: true,
    imported: true,
    date: "2023-11-15T08:00:00.000Z",
    checkNumber: false,
    journalEntry: false,
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 268000000,
    },
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    memo: "Supplies for Grant",
    id: "4ex7aflv8LueSeFeG1dl",
    addTimestamp: false,
  },
  {
    batch: true,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    journalEntry: false,
    addTimestamp: false,
    lines: [
      {
        sign: "debit",
        account: "3nLtYOnsMUhZchI6ODb4",
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        amount: 25000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        sign: "credit",
        comment: "",
        account: "WdxB1CUpp7MroxSNTAoE",
        amount: 25000,
        contact: "ZFlSRmK3Nkqyq11BpVc1",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    memo: "Accounting Services",
    date: "2023-11-20T08:00:00.000Z",
    checkNumber: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 645000000,
    },
    id: "9ngxNnNStH1eK1pirGvs",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    checkNumber: false,
    memo: "Best Software Ever",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 371000000,
    },
    addTimestamp: false,
    batch: true,
    lines: [
      {
        contact: "65g38BLuK4213O183cWh",
        account: "wTNOOyhANlDzE2HHXUZd",
        sign: "debit",
        amount: 3000,
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        amount: 3000,
        contact: "65g38BLuK4213O183cWh",
        account: "WdxB1CUpp7MroxSNTAoE",
      },
    ],
    imported: true,
    date: "2023-11-20T08:00:00.000Z",
    id: "xxZ7FXzt9tXOUuIx0yVK",
    journalEntry: false,
  },
  {
    memo: "Monthly Salaries",
    lastChangeTimestamp: {
      seconds: 1703267543,
      nanoseconds: 255000000,
    },
    lines: [
      {
        amount: 100000,
        comment: "",
        contact: "Iat2RXc1OK1JG21s26Gd",
        sign: "debit",
        account: "9j8bIW5jBY8rMzU31j9h",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        sign: "debit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "IVcNfMTIBeAzUkqpU1Eg",
      },
      {
        account: "fX3btsviWqR00GywOQ5W",
        sign: "debit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        comment: "",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 10000,
      },
      {
        sign: "credit",
        contact: "Iat2RXc1OK1JG21s26Gd",
        amount: 110000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
      {
        amount: 10000,
        contact: "Iat2RXc1OK1JG21s26Gd",
        account: "o1KVIlm3AFXVX00vXMXW",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        sign: "credit",
      },
    ],
    checkNumber: false,
    journalEntry: false,
    addTimestamp: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    batch: true,
    id: "9SyiACqi891PkbVOuuMw",
    date: "2023-11-25T08:00:00.000Z",
  },
  {
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267546,
      nanoseconds: 129000000,
    },
    date: "2023-11-28T08:00:00.000Z",
    id: "0GLWjVPP3JzVwqT8m5qo",
    checkNumber: false,
    memo: "Monthly Interest",
    lines: [
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        amount: 500,
        account: "vDIp8A77y0rS4KyNwaSM",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        comment: "",
      },
      {
        comment: "",
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 500,
        fund: "ipkDGFt1ehywSC7UPjZ8",
      },
    ],
    imported: true,
    batch: true,
    journalEntry: false,
    addTimestamp: false,
  },
  {
    batch: true,
    id: "CesXzFyt8yGLpLZgrRvs",
    checkNumber: false,
    date: "2023-11-28T08:00:00.000Z",
    lines: [
      {
        contact: "OCJw6UtozTdsRAdP96vS",
        account: "WdxB1CUpp7MroxSNTAoE",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        sign: "debit",
        amount: 29000,
        comment: "",
      },
      {
        sign: "credit",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        comment: "",
        contact: "OCJw6UtozTdsRAdP96vS",
        amount: 29000,
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    imported: true,
    memo: "Paying Off Credit Card",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    journalEntry: false,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267538,
      nanoseconds: 74000000,
    },
  },
  {
    imported: true,
    memo: "Grant Deposit",
    date: "2023-11-28T08:00:00.000Z",
    id: "IZdqqQ3EIhiJq63chxyx",
    batch: true,
    addTimestamp: false,
    lastChangeTimestamp: {
      seconds: 1703267541,
      nanoseconds: 572000000,
    },
    checkNumber: false,
    journalEntry: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lines: [
      {
        fund: "0y8FCh2cefSnhhZJxEJ8",
        amount: 50000,
        contact: "rxfzXZnbPqHD7pzI0lfp",
        sign: "credit",
        comment: "",
        account: "b4NSrV18NpQIG3O81Wih",
      },
      {
        sign: "debit",
        contact: "rxfzXZnbPqHD7pzI0lfp",
        amount: 50000,
        comment: "",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        fund: "0y8FCh2cefSnhhZJxEJ8",
      },
    ],
  },
  {
    date: "2023-11-28T08:00:00.000Z",
    addTimestamp: false,
    journalEntry: false,
    checkNumber: false,
    lines: [
      {
        sign: "debit",
        comment: "",
        amount: 10000,
        contact: "jN4Os0oEAeUkQkvyRqo3",
        fund: "ipkDGFt1ehywSC7UPjZ8",
        account: "o1KVIlm3AFXVX00vXMXW",
      },
      {
        fund: "ipkDGFt1ehywSC7UPjZ8",
        contact: "jN4Os0oEAeUkQkvyRqo3",
        amount: 10000,
        comment: "",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
      },
    ],
    memo: "Benefits Payment",
    imported: true,
    id: "JriZvjoSG7F794kANcQD",
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    lastChangeTimestamp: {
      seconds: 1703267540,
      nanoseconds: 614000000,
    },
    batch: true,
  },
  {
    batch: true,
    journalEntry: false,
    checkNumber: false,
    userMadeChange: "rhu2KpVGN0g3M5HtJSFfbmy31b52",
    imported: true,
    lastChangeTimestamp: {
      seconds: 1703267537,
      nanoseconds: 384000000,
    },
    lines: [
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "eXfWDW0DotIL5FQTibmx",
        account: "HcENBgtb85Cej4nruFVV",
        sign: "debit",
        amount: 20000,
        comment: "",
      },
      {
        fund: "WWfUVTCdovlEqYuKWBzO",
        contact: "eXfWDW0DotIL5FQTibmx",
        sign: "credit",
        account: "mQ9Z5KPcchYeOP2p2qsV",
        amount: 20000,
        comment: "",
      },
    ],
    date: "2023-11-28T08:00:00.000Z",
    addTimestamp: false,
    memo: "Monthly Transfer",
    id: "lkKCl6Zt7m6Se4KWmOUB",
  },
];
