import { Autocomplete } from "@mui/material";
import WhiteTextField from "./enter_transaction_styles";
import { sortAccountsByType } from "../../../utilities/general_util";

const AccountAutocomplete = ({
  line,
  accounts,
  funds,
  entryData,
  setEntryData,
  setDisabledFundLines,
  entryErrors,
  findAccountById,
  uniqueKey,
  setTouched,
  size,
}) => {
  const handleEquityAccount = (newValue, line, newEntryData) => {
    newEntryData[line].fund = funds.find(
      (fund) => fund.fundNumber === newValue.accountNumber,
    ).id;

    setDisabledFundLines((prevDisabledFundLines) => [
      ...prevDisabledFundLines,
      line,
    ]);
  };

  const handleNonEquityAccount = (line) => {
    setDisabledFundLines((prevDisabledFundLines) =>
      prevDisabledFundLines.filter((lineInDisabled) => lineInDisabled !== line),
    );
  };

  const handleChange = (event, newValue, line) => {
    const newEntryData = [...entryData];
    if (newValue?.accountType === "Equity") {
      handleEquityAccount(newValue, line, newEntryData);
    } else {
      handleNonEquityAccount(line);
    }
    newEntryData[line].account = newValue ? newValue.id : null;
    setEntryData(newEntryData);
  };

  const getAccountLabel = (option) =>
    ` ${option.accountNumber} - ${option.accountName}`;

  return (
    <Autocomplete
      key={`${uniqueKey}-${line}-account`}
      id={`account-${line}`}
      name={`account-${line}`}
      autoSelect
      autoHighlight
      disabled={entryData[line].cleared}
      options={
        accounts?.length > 0
          ? sortAccountsByType(accounts, [
              "assets",
              "liabilities",
              "equity",
              "income",
              "expenses",
            ])
          : []
      }
      groupBy={(option) => option.accountType}
      getOptionLabel={getAccountLabel}
      value={findAccountById(entryData[line].account) || null}
      onChange={(event, newValue) => handleChange(event, newValue, line)}
      renderInput={(params) => (
        <WhiteTextField
          {...params}
          name={`account-${line}`}
          label="Account"
          variant="outlined"
          color="secondary"
          required
          size={size}
          error={entryErrors.length > 0 && entryErrors[line]?.account}
          helperText={
            entryErrors.length > 0 && entryErrors[line]?.account
              ? "Account is required"
              : ""
          }
          onFocus={() => setTouched("account")}
        />
      )}
    />
  );
};

const FundAutocomplete = ({
  line,
  funds,
  entryData,
  setEntryData,
  disabledFundLines,
  entryErrors,
  uniqueKey,
  setTouched,
  size,
}) => {
  const handleChange = (event, newValue) => {
    const newEntryData = [...entryData];
    newEntryData[line].fund = newValue ? newValue.id : null;
    setEntryData(newEntryData);
  };

  return (
    <Autocomplete
      key={`${uniqueKey}-${line}-fund`}
      id={`fund-${line}`}
      autoSelect
      autoHighlight
      options={funds
        .filter((fund) => !fund.disabled)
        .sort((a, b) => {
          if (a.fundNumber < b.fundNumber) {
            return -1;
          } else if (a.fundNumber > b.fundNumber) {
            return 1;
          } else {
            return 0;
          }
        })}
      getOptionLabel={(option) => `${option.fundNumber} - ${option.fundName}`}
      value={funds.find((fund) => fund.id === entryData[line].fund) || null}
      onChange={handleChange}
      disabled={disabledFundLines.includes(line)}
      renderInput={(params) => (
        <WhiteTextField
          {...params}
          label="Fund"
          required
          error={entryErrors.length > 0 && entryErrors[line]?.fund}
          helperText={
            entryErrors.length > 0 && entryErrors[line]?.fund
              ? "Fund is required"
              : ""
          }
          onFocus={() => setTouched("fund")}
          size={size}
        />
      )}
    />
  );
};

const ContactAutocomplete = ({
  line,
  contacts,
  entryData,
  setEntryData,
  displayContact,
  setDisplayContact,
  entryErrors,
  validateEntry,
  uniqueKey,
  contactMapping,
  touched,
  size,
}) => {
  const filterAndMapContacts = (contacts) =>
    contacts
      .filter(
        (contact) =>
          contact.shortName !== null &&
          contact.shortName !== "" &&
          contact.shortName !== undefined,
      )
      .map((contact) => contact.shortName);
  const handleInputChange = (
    event,
    newValue,
    line,
    contacts,
    entryData,
    setEntryData,
    setDisplayContact,
  ) => {
    const newEntryData = [...entryData];

    const matchingContact = contacts.find(
      (contact) => contact.shortName === newValue,
    );

    newEntryData[line].contact = matchingContact
      ? matchingContact.id
      : newValue;

    if (matchingContact) {
      console.log("touched:", touched);
      const contactId = matchingContact.id;
      const mapping = contactMapping[contactId];
      if (mapping && touched[line].account === false) {
        newEntryData[line].account = mapping.account;
      }
      if (mapping && touched[line].fund === false) {
        newEntryData[line].fund = mapping.fund;
      }
    }

    setEntryData(newEntryData);

    setDisplayContact((prevDisplayContact) => {
      const newDisplayContact = [...prevDisplayContact];
      newDisplayContact[line] = matchingContact
        ? matchingContact.shortName
        : newValue;
      return newDisplayContact;
    });
  };
  const determineHelperText = (
    isValueInList,
    displayContact,
    line,
    entryErrors,
  ) => {
    if (entryErrors.length > 0 && entryErrors[line]?.contact) {
      return "Contact is required";
    } else if (
      !isValueInList &&
      displayContact[line] &&
      displayContact[line] !== "Journal Entry"
    ) {
      return "No matching Contact - this will create a new one";
    }
    return "";
  };

  return (
    <Autocomplete
      key={`${uniqueKey}-${line}-contact`}
      freeSolo
      autoSelect
      label="Contact"
      name={`contact-${line}`}
      options={contacts ? filterAndMapContacts(contacts) : []}
      value={displayContact[line]}
      onInputChange={(event, newValue) =>
        handleInputChange(
          event,
          newValue,
          line,
          contacts,
          entryData,
          setEntryData,
          setDisplayContact,
        )
      }
      renderInput={(params) => {
        const isValueInList = contacts?.some(
          (contact) => contact.id === entryData[line].contact,
        );

        return (
          <WhiteTextField
            {...params}
            name={`contact-${line}`}
            label="Contact"
            variant="outlined"
            color="secondary"
            onBlur={() => validateEntry("contact", "Contact is required")}
            error={entryErrors.length > 0 && entryErrors[line]?.contact}
            helperText={determineHelperText(
              isValueInList,
              displayContact,
              line,
              entryErrors,
            )}
            size={size}
          />
        );
      }}
    />
  );
};

export { AccountAutocomplete, FundAutocomplete, ContactAutocomplete };
