import { doc, updateDoc } from "firebase/firestore";

export const choosePower = async ({ db, org }) => {
  const orgRef = doc(db, "orgs", org);
  try {
    await updateDoc(orgRef, { chosenPath: "power" }, { merge: true });
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const chooseExperimental = async ({ db, org }) => {
  const orgRef = doc(db, "orgs", org);
  try {
    await updateDoc(orgRef, { viewedExperimental: true }, { merge: true });
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const chooseExpert = async ({ db, org }) => {
  const orgRef = db.collection("orgs").doc(org);
  await updateDoc(orgRef, { chosenPath: "expert", onboarded: true });
};

export const choosePowerStartingBalances = async ({ db, org }) => {
  const orgRef = doc(db, "orgs", org);
  try {
    await updateDoc(
      orgRef,
      { chosenPath: "power-startingBalance" },
      { merge: true },
    );
  } catch (err) {
    console.log("Error: ", err);
  }
};
