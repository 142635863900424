import React from "react";
import { LineText, TightRowWrapper } from "../component_styles";
import { MenuItem, Select } from "@mui/material";
import { useWindowSize } from "../../utilities/responsive_helpers";

const AccountSelector = ({
  accounts,
  selectedAccount,
  setSelectedAccount,
  callback,
  height,
}) => {
  const size = height > 950 ? "medium" : "small";

  return (
    <React.Fragment>
      {accounts.length > 0 && (
        <TightRowWrapper>
          <LineText>Working in Account: </LineText>
          <Select
            value={selectedAccount || accounts[0].id}
            onChange={(e) => {
              setSelectedAccount(e.target.value);
              if (callback) {
                callback();
              }
            }}
            size={size}
            style={{ width: "300px", marginLeft: "20px" }}>
            {accounts.map((account) => {
              return (
                <MenuItem value={account.id} key={account.id}>
                  {account.accountNumber} - {account.accountName}
                </MenuItem>
              );
            })}
          </Select>
        </TightRowWrapper>
      )}
    </React.Fragment>
  );
};

export default AccountSelector;
