import React, { useContext, useState } from "react";
import UserContext from "../../../assets/user_context";
import { useAuth } from "../../../services/use-auth";
import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import StyledButton from "../../../assets/buttons";
import { CenteringDiv } from "./manage_account_styles";
import AddFund from "./add_fund";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";
import styled from "@emotion/styled";
import { LineText } from "../../component_styles";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 50%;
`;

const fundNumberstartsWith3 = (fundNumber) => {
  if (fundNumber.toString().startsWith("3")) {
    return true;
  } else {
    return false;
  }
};

export const AddOrChangeFund = (props) => {
  const { funds } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  const [fundEntryData, setFundEntryData] = useState({
    fundName: props.possibleFundNameToAdd ? props.possibleFundNameToAdd : "",
    fundNumber: props.fundNumberToAdd ? props.fundNumberToAdd : "",
  });
  const matchOption = props.fundNumberToAdd && funds.length > 0;
  const [matchDataEntry, setMatchDataEntry] = useState({
    fundNumber: "",
    changeAll: false,
  });
  const [attemptingToAdd, setAttemptingToAdd] = useState(false);

  const handleChangeMatch = (event) => {
    if (event.target.name === "changeAll" && event.target.checked) {
      setMatchDataEntry({
        ...matchDataEntry,
        [event.target.name]: event.target.checked,
      });
    } else {
      setMatchDataEntry({
        ...matchDataEntry,
        [event.target.name]: parseInt(event.target.value),
      });
    }
  };

  const handleMatch = async () => {
    setAttemptingToAdd(true);
    props.updateFundNumber(matchDataEntry.fundNumber, matchDataEntry.changeAll);
    setAttemptingToAdd(false);
    props.setShowModal(false);
  };

  return (
    <CenteringDiv>
      <React.Fragment>
        {matchOption && !attemptingToAdd ? <h1>Add or Match Fund</h1> : null}
        {attemptingToAdd && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <LoadingLogoStyled />
            <LineText>Adding Fund</LineText>
          </div>
        )}
        <div
          style={
            attemptingToAdd
              ? { display: "none" }
              : { display: "flex", flexDirection: "row" }
          }>
          {matchOption ? (
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                pb: 3,
                pl: 2,
                pr: 2,
                width: "350px",
              }}
              noValidate
              autoComplete="off">
              {matchOption ? <h2>Match to Existing Fund</h2> : null}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Match to Fund
                </InputLabel>
                <Select
                  style={{ marginBottom: "20px" }}
                  value={matchDataEntry.fundNumber}
                  label="Fund Number to Match"
                  name="fundNumber"
                  color="secondary"
                  onChange={handleChangeMatch}>
                  {funds.map((fund) => (
                    <MenuItem
                      key={`menu-${fund.fundNumber}`}
                      value={fund.fundNumber}>
                      {`${fund.fundNumber} - ${fund.fundName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={matchDataEntry.changeAll}
                      onChange={handleChangeMatch}
                      name="changeAll"
                      color="secondary"
                    />
                  }
                  label={
                    matchDataEntry.fundNumber === ""
                      ? "Change all accounts"
                      : `Change all accounts with account number ${fundEntryData.fundNumber} to ${matchDataEntry.fundNumber}`
                  }
                />
              </FormControl>
              <StyledButton
                fontSize="1.3rem"
                primary
                onClick={() => handleMatch()}>
                Change Fund Number
              </StyledButton>
            </Box>
          ) : null}

          <AddFund
            setShowModal={props.setShowModal}
            possibleFundNameToAdd={props.possibleFundNameToAdd}
            fundNumberToAdd={props.fundNumberToAdd}
            db={db}
            clickedAdd={() => setAttemptingToAdd(true)}
            reenableSubmit={() => setAttemptingToAdd(false)}
            disabledAddButton={attemptingToAdd}
          />
        </div>
      </React.Fragment>
    </CenteringDiv>
  );
};
