export const defaultAccounts = [
  {
    accountName: "Default - Misc Expense",
    isPayableReceivable: false,
    accountType: "Expenses",
    accountNumber: "5000",
    isRegister: false,
    id: "8HM1DXwTxpp5udTV0W0i",
  },
  {
    accountName: "Default - Credit Card",
    isPayableReceivable: false,
    accountType: "Liabilities",
    accountNumber: "2000",
    isRegister: true,
    id: "DIBsZ9GZRYjPDWDC0mXM",
  },
  {
    isPayableReceivable: false,
    accountType: "Income",
    accountNumber: "4000",
    isRegister: false,
    accountName: "Default - Misc Income",
    id: "J128xc90Uny46oulSQdf",
  },
  {
    accountName: "Default - General Fund",
    accountType: "Equity",
    accountNumber: "3000",
    group: "Fund-Linked Accounts",
    id: "d59MBWcC6Ez0DJ7ejSm9",
  },
  {
    accountName: "Default - Restricted Fund",
    accountType: "Equity",
    accountNumber: "3100",
    group: "Fund-Linked Accounts",
    id: "xFbugVPfhpDU09KOPDJZ",
  },
  {
    accountName: "Default - Checking",
    isPayableReceivable: false,
    accountType: "Assets",
    accountNumber: "1000",
    isRegister: true,
    id: "xmGQmn9XqgJc2weq67US",
  },
];

export const defaultFunds = [
  {
    fundNumber: "3000",
    fundName: "Default - General Fund",
    isRestricted: false,
    id: "d59MBWcC6Ez0DJ7ejSm9",
  },
  {
    fundNumber: "3100",
    fundName: "Default - Restricted Fund",
    isRestricted: true,
    id: "xFbugVPfhpDU09KOPDJZ",
  },
];

export const defaultAccountsHierarchy = {
  types: [
    {
      groups: [
        {
          groupName: "Cash",
          accounts: [
            {
              id: "xmGQmn9XqgJc2weq67US",
            },
          ],
        },
      ],
      type: "Assets",
    },
    {
      groups: [
        {
          groupName: "Payables",
          accounts: [
            {
              id: "DIBsZ9GZRYjPDWDC0mXM",
            },
          ],
        },
      ],
      type: "Liabilities",
    },
    {
      groups: [
        {
          groupName: "Income",
          accounts: [
            {
              id: "J128xc90Uny46oulSQdf",
            },
          ],
        },
      ],
      type: "Income",
    },
    {
      groups: [
        {
          groupName: "Expenses",
          accounts: [
            {
              id: "8HM1DXwTxpp5udTV0W0i",
            },
          ],
        },
      ],
      type: "Expenses",
    },
    {
      groups: [
        {
          groupName: "Fund-Linked Accounts",
          accounts: [
            {
              id: "d59MBWcC6Ez0DJ7ejSm9",
            },
            {
              subAccounts: [],
              id: "xFbugVPfhpDU09KOPDJZ",
            },
          ],
        },
      ],
      type: "Equity",
    },
  ],
};

export const defaultFundsHierarchy = {
  groups: [
    {
      groupName: "Unrestricted Funds",
      funds: [
        {
          id: "d59MBWcC6Ez0DJ7ejSm9",
        },
      ],
    },
    {
      groupName: "Restricted Funds",
      funds: [
        {
          id: "xFbugVPfhpDU09KOPDJZ",
        },
      ],
    },
  ],
};
