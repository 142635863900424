import React, { useEffect, useContext, useState } from "react";
import UserContext from "../../../assets/user_context.jsx";
import { useAuth } from "../../../services/use-auth";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { IconButton, Modal, TextField, Tooltip } from "@mui/material";
import { ModalBox, ModalInner } from "../../onboarding/onboarding_styles.jsx";
import { AddOrg } from "../../onboarding/add_org.jsx";
import { ClickableDiv, SideBySideDiv } from "../account_styles.jsx";
import { AddCircleOutline, Check, Edit } from "@mui/icons-material";
import AddUser from "./add_user.jsx";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import dayjs from "dayjs";

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px;
  border-radius: 8px;
  opacity: 0.85;
`;

const SubtitleText = styled.p`
  font-size: 22px;
  font-family: "MontserratMed";
  margin: 0.7rem;
  color: black;
`;

const BreakDiv = styled.div`
  height: 1rem;
  width: 100%;
  flex-shrink: 0;
  margin: 0.5rem;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px; // same as your border thickness
    background: linear-gradient(
      to left,
      rgba(207, 207, 207, 0),
      #c6c6c6 50%,
      rgba(207, 207, 207, 0)
    );
    bottom: 0;
    left: 0;
  }
`;

const InviteDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${(props) =>
    props.userAdded ? "#c4c4c4ef" : "#ffb55bed"}; */
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const OrgInfo = () => {
  const {
    userData,
    user,
    showLoading,
    org,
    orgData,
    setIsChildDataLoaded,
    experimental,
    orgRoles,
  } = useContext(UserContext);
  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false);
  const [invites, setInvites] = useState([]);
  const [editingName, setEditingName] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");

  const authHook = useAuth();
  const db = authHook.db;

  //Redirect to sign in page if user is not signed in
  const redirect = useNavigate();
  useEffect(() => {
    if (!showLoading) {
      if (user === null) {
        console.log("Should redirect to SIGN_IN");
        redirect("/signin");
      }
    }
    setIsChildDataLoaded(true);
  }, [user, showLoading, redirect]);

  useEffect(() => {
    let unsubscribe; // Declare a variable to hold the unsubscribe function

    const listenToInvites = () => {
      if (!experimental && orgRoles.some((role) => role === "admin")) {
        const invitesRef = collection(db, "orgs", org, "invites");

        // Set up a real-time listener using onSnapshot
        unsubscribe = onSnapshot(invitesRef, (snapshot) => {
          const invitesData = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              emailSent: data.emailSent
                ? dayjs(
                    new Date(
                      data.emailSent.seconds * 1000 +
                        data.emailSent.nanoseconds / 1000000,
                    ),
                  ).format("MM/DD")
                : null,
              userAdded: data.userAdded
                ? dayjs(
                    new Date(
                      data.userAdded.seconds * 1000 +
                        data.userAdded.nanoseconds / 1000000,
                    ),
                  ).format("MM/DD")
                : null,
            };
          });
          console.log("Real-time invitesData: ", invitesData);
          setInvites(invitesData); // Update the state with the new invites data
        });
      }
    };

    if (org) {
      listenToInvites();
    }

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [db, org, setIsChildDataLoaded]);

  const changeOrgName = async () => {
    console.log("Changing org name");
    console.log(newOrgName);
    await updateDoc(doc(db, "orgs", org), {
      orgName: newOrgName,
    });
    setEditingName(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}>
      {orgData?.orgName && (
        <SubtitleText>Manage {orgData.orgName}'s Information</SubtitleText>
      )}
      <SideBySideDiv>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "left",
          }}>
          <ClickableDiv onClick={() => setAddOrgModalOpen(true)}>
            <AddCircleOutline />
            <SubtitleText style={{ fontSize: "1.1rem" }}>
              Add a new org
            </SubtitleText>
          </ClickableDiv>
          <SubtitleText>Orgs</SubtitleText>
          {userData?.orgs?.map((org, index) => (
            <SubtitleText
              key={`orgName-${index}`}
              style={{
                fontSize: "1.2rem",
                color: "grey",
                paddingLeft: "1rem",
              }}>
              {`${index + 1}.  ${org.orgName}`}
            </SubtitleText>
          ))}
          <BreakDiv />
          {orgRoles.some((role) => role === "admin") ? (
            <AddUser />
          ) : (
            <SubtitleText>Note: only admins can add/remove users.</SubtitleText>
          )}
          {orgData?.users && Object.entries(orgData.users).length > 0
            ? Object.entries(orgData.users).map(([uid, user]) => {
                return (
                  <div key={uid}>
                    <SubtitleText>{`${user.username ? user.username : uid} ${
                      user.email ? `(${user.email})` : ""
                    }`}</SubtitleText>

                    {user.roles.map((role, index) => (
                      <SubtitleText
                        key={`role-${index}`}
                        style={{
                          fontSize: "1.2rem",
                          color: "grey",
                          paddingLeft: "1rem",
                        }}>
                        {`${index + 1}. ${role
                          .substring(0, 1)
                          .toUpperCase()}${role.substring(1, role.length)}`}
                      </SubtitleText>
                    ))}
                  </div>
                );
              })
            : null}
          {invites.length > 0 && (
            <div>
              <SubtitleText>Invites</SubtitleText>

              {invites.map((invite, index) => (
                <InviteDiv userAdded={invite.userAdded}>
                  <SubtitleText
                    key={`invite-${index}`}
                    style={{
                      fontSize: "1.2rem",
                      marginTop: "0",
                      marginBottom: "0",
                    }}>
                    {invite.email}
                  </SubtitleText>
                  <SubtitleText
                    key={`invite-${index}`}
                    style={{
                      fontSize: "1.2rem",
                      marginTop: "0",
                      marginBottom: "0",
                    }}>
                    {" "}
                    {/* <React.Fragment> */}
                    <Tooltip
                      title={
                        invite.invitedByName
                          ? `Invited by ${invite.invitedByName || null}`
                          : null
                      }>
                      {invite.userAdded ? (
                        <SubtitleText
                          style={{
                            fontSize: "1rem",
                            marginTop: "0",
                            marginBottom: "0",
                            padding: "0.5rem",
                            borderRadius: "0.75rem",
                            backgroundColor: "#9be495eb",
                          }}>
                          {`Accepted on ${invite.userAdded}`}
                        </SubtitleText>
                      ) : invite.userExists ? (
                        <SubtitleText>Pending</SubtitleText>
                      ) : (
                        <SubtitleText
                          style={{
                            fontSize: "1rem",
                            marginTop: "0",
                            marginBottom: "0",
                            padding: "0.5rem",
                            borderRadius: "0.75rem",
                            backgroundColor: "#ffb55bed",
                          }}>{`Invited on ${invite.emailSent}`}</SubtitleText>
                      )}
                    </Tooltip>
                    {/* </React.Fragment> */}
                  </SubtitleText>
                </InviteDiv>
              ))}
            </div>
          )}
        </div>
        <AccountWrapper>
          <SubtitleText>Edit Org</SubtitleText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            {!editingName ? (
              <React.Fragment>
                <SubtitleText
                  key={`currentOrgName`}
                  style={{
                    fontSize: "1.2rem",
                    color: "grey",
                    paddingLeft: "1rem",
                  }}>
                  {orgData?.orgName}
                </SubtitleText>
                <Edit
                  onClick={() => {
                    console.log("Edit org name");
                    setNewOrgName(orgData?.orgName);
                    setEditingName(true);
                  }}
                  style={{ color: "grey", cursor: "pointer" }}
                />
              </React.Fragment>
            ) : (
              <TextField
                label="New Org Name"
                value={newOrgName}
                onChange={(e) => setNewOrgName(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => changeOrgName()}>
                      <Check style={{ color: "#61b561" }} fontSize="large" />
                    </IconButton>
                  ),
                }}
              />
            )}
          </div>
          <Modal
            open={addOrgModalOpen}
            onClose={() => setAddOrgModalOpen(false)}>
            <ModalBox>
              <ModalInner>
                <AddOrg
                  db={db}
                  userData={userData}
                  user={user}
                  closeModal={() => setAddOrgModalOpen(false)}
                />
              </ModalInner>
            </ModalBox>
          </Modal>
        </AccountWrapper>
      </SideBySideDiv>
    </div>
  );
};

export default OrgInfo;
