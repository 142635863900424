import React, { useRef, useState, createRef, useEffect } from "react";
import {
  LineTextBlack,
  NoteText,
  TitleTextBlack,
} from "../mass_import_styles.jsx";
import AddAccount from "../../../locations/foundation/manage_accounts/add_account.jsx";
import styled from "styled-components";
import StyledButton from "../../../assets/buttons.js";
import { Box } from "@mui/system";
import {
  createHierarchyFromMassImport,
  findAccountType,
} from "../../../locations/foundation/manage_accounts/utils.jsx";
import { setDoc, doc } from "firebase/firestore";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 50%;
`;

const MassAddAccounts = ({ org, db, transactionsArg, setShowModal }) => {
  const [groupsAdded, setGroupsAdded] = useState({});
  const [groupsMapping, setGroupsMapping] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    console.log("Groups Added to Parent: ", groupsAdded);
  }, [groupsAdded]);

  const addGroupToList = (groupObj, accountNumber) => {
    if (groupObj.group !== "") {
      setGroupsAdded((prevGroups) => {
        const existingTypeObject = prevGroups[groupObj.type] || {};
        const existingGroupArray = existingTypeObject[groupObj.group] || [];

        // Check if the array already contains the account number
        if (existingGroupArray.includes(accountNumber)) {
          return prevGroups; // Return the previous state without changes
        }

        // Otherwise, update the state with the new value
        return {
          ...prevGroups,
          [groupObj.type]: {
            ...existingTypeObject,
            [groupObj.group]: [...existingGroupArray, accountNumber],
          },
        };
      });

      // Also update the mapping for this specific account
      setGroupsMapping((prevMapping) => ({
        ...prevMapping,
        [accountNumber]: { type: groupObj.type, group: groupObj.group },
      }));
    }
  };

  // Create a set to keep track of which accounts have already been added
  const uniqueAccounts = new Set();
  const accountDataToAdd = transactionsArg
    .reduce((accumulator, transaction) => {
      const linesArray = transaction.lines.map((line) => {
        return {
          accountNumber: line.account,
          possibleAccountName: line.accountText,
        };
      });

      // Filter out duplicates based on accountNumber
      const uniqueLinesArray = linesArray.filter((line) => {
        if (!uniqueAccounts.has(line.accountNumber)) {
          uniqueAccounts.add(line.accountNumber);
          return true;
        }
        return false;
      });

      return [...accumulator, ...uniqueLinesArray];
    }, [])
    .filter(
      (accountData) => findAccountType(accountData.accountNumber) !== "Equity",
    );

  console.log("accountDataToAdd: ", accountDataToAdd);

  const childRefs = useRef(
    accountDataToAdd.reduce((acc, accountData) => {
      acc[accountData.accountNumber] = createRef();
      return acc;
    }, {}),
  );

  const splitDataAndSort = accountDataToAdd.reduce(
    (acc, accountData) => {
      const groupData = groupsMapping[accountData.accountNumber];
      if (!groupData) {
        acc.ungrouped.push(accountData);
      } else {
        const type = groupData.type;
        const group = groupData.group;
        acc.grouped[type] = acc.grouped[type] || {};
        (acc.grouped[type][group] = acc.grouped[type][group] || []).push(
          accountData,
        );
      }
      return acc;
    },
    { ungrouped: [], grouped: {} },
  );

  const { ungrouped, grouped } = splitDataAndSort;

  const validateAllChildrenForms = async () => {
    // Use Promise.all to wait for all the child components' validation promises to resolve
    const validationResults = await Promise.all(
      Object.values(childRefs.current).map((ref) => ref.current.validateForm()),
    );
    console.log("Validation Results: ", validationResults);

    // Return true if all the child components' validation functions returned true
    return validationResults.every((result) => result);
  };

  const handleSubmitAll = async () => {
    setSubmitting(true);
    const isValid = await validateAllChildrenForms();
    if (!isValid) {
      setSubmitting(false);
      return;
    } else {
      // If all the forms are valid, submit them
      const accountIds = await Promise.all(
        Object.values(childRefs.current).map((ref) =>
          ref.current.handleSubmit(),
        ),
      ).catch((err) => {
        console.error("Error in Promise.all: ", err);
      });

      // Build the hierarchy using the account IDs
      const accountsHierarchy = createHierarchyFromMassImport(
        groupsMapping,
        accountIds,
      );
      await setDoc(
        doc(db, "orgs", org, "organizationAndSettings", "accountsHierarchy"),
        accountsHierarchy,
      );
      setShowModal(false);
      console.log("Submitting...");
    }
  };

  return (
    <div>
      <React.Fragment>
        {submitting && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <LoadingLogoStyled />
            <TitleTextBlack>Adding Accounts...</TitleTextBlack>
          </div>
        )}
      </React.Fragment>
      <React.Fragment>
        {!submitting && (
          <React.Fragment>
            <TitleTextBlack>We found these new accounts...</TitleTextBlack>{" "}
            <LineTextBlack>
              Please verify the details, add groups, and submit.
            </LineTextBlack>
          </React.Fragment>
        )}
        {Object.keys(groupsMapping).length > 0 && (
          <NoteText>
            Note: Accounts are moved to the sorted list at the bottom when a
            group is entered
          </NoteText>
        )}
        {accountDataToAdd.length > 0 && (
          <Box
            sx={
              submitting
                ? { display: "none" }
                : {
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "65vh",
                    overflow: "scroll",
                    pt: 2,
                  }
            }>
            {ungrouped.map((accountData) => (
              <AddAccount
                ref={childRefs.current[accountData.accountNumber]}
                key={`AddAccountComponent-${accountData.accountNumber}`}
                accountNumberToAdd={accountData.accountNumber}
                possibleAccountNameToAdd={accountData.possibleAccountName}
                accounts={[]}
                org={org}
                db={db}
                accountsHierarchy={{}}
                massAdd
                addGroup={addGroupToList}
                groupsAddedToParent={groupsAdded}
              />
            ))}
            {Object.keys(grouped).map((typeKey) => (
              <React.Fragment key={`Type-${typeKey}`}>
                <h2>{typeKey}</h2>
                {Object.keys(grouped[typeKey]).map((groupKey) => (
                  <React.Fragment key={`Group-${groupKey}`}>
                    <h3>Group: {groupKey}</h3>
                    {grouped[typeKey][groupKey].map((accountData) => (
                      <AddAccount
                        ref={childRefs.current[accountData.accountNumber]}
                        key={`AddAccountComponent-${accountData.accountNumber}`}
                        accountNumberToAdd={accountData.accountNumber}
                        possibleAccountNameToAdd={
                          accountData.possibleAccountName
                        }
                        accounts={[]}
                        org={org}
                        db={db}
                        accountsHierarchy={{}}
                        massAdd
                        addGroup={(groupObj) => {
                          addGroupToList(groupObj, accountData.accountNumber);
                        }}
                        groupsAddedToParent={groupsAdded}
                        thisGroup={groupKey}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </Box>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "1rem",
          }}>
          {!submitting && (
            <StyledButton
              disabled={submitting}
              primary
              onClick={handleSubmitAll}>
              Submit All
            </StyledButton>
          )}
        </div>
      </React.Fragment>
    </div>
  );
};

export default MassAddAccounts;
