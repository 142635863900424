import {
  convertToDate,
  findAccountById,
} from "../../../utilities/general_util";

export const deepCopyTransactions = (transactions) => {
  return transactions.map((transaction) => {
    return {
      ...transaction,
      lines: transaction.lines.map((line) => {
        return { ...line };
      }),
    };
  });
};

export const sortTransactionsByDateAndAccount = ({
  transactions,
  accounts,
  filterAccount,
}) => {
  const sortedTx = transactions.sort((a, b) => {
    const aAccountNumber = findAccountById({
      accountId: a.lines.find((line) => line.account !== filterAccount)
        ?.account,
      accounts,
    })?.accountNumber;
    const bAccountNumber = findAccountById({
      accountId: b.lines.find((line) => line.account !== filterAccount)
        ?.account,
      accounts,
    })?.accountNumber;
    const aDate = convertToDate(a.date);
    const bDate = convertToDate(b.date);
    // Sort by ascending date
    if (aDate.isBefore(bDate)) return -1;
    if (aDate.isAfter(bDate)) return 1;
    // If the dates are the same, sort by account number of the first line
    if (aDate.isSame(bDate) && aAccountNumber && bAccountNumber) {
      if (aAccountNumber < bAccountNumber) return -1;
      if (aAccountNumber > bAccountNumber) return 1;
    }
    return 0;
  });
  return sortedTx;
};

const findBalanceForFirstTx = ({
  accounts,
  filterAccount,
  dateRangeForTx,
  monthlyAggregates,
  allTransactions,
  accountType,
}) => {
  //We need to get the starting balance for the account from the monthly aggreagate imediately before the first date
  //turn firstDate minus one month into YYYY-MM
  const firstDateMinusOneMonth = dateRangeForTx.firstDate
    .subtract(1, "month")
    .format("YYYY-MM");
  console.log("First Date Minus One Month: ", firstDateMinusOneMonth);
  const monthAggregate =
    monthlyAggregates !== null && monthlyAggregates?.length > 0
      ? monthlyAggregates.find(
          (aggregate) => aggregate.id === firstDateMinusOneMonth,
        )
      : null;
  //Need to find the first day of the month of the month in dateRangeForTx.firstDate
  const firstDayOfTheMonth = convertToDate(dateRangeForTx.firstDate).startOf(
    "month",
  );
  const dayOfFirstTx = convertToDate(dateRangeForTx.firstDate);
  // console.log(
  //   "First Day of the Month: ",
  //   firstDayOfTheMonth.$d,
  //   "Day of First Tx:",
  //   dayOfFirstTx.$d,
  // );
  let accountBalance = 0;
  //If there is no monthAggregate, this must be before the first month of the org, so start with a balancd of zero
  if (
    monthAggregate?.balanceSheet &&
    Object.keys(monthAggregate.balanceSheet).length > 0 &&
    Object.values(monthAggregate.balanceSheet)?.some(
      (fundObj) =>
        Array.isArray(fundObj?.[accountType]) &&
        fundObj[accountType].some((account) => account.id === filterAccount),
    )
  ) {
    console.log("Month Aggregate: ", firstDateMinusOneMonth, monthAggregate);
    // //Need to find the earliest transaction, then get any transactions before that point and after the last month
    // const earliestDate = filteredTransactions.reduce(
    //   (acc, transaction) => {
    //     const transactionDate = convertToDate(transaction.date);
    //     if (transactionDate.isBefore(acc)) {
    //       return transactionDate;
    //     } else {
    //       return acc;
    //     }
    //   },
    //   convertToDate("9999-12-31"),
    // );

    //We need to get the balance for the account from the month aggregate under each fund and sum them for the total account balance, this is the starting point for the balance
    // console.log(
    //   "monthAggregate.balanceSheet: ",
    //   monthAggregate.balanceSheet,
    // );
    Object.entries(monthAggregate.balanceSheet).forEach(
      ([fundKey, fundObj]) => {
        if (fundKey === "total") return; //Skip the total key
        // console.log(
        //   "fundObj, accountType, monthAgg ",
        //   fundObj,
        //   accountType,
        //   monthAggregate,
        // );
        return fundObj[accountType].forEach((account) => {
          // console.log("account: ", account);
          if (account.id === filterAccount) {
            // console.log(
            //   `Found Account ${account.id} with balance: ${account.total} in fund ${fundKey}`,
            // );
            accountBalance += parseInt(account.total);
            // console.log("New Account Balance: ", accountBalance);
          }
        });
      },
    );
    console.log(
      `Brining in the account balance of ${
        accountBalance / 100
      } from the month aggregate for ${firstDateMinusOneMonth} for account ${filterAccount} and account type ${accountType}`,
    );

    // console.log(
    //   `Account Balance (running total) for this account is starting at ${
    //     runningTotal / 100
    //   } for account due to ${
    //     transactionsSinceBeginningOfMonth.length
    //   } transactions since the beginning of the month`,
    // );
    // console.log(transactionsSinceBeginningOfMonth, filterAccount);
  } else {
    console.log("No month aggregate found, starting with zero balance");
  }

  //Find the starting balance for the account using the initial balance and the first transactions that aren't shown
  const transactionsSinceBeginningOfMonth = allTransactions.filter(
    (transaction) => {
      const transactionDate = convertToDate(transaction.date);
      const isOnOrAfterStart = transactionDate.isSameOrAfter(
        firstDayOfTheMonth,
        "day",
      );
      const isBeforeEnd = transactionDate.isBefore(dayOfFirstTx, "day");
      return isOnOrAfterStart && isBeforeEnd;
    },
  );
  console.log(
    "tx since begining of month before first tx to show",
    transactionsSinceBeginningOfMonth,
  );

  //Now add the transaction's lines that match this account are are since the beginning of the month to the account balance
  transactionsSinceBeginningOfMonth.forEach((transaction) => {
    transaction.lines.forEach((line) => {
      if (line.account === filterAccount) {
        const amount = parseInt(line.amount);
        accountBalance +=
          (line.sign === "debit" && accountType === "assets") ||
          (line.sign === "credit" && accountType === "liabilities")
            ? amount
            : -amount;
        console.log(
          `Adding ${
            (line.sign === "debit" && accountType === "assets") ||
            (line.sign === "credit" && accountType === "liabilities")
              ? "+"
              : "-"
          } ${amount} to account balance from transaction on ${convertToDate(
            transaction.date,
          ).format("MM/DD/YYYY")}`,
        );
      }
    });
  });
  return accountBalance;
};

export const appendBalanceLinesToTransactions = ({
  transactions,
  allTransactions,
  accounts,
  filterAccount,
  dateRangeForTx,
  monthlyAggregates,
}) => {
  const thisAccountType = accounts
    .find((account) => account.id === filterAccount)
    .accountType.toLowerCase();

  let runningTotal = findBalanceForFirstTx({
    accounts,
    filterAccount,
    dateRangeForTx,
    monthlyAggregates,
    allTransactions,
    accountType: thisAccountType,
  });
  console.log("Starting Balance: ", runningTotal);
  const transactionsWithBalances = transactions.map((transaction, txIndex) => {
    //Movement total for this transaction (all lines pertaining to the account we are filtering for)
    const movementTotal = transaction.lines
      .filter((line) => line.account === filterAccount)
      .reduce((acc, line) => {
        const amount = parseInt(line.amount);
        return (line.sign === "debit" && thisAccountType === "assets") ||
          (line.sign === "credit" && thisAccountType === "liabilities")
          ? acc + amount
          : acc - amount;
      }, 0);
    //Append the running total to each line
    const newLines = transaction.lines.map((line, index) => {
      //This should be the line balancing with the account we are filtering for. Should add a check for that, however.
      if (index === 0) {
        //This is the line for the account we are filtering for, so we need to add a running total
        runningTotal += movementTotal;
        // if (txIndex < 5)
        //   console.log(
        //     "running total: ",
        //     runningTotal,
        //     "movement total: ",
        //     movementTotal,
        //     "account: ",
        //     accounts.find((account) => account.id === line.account).accountName,
        //   );
        // if (txIndex < 3) line.color = "red";
        return { ...line, runningTotal };
      } else {
        return { ...line, runningTotal };
      }
    });
    return { ...transaction, lines: newLines };
  });
  return transactionsWithBalances;
};
