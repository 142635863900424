export const validateBankingInputs = ({ txEntryData, txMetaData }) => {
  const currentEntryData = { ...txEntryData };
  Object.keys(txEntryData).forEach((key) => {
    // Remove any commas from the credit and debit fields and convert to an integer for cents
    txEntryData[key].credit = txEntryData[key]?.credit
      ? Math.round(
          parseFloat(
            currentEntryData[key].credit.toString().replace(/,/g, ""),
          ) * 100,
        )
      : "";
    txEntryData[key].debit = txEntryData[key]?.debit
      ? Math.round(
          parseFloat(currentEntryData[key].debit.toString().replace(/,/g, "")) *
            100,
        )
      : "";
  });
  const errors = {};

  const transactionLinesCount = Object.keys(txEntryData).length;
  const splitTransaction = transactionLinesCount > 1;

  if (splitTransaction) {
    //sum all of the lines after the first line
    const splitTotal = Object.values(txEntryData)
      .slice(1)
      .reduce((acc, curr) => {
        return acc + (parseInt(curr.credit) || parseInt(curr.debit));
      }, 0);
    //check if the split total is equal to the first line
    if (
      splitTotal !== parseInt(txEntryData[0].credit) &&
      splitTotal !== parseInt(txEntryData[0].debit)
    ) {
      errors.splitTotal = "Split total must equal the first line";
    }
    Object.entries(txEntryData).forEach(([key, value]) => {
      if (value.credit !== "" || value.debit !== "") {
        if ((value.account === "" || value.account === null) && key !== "0") {
          errors[`account-${key}`] = "Account is required";
        }
        if ((value.fund === "" || value.fund === null) && key !== "0") {
          errors[`fund-${key}`] = "Fund is required";
        }
      }
    });
  } else {
    if (txEntryData[0].credit === "" && txEntryData[0].debit === "") {
      errors.amount = "Inflow or Outflow is required";
    }
    if (txEntryData[0].account === "" || txEntryData[0].account === null) {
      errors.account = "Account is required";
    }
    if (txEntryData[0].fund === "" || txEntryData[0].fund === null) {
      errors.fund = "Fund is required";
    }
  }
  if (txMetaData.date === "" || txMetaData.date === null) {
    errors.date = "Date is required";
  }
  if (txEntryData[0].contact === "" || txEntryData[0].contact === null) {
    errors.contact = "Contact is required";
  }

  return errors;
};
