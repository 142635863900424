import React, { useContext } from "react";
import {
  DetailsDiv,
  LightBoldText,
  LightText,
  LightTitleText,
  TransactionDetailsDiv,
} from "./history_styles";
import {
  findAccountById,
  findContactNameById,
  findFundById,
  returnCurrency,
} from "../../utilities/general_util";
import UserContext from "../../assets/user_context";

//function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ChangedTransactionItem = ({ changeToExamine, timeState }) => {
  const { accounts, contacts, funds } = useContext(UserContext);
  const labelState = capitalizeFirstLetter(timeState);
  return (
    <TransactionDetailsDiv>
      <LightTitleText>{labelState}</LightTitleText>
      <DetailsDiv>
        <LightBoldText>Date:</LightBoldText>
        <LightText>
          {changeToExamine[timeState].date.toDate().toLocaleDateString()}
        </LightText>
      </DetailsDiv>

      <DetailsDiv>
        <LightBoldText>Memo:</LightBoldText>
        <LightText>{changeToExamine[timeState].memo}</LightText>
      </DetailsDiv>
      <table>
        <thead>
          <tr>
            <th>
              <LightBoldText>Account</LightBoldText>
            </th>
            <th>
              <LightBoldText>Contact</LightBoldText>
            </th>
            <th>
              <LightBoldText>Fund</LightBoldText>
            </th>
            <th>
              <LightBoldText>Amount</LightBoldText>
            </th>
          </tr>
        </thead>
        <tbody>
          {changeToExamine[timeState].lines.map((line, index) => (
            <tr key={`changeLine-${index}`}>
              <td>
                <LightText>
                  {findAccountById({
                    accountId: line.account,
                    accounts: accounts,
                  })
                    ? findAccountById({
                        accountId: line.account,
                        accounts: accounts,
                      })?.accountName
                    : "No Longer Exists"}
                </LightText>
              </td>
              <td>
                <LightText>
                  {line.contact !== null
                    ? findContactNameById({
                        contacts,
                        contactId: line.contact,
                      })
                    : "Journal Entry"}
                </LightText>
              </td>
              <td>
                <LightText>
                  {findFundById({
                    fundId: line.fund,
                    funds: funds,
                  })
                    ? findFundById({
                        fundId: line.fund,
                        funds: funds,
                      })?.fundName
                    : "No Longer Exists"}
                </LightText>
              </td>
              <td>
                <LightText>
                  {line.sign} of {returnCurrency(line.amount)}
                </LightText>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TransactionDetailsDiv>
  );
};

export const ChangedReconciliationItem = ({ changeToExamine }) => {
  const { accounts } = useContext(UserContext);
  console.log(changeToExamine, "changeToExamine");
  const changeTitle =
    changeToExamine.action.toLowerCase() === "created"
      ? "Created Reconciliation"
      : "Deleted Reconciliation";
  const changeKey =
    changeToExamine.action.toLowerCase() === "created" ? "after" : "before";

  return (
    <TransactionDetailsDiv>
      <LightTitleText style={{ whiteSpace: "nowrap" }}>{`${changeTitle} in ${
        findAccountById({
          accountId: changeToExamine[changeKey].account,
          accounts,
        })?.accountNumber
      }-${
        findAccountById({
          accountId: changeToExamine[changeKey].account,
          accounts,
        })?.accountName
      }`}</LightTitleText>
      <LightText>{`Closing Date: ${changeToExamine[changeKey].closingDate
        .toDate()
        .toLocaleDateString()}`}</LightText>
      <LightText>{`Opening Date: ${changeToExamine[changeKey].openingDate
        .toDate()
        .toLocaleDateString()}`}</LightText>
      <LightText>{`Account balance change from ${returnCurrency(
        changeToExamine[changeKey].openingBalance,
      )} to ${returnCurrency(
        changeToExamine[changeKey].closingBalance,
      )}`}</LightText>
      <LightText>{`Reconciliation created by ${changeToExamine[changeKey].username}`}</LightText>
      <LightText>{`Reconciliation cleared ${changeToExamine[changeKey].clearedTxIds.length} transactions`}</LightText>
      <LightText>{`It contained ${changeToExamine[changeKey].unClearedTxIds.length} uncleared transactions`}</LightText>
    </TransactionDetailsDiv>
  );
};
