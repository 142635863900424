import React, { useState, useEffect, useMemo, useContext } from "react";
import { Parser } from "@json2csv/plainjs";
import StyledButton from "../../assets/buttons";
import { useAuth } from "../../services/use-auth";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import UserContext from "../../assets/user_context";

const ExportCSV = (props) => {
  const opts = useMemo(
    () => ({
      fields: [
        { value: "date", label: "Date" },
        { value: "memo", label: "Memo" },
        { value: "account", label: "Account" },
        { value: "fund", label: "Fund" },
        { value: "comment", label: "Comment" },
        { value: "contact", label: "Contact" },
        { value: "debit", label: "Debit" },
        { value: "credit", label: "Credit" },
      ],
    }),
    [],
  );

  useEffect(() => {
    if (
      props.transactionsData.length > 0 &&
      props.transactionsProcessed.length === 0
    ) {
      // console.log("Processing transactions");
      const parser = new Parser(opts);
      let flattenedTransactions = [];
      props.transactionsData.forEach((transaction) => {
        const transactionBatch = transaction.lines.forEach(
          (transactionLine, index) => {
            flattenedTransactions.push({
              date:
                index === 0
                  ? transaction.date.toLocaleString(undefined, {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  : "",
              memo: index === 0 ? transaction.memo : "",
              account: transactionLine.account,
              fund: transactionLine.fund,
              comment: transactionLine.comment,
              contact: index === 0 ? transaction.contact : "",
              debit:
                transactionLine.sign === "debit"
                  ? transactionLine.amount.toFixed(2).toString()
                  : "",
              credit:
                transactionLine.sign === "credit"
                  ? transactionLine.amount.toFixed(2).toString()
                  : "",
            });
          },
        );
        return transactionBatch;
      });
      const csv = parser.parse(flattenedTransactions);
      // console.log(csv);

      const date = new Date();
      const dateString = date.toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const importedDataRef = doc(
        props.db,
        "orgs",
        props.org,
        "importedData",
        "initialTransactions",
      );
      setDoc(
        importedDataRef,
        { csv: csv, dateString: dateString },
        { merge: true },
      );
    }
  }, [
    props.db,
    opts,
    props.org,
    props.transactionsData,
    props.transactionsProcessed?.length,
  ]);

  const downloadCSV = () => {
    if (props.transactionsProcessed.length > 0) {
      try {
        const csvFile = new Blob([props.transactionsProcessed], {
          type: "text/csv",
        });
        const downloadLink = URL.createObjectURL(csvFile);
        const link = document.createElement("a");
        link.href = downloadLink;

        link.download = `ImportedTransactions_Updated-${props.dateString}.csv`;
        link.click();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      {(props.transactionsProcessed.length > 0 ||
        props.transactionsData.length) && (
        <StyledButton
          bright
          fontSize={"1.5rem"}
          onClick={() => {
            downloadCSV();
            console.log(props.transactionsProcessed);
          }}>
          Export Updated Transactions to CSV
        </StyledButton>
      )}
    </div>
  );
};

export default ExportCSV;
