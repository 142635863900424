import React, { useContext, useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import UserContext from "../../../assets/user_context";
import { Switch } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import styled from "@emotion/styled";
import { primary_dark_color } from "../../../constants/color_constants";
import FundsRearrange from "./type_rearrage";

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ControlsLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ClickableControlsLabelDiv = styled(ControlsLabelDiv)`
  cursor: pointer;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  border-radius: 1rem;
  border-radius: 3rem;
  background-color: #e7eff1;
`;

const DetailsText = styled.p`
  font-size: 1rem;
  font-family: "MontserratLight";
`;

const ControlsText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: center;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const RearrangeFundsUI = ({ setEditMode, editMode }) => {
  const { org, fundsHierarchy, experimental, setFundsHierarchy } =
    useContext(UserContext);
  const authHook = useAuth();
  const [draftGroups, setDraftGroups] = useState(fundsHierarchy.groups || []);

  useEffect(() => {
    if (fundsHierarchy?.groups) {
      setDraftGroups(fundsHierarchy.groups);
    }
  }, [fundsHierarchy]);

  //Log out draftGroups when they change
  useEffect(() => {
    console.log(draftGroups);
  }, [draftGroups]);

  return (
    <HorizontalDiv>
      <VerticalDiv>
        <ControlsLabelDiv>
          <ControlsText>Rearrange Funds</ControlsText>
          <Switch
            color="secondary"
            checked={editMode}
            onChange={() => setEditMode(!editMode)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </ControlsLabelDiv>
        <ClickableControlsLabelDiv
          onClick={async () => {
            try {
              if (!experimental) {
                const newFundsHierarchy = { groups: draftGroups.groups };
                console.log(newFundsHierarchy);
                await setDoc(
                  doc(
                    authHook.db,
                    "orgs",
                    org,
                    "organizationAndSettings",
                    "fundsHierarchy",
                  ),
                  { ...newFundsHierarchy },
                );

                console.log("Document successfully written!");
              } else {
                console.log("Experimental Mode, making saves");
                setFundsHierarchy({ draftGroups });
              }
              setEditMode(false);
            } catch (error) {
              console.error("Error writing document: ", error);
            }
          }}>
          <ControlsText>Save Changes</ControlsText>
          <div style={{ width: "1rem" }} />
          <Save fontSize="large" />
        </ClickableControlsLabelDiv>
        <DetailsText style={{ width: "20rem" }}>
          Rearrange funds as you wish by dragging the funds to combine or
          reorder them. You can also drag the groups to rearrange. Then click
          save to save this new fund organization, or the switch to return to
          your normal funds view.
        </DetailsText>
        <DetailsText style={{ width: "20rem" }}>
          Please note that rearranging Funds will affect the order and
          organization of all Reports!
        </DetailsText>
      </VerticalDiv>
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FundsRearrange
            groupsIn={draftGroups}
            setDraftGroups={setDraftGroups}
          />
        </div>
      </React.Fragment>
    </HorizontalDiv>
  );
};

export default RearrangeFundsUI;
