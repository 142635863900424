import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  query,
  collection,
  where,
  onSnapshot,
} from "firebase/firestore";
import dayjs from "dayjs";

import { CreateLinkButton } from "./banking/banking_styles.jsx";
import { TitleText } from "../component_styles.jsx";
import { Close, CloudUploadOutlined } from "@mui/icons-material";
import { LineText, DetailText } from "../transactions/transactions_styles.jsx";

//Helper to convert a timestamp to a date
const convertTimestampToDate = (timestamp) => {
  if (typeof timestamp === "object" && timestamp.seconds) {
    // Firebase-like timestamp
    const jsNativeDate = new Date(timestamp.seconds * 1000);
    return dayjs(jsNativeDate);
  } else if (typeof timestamp === "string") {
    // ISO date string
    return dayjs(timestamp);
  }
  return null; // Or throw an error, or whatever you'd like
};

export const useListenForIncomingTx = ({
  selectedAccount,
  accounts,
  user,
  org,
  db,
  setIncomingTx,
  setAcceptedTx,
}) => {
  useEffect(() => {
    if (selectedAccount) {
      const thisAccountsPlaidId = accounts.find(
        (account) => account.id === selectedAccount,
      ).plaidAccountId;
      if (user && org && thisAccountsPlaidId) {
        const incomingQuery = query(
          collection(db, "orgs", org, "incomingTransactions"),
          where("account_id", "==", thisAccountsPlaidId),
        );
        const acceptedQuery = query(
          collection(db, "orgs", org, "acceptedPlaidTx"),
          where("fbAccountId_integrated", "==", `${selectedAccount}_false`),
        );
        const unsubscribeIncoming = onSnapshot(incomingQuery, (querySnapshot) =>
          handleTransactionChanges(querySnapshot, setIncomingTx),
        );
        const unsubscribeAccepted = onSnapshot(
          acceptedQuery,
          (querySnapshot) => {
            handleTransactionChanges(querySnapshot, setAcceptedTx);
          },
        );
        return () => {
          unsubscribeIncoming();
          unsubscribeAccepted();
        };
      }
    }
  }, [user, org, selectedAccount, accounts, db, setIncomingTx, setAcceptedTx]);
};

export const useListenForIncomingPayments = ({
  selectedAccount,
  accounts,
  user,
  org,
  db,
  setIncomingPayments,
}) => {
  useEffect(() => {
    if (selectedAccount) {
      const thisAccountsPlaidId = accounts.find(
        (account) => account.id === selectedAccount,
      ).plaidAccountId;
      if (user && org && thisAccountsPlaidId) {
        const incomingQuery = query(
          collection(db, "orgs", org, "acceptedPlaidCCPayments"),
          where("account_id", "==", thisAccountsPlaidId),
        );

        const unsubscribeIncoming = onSnapshot(incomingQuery, (querySnapshot) =>
          handleTransactionChanges(querySnapshot, setIncomingPayments),
        );

        return () => {
          unsubscribeIncoming();
        };
      }
    }
  }, [user, org, selectedAccount, accounts, db, setIncomingPayments]);
};

export const useListenForImportedTransactions = ({
  selectedAccount,
  accounts,
  user,
  org,
  db,
  setImportedToHandle,
}) => {
  useEffect(() => {
    if (selectedAccount) {
      if (user && org) {
        const incomingQuery = query(
          collection(db, "orgs", org, "importedTransactions"),
          where("intoAccount", "==", selectedAccount),
        );
        const unsubscribeIncoming = onSnapshot(incomingQuery, (querySnapshot) =>
          // set new transactions as transactionsToHandle with they doc id as the key and the data as the value
          setImportedToHandle(() => {
            const importedTransactions = querySnapshot.docs.reduce(
              (acc, doc) => {
                acc[doc.id] = doc.data();
                return acc;
              },
              {},
            );
            console.log("importedTransactions: ", importedTransactions);
            Object.entries(importedTransactions).forEach(([key, value]) => {
              console.log("key: ", key);
              console.log("value: ", value);
            });
            return importedTransactions;
          }),
        );

        return () => {
          unsubscribeIncoming();
        };
      }
    }
  }, [user, org, selectedAccount, accounts, db, setImportedToHandle]);
};

//Function for linking accounts between Plaid and Dive, creates a mapping in the database
export const linkAccounts = async ({
  org,
  accountToLink,
  setLinkAccountsModalOpen,
  db,
}) => {
  const accountsMappingRef = doc(
    db,
    "orgs",
    org,
    "organizationAndSettings",
    "accountsMapping",
  );

  const accountsMappingDoc = await getDoc(accountsMappingRef);
  const accountsMapping = accountsMappingDoc.data() || {};
  console.log("old accountsMapping: ", accountsMapping);
  const newAccountsMapping =
    Object.keys(accountsMapping).length > 0 ? { ...accountsMapping } : {};
  console.log("Account to link: ", accountToLink);
  newAccountsMapping[accountToLink[0]] = accountToLink[1];
  await setDoc(accountsMappingRef, newAccountsMapping);
  setLinkAccountsModalOpen(false);
};

// Helper function to handle transaction changes
const handleTransactionChanges = (querySnapshot, setTransactionState) => {
  setTransactionState((prevState) => {
    let updatedState = [...prevState];

    querySnapshot.docChanges().forEach((change) => {
      const changeData = {
        ...change.doc.data(),
        date: convertTimestampToDate(change.doc.data().date),
        //Note that category is an array of strings, and contains "Payment" if it is a payment
      };

      if (change.type === "added") {
        updatedState.push(changeData);
      } else if (change.type === "modified") {
        const index = updatedState.findIndex(
          (tx) => tx.transaction_id === change.doc.id,
        );
        if (index !== -1) {
          updatedState[index] = changeData;
        }
      } else if (change.type === "removed") {
        updatedState = updatedState.filter(
          (tx) => tx.transaction_id !== change.doc.id,
        );
      }
    });

    return updatedState;
  });
};

const AccountMappingTools = ({
  institutionAccounts,
  currentAccount,
  db,
  org,
  closeMappingModal,
}) => {
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  useEffect(() => {
    if (institutionAccounts?.length > 0 && currentAccount) {
      console.log("institutionAccounts: ", institutionAccounts);
      console.log("currentAccount: ", currentAccount);
      //Filter out accounts that are already linked and are don't match the cuurentAccount type: Assets = "depository"
      const newlyFilteredAccounts = institutionAccounts.filter(
        (account) =>
          !account.linkedToAccount &&
          ((account.type === "depository" &&
            currentAccount.accountType.toLowerCase() === "assets") ||
            (account.type === "credit" &&
              currentAccount.accountType.toLowerCase() === "liabilities")),
      );
      setFilteredAccounts(newlyFilteredAccounts);
    }
  }, [institutionAccounts, currentAccount]);

  return (
    <React.Fragment>
      <TitleText>Map Account</TitleText>
      <Close
        fontSize="large"
        style={{
          position: "absolute",
          marginLeft: "10px",
          height: "50px",
          transform: "translateX(42vw)",
          cursor: "pointer",
        }}
        onClick={() => closeMappingModal()}
      />
      <LineText>Your Dive Account</LineText>
      {currentAccount?.accountNumber && (
        <DetailText>{` ${currentAccount.accountNumber} - ${currentAccount.accountName}`}</DetailText>
      )}
      <CloudUploadOutlined fontSize="large" />
      <LineText>Your Bank Accounts</LineText>
      {filteredAccounts.length > 0 &&
        filteredAccounts.map((account) => {
          return (
            <CreateLinkButton
              style={{ flexDirection: "column", width: "350px" }}
              onClick={() =>
                linkAccounts({
                  db,
                  org,
                  //This array will form the new key/value for the accountsMapping object
                  accountToLink: [account.account_id, currentAccount.id],
                  setLinkAccountsModalOpen: closeMappingModal,
                })
              }>
              <DetailText>{`${account.name} - ${account.mask}`}</DetailText>
              <DetailText>{`$${account.balances.current}`}</DetailText>
            </CreateLinkButton>
          );
        })}
      {/* <StyledButton
        primary
        onClick={() => {
          linkAccounts({
            db,
            org,
            accountToLink,
            setLinkAccountsModalOpen: closeMappingModal,
          });
        }}>
        Link Accounts
      </StyledButton> */}
    </React.Fragment>
  );
};

export default AccountMappingTools;
