import { Checkbox, TableCell, TableRow } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { DetailText } from "../mass_import_styles";
import { ArrowDownward, CallSplit } from "@mui/icons-material";
import { returnCurrency } from "../../general_util";
import SelectContact from "./contact_chooser";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import UserContext from "../../../assets/user_context";
import SelectAccount from "./account_chooser";
import SelectFund from "./fund_chooser";
import styled from "@emotion/styled";

const StartSplittingIcon = styled(CallSplit)`
  color: white;
  cursor: pointer;
  transform: rotate(90deg);
`;

const StyledTableCell = styled(TableCell)`
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TransactionTopRow = ({
  transaction,
  txkey,
  activelySplitting,
  setActivelySplitting,
  selectedTransactions,
  handleTransactionSelection,
}) => {
  const db = useAuth().db;
  const { org } = useContext(UserContext);

  //sum total of split amounts and check if it is equal with transaction.debit or transaction.credit
  //TODO: Calc because if all splits are assigned we don't need a bottom border even though we're not activelySplitting

  const setTransactionContact = async ({ transactionId, contactId }) => {
    console.log(
      "Setting contact for transaction: ",
      transactionId,
      txkey,
      org,
      contactId,
    );
    const transactionDoc = doc(
      db,
      "orgs",
      org,
      "importedTransactions",
      transactionId,
    );
    //check if this doc exists
    const transaction = await getDoc(transactionDoc);
    if (!transaction.exists()) {
      console.error("No transaction by that ID!");
      return;
    }
    try {
      console.log("Transaction document path: ", transactionDoc.path);
      console.log("Setting contactId: ", contactId);

      await updateDoc(transactionDoc, {
        contact: contactId,
      });
      console.log("Contact set for transaction: ", transactionId, contactId);
    } catch (error) {
      console.error("Error setting contact for transaction: ", error);
    }
  };

  const setTransactionAccount = async ({ transactionId, accountId }) => {
    console.log(
      "Setting account for transaction: ",
      transactionId,
      txkey,
      org,
      accountId,
    );
    const transactionDoc = doc(
      db,
      "orgs",
      org,
      "importedTransactions",
      transactionId,
    );
    //check if this doc exists
    const transaction = await getDoc(transactionDoc);
    if (!transaction.exists()) {
      console.error("No transaction by that ID!");
      return;
    }
    try {
      console.log("Transaction document path: ", transactionDoc.path);
      console.log("Setting accountId: ", accountId);

      await updateDoc(transactionDoc, {
        account: accountId,
      });
      console.log("Account set for transaction: ", transactionId, accountId);
    } catch (error) {
      console.error("Error setting account for transaction: ", error);
    }
  };

  const setTransactionFund = async ({ transactionId, fundId }) => {
    console.log(
      "Setting fund for transaction: ",
      transactionId,
      txkey,
      org,
      fundId,
    );
    const transactionDoc = doc(
      db,
      "orgs",
      org,
      "importedTransactions",
      transactionId,
    );
    //check if this doc exists
    const transaction = await getDoc(transactionDoc);
    if (!transaction.exists()) {
      console.error("No transaction by that ID!");
      return;
    }
    try {
      console.log("Transaction document path: ", transactionDoc.path);
      console.log("Setting fundId: ", fundId);

      await updateDoc(transactionDoc, {
        fund: fundId,
      });
      console.log("Fund set for transaction: ", transactionId, fundId);
    } catch (error) {
      console.error("Error setting fund for transaction: ", error);
    }
  };

  //Log selectedTransactions when they change
  useEffect(() => {
    console.log(selectedTransactions, selectedTransactions?.[txkey]);
  }, [selectedTransactions]);

  return (
    <TableRow key={txkey}>
      <TableCell sx={{ p: 0, borderBottom: "none" }}>
        <Checkbox
          checked={selectedTransactions?.[txkey] || false}
          onChange={(e) => {
            handleTransactionSelection({ key: txkey, e });
          }}
        />
      </TableCell>
      <TableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        <DetailText style={{ fontSize: "1rem" }}>{transaction.date}</DetailText>
      </TableCell>
      <StyledTableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        <DetailText style={{ opacity: 0.7, margin: 0 }}>
          {transaction.contactRawText}
        </DetailText>
        {transaction.contact && (
          <ArrowDownward style={{ color: "white", paddingLeft: "4rem" }} />
        )}
        <SelectContact
          transactionContactValue={transaction.contact}
          txkey={txkey}
          setTransactionContact={setTransactionContact}
          error={transaction.errors?.contact}
        />
      </StyledTableCell>
      <TableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        {!transaction?.splits?.length > 0 && (
          <SelectAccount
            transactionAccountValue={transaction.account}
            txkey={txkey}
            setAccount={setTransactionAccount}
            thisAccount={transaction.intoAccount}
            error={transaction.errors?.account}
          />
        )}
      </TableCell>
      <TableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        {!transaction?.splits?.length > 0 && (
          <SelectFund
            transactionFundValue={transaction.fund}
            txkey={txkey}
            setFund={setTransactionFund}
            error={transaction.errors?.fund}
          />
        )}
      </TableCell>
      <TableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        <DetailText
          style={
            transaction?.errors?.splitsAmount &&
            parseInt(transaction.errors.splitsAmount) !== 0
              ? { color: "red" }
              : { color: "white" }
          }>
          {transaction.debit === 0
            ? ""
            : transaction?.splits?.length > 0
            ? `${returnCurrency(transaction.debit)} (${returnCurrency(
                transaction.debit -
                  transaction.splits.reduce(
                    (acc, split) => acc + split.amount,
                    0,
                  ),
              )} left)`
            : returnCurrency(transaction.debit)}
        </DetailText>
      </TableCell>
      <TableCell
        sx={
          transaction?.splits?.length > 0 ||
          (activelySplitting && {
            borderBottom: "none",
          })
        }>
        <DetailText
          style={
            transaction?.errors?.splitsAmount &&
            parseInt(transaction.errors.splitsAmount) !== 0
              ? { color: "red" }
              : { color: "white" }
          }>
          {transaction.credit === 0
            ? ""
            : transaction?.splits?.length > 0
            ? `${returnCurrency(transaction.credit)} (${returnCurrency(
                transaction.credit -
                  transaction.splits.reduce(
                    (acc, split) => acc + split.amount,
                    0,
                  ),
              )} left)`
            : returnCurrency(transaction.credit)}
        </DetailText>
      </TableCell>
      <TableCell sx={{ borderBottom: "none" }}>
        {!activelySplitting && (
          <StartSplittingIcon onClick={() => setActivelySplitting(true)} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TransactionTopRow;
