import React, { useContext } from "react";
import { CenteringDiv } from "../../assets/containers";
import {
  ActionButton,
  ActionButtonText,
  DescriptiveText,
  FootnoteDiv,
  HorizontalDiv,
  OptionCard,
  ThemedTitleText,
  TitleText,
} from "./onboarding_styles";
import { chooseExperimental, choosePower } from "./onboarding_logic";
import UserContext from "../../assets/user_context";
import { useAuth } from "../../services/use-auth";
import { ConfirmAction } from "../../assets/controls";
import { useState } from "react";
import { doc, writeBatch } from "firebase/firestore";
import {
  defaultAccounts,
  defaultAccountsHierarchy,
  defaultFunds,
  defaultFundsHierarchy,
} from "./defaultsForExpert";

const ChooseAPath = () => {
  const { org, setExperimental } = useContext(UserContext);
  const authHook = useAuth();
  const [confirmExpert, setConfirmExpert] = useState(false);

  const chooseExpert = async (retryCount = 3) => {
    let attempts = 0;
    let success = false;

    while (attempts < retryCount && !success) {
      try {
        const batch = writeBatch(authHook.db);

        const orgRef = doc(authHook.db, "orgs", org);
        const accountsHierarchyRef = doc(
          authHook.db,
          "orgs",
          org,
          "organizationAndSettings",
          "accountsHierarchy",
        );
        const fundsHierarchyRef = doc(
          authHook.db,
          "orgs",
          org,
          "organizationAndSettings",
          "fundsHierarchy",
        );

        batch.set(accountsHierarchyRef, defaultAccountsHierarchy);
        batch.set(fundsHierarchyRef, defaultFundsHierarchy);

        defaultAccounts.forEach((account) => {
          const accountRef = doc(
            authHook.db,
            "orgs",
            org,
            "accounts",
            account.id,
          );
          batch.set(accountRef, account);
        });

        defaultFunds.forEach((fund) => {
          const fundRef = doc(authHook.db, "orgs", org, "funds", fund.id);
          batch.set(fundRef, fund);
        });

        batch.update(orgRef, { onboarded: true }, { merge: true });

        await batch.commit();
        success = true; // If commit is successful, set success to true
      } catch (error) {
        attempts++;
        console.error(`Attempt ${attempts} failed:`, error);
        if (attempts >= retryCount) {
          throw new Error(`Failed after ${attempts} attempts`);
        }
      }
    }
  };

  return (
    <CenteringDiv>
      <TitleText>Choose Your Path</TitleText>
      <HorizontalDiv>
        <OptionCard>
          <ThemedTitleText>Power Setup ⚡️</ThemedTitleText>
          <DescriptiveText>
            This is the recommended path. Our onboarding will guide you through
            importing a transactions file, which will also create your initial
            chart of accounts and contact list. We'll provide a template to
            input your current bookkeeping data.
          </DescriptiveText>
          <ActionButton onClick={() => choosePower({ db: authHook.db, org })}>
            <ActionButtonText>Take off 🚀</ActionButtonText>
          </ActionButton>
        </OptionCard>
        {/* <OptionCard>
          <ThemedTitleText>Experimental 🧪</ThemedTitleText>
          <DescriptiveText>
            This path will allow you to poke around Dive using demo accounts,
            contacts, and transactions. Kick the tires, and when you're ready to
            begin your account just refresh your screen. Any changes you make
            will not be saved, so don't worry about kicking too hard.
          </DescriptiveText>
          <ActionButton
            onClick={() => {
              chooseExperimental({ db: authHook.db, org });
              setExperimental(true);
            }}>
            <ActionButtonText>Poke around 🔎</ActionButtonText>
          </ActionButton>
        </OptionCard> */}
        <OptionCard>
          <ThemedTitleText>Fresh Start 🌱</ThemedTitleText>
          <DescriptiveText>
            This will bypass the onboarding process and start you with a fresh
            account in Dive. All accounts, funds, etc.. will need to be setup
            manually. If you don't have information to migrate, this is the path
            for you.
          </DescriptiveText>
          <ActionButton onClick={() => setConfirmExpert(true)}>
            <ActionButtonText>Dive in 🤿</ActionButtonText>
          </ActionButton>
        </OptionCard>
      </HorizontalDiv>
      <HorizontalDiv>
        <FootnoteDiv>
          <DescriptiveText style={{ marginTop: "0" }}>
            Payment information will be required at the end, but the first 14
            days are free!
          </DescriptiveText>
        </FootnoteDiv>
        <FootnoteDiv style={{ width: "25vw" }}>
          <DescriptiveText style={{ marginTop: "0" }}>
            Payment information will be required at the end, but the first 14
            days are free!
          </DescriptiveText>
        </FootnoteDiv>
      </HorizontalDiv>
      <ConfirmAction
        callback={chooseExpert}
        openState={confirmExpert}
        handleCloseState={() => setConfirmExpert(false)}
        dataForAction={true}>
        finish onboarding and just let me dive in!
      </ConfirmAction>
    </CenteringDiv>
  );
};

export default ChooseAPath;
