import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  primary_light_color,
  primary_dark_color,
  primary_color,
} from "../../../constants/color_constants";
import { ExpandMore } from "@mui/icons-material";

//This will help clean up media queries
const mediaQuery = (maxWidth) => `
  @media (max-width: ${maxWidth}px)
`;

const generateMediaQuery = (maxWidth, headerWidths) => {
  return (props) => {
    let mediaQueryCss = `@media (max-width: ${maxWidth}px) {`;

    const width = headerWidths[props.header];
    if (width) {
      mediaQueryCss += `width: ${width}px;`;
    }

    mediaQueryCss += "}";

    return mediaQueryCss;
  };
};

export const TransactionsComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  padding: 3rem;
  margin-top: 4rem;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: ${primary_dark_color} 0px 0px 10px 0px;
  padding: 5px;
`;

export const ModalBoxLight = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: ${primary_dark_color} 0px 0px 10px 0px;
  padding: 5px;
  width: 90vw;
`;

export const MassImportModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90vw;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: ${primary_dark_color} 0px 0px 10px 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const PageTitle = styled.p`
  font-size: 2.3rem;
  font-family: "MontserratBold";
  margin-bottom: 1rem;
  color: ${primary_dark_color};
  text-align: center;
  margin-top: 0px;
`;

export const AddEntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.75rem;
  cursor: pointer;
  border: 2px solid ${primary_dark_color};
  border-radius: 1rem;
`;

export const TableText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  text-align: left;
`;

export const TableTextSmall = styled.p`
  font-size: 0.8rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  text-align: left;
`;

export const ScrollableContainer = styled.div`
  max-height: ${(props) => props.height || "75vh"};
  overflow-y: auto;
`;

export const ActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export const StyledTitleText = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
  //outline text
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${primary_dark_color};
  margin-top: 6rem;
`;

export const StyledHeaderText = styled.p`
  display: inline-block;
  font-size: 1.6rem;
  font-family: "MontserratBold";
  text-align: ${(props) => (props.header === "Balance" ? "right" : "left")};
  color: white;
  padding: 8px;
  padding-right: 2px;
  margin: 0px;
  ${mediaQuery(2200)} {
    font-size: 1.4rem;
  }
  ${mediaQuery(1800)} {
    font-size: 1.3rem;
  }
  ${mediaQuery(1600)} {
    font-size: 1.2rem;
  }
  ${mediaQuery(1400)} {
    font-size: 1.1rem;
  }
  ${mediaQuery(1200)} {
    font-size: 1rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 0.9rem;
  }
  ${mediaQuery(900)} {
    font-size: 0.8rem;
  }
`;

export const MobileWarningWrapper = styled.div`
  display: none; // By default, it will not display
  position: absolute;
  z-index: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${primary_dark_color};
  border-radius: 3rem;
  padding: 1rem;
  bottom: 2rem; // 1 rem above the bottom edge
  left: 1rem;
  right: 1rem;

  @media (max-width: 799px) {
    display: flex; // Will display on screens smaller than 600px
  }
`;

export const WarningText = styled.p`
  font-size: 1.4rem;
  font-family: "MontserratBold";
  text-align: center;
  color: white;
  padding: 8px;
  margin: 0px;
`;

export const DetailText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  text-align: left;
  color: ${primary_dark_color};
  padding-left: 25px;
  padding-right: 25px;
`;

export const ItemTitleText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  text-align: left;
  color: ${primary_color};
  padding-left: 25px;
  padding-right: 25px;
  white-space: nowrap;
`;

export const ItemText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  text-align: left;
  color: ${primary_color};
  padding-left: 25px;
  padding-right: 25px;
  white-space: nowrap;
`;

export const ItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const ItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
`;

export const ItemLineHeader = styled.th`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  text-align: left;
  color: ${primary_color};
  padding-left: 25px;
  padding-right: 25px;
  white-space: nowrap;
`;

//Responsive Items organized here
export const LineText = styled.p`
  font-size: 1.5rem;
  font-family: "MontserratMed";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: ${(props) => (props.alignRight ? "right" : "left")};
  ${mediaQuery(2200)} {
    font-size: 1.3rem;
  }
  ${mediaQuery(1800)} {
    font-size: 1.1rem;
  }
  ${mediaQuery(1400)} {
    font-size: 1rem;
  }
  ${mediaQuery(1200)} {
    font-size: 1rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 0.9rem;
    font-stretch: condensed;
  }
  ${mediaQuery(900)} {
    font-size: 0.8rem;
    font-stretch: condensed;
  }
  ${(props) =>
    props.attribute === "Contact" ||
    props.attribute === "Fund" ||
    props.attribute === "Account"
      ? `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`
      : null}
`;

//Main Table Elements

export const TableWrapperDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto; // Enable scrolling here
  height: ${(props) =>
    props.height ? props.height : "700px"}; // Fixed height for the wrapper
`;

export const TableWrapperInnerDiv = styled.div`
  height: 100%;
  width: 97%;
`;

export const StyledTableHeaderRow = styled.tr``;

export const StyledTableHeaderColumn = styled.th`
  padding-left: 5px;
  position: sticky;
  top: 0;

  //display: flex;
  border-radius: ${(props) =>
    props.header === "Date"
      ? "1rem 0 0 1rem"
      : props.header === "Balance" ||
        (props.header === "Clear" && props.isBankRec)
      ? "0 1rem 1rem 0"
      : null};
  background-color: ${primary_dark_color};

  ${(props) =>
    props.header === "Date" ||
    props.header === "Inflow" ||
    props.header === "Outflow"
      ? `cursor: pointer;`
      : null}

  ${(props) =>
    props.header === "Clear"
      ? `width: 95px; text-align: left; margin-right: 10px;`
      : props.isBankRec
      ? "border-radius: 0 1rem 1rem 0"
      : null} //At the argument pixels, set the width of the columns to the argument width, should only need to 
  //  do this for those that we don't want trucated

  ${(props) =>
    props.header === "Date"
      ? `width: 130px;`
      : props.header === "Inflow" || props.header === "Outflow"
      ? `width: 190px;`
      : null}
       //At the argument pixels, set the width of the columns to the argument width, should only need to
  ${generateMediaQuery(1800, {
    Date: 130,
    Inflow: 160,
    Outflow: 160,
    Balance: 170,
    Clear: 85,
  })}
  ${generateMediaQuery(1600, {
    Date: 130,
    Inflow: 150,
    Outflow: 150,
    Balance: 160,
    Clear: 80,
  })}
  ${generateMediaQuery(1400, {
    Date: 120,
    Inflow: 140,
    Outflow: 140,
    Balance: 150,
    Clear: 80,
  })}
  ${generateMediaQuery(1200, {
    Date: 110,
    Inflow: 125,
    Outflow: 125,
    Balance: 140,
    Clear: 75,
  })}
  ${generateMediaQuery(1000, {
    Date: 110,
    Inflow: 100,
    Outflow: 100,
    Balance: 130,
    Clear: 70,
  })}
  ${generateMediaQuery(800, {
    Date: 110,
    Inflow: 90,
    Outflow: 90,
    Balance: 120,
    Clear: 60,
  })}
  ${generateMediaQuery(600, {
    Date: 100,
    Inflow: 85,
    Outflow: 85,
    Balance: 110,
    Clear: 60,
  })}
`;

export const StyledTbody = styled.tbody`
  /* display: block; */
  /* max-height: ${(props) => props.height || "850px"}; */
  overflow-y: auto;
  /* width: 100%; */
  height: auto;
`;

export const StyledTableRow = styled.tr`
  padding-right: 10px;
  //display: flex;
  cursor: ${(props) => (props.optimistic ? "not-allowed" : "pointer")};

  background-color: ${(props) =>
    props.optimistic
      ? `#d4f9d5`
      : props.index % 2 === 0
      ? `${primary_light_color}`
      : props.header
      ? `${primary_dark_color}`
      : `#FFFFF`};
`;

export const StyledTableItem = styled.td`
  font-size: 20px;
  font-family: "MontserratMed";
  text-align: left;
  color: ${primary_dark_color};

  padding-right: 5px;
  border-radius: ${(props) =>
    props.isSingle && props.attribute === "Date"
      ? "1rem  0 0 1rem"
      : props.isSingle && props.attribute === "runningTotal"
      ? "0 1rem 1rem 0"
      : props.isSingle && props.attribute === "Clear" && props.isBankRec
      ? "0 1rem 1rem 0"
      : props.isFirst && props.attribute === "Date"
      ? "1rem 0 0 0"
      : props.isLast && props.attribute === "Date"
      ? "0  0 0 1rem"
      : props.isFirst && props.attribute === "runningTotal"
      ? "0 1rem  0 0"
      : props.isLast && props.attribute === "runningTotal"
      ? "0  0 1rem 0 "
      : props.isFirst && props.attribute === "Clear" && props.isBankRec
      ? "0 1rem 0 0"
      : props.isLast && props.attribute === "Clear" && props.isBankRec
      ? "0 0 1rem 0"
      : null};
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

export const ExpandCollapsedButton = styled(ExpandMore)`
  color: ${primary_dark_color};
  width: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  ${(props) =>
    props.isExpanded
      ? `transform: rotate(0deg);`
      : `transform: rotate(-90deg);`}
  transition: transform 0.3s ease-out;
`;
