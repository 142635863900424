const sortByDate = ({ a, b, inverse }) => {
  if (!inverse) {
    return b.date - a.date;
  } else {
    return a.date - b.date;
  }
};

const sortByAmount = ({ a, b, sign, filterAccount, inverse }) => {
  const aAmount = a.lines
    .filter((line) => !filterAccount || line.account === filterAccount)
    .reduce((acc, line) => {
      if (line.sign === sign) {
        return acc + parseFloat(line.amount);
      } else {
        return acc;
      }
    }, 0);

  const bAmount = b.lines
    .filter((line) => !filterAccount || line.account === filterAccount)
    .reduce((acc, line) => {
      if (line.sign === sign) {
        return acc + parseFloat(line.amount);
      } else {
        return acc;
      }
    }, 0);
  if (inverse === true) {
    return aAmount - bAmount;
  } else {
    return bAmount - aAmount;
  }
};

export const sortTransactions = (a, b, sortBy, filterAccount) => {
  if (sortBy === "date") {
    return sortByDate({ a, b, inverse: false });
  } else if (sortBy === "inverseDate") {
    return sortByDate({ a, b, inverse: true });
  } else if (sortBy === "debit") {
    return sortByAmount({ a, b, sign: "debit", filterAccount, inverse: false });
  } else if (sortBy === "credit") {
    return sortByAmount({
      a,
      b,
      sign: "credit",
      filterAccount,
      inverse: false,
    });
  } else if (sortBy === "inverseDebit") {
    return sortByAmount({ a, b, sign: "debit", filterAccount, inverse: true });
  } else if (sortBy === "inverseCredit") {
    return sortByAmount({
      a,
      b,
      sign: "credit",
      filterAccount,
      inverse: true,
    });
  } else {
    return sortByDate(a, b);
  }
};
