import styled from "@emotion/styled";
import { ReactComponent as LoadingLogo } from "../assets/logos/animated-logo.svg";

const LoadingScreenDiv = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const LoadingScreenOverlay = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0.6;
  backdrop-filter: blur(15px);
`;

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  transform: translateY(-15vh);
  width: 17vw;
  @media only screen and (max-width: 2500px) {
    width: 20vw;
  }
  @media only screen and (max-width: 1500px) {
    width: 25vw;
  }
  @media only screen and (max-width: 1500px) {
    width: 30vw;
  }
  @media only screen and (max-width: 1200px) {
    width: 35vw;
  }
  @media only screen and (max-width: 1000px) {
    width: 40vw;
  }
  @media only screen and (max-width: 800px) {
    width: 45vw;
  }
  @media only screen and (max-width: 650px) {
    width: 55vw;
  }
  @media only screen and (max-width: 450px) {
    width: 80vw;
  }
`;

const LoadingScreen = () => {
  return (
    <LoadingScreenDiv>
      <LoadingLogoStyled />
      <LoadingScreenOverlay />
    </LoadingScreenDiv>
  );
};

export default LoadingScreen;
