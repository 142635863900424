import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { produce } from "immer";
import styled from "@emotion/styled";
import UserContext from "../../../assets/user_context";
import { findFundById } from "../../../utilities/general_util";

const FundDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3rem;
  background-color: #80a4ad;
  padding: 0.5rem;
  padding-left: 1rem;
  width: 250px;
`;

const SubFundDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3rem;
  background-color: #acd4a0;
  padding: 0.5rem;
  padding-left: 1rem;
  width: 220px;
  margin-bottom: 0.5rem;
`;

const GroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  background-color: #e7eff1;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-left: 2rem;
  width: 350px;
  margin: 0.5rem;
`;

const findFund = (groups, id) => {
  for (const groupObj of groups) {
    for (const fund of groupObj.funds) {
      if (fund.id === id) {
        return fund;
      }
    }
  }
  return null;
};

const FundsRearrange = ({ groupsIn, setDraftGroups }) => {
  const { funds } = useContext(UserContext);
  const [groups, setGroups] = useState(groupsIn);

  useEffect(() => {
    setDraftGroups((prev) => {
      const newDraft = { ...prev };
      newDraft.groups = groups;
      return newDraft;
    });
  }, [groups, setDraftGroups]);

  const renderSubDraggables = (item) => (
    <ul>
      {item?.subFunds?.length > 0 &&
        item.subFunds.map((subItem, index) => (
          <Draggable
            key={subItem}
            draggableId={`${item.id}-${subItem}`}
            index={index}>
            {(provided) => (
              <SubFundDiv
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {`${findFundById({ fundId: subItem, funds }).fundNumber} - ${
                  findFundById({ fundId: subItem, funds }).fundName
                }`}
              </SubFundDiv>
            )}
          </Draggable>
        ))}
    </ul>
  );

  const findAndRemoveItem = (groups, groupId, index) => {
    for (const groupObj of groups) {
      if (groupObj.groupName === groupId) {
        return groupObj.funds.splice(index, 1)[0];
      }
      if (Array.isArray(groupObj.funds)) {
        for (const fund of groupObj.funds) {
          if (fund?.subFunds) {
            const found = findAndRemoveItem(fund.subFunds, groupId, index);
            if (found) {
              return found;
            }
          }
        }
      }
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, combine, draggableId } = result;
    if (!destination && !combine) return;

    setGroups(
      produce((draft) => {
        let moved;
        if (result.type === "group") {
          const [movedGroup] = draft.splice(source.index, 1);
          draft.splice(destination.index, 0, movedGroup);
        } else if (destination) {
          if (draggableId.includes("-")) {
            const parentFundId = draggableId.split("-")[0];
            const subFundId = draggableId.split("-")[1];
            const parentFund = findFund(draft, parentFundId);

            if (parentFund) {
              const subFundIndex = parentFund.subFunds.indexOf(subFundId);
              if (subFundIndex !== -1) {
                parentFund.subFunds.splice(subFundIndex, 1);
              }
            }

            const newParentFund = {
              id: subFundId,
              subFunds: [],
            };

            const toGroup =
              draft.find((groupObj) =>
                groupObj.funds.some(
                  (fund) => fund.id === destination.droppableId,
                ),
              ) ||
              draft.find(
                (groupObj) => groupObj.groupName === destination.droppableId,
              );

            if (toGroup) {
              toGroup.funds.splice(destination.index, 0, newParentFund);
            }
          } else {
            const fromGroup = draft.find(
              (groupObj) => groupObj.groupName === source.droppableId,
            );
            const toGroup = draft.find(
              (groupObj) => groupObj.groupName === destination.droppableId,
            );

            if (fromGroup && toGroup) {
              const [movedFund] = fromGroup.funds.splice(source.index, 1);
              toGroup.funds.splice(destination.index, 0, movedFund);
            }
          }
        }
        if (combine) {
          if (!moved) {
            moved = findAndRemoveItem(draft, source.droppableId, source.index);
          }

          if (draggableId.includes("-")) {
            const parentFundId = draggableId.split("-")[0];
            const subFundId = draggableId.split("-")[1];
            const parentFund = findFund(draft, parentFundId);

            if (parentFund) {
              const subFundIndex = parentFund.subFunds.indexOf(subFundId);
              if (subFundIndex !== -1) {
                parentFund.subFunds.splice(subFundIndex, 1);
              }
            }
          }
          const toGroup = draft.find(
            (groupObj) => groupObj.groupName === combine.droppableId,
          );
          const parentItem = toGroup.funds.find(
            (fund) => fund.id === combine.draggableId,
          );
          if (parentItem) {
            if (parentItem?.subFunds?.length > 0) {
              parentItem.subFunds.push(moved.id);
              if (moved.subFunds?.length > 0) {
                moved.subFunds.forEach((subFund) =>
                  parentItem.subFunds.push(subFund),
                );
              }
            } else {
              parentItem["subFunds"] = [moved.id];
              if (moved.subFunds?.length > 0) {
                moved.subFunds.forEach((subFund) =>
                  parentItem.subFunds.push(subFund),
                );
              }
            }
          }
        }
      }),
    );
  };

  useEffect(() => {
    console.log("groups:", groups);
  }, [groups]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-groups" type="group">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {groups.map((groupObj, groupIndex) => (
              <Draggable
                key={groupObj.groupName}
                draggableId={groupObj.groupName}
                index={groupIndex}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <Droppable
                      droppableId={groupObj.groupName}
                      key={groupIndex}
                      isCombineEnabled>
                      {(provided) => (
                        <GroupDiv
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          <h2>{groupObj.groupName}</h2>
                          {groupObj.funds.map((fund, index) => (
                            <Draggable
                              key={fund.id}
                              draggableId={fund.id}
                              index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <FundDiv>
                                    {`${
                                      findFundById({
                                        fundId: fund.id,
                                        funds,
                                      }).fundNumber
                                    } - ${
                                      findFundById({
                                        fundId: fund.id,
                                        funds,
                                      }).fundName
                                    }`}
                                  </FundDiv>
                                  <Droppable
                                    droppableId={`${fund.id}`}
                                    isCombineEnabled>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {renderSubDraggables(fund)}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </GroupDiv>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FundsRearrange;
