import { useContext, useState } from "react";
import UserContext from "../../assets/user_context.jsx";
import { ComponentPageWrapper } from "../component_styles.jsx";
import {
  ClickableDiv,
  TitleText,
  SubtitleText,
  ModalBox,
  ModalInner,
  LineText,
  AddUserRowWrapper,
} from "./account_styles.jsx";
import { AddCircleOutline } from "@mui/icons-material";
import { Modal, TextField, Switch } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { collection, addDoc } from "firebase/firestore";
import StyledButton from "../../assets/buttons";
import { useAuth } from "../../services/use-auth";

const Users = () => {
  const { orgData, user, org } = useContext(UserContext);
  const [openAddUser, setOpenAddUser] = useState(false);

  const authHook = useAuth();
  const db = authHook.db;

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .max(30, "Must be 30 characters or less")
      .required("You must include an email address"),
    admin: Yup.boolean(),
    subscriber: Yup.boolean(),
    auditor: Yup.boolean(),
    bookkeeper: Yup.boolean(),
  });

  // Initialize React Hook Form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      admin: false,
      subscriber: false,
      auditor: false,
      bookkeeper: false,
    },
  });

  // Handle form submission
  const onSubmit = async (values) => {
    const newUserData = {
      ...values,
      inviteBy: user.uid,
      toOrg: org,
    };
    console.log("Adding user: ", newUserData);
    const invitesRef = collection(db, "orgs", org, "invites");
    await addDoc(invitesRef, newUserData);
    console.log("Added user: ", newUserData);
    setOpenAddUser(false);
  };

  return (
    <ComponentPageWrapper>
      <Modal open={openAddUser} onClose={() => setOpenAddUser(false)}>
        <ModalBox>
          <ModalInner>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TitleText>Invite a New User</TitleText>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New User Email"
                    color="secondary"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    sx={{ margin: "15px", width: "450px" }}
                  />
                )}
              />
              <AddUserRowWrapper>
                <LineText>Admin</LineText>
                <Controller
                  name="admin"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      color="secondary"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      sx={{ margin: "15px" }}
                    />
                  )}
                />
              </AddUserRowWrapper>
              <AddUserRowWrapper>
                <LineText>Subscriber</LineText>
                <Controller
                  name="subscriber"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      color="secondary"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      sx={{ margin: "15px" }}
                    />
                  )}
                />
              </AddUserRowWrapper>
              <AddUserRowWrapper>
                <LineText>Auditor</LineText>
                <Controller
                  name="auditor"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      color="secondary"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      sx={{ margin: "15px" }}
                    />
                  )}
                />
              </AddUserRowWrapper>
              <AddUserRowWrapper>
                <LineText>Bookkeeper</LineText>
                <Controller
                  name="bookkeeper"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      color="secondary"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      sx={{ margin: "15px" }}
                    />
                  )}
                />
              </AddUserRowWrapper>
              <StyledButton type="submit" primary adaptiveSize>
                Invite
              </StyledButton>
            </form>
          </ModalInner>
        </ModalBox>
      </Modal>
      <ClickableDiv onClick={() => setOpenAddUser(true)}>
        <AddCircleOutline />
        <SubtitleText>Add User </SubtitleText>
      </ClickableDiv>

      {orgData?.users && Object.entries(orgData.users).length > 0
        ? Object.entries(orgData.users).map(([uid, user]) => {
            return (
              <div key={uid}>
                {`${user.username ? user.username : uid} roles: `}
                {user.roles.map((role, index) => (
                  <span key={index}>
                    {role}
                    {index < user.roles.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            );
          })
        : null}
    </ComponentPageWrapper>
  );
};

export default Users;
