import { useNavigate } from "react-router-dom";
import { ComponentTile, StyledColumnDiv, StyledRowDiv } from "./parent_styles";

const Foundation = () => {
  const navigate = useNavigate();

  return (
    <StyledColumnDiv>
      <StyledRowDiv>
        <ComponentTile
          left
          top
          onClick={() => navigate("/foundation/chart-of-accounts")}>
          <ChartOfAccountsTile />
        </ComponentTile>
        <ComponentTile top onClick={() => navigate("/foundation/funds-list")}>
          <FundsListTile />
        </ComponentTile>
      </StyledRowDiv>
      <StyledRowDiv>
        <ComponentTile left onClick={() => navigate("/foundation/labels")}>
          <LabelsTile />
        </ComponentTile>
        <ComponentTile onClick={() => navigate("/foundation/budgets")}>
          <BudgetsTile />
        </ComponentTile>
      </StyledRowDiv>
    </StyledColumnDiv>
  );
};

const ChartOfAccountsTile = () => {
  return <h1>Chart of Accounts</h1>;
};

const FundsListTile = () => {
  return <h1>Funds List</h1>;
};

const LabelsTile = () => {
  return <h1>Labels</h1>;
};

const BudgetsTile = () => {
  return <h1>Budgets</h1>;
};

export default Foundation;
