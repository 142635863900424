import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../assets/user_context";
import {
  collection,
  doc,
  onSnapshot,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../../services/use-auth";
import styled from "@emotion/styled";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ModalBox, ModalInner } from "../component_styles";

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 2rem;
  gap: 1rem;
`;

const AdminUsers = () => {
  const { user } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [activeUserForChange, setActiveUserForChange] = useState({});
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [orgToAddToUser, setOrgToAddToUser] = useState(null);
  const [roleToSet, setRoleToSet] = useState("auditor");

  //Listener for orgs and users
  useEffect(() => {
    if (user) {
      const usersRef = collection(db, "users");
      const unSubUsers = onSnapshot(usersRef, (snapshot) => {
        const users = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setUsers(users);
      });
      const orgRef = collection(db, "orgs");
      const unSubOrgs = onSnapshot(orgRef, (snapshot) => {
        const orgs = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        console.log(orgs);
        setOrgs(orgs);
      });

      return () => {
        unSubOrgs();
        unSubUsers();
      };
    }
  }, [user]);

  const handleAddUser = async (orgId) => {
    console.log(
      `Adding user ${activeUserForChange.id} to org ${orgId} as ${roleToSet}`,
    );

    const roles = [roleToSet];

    try {
      await runTransaction(db, async (transaction) => {
        const orgRef = doc(db, "orgs", orgId);
        const userRef = doc(db, "users", activeUserForChange.id);

        // Get the documents
        const orgDoc = await transaction.get(orgRef);
        const userDoc = await transaction.get(userRef);

        if (!orgDoc.exists()) {
          throw new Error(`Org with ID ${orgId} does not exist!`);
        }
        if (!userDoc.exists()) {
          throw new Error(
            `User with ID ${activeUserForChange.id} does not exist!`,
          );
        }

        // Add user to org
        const originalOrgUsers = orgDoc.data().users;
        const newOrgUsers = {
          ...originalOrgUsers,
          [activeUserForChange.id]: {
            roles: roles,
            username: activeUserForChange.username,
            email: activeUserForChange.email,
          },
        };

        transaction.update(orgRef, { users: newOrgUsers });

        // Update user's orgs
        const updatedUserOrgs =
          userDoc.data()?.orgs?.length > 0
            ? [
                ...userDoc.data().orgs,
                {
                  orgID: orgId,
                  orgName: orgDoc.data().orgName,
                  orgType: orgDoc.data().orgType,
                },
              ]
            : [
                {
                  orgID: orgId,
                  orgName: orgDoc.data().orgName,
                  orgType: orgDoc.data().orgType,
                },
              ];

        transaction.update(userRef, { orgs: updatedUserOrgs });
      });

      console.log("Transaction successfully committed!");
      setAddUserModalOpen(false);
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  };

  return (
    <div>
      <Modal
        open={addUserModalOpen}
        onClose={() => {
          setAddUserModalOpen(false);
          setActiveUserForChange({});
          setOrgToAddToUser(null);
          setRoleToSet("auditor");
        }}>
        <ModalBox>
          <ModalInner>
            <h3>Add User to Org</h3>
            <p>{`Adding ${activeUserForChange.username} to ${
              orgToAddToUser !== null && orgToAddToUser !== undefined
                ? orgs.find((org) => org.id === orgToAddToUser).orgName
                : `...`
            }`}</p>
            <Autocomplete
              sx={{ minWidth: "400px" }}
              autoSelect
              autoHighlight
              id={`selectOrg`}
              key={`slectOrg-autocomplete`}
              name={`selectOrg-autcomplete`}
              options={
                orgs?.length > 0
                  ? orgs.filter(
                      //Filter out orgs that the user is already a part of
                      (org) =>
                        !activeUserForChange?.orgs?.some(
                          (userOrg) => userOrg.orgID === org.id,
                        ),
                    )
                  : []
              }
              getOptionLabel={(option) => option.orgName}
              value={
                orgToAddToUser
                  ? orgs.find((org) => org?.id === orgToAddToUser)
                  : null
              }
              onChange={(e, value) => {
                if (value !== null && value !== undefined) {
                  setOrgToAddToUser(value.id);
                } else {
                  setOrgToAddToUser(null);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Org" variant="outlined" />
              )}>
              {orgs.map((org, index) => (
                <option key={`org-${index}`} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Autocomplete>
            <br />
            <FormControl>
              <FormLabel id="radio-group-role">Role</FormLabel>
              <RadioGroup
                aria-labelledby="radio-group-role"
                name="role"
                value={roleToSet}
                onChange={(e) => setRoleToSet(e.target.value)}>
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="auditor"
                  control={<Radio />}
                  label="Auditor"
                />
                <FormControlLabel
                  value="subscriber"
                  control={<Radio />}
                  label="Subscriber"
                />
                <FormControlLabel
                  value="bookkeeper"
                  control={<Radio />}
                  label="Bookkeeper"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <button onClick={() => handleAddUser(orgToAddToUser)}>
              Add User
            </button>
          </ModalInner>
        </ModalBox>
      </Modal>
      <h3>Users</h3>
      <TextField
        label="Search"
        variant="outlined"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      {users?.length > 0 &&
        users
          .filter(
            (user) =>
              searchString === "" ||
              user.username.toLowerCase().includes(searchString.toLowerCase()),
          )
          .map((user, index) => (
            <UserWrapper key={`user-${index}`}>
              <p>{user.username}</p>
              <p>{user.email}</p>
              <button
                onClick={() => {
                  console.log(`Add ${user.username} to org`);
                  setActiveUserForChange(user);
                  setAddUserModalOpen(true);
                }}>
                Add to Org
              </button>
            </UserWrapper>
          ))}
    </div>
  );
};

export default AdminUsers;
