import dayjs from "dayjs";
import { findAccountById } from "../../../utilities/general_util";
import html2pdf from "html2pdf.js";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";

export const returnDateForDisplay = (date) => {
  // Check if the date is a Dayjs object
  if (dayjs.isDayjs(date)) {
    return date.format("MM/DD/YYYY");
  }

  // Check if the date is a native Date object
  if (date instanceof Date) {
    return date.toLocaleDateString();
  }

  if (
    date &&
    typeof date.seconds === "number" &&
    typeof date.nanoseconds === "number"
  ) {
    const dateFromTimestamp = new Date(date.seconds * 1000);
    return dateFromTimestamp.toLocaleDateString();
  }

  if (date?.$d?.toDate() instanceof Date) {
    return date.$d.toDate().toLocaleDateString();
  }
  if (
    date &&
    date.$d &&
    typeof date.$d === "object" &&
    date.$d instanceof Date
  ) {
    // If $d is a Date object, format it
    return date.$d.toLocaleDateString();
  } else if (
    date &&
    typeof date.$y === "number" &&
    typeof date.$M === "number" &&
    typeof date.$D === "number"
  ) {
    // Construct a new Date object from the year, month, and day properties
    const formattedDate = new Date(date.$y, date.$M, date.$D);
    return formattedDate.toLocaleDateString();
  } else {
    // If the date is neither, or it's undefined/null
    return "Invalid date";
  }
};

export const reportToPDF = ({ selectedAccount, recToDisplay, accounts }) => {
  const addPdfExportClass = (element) => {
    element.classList.add("pdf-export");
    Array.from(element.children).forEach(addPdfExportClass);
  };

  const renderTitle = (recToDisplay) => {
    return (
      <React.Fragment>
        <p
          style={{
            fontSize: "1.2rem",
            fontFamily: "MontserratMed",
            textAlign: "center",
            margin: "0.5rem",
          }}>
          {`Bank Reconciliation for ${
            findAccountById({ accountId: selectedAccount, accounts })
              .accountNumber
          }-${
            findAccountById({ accountId: selectedAccount, accounts })
              .accountName
          } on ${returnDateForDisplay(recToDisplay.closingDate)}`}
        </p>
      </React.Fragment>
    );
  };

  const element = document.getElementById("bank-rec-report");
  if (!element) {
    console.error("Element not found");
    return;
  }

  // Clone the element
  const clone = element.cloneNode(true);
  clone.classList.add("clone-for-pdf");

  // Find all <p> elements within <th> in the clone and add the 'pdf-header-text' class
  const headerParagraphs = clone.querySelectorAll("th > p"); // This selects <p> elements that are direct children of <th>
  headerParagraphs.forEach((p) => {
    p.classList.add("pdf-header-text");
  });

  // if (funds.length > 4) {
  //   clone.classList.add("small-text");
  // }

  addPdfExportClass(clone);

  // Measure the height of the clone
  const tempContainer = document.createElement("div");
  document.body.appendChild(tempContainer); // Temporarily add to the body
  tempContainer.appendChild(clone); // Append clone for measurement

  const contentHeight = clone.scrollHeight;
  document.body.removeChild(tempContainer); // Remove temp container after measurement

  // Create a container for the clone and title
  const container = document.createElement("div");
  container.style.width = "200mm"; // A4 width
  // container.style.height = "297mm"; // A4 height
  container.style.height = `${contentHeight}px`; //Test height
  container.style.display = "flex";
  container.style.flexDirection = "column";
  container.style.alignItems = "center";
  // container.style.visibility = "hidden"; // Make the container invisible while keeping it in the flow

  // Render the title to a string
  const titleHtml = ReactDOMServer.renderToString(renderTitle(recToDisplay));
  // Append title and clone to the container
  container.innerHTML = titleHtml;
  container.appendChild(clone);
  document.body.appendChild(container);

  console.log("Rec to display:", recToDisplay);

  // Define options for html2pdf
  const options = {
    margin: 10,
    filename: `Bank_Rec_${
      findAccountById({ accountId: selectedAccount, accounts }).accountNumber
    }-${
      findAccountById({ accountId: selectedAccount, accounts }).accountName
    }_${returnDateForDisplay(recToDisplay.closingDate)}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 3 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    pagebreak: { mode: ["css", "legacy"] },
  };

  // Generate PDF
  setTimeout(() => {
    html2pdf()
      .from(container)
      .set(options)
      .save()
      .then(() => {
        document.body.removeChild(container); // Clean up after saving PDF
      });
  }, 800); // Delay to ensure rendering
};

export const deleteRec = async ({ recId, db, org, userId }) => {
  console.log("Deleting rec", recId, org, db);
  await updateDoc(doc(db, `orgs/${org}/reconciliations/${recId}`), {
    deletedBy: userId,
  }).then(() => {
    return deleteDoc(doc(db, `orgs/${org}/reconciliations/${recId}`));
  });
};
