import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const possibleDateFormats = [
  "MM/DD/YYYY",
  "MM-DD-YYYY",
  "MM.DD.YYYY",
  "MM DD YYYY",
  "MMDDYYYY",
  "M/DD/YY",
  "M-DD-YY",
  "M.DD.YY",
  "M DD YY",
  "M/D/YY",
  "M-D-YY",
  "M.D.YY",
  "M D YY",
  "M/D/YYYY",
  "M-D-YYYY",
  "M.D.YYYY",
  "M D YYYY",
];

export const parseDate = (dateString) => {
  let dateObj = null;

  for (const format of possibleDateFormats) {
    const parsedDate = dayjs(dateString, format, true);

    if (parsedDate.isValid()) {
      dateObj = parsedDate.toDate();
      break;
    }
  }

  if (!dateObj) {
    console.error("Date could not be parsed: ", dateString, typeof dateString);
    return false;
  }

  return dateObj;
};

export const findMatchingContact = ({ contacts, incomingContactName }) => {
  // Return an empty string if the incoming contact name is empty or undefined
  if (!incomingContactName || incomingContactName.trim() === "") {
    return "";
  }

  // Normalize the incoming contact name and split into words
  const incomingWords = incomingContactName.toLowerCase().split(/\s+/);
  //remove any special characters or symbols
  incomingWords.forEach((word, index) => {
    incomingWords[index] = word.replace(/[^a-zA-Z0-9]/g, "");
  });

  //remove common words
  const commonWords = [
    "the",
    "and",
    "of",
    "for",
    "in",
    "on",
    "at",
    "to",
    "payment",
    "autopay",
  ];
  incomingWords.forEach((word, index) => {
    if (commonWords.includes(word)) {
      incomingWords.splice(index, 1);
    }
  });

  let highestMatchCount = 0;
  let highestMatchContact = null;

  // Try to find a match in the contacts list
  contacts.forEach((contact) => {
    // Safely access shortName and companyName, handling cases where they might not exist
    const shortName = contact.shortName || "";
    const companyName = contact.companyName || "";
    const previousMatchWords = contact.previousMatchWords || "";

    // Combine the names, normalize, and split into words
    const contactWords = (
      shortName +
      " " +
      companyName +
      " " +
      previousMatchWords
    )
      .toLowerCase()
      .split(/\s+/);

    console.log(
      "Contact Words: ",
      contactWords,
      "Incoming Words: ",
      incomingWords,
    );

    // Check if any word from the contact matches any word from the incoming name
    const matchCount = contactWords.filter((word) =>
      incomingWords.includes(word),
    ).length;

    // If the match count is higher than the current highest, update the highest match
    if (matchCount > highestMatchCount) {
      highestMatchCount = matchCount;
      highestMatchContact = contact;
    }
  });

  console.log("Matched ", incomingContactName, " to: ", highestMatchContact);

  return highestMatchContact ? highestMatchContact.id : "";
};

export const getAccountNumberFromString = (string) => {
  const firstPortion = string.split("-")[0];
  // Updated regex to match numbers that can include decimals
  const accountNumber = firstPortion.match(/\d+(\.\d+)?/g);

  // If there is an account number, return the first match, otherwise return null
  return accountNumber ? accountNumber[0] : null;
};

export const getFundNumberFromString = (string) => {
  const fundNumber = string.match(/\d+/g);
  //If there is an fund number, return it, otherwise return the string
  return fundNumber ? fundNumber[0] : null;
};

export const getAccountTextFromString = (string) => {
  const firstDashIndex = string.indexOf("-");
  // Ensure there is a dash and it's not the last character
  if (firstDashIndex !== -1 && firstDashIndex !== string.length - 1) {
    const secondPortion = string.substring(firstDashIndex + 1);
    console.log("Second Portion: ", secondPortion, "Full String:", string);

    // Match the desired characters
    const accountText = secondPortion.match(/[a-zA-Z0-9()\-\s]+/g);

    // If there is an account string, first trim it, then check if it's only spaces or numbers
    if (accountText) {
      const trimmedText = accountText.join(" ").trim();
      // Check if the resulting string is only spaces (should be empty after trim) or only numbers
      if (trimmedText === "" || /^\d+$/.test(trimmedText)) {
        return "";
      }
      return trimmedText;
    }
  }
  return null;
};

export const getFundTextFromString = (string) => {
  const fundText = string.match(/[a-zA-Z()]+/g);
  return fundText ? fundText.join(" ") : null;
};

const findFund = (fundIdentifier, funds) => {
  console.log("Finding fund: ", fundIdentifier);
  // const fundIdentifierClean = fundIdentifier.replace(/['", $]/g, "");
  if (!funds || !Array.isArray(funds)) {
    console.log(
      "Funds is not an array, returning fundIdentifier:",
      fundIdentifier,
    );
    return fundIdentifier; // Return fundIdentifier if funds is not an array
  }

  const fund = funds.find((fund) => {
    return fund.fundName === fundIdentifier;
  });

  return fund ? fund.fundNumber : fundIdentifier;
};

//Break up the CSV into an array of Transaction objects with lines for each line ammount
export const processTrans = (data, { funds = [] } = {}) => {
  const groupTransactions = (data) => {
    console.log("Processing Transactions: ", data);
    const transactions = [];
    let transaction = null;

    data.forEach((line) => {
      // Check if line has a date
      if (line.Date && line.Date !== "") {
        // If a transaction is currently open, push it to the transactions array
        if (transaction) {
          transactions.push(transaction);
        }
        // Start a new transaction with the current line as the first line
        transaction = {
          date: parseDate(line.Date),
          dateText: line.Date,
          contact: line.Contact,
          checkNumber: line.Check_Number || "",
          memo:
            line.Memo?.length > 0
              ? line.Memo
              : `Imported on ${dayjs().format("MM/DD/YYYY")}`,
          lines: [
            {
              amount: line.Debit
                ? parseFloat(line.Debit.replace(/['", $]/g, ""))
                : parseFloat(line.Credit.replace(/['", $]/g, "")),
              account: getAccountNumberFromString(line.Account),
              sign: line.Debit ? "debit" : "credit",
              fund: getFundNumberFromString(line.Fund)
                ? getFundNumberFromString(line.Fund)
                : funds.length > 0
                ? findFund(line.Fund, funds)
                : line.Fund,
              comment: line.Comment,
              accountText: getAccountTextFromString(line.Account),
              fundText: getFundTextFromString(line.Fund),
            },
          ],
        };
      } else {
        // If no date is present, add the line to the current transaction
        transaction.lines.push({
          amount: line.Debit
            ? parseFloat(line.Debit.replace(/['", $]/g, ""))
            : parseFloat(line.Credit.replace(/['", $]/g, "")),
          account: getAccountNumberFromString(line.Account),
          sign: line.Debit ? "debit" : "credit",
          fund: getAccountNumberFromString(line.Fund)
            ? getAccountNumberFromString(line.Fund)
            : funds.length > 0
            ? findFund(line.Fund, funds)
            : line.Fund,
          comment: line.Comment,
          accountText: getAccountTextFromString(line.Account),
          fundText: getFundTextFromString(line.Fund),
        });
      }
    });

    // Push the last transaction into transactions
    if (transaction) {
      transactions.push(transaction);
    }
    return transactions;
  };
  const groupedTX = groupTransactions(data);
  console.log(groupedTX);
  return groupedTX;
};

export const processTransactionsFromSingleEntry = ({
  data,
  contacts,
  accountType,
}) => {
  const transactions = [];
  data.forEach((transactionData) => {
    const { date, contact, amount, inflow, outflow, memo, checkNumber } =
      transactionData;

    // Initialize transaction with lines array containing one empty object
    let transaction = {
      lines: [{}],
    };

    if (amount) {
      transaction.lines[0].amount = parseFloat(amount);
      transaction.lines[0].sign =
        (amount < 0 && accountType === "Liabilities") ||
        (amount > 0 && accountType === "Assets")
          ? "debit"
          : "credit";
    } else {
      if (inflow && inflow !== "") {
        transaction.lines[0].amount = parseInt(
          Math.abs(parseFloat(inflow)) * 100,
        );
        transaction.lines[0].sign =
          accountType === "Assets" ? "debit" : "credit";
      }
      if (outflow && outflow !== "") {
        transaction.lines[0].amount = parseInt(
          Math.abs(parseFloat(outflow)) * 100,
        );
        transaction.lines[0].sign =
          accountType === "Assets" ? "credit" : "debit";
      }
    }

    transaction.date = parseDate(date);
    transaction.lines[0].contact = findMatchingContact({
      contacts,
      incomingContactName: contact,
    });
    transaction.lines[0].contactRawText = contact;
    transaction.memo = memo;
    transaction.checkNumber = checkNumber || false;

    transactions.push(transaction);
  });
  return transactions;
};
