import React from "react";
import {
  StyledColumnDiv,
  StyledRowDiv,
  ComponentTile,
  WhiteButton,
  WhiteText,
  WhiteArrow,
} from "./parent_styles";
import { useNavigate } from "react-router-dom";

const Banking = () => {
  const navigate = useNavigate(); // Use the navigate hook

  return (
    <StyledColumnDiv>
      <StyledRowDiv>
        <ComponentTile
          left={true}
          top={true}
          onClick={() => navigate("/banking/bank-accounts")}>
          <BankAccountsTile />
        </ComponentTile>
        <ComponentTile
          top={true}
          onClick={() => navigate("/banking/credit-cards")}>
          <CreditCardsTile />
        </ComponentTile>
      </StyledRowDiv>
      <StyledRowDiv>
        <ComponentTile
          left={true}
          onClick={() => navigate("/banking/integrations")}>
          <IntegrationsTile />
        </ComponentTile>
        <ComponentTile onClick={() => navigate("/banking/reconciliation")}>
          <ReconciliationTile />
        </ComponentTile>
      </StyledRowDiv>
    </StyledColumnDiv>
  );
};
const BankAccountsTile = () => {
  return (
    <React.Fragment>
      <h1>Bank Accounts</h1>
      <WhiteButton to="/banking/bank-accounts">
        <WhiteText>Details</WhiteText>
        <WhiteArrow fontSize="large" />
      </WhiteButton>
    </React.Fragment>
  );
};

const CreditCardsTile = () => {
  return <h1>Credit Cards</h1>;
};

const IntegrationsTile = () => {
  return <h1>Integrations</h1>;
};

const ReconciliationTile = () => {
  return <h1>Reconciliation</h1>;
};

export default Banking;
