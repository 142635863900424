import { useContext, useEffect } from "react";
import UserContext from "../../../assets/user_context";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  filterTranasctionsByDate,
  filterTransactionsByAccount,
  filterTransactionsBySearch,
} from "./filtering_logic";
import {
  appendBalanceLinesToTransactions,
  deepCopyTransactions,
  sortTransactionsByDateAndAccount,
} from "./sorting_processing_logic";
dayjs.extend(isSameOrAfter);

//Overall workflow here will be this:
//1. Filter to transactions that have a line that matches the account we are filtering for
// 2. Filter transactions based on date range
// 3. Append a running total to each line, starting with the balance of the account at the beginning of the date range
// 3a. (startingBalance steps) Get the balance of the account at the beginning of the date range (end of the last month's balance from server)
// 3b. (startingBalance steps) Get the transactions (lines pertaining to filterAccount) that are before the first transaction in the date range and after the last month
// 3c. (startingBalance steps) Add the transactions (lines pertaining to filterAccount) that are before the first transaction in the date range and after the last month to the balance
// 4. Apply search filters to the transactions
// 5. Eliminate any lines that are the account we are filtering for, since they are assumed to be the balance of the account, we want to show the other side of the transaction
// 6. sort the transactions by date
// 7. reverse the transactions so the most recent is at the top
// 8. set the filtered transactions to the state

const useFilterAndProcessTransactionsForDisplay = ({
  filterAccount,
  dateRangeForTx,
  setFilteredTransactionsData,
  searchString,
}) => {
  const { transactions, accounts, funds, contacts, monthlyAggregates } =
    useContext(UserContext);

  //  //Function to filter transactions by account
  // let initialFilteredTransactions = transactions.filter(
  //   (transaction) =>
  //     transaction.lines.some((line) => line.account === filterAccount),
  // );

  useEffect(() => {
    //TODO: Apply code to use monthlyAggregates to get a starting balance for the account, then we can calculate running totals,
    // maybe should be in another sub function...
    if (dateRangeForTx && transactions && transactions.length > 0) {
      console.log("Date Range to filter tx by: ", dateRangeForTx);

      const deepCopyOfTransactions = deepCopyTransactions(transactions);

      const transactionsInAccount = filterTransactionsByAccount({
        transactions: deepCopyOfTransactions,
        filterAccount,
      });

      const initialFilteredTransactions = filterTranasctionsByDate({
        transactions: transactionsInAccount,
        dateRangeForTx,
      });

      const initialFilteredAndSortedTransactions =
        sortTransactionsByDateAndAccount({
          transactions: initialFilteredTransactions,
          accounts,
          filterAccount,
        });

      const filteredAndSortedWithBalances = appendBalanceLinesToTransactions({
        transactions: initialFilteredAndSortedTransactions,
        allTransactions: transactions,
        accounts,
        filterAccount,
        dateRangeForTx,
        monthlyAggregates,
      });

      //Apply search filters to tx list
      const finalTransactionsToDisplay = filterTransactionsBySearch({
        processedTx: filteredAndSortedWithBalances,
        dateRangeForTx,
        accounts,
        funds,
        searchString,
        contacts,
      });
      // //We need to eliminate any lines that are the account we are filtering for, since they are assumed
      // //to be the balance of the account
      // filteredTransactions = filteredTransactions.map((transaction) => {
      //   const newLines = transaction.lines.filter(
      //     (line) => line.account !== filterAccount,
      //   );
      //   return { ...transaction, lines: newLines };
      // });
      finalTransactionsToDisplay.reverse();

      setFilteredTransactionsData(finalTransactionsToDisplay);
    } else {
      setFilteredTransactionsData([]);
    }
  }, [
    searchString,
    transactions,
    accounts,
    dateRangeForTx,
    dateRangeForTx.firstDate,
    filterAccount,
    contacts,
    monthlyAggregates,
    funds,
    setFilteredTransactionsData,
  ]);
};

export default useFilterAndProcessTransactionsForDisplay;
