import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { convertToDate } from "../../../utilities/general_util";
dayjs.extend(isSameOrAfter);

const isTransactionInDateRange = (transaction, dateRange) => {
  const parsedDate = convertToDate(transaction.date);
  if (!transaction?.date) return false;
  const beforeDate = parsedDate.isBefore(dateRange.lastDate);
  const afterDate = parsedDate.isSameOrAfter(dateRange.firstDate);
  return beforeDate && afterDate;
};

export const filterTranasctionsByDate = ({ transactions, dateRangeForTx }) => {
  const filteredTx = transactions.filter((transaction) => {
    if (!isTransactionInDateRange(transaction, dateRangeForTx)) return false;
    return true;
  });
  return filteredTx;
};

const findNameByIdAndMatch = (array, id, searchString, fieldName) => {
  if (!array || !id) return false;

  const foundItem = array.find((item) => item.id === id);
  if (!foundItem || !foundItem[fieldName]) return false;

  return foundItem[fieldName]
    .toLowerCase()
    .includes(searchString.toLowerCase());
};

export const filterTransactionsBySearch = ({
  processedTx,
  dateRangeForTx,
  accounts,
  funds,
  searchString,
  contacts,
}) => {
  const filteredTx = processedTx.filter((transaction) => {
    const accountMatch = transaction.lines.some((line) =>
      findNameByIdAndMatch(accounts, line.account, searchString, "accountName"),
    );

    const contactMatch = transaction.lines.some((line) =>
      findNameByIdAndMatch(contacts, line.contact, searchString, "shortName"),
    );

    const fundMatch = transaction.lines.some((line) =>
      findNameByIdAndMatch(funds, line.fund, searchString, "name"),
    );

    return accountMatch || fundMatch || contactMatch || searchString === "";
  });
  return filteredTx;
};

export const filterTransactionsByAccount = ({
  transactions,
  filterAccount,
}) => {
  const filteredTx = transactions.filter((transaction) =>
    transaction.lines.some((line) => line.account === filterAccount),
  );
  return filteredTx;
};
