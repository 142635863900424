//Function to find and link an account given an account number and account Data
//If no match can be found, returns null
export const findAccount = (accountNumber, accountsData) => {
  const account = accountsData.find((account) => {
    return account.accountNumber.toString() === accountNumber.toString();
  });
  return account;
};

//Function to find and link a fund given a fund number and fund Data
//If no match can be found, returns null
export const findFund = (fundIdentifier, fundsData) => {
  console.log("Finding fund: ", fundIdentifier);
  if (fundIdentifier) {
    const fund = fundsData.find((fund) => {
      return fund.fundNumber.toString() === fundIdentifier.toString();
    });
    if (fund) {
      return fund;
    } else {
      console.log("Finding fund by name: ", fundIdentifier);
      const fundByName = fundsData.find((fund) => {
        console.log("Checking fund: ", fund.fundName, fundIdentifier);
        return fund.fundName === fundIdentifier;
      });
      return fundByName;
    }
  } else {
    return null;
  }
};

//Function to check if a fund and account exist in the accountsData and fundsData, returns an array of transactions with arrays of error objects with keys for accounts and funds, corresponding to each line
export const validateAccountsAndFunds = (
  initialImportData,
  accountsData,
  fundsData,
) => {
  if (initialImportData.length > 0) {
    let errors = [];
    initialImportData.forEach((transaction) => {
      let transactionErrors = [];
      transaction.lines.forEach((line) => {
        let lineErrors = {};
        const account =
          accountsData?.length > 0
            ? findAccount(line.account, accountsData)
            : false;
        //TODO: Discover why this is not working when a fund is added, has to be reloaded by re-uploading the csv
        const fund = findFund(line.fund, fundsData);
        if (account) {
          //The acocunt exists
          lineErrors.account = false;
        } else {
          //The account does not exist
          lineErrors.account = true;
        }
        if (fund) {
          //The fund exists
          lineErrors.fund = false;
        } else {
          //The fund does not exist
          lineErrors.fund = true;
          if (line.fund === undefined) {
            console.log("Fund does not exist: ", line.fund, fundsData);
            console.log("Transaction: ", transaction);
          }
        }
        transactionErrors.push(lineErrors);
      });
      errors.push(transactionErrors);
    });
    return errors;
  }
};

//Validate initialImportData to make sure all journal entries balance credits and debits by fund, and each journal entry balances credits and debits
export const validateAmountsBalance = (initialImportData) => {
  if (initialImportData.length > 0) {
    let errors = [];
    initialImportData.forEach((transaction) => {
      let totalDebit = 0;
      let totalCredit = 0;
      transaction.lines.forEach((line) => {
        // Convert to cents and round to the nearest integer
        let amount = Math.round(parseFloat(line.amount) * 100);
        if (line.sign === "debit") {
          totalDebit += isNaN(amount) ? 0 : amount;
        } else if (line.sign === "credit") {
          totalCredit += isNaN(amount) ? 0 : amount;
        }
      });

      // Use a tolerance of 1 cent
      let tolerance = 1;
      if (Math.abs(totalDebit - totalCredit) > tolerance) {
        console.log(
          "Transaction does not balance: ",
          transaction,
          totalDebit / 100,
          totalCredit / 100,
        );
        errors.push(true);
      } else {
        // Build a list of funds in the journal entry
        const funds = [];
        transaction.lines.forEach((line) => {
          if (line.fund && !funds.includes(line.fund.toString())) {
            funds.push(line.fund.toString());
          }
        });

        // Validate that each fund balances
        let fundBalanced = true;
        funds.forEach((fund) => {
          let fundDebit = 0;
          let fundCredit = 0;
          transaction.lines.forEach((line) => {
            if (line.fund && line.fund.toString() === fund) {
              let amount = Math.round(parseFloat(line.amount) * 100);
              if (line.sign === "debit") {
                fundDebit += isNaN(amount) ? 0 : amount;
              } else {
                fundCredit += isNaN(amount) ? 0 : amount;
              }
            }
          });

          if (Math.abs(fundCredit - fundDebit) > tolerance) {
            console.log(
              "Fund does not balance: ",
              fund,
              fundDebit / 100,
              fundCredit / 100,
            );
            fundBalanced = false;
          }
        });

        errors.push(!fundBalanced);
      }
    });
    console.log("VALIDATION FILE: ", errors);
    return errors;
  }
};

export const findContact = (contactName, contactsData) => {
  const contact = contactsData.find((contact) => {
    if (contact.oldMatchName || contact.oldMatchName === "") {
      return contact.oldMatchName === contactName;
    } else {
      return contact.shortName === contactName;
    }
  });
  if (contact === undefined) {
    console.error("Contact not found: ", contactName, contactsData);
  }
  return contact;
};

export const validateDataTypes = (initialImportData) => {
  if (initialImportData.length > 0) {
    let errorsDetails = {};
    let errors = [];
    initialImportData.forEach((transaction, index) => {
      let txError = {};
      console.log("Date: ", transaction.date);
      if (!transaction.date || transaction.date === "") {
        txError.date = true;
      }
      let transactionLineErrors = [];
      transaction.lines.forEach((line) => {
        let lineError;
        if (line.amount === "") {
          lineError = true;
        } else if (typeof line.amount !== "number") {
          lineError = true;
        } else {
          lineError = false;
        }
        transactionLineErrors.push(lineError);
      });
      if (transactionLineErrors.some((error) => error === true)) {
        txError.amount = true;
        txError.lines = transactionLineErrors;
      } else {
        txError.amount = false;
      }
      errorsDetails[index] = txError;
      errors.push(txError.amount || txError.date);
    });
    console.log("VALIDATION FILE: ", errorsDetails);
    return { errorsDetails, errors };
  }
};
