import { useEffect } from "react";
import { findAccountType } from "./utils";

const accountNumbers = {
  Assets: 1000,
  Liabilities: 2000,
  Equity: 3000,
  Income: 4000,
  Expenses: 5000,
};

const checkIfAccountNumberTaken = ({
  accountNumber,
  existingAccountNumbers,
}) => {
  if (accountNumber !== undefined) {
    const accountNumberEntry = accountNumber.toString();
    const alreadyTaken = existingAccountNumbers.find(
      (accountNumber) => accountNumber === accountNumberEntry,
    );
    return alreadyTaken;
  } else return;
};

export const useAccountNumberValidation = ({
  number,
  setAccountEntryErrors,
  accountEntryErrors,
  setAccountEntryFeedback,
  accountEntryFeedback,
  existingAccountNumbers,
  accounts,
}) =>
  useEffect(() => {
    if (number !== "") {
      const alreadyTaken = checkIfAccountNumberTaken({
        accountNumber: number,
        existingAccountNumbers,
      });
      if (alreadyTaken) {
        setAccountEntryErrors({
          ...accountEntryErrors,
          accountNumber: true,
        });
        setAccountEntryFeedback({
          ...accountEntryFeedback,
          accountNumber: "This number is already taken",
        });
      } else {
        setAccountEntryErrors({
          ...accountEntryErrors,
          accountNumber: false,
        });
        setAccountEntryFeedback({
          ...accountEntryFeedback,
          accountNumber: "",
        });
      }
    }
  }, [number, existingAccountNumbers, accounts]);

export const validateAccountType = ({
  accountEntryData,
  setAccountEntryErrors,
  accountEntryErrors,
  accountEntryFeedback,
  setAccountEntryFeedback,
}) => {
  new Promise((resolve) => {
    if (accountEntryData.type === "") {
      setAccountEntryErrors({
        ...accountEntryErrors,
        type: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        type: "Please select an account type",
      });
    } else if (
      accountEntryData.accountNumber !== "" &&
      findAccountType(accountEntryData.accountNumber) !== accountEntryData.type
    ) {
      setAccountEntryErrors({
        ...accountEntryErrors,
        type: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        type: `For ${
          accountEntryData.type
        } accounts, the account number must be in the ${
          accountNumbers[accountEntryData.type]
        }'s`,
      });
    } else {
      setAccountEntryErrors({
        ...accountEntryErrors,
        type: false,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        type: "",
      });
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });
};

export const validateAccountNumber = ({
  accountEntryData,
  setAccountEntryErrors,
  accountEntryErrors,
  accountEntryFeedback,
  setAccountEntryFeedback,
  accountDataToEdit,
}) => {
  new Promise((resolve) => {
    console.log("Validating Account Number: ", accountEntryData);
    if (accountEntryData.accountNumber === "") {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountNumber: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountNumber: "Please enter an account number",
      });
    } else if (
      parseInt(accountEntryData.accountNumber.toString().slice(0, 1)) === 3
    ) {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountNumber: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountNumber: `Account numbers starting in 3 are reserved for fund's'equity accounts`,
      });
    } else if (
      checkIfAccountNumberTaken(accountEntryData.accountNumber) &&
      //If the account number is taken, but it's the same as the account being edited, don't throw an error
      (!accountDataToEdit.accountDatabaseId ||
        accountDataToEdit.accountNumber !== accountEntryData.accountNumber)
    ) {
      //FIXME: For some reason on initial edit loading this does not work, yet it still says the account number is taken
      console.log("Account Number Taken");
      //AccountNumber already exists
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountNumber: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountNumber: "Account number already exists",
      });
    } else if (
      accountEntryData.type !== "" &&
      findAccountType(accountEntryData.accountNumber) !== accountEntryData.type
    ) {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountNumber: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountNumber: `For ${
          accountEntryData.type
        } accounts, the account number must start with ${
          accountNumbers[accountEntryData.type]
        }`,
      });
    } else {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountNumber: false,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountNumber: "",
      });
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });
};

export const validateAccountName = ({
  accountEntryData,
  setAccountEntryErrors,
  accountEntryErrors,
  accountEntryFeedback,
  setAccountEntryFeedback,
}) => {
  new Promise((resolve) => {
    console.log("Validating Account Name: ", accountEntryData);
    if (accountEntryData.accountName === "") {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountName: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountName: "Please enter an account name",
      });
    } else {
      setAccountEntryErrors({
        ...accountEntryErrors,
        accountName: false,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        accountName: "",
      });
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });
};

export const validateGroup = ({
  accountEntryData,
  setAccountEntryErrors,
  setAccountEntryFeedback,
}) =>
  new Promise((resolve) => {
    console.log("Validating Group: ", accountEntryData);
    if (accountEntryData.group === "") {
      console.log("Group is empty");
      setAccountEntryErrors((prevState) => ({
        ...prevState,
        group: true,
      }));
      setAccountEntryFeedback((prevState) => ({
        ...prevState,
        group: "Please enter or select a group",
      }));
    } else {
      setAccountEntryErrors((prevState) => ({
        ...prevState,
        group: false,
      }));
      setAccountEntryFeedback((prevState) => ({
        ...prevState,
        group: "",
      }));
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });

//Validate sub account, which is required if the account is a sub account
export const validateSubAccount = ({
  accountEntryData,
  setAccountEntryErrors,
  accountEntryErrors,
  setAccountEntryFeedback,
  accountEntryFeedback,
}) => {
  if (accountEntryData.subAccount && !accountEntryData.subAccountOf) {
    setAccountEntryErrors({
      ...accountEntryErrors,
      subAccount: true,
    });
    setAccountEntryFeedback({
      ...accountEntryFeedback,
      subAccount: "Please select a parent account or turn off sub account",
    });
  } else {
    setAccountEntryErrors({
      ...accountEntryErrors,
      subAccount: false,
    });
    setAccountEntryFeedback({
      ...accountEntryFeedback,
      subAccount: "",
    });
  }
};

//Validate the starting balance of each fund summed is equal to the startingBalance, but only if startingBalance is > 0
export const validateStartingBalance = ({
  accountEntryData,
  setRemainingStartingToAssign,
  setAccountEntryErrors,
  accountEntryErrors,
  setAccountEntryFeedback,
  accountEntryFeedback,
}) => {
  console.log("Validating Starting Balance: ", accountEntryData);
  if (accountEntryData.startingBalance > 0) {
    const fundStartingBalances = Object.values(
      accountEntryData.fundStartingBalances,
    );

    console.log("Account Entry Data in validation: ", accountEntryData);
    console.log("Date", accountEntryData.startingBalanceDate);

    const sumOfFundStartingBalances = fundStartingBalances.reduce(
      (acc, curr) => {
        if (curr !== "") {
          return acc + parseFloat(curr || 0);
        } else {
          return acc;
        }
      },
      0,
    );
    console.log("Sum of Fund Starting Balances: ", sumOfFundStartingBalances);
    console.log(
      "Starting Balance: ",
      parseFloat(accountEntryData.startingBalance),
    );
    if (
      sumOfFundStartingBalances !==
        parseFloat(accountEntryData.startingBalance) &&
      accountEntryData.startingBalanceDate === undefined
    ) {
      setRemainingStartingToAssign(
        accountEntryData.startingBalance - sumOfFundStartingBalances,
      );
      setAccountEntryErrors({
        ...accountEntryErrors,
        startingBalance: true,
        startingBalanceDate: true,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        startingBalance: "Fund starting balances must equal starting balance",
        startingBalanceDate: "Please select a starting balance date",
      });
    } else if (
      sumOfFundStartingBalances !== parseFloat(accountEntryData.startingBalance)
    ) {
      setRemainingStartingToAssign(
        accountEntryData.startingBalance - sumOfFundStartingBalances,
      );
      setAccountEntryErrors({
        ...accountEntryErrors,
        startingBalance: true,
        startingBalanceDate: false,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        startingBalance: "Fund starting balances must equal starting balance",
        startingBalanceDate: "",
      });
    } else {
      setRemainingStartingToAssign(0);
      setAccountEntryErrors({
        ...accountEntryErrors,
        startingBalance: false,
        startingBalanceDate: false,
      });
      setAccountEntryFeedback({
        ...accountEntryFeedback,
        startingBalance: "",
        startingBalanceDate: "",
      });
    }
  } else {
    setRemainingStartingToAssign(0);
    setAccountEntryErrors({
      ...accountEntryErrors,
      startingBalance: false,
      startingBalanceDate: false,
    });
    setAccountEntryFeedback({
      ...accountEntryFeedback,
      startingBalance: "",
      startingBalanceDate: "",
    });
  }
};

export const validateFundName = ({
  fundEntryData,
  setFundEntryErrors,
  fundEntryErrors,
  fundEntryFeedback,
  setFundEntryFeedback,
}) => {
  new Promise((resolve) => {
    console.log("Validating Fund Name: ", fundEntryData);
    if (fundEntryData.fundName === "") {
      setFundEntryErrors({
        ...fundEntryErrors,
        fundName: true,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundName: "Please enter an fund name",
      });
    } else {
      setFundEntryErrors({
        ...fundEntryErrors,
        fundName: false,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundName: "",
      });
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });
};

const checkIfFundNumberTaken = ({ fundNumber, existingFundNumbers }) => {
  if (fundNumber !== undefined) {
    const fundNumberEntry = fundNumber.toString();
    const alreadyTaken = existingFundNumbers.find(
      (fundNumber) => fundNumber === fundNumberEntry,
    );
    return alreadyTaken;
  } else return;
};

export const useFundNumberValidation = ({
  number,
  setFundEntryErrors,
  fundEntryErrors,
  setFundEntryFeedback,
  fundEntryFeedback,
  existingFundNumbers,
  funds,
  fundDataToEdit,
  fundEntryData,
}) =>
  useEffect(() => {
    if (number !== "") {
      const alreadyTaken =
        checkIfFundNumberTaken({
          fundNumber: number,
          existingFundNumbers,
        }) &&
        (!fundDataToEdit?.fundDatabaseId ||
          fundDataToEdit.fundNumber !== fundEntryData.fundNumber);
      console.log("Already Taken: ", alreadyTaken);
      if (alreadyTaken) {
        setFundEntryErrors({
          ...fundEntryErrors,
          fundNumber: true,
        });
        setFundEntryFeedback({
          ...fundEntryFeedback,
          fundNumber: "This number is already taken",
        });
      } else {
        setFundEntryErrors({
          ...fundEntryErrors,
          fundNumber: false,
        });
        setFundEntryFeedback({
          ...fundEntryFeedback,
          fundNumber: "",
        });
      }
    }
  }, [number, existingFundNumbers, funds]);

export const validateFundNumber = ({
  fundEntryData,
  setFundEntryErrors,
  fundEntryErrors,
  fundEntryFeedback,
  setFundEntryFeedback,
  fundDataToEdit,
}) => {
  new Promise((resolve) => {
    console.log("Validating Fund Number: ", fundEntryData);
    if (fundEntryData.fundNumber === "") {
      setFundEntryErrors({
        ...fundEntryErrors,
        fundNumber: true,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundNumber: "Please enter an fund number",
      });
    } else if (
      parseInt(fundEntryData.fundNumber.toString().slice(0, 1)) !== 3
    ) {
      setFundEntryErrors({
        ...fundEntryErrors,
        fundNumber: true,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundNumber: `Fund Numbers must start with 3, since they have tied equity accounts`,
      });
    } else if (
      checkIfFundNumberTaken(fundEntryData.fundNumber) &&
      //If the fund number is taken, but it's the same as the fund being edited, don't throw an error
      (!fundDataToEdit.fundDatabaseId ||
        fundDataToEdit.fundNumber !== fundEntryData.fundNumber)
    ) {
      //FIXME: For some reason on initial edit loading this does not work, yet it still says the fund number is taken
      console.log("Fund Number Taken");
      //FundNumber already exists
      setFundEntryErrors({
        ...fundEntryErrors,
        fundNumber: true,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundNumber: "Fund number already exists",
      });
    } else {
      setFundEntryErrors({
        ...fundEntryErrors,
        fundNumber: false,
      });
      setFundEntryFeedback({
        ...fundEntryFeedback,
        fundNumber: "",
      });
    }
    resolve(); // Resolves the promise once the state updates are initiated
  });
};
