import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { primary_dark_color } from "../../constants/color_constants";

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90vw;
`;

export const TitleText = styled.p`
  font-size: 2rem;
  font-family: "MontserratBold";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: center;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const ClickableDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid black;
  margin: 5px;
  width: fit-content;
`;

export const SubtitleText = styled.p`
  font-size: 1.3rem;
  font-family: "MontserratMed";
  margin: 0.7rem;
  color: ${primary_dark_color};
  text-align: center;
`;

export const LineText = styled.p`
  font-size: 1.3rem;
  font-family: "MontserratBold";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: left;
`;

export const AddUserRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 45px;
`;

export const SideBySideDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  margin: 1rem;
`;
