import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import UserContext from "../assets/user_context";
import { primary_color } from "../constants/color_constants";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const MessageBox = styled.div`
  position: relative;
  text-align: center;
  z-index: 6;
`;

const ThemedLargeText = styled.p`
  font-size: 2.8rem;
  color: ${primary_color};
  font-family: "MontserratBold";
  margin-bottom: 20px;
  letter-spacing: 0.1rem;
  text-shadow:
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    -3px 0 0 #000,
    3px 0 0 #000,
    0 -3px 0 #000,
    0 3px 0 #000;
  z-index: 6;
`;

const BackButton = styled(ThemedLargeText)`
  font-size: 2.3rem;
  cursor: pointer;
`;

const ExperimentalOverlay = () => {
  const navigate = useNavigate();
  return (
    <Overlay>
      <MessageBox>
        <ThemedLargeText>
          This feature is not yet available in test mode
        </ThemedLargeText>
        <BackButton onClick={() => navigate(-1)}>⬅ Back</BackButton>
      </MessageBox>
    </Overlay>
  );
};

const withExperimental = (WrappedComponent) => {
  return (props) => {
    const { experimental } = useContext(UserContext);
    if (experimental) {
      return <ExperimentalOverlay />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withExperimental;
