import React, { useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { primary_dark_color } from "../constants/color_constants";
import UserContext from "../assets/user_context";
import { openBillingPortal } from "../services/with_subscription";
import { useAuth } from "../services/use-auth";
import { generateResponsiveStyles } from "../utilities/general_util";

const mediaQuery = (maxWidth) => `
  @media only screen and (max-width: ${maxWidth}px)
`;

const TopNavWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  padding-left: 5vw;
  padding-right: 5vw;
  z-index: 4;
  margin-bottom: 1rem;

  // Create a pseudo-element for the gradient border
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px; // same as your border thickness
    background: linear-gradient(
      to left,
      rgba(207, 207, 207, 0),
      rgba(207, 207, 207, 1) 50%,
      rgba(207, 207, 207, 0)
    );
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const StyledNavLinkLikeButton = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  font-size: 1.4rem;
  padding-left: 10px;
  padding-right: 15px;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  ${mediaQuery(1500)} {
    font-size: 1.2rem;
  }
  &:after {
    content: "";
    height: 2px;
    background-image: linear-gradient(to right, #70c8b8, #469dd7);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
  font-size: 1.4rem;
  padding-left: 10px;
  padding-right: 15px;
  text-decoration: none;
  cursor: "pointer";
  ${generateResponsiveStyles([
    { size: 1500, styles: { "font-size": "1.2rem" } },
    { size: 1300, styles: { "font-size": "1rem" } },
    { size: 1100, styles: { "font-size": "0.9rem" } },
  ])}
  &:after {
    content: "";
    height: 2px;
    background-image: linear-gradient(to right, #70c8b8, #469dd7);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &.active:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &:hover {
    text-decoration: none;
    color: #1d242c;
  }
`;

const TopNav = ({ collapsed }) => {
  const location = useLocation();
  const authHook = useAuth();
  const { org, user, setIsChildDataLoaded, orgRoles, features } =
    useContext(UserContext);
  // useEffect(() => {
  //   console.log(location);
  // }, [location]);
  return (
    <TopNavWrapper collapsed={collapsed}>
      {location.pathname.includes("foundation") && (
        <React.Fragment>
          <StyledNavLink to="/foundation/chart-of-accounts">
            Accounts
          </StyledNavLink>
          <StyledNavLink to="/foundation/funds-list">Funds</StyledNavLink>
          {/* <StyledNavLink to="/foundation/labels">Labels</StyledNavLink> */}
        </React.Fragment>
      )}
      {location.pathname.includes("upkeep") && (
        <React.Fragment>
          <StyledNavLink to="/upkeep/bank-accounts">Banks</StyledNavLink>
          <StyledNavLink to="/upkeep/credit-cards">Credit Cards</StyledNavLink>
          <StyledNavLink to="/upkeep/reconciliation">
            Reconciliation
          </StyledNavLink>
          {Object.keys(features).length > 0 && features["donorManagement"] && (
            <StyledNavLink to="/upkeep/donations">Donations</StyledNavLink>
          )}
          <StyledNavLink to="/upkeep/journal-entry">
            Journal Entries
          </StyledNavLink>
        </React.Fragment>
      )}
      {location.pathname.includes("reports") && (
        <React.Fragment>
          <StyledNavLink to="/reports/balance-sheet">
            Balance Sheet
          </StyledNavLink>
          <StyledNavLink to="/reports/income-statement">
            Income Statement
          </StyledNavLink>
          <StyledNavLink to="/reports/general-ledger">
            General Ledger
          </StyledNavLink>
          <StyledNavLink to="/reports/transactions-list">
            Transactions List
          </StyledNavLink>
          <StyledNavLink to="/reports/reconciliation">
            Reconciliation
          </StyledNavLink>
          {Object.keys(features).length > 0 && features["donorManagement"] && (
            <StyledNavLink to="/reports/donations">Donations</StyledNavLink>
          )}
        </React.Fragment>
      )}
      {location.pathname.split("/")[1].includes("account") && (
        <React.Fragment>
          <StyledNavLink to="/account/settings">Settings</StyledNavLink>
          {/* <StyledNavLink to="/account/users">Org Users</StyledNavLink> */}
          <StyledNavLink to="/account/org-management">
            Org Management
          </StyledNavLink>
          {orgRoles?.length > 0 &&
            orgRoles.some((role) => role === "subscriber") && (
              <StyledNavLinkLikeButton
                onClick={() =>
                  openBillingPortal({
                    org,
                    user,
                    authHook,
                    setLoaded: setIsChildDataLoaded,
                  })
                }>
                Subscription
              </StyledNavLinkLikeButton>
            )}
        </React.Fragment>
      )}
      {location.pathname.split("/")[1].includes("contacts") &&
        Object.keys(features).length > 0 &&
        features["donorManagement"] && (
          <React.Fragment>
            <StyledNavLink to="/contacts/contacts">Contacts</StyledNavLink>
            <StyledNavLink to="/contacts/donors">Donors</StyledNavLink>
          </React.Fragment>
        )}
      {location.pathname.includes("admin") && (
        <React.Fragment>
          <StyledNavLink to="/admin-orgs">Orgs</StyledNavLink>
          <StyledNavLink to="/admin-users">Users</StyledNavLink>
        </React.Fragment>
      )}
    </TopNavWrapper>
  );
};

export default TopNav;
