import styled from "@emotion/styled";

// Container that holds both the Nav and the content
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

// Content styled to have a left margin to match the Nav width
export const TopNavAndContentContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 98%;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 225px; */
  /* width: ${(props) => (props.collapsed ? "10vw" : "20vw")}; */
  /* @media (max-width: 600px) {
    width: 100px;
  } */
`;
