import { Checkbox, Table, TableCell, TableRow } from "@mui/material";
import React, { useContext, useState } from "react";
import SelectAccount from "./account_chooser";
import SelectFund from "./fund_chooser";
import { DetailText } from "../mass_import_styles";
import { CurrencyInput, returnCurrency } from "../../general_util";
import UserContext from "../../../assets/user_context";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import { Close } from "@mui/icons-material";

const TransactionSplitRow = ({
  split,
  txkey,
  splitIndex,
  txTopCredit,
  txTopDebit,
  changeSplitAmount,
  selectedTransactions,
  handleTransactionSelection,
  splitError,
  removeSplit,
  lastSplit,
}) => {
  const [tempSplitAmount, setTempSplitAmount] = useState(split.amount);
  const { org } = useContext(UserContext);
  const db = useAuth().db;

  const setTransactionAccount = async ({
    transactionId,
    splitIndex,
    accountId,
    splitErrors,
  }) => {
    //TODO: Fix this for splits instead of top level accounts
    console.log(
      "Setting account for transaction: ",
      transactionId,
      txkey,
      splitIndex,
      org,
      accountId,
    );
    const transactionDoc = doc(
      db,
      "orgs",
      org,
      "importedTransactions",
      transactionId,
    );
    //check if this doc exists
    const transaction = await getDoc(transactionDoc);
    if (!transaction.exists()) {
      console.error("No transaction by that ID!");
      return;
    }
    if (transaction.data().splits.length < splitIndex) {
      console.error("No split by that index!");
      return;
    }
    try {
      console.log("Transaction document path: ", transactionDoc.path);
      console.log("Setting accountId: ", accountId);

      const newSplits = [...transaction.data().splits];
      newSplits[splitIndex] = {
        ...newSplits[splitIndex],
        account: accountId,
      };

      await updateDoc(transactionDoc, {
        splits: newSplits,
      });
      console.log("Account set for transaction: ", transactionId, accountId);
    } catch (error) {
      console.error("Error setting account for transaction: ", error);
    }
  };

  const setTransactionFund = async ({ transactionId, splitIndex, fundId }) => {
    console.log(
      "Setting fund for transaction: ",
      transactionId,
      txkey,
      splitIndex,
      org,
      fundId,
    );
    const transactionDoc = doc(
      db,
      "orgs",
      org,
      "importedTransactions",
      transactionId,
    );
    //check if this doc exists
    const transaction = await getDoc(transactionDoc);
    if (!transaction.exists()) {
      console.error("No transaction by that ID!");
      return;
    }
    if (transaction.data().splits.length < splitIndex) {
      console.error("No split by that index!");
      return;
    }
    try {
      console.log("Transaction document path: ", transactionDoc.path);
      console.log("Setting fundId: ", fundId);

      const newSplits = [...transaction.data().splits];
      newSplits[splitIndex] = {
        ...newSplits[splitIndex],
        fund: fundId,
      };

      await updateDoc(transactionDoc, {
        splits: newSplits,
      });
      console.log(
        "Fund set for transaction splits: ",
        transactionId,
        splitIndex,
        fundId,
        newSplits,
      );
    } catch (error) {
      console.error("Error setting fund for transaction: ", error);
    }
  };

  return (
    <TableRow key={`split-${txkey}-${splitIndex}`}>
      {/* Space for date and contact above */}
      <TableCell sx={{ p: 0, borderBottom: "none" }}>
        <Checkbox
          checked={selectedTransactions?.[txkey]?.[splitIndex] || false}
          onChange={(e) => {
            handleTransactionSelection({ key: txkey, e, index: splitIndex });
          }}
        />
      </TableCell>
      <TableCell
        colSpan={2}
        sx={
          !lastSplit && {
            borderBottom: "none",
          }
        }
      />
      <TableCell
        sx={
          !lastSplit && {
            borderBottom: "none",
          }
        }>
        <SelectAccount
          txkey={txkey}
          splitIndex={splitIndex}
          transactionAccountValue={split.account}
          setAccount={setTransactionAccount}
          error={splitError?.account || false}
        />
      </TableCell>
      <TableCell
        sx={
          !lastSplit && {
            borderBottom: "none",
          }
        }>
        <SelectFund
          txkey={txkey}
          splitIndex={splitIndex}
          transactionFundValue={split.fund}
          setFund={setTransactionFund}
          error={splitError?.fund || false}
        />
      </TableCell>

      {txTopDebit ? (
        <React.Fragment>
          <TableCell
            sx={
              !lastSplit && {
                borderBottom: "none",
              }
            }>
            {tempSplitAmount && !isNaN(tempSplitAmount) && (
              <CurrencyInput
                currencyValueCents={tempSplitAmount}
                setCurrencyValueCents={setTempSplitAmount}
                size={"small"}
              />
            )}
          </TableCell>
          <TableCell />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableCell
            sx={
              !lastSplit && {
                borderBottom: "none",
              }
            }
          />
          <TableCell
            sx={
              !lastSplit && {
                borderBottom: "none",
              }
            }>
            {tempSplitAmount && !isNaN(tempSplitAmount) && (
              <CurrencyInput
                currencyValueCents={tempSplitAmount}
                setCurrencyValueCents={setTempSplitAmount}
                size={"small"}
                onBlur={async () => {
                  if (tempSplitAmount !== split.amount) {
                    changeSplitAmount({
                      txkey,
                      splitIndex,
                      amount: tempSplitAmount,
                    });
                  }
                }}
              />
            )}
          </TableCell>
        </React.Fragment>
      )}
      <TableCell sx={{ borderBottom: "none" }}>
        <Close
          sx={{ cursor: "pointer", color: "white" }}
          onClick={() => removeSplit({ txkey, splitIndex })}
        />
      </TableCell>
    </TableRow>
  );
};

export default TransactionSplitRow;
