import React, { useContext } from "react";
import { useAuth } from "../services/use-auth";
import { signOut } from "@firebase/auth";
import { ConfirmAction } from "../assets/controls";
import UserContext from "../assets/user_context";

const SignOutModal = (props) => {
  const { userData } = useContext(UserContext);
  const authHook = useAuth();
  const signOutFunc = () => {
    signOut(authHook.auth).catch((error) => {
      console.error(error.message);
    });
    props.handleClose();
  };

  return (
    <React.Fragment>
      {userData?.username && (
        <ConfirmAction
          dataForAction={true}
          callback={(data) => signOutFunc(data)}
          openState={props.open}
          handleCloseState={props.handleClose}>
          Sign Out as {userData.username}
        </ConfirmAction>
      )}
    </React.Fragment>
  );
};

export default SignOutModal;
