import React, { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../../assets/user_context";
import styled from "@emotion/styled";
import { IconButton, TextField, Tooltip } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import { Check, Close } from "@mui/icons-material";

import { useAuth } from "../../../services/use-auth";
import { doc, updateDoc } from "firebase/firestore";
import { updateEmail } from "firebase/auth";
import * as yup from "yup";

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      "Email must include a valid top-domain (like .com, .org)",
    )
    .required("Email is required"),
});

const MajorText = styled.div`
  font-size: 1.5rem;
  font-family: "MontserratMed";
  margin-bottom: 10px;
  cursor: pointer;
`;

const MinorText = styled.div`
  font-size: 1.1rem;
  font-family: "MontserratLight";
  margin-bottom: 5px;
  cursor: pointer;
`;

const AccountDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e9e9e9f0;
  border-radius: 10px;
  padding: 1rem;
`;

const SideBySideDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  margin: 1rem;
`;

const ChangePasswordDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #e9e9e9f0;
  border-radius: 10px;
  padding: 2rem;
`;

const UserDetails = () => {
  const authHook = useAuth();
  const { userData, user } = useContext(UserContext);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
  const [editingUserDetails, setEditingUserDetails] = useState(false);
  const [firstName, setFirstName] = useState(
    userData?.firstName ? userData.firstName : null,
  );
  const [lastName, setLastName] = useState(
    userData?.lastName ? userData.lastName : null,
  );
  const [email, setEmail] = useState(userData?.email ? userData.email : null);
  const [phoneNumber, setPhoneNumber] = useState(
    userData?.phoneNumber ? userData.phoneNumber.replace(/\D/g, "") : null,
  );
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [generalFieldError, setGeneralFieldError] = useState({
    firstName: false,
    lastName: false,
    phone: false,
  });
  const [phoneErrorFeedback, setPhoneErrorFeedback] = useState(null);

  useEffect(() => {
    setFirstName(userData?.firstName ? userData.firstName : null);
    setLastName(userData?.lastName ? userData.lastName : null);
    setEmail(userData?.email ? userData.email : null);
    setPhoneNumber(
      userData?.phoneNumber ? userData.phoneNumber.replace(/\D/g, "") : null,
    );
  }, [userData]);

  const handleChange = (event) => {
    console.log(event.target.value);
    switch (event.target.name) {
      case "currentPassword":
        setCurrentPassword(event.target.value);
        break;
      case "newPassword":
        setNewPassword(event.target.value);
        break;
      case "newPasswordConfirm":
        setNewPasswordConfirm(event.target.value);
        break;
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phoneNumber":
        setPhoneNumber(event.target.value);
        break;
      default:
        break;
    }
  };

  const changeName = async () => {
    const userRef = doc(authHook.db, `users/${user.uid}`);
    const firstNameParsed = firstName ? firstName : userData.firstName;
    const lastNameParsed = lastName ? lastName : userData.lastName;
    const usernameParsed = `${firstNameParsed} ${lastNameParsed}`;
    if (usernameParsed !== userData.username) {
      await updateDoc(
        userRef,
        {
          firstName: firstNameParsed,
          lastName: lastNameParsed,
          username: usernameParsed,
        },
        { merge: true },
      );
    }
    setEditingUserDetails(false);
  };

  const validatePhone = useCallback(() => {
    if (phoneNumber === "" || phoneNumber === null) {
      setGeneralFieldError((entryErrors) => ({
        ...entryErrors,
        phone: true,
      }));
      setPhoneErrorFeedback("You must enter a phone number");
      return false;
    } else {
      const notLongEnough = phoneNumber.length < 10;
      console.log(`Phone has ${phoneNumber?.length} chars`);
      if (notLongEnough) {
        setGeneralFieldError((entryErrors) => ({
          ...entryErrors,
          phone: true,
        }));
        setPhoneErrorFeedback("Include a complete phone number with area code");
        console.log("not long enough");
        return false;
      } else {
        const tooLong = phoneNumber.length > 10;
        if (tooLong) {
          setGeneralFieldError((entryErrors) => ({
            ...entryErrors,
            phone: true,
          }));
          setPhoneErrorFeedback("Include only a 10 digit phone number");
          return false;
        } else {
          if (isNaN(phoneNumber)) {
            setGeneralFieldError((entryErrors) => ({
              ...entryErrors,
              phone: true,
            }));
            setPhoneErrorFeedback("Include only numbers");
            return false;
          } else {
            setGeneralFieldError((entryErrors) => ({
              ...entryErrors,
              phone: false,
            }));
            setPhoneErrorFeedback(null);
            return true;
          }
        }
      }
    }
  }, [phoneNumber]);

  const cleanPhone = (number) => {
    if (number.length === 10) {
      const raw = number;
      const areaCode = raw.substring(0, 3);
      const threeDig = raw.substring(3, 6);
      const lastDig = raw.substring(6, 10);
      const altered = `(${areaCode}) ${threeDig}-${lastDig}`;
      return altered;
    }
  };

  const changePhoneNumber = async () => {
    if (validatePhone()) {
      const userRef = doc(authHook.db, `users/${user.uid}`);
      const phoneNumberParsed = phoneNumber
        ? phoneNumber
        : userData.phoneNumber;
      if (userData.phoneNumber !== phoneNumberParsed) {
        await updateDoc(
          userRef,
          {
            phoneNumber: cleanPhone(phoneNumberParsed),
          },
          { merge: true },
        );
      }
      setEditingUserDetails(false);
    }
  };

  const validateEmail = async (email) => {
    try {
      // Validate the email using the schema
      await emailSchema.validate({ email });

      // If validation is successful, return true
      return true;
    } catch (error) {
      // If validation fails, log the error and return false
      console.error("Validation error:", error.message);
      setEmailErrorMessage(error.message);
      return false;
    }
  };

  const changeEmail = async () => {
    const isValidEmail = await validateEmail(email);
    if (isValidEmail) {
      if (email !== userData.email) {
        try {
          // Checking if auth and currentUser are available
          if (authHook.auth && authHook.auth.currentUser) {
            console.log(
              "Attempting to update email for:",
              authHook.auth.currentUser.email,
            );

            // Step 1: Update email in Firebase Authentication
            await updateEmail(user, email);
            console.log("Firebase Auth email updated successfully to:", email);

            // Step 2: Update Firestore document
            const userRef = doc(
              authHook.db,
              `users/${authHook.auth.currentUser.uid}`,
            );
            await updateDoc(userRef, { email: email }, { merge: true });
            console.log(
              "Firestore email updated successfully for user ID:",
              authHook.auth.currentUser.uid,
            );

            // Update local state and inform user of success
            setEmail(email);
            console.log(`Successfully updated email to ${email}`);
          } else {
            console.log(
              "auth or currentUser is not defined. Please check Firebase initialization and authentication state.",
            );
            // Additional handling if auth or currentUser is not available
          }
        } catch (error) {
          // Handle any errors that occur during the update
          console.error("Error updating email: ", error);
          // Optionally, inform the user of the failure
        }
      } else {
        console.log(
          "New email is the same as the current email. No update needed.",
        );
      }
    } else {
      console.log("Invalid email format");
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const checkEmail = async () => {
      const isValidEmail = await validateEmail(email);
      if (isValidEmail) {
        setInvalidEmail(false);
      } else {
        setInvalidEmail(true);
      }
    };
    if (invalidEmail) {
      checkEmail();
    }
  }, [email, invalidEmail]);

  useEffect(() => {
    const checkPhone = () => {
      validatePhone();
    };
    checkPhone();
  }, [phoneNumber, validatePhone]);

  return (
    <React.Fragment>
      {!editingUserDetails && (
        <Tooltip title="Edit Account Details">
          <AccountDetailsDiv
            style={{ cursor: "pointer" }}
            onClick={() => setEditingUserDetails(true)}>
            <MajorText>{userData.username}</MajorText>
            <MinorText>{userData.email}</MinorText>
            <MinorText>{userData.phoneNumber}</MinorText>
          </AccountDetailsDiv>
        </Tooltip>
      )}
      {editingUserDetails && (
        <AccountDetailsDiv
          style={{
            position: "relative",
            padding: "2rem",
            alignItems: "center",
          }}>
          <Close
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
            onClick={() => setEditingUserDetails(false)}
          />
          <MajorText style={{ paddingBottom: "1rem" }}>
            Change Details
          </MajorText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <TextField
              sx={{ mb: 2, width: "45%" }}
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={handleChange}
              error={generalFieldError.firstName}
              helperText={
                generalFieldError.firstName ? "This cannot be blank" : null
              }
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => changeName()}>
                    <Check style={{ color: "#61b561" }} fontSize="large" />
                  </IconButton>
                ),
              }}
            />
            <TextField
              sx={{ mb: 2, width: "45%" }}
              name="lastName"
              label="Last Name"
              value={lastName}
              onChange={handleChange}
              error={generalFieldError.lastName}
              helperText={
                generalFieldError.lastName ? "This cannot be blank" : null
              }
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => changeName()}>
                    <Check style={{ color: "#61b561" }} fontSize="large" />
                  </IconButton>
                ),
              }}
            />
          </div>
          <TextField
            sx={{ mb: 2, width: "100%" }}
            name="email"
            label="Email"
            value={email}
            onChange={handleChange}
            error={invalidEmail}
            helperText={invalidEmail ? emailErrorMessage : null}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => changeEmail()}>
                  {invalidEmail ? (
                    <BlockIcon style={{ color: "#e14d4d" }} fontSize="large" />
                  ) : (
                    <Check style={{ color: "#61b561" }} fontSize="large" />
                  )}
                </IconButton>
              ),
            }}
          />
          <TextField
            sx={{ mb: 2 }}
            name="phoneNumber"
            label="Phone Number"
            value={phoneNumber}
            onChange={handleChange}
            error={generalFieldError.phone}
            helperText={generalFieldError.phone ? phoneErrorFeedback : null}
            onFocus={() => setPhoneNumber(phoneNumber.replace(/\D/g, ""))}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => changePhoneNumber()}>
                  <Check style={{ color: "#61b561" }} fontSize="large" />
                </IconButton>
              ),
            }}
          />
        </AccountDetailsDiv>
      )}
    </React.Fragment>
  );
};

export default UserDetails;
