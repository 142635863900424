import { collection, getDocs } from "firebase/firestore";
import { convertToDate } from "../../utilities/general_util";
import dayjs from "dayjs";

// Function to process new transactions
export const processNewTransactions = ({
  newTransactions,
  currentTransactions,
  setTransactions,
  setDateToRecalcAfter,
  dateToRecalcAfter,
  deleteQueue,
}) => {
  // Logic to merge new transactions with the existing state
  return new Promise((resolve) => {
    //We'll push in newly added tx that aren't already in the monthlyAggregates
    // console.log("Begin processing new transactions, compare current and new:");
    // console.log(
    //   "currentTransactions (in process function): ",
    //   currentTransactions,
    // );
    // console.log("newTransactions (in process function): ", newTransactions);
    const updatedTransactions = currentTransactions
      //Filter out optimistically updated transactions
      .filter((t) => !t.optimistic)
      .reduce((acc, trans) => {
        acc[trans.id] = trans;
        return acc;
      }, {});
    const newlyAddedTx = [];

    //To be sure these tx aren't in the currentTransactions, we'll check the lastChangeTimestamp of the tx in the monthlyAggregates && the transaction.id
    //If the transaction.id is the same and the lastChangeTimestamp is the same, we'll assume it's the same transaction, and remove it from the newTransactions
    const areTimestampsEqual = (timestamp1, timestamp2) =>
      timestamp1.seconds === timestamp2.seconds &&
      timestamp1.nanoseconds === timestamp2.nanoseconds;

    //Check for new transactions that are not in the currentTransactions
    const newTransactionsToProcess =
      currentTransactions.length > 0
        ? newTransactions.filter(
            (tx) =>
              !currentTransactions.some((currentTx) => {
                return (
                  deleteQueue?.includes(tx.id) &&
                  tx.addTimestamp === false &&
                  currentTx.id === tx.id &&
                  areTimestampsEqual(
                    currentTx.lastChangeTimestamp,
                    tx.lastChangeTimestamp,
                  )
                );
              }),
          )
        : newTransactions;
    //Check for transactions that are missing from newTransactions , but exist currently in transactions since they may have been deleted
    // console.log(currentTransactions.length, " current transactions");
    // console.log(newTransactions.length, " new transactions");
    // const deletedTransactions = currentTransactions.filter((tx) => {
    //   return !newTransactions.some((newTx) => {
    //     return newTx.id === tx.id;
    //   });
    // });

    // console.log(deletedTransactions.length, " deleted transactions");

    // console.log(
    //   `Filtered to ${newTransactionsToProcess.length} actual new transactions after comparing lastChangeTimestamps where id's are the same`,
    // );
    // Merge new transactions with existing transactions, overwriting any existing transactions with the same ID
    newTransactionsToProcess.forEach((newTrans) => {
      console.log(
        "Adding to list to determine stale monthlyAggregates",
        newTrans,
      );
      newlyAddedTx.push(newTrans);
      updatedTransactions[newTrans.id] = newTrans;
    });

    //Find the earliest tx by the date field in newlyAddedTx
    const earliestTxDate = newlyAddedTx.reduce(
      (acc, tx) => {
        const date = convertToDate(tx.date).$d;
        if (date < acc.date) {
          return tx;
        } else {
          return acc;
        }
      },
      { date: new Date() },
    ).date;
    console.log("Earliest Tx Date: ", earliestTxDate.$d);
    const updatedTxArr = Object.values(updatedTransactions);

    if (
      (dateToRecalcAfter === null && newlyAddedTx.length > 0) ||
      (newlyAddedTx.length > 0 && earliestTxDate < dateToRecalcAfter)
    ) {
      setDateToRecalcAfter(earliestTxDate.$d);
    }
    setTransactions(updatedTxArr);
    resolve();
  });
};

// Function to process deleted transactions (if applicable)
export const processDeletedTransactions = ({
  deletedTransactionIds,
  monthlyAggregates,
  currentTransactions,
  setTransactions,
  setDateToRecalcAfter,
  dateToRecalcAfter,
}) => {
  // Logic to remove deleted transactions from the existing state
  return new Promise((resolve) => {
    const updatedTransactions = currentTransactions.reduce((acc, trans) => {
      acc[trans.id] = trans;
      return acc;
    }, {});
    console.log("Got deteled tx: ", deletedTransactionIds);
    const newlyRemovedTx = [];
    // Remove these transactions from existingTransactionsById, and add them to newlyRemovedTx if they existed in the updatedTransactions
    deletedTransactionIds.forEach((deletedTxId) => {
      if (updatedTransactions[deletedTxId]) {
        console.log(
          "transaction to delete exists, adding to the newlyRemoved list",
        );
        newlyRemovedTx.push(updatedTransactions[deletedTxId]);
        delete updatedTransactions[deletedTxId];
      } else {
        console.log("transaction to delete does not exist currently");
      }
    });

    //Find the deleted tx with the earliest date by finding the deleted tx in the monthlyAggregates with the earliest date
    //TODO: This is breaking when the only tx that have been deleted are after the monthlyAggregates. Really means they don't need to recalc (MAYBE FIXED, CHECK IT)
    console.log("monthlyAggregates: ", monthlyAggregates);
    const earliestTxDate = Object.values(monthlyAggregates).reduce(
      (acc, month) => {
        const txInMonth = month.transactions
          .sort((a, b) => a.date.seconds - b.date.seconds)
          .find((tx) => deletedTransactionIds.includes(tx.id));
        // Create a JavaScript Date object from the timestamp
        // console.log("txInMonth: ", txInMonth);
        const txDate = txInMonth
          ? txInMonth.date.seconds
            ? new Date(txInMonth.date.seconds * 1000)
            : txInMonth.date.$d
          : null;
        // console.log("txDate: ", txDate);

        // Compare with the accumulator's date
        if (txDate && txDate < acc.date) {
          return { date: txDate };
        } else {
          return acc;
        }
      },
      { date: new Date() }, // using today's date
    );

    console.log("Earliest Deleted Tx Date: ", earliestTxDate.date);

    const updatedTxArr = Object.values(updatedTransactions);

    if (
      (dateToRecalcAfter === null && newlyRemovedTx.length > 0) ||
      (newlyRemovedTx.length > 0 && earliestTxDate.date < dateToRecalcAfter)
    ) {
      setDateToRecalcAfter(earliestTxDate.date);
    }

    setTransactions(updatedTxArr);
    resolve();
  });
};

export const aggregatesFromDb = async ({ setMonthlyAggregates, db, org }) => {
  const snapshot = await getDocs(
    collection(db, "orgs", org, "monthlyAggregates"),
  );
  const monthlyAggregates =
    snapshot.docs.length > 0
      ? snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      : null;

  if (monthlyAggregates === null) {
    // setMonthlyAggregates({});
    return null;
  } else {
    let allTransactions = [];
    monthlyAggregates.forEach((month) => {
      month.transactions.forEach((tx) => {
        if (!allTransactions.some((txToCheck) => txToCheck.id === tx.id)) {
          allTransactions.push(tx);
        }
      });
    });
    console.log("After fetching aggregates: ", allTransactions.length);

    const latestTimestampInSec = Math.max(
      ...allTransactions.map((t) => t.lastChangeTimestamp?.seconds || 0),
      0,
    );

    //Change all dates in allTransactions into dayjs objects
    const transactionsWithDates = allTransactions.map((t) => {
      t.date = convertToDate(t.date);
      return t;
    });

    // console.log("All Transactions: ", transactionsWithDates);

    // Convert existing transactions to an object for easy lookup by Id
    const existingTransactionsById = transactionsWithDates.reduce(
      (acc, trans) => {
        acc[trans.id] = trans;
        return acc;
      },
      {},
    );
    return {
      existingTransactionsById,
      monthlyAggregates,
      latestTimestampInSec,
    };
  }
};
