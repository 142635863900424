import React, { useState } from "react";
import Burger from "./burger";
import MobileMenu from "./mobileMenu";
import styled from "@emotion/styled";
import { primary_dark_color } from "../constants/color_constants";
import { WarningText } from "../locations/transactions/transactions_styles";

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
`;

const MobileNavBarSpacer = styled.div`
  position: relative;
  width: 100vw;
  height: 80px;
`;

export const MobileWarningWrapper = styled.div`
  display: none; // By default, it will not display
  position: absolute;
  z-index: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${primary_dark_color};
  border-radius: 3rem;
  padding: 1rem;
  bottom: 2rem; // 1 rem above the bottom edge
  left: 1rem;
  right: 1rem;

  @media (max-width: 799px) {
    display: flex; // Will display on screens smaller than 600px
  }
`;

const MobileNav = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <MobileMenuWrapper>
        <MobileNavBarSpacer />
        <Burger
          open={open}
          setOpen={setOpen}
          stillLoading={props.stillLoading}
        />
        <MobileMenu
          userData={props.userData}
          authUser={props.authUser}
          open={open}
          setOpen={setOpen}
        />
      </MobileMenuWrapper>
      <MobileWarningWrapper>
        <WarningText>
          Accounting work on mobile is really tough. We recomend you work on a
          larger screen to display these tables.
        </WarningText>
      </MobileWarningWrapper>
    </React.Fragment>
  );
};

export default MobileNav;
