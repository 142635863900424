import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../services/use-auth";
import AddDonation from "./add_donation";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import UserContext from "../../assets/user_context";
import {
  StyledHeaderText,
  StyledTable,
  StyledTableHeaderColumn,
  StyledTableHeaderRow,
  StyledTableItem,
  StyledTableRow,
  StyledTbody,
  TableWrapperDiv,
  TableWrapperInnerDiv,
} from "../transactions/transactions_styles";
import { findDonorName, returnCurrency } from "../../utilities/general_util";
import { Delete, Edit } from "@mui/icons-material";
import { primary_dark_color } from "../../constants/color_constants";
import { ConfirmAction } from "../../assets/controls";
import Modal from "@mui/material/Modal";

const Donations = () => {
  const authHook = useAuth();
  const { org, donors } = useContext(UserContext);
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);
  const [donationsByYear, setDonationsByYear] = useState([]);
  const [donationToDelete, setDonationToDelete] = useState({});
  const [donationToEdit, setDonationToEdit] = useState({});

  useEffect(() => {
    if (org) {
      const unSubDonations = onSnapshot(
        collection(authHook.db, "orgs", org, "donorManagement"),
        (snapshot) => {
          const newDonations = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("New Donations:", newDonations); // Debugging log

          const donationsToSet = newDonations
            .filter((doc) => doc.id !== "donors")
            .map((donationYear) => {
              return {
                ...donationYear,
                donations: donationYear.donations.map((donation) => {
                  console.log("Donation Date:", donation.date);
                  return {
                    ...donation,
                    date: donation.date.toDate(),
                  };
                }),
              };
            });
          console.log("Donations To Set:", donationsToSet); // Debugging log

          setDonationsByYear(donationsToSet);
        },
        (error) => {
          console.error("Error fetching donations:", error);
        },
      );

      return () => {
        unSubDonations();
      };
    }
  }, [authHook.db, org]);

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer
      const enterTxHeight =
        document.querySelector("#transaction-entry-wrapper")?.offsetHeight || 0;
      const bankingToolsHeight =
        document.querySelector("#banking-tools-wrapper")?.offsetHeight || 0;
      const titleHeight =
        document.querySelector("#page-title")?.offsetHeight || 0;
      const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
      const extra = 190;
      const height =
        vh -
        (enterTxHeight +
          bankingToolsHeight +
          titleHeight +
          footerHeight +
          extra);
      const heightString = height.toString().concat("px");
      //   if (height <= 0) {
      //     setShowTxTable(false);
      //   } else {
      //     setShowTxTable(true);
      //   }

      console.log(
        `Recalc Tx table height, viewHeight:${vh}, enterTxHeight:${enterTxHeight}, bankingToolsHeight:${bankingToolsHeight}, titleHeight:${titleHeight}, footerHeight:${footerHeight}, height:${heightString}`,
      );
      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "enter-transactions-wrapper") {
          calculateHeight();
        }
      }
    });

    // Observe the target element
    const enterTxWrapper = document.querySelector(
      "#enter-transactions-wrapper",
    );
    if (enterTxWrapper) {
      resizeObserver.observe(enterTxWrapper);
    }

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
      if (enterTxWrapper) {
        resizeObserver.unobserve(enterTxWrapper);
      }
    };
  }, []);

  const recordRemoval = (data) => {
    console.log("Record Removal: ", data);
    const { year, index, id } = data;
    donationsByYear.forEach(async (donationYear) => {
      if (donationYear.year === year) {
        if (donationYear.donations[index].id === id) {
          console.log("Removing donation, id's match: ");
          const donationsForYearAfterRemoval = donationYear.donations.splice(
            index,
            1,
          );
          await updateDoc(
            doc(
              authHook.db,
              "orgs",
              org,
              "donorManagement",
              `donations-${year}`,
            ),
            { donations: donationsForYearAfterRemoval },
          );
          console.log("Donation removed.");
          setDonationToDelete({});
        } else {
          console.error("Donation ID's do not match.");
        }
      }
    });
  };

  const donationHeaders = ["Date", "Donor", "Reason", "Amount"];

  return (
    <div>
      <AddDonation db={authHook.db} />
      <ConfirmAction
        dataForAction={{
          ...donationToDelete,
          label: `by ${
            donationToDelete?.donor
              ? findDonorName({
                  donorId: donationToDelete.donor,
                  donors,
                })
              : ""
          } on ${donationToDelete?.date?.toLocaleDateString()} for ${returnCurrency(
            donationToDelete.amount,
          )}`,
        }}
        callback={(data) => recordRemoval(data)}
        openState={Object.keys(donationToDelete)?.length > 0}
        handleCloseState={() => setDonationToDelete({})}>
        Remove Donation
      </ConfirmAction>
      <Modal open={Object.keys(donationToEdit)?.length > 0}>
        <AddDonation
          db={authHook.db}
          donationToEdit={donationToEdit}
          handleClose={() => setDonationToEdit({})}
        />
      </Modal>

      {donationsByYear?.length > 0 ? (
        <TableWrapperDiv height={tableWrapperHeight}>
          <TableWrapperInnerDiv>
            <StyledTable>
              <thead>
                <StyledTableHeaderRow header>
                  {donationHeaders.map((header) => (
                    <StyledTableHeaderColumn
                      header={header}
                      key={`header-${header}`}>
                      <StyledHeaderText> {header}</StyledHeaderText>
                    </StyledTableHeaderColumn>
                  ))}
                  <StyledTableHeaderColumn header={"Clear"} />
                </StyledTableHeaderRow>
              </thead>
              <StyledTbody height={tableWrapperHeight}>
                {donationsByYear
                  .sort((a, b) => b.year - a.year)
                  .map((donationYear) => {
                    return donationYear.donations.map((donation, index) => {
                      return (
                        <StyledTableRow
                          key={`row-${donationYear.year}-${donation.donor}`}>
                          <StyledTableItem>
                            {donation.date.toLocaleDateString()}
                          </StyledTableItem>
                          <StyledTableItem>
                            {findDonorName({ donorId: donation.donor, donors })}
                          </StyledTableItem>
                          <StyledTableItem>{donation.reason}</StyledTableItem>
                          <StyledTableItem>
                            {returnCurrency(donation.amount)}
                          </StyledTableItem>
                          <td>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}>
                              <Edit
                                style={{ color: primary_dark_color }}
                                onClick={() => {
                                  console.log(
                                    `Edit donation ${donationYear.year}, donation#:${index}, with ID:${donation.id}`,
                                  );
                                  setDonationToEdit({
                                    ...donation,
                                    year: donationYear.year,
                                    index: index,
                                    id: donation.id,
                                  });
                                }}
                              />
                              <div style={{ width: "15px" }} />
                              <Delete
                                style={{ color: primary_dark_color }}
                                onClick={() => {
                                  console.log(
                                    `Delete donation ${donationYear.year}, donation#:${index}, with ID:${donation.id}`,
                                  );
                                  setDonationToDelete({
                                    ...donation,
                                    year: donationYear.year,
                                    index: index,
                                    id: donation.id,
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </StyledTableRow>
                      );
                    });
                  })}
              </StyledTbody>
            </StyledTable>
          </TableWrapperInnerDiv>
        </TableWrapperDiv>
      ) : null}
    </div>
  );
};

export default Donations;
