import React, { useContext, useState, useEffect } from "react";
import { ComponentPageWrapper } from "../component_styles.jsx";
import UserContext from "../../assets/user_context.jsx";
import { calcGeneralLedger } from "../../utilities/transaction_parser.jsx";
import withSubscriptionProtection from "../../services/with_subscription.jsx";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AmountLineText,
  FiltersWrapper,
  LargeLineText,
  LineText,
  RowWrapper,
  StyledHeaderText,
  StyledTableHeaderDiv,
  StyledTableHeaderRowDiv,
} from "./reports_styles.jsx";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  findAccountById,
  findContactNameById,
  findFundById,
  returnCurrency,
} from "../../utilities/general_util.jsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PictureAsPdfOutlined } from "@mui/icons-material";

const GeneralLedgerProtected = () => {
  const { transactions, accounts, funds, contacts } = useContext(UserContext);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf("year"),
    dayjs(),
  ]);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(() => dayjs(new Date()));

  const [generalLedger, setGeneralLedger] = useState(null);

  useEffect(() => {
    //If dateRange's final date is set, set a maxDate for the first date as the day before the final date
    if (dateRange[1]) {
      setMaxDate(dayjs(dateRange[1]));
    }

    //If dateRange's first date is set, set a minDate for the first date as the day after the first date
    if (dateRange[0]) {
      setMinDate(dayjs(dateRange[0]));
    }
  }, [dateRange]);

  useEffect(() => {
    if (
      accounts?.length > 0 &&
      funds?.length > 0 &&
      transactions?.length > 0 &&
      minDate?.$d &&
      maxDate?.$d
    ) {
      console.log("CALCULATING GENERAL LEDGER");
      const generalLedgerObj = calcGeneralLedger({
        transactions,
        accounts,
        funds,
        startDate: minDate.$d,
        endDate: maxDate.$d,
      });
      console.log("GENERAL LEDGER: ", generalLedgerObj);
      setGeneralLedger(generalLedgerObj);
    }
  }, [accounts, funds, transactions, minDate, maxDate]);

  const reportToPDF = () => {
    const input = document.getElementById("general-ledger-container");
    const a4WidthPx = 595; // A4 width in pixels
    const a4HeightPx = 842; // A4 height in pixels

    html2canvas(input, { scale: 1 }).then((canvas) => {
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      let scaleFactor = a4WidthPx / imgWidth;

      // Create a PDF in portrait orientation
      const pdf = new jsPDF("portrait", "pt", "a4");

      // Calculate the number of pages
      let pages = Math.ceil((imgHeight * scaleFactor) / a4HeightPx);

      for (let i = 0; i < pages; i++) {
        // Calculate the height of the image slice for each page
        let imgSliceHeight = imgHeight / pages;

        // Use context to draw the sliced part of the image
        let canvasSlice = document.createElement("canvas");
        canvasSlice.width = imgWidth;
        canvasSlice.height = imgSliceHeight;
        let ctx = canvasSlice.getContext("2d");
        ctx.drawImage(canvas, 0, -i * imgSliceHeight);

        // Add the sliced part to the PDF
        if (i > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          canvasSlice.toDataURL("image/png"),
          "PNG",
          0,
          0,
          a4WidthPx,
          imgSliceHeight * scaleFactor,
        );
      }

      pdf.save("balance-sheet.pdf");
    });
  };

  return (
    <ComponentPageWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FiltersWrapper>
          <div style={{ width: "20vw" }} />
          <DatePicker
            label="Starting Date"
            value={dateRange[0]}
            onChange={(newValue) => {
              setDateRange([newValue, dateRange[1]]);
            }}
            maxDate={maxDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={true}
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <LargeLineText>TO</LargeLineText>
          <DatePicker
            label="Ending Date"
            value={dateRange[1]}
            onChange={(newValue) => {
              setDateRange([dateRange[0], newValue]);
            }}
            disableFuture
            minDate={minDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={true}
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <div style={{ width: "20vw" }} />
          <PictureAsPdfOutlined onClick={reportToPDF} />
        </FiltersWrapper>
      </LocalizationProvider>
      <div id="general-ledger-container">
        <table>
          <thead>
            <StyledTableHeaderRowDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Account</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Transaction Date</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Contact</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Fund</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Debit</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Credit</StyledHeaderText>
              </StyledTableHeaderDiv>
              <StyledTableHeaderDiv>
                <StyledHeaderText>Running Total</StyledHeaderText>
              </StyledTableHeaderDiv>
            </StyledTableHeaderRowDiv>
          </thead>
          <tbody>
            {generalLedger &&
              Object.keys(generalLedger).length > 0 &&
              Object.keys(generalLedger)
                .sort((a, b) => {
                  const aData = findAccountById({ accountId: a, accounts });
                  const bData = findAccountById({ accountId: b, accounts });

                  // Remove non-numeric characters
                  const aAccountNumber = aData
                    ? aData.accountNumber.replace(/[^\d]/g, "")
                    : "";
                  const bAccountNumber = bData
                    ? bData.accountNumber.replace(/[^\d]/g, "")
                    : "";

                  const maxLength = Math.max(
                    aAccountNumber.length,
                    bAccountNumber.length,
                  );
                  const paddedA = aAccountNumber.padStart(maxLength, "0");
                  const paddedB = bAccountNumber.padStart(maxLength, "0");

                  for (let i = 0; i < maxLength; i++) {
                    if (paddedA[i] < paddedB[i]) {
                      return -1;
                    } else if (paddedA[i] > paddedB[i]) {
                      return 1;
                    }
                  }
                  return 0;
                })
                .map((accountId, index) => (
                  <React.Fragment key={index}>
                    <RowWrapper>
                      <td colSpan={5}>
                        <LargeLineText>{`${
                          findAccountById({ accountId, accounts }).accountNumber
                        } - ${
                          findAccountById({ accountId, accounts }).accountName
                        }`}</LargeLineText>
                      </td>
                      {(findAccountById({ accountId, accounts }).accountType ===
                        "Assets" ||
                        findAccountById({ accountId, accounts }).accountType ===
                          "Liabilities") && (
                        <td colSpan={2}>
                          <h4>
                            {`Starting Balance: ${returnCurrency(
                              generalLedger[accountId].startingBalance,
                            )}`}
                          </h4>
                        </td>
                      )}
                    </RowWrapper>
                    {generalLedger[accountId].transactions.map((tx, index) => (
                      <RowWrapper key={`${tx.id}-${index}`}>
                        <td />
                        <td>
                          <LineText>
                            {dayjs(tx.date.$d).format("MM/DD/YYYY")}
                          </LineText>
                        </td>
                        <td>
                          <LineText
                          //Uncomment to cut off contact name if it's too long with an ...
                          // style={{
                          //   //Cut off contact name if it's too long with an ...
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   whiteSpace: "nowrap",
                          //   width: "10vw",
                          // }}
                          >
                            {findContactNameById({
                              contactId: tx.contact,
                              contacts,
                            })}
                          </LineText>
                        </td>
                        <td>
                          <LineText>
                            {findFundById({ fundId: tx.fund, funds }).fundName}
                          </LineText>
                        </td>
                        <td>
                          <LineText>
                            {tx.sign === "debit"
                              ? returnCurrency(tx.amount)
                              : ""}
                          </LineText>
                        </td>
                        <td>
                          <LineText>
                            {tx.sign === "credit"
                              ? returnCurrency(tx.amount)
                              : ""}
                          </LineText>
                        </td>
                        <td>
                          <LineText>{returnCurrency(tx.runningTotal)}</LineText>
                        </td>
                      </RowWrapper>
                    ))}
                    <RowWrapper key={`${accountId}-totals`}>
                      <td colSpan={4} />
                      <td>
                        <AmountLineText
                          style={{ borderTop: "1px solid black" }}>
                          {returnCurrency(generalLedger[accountId].totalDebits)}
                        </AmountLineText>
                      </td>
                      <td>
                        <AmountLineText
                          style={{ borderTop: "1px solid black" }}>
                          {returnCurrency(
                            generalLedger[accountId].totalCredits,
                          )}
                        </AmountLineText>
                      </td>
                      <td>
                        <AmountLineText
                          style={{ borderTop: "1px solid black" }}>
                          {returnCurrency(
                            generalLedger[accountId].endingBalance,
                          )}
                        </AmountLineText>
                      </td>
                    </RowWrapper>
                  </React.Fragment>
                ))}
          </tbody>
        </table>
      </div>
    </ComponentPageWrapper>
  );
};

const GeneralLedger = withSubscriptionProtection(GeneralLedgerProtected);

export default GeneralLedger;
