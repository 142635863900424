import React, { useEffect } from "react";
import { useContext, useState } from "react";
import UserContext from "../../../assets/user_context";
import { LineText, TopRowDiv } from "../../component_styles";
import AccountSelector from "../account_selector";
import { ButtonText, CreateLinkButton } from "../banking/banking_styles";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import {
  deleteRec,
  reportToPDF,
  returnDateForDisplay,
} from "./rec_helper_tools";
import { StyledTitleText } from "../../transactions/transactions_styles";
import { DeleteOutline, FileDownloadOutlined } from "@mui/icons-material";
import { RecButton } from "./reconciliation_styles.jsx";
import { ButtonDiv } from "../../reports/reports_styles";
import { convertToDate } from "../../../utilities/general_util.jsx";
import RecDisplay from "./completed_rec.jsx";
import { useParsedRecForDisplay } from "./reconciliation_effects.jsx";
import { ConfirmAction } from "../../../assets/controls.jsx";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { primary_color } from "../../../constants/color_constants.jsx";

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

const CompletedRecs = ({
  selectedAccount,
  isInReports,
  recsForAcct,
  setInARec,
  setSelectedAccount,
  registerAccounts,
  recInProgress,
  db,
  processingRec,
}) => {
  const { orgRoles, contacts, accounts, org, user } = useContext(UserContext);

  const [recToDisplay, setRecToDisplay] = useState(null); //reconciliation to display (if there is one
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [idToRemove, setIdToRemove] = useState(null);

  useParsedRecForDisplay({ recToDisplay, setRecToDisplay });

  //useEffect to log recToDisplay when it changes
  useEffect(() => {
    console.log("recToDisplay", recToDisplay);
  }, [recToDisplay]);

  return (
    <React.Fragment>
      {user?.uid && (
        <ConfirmAction
          callback={deleteRec}
          openState={confirmRemove}
          handleCloseState={() => setConfirmRemove(false)}
          dataForAction={{
            recId: idToRemove,
            db,
            org,
            userId: user.uid,
            label: "reconcilation permanently. This cannot be undone.",
          }}>
          remove
        </ConfirmAction>
      )}
      <TopRowDiv>
        <AccountSelector
          accounts={registerAccounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
        {orgRoles.some((role) => role === "admin" || role === "bookkeeper") &&
        !isInReports &&
        !recToDisplay ? (
          <CreateLinkButton onClick={() => setInARec(true)}>
            <ButtonText>
              {recInProgress?.id
                ? "Continue Reconciliation"
                : "Start Reconciliation"}
            </ButtonText>
            <ChecklistRtlIcon />
            <div style={{ marginLeft: "10px" }} />
          </CreateLinkButton>
        ) : null}
        {recToDisplay !== null && (
          <ButtonDiv
            onClick={() =>
              reportToPDF({ selectedAccount, accounts, recToDisplay })
            }>
            <LineText style={{ whiteSpace: "nowrap" }}>Export to PDF</LineText>
            {/* <div style={{ width: "10px" }} /> */}
            <FileDownloadOutlined fontSize="medium" />
          </ButtonDiv>
        )}
      </TopRowDiv>
      {!recToDisplay && (
        <StyledTitleText>Completed Reconciliations</StyledTitleText>
      )}
      {processingRec && (
        <RowDiv
          style={{
            width: "min-content",
            border: "dashed black 1px",
            padding: "0.75rem",
            borderRadius: "1rem",
          }}>
          <CircularProgress style={{ color: primary_color }} />
          <LineText>Processing New Reconciliation</LineText>
        </RowDiv>
      )}
      {recsForAcct.length > 0 && !recToDisplay ? (
        recsForAcct
          .sort((a, b) =>
            convertToDate(a.closingDate).isBefore(convertToDate(b.closingDate))
              ? 1
              : -1,
          )
          .map((rec, index) => (
            <RowDiv key={`RecFrag-${index}`}>
              <RecButton
                key={rec.id}
                onClick={() => setRecToDisplay({ ...rec, sorted: false })}>
                {rec?.closingDate && (
                  <LineText>
                    {`Reconciliation through ${returnDateForDisplay(
                      rec.closingDate,
                    )}, completed on ${returnDateForDisplay(
                      rec.completedDate,
                    )} by ${rec.username ? rec.username : "unknown user"}`}
                  </LineText>
                )}
              </RecButton>
              {index === 0 ? (
                <DeleteOutline
                  style={{ height: "100%", width: "30px", cursor: "pointer" }}
                  onClick={async () => {
                    console.log("Delete rec", rec.id);
                    setIdToRemove(rec.id);
                    setConfirmRemove(true);
                  }}
                />
              ) : (
                <div />
              )}
            </RowDiv>
          ))
      ) : recToDisplay?.sorted ? (
        <RecDisplay
          setRecToDisplay={setRecToDisplay}
          recToDisplay={recToDisplay}
          accounts={accounts}
          contacts={contacts}
        />
      ) : (
        <LineText>No completed reconciliations</LineText>
      )}
    </React.Fragment>
  );
};

export default CompletedRecs;
