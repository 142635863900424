import React, { useState, useEffect, useContext } from "react";
import {
  RowWrapper,
  LineText,
  PageWrapper,
  HorizontalWrapper,
  ButtonDiv,
} from "../reports_styles.jsx";
import html2pdf from "html2pdf.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import UserContext from "../../../assets/user_context.jsx";
import { calcReportV2 } from "../calculators/calc_reports.js";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import { FileDownloadOutlined, Inventory2Outlined } from "@mui/icons-material";
import { convertToJSON, exportReportXlsx } from "../export_report_xlsx.jsx";
import ReactDOMServer from "react-dom/server";
import TransactionsDrilling from "../transaction_drilling/transactions_drilling.jsx";

import { useTrackFundsDisplayedCount } from "../report_helper_functions.js";
import AccountTypeRows, { EquityColumns } from "../account_type_rows.jsx";
import TableHeader from "../funds_header.jsx";
dayjs.extend(isBetween);

const BalanceSheetProtected = () => {
  const {
    accounts,
    accountsHierarchy,
    funds,
    fundsHierarchy,
    transactions,
    contacts,
    orgData,
  } = useContext(UserContext);

  // const result = fundsHierarchy.groups.map((group, index) => {
  //   console.log(group.groupName);
  //   return group.funds.map((fund, innerIndex) => {
  //     console.log(fund);
  //     return fund;
  //   });
  // });

  // console.log(result);

  const [newBalanceSheet, setNewBalanceSheet] = useState({});

  const [date, setDate] = useState(dayjs());

  const [txModalOpen, setTxModalOpen] = useState(false);
  const [fundTransactions, setFundTransactions] = useState({});
  const [txToDrill, setTxToDrill] = useState({ fund: null, account: null });

  const [showFunds, setShowFunds] = useState(true);
  const [numberOfFundsShown, setNumberOfFundsShown] = useState(0);

  const [fundsDetailsToDisplay, setFundsDetailsToDisplay] = useState([]);

  useEffect(() => {
    if (fundsHierarchy?.groups?.length > 0) {
      const fundsDetails = fundsHierarchy.groups.map((group) => {
        return {
          show: true,
          funds: group.funds.map((fund) => {
            return { show: true };
          }),
        };
      });
      setFundsDetailsToDisplay(fundsDetails);
    }
  }, [fundsHierarchy]);

  const toggleFundVisibility = ({ groupIndex, fundIndex }) => {
    setFundsDetailsToDisplay((prevGroups) =>
      prevGroups.map((group, gIndex) => {
        if (gIndex === groupIndex) {
          // Toggle the entire group's visibility if it's the group header
          if (fundIndex === null) {
            return {
              ...group,
              show: !group.show,
            };
          } else {
            // Toggle only the subFunds' visibility for a specific fund
            const funds = group.funds.map((fund, fIndex) => {
              if (fIndex === fundIndex) {
                return {
                  show: !fund.show,
                };
              }
              return fund;
            });
            return { ...group, funds };
          }
        }
        return group;
      }),
    );
  };

  const filterTransactionsToInspect = ({ relevantLines }) => {
    console.log("relevantLines: ", relevantLines);
    setTxToDrill(relevantLines);
    setTxModalOpen(true);
  };

  //log fundsDetailsToDisplay when it changes
  useEffect(() => {
    console.log(fundsDetailsToDisplay);
  }, [fundsDetailsToDisplay]);

  //Calculate totals from all transactions
  useEffect(() => {
    if (
      transactions?.length > 0 &&
      date &&
      accounts?.length > 0 &&
      funds?.length > 0
    ) {
      const txBeforeDate = transactions.filter((entry) => {
        const dateIsBeforeOrSame =
          dayjs(entry.date).isBefore(date, "day") ||
          dayjs(entry.date).isSame(date, "day");
        return dateIsBeforeOrSame;
      });
      if (txBeforeDate.length > 0) {
        const version2BalanceSheet = calcReportV2({
          filteredTransactions: txBeforeDate,
          accounts,
          funds,
          accountsHierarchy,
          fundsHierarchy,
          type: "balanceSheet",
        });
        console.log("Version 2 Balance Sheet: ", version2BalanceSheet);
        setNewBalanceSheet(version2BalanceSheet);
      } else {
        console.log("No transactions in date range");
        setNewBalanceSheet({});
      }
    }
  }, [transactions, date, accounts, funds]);

  const renderTitle = () => {
    return (
      <React.Fragment>
        <p
          style={{
            fontSize: "1.2rem",
            fontFamily: "MontserratMed",
            textAlign: "center",
            margin: "0.5rem",
            marginBottom: "27px",
          }}>
          {`Balance Sheet as of ${date.format("MM/DD/YYYY")}`}
        </p>
        <div style={{ height: "10px" }} />
      </React.Fragment>
    );
  };

  useTrackFundsDisplayedCount({
    fundsHierarchy,
    fundsDetailsToDisplay,
    setNumberOfFundsShown,
  });

  const addPdfExportClass = (element) => {
    element.classList.add("pdf-export");
    Array.from(element.children).forEach(addPdfExportClass);
  };

  const reportToPDF = () => {
    const element = document.getElementById("balance-sheet-table");
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Clone the element
    const clone = element.cloneNode(true);
    clone.classList.add("clone-for-pdf");

    // Find all <p> elements within <th> in the clone and add the 'pdf-header-text' class
    const headerParagraphs = clone.querySelectorAll("th > p"); // This selects <p> elements that are direct children of <th>
    headerParagraphs.forEach((p) => {
      p.classList.add("pdf-header-text");
    });

    if (numberOfFundsShown > 4) {
      clone.classList.add("small-text");
    }

    addPdfExportClass(clone);

    // Measure the height of the clone
    const tempContainer = document.createElement("div");
    document.body.appendChild(tempContainer); // Temporarily add to the body
    tempContainer.appendChild(clone); // Append clone for measurement

    const contentHeight = clone.scrollHeight;
    document.body.removeChild(tempContainer); // Remove temp container after measurement

    // Create a container for the clone and title
    const container = document.createElement("div");
    container.style.width = numberOfFundsShown > 4 ? "297mm" : "210mm"; // A4 width: ;
    container.style.height = `${
      contentHeight + (numberOfFundsShown > 4 ? 25 : 60)
    }px`; // A4 height: ;
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.alignItems = "center";
    // container.style.visibility = "hidden"; // Make the container invisible while keeping it in the flow

    // Render the title to a string
    const titleHtml = ReactDOMServer.renderToString(renderTitle());
    // Append title and clone to the container
    container.innerHTML = titleHtml;
    container.appendChild(clone);
    document.body.appendChild(container);

    // Define options for html2pdf
    const options = {
      margin: 10,
      filename: `balance-sheet-${date.format("MM/DD/YYYY")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: numberOfFundsShown > 4 ? "landscape" : "portrait",
      },
      pagebreak: { mode: ["css", "legacy"] },
    };

    // Generate PDF
    setTimeout(() => {
      html2pdf()
        .from(container)
        .set(options)
        .save()
        .then(() => {
          document.body.removeChild(container); // Clean up after saving PDF
        });
    }, 800); // Delay to ensure rendering
  };

  return (
    <PageWrapper style={{ width: "100%", alignItems: "flex-start" }}>
      <HorizontalWrapper style={{ paddingBottom: "1rem", width: "80vw" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RowWrapper>
            <DatePicker
              label="Balance on Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
          </RowWrapper>
        </LocalizationProvider>
        <div style={{ height: "60px", width: "100px" }} />
        <ButtonDiv>
          <LineText style={{ whiteSpace: "nowrap" }}>Export to XLSX</LineText>
          {/* <div style={{ width: "10px" }} /> */}
          <Inventory2Outlined
            fontSize="medium"
            onClick={() => {
              if (
                transactions?.length > 0 &&
                date &&
                accounts?.length > 0 &&
                funds?.length > 0
              ) {
                const txBeforeDate = transactions.filter((entry) => {
                  const dateIsBeforeOrSame =
                    dayjs(entry.date).isBefore(date, "day") ||
                    dayjs(entry.date).isSame(date, "day");
                  return dateIsBeforeOrSame;
                });
                if (txBeforeDate.length > 0) {
                  const JSONReport = convertToJSON({
                    reportData: newBalanceSheet,
                    funds,
                  });
                  console.log(JSONReport);
                  exportReportXlsx({
                    JSONReport,
                    reportName: "Balance Sheet",
                    dateRange: date.format("MM/DD/YYYY"),
                    orgName: orgData?.orgName || "Test Organization",
                  });
                }
              }
            }}
          />
        </ButtonDiv>
        <div style={{ width: "60px" }} />
        <Tooltip
          title={
            numberOfFundsShown > 8
              ? "Too many horizontal funds, collapse some if possible"
              : null
          }>
          <ButtonDiv
            style={
              numberOfFundsShown > 8
                ? { borderColor: "grey", cursor: "not-allowed" }
                : {}
            }
            disabled={numberOfFundsShown > 8}
            onClick={() => {
              if (numberOfFundsShown <= 8) reportToPDF();
            }}>
            <LineText
              style={
                numberOfFundsShown > 8
                  ? {
                      textDecorationLine: "line-through",
                      whiteSpace: "nowrap",
                      color: "gray",
                    }
                  : { whiteSpace: "nowrap" }
              }>
              Export to PDF
            </LineText>
            {/* <div style={{ width: "10px" }} /> */}
            <FileDownloadOutlined
              fontSize="medium"
              style={numberOfFundsShown > 8 ? { color: "grey" } : {}}
            />
          </ButtonDiv>
        </Tooltip>
      </HorizontalWrapper>

      <TransactionsDrilling
        txModalOpen={txModalOpen}
        setTxToDrill={setTxToDrill}
        setTxModalOpen={setTxModalOpen}
        contacts={contacts}
        txToDrill={txToDrill}
        accounts={accounts}
      />

      {Object.keys(newBalanceSheet).length > 0 ? (
        <div
          id="balance-sheet-table"
          style={{
            paddingTop: "1.75rem",
            width: "100%",
            overflowX: "auto",
            maxWidth: "92vw",
          }}>
          <table>
            <TableHeader
              showFunds={showFunds}
              fundsDetailsToDisplay={fundsDetailsToDisplay}
              toggleFundVisibility={toggleFundVisibility}
              setShowFunds={setShowFunds}
              numberOfFundsShown={numberOfFundsShown}
            />
            <tbody>
              <AccountTypeRows
                accountType="Assets"
                balanceSheetObject={newBalanceSheet}
                fundsDetailsToDisplay={fundsDetailsToDisplay}
                showFunds={showFunds}
                filterTransactionsToInspect={filterTransactionsToInspect}
              />
              <AccountTypeRows
                accountType="Liabilities"
                balanceSheetObject={newBalanceSheet}
                fundsDetailsToDisplay={fundsDetailsToDisplay}
                showFunds={showFunds}
                filterTransactionsToInspect={filterTransactionsToInspect}
              />
              <EquityColumns
                balanceSheetObject={newBalanceSheet}
                fundsDetailsToDisplay={fundsDetailsToDisplay}
                showFunds={showFunds}
              />
            </tbody>
          </table>
        </div>
      ) : null}
    </PageWrapper>
  );
};

const BalanceSheet = withSubscriptionProtection(BalanceSheetProtected);

export default BalanceSheet;
