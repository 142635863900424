export const findAccountType = (accountNumber) => {
  //Get the first digit of the account number
  const firstDigit = parseInt(accountNumber.toString().slice(0, 1));
  if (firstDigit === 1) {
    return "Assets";
  } else if (firstDigit === 2) {
    return "Liabilities";
  } else if (firstDigit === 3) {
    return "Equity";
  } else if (firstDigit === 4) {
    return "Income";
  } else if (firstDigit >= 5) {
    return "Expenses";
  } else {
    return "Invalid account number";
  }
};

export const createNewHierarchy = (
  accountsHierarchy,
  type,
  groupName,
  accountId,
  parentId,
  subAccountIds,
) => {
  const typeOrder = ["Assets", "Liabilities", "Income", "Expenses", "Equity"];

  console.log(
    "createNewHierarchy FROM:",
    accountsHierarchy,
    type,
    groupName,
    accountId,
    parentId,
  );
  console.log("ParendID: ", parentId);
  if (!type || !groupName || !accountId) {
    console.error("Invalid: ", type, groupName, accountId);
    return null;
  } // Handling invalid inputs

  // If accountsHierarchy is null or undefined, create an empty hierarchy
  if (!accountsHierarchy) {
    return {
      types: [
        {
          type: type,
          groups: [
            {
              groupName: groupName,
              accounts: [{ id: accountId, subAccounts: [] }],
            },
          ],
        },
      ],
    };
  }

  // Check if the type exists
  const existingType = accountsHierarchy.types?.find((t) => t.type === type);

  if (!existingType) {
    const newTypes =
      accountsHierarchy?.types?.length > 0
        ? [
            ...accountsHierarchy.types,
            { type, groups: [{ groupName, accounts: [{ id: accountId }] }] },
          ]
        : [{ type, groups: [{ groupName, accounts: [{ id: accountId }] }] }];
    newTypes.sort((a, b) => {
      const orderA = typeOrder.indexOf(a.type);
      const orderB = typeOrder.indexOf(b.type);
      return orderA - orderB;
    });
    return { ...accountsHierarchy, types: newTypes };
  }

  // Check if the group exists within the type
  const existingGroup = existingType.groups?.find(
    (g) => g.groupName === groupName,
  );

  if (!existingGroup) {
    const updatedTypes = accountsHierarchy.types.map((t) =>
      t.type === type
        ? {
            ...t,
            groups: [
              ...t.groups,
              {
                groupName,
                accounts: [
                  {
                    id: accountId,
                    subAccounts: subAccountIds?.length > 0 ? subAccountIds : [],
                  },
                ],
              },
            ],
          }
        : t,
    );
    updatedTypes.sort((a, b) => {
      const orderA = typeOrder.indexOf(a.type);
      const orderB = typeOrder.indexOf(b.type);
      return orderA - orderB;
    });
    return { ...accountsHierarchy, types: updatedTypes };
  }

  // If the group exists, add the account to it
  console.log("Existing group found: ", existingGroup);
  const updatedHierarchyTypes = accountsHierarchy.types.map((t) =>
    t.type === type
      ? {
          ...t,
          groups: t.groups.map((g) => {
            if (g.groupName === groupName) {
              let updatedAccounts = [...g.accounts];

              // If there's a parentId, update the subAccounts for that parent account
              if (parentId) {
                const parentAccount = updatedAccounts.find(
                  (acc) => acc.id === parentId,
                );
                if (parentAccount) {
                  parentAccount.subAccounts = [
                    ...(parentAccount.subAccounts || []),
                    accountId,
                  ];
                }
              } else {
                // No parentId, just add the account normally
                updatedAccounts.push({
                  id: accountId,
                  subAccounts: subAccountIds?.length > 0 ? subAccountIds : [],
                });
              }

              return { ...g, accounts: updatedAccounts };
            }
            return g;
          }),
        }
      : t,
  );
  updatedHierarchyTypes.sort((a, b) => {
    const orderA = typeOrder.indexOf(a.type);
    const orderB = typeOrder.indexOf(b.type);
    return orderA - orderB;
  });
  return { ...accountsHierarchy, types: updatedHierarchyTypes };
};

export const createHierarchyFromMassImport = (groupsMapping, accountIds) => {
  console.log("createHierarchyFromMassImport is being called");
  console.log("createHierarchyFromMassImport", groupsMapping, accountIds);
  const typesPossible = [
    "Assets",
    "Liabilities",
    "Equity",
    "Income",
    "Expenses",
  ];
  const tempArr = [];
  typesPossible.forEach((type) => {
    console.log("Processing type: ", type);
    const accountsByType = Object.entries(groupsMapping).filter(
      ([accountNumber, accountData]) => accountData.type === type,
    );
    console.log("accountsByType for type " + type + ": ", accountsByType);
    if (accountsByType.length > 0) {
      const groupsByType = accountsByType.map(
        ([accountNumber, accountData]) => {
          return accountData.group;
        },
      );
      const uniqueGroups = [...new Set(groupsByType)];
      const groupsArr = [];
      console.log("uniqueGroups: ", uniqueGroups);
      uniqueGroups.forEach((group) => {
        console.log("group: ", group);
        const accountsByGroup = accountsByType.filter(
          ([accountNumber, accountData]) => accountData.group === group,
        );
        console.log("accountsByGroup: ", accountsByGroup);
        groupsArr.push({
          groupName: group,
          accounts: accountsByGroup.map(([accountNumber, accountData]) => {
            console.log("accountNumber: ", accountNumber);
            const accountObj = accountIds.find((account) => {
              console.log("account: ", account, accountNumber);
              return account[accountNumber] !== undefined;
            });
            return accountObj ? { id: accountObj[accountNumber] } : undefined;
          }),
        });
      });
      tempArr.push({
        type: type,
        groups: groupsArr,
      });
    }
  });

  console.log("sortedTempArr: ", tempArr);
  return { types: tempArr };
};

export const removeHierarchyAccount = (accountsHierarchy, accountId) => {
  if (!accountsHierarchy) {
    return null;
  }

  const removeAccountFromArr = (accounts) => {
    return accounts
      .map((account) => {
        // Remove from subAccounts if present
        if (account.subAccounts) {
          account.subAccounts = account.subAccounts.filter(
            (id) => id !== accountId,
          );
        }
        return account;
      })
      .filter((account) => account.id !== accountId);
  };

  const updatedTypes = accountsHierarchy.types.map((t) => {
    const updatedGroups = t.groups.map((g) => {
      const updatedAccounts = removeAccountFromArr(g.accounts);
      return { ...g, accounts: updatedAccounts };
    });

    const nonEmptyGroups = updatedGroups.filter((g) => g.accounts.length > 0);
    return { ...t, groups: nonEmptyGroups };
  });

  return { ...accountsHierarchy, types: updatedTypes };
};

export const findAccountsGroupInHierarchy = (accountsHierarchy, accountId) => {
  if (!accountsHierarchy) {
    return null;
  }

  const group = accountsHierarchy.types
    .map((t) => t.groups)
    .flat()
    .find((g) => {
      return g.accounts.find((a) => a.id === accountId);
    });

  return group;
};

//Search the hierarchy to find if an account is in a subAccounts array
export const findIfSubAccountInHierarchy = (accountsHierarchy, accountId) => {
  if (!accountsHierarchy) {
    return null;
  }

  const groupsWithThisAsSubaccount = accountsHierarchy.types
    .map((t) => t.groups)
    .flat()
    .map((g) => g.accounts)
    .flat()
    .filter((group) => {
      return group.subAccounts?.includes(accountId);
    });

  const isASubAccount = groupsWithThisAsSubaccount.length > 0;

  console.log("SubAccount? : ", isASubAccount);

  return isASubAccount;
};

export const createNewFundHierarchy = (
  fundsHierarchy,
  groupName,
  fundId,
  parentId,
  subFundIds,
) => {
  console.log(
    "createNewFundHierarchy FROM:",
    fundsHierarchy,
    groupName,
    fundId,
    parentId,
    subFundIds,
  );

  if (!groupName || !fundId) {
    console.error("Invalid: ", groupName, fundId);
    return null;
  } // Handling invalid inputs

  // If fundsHierarchy is null or undefined, create an empty hierarchy
  if (!fundsHierarchy) {
    return {
      groups: [
        {
          groupName: groupName,
          funds: [{ id: fundId }],
        },
      ],
    };
  }

  // Check if the group exists
  const existingGroup = fundsHierarchy.groups?.find(
    (g) => g.groupName === groupName,
  );

  if (!existingGroup) {
    const newGroups =
      fundsHierarchy?.groups?.length > 0
        ? [
            ...fundsHierarchy.groups,
            {
              groupName,
              funds: [
                {
                  id: fundId,
                  subFunds: subFundIds?.length > 0 ? subFundIds : [],
                },
              ],
            },
          ]
        : [{ groupName, funds: [{ id: fundId }] }];
    return { ...fundsHierarchy, groups: newGroups };
  }

  // If the group exists, add the fund to it
  console.log("Existing group found: ", existingGroup);
  const updatedHierarchyGroups = fundsHierarchy.groups.map((g) => {
    if (g.groupName === groupName) {
      let updatedFunds = [...g.funds];

      if (parentId) {
        const parentFund = updatedFunds.find((fund) => fund.id === parentId);
        if (parentFund) {
          parentFund.subFunds = [...(parentFund.subFunds || []), fundId];
          return { ...g, funds: updatedFunds }; // Exit early, the fund is added as a subFund
        }
      }
      // No parentId, or parentFund not found. Add the fund normally.
      updatedFunds.push({
        id: fundId,
        subFunds: subFundIds?.length > 0 ? subFundIds : [],
      });
      return { ...g, funds: updatedFunds };
    }
    return g;
  });
  return { ...fundsHierarchy, groups: updatedHierarchyGroups };
};

export const removeFundHierarchy = (fundsHierarchy, fundId) => {
  if (!fundsHierarchy) {
    return null;
  }

  const removeFundFromArr = (funds) => {
    return funds
      .map((fund) => {
        // Remove from subFunds if present
        if (fund.subFunds) {
          fund.subFunds = fund.subFunds.filter((id) => id !== fundId);
        }
        return fund;
      })
      .filter((fund) => fund.id !== fundId);
  };

  const updatedGroups = fundsHierarchy.groups.map((g) => {
    const updatedFunds = removeFundFromArr(g.funds);
    return { ...g, funds: updatedFunds };
  });

  const nonEmptyGroups = updatedGroups.filter((g) => g.funds.length > 0);

  return { ...fundsHierarchy, groups: nonEmptyGroups };
};

export const findIfSubFundInHierarchy = (fundsHierarchy, fundId) => {
  if (!fundsHierarchy) {
    return null;
  }

  // Flatten all groups' funds into a single array
  const allFunds = (fundsHierarchy.groups || [])
    .map((group) => group.funds)
    .flat();

  // Check if the fund appears in any subFunds array
  const fundsWithThisAsSubfund = allFunds.filter((fund) => {
    return fund.subFunds?.includes(fundId);
  });

  const isASubFund = fundsWithThisAsSubfund.length > 0;

  console.log("Currently a Subfund? : ", isASubFund);

  return isASubFund;
};

export const findFundsGroupInHierarchy = (fundsHierarchy, fundId) => {
  if (!fundsHierarchy) {
    return null;
  }

  const group = (fundsHierarchy.groups || []).find((g) => {
    return g.funds.find((f) => f.id === fundId);
  });

  return group;
};
