import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../assets/user_context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from "@mui/material";
import styled from "@emotion/styled";
import { primary_highlight_color } from "../../../constants/color_constants";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import {
  formatCurrency,
  generateRandomId,
  returnCurrency,
  returnCurrencyInput,
} from "../../../utilities/general_util";

const StyledHelperTextField = styled(TextField)`
  .MuiFormHelperText-root.Mui-error {
    color: #f44336; // or whatever the default Material-UI error color you want
  }

  .MuiFormHelperText-root {
    color: ${primary_highlight_color};
    font-size: 0.8rem;
  }
`;

export const addJournalEntry = async ({
  accountEntryData,
  accounts,
  funds,
  accountId,
  user,
  org,
  db,
  experimental,
  setTransactions,
  newAccountOverride,
}) => {
  //Need to create a journalEntry that will be used to create the starting balance from equity accounts

  const lines = [];
  console.log("accountEntryData: ", accountEntryData);
  console.log("accountTypeOverride: ", newAccountOverride);
  Object.keys(accountEntryData.fundStartingBalances).forEach((fundID) => {
    if (accountEntryData.fundStartingBalances[fundID] !== "") {
      lines.push({
        //Equity account line
        account: accounts.find(
          (account) =>
            account.accountNumber ===
            funds.find((fund) => fund.id === fundID).fundNumber,
        ).id,
        fund: fundID,
        amount: accountEntryData.fundStartingBalances[fundID],
        sign: newAccountOverride?.accountType
          ? newAccountOverride.accountType === "Assets"
            ? "credit"
            : "debit"
          : accounts.find((account) => account.id === accountId).accountType ===
            "Assets"
          ? "credit"
          : "debit",
        comment: `Starting Balance for ${
          newAccountOverride?.accountName
            ? newAccountOverride.accountName
            : accountEntryData.accountName
        }`,
        contact: null,
      });
      lines.push({
        //Account line
        account: accountId,
        fund: fundID,
        amount: accountEntryData.fundStartingBalances[fundID],
        sign: newAccountOverride?.accountType
          ? newAccountOverride.accountType === "Assets"
            ? "debit"
            : "credit"
          : accounts.find((account) => account.id === accountId).accountType ===
            "Assets"
          ? "debit"
          : "credit",
        comment: `Starting Balance for ${
          newAccountOverride?.accountName
            ? newAccountOverride.accountName
            : accountEntryData.accountName
        }`,
        contact: null,
      });
    }
  });
  console.log("Lines: ", lines);
  console.log("Date: ", accountEntryData.startingBalanceDate);
  const journalEntryData = {
    date: accountEntryData.startingBalanceDate.$d
      ? accountEntryData.startingBalanceDate.$d
      : accountEntryData.startingBalanceDate,
    addTimestamp: true,
    memo: `Starting Balance for ${
      newAccountOverride?.accountName
        ? newAccountOverride.accountName
        : accounts.find((account) => account.id === accountId).accountName
    }`,
    lines: lines,
    userMadeChange: user.uid,
    journalEntry: true,
  };
  console.log(journalEntryData);
  let res;
  if (!experimental) {
    try {
      const journalEntriesRef = collection(db, "orgs", org, "journalEntries");
      const journalEntryRef = await addDoc(journalEntriesRef, journalEntryData);
      console.log(journalEntryRef);
      //update the account doc to have startingBalance=true
      const accountRef = doc(db, "orgs", org, "accounts", accountId);
      res = await updateDoc(
        accountRef,
        {
          startingBalance: true,
        },
        { merge: true },
      );
    } catch (e) {
      console.error("Error adding StartingBalance journal entry: ", e);
    }
  } else {
    setTransactions((prevState) => [
      ...prevState,
      { ...journalEntryData, id: generateRandomId() },
    ]);
  }
  return res;
};

const StartingBalance = ({
  accountEntryData,
  setAccountEntryData,
  entryErrors,
  entryFeedback,
  location,
  accountsWithoutSB,
}) => {
  const { funds } = useContext(UserContext);
  const [remainingStartingToAssign, setRemainingStartingToAssign] = useState({
    string: "",
    num: 0,
  });
  const [startingBalanceDisplay, setStartingBalanceDisplay] = useState("");
  const [fundStartingBalancesDisplay, setFundStartingBalancesDisplay] =
    useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    const match = name.split("-")[1];
    // TextField name={`startingBalance-${fund.id}`}

    if (name === "startingBalance") {
      // let inputVal = value.replace(/[^0-9]/g, "");
      // inputVal = inputVal.padStart(2, "0");
      // const newCentsValue = parseInt(inputVal, 10);
      const { centsInt: newCentsValue, formattedDollarValue } =
        returnCurrencyInput(value);

      setAccountEntryData((prevData) => ({
        ...prevData,
        startingBalance: newCentsValue,
        // Initialize 'fundStartingBalances' if it doesn't exist
        fundStartingBalances:
          prevData.fundStartingBalances ||
          funds.reduce((acc, fund) => {
            acc[fund.id] = ""; // Initialize each fund's starting balance as an empty string
            return acc;
          }, {}),
      }));
      setStartingBalanceDisplay(formattedDollarValue);
    } else if (match) {
      const fundId = match;
      // let inputVal = value.replace(/[^0-9]/g, "");
      // inputVal = inputVal.padStart(2, "0");
      // const newCentsValue = parseInt(inputVal, 10);
      const { centsInt: newCentsValue, formattedDollarValue } =
        returnCurrencyInput(value);

      setAccountEntryData((prevData) => ({
        ...prevData,
        fundStartingBalances: {
          ...prevData.fundStartingBalances,
          [fundId]: newCentsValue,
        },
      }));

      setFundStartingBalancesDisplay((prevDisplay) => ({
        ...prevDisplay,
        [fundId]: formattedDollarValue,
      }));
    }
  };

  // const calcRemainingStartingToAssign = () => {
  //   if (accountEntryData.startingBalance > 0) {
  //     const sumOfFundStartingBalances = Object.values(
  //       accountEntryData.fundStartingBalances,
  //     ).reduce((acc, curr) => acc + (curr || 0), 0);

  //     const remainder =
  //       accountEntryData.startingBalance - sumOfFundStartingBalances;
  //     setRemainingStartingToAssign(returnCurrency(remainder));
  //   }
  // };

  const calcRemainingStartingToAssign = () => {
    if (accountEntryData.startingBalance > 0) {
      const fundStartingBalances = Object.values(
        accountEntryData.fundStartingBalances,
      );

      const sumOfFundStartingBalances = fundStartingBalances.reduce(
        (acc, curr) => {
          if (curr !== "") {
            return acc + (curr || 0);
          } else {
            return acc;
          }
        },
        0,
      );

      const remainder =
        accountEntryData.startingBalance - sumOfFundStartingBalances;
      console.log("remainder", remainder, returnCurrency(remainder));
      setRemainingStartingToAssign({
        string: returnCurrency(Math.abs(remainder)),
        num: remainder,
      });
    }
  };

  useEffect(
    () => {
      console.log("accountEntryData changed", accountEntryData);
      //Reset display values when accountEntryData changes
      setStartingBalanceDisplay("");
      setFundStartingBalancesDisplay({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountsWithoutSB],
  );

  useEffect(() => {
    console.log("entryErrors: ", entryErrors);
  }, [entryErrors]);

  //Error style for date picker since prop is not working
  const errorStyleForDate = entryErrors.startingBalanceDate
    ? {
        color: "red",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "red",
          },
        },
        "& .MuiInputLabel-root": {
          // Custom style for the label
          color: "red",
        },
      }
    : {};

  return (
    <>
      <StyledHelperTextField
        sx={{
          width: "350px",
          fontSize: "22px",
        }}
        label="Starting Balance"
        name="startingBalance"
        variant="outlined"
        color="secondary"
        value={startingBalanceDisplay}
        onChange={handleChange}
        error={entryErrors.startingBalanceDate}
        disabled={typeof accountDataToEdit === "object" ? true : false}
        helperText={
          remainingStartingToAssign.num > 0
            ? `You have ${remainingStartingToAssign.string} left to assign to funds`
            : remainingStartingToAssign.num < 0
            ? `You have assigned too many funds by  ${remainingStartingToAssign.string}`
            : ""
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onBlur={() =>
          setStartingBalanceDisplay(
            formatCurrency(accountEntryData.startingBalance),
          )
        }
      />
      {accountEntryData.startingBalance > 0 &&
        Object.keys(accountEntryData.fundStartingBalances || {}).length > 0 && (
          <>
            <div style={{ height: "20px" }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={<span>Transaction Date *</span>}
                sx={errorStyleForDate}
                value={accountEntryData.startingBalanceDate}
                onChange={(event) => {
                  console.log(event);
                  const value = event.$d;
                  const newAccountEntryData = {
                    ...accountEntryData,
                  };
                  newAccountEntryData.startingBalanceDate = value;
                  console.log(newAccountEntryData);
                  setAccountEntryData(newAccountEntryData);
                }}
                disableFuture
                disabled={location === "onboarding" ? true : false}
                variant="outlined"
                color="secondary"
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: "20px" }}
                    {...params}
                    error={true}
                    variant="outlined"
                    color="secondary"
                  />
                )}
              />
            </LocalizationProvider>
            {funds.map((fund) => (
              <TextField
                sx={{
                  width: "320px",
                  fontSize: "22px",
                  marginTop: "20px",
                  marginLeft: "30px",
                }}
                label={`Starting Balance for ${fund.fundName}`}
                name={`startingBalance-${fund.id}`}
                variant="outlined"
                color="secondary"
                value={fundStartingBalancesDisplay[fund.id]}
                onChange={handleChange}
                error={entryErrors.startingBalance}
                disabled={typeof accountDataToEdit === "object" ? true : false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onBlur={() => {
                  calcRemainingStartingToAssign();
                  setFundStartingBalancesDisplay((prevDisplay) => ({
                    ...prevDisplay,
                    [fund.id]: formatCurrency(
                      accountEntryData.fundStartingBalances[fund.id],
                    ),
                  }));
                }}
                helperText={entryFeedback.startingBalance}
              />
            ))}
          </>
        )}
    </>
  );
};

export default StartingBalance;
