import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { css } from "@emotion/react";
import { primary_dark_color } from "../constants/color_constants";
import StyledButton from "./buttons";
import { Close } from "@mui/icons-material";

const ModalBox = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  width: max-content;
`;

const ModalInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
`;

export const ConfirmAction = ({
  callback,
  children,
  openState,
  handleCloseState,
  dataForAction,
}) => {
  // const [value, setValue] = useState(30);
  // const [actionTriggered, setActionTriggered] = useState(false);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // useEffect(() => {
  //   if (value >= 95 && !actionTriggered) {
  //     setActionTriggered(true); // Set the flag to true to prevent multiple triggers
  //     setTimeout(() => {
  //       handleCloseState();
  //       callback(dataForAction);
  //       setValue(30); // Reset the slider value
  //       setActionTriggered(false); // Reset the flag for future actions
  //     }, 100); // Adjust time as needed
  //   }
  // }, [callback, handleCloseState, value, dataForAction, actionTriggered]);

  return (
    <Modal open={openState} onClose={handleCloseState}>
      <ModalBox>
        {dataForAction !== undefined && dataForAction !== null && (
          <ModalInner>
            <Close
              style={{
                position: "absolute",
                top: "10px",
                right: "40px",
                color: "black",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={handleCloseState}
              fontSize="Large"
            />
            <Text>
              Click to {children} {dataForAction.label}
            </Text>
            {/* <Slider
              style={{ color: primary_dark_color }}
              aria-label="Confirm"
              value={value}
              onChange={handleChange}
            /> */}
            <StyledButton
              primary
              fontSize={"1.3rem"}
              width={"390px"}
              onClick={() => {
                handleCloseState();
                callback(dataForAction);
              }}>
              {`Confirm ${children}`}
            </StyledButton>
          </ModalInner>
        )}
      </ModalBox>
    </Modal>
  );
};

export const filterStyle = (props) => {
  if (props.blur) {
    return css`
      filter: blur(20px);
    `;
  } else if (props.cancelAnimate) {
    return css`
      filter: none;
    `;
  } else {
    return css`
      filter: none;
      transition: filter 0.3s ease-in;
    `;
  }
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
