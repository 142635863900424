import React, { useContext } from "react";
import { SubtitleText } from "../component_styles.jsx";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { Autocomplete, TextField, Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import StyledButton from "../../assets/buttons.js";
import { StyledSwitch, StyledTextField, LineText } from "./contacts_styles.jsx";
import UserContext from "../../assets/user_context.jsx";
import { generateRandomId } from "../../utilities/general_util.jsx";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ContactFields = ({ control, errors, touched }) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item xs={12}>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Company Name"
              required
              fullWidth
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="First Name"
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Last Name"
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Phone Number"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email Address"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={["Vendor", "Person"]}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Contact Type"
                  required
                  error={!!errors.type}
                  helperText={errors.type?.message}
                />
              )}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="shortName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nickname"
              required
              fullWidth
              error={!!errors.shortName}
              helperText={errors.shortName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="streetAddress"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Street Address"
              fullWidth
              error={!!errors.streetAddress}
              helperText={errors.streetAddress?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="City"
              fullWidth
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={states}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="State"
                  error={!!errors.state}
                  helperText={errors.state?.message}
                />
              )}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="zip"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Zip Code"
              fullWidth
              error={!!errors.zip}
              helperText={errors.zip?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Country"
              fullWidth
              error={!!errors.country}
              helperText={errors.country?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center">
        <Controller
          name="1099"
          control={control}
          render={({ field }) => (
            <StyledSwitch
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <LineText>This Contact receives a 1099</LineText>
      </Grid>
    </Grid>
  );
};

const AddContact = ({ db, org, contactToEdit, suggestedName, handleClose }) => {
  const { experimental, setContacts } = useContext(UserContext);

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    shortName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("You must include a nickname")
      .transform((value) => (value === "" ? null : value)),
    type: Yup.string()
      .required("You must select a contact type")
      .transform((value) => (value === "" ? null : value)),
    email: Yup.string()
      .email("Invalid email address")
      .max(35, "Must be 35 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    companyName: Yup.string()
      .min(3, "I'm sure that's too short...")
      .max(45, "Must be 45 characters or less")
      .required("You must include a company name")
      .transform((value) => (value === "" ? null : value)),
    firstName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    lastName: Yup.string()
      .max(30, "Must be 30 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    phone: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .notRequired(),
    streetAddress: Yup.string()
      .max(30, "Must be 30 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    city: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    state: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    zip: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
      .notRequired(),
    country: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    1099: Yup.boolean(),
  });

  // Initialize React Hook Form
  const {
    control,
    handleSubmit,
    formState: { errors, touched },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
      contactToEdit && Object.keys(contactToEdit)?.length > 0
        ? {
            shortName: contactToEdit?.shortName || "",
            type: contactToEdit?.type || "",
            companyName: contactToEdit?.companyName || "",
            firstName: contactToEdit?.firstName || "",
            lastName: contactToEdit?.lastName || "",
            phone: contactToEdit?.phone || "",
            email: contactToEdit?.email || "",
            streetAddress: contactToEdit?.streetAddress || "",
            city: contactToEdit?.city || "",
            state: contactToEdit?.state || "",
            zip: contactToEdit?.zip || "",
            country: contactToEdit?.country || "",
            1099: contactToEdit?.["1099"] || false,
          }
        : {
            shortName: "",
            type: "Vendor",
            companyName: suggestedName || "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            1099: false,
          },
  });

  // Handle form submission
  const onSubmit = async (values) => {
    if (contactToEdit && Object.keys(contactToEdit)?.length > 0) {
      console.log("Editing contact: ", values);
      if (!experimental) {
        const contactRef = doc(db, "orgs", org, "contacts", contactToEdit.id);
        try {
          await setDoc(
            contactRef,
            {
              ...values,
              previousMatchWords: contactToEdit?.previousMatchWords || "",
            },
            { merge: true },
          );
          console.log("Contact edited successfully");
        } catch (error) {
          console.error("Error editing contact: ", error);
        }
      } else {
        setContacts((prevState) =>
          prevState.map((contact) =>
            contact.id === contactToEdit.id ? values : contact,
          ),
        );
      }
    } else {
      console.log("Adding NEW contact: ", values);
      if (!experimental) {
        const contactsRef = collection(db, "orgs", org, "contacts");
        try {
          await addDoc(contactsRef, {
            ...values,
            previousMatchWords: suggestedName || "",
          });
          console.log("Contact added successfully");
        } catch (error) {
          console.error("Error adding contact: ", error);
        }
      } else {
        let contactId = generateRandomId();
        const newContact = { id: contactId, ...values };
        setContacts((prevState) => [...prevState, newContact]);
      }
    }
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <SubtitleText>
          {Object.keys(contactToEdit)?.length > 0
            ? "Edit Contact"
            : "Add Contact"}
        </SubtitleText>
        <ContactFields control={control} errors={errors} touched={touched} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
          <StyledButton type="submit" primary width="250px">
            {Object.keys(contactToEdit)?.length > 0
              ? "Edit Contact"
              : "Add Contact"}
          </StyledButton>
        </div>
      </div>
    </form>
  );
};

export default AddContact;
