import { Box, Modal } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { ModalBox, ModalInner } from "../reports_styles";
import {
  findAccountById,
  returnCurrency,
} from "../../../utilities/general_util";
import dayjs from "dayjs";
import SliderFilter from "./slider_filter";
import TransactionsTable from "./transactions_table";

const TransactionsDrilling = ({
  txModalOpen,
  setTxToDrill,
  setTxModalOpen,
  accounts,
  contacts,
  txToDrill,
}) => {
  const [txBeingExamined, setTxBeingExamined] = useState(txToDrill);
  const [amountRange, setAmountRange] = useState([null, null]);
  const [maxMinFromTx, setMaxMinFromTx] = useState([null, null]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [minMaxDate, setMinMaxDate] = useState([null, null]);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (txToDrill?.length > 0) {
      setTxBeingExamined(txToDrill);
      const amounts = txToDrill
        .filter((tx) => tx.type === "simple")
        .map((tx) => tx.amount);
      const max = Math.max(...amounts);
      const min = Math.min(...amounts);
      setMaxMinFromTx([min, max]);
      setAmountRange([min, max]);

      // Extract the first and last dates
      const dates = txToDrill.map((tx) =>
        dayjs(tx.date.$d || tx.date).valueOf(),
      );
      const firstDate = Math.min(...dates);
      const lastDate = Math.max(...dates);
      setMinMaxDate([firstDate, lastDate]);
      setDateRange([firstDate, lastDate]);
    }
  }, [txToDrill]);

  const calculateScale = useMemo(() => {
    if (!Array.isArray(txToDrill)) return;
    const [min, max] = maxMinFromTx;
    if (min === null || max === null || min === max) return (x) => x;

    const range = max - min;
    const spreadThreshold = 1.5;
    const shouldScaleNonLinearly =
      range /
        Math.max(
          ...txToDrill
            .filter((tx) => tx.type === "simple")
            .map((tx) => tx.amount),
        ) >
      spreadThreshold;

    if (shouldScaleNonLinearly) {
      return (value) => {
        const ratio = (value - min) / range;
        return (
          min +
          (Math.exp(ratio * Math.log(10)) * range) /
            (Math.exp(Math.log(10)) - 1)
        );
      };
    } else {
      return (value) => value;
    }
  }, [maxMinFromTx, txToDrill]);

  const filterTransactions = () => {
    setTxBeingExamined(
      txToDrill.filter(
        (tx) =>
          (tx.type === "complex" || tx.amount >= amountRange[0]) &&
          (tx.type === "complex" || tx.amount <= amountRange[1]) &&
          dayjs(tx.date.$d || tx.date).valueOf() >= dateRange[0] &&
          dayjs(tx.date.$d || tx.date).valueOf() <= dateRange[1],
      ),
    );
  };

  const handleSliderChange = (type, newValue) => {
    if (type === "amount") {
      setAmountRange(newValue);
    } else if (type === "date") {
      setDateRange(newValue);
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(filterTransactions, 500);
  };

  return (
    <Modal
      open={txModalOpen}
      onClose={() => {
        setTxToDrill({ fund: null, account: null });
        setTxModalOpen(false);
      }}>
      <ModalBox>
        <ModalInner>
          <TransactionsTable
            txBeingExamined={txBeingExamined}
            contacts={contacts}
            accounts={accounts}
          />
          <Box sx={{ width: "80%", marginBottom: 2 }}>
            <SliderFilter
              type="amount"
              value={amountRange}
              min={maxMinFromTx[0]}
              max={maxMinFromTx[1]}
              scale={calculateScale}
              valueLabelFormat={(value) => returnCurrency(value)}
              onChange={handleSliderChange}
              marks={[
                {
                  value: maxMinFromTx[0],
                  label: returnCurrency(maxMinFromTx[0]),
                },
                {
                  value: maxMinFromTx[1],
                  label: returnCurrency(maxMinFromTx[1]),
                },
              ]}
              label="Amount Range"
            />
          </Box>
          <Box sx={{ width: "80%" }}>
            <SliderFilter
              type="date"
              value={dateRange}
              min={minMaxDate[0]}
              max={minMaxDate[1]}
              valueLabelFormat={(value) => dayjs(value).format("MM/DD/YYYY")}
              onChange={handleSliderChange}
              marks={[
                {
                  value: minMaxDate[0],
                  label: dayjs(minMaxDate[0]).format("MM/DD/YYYY"),
                },
                {
                  value: minMaxDate[1],
                  label: dayjs(minMaxDate[1]).format("MM/DD/YYYY"),
                },
              ]}
              label="Date Range"
            />
          </Box>
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

export default TransactionsDrilling;
