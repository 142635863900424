import React, { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../../../assets/user_context";

import {
  balanceTransaction,
  checkFundBalance,
} from "./balance_transaction_auto";

import { checkFundsBalance, validateJournalEntry } from "./validation";

import StyledButton from "../../../assets/buttons";
import { FormControl, InputAdornment } from "@mui/material";
import WhiteTextField, {
  PageTitle,
  MasterErrorText,
  ScrollableContainer,
  LineText,
} from "./enter_transaction_styles";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useRecordJE } from "./record_journal_entry";
import { useAuth } from "../../../services/use-auth";
import {
  AccountAutocomplete,
  ContactAutocomplete,
  FundAutocomplete,
} from "./form_components";
import { findFundById } from "../../../utilities/general_util";
import { Check, Close, UploadFileOutlined } from "@mui/icons-material";
import { useWindowSize } from "../../../utilities/responsive_helpers";
import Grid from "@mui/material/Grid";
import { TitleText, TopRowDiv } from "../../component_styles";
import { primary_dark_color } from "../../../constants/color_constants";

const EnterTransaction = (props) => {
  const dateInputRef = useRef(null);
  const { accounts, funds, user, contacts, org, contactMapping } =
    useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  const [fundBalanceStatus, setFundBalanceStatus] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [autoBalance, setAutoBalance] = useState(false);

  const [entryData, setEntryData] = useState(
    props.entryToEdit
      ? props.entryToEdit.lines.map((line) => {
          return {
            account: line.account.toString(),
            credit:
              line.sign === "credit"
                ? formatNumberWithCommas(
                    parseFloat(line.amount / 100).toFixed(2),
                  )
                : "",
            debit:
              line.sign === "debit"
                ? formatNumberWithCommas(
                    parseFloat(line.amount / 100).toFixed(2),
                  )
                : "",
            fund: line.fund.toString(),
            comment: line.comment,
            contact: line.contact,
            entered: true,
            cleared: line.cleared ? true : false,
          };
        })
      : [
          {
            account: null,
            contact: null,
            credit: "",
            debit: "",
            fund: "",
            comment: "",
            entered: false,
          },
          {
            account: null,
            contact: null,
            credit: "",
            debit: "",
            fund: "",
            comment: "",
            entered: false,
          },
        ],
  );

  const [entryErrors, setEntryErrors] = useState([]);

  const [metaDataEntryErrors, setMetaDataEntryErrors] = useState({
    date: false,
    contact: false,
    memo: false,
  });
  const [metaDataEntryMessages, setMetaDataEntryMessages] = useState({
    date: "",
    contact: "",
    memo: "",
  });

  const [journalEntryMetaData, setJournalEntryMetaData] = useState(
    props.entryToEdit
      ? {
          date: dayjs(props.entryToEdit.date),

          memo: props.entryToEdit.memo,
          id: props.entryToEdit?.id ? props.entryToEdit.id : null,
          journalEntry: props.entryToEdit?.journalEntry,
        }
      : {
          date: null,
          memo: "",
          journalEntry: true,
        },
  );
  const [displayContact, setDisplayContact] = useState(
    props.entryToEdit &&
      contacts &&
      contacts.length > 0 &&
      props.entryToEdit.lines.every(
        (line) =>
          line.contact !== null &&
          line.contact !== undefined &&
          line.contact !== "",
      )
      ? props.entryToEdit.lines.map(
          (line) =>
            contacts.find((contact) => contact.id === line.contact).shortName,
        )
      : [null, null],
  );

  const [errorFeedback, setErrorFeedback] = useState("");

  const [lines, setLines] = useState(
    props.entryToEdit
      ? props.entryToEdit.lines.map((value, index) => index)
      : [0, 1],
  );

  const [fundsBalanceStatus, setFundsBalanceStatus] = useState([]);

  const [disabledFundLines, setDisabledFundLines] = useState([]);
  const [touched, setTouched] = useState(
    props.entryToEdit && Array.isArray(props.entryToEdit.lines)
      ? Array(props.entryToEdit.lines.length)
          .fill()
          .map(() => ({ account: true, fund: true }))
      : [
          { account: false, fund: false },
          { account: false, fund: false },
        ],
  );

  useEffect(() => {
    if (
      props.journalEntryData &&
      Object.keys(props.journalEntryData).length > 0
    ) {
      setJournalEntryMetaData({
        date: null,
        memo: "",
        journalEntry: true,
      });
      setEntryData(
        props.journalEntryData.lines.map((line) => {
          return {
            account: line.account.toString(),
            credit:
              line.sign === "credit"
                ? formatNumberWithCommas(
                    parseFloat(line.amount / 100).toFixed(2),
                  )
                : "",
            debit:
              line.sign === "debit"
                ? formatNumberWithCommas(
                    parseFloat(line.amount / 100).toFixed(2),
                  )
                : "",
            fund: line.fund.toString(),
            comment: line.comment,
            contact: line.contact,
            entered: true,
            cleared: false,
          };
        }),
      );
      setTouched(
        Array(props.journalEntryData.lines.length)
          .fill()
          .map(() => ({ account: true, fund: true })),
      );
      setDisplayContact(
        props.journalEntryData.lines.map(
          (line) =>
            contacts.find((contact) => contact.id === line.contact).shortName,
        ),
      );
      setLines(props.journalEntryData.lines.map((value, index) => index));
    }
  }, [props.journalEntryData]);

  useEffect(() => {
    if (dateInputRef.current && !props.entryToEdit) {
      // console.log("dateInputRef.current is not null");
      dateInputRef.current.focus();
    }
  }, [dateInputRef.current]);

  const updateTouched = (line, field) => {
    const newTouched = [...touched];
    newTouched[line][field] = true;
    setTouched(newTouched);
  };

  //This silly key is concat to the end of the Autocomplete components to force a re-render when the accounts or funds change
  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");

  //useEffect to enable button if any entryData or journalEntryMetaData is changed
  useEffect(() => {
    if (buttonDisabled) {
      setButtonDisabled(false);
    }
  }, [entryData, journalEntryMetaData]);

  //useEffect to log journalEntryMetaData
  useEffect(() => {
    console.log("Journal Entry Meta Data: ", journalEntryMetaData);
  }, [journalEntryMetaData]);

  // //useEffect to log the entryErrors out if they ever change
  // useEffect(() => {
  //   console.log("Entry Errors: ", entryErrors);
  //   console.log("Entry Error Feedback: ", errorFeedback);
  // }, [entryErrors, errorFeedback]);

  //Function to handle the change of the date input
  const handleDateChange = (event) => {
    const value = event.$d;
    const newJournalEntryMetaData = { ...journalEntryMetaData };
    newJournalEntryMetaData.date = value;
    setJournalEntryMetaData(newJournalEntryMetaData);
  };

  const findAccountById = (id) => {
    return accounts.find((account) => account.id === id);
  };

  // Function to format numbers with commas for display
  function formatNumberWithCommas(value) {
    // Ensure that we're formatting only if there's a value
    if (!value) return "";
    let parts = value.split(".");
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Reassemble the parts to include the decimal, if present
    return parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;
  }

  //Function to handle the change of the amount input
  const handleChangeAmount = (event) => {
    const value = event.target.value.replace(/,/g, "");
    const name = event.target.name;
    //Get the entryData line number from the input name, and add value to that array element, then update entryData below if it is a number

    // Check if inputValue is a number or a decimal
    const isNumberOrDecimal = /^(\d+\.?\d{0,2}|\.\d{1,2})$/.test(value);

    // Only update value if inputValue is empty, a number, or a decimal
    if (value === "" || isNumberOrDecimal) {
      const line = name.split("-")[1];
      const sign = name.split("-")[0];
      const newEntryData = [...entryData];
      const formattedValue = formatNumberWithCommas(value);
      newEntryData[line][sign] = formattedValue;
      newEntryData[line][sign === "credit" ? "debit" : "credit"] = "";
      newEntryData[line].entered = true;
      setEntryData(newEntryData);
    }
  };

  //Function to handle the change of the memo input
  const handleChangeText = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const newJournalEntryMetaData = { ...journalEntryMetaData };
    newJournalEntryMetaData[name] = value;
    setJournalEntryMetaData(newJournalEntryMetaData);
  };

  //Function to handle the change of the comment in a line
  const handleChangeComment = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const line = name.split("-")[1];
    const newEntryData = [...entryData];
    newEntryData[line].comment = value;
    setEntryData(newEntryData);
  };

  //Function to format the amount to 2 decimal places when the input is blurred
  const formatAmount = (line, sign) => {
    const newEntryData = [...entryData];
    const amount = newEntryData[line][sign];
    console.log("Amount: ", amount);
    if (amount !== undefined && amount !== "") {
      const numericAmount = parseFloat(amount.replace(/,/g, "")).toFixed(2);
      console.log("Numeric Amount: ", numericAmount);
      const formattedAmount = formatNumberWithCommas(numericAmount);
      newEntryData[line][sign] = formattedAmount;
      setEntryData(newEntryData);
    }
    if (amount === "") {
      newEntryData[line].amount = "0";
      setEntryData(newEntryData);
    }
  };

  //useEffect to check if every line has either a credit or debit that is not an empty string, and trigger addLine if this is true
  useEffect(() => {
    // Function to add a new line to the transaction
    const addLine = () => {
      const newLines = [...lines];
      const newEntryData = [...entryData];
      const newDisplayContact = [...displayContact];
      newLines.push(lines.length);
      newEntryData.push({
        account: null,
        contact: null,
        credit: "",
        debit: "",
        comment: "",
        fund: "",
        entered: false,
      });
      newDisplayContact.push(null);
      setEntryErrors([]);
      setLines(newLines);
      setDisplayContact(newDisplayContact);
      setEntryData(newEntryData);
      setTouched((prevState) => [
        ...prevState,
        { account: false, fund: false },
      ]);
    };
    const allEntered = entryData.every(
      (line) => line.credit !== "" || line.debit !== "",
    );
    if (allEntered) {
      addLine();
    }
  }, [entryData]);

  const validateEntry = (entryKey, errorMessage) => {
    const newMetaDataEntryErrors = { ...metaDataEntryErrors };
    const newMetaDataEntryMessages = { ...metaDataEntryMessages };
    if (journalEntryMetaData[entryKey] === "") {
      newMetaDataEntryErrors[entryKey] = true;
      newMetaDataEntryMessages[entryKey] = errorMessage;
    } else {
      newMetaDataEntryErrors[entryKey] = false;
      newMetaDataEntryMessages[entryKey] = "";
    }
    setMetaDataEntryErrors(newMetaDataEntryErrors);
    setMetaDataEntryMessages(newMetaDataEntryMessages);
  };

  //Check if all the funds are balanced with credits vs debits and update the fundBalanceStatus
  useEffect(() => {
    console.log(entryData);
    if (!fundBalanceStatus) {
      checkFundsBalance({
        entryDataIn: entryData,
        setFundBalanceStatus,
        setEntryErrors,
        allFunds: funds,
      });
    }
  }, [entryData]);

  //useEffect to track fund balance status for display
  useEffect(() => {
    let fundsInTx = [];
    //Build a list of funds in this journal entry
    entryData.forEach((line) => {
      if (
        line.fund !== "" &&
        line.fund !== null &&
        !fundsInTx.includes(line.fund) &&
        (line.credit !== "" || line.debit !== "")
      ) {
        fundsInTx.push(line.fund);
      }
    });
    //Build a total of debits and credits for each fund
    const fundsStatus = [];
    fundsInTx.forEach((fund) => {
      let totalDebit = 0;
      let totalCredit = 0;
      entryData.forEach((line) => {
        if (line.fund === fund) {
          totalDebit +=
            line.debit !== "" ? parseFloat(line.debit.replace(/,/g, "")) : 0;
          totalCredit +=
            line.credit !== "" ? parseFloat(line.credit.replace(/,/g, "")) : 0;
        }
      });
      fundsStatus.push({
        totalDebit: `$ ${formatNumberWithCommas(totalDebit.toFixed(2))}`,
        totalCredit: `$ ${formatNumberWithCommas(totalCredit.toFixed(2))}`,
        difference: `$ ${formatNumberWithCommas(
          Math.abs(totalDebit - totalCredit).toFixed(2),
        )}`,
        fund: findFundById({ fundId: fund, funds }).fundNumber,
      });
    });
    console.log("Funds Status: ", fundsStatus);
    setFundsBalanceStatus(fundsStatus);
  }, [entryData]);

  // Function to record the journal entry in the database
  const recordJE = useRecordJE();
  const recordJournalEntry = async () => {
    console.log(
      "Starting submit process for JE: ",
      entryData,
      validateJournalEntry({
        journalEntryMetaData,
        setMetaDataEntryErrors,
        setMetaDataEntryMessages,
        entryDataIn: entryData,
        entryErrors,
        setEntryErrors,
        setErrorFeedback,
        setFundBalanceStatus,
        funds,
      }),
    );
    if (
      validateJournalEntry({
        journalEntryMetaData,
        setMetaDataEntryErrors,
        setMetaDataEntryMessages,
        entryDataIn: entryData,
        entryErrors,
        setEntryErrors,
        setErrorFeedback,
        setFundBalanceStatus,
        funds,
      })
    ) {
      console.log("Recording, no errors");
      const formatedEntryData = entryData.map((line) => {
        return {
          account: line.account,
          credit:
            line.credit !== "" && line.credit !== undefined
              ? Math.round(parseFloat(line.credit.replace(/,/g, "")) * 100)
              : "",
          debit:
            line.debit !== "" && line.debit !== undefined
              ? Math.round(parseFloat(line.debit.replace(/,/g, "")) * 100)
              : "",
          fund: line.fund,
          comment: line.comment || "",
          contact: line.contact,
          cleared: line.cleared ? true : false,
        };
      });
      console.log("Formated Entry Data: ", formatedEntryData);
      recordJE({
        metaData: journalEntryMetaData,
        entryData: formatedEntryData,
        contacts,
        db,
        org,
        entryToEdit: props.entryToEdit,
        userId: user.uid,
      });
      setJournalEntryMetaData({
        date: Dayjs || null,
        contact: null,
        memo: "",
      });
      setLines([0, 1]);
      setEntryData([
        {
          account: "",
          debit: "",
          credit: "",
          comment: "",
          fund: "",
          entered: false,
        },
        {
          account: "",
          debit: "",
          credit: "",
          comment: "",
          fund: "",
          entered: false,
        },
      ]);
      setEntryErrors([]);
      setErrorFeedback("");
      setDisabledFundLines([]);
      setDisplayContact([null, null]);
      setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
      setTimeout(() => {
        if (dateInputRef.current) {
          dateInputRef.current.focus();
        }
      }, 200);
      setButtonDisabled(false);
      return true;
    } else {
      console.log("Not recording, foundErrors");
      checkFundsBalance({
        entryDataIn: entryData,
        setFundBalanceStatus,
        setEntryErrors,
        allFunds: funds,
      });
      setButtonDisabled(false);
      return false;
    }
  };

  //Error style for date picker since prop is not working
  const errorStyleForDate = entryErrors[0]?.date
    ? {
        color: "red",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "red",
          },
        },
        "& .MuiInputLabel-root": {
          // Custom style for the label
          color: "red",
        },
      }
    : {};

  // //useEffect to log entryErrors when it changes
  // useEffect(() => {
  //   console.log("Entry Errors: ", entryErrors);
  // }, [entryErrors]);

  //useEffect to log entryData when it changes
  useEffect(() => {
    console.log("Entry Data: ", entryData);
  }, [entryData]);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      recordJournalEntry();
    }
  };

  const { height } = useWindowSize();
  const size = height > 800 && entryData?.length < 6 ? "medium" : "small";

  return (
    <div onKeyDown={handleEnterPress}>
      <TopRowDiv
        style={{
          justifyContent: "space-around",
          marginTop: 0,
          marginBottom: "0.5rem",
        }}>
        <TitleText
          style={{
            margin: 0,
            padding: 0,
            fontSize: "1.5rem",
            width: "70%",
          }}>
          {props.entryToEdit ? "Edit Entry" : "Journal Entry"}
        </TitleText>
        {fundsBalanceStatus?.length > 0 ? (
          <div
            style={{
              // border: "solid black 1px",
              borderRadius: "5px",
              background: "white",
              padding: "0.5rem",
              width: "100%",
            }}>
            <table>
              <thead>
                <tr>
                  <th style={{ paddingRight: "8px", whiteSpace: "nowrap" }}>
                    Fund
                  </th>
                  <th style={{ paddingRight: "8px", whiteSpace: "nowrap" }}>
                    Total Debit
                  </th>
                  <th style={{ paddingLeft: "10px", whiteSpace: "nowrap" }}>
                    Total Credit
                  </th>
                  <th style={{ paddingLeft: "8px", whiteSpace: "nowrap" }}>
                    Difference
                  </th>
                </tr>
              </thead>
              {fundsBalanceStatus?.length > 0
                ? fundsBalanceStatus.map((fund) => (
                    <tr>
                      <td style={{ whiteSpace: "nowrap" }}>{fund.fund}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {fund.totalDebit}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {fund.totalCredit}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}>
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                            }}>
                            {fund.difference}
                          </p>
                          {fund.difference === "$ 0.00" ? (
                            <Check style={{ color: "green" }} />
                          ) : (
                            <Close style={{ color: "red" }} />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </table>
          </div>
        ) : !props.entryToEdit ? (
          <div
            style={{
              border: `2px solid ${primary_dark_color}`,
              padding: "3px",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              height: "fit-content",
            }}
            onClick={() => props.setOpenUploadModal(true)}>
            <UploadFileOutlined
              style={{ color: primary_dark_color }}
              fontSize="medium"
            />
            <LineText style={{ fontSize: "1rem", margin: "0.25rem" }}>
              Import Transactions
            </LineText>
          </div>
        ) : null}
      </TopRowDiv>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: "white" }}
            variant="filled">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={<span>Transaction Date *</span>}
                sx={errorStyleForDate}
                value={journalEntryMetaData.date}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <WhiteTextField
                    {...params}
                    style={{ backgroundColor: "white" }}
                    error={true}
                    variant="outlined"
                    color="secondary"
                  />
                )}
                slotProps={{
                  textField: {
                    inputRef: dateInputRef,
                    size: size,
                    disabled: entryData.some((line) => line.cleared),
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="filled">
            <WhiteTextField
              id="memo"
              name="memo"
              label="Memo"
              variant="outlined"
              value={journalEntryMetaData.memo}
              onChange={handleChangeText}
              color="secondary"
              required
              onBlur={() => {
                validateEntry("memo", "Memo is Required");
              }}
              error={metaDataEntryErrors.memo}
              helperText={metaDataEntryMessages.memo}
              size={size}
            />
          </FormControl>
        </Grid>
        {/* <CenteringWrapper>
        <AutoBalanceWrapper
          width="350px"
          onClick={() => {
            setAutoBalance(!autoBalance);
          }}>
          {autoBalance ? (
            <React.Fragment>
              <LineText>Auto Balance Enabled</LineText>
              <LockOutlined style={{ color: primary_dark_color }} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <LineText>Auto Balance Disabled</LineText>
              <LockOpenIcon style={{ color: primary_dark_color }} />
            </React.Fragment>
          )}
        </AutoBalanceWrapper>
      </CenteringWrapper> */}
        {funds.length > 0 && accounts.length > 0 && props.entryToEdit ? (
          //Container for editing a transaction
          // <ScrollableContainer>
          <React.Fragment>
            {lines.map((line) => (
              <React.Fragment key={`lineWrapperCreditDebit-${line}`}>
                <Grid item xs={2} md={2.25} xl={2.5}>
                  <FormControl
                    fullWidth
                    key={`formAccount-${line}`}
                    variant="outlined">
                    <AccountAutocomplete
                      line={line}
                      accounts={accounts}
                      funds={funds}
                      entryData={entryData}
                      setEntryData={setEntryData}
                      setDisabledFundLines={setDisabledFundLines}
                      entryErrors={entryErrors}
                      findAccountById={findAccountById}
                      uniqueKey={someUniqueKeyThatForcesReRender}
                      setTouched={(field) => updateTouched(line, field)}
                      size={size}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2.25} xl={2.5}>
                  <FormControl
                    fullWidth
                    key={`formFund-${line}`}
                    variant="filled">
                    <FundAutocomplete
                      line={line}
                      funds={funds}
                      entryData={entryData}
                      setEntryData={setEntryData}
                      disabledFundLines={disabledFundLines}
                      entryErrors={entryErrors}
                      uniqueKey={someUniqueKeyThatForcesReRender}
                      setTouched={(field) => updateTouched(line, field)}
                      size={size}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2} xl={2.5}>
                  <FormControl fullWidth variant="filled">
                    <ContactAutocomplete
                      line={line}
                      contacts={contacts}
                      entryData={entryData}
                      setEntryData={setEntryData}
                      displayContact={displayContact}
                      setDisplayContact={setDisplayContact}
                      entryErrors={entryErrors}
                      validateEntry={validateEntry}
                      uniqueKey={someUniqueKeyThatForcesReRender}
                      contactMapping={contactMapping}
                      touched={touched}
                      size={size}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} md={1.5} xl={2}>
                  <FormControl fullWidth variant="filled">
                    <WhiteTextField
                      key={`formComment-${line}`}
                      id="comment"
                      name={`comment-${line}`}
                      label="comment"
                      variant="outlined"
                      value={entryData[line].comment}
                      onChange={handleChangeComment}
                      color="secondary"
                      size={size}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.5} md={2} xl={1.25}>
                  <FormControl
                    key={`formAmount-${line}-debit`}
                    fullWidth
                    variant="filled">
                    <WhiteTextField
                      key={`input-${line}-debit`}
                      id="filled-adornment-amount"
                      disabled={entryData[line].cleared}
                      name={`debit-${line}`}
                      label="Debit"
                      color="secondary"
                      value={entryData[line].debit}
                      onChange={handleChangeAmount}
                      required={entryData[line].credit === ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      onBlur={() => {
                        formatAmount(line);
                        if (autoBalance) {
                          balanceTransaction(line, setEntryData, entryErrors);
                        }
                        checkFundBalance(entryData);
                      }}
                      onFocus={() => {
                        if (entryData[line].amount === "0") {
                          const newEntryData = [...entryData];
                          newEntryData[line].amount = "";
                          setEntryData(newEntryData);
                        }
                      }}
                      error={
                        entryErrors.length > 0 &&
                        ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                          !entryErrors[line]?.errorFeedback?.length)
                          ? !(!entryErrors[line] || !entryErrors[line].error)
                          : false
                      }
                      helperText={
                        entryErrors.length > 0 &&
                        ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                          !entryErrors[line]?.errorFeedback?.length)
                          ? entryErrors[line]?.error &&
                            entryData[line].debit !== ""
                            ? entryErrors[line]?.errorFeedback ||
                              "An amount is required"
                            : null
                          : null
                      }
                      size={size}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.5} md={2} xl={1.25}>
                  <FormControl
                    key={`formAmount-${line}-credit`}
                    fullWidth
                    variant="filled">
                    <WhiteTextField
                      key={`input-${line}-credit`}
                      id="filled-adornment-amount"
                      disabled={entryData[line].cleared}
                      name={`credit-${line}`}
                      label="Credit"
                      color="secondary"
                      value={entryData[line].credit}
                      onChange={handleChangeAmount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      required={entryData[line].debit === ""}
                      onBlur={() => {
                        formatAmount(line);
                        if (autoBalance) {
                          balanceTransaction(line, setEntryData, entryErrors);
                        }
                        checkFundBalance(entryData);
                      }}
                      onFocus={() => {
                        if (entryData[line].amount === "0") {
                          const newEntryData = [...entryData];
                          newEntryData[line].amount = "";
                          setEntryData(newEntryData);
                        }
                      }}
                      error={
                        entryErrors.length > 0 &&
                        ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                          !entryErrors[line]?.errorFeedback?.length)
                          ? !(!entryErrors[line] || !entryErrors[line].error)
                          : false
                      }
                      helperText={
                        entryErrors.length > 0 &&
                        ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                          !entryErrors[line]?.errorFeedback?.length)
                          ? entryErrors[line]?.error &&
                            entryData[line].credit !== ""
                            ? entryErrors[line]?.errorFeedback ||
                              "An amount is required"
                            : null
                          : null
                      }
                      size={size}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
            ))}
            {/* </ScrollableContainer> */}
          </React.Fragment>
        ) : funds.length > 0 && accounts.length > 0 && !props.entryToEdit ? (
          lines.map((line) => (
            <React.Fragment key={`lineWrapperCreditDebit-${line}`}>
              <Grid item xs={2} md={2.25} xl={2.5}>
                <FormControl
                  fullWidth
                  key={`formAccount-${line}`}
                  variant="outlined">
                  <AccountAutocomplete
                    line={line}
                    accounts={accounts}
                    funds={funds}
                    entryData={entryData}
                    setEntryData={setEntryData}
                    setDisabledFundLines={setDisabledFundLines}
                    entryErrors={entryErrors}
                    findAccountById={findAccountById}
                    uniqueKey={someUniqueKeyThatForcesReRender}
                    setTouched={(field) => updateTouched(line, field)}
                    size={size}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2.25} xl={2.5}>
                <FormControl
                  key={`formFund-${line}`}
                  fullWidth
                  variant="filled">
                  <FundAutocomplete
                    line={line}
                    funds={funds}
                    entryData={entryData}
                    setEntryData={setEntryData}
                    disabledFundLines={disabledFundLines}
                    entryErrors={entryErrors}
                    uniqueKey={someUniqueKeyThatForcesReRender}
                    setTouched={(field) => updateTouched(line, field)}
                    size={size}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2} xl={2.5}>
                <FormControl fullWidth variant="filled">
                  <ContactAutocomplete
                    line={line}
                    contacts={contacts}
                    entryData={entryData}
                    setEntryData={setEntryData}
                    displayContact={displayContact}
                    setDisplayContact={setDisplayContact}
                    entryErrors={entryErrors}
                    validateEntry={validateEntry}
                    uniqueKey={someUniqueKeyThatForcesReRender}
                    contactMapping={contactMapping}
                    touched={touched}
                    size={size}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} md={1.5} xl={2}>
                <FormControl fullWidth variant="filled">
                  <WhiteTextField
                    key={`formComment-${line}`}
                    id="comment"
                    name={`comment-${line}`}
                    label="comment"
                    variant="outlined"
                    value={entryData[line].comment}
                    onChange={handleChangeComment}
                    color="secondary"
                    size={size}
                  />
                </FormControl>
              </Grid>
              {/* FIXME: When editing a tx, the amount is loading in cents as dollars so it's adding two zeros every time */}
              <Grid item xs={2.5} md={2} xl={1.25}>
                <FormControl
                  key={`formAmount-${line}-debit`}
                  fullWidth
                  variant="filled">
                  <WhiteTextField
                    key={`input-${line}-debit`}
                    id="filled-adornment-amount"
                    disabled={entryData[line].cleared}
                    name={`debit-${line}`}
                    label="Debit"
                    color="secondary"
                    value={entryData[line].debit}
                    onChange={handleChangeAmount}
                    required={entryData[line].credit === ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onBlur={() => {
                      formatAmount(line, "debit");
                      if (autoBalance) {
                        balanceTransaction(line, setEntryData, entryErrors);
                      }
                      checkFundBalance(entryData);
                    }}
                    onFocus={() => {
                      if (entryData[line].amount === "0") {
                        const newEntryData = [...entryData];
                        newEntryData[line].amount = "";
                        setEntryData(newEntryData);
                      }
                    }}
                    error={
                      entryErrors.length > 0 &&
                      ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                        !entryErrors[line]?.errorFeedback?.length) &&
                      entryData[line].debit !== ""
                        ? !(!entryErrors[line] || !entryErrors[line].error)
                        : false
                    }
                    helperText={
                      entryErrors.length > 0 &&
                      ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                        !entryErrors[line]?.errorFeedback?.length)
                        ? entryErrors[line]?.error &&
                          entryData[line].debit !== ""
                          ? entryErrors[line]?.errorFeedback ||
                            "An amount is required"
                          : null
                        : null
                    }
                    size={size}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5} md={2} xl={1.25}>
                <FormControl
                  key={`formAmount-${line}-credit`}
                  fullWidth
                  variant="filled">
                  <WhiteTextField
                    key={`input-${line}-credit`}
                    id="filled-adornment-amount"
                    disabled={entryData[line].cleared}
                    name={`credit-${line}`}
                    label="Credit"
                    color="secondary"
                    value={entryData[line].credit}
                    onChange={handleChangeAmount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    required={entryData[line].debit === ""}
                    onBlur={() => {
                      formatAmount(line, "credit");
                      if (autoBalance) {
                        balanceTransaction(line, setEntryData, entryErrors);
                      }
                      checkFundBalance(entryData);
                    }}
                    onFocus={() => {
                      if (entryData[line].amount === "0") {
                        const newEntryData = [...entryData];
                        newEntryData[line].amount = "";
                        setEntryData(newEntryData);
                      }
                    }}
                    error={
                      entryErrors.length > 0 &&
                      ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                        !entryErrors[line]?.errorFeedback?.length) &&
                      entryData[line].credit !== ""
                        ? !(!entryErrors[line] || !entryErrors[line].error)
                        : false
                    }
                    helperText={
                      entryErrors.length > 0 &&
                      ((!entryErrors[line]?.fund && !fundBalanceStatus) ||
                        !entryErrors[line]?.errorFeedback?.length)
                        ? entryErrors[line]?.error &&
                          entryData[line].credit !== ""
                          ? entryErrors[line]?.errorFeedback ||
                            "An amount is required"
                          : null
                        : null
                    }
                    size={size}
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          ))
        ) : null}
      </Grid>
      {/* <div style={{ height: "1rem" }} /> */}
      {errorFeedback === "" || errorFeedback === null ? null : (
        <MasterErrorText>{errorFeedback}</MasterErrorText>
      )}
      {props.entryToEdit ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "2rem",
          }}>
          <StyledButton
            primary
            adaptiveSize
            disabled={buttonDisabled}
            onClick={async () => {
              try {
                setButtonDisabled(true);
                const success = await recordJournalEntry(); // Wait for the promise to resolve
                console.log("Success: ", success);
                if (success) {
                  console.log(success);
                  props.closeModal();
                }
              } catch (error) {
                console.error("Failed to record journal entry:", error);
              } finally {
                setButtonDisabled(false);
              }
            }}>
            Register Changes
          </StyledButton>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            margin: "0.5rem",
          }}>
          <StyledButton
            disabled={buttonDisabled}
            primary
            fontSize={height > 800 ? "1.3rem" : "1rem"}
            onClick={() => {
              setButtonDisabled(true);
              recordJournalEntry();
            }}>
            Record Transaction
          </StyledButton>
        </div>
      )}
    </div>
  );
};

export default EnterTransaction;
