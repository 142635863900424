import { useEffect } from "react";

//REMOVE WHEN POSSIBLE - After rewriting the Income Statement and Balance Sheet components, this file should be removed.
export const countFundsDisplayed = (data) => {
  let count = 0;

  const traverse = (node) => {
    // If show is false, count as 1 and return without exploring further.
    if (node.show === false) {
      count += 1;
      return;
    }
    // If show is true, count it and explore further.

    if (node.funds && node.funds.length > 0) {
      node.funds.forEach((fund) => {
        // If a fund's show is false, it still counts as 1, so we check before traversing.
        if (fund.show === false) {
          count += 1;
        } else {
          traverse(fund);
        }
      });
    } else {
      count += 1;
    }
    if (node.subFunds && node.subFunds.length > 0) {
      node.subFunds.forEach((subFund) => {
        // If a subFund's show is false, it still counts as 1, so we check before traversing.
        if (subFund.show === true) {
          count += 1;
        }
      });
    }
  };

  data.forEach(traverse);
  return count;
};

// Custom hook to count funds displayed and track the number
export const useTrackFundsDisplayedCount = ({
  fundsHierarchy,
  fundsDetailsToDisplay,
  setNumberOfFundsShown,
}) => {
  useEffect(() => {
    // Function to count the displayed funds, checking subFunds when necessary
    const countFundsDisplayed = () => {
      let count = 0;

      fundsDetailsToDisplay.forEach((group, groupIndex) => {
        // Iterate through each fund in the group
        group.funds.forEach((fund, fundIndex) => {
          // If the fund is not shown, count it and skip further processing
          if (fund.show === false) {
            count += 1;
          } else {
            // If the fund is shown, count it and check for subFunds in the fundsHierarchy
            count += 1;

            // Access the corresponding fund in the fundsHierarchy to count its subFunds
            const originalFund =
              fundsHierarchy.groups[groupIndex].funds[fundIndex];

            if (originalFund.subFunds && originalFund.subFunds.length > 0) {
              // Count subFunds only if the parent fund is shown
              count += originalFund.subFunds.length;
            }
          }
        });
      });

      return count;
    };

    // Calculate and update the number of funds displayed
    const numberOfFundsHorizontallyDisplayed = countFundsDisplayed();
    console.log(
      "Number of Funds Displayed: ",
      numberOfFundsHorizontallyDisplayed,
    );

    setNumberOfFundsShown(numberOfFundsHorizontallyDisplayed);
  }, [fundsHierarchy, fundsDetailsToDisplay, setNumberOfFundsShown]);
};
