import React, { useContext } from "react";
import { SubtitleText } from "../component_styles.jsx";
import { setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { Autocomplete, TextField, Grid } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import StyledButton from "../../assets/buttons.js";
import UserContext from "../../assets/user_context.jsx";
import { generateRandomId } from "../../utilities/general_util.jsx";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ContactFields = ({ control, errors }) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="First Name"
              fullWidth
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Last Name"
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Company Name"
              fullWidth
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Phone Number"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email Address"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="shortName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nickname"
              required
              fullWidth
              error={!!errors.shortName}
              helperText={errors.shortName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="streetAddress"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Street Address"
              fullWidth
              error={!!errors.streetAddress}
              helperText={errors.streetAddress?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="City"
              fullWidth
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={states}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  error={!!errors.state}
                  helperText={errors.state?.message}
                />
              )}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="zip"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Zip Code"
              fullWidth
              error={!!errors.zip}
              helperText={errors.zip?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Country"
              fullWidth
              error={!!errors.country}
              helperText={errors.country?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const AddDonor = ({ db, org, donorToEdit, handleClose }) => {
  const { experimental, setContacts, donors } = useContext(UserContext);

  const validationSchema = Yup.object({
    shortName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("You must include a nickname"),
    email: Yup.string()
      .email("Invalid email address")
      .max(35, "Must be 35 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    companyName: Yup.string()
      .min(3, "I'm sure that's too short...")
      .max(45, "Must be 45 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    firstName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    lastName: Yup.string()
      .max(30, "Must be 30 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    phone: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .notRequired()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    streetAddress: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .notRequired(),
    city: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    state: Yup.string()
      .max(2, "Must be 2 letters")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
    zip: Yup.string()
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .notRequired()
      .matches(/^\d{5}$/, "Zip code must be exactly 5 digits"),
    country: Yup.string()
      .max(25, "Must be 25 characters or less")
      .nullable()
      .transform((value) => (value === "" ? null : value)),
  }).test(
    "emailOrCompleteAddress",
    "You must provide either an email or a complete address (street, city, state, zip)",
    function (values) {
      const { email, streetAddress, city, state, zip } = values;
      const isCompleteAddress = streetAddress && city && state && zip;
      if (!email && !isCompleteAddress) {
        return this.createError({
          path: "email",
          message:
            "You must provide either an email or a complete address (street, city, state, zip)",
        });
      }
      return true;
    },
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
      donorToEdit && Object.keys(donorToEdit)?.length > 0
        ? {
            shortName: donorToEdit?.shortName || "",
            firstName: donorToEdit?.firstName || "",
            lastName: donorToEdit?.lastName || "",
            phone: donorToEdit?.phone || "",
            email: donorToEdit?.email || "",
            streetAddress: donorToEdit?.streetAddress || "",
            city: donorToEdit?.city || "",
            state: donorToEdit?.state || "",
            zip: donorToEdit?.zip || "",
            country: donorToEdit?.country || "",
          }
        : {
            shortName: "",
            companyName: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
            country: "",
          },
  });

  const onSubmit = async (values) => {
    if (Object.keys(donorToEdit)?.length > 0) {
      console.log("Editing contact: ", values);
      if (!experimental) {
        const donorsRef = doc(db, "orgs", org, "donorManagement", "donors");
        try {
          const docSnapshot = await getDoc(donorsRef);
          if (docSnapshot.exists()) {
            const currentDonors = docSnapshot.data().donors || [];
            const donorIndex = currentDonors.findIndex((donor) =>
              donor.id.some((donorIdItem) => donorIdItem === donorToEdit.id[0]),
            );
            currentDonors[donorIndex] = { ...values, id: donorToEdit.id };
            await updateDoc(donorsRef, {
              donors: currentDonors,
            });
            console.log("Donor edited successfully");
          } else {
            console.error(
              "Document does not exist, but should since we are editing",
            );
          }
        } catch (error) {
          console.error("Error editing contact: ", error);
        }
      } else {
        setContacts((prevState) =>
          prevState.map((contact) =>
            contact.id === donorToEdit.id ? values : contact,
          ),
        );
      }
    } else {
      console.log("Adding NEW donor: ", values);
      if (!experimental) {
        const donorsRef = doc(db, "orgs", org, "donorManagement", "donors");
        let donorId = generateRandomId();
        let isIdUnique = false;

        while (!isIdUnique && donors?.length > 0) {
          isIdUnique = true;
          for (let donor of donors) {
            if (donor.id.includes(donorId)) {
              isIdUnique = false;
              donorId = generateRandomId();
              break;
            }
          }
        }
        try {
          const docSnapshot = await getDoc(donorsRef);
          if (docSnapshot.exists()) {
            const currentDonors = docSnapshot.data().donors || [];
            await updateDoc(donorsRef, {
              donors: [...currentDonors, { ...values, id: [donorId] }],
            });
            console.log("Donor added successfully");
          } else {
            await setDoc(donorsRef, {
              donors: [{ ...values, id: [donorId] }],
            });
          }
        } catch (error) {
          console.error("Error adding donor: ", error);
        }
      } else {
        let contactId = generateRandomId();
        const newContact = { id: contactId, ...values };
        setContacts((prevState) => [...prevState, newContact]);
      }
    }
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <SubtitleText>
          {donorToEdit && Object.keys(donorToEdit)?.length > 0
            ? "Edit Donor"
            : "Add Donor"}
        </SubtitleText>
        <ContactFields control={control} errors={errors} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}>
          <StyledButton type="submit" primary width="250px">
            {donorToEdit && Object.keys(donorToEdit)?.length > 0
              ? "Edit Donor"
              : "Add Donor"}
          </StyledButton>
        </div>
      </div>
    </form>
  );
};

export default AddDonor;
