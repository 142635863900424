import {
  ComponentPageWrapper,
  NavBack,
  UnderConstruction,
} from "../component_styles.jsx";

const FundSummary = () => {
  return (
    <ComponentPageWrapper>
      <NavBack>REPORTS</NavBack>
      <UnderConstruction>Fund Summary</UnderConstruction>
    </ComponentPageWrapper>
  );
};

export default FundSummary;
