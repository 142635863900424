import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { returnCurrency } from "../../../utilities/general_util";
import { primary_dark_color } from "../../../constants/color_constants";

const AmountLabelText = styled.p`
  text-align: center;
  font-family: "MontserratLight";
  font-size: 1.5rem;
  margin-top: 0;
`;

const AmountText = styled.p`
  text-align: center;
  font-family: "MontserratBold";
  char-spacing: 0.2rem;
  font-size: 1.7rem;
  margin-bottom: 0;
  color: ${primary_dark_color};
  color: ${(props) => (props.error ? "#ff0000" : null)};
  color: ${(props) => (props.success ? "#25b525" : null)};
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: ${(props) => (props.balance ? "#edebeb" : null)};
  border-radius: 3rem;
`;

const MathSymbolText = styled.p`
  text-align: center;
  font-family: "MontserratLight";
  font-size: 3.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.balance ? "#edebeb" : null)};
  border-radius: 3rem;
  margin-bottom: 1.5rem;
  padding: 0;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReconciliationStatus = ({
  clearedTx,
  account,
  openingBalance,
  closingBalance,
  setRecBalanced,
  filteredTx,
  setInitialOptionsModal,
}) => {
  const [clearedBalances, setClearedBalances] = useState({
    credits: null,
    debits: null,
    balance: null,
  });

  useEffect(() => {
    let totalCredits = 0;
    let totalDebits = 0;
    let creditLines = 0;
    let debitLines = 0;

    filteredTx
      .filter((tx) => clearedTx.includes(tx.id))
      .forEach((tx) => {
        tx.lines.forEach((line) => {
          // Changed from reduce to forEach
          // console.log(`Adding ${line.sign} of amount ${line.amount}`);

          if (line.sign === "credit") {
            totalCredits += parseFloat(line.amount);
            creditLines += 1;
          } else if (line.sign === "debit") {
            totalDebits += parseFloat(line.amount);
            debitLines += 1;
          }
          // console.log(`Total Credits: ${totalCredits}`);
        });
      });

    let balance = 0;
    //For Liabilities
    if (account?.accountType === "Liabilities") {
      balance =
        parseFloat(openingBalance.amount) + (totalCredits - totalDebits);
    }
    //For Assets
    if (account?.accountType === "Assets") {
      balance =
        parseFloat(openingBalance.amount) + (totalDebits - totalCredits);
    }
    const difference = parseFloat(
      parseFloat(closingBalance - balance).toFixed(2),
    );

    console.log(difference);

    setClearedBalances({
      credits: totalCredits,
      creditLines: creditLines,
      debits: totalDebits,
      debitLines: debitLines,
      balance,
      difference,
    });
  }, [filteredTx, clearedTx]);

  useEffect(() => {
    if (clearedBalances?.difference === 0) {
      setRecBalanced(true);
    } else {
      setRecBalanced(false);
    }
  }, [clearedBalances?.difference]);

  return (
    <ColumnWrapper>
      <RowWrapper>
        {closingBalance !== "" && (
          <AmountWrapper
            onClick={() => setInitialOptionsModal(true)}
            style={{ cursor: "pointer" }}>
            <AmountText>{returnCurrency(closingBalance)}</AmountText>
            <AmountLabelText>Closing Balance</AmountLabelText>
          </AmountWrapper>
        )}
        <MathSymbolText> - </MathSymbolText>
        <AmountWrapper balance>
          <AmountText>{returnCurrency(clearedBalances.balance)}</AmountText>
          <AmountLabelText>Cleared Balance</AmountLabelText>
        </AmountWrapper>
        <MathSymbolText> = </MathSymbolText>
        <AmountWrapper>
          <AmountText
            error={clearedBalances.difference !== 0}
            success={clearedBalances.difference === 0}>
            {returnCurrency(clearedBalances.difference)}
          </AmountText>
          <AmountLabelText>Difference</AmountLabelText>
        </AmountWrapper>
      </RowWrapper>
      <RowWrapper balance={true}>
        <AmountWrapper>
          <AmountText>{returnCurrency(openingBalance.amount)}</AmountText>
          <AmountLabelText>Starting Balance</AmountLabelText>
        </AmountWrapper>
        <MathSymbolText style={{ fontSize: "4rem" }}> - </MathSymbolText>
        <AmountWrapper>
          <AmountText>{returnCurrency(clearedBalances.credits)}</AmountText>
          <AmountLabelText>
            {account?.accountType === "Assets"
              ? `${clearedBalances.creditLines} Payments`
              : `${clearedBalances.creditLines} Charges`}
          </AmountLabelText>
        </AmountWrapper>
        <MathSymbolText> + </MathSymbolText>
        <AmountWrapper>
          <AmountText>{returnCurrency(clearedBalances.debits)}</AmountText>
          <AmountLabelText>
            {account?.accountType === "Assets"
              ? `${clearedBalances.debitLines} Deposits`
              : `${clearedBalances.debitLines} Payments`}
          </AmountLabelText>
        </AmountWrapper>
      </RowWrapper>
    </ColumnWrapper>
  );
};

export default ReconciliationStatus;
