import { collection, onSnapshot, query, where } from "firebase/firestore";
import { convertToDate } from "../../utilities/general_util";

const { aggregatesFromDb } = require("./process_tx_changes");

export const fetchDataInitially = async ({
  db,
  org,
  setMonthlyAggregates,
  setTransactions,
  setLatestTimestamp,
}) => {
  // Get all aggregated transactions from org/orgId/monthlyAggregates
  try {
    const aggregateReturn = await aggregatesFromDb({
      db,
      org,
      setMonthlyAggregates,
    });
    console.log(aggregateReturn);
    if (aggregateReturn === null || aggregateReturn === undefined) {
      console.log(
        "No aggregates for this org - it either doesn't have enough transactions or it's a new org",
      );
      // setMonthlyAggregates({});
      return { monthlyAggregates: null, transactions: [] };
    } else {
      //There are aggregates, so we need to merge them with the existing transactions
      //parse out the aggregate info with its function
      const {
        existingTransactionsById,
        monthlyAggregates,
        latestTimestampInSec,
      } = aggregateReturn;
      console.log(
        "Transactions from the monthlyAggregates: ",
        existingTransactionsById,
      );
      const updatedTransactions = Object.entries(existingTransactionsById).map(
        ([key, value]) => {
          return { id: key, ...value };
        },
      );
      return {
        latestTimestampInSec: latestTimestampInSec,
        transactions: updatedTransactions,
        monthlyAggregates: monthlyAggregates,
      };
    }
  } catch (error) {
    console.error("Error fetching transactions: ", error);
  }
};

export const nonOnboardedTxListener = ({ db, org, setTransactions }) => {
  const txCollectionRef = collection(db, "orgs", org, "journalEntries");

  // Query to fetch documents where addTimestamp is false
  const q = query(txCollectionRef, where("addTimestamp", "==", false));

  let localTransactions = []; // Local state of transactions

  const unsubTransactions = onSnapshot(q, (snapshot) => {
    // Process only changes
    snapshot.docChanges().forEach((change) => {
      const transactionData = { id: change.doc.id, ...change.doc.data() };

      if (change.type === "added") {
        const entryUnformattedDate = transactionData.date;
        const entryFormattedDate = convertToDate(entryUnformattedDate);
        const entryFormatted = {
          ...transactionData,
          date: entryFormattedDate,
        };
        localTransactions.push(entryFormatted);
      } else if (change.type === "modified") {
        localTransactions = localTransactions.map((tx) =>
          tx.id === transactionData.id ? transactionData : tx,
        );
      } else if (change.type === "removed") {
        localTransactions = localTransactions.filter(
          (tx) => tx.id !== transactionData.id,
        );
      }
    });

    // Update the state with the latest transactions
    setTransactions([...localTransactions]);
  });

  return () => {
    unsubTransactions();
  };
};
