import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  primary_color,
  primary_dark_color,
} from "../../constants/color_constants";

export const Text = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: center;
  margin: 1rem;
`;
export const TitleText = styled.p`
  font-size: 2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: center;
  margin: 1rem;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 650px;
  background-color: rgba(24, 30, 32, 0.9);
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
  padding: 20px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
  max-height: 80vh; // Adjust the maximum height as needed
  overflow-y: auto; // Enable vertical scrolling
`;

export const OptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  border: 4px solid ${primary_color};
  box-shadow: black 0px 0px 10px 0px;
  padding: 20px;
  margin: 20px;
  width: 25vw;
`;

export const FootnoteDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ThemedTitleText = styled.p`
  font-size: 1.7rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: center;
  margin: 1rem;
  /* border-bottom: 2px solid ${primary_color}; */
`;

export const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

export const DescriptiveText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: left;
  margin: 0.5rem;
  margin-top: 0;
`;

export const ActionButton = styled.div`
  border: 2px solid ${primary_color};
  background-color: ${primary_color};
  border-radius: 12px;
  cursor: pointer;
`;

export const ActionButtonText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: black;
  background-color: ${primary_color};
  text-align: left;
  margin: 0.4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
`;
