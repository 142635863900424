import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { useContext } from "react";
import UserContext from "../../../assets/user_context";
import {
  amountToCentsInt,
  generateRandomId,
} from "../../../utilities/general_util";

const convertToDate = (date) => {
  if (typeof date === "string") {
    // Convert string to JavaScript Date object
    return new Date(date);
  } else if (date instanceof Date) {
    // Already a JavaScript Date object
    return date;
  } else if (dayjs.isDayjs(date)) {
    // Convert Day.js object to JavaScript Date object
    return date.toDate();
  } else if (date && typeof date.seconds === "number") {
    // Convert from seconds to JavaScript Date object
    return new Date(date.seconds * 1000);
  } else {
    // Unsupported type
    throw new Error("Unsupported date type");
  }
};

// Function to record the journal entry in the database
export const useRecordJE = () => {
  const {
    experimental,
    org,
    contacts,
    setTransactions,
    transactions,
    user,
    setContacts,
  } = useContext(UserContext);
  const recordJE = async ({ metaData, entryData, db, entryToEdit }) => {
    const userId = user.uid;
    console.log("Recording Journal Entry IN recordJE function: ", entryData);

    const buildUniqueContactsNotInDB = () => {
      //build a list of contacts from the entryData that is unique
      const uniqueContacts = [];
      entryData.forEach((line) => {
        if (
          !uniqueContacts.some((contact) => contact === line.contact) &&
          line.contact !== null &&
          line.contact !== "Journal Entry"
        ) {
          uniqueContacts.push(line.contact);
        }
      });
      //For each unique contact, filter out all contacts that are in the list of contacts, or return all if the list of contacts is empty
      const contactsToAdd = uniqueContacts.filter(
        (contact) =>
          contacts.length === 0 ||
          !contacts.some((existingContact) => existingContact.id === contact),
      );
      return contactsToAdd;
    };

    // adds the list to the database, returning the id's of the new contacts so these can be updated in the journal entry before submission
    const addMultipleContacts = async (contactsToAdd) => {
      const newContacts = await Promise.all(
        contactsToAdd.map(async (contact) => {
          let contactId;
          if (!experimental) {
            const contactsRef = collection(db, "orgs", org, "contacts");
            const newContact = await addDoc(contactsRef, {
              shortName: contact,
              type: "Vendor",
              companyName: contact,
            });
            contactId = newContact.id;
          } else {
            contactId = generateRandomId();
            const newContact = {
              id: contactId,
              shortName: contact,
              type: "Vendor",
              companyName: contact,
            };
            setContacts((prevState) => [...prevState, newContact]);
          }
          return { id: contactId, shortName: contact };
        }),
      );
      return newContacts;
    };

    const addJE = async (entryData) => {
      //Need to check if this is an edit or a new entry

      const journalEntriesRef = collection(db, "orgs", org, "journalEntries");
      const journalEntryData = {
        userMadeChange: userId,
        addTimestamp: true,
        journalEntry: metaData.journalEntry || false,
        date: convertToDate(metaData.date),
        memo: metaData.memo || "",
        checkNumber: metaData.checkNumber || false,
        lines: entryData
          .map((line) => {
            return {
              account: line.account,
              amount: line.credit ? line.credit : line.debit,
              sign: line.credit ? "credit" : "debit",
              fund: line.fund,
              comment: line.comment || "",
              contact: line.contact,
              cleared: line.cleared || false,
            };
          })
          .filter(
            (line) =>
              line.amount !== "" &&
              line.amount !== 0 &&
              line.amount !== null &&
              line.amount !== undefined,
          ),
      };
      // console.log("Journal Entry Data1: ", journalEntryData);
      if (metaData.plaidTx && metaData.plaidTx !== null) {
        //This was imported from plaid, so we need to save that data as well
        journalEntryData.plaidTx = metaData.plaidTx;
        journalEntryData.plaidTx.date = journalEntryData.date;
      }
      // console.log("Journal Entry Data2: ", journalEntryData);
      if (entryToEdit) {
        console.log("Editing an entry");
        //This is an edit, so we need to replace the old entry with the new one
        if (!experimental) {
          //Optimistically update transactions, but with optimistic=true, so we can replace it when receiving the response from the database
          setTransactions((prevState) => [
            ...prevState.filter((tx) => tx.id !== entryToEdit.id),
            {
              ...journalEntryData,
              date: convertToDate(journalEntryData.date),
              id: entryToEdit.id,
              optimistic: true,
            },
          ]);
          const journalEntryRef = doc(
            db,
            "orgs",
            org,
            "journalEntries",
            entryToEdit.id,
          );
          try {
            const resp = await setDoc(journalEntryRef, journalEntryData);
            console.log("Journal Entry edited with ID: ", resp.id, resp);
          } catch (e) {
            console.error("Error editing journal entry: ", e);
          }
        } else {
          const newJournalEntries = transactions.map((tx) => {
            if (tx.id === entryToEdit.id) {
              return {
                ...journalEntryData,
                date: convertToDate(journalEntryData.date),
              };
            } else {
              return tx;
            }
          });
          setTransactions(newJournalEntries);
        }
      } else {
        if (!experimental) {
          //Optimistically update transactions, but with optimistic=true, so we can replace it when receiving the response from the database
          setTransactions((prevState) => [
            ...prevState,
            {
              ...journalEntryData,
              date: convertToDate(journalEntryData.date),
              id: generateRandomId(),
              optimistic: true,
            },
          ]);
          try {
            const resp = await addDoc(journalEntriesRef, journalEntryData);
            // console.log("Journal Entry added with ID: ", resp.id, resp);
          } catch (e) {
            console.error("Error adding journal entry: ", e);
          }
        } else {
          setTransactions((prevState) => [
            ...prevState,
            {
              ...journalEntryData,
              id: generateRandomId(),
              date: dayjs(journalEntryData.date),
            },
          ]);
        }
      }
    };

    if (contacts?.length > 0) {
      const contactsToAdd = buildUniqueContactsNotInDB();
      if (contactsToAdd.length > 0) {
        console.log("Contacts to add: ", contactsToAdd);

        const newContactIds = await addMultipleContacts(contactsToAdd);
        // Update the entryData with the new contact id's
        const newEntryData = [...entryData];
        newEntryData.forEach((line) => {
          newContactIds.forEach((newContact) => {
            if (line.contact === newContact.shortName) {
              line.contact = newContact.id;
            }
          });
        });
        addJE(newEntryData);
      } else {
        // No new contacts to add, just add the journal entry
        addJE(entryData);
      }
    } else {
      // No contacts in the database, we must add all contacts before the journal entry
      const contactsToAdd = buildUniqueContactsNotInDB();
      const newContactIds = await addMultipleContacts(contactsToAdd);
      // Update the entryData with the new contact id's
      const newEntryData = [...entryData];
      newEntryData.forEach((line) => {
        newContactIds.forEach((newContact) => {
          if (line.contact === newContact.shortName) {
            line.contact = newContact.id;
          }
        });
      });
      addJE(newEntryData);
    }
  };
  return recordJE;
};
