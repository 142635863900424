import { TableCell, TableRow, TextField } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import UserContext from "../../../assets/user_context";
import { CurrencyInput } from "../../general_util";

const AddSplitRow = ({
  txkey,
  focusedKey,
  splitValue,
  setSplitValue,
  setFocusedKey,
  transaction,
  setFocusedAspect,
  setSplitTx,
  addSplit,
}) => {
  //useEffect to log splitValue when it changes
  useEffect(() => {
    console.log("Split value changed to ", splitValue);
  }, [splitValue]);

  return (
    <TableRow key={`${txkey}-nextSplit`}>
      <TableCell sx={{ borderBottom: "none" }} />
      <TableCell colSpan={transaction.debit > 0 ? 4 : 5} />
      <TableCell>
        {/* <TextField
          name={`splitamount-${txkey}-new`}
          type="text"
          value={
            focusedKey !== null &&
            txkey === focusedKey[0] &&
            focusedKey[1] === -1
              ? splitValue
              : ""
          }
          onChange={(e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
              setSplitValue(e.target.value);
            }
          }}
          onFocus={() => {
            setSplitValue("");
            setFocusedKey([txkey, -1]);
          }}
          onBlur={() => {
            if (splitValue === "") {
              if (setSplitTx) {
                setTimeout(() => {
                  setSplitTx(false);
                }, 500);
              }
              return;
            }
            //This timeout will allow the new focus to set before pushing to the db which seems to be creating a render when recieving the new data
            setTimeout(() => {
              addSplit({ key: txkey, splitValue });
            }, 500);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addSplit({ key: txkey, splitValue });
            }
            if (e.key === "Tab") {
              e.preventDefault();
              addSplit({ key: txkey, splitValue });
              setTimeout(() => {
                setFocusedKey([txkey, transaction?.splits?.length]);
              }, 450);
              setFocusedAspect("accountselect");
            }
          }}
        /> */}
        <CurrencyInput
          name={`splitamount-${txkey}-new`}
          type="text"
          value={
            focusedKey !== null &&
            txkey === focusedKey[0] &&
            focusedKey[1] === -1
              ? splitValue
              : ""
          }
          currencyValueCents={splitValue}
          setCurrencyValueCents={setSplitValue}
          onFocus={() => {
            setSplitValue("");
            setFocusedKey([txkey, -1]);
          }}
          onBlur={() => {
            if (splitValue === "") {
              if (setSplitTx) {
                setTimeout(() => {
                  setSplitTx(false);
                }, 500);
              }
              return;
            }
            //This timeout will allow the new focus to set before pushing to the db which seems to be creating a render when recieving the new data
            setTimeout(() => {
              addSplit({ key: txkey, splitValue });
            }, 500);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addSplit({ key: txkey, splitValue });
            }
            if (e.key === "Tab") {
              e.preventDefault();
              addSplit({ key: txkey, splitValue });
              setTimeout(() => {
                setFocusedKey([txkey, transaction?.splits?.length]);
              }, 450);
              setFocusedAspect("accountselect");
            }
          }}
          size="small"
        />
      </TableCell>
      {transaction.debit > 0 && <TableCell />}
    </TableRow>
  );
};

export default AddSplitRow;
