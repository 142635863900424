import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import StyledButton from "../assets/buttons";
import UserContext from "../assets/user_context";
import { openBillingPortal } from "./with_subscription";
import { useAuth } from "./use-auth";
import { Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import dayjs from "dayjs";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 800px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
  padding: 5px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const FreeTrialNotification = () => {
  const { openTrialNotification, setOpenTrialNotification, orgData } =
    useContext(UserContext);
  const [daysSinceCreation, setDaysSinceCreation] = useState(0);
  useEffect(() => {
    if (orgData) {
      const today = new Date();
      console.log("orgData: ", orgData);
      const creationDate = orgData.creationDate?.toDate();
      //using dayjs, check if it has been more than 15 days since the org was created
      const daysSinceCreationCalc = dayjs(today).diff(creationDate, "day");
      setDaysSinceCreation(daysSinceCreationCalc);
    }
  }, [orgData]);

  return (
    <Modal
      open={openTrialNotification}
      onClose={() => setOpenTrialNotification(false)}>
      <ModalBox>
        <ModalInner style={{ padding: "30px" }}>
          <h1>Free Trial 🎉</h1>
          <h3>{`You have ${
            15 - daysSinceCreation
          } days left of your free trial, then you'll need to subscribe to keep using the software.`}</h3>
          <StyledButton
            primary
            fontSize="1.2rem"
            onClick={() => {
              setOpenTrialNotification(false);
            }}>
            Sounds Good
          </StyledButton>
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

const SubscribeWarning = React.forwardRef(
  ({ closeModal, openSubscribeModal, closeSubscribeModal }, ref) => {
    const {
      org,
      user,
      setIsChildDataLoaded,
      userData,
      openSubscribe,
      setOpenSubscribe,
      orgData,
    } = useContext(UserContext);
    const authHook = useAuth();
    const [stripeSubscriber, setStripeSubscriber] = useState(null);

    const closeSubscribe = () => {
      setOpenSubscribe(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
      const fetchSubscriberData = async () => {
        try {
          // console.log("Fetching Stripe User - org subscriber data");
          const stripeUsersRef = collection(authHook.db, "stripeUsers");
          // Query for stripeUsers that have currentOrg=org && userId=user.uid
          const q = query(
            stripeUsersRef,
            where("currentOrg", "==", org),
            where("userId", "==", user.uid),
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length === 0) {
            console.log("No stripe user found for this user and org");
            return;
          }
          const stripeUser = querySnapshot.docs[0].data();
          console.log("stripeUser: ", stripeUser);
          setStripeSubscriber(stripeUser);
        } catch (error) {
          console.error(
            "Error fetching Stripe User data: ",
            error,
            "Note this could simply be due to permissions if this user doesn't exist in the Stripe collection yet.",
          );
          // Handle the error based on your application's needs
        }
      };
      if (user && org && !stripeSubscriber) {
        setTimeout(() => {
          fetchSubscriberData();
        }, 2000);
      }
    }, [user, org, stripeSubscriber, authHook.db, orgData]);

    return (
      <Modal open={openSubscribe} onClose={() => alert("You must subscribe")}>
        <ModalBox>
          <ModalInner style={{ padding: "30px" }}>
            <Close
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
                setTimeout(() => {
                  closeSubscribe();
                }, 200);
              }}
              fontSize="large"
            />
            {stripeSubscriber ? (
              <h3>
                <h1>Subscription Expired 🫣</h1>
                Please renew your subscription to continue using the app.
              </h3>
            ) : (
              <React.Fragment>
                <h1>Start a Subscription 🤓</h1>
                <h3>Please set up a subscription to continue using the app.</h3>
              </React.Fragment>
            )}
            {stripeSubscriber ? (
              <StyledButton
                bright
                onClick={() =>
                  openBillingPortal({
                    org,
                    user,
                    authHook,
                    setLoaded: setIsChildDataLoaded,
                  })
                }>
                Manage Account
              </StyledButton>
            ) : (
              <React.Fragment>
                {user && userData && (
                  <div
                    style={{
                      background: "white",
                      padding: "6px",
                      borderRadius: "20px",
                      width: "100%",
                    }}>
                    <stripe-pricing-table
                      pricing-table-id={
                        process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
                      }
                      publishable-key={
                        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                      }
                      customer-email={userData.email}
                      client-reference-id={`${user.uid}-${org}`}></stripe-pricing-table>
                  </div>
                )}
              </React.Fragment>
            )}
          </ModalInner>
        </ModalBox>
      </Modal>
    );
  },
);

export default SubscribeWarning;
