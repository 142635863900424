import React, { useContext, useEffect, useState } from "react";
import TypeRearrange from "./type_rearrange";
import { Save } from "@mui/icons-material";
import UserContext from "../../../assets/user_context";
import { MenuItem, Select, Switch } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import styled from "@emotion/styled";
import { primary_dark_color } from "../../../constants/color_constants";

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ControlsLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ClickableControlsLabelDiv = styled(ControlsLabelDiv)`
  cursor: pointer;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  border-radius: 1rem;
  border-radius: 3rem;
  background-color: #e7eff1;
`;

const DetailsText = styled.p`
  font-size: 1rem;
  font-family: "MontserratLight";
`;

const ControlsText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: center;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const RearrangeAccountsUI = ({ setEditMode, editMode }) => {
  const { org, accountsHierarchy, experimental, setAccountsHierarchy } =
    useContext(UserContext);
  const authHook = useAuth();
  const [draftGroups, setDraftGroups] = useState(accountsHierarchy.types || []);
  const [editing, setEditing] = useState("Assets");

  useEffect(() => {
    if (accountsHierarchy?.types) {
      setDraftGroups(accountsHierarchy.types);
    }
  }, [accountsHierarchy]);

  //Log out draftGroups when they change
  useEffect(() => {
    console.log(draftGroups);
  }, [draftGroups]);

  return (
    <HorizontalDiv>
      <VerticalDiv>
        <ControlsLabelDiv>
          <ControlsText>Edit Type: </ControlsText>
          <div style={{ width: "1rem" }} />
          <Select
            value={editing}
            onChange={(e) => setEditing(e.target.value)}
            style={{ width: "10rem" }}>
            <MenuItem value={"Assets"}>Assets</MenuItem>
            <MenuItem value={"Liabilities"}>Liabilities</MenuItem>
            <MenuItem value={"Income"}>Income</MenuItem>
            <MenuItem value={"Expenses"}>Expenses</MenuItem>
          </Select>
        </ControlsLabelDiv>
        <ControlsLabelDiv>
          <ControlsText>Rearrange Accounts</ControlsText>
          <Switch
            color="secondary"
            checked={editMode}
            onChange={() => setEditMode(!editMode)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </ControlsLabelDiv>
        <ClickableControlsLabelDiv
          onClick={async () => {
            try {
              if (!experimental) {
                await setDoc(
                  doc(
                    authHook.db,
                    "orgs",
                    org,
                    "organizationAndSettings",
                    "accountsHierarchy",
                  ),
                  { types: draftGroups },
                  { merge: true },
                );
                console.log("Document successfully written!");
              } else {
                console.log("Experimental Mode, making saves");
                setAccountsHierarchy({ types: draftGroups });
              }
              setEditMode(false);
            } catch (error) {
              console.error("Error writing document: ", error);
            }
          }}>
          <ControlsText>Save Changes</ControlsText>
          <div style={{ width: "1rem" }} />
          <Save fontSize="large" />
        </ClickableControlsLabelDiv>
        <DetailsText style={{ width: "20rem" }}>
          Rearrange accounts as you wish by dragging the accounts to combine or
          reorder them. You can also drag the groups to rearrange. Then click
          save to save this new account organization, or the switch to return to
          your normal accounts view.
        </DetailsText>
      </VerticalDiv>
      <React.Fragment>
        {draftGroups.map((typeObj) => (
          <React.Fragment>
            {typeObj.type === editing && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {typeObj.type !== "Equity" && (
                  <TypeRearrange
                    groupsIn={typeObj.groups}
                    // setGroups={setDraftGroups}
                    type={typeObj.type}
                    setDraftGroups={setDraftGroups}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </HorizontalDiv>
  );
};

export default RearrangeAccountsUI;
