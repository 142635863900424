import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../assets/user_context.jsx";

import {
  LargeLineText,
  PageWrapper,
  ButtonDiv,
  LineText,
  HorizontalWrapper,
} from "../reports_styles.jsx";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import { calcReportV2 } from "../calculators/calc_reports.js";
import { FileDownloadOutlined, Inventory2Outlined } from "@mui/icons-material";
import { convertToJSON, exportReportXlsx } from "../export_report_xlsx.jsx";
import { convertToDate } from "../../../utilities/general_util.jsx";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import TransactionsDrilling from "../transaction_drilling/transactions_drilling.jsx";
import { useTrackFundsDisplayedCount } from "../report_helper_functions.js";
import TableHeader from "../funds_header.jsx";
import AccountTypeRows, { NetIncomeColumns } from "../account_type_rows.jsx";
dayjs.extend(isBetween);

const IncomeStatementList = () => {
  const {
    accounts,
    accountsHierarchy,
    fundsHierarchy,
    funds,
    transactions,
    contacts,
    orgData,
  } = useContext(UserContext);

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("year"),
    dayjs(),
  ]);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(() => dayjs(new Date()));
  const [incomeStatementReportsData, setIncomeStatementReportsData] = useState(
    {},
  );

  const [showFunds, setShowFunds] = useState(true);
  const [txToDrill, setTxToDrill] = useState(null);
  const [txModalOpen, setTxModalOpen] = useState(false);
  const [fundTransactions, setFundTransactions] = useState({});

  const [fundGroupsToShow, setFundGroupsToShow] = useState([]);

  const [numberOfFundsShown, setNumberOfFundsShown] = useState(0);

  const [fundsDetailsToDisplay, setFundsDetailsToDisplay] = useState([]);

  useEffect(() => {
    if (fundsHierarchy?.groups?.length > 0) {
      const newFundGroupsToShow = fundsHierarchy.groups.map((group) => ({
        show: true, // Assuming you want all groups to be shown initially
        funds: group.funds.map((fund) => ({
          show: true, // Assuming you want all funds to be shown initially
          subFunds:
            fund.subFunds?.length > 0
              ? fund.subFunds.map(() => ({ show: true }))
              : [], // Ensure subFunds array is correctly set up
        })),
      }));
      setFundGroupsToShow(newFundGroupsToShow);

      const fundsDetails = fundsHierarchy.groups.map((group) => {
        return {
          show: true,
          funds: group.funds.map((fund) => {
            return { show: true };
          }),
        };
      });
      setFundsDetailsToDisplay(fundsDetails);
    }
  }, [fundsHierarchy]);

  const toggleFundVisibility = ({ groupIndex, fundIndex }) => {
    setFundsDetailsToDisplay((prevGroups) =>
      prevGroups.map((group, gIndex) => {
        if (gIndex === groupIndex) {
          // Toggle the entire group's visibility if it's the group header
          if (fundIndex === null) {
            return {
              ...group,
              show: !group.show,
            };
          } else {
            // Toggle only the subFunds' visibility for a specific fund
            const funds = group.funds.map((fund, fIndex) => {
              if (fIndex === fundIndex) {
                return {
                  show: !fund.show,
                };
              }
              return fund;
            });
            return { ...group, funds };
          }
        }
        return group;
      }),
    );
  };

  //log fundsDetailsToDisplay when it changes
  useEffect(() => {
    console.log(fundsDetailsToDisplay);
  }, [fundsDetailsToDisplay]);

  useTrackFundsDisplayedCount({
    fundsHierarchy,
    fundsDetailsToDisplay,
    setNumberOfFundsShown,
  });

  useEffect(() => {
    //If dateRange's final date is set, set a maxDate for the first date as the day before the final date
    if (dateRange[1]) {
      setMaxDate(dayjs(dateRange[1]));
    }

    //If dateRange's first date is set, set a minDate for the first date as the day after the first date
    if (dateRange[0]) {
      setMinDate(dayjs(dateRange[0]));
    }
  }, [dateRange]);

  //Income Report
  useEffect(() => {
    if (transactions.length > 0 && dateRange[0] && dateRange[1]) {
      console.log("Date Range: ", dateRange);
      const txInDateRange = transactions.filter((entry) => {
        const dateIsBetween = dayjs(convertToDate(entry.date)).isBetween(
          dateRange[0],
          dateRange[1],
          "day",
          //Inclusive of the start and end dates, use () instead of [] to be exclusive
          "[]",
        );
        return dateIsBetween;
      });
      console.log("Transactions in date range: ", txInDateRange.length);

      if (txInDateRange.length > 0) {
        const incomeStatementResults = calcReportV2({
          filteredTransactions: txInDateRange,
          accounts,
          funds,
          accountsHierarchy,
          fundsHierarchy,
          type: "incomeStatement",
        });

        console.log("Income Statement Report Data: ", incomeStatementResults);
        setIncomeStatementReportsData(incomeStatementResults);
      } else {
        console.log("No transactions in date range");
        setIncomeStatementReportsData({});
        setFundTransactions({});
      }
    }
  }, [transactions, dateRange, accounts]);

  useEffect(() => {
    if (Object.keys(incomeStatementReportsData).length > 0) {
      console.log("Income Statement Report Data: ", incomeStatementReportsData);
      console.log(
        incomeStatementReportsData[Object.keys(incomeStatementReportsData)[0]]
          .income,
      );
    }
  }, [incomeStatementReportsData]);

  const drillTx = ({ fund, account }) => {
    setTxToDrill({ fund: fund, account: account });
    setTxModalOpen(true);
  };

  const renderTitle = () => {
    return (
      <React.Fragment>
        <p
          style={{
            fontSize: "1.2rem",
            fontFamily: "MontserratMed",
            textAlign: "center",
            margin: "0.5rem",
            marginBottom: "27px",
          }}>
          {`Income Statement for the period of ${dateRange[0].format(
            "MM/DD/YYYY",
          )} to ${dateRange[1].format("MM/DD/YYYY")}`}
        </p>
      </React.Fragment>
    );
  };

  const addPdfExportClass = (element) => {
    element.classList.add("pdf-export");
    Array.from(element.children).forEach(addPdfExportClass);
  };

  const reportToPDF = () => {
    const element = document.getElementById("income-statement-table");
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Clone the element
    const clone = element.cloneNode(true);
    clone.classList.add("clone-for-pdf");

    // Find all <p> elements within <th> in the clone and add the 'pdf-header-text' class
    const headerParagraphs = clone.querySelectorAll("th > p"); // This selects <p> elements that are direct children of <th>
    headerParagraphs.forEach((p) => {
      p.classList.add("pdf-header-text");
    });

    if (funds.length > 4) {
      clone.classList.add("small-text");
    }

    if (numberOfFundsShown > 4) {
      clone.classList.add("small-text");
    }

    addPdfExportClass(clone);

    // Measure the height of the clone
    const tempContainer = document.createElement("div");
    document.body.appendChild(tempContainer); // Temporarily add to the body
    tempContainer.appendChild(clone); // Append clone for measurement

    const contentHeight = clone.scrollHeight;
    document.body.removeChild(tempContainer); // Remove temp container after measurement

    // Create a container for the clone and title
    const container = document.createElement("div");
    container.style.width = numberOfFundsShown > 4 ? "297mm" : "210mm"; // A4 width: ;
    container.style.height = `${
      contentHeight + (numberOfFundsShown > 4 ? 25 : 60)
    }px`; // A4 height: ;
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.alignItems = "center";
    // container.style.visibility = "hidden"; // Make the container invisible while keeping it in the flow

    // Render the title to a string
    const titleHtml = ReactDOMServer.renderToString(renderTitle());
    // Append title and clone to the container
    container.innerHTML = titleHtml;
    container.appendChild(clone);
    document.body.appendChild(container);

    // Define options for html2pdf
    const options = {
      margin: 10,
      filename: `income-statement-${dateRange[0].format(
        "MM/DD/YYYY",
      )} to ${dateRange[1].format("MM/DD/YYYY")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: numberOfFundsShown > 4 ? "landscape" : "portrait",
      },
      pagebreak: { mode: ["css", "legacy"] },
    };

    // Generate PDF
    setTimeout(() => {
      html2pdf()
        .from(container)
        .set(options)
        .save()
        .then(() => {
          document.body.removeChild(container); // Clean up after saving PDF
        });
    }, 800); // Delay to ensure rendering
  };

  return (
    <div>
      <PageWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HorizontalWrapper style={{ paddingBottom: "1rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            />
            <DatePicker
              label="Starting Date"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange([newValue, dateRange[1]]);
              }}
              maxDate={maxDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
            <LargeLineText>TO</LargeLineText>
            <DatePicker
              label="Ending Date"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange([dateRange[0], newValue]);
              }}
              disableFuture
              minDate={minDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
            <div style={{ width: "20vw" }} />
            <ButtonDiv>
              <LineText style={{ whiteSpace: "nowrap" }}>
                Export to XLSX
              </LineText>
              {/* <div style={{ width: "10px" }} /> */}
              <Inventory2Outlined
                fontSize="medium"
                onClick={() => {
                  const JSONReport = convertToJSON({
                    reportData: incomeStatementReportsData,
                    funds,
                  });
                  console.log("JSON Report: ", JSONReport);
                  exportReportXlsx({
                    JSONReport,
                    reportName: "Income Statement",
                    dateRange: `${dateRange[0].format(
                      "MM/DD/YYYY",
                    )}-${dateRange[1].format("MM/DD/YYYY")}`,
                    orgName: orgData?.orgName || "Test Organization",
                  });
                }}
              />
            </ButtonDiv>
            <div style={{ width: "60px" }} />
            <Tooltip
              title={
                numberOfFundsShown > 8
                  ? "Too many horizontal funds, collapse some if possible"
                  : null
              }>
              <ButtonDiv
                style={
                  numberOfFundsShown > 8
                    ? { borderColor: "grey", cursor: "not-allowed" }
                    : {}
                }
                disabled={numberOfFundsShown > 8}
                onClick={() => {
                  if (numberOfFundsShown <= 8) reportToPDF();
                }}>
                <LineText
                  style={
                    numberOfFundsShown > 8
                      ? {
                          textDecorationLine: "line-through",
                          whiteSpace: "nowrap",
                          color: "gray",
                        }
                      : { whiteSpace: "nowrap" }
                  }>
                  Export to PDF
                </LineText>
                {/* <div style={{ width: "10px" }} /> */}
                <FileDownloadOutlined
                  fontSize="medium"
                  style={numberOfFundsShown > 8 ? { color: "grey" } : {}}
                />
              </ButtonDiv>
            </Tooltip>
          </HorizontalWrapper>
        </LocalizationProvider>
        <div style={{ height: "25px" }} />
        <TransactionsDrilling
          txModalOpen={txModalOpen}
          setTxToDrill={setTxToDrill}
          setTxModalOpen={setTxModalOpen}
          fundTransactions={fundTransactions}
          contacts={contacts}
          txToDrill={txToDrill}
        />

        {Object.keys(incomeStatementReportsData).filter(
          (fundId) => fundId !== "total",
        ).length > 0 ? (
          <div
            id="income-statement-table"
            style={{
              paddingTop: "1.75rem",
              width: "100%",
              overflowX: "auto",
              maxWidth: "92vw",
            }}>
            <table>
              <TableHeader
                showFunds={showFunds}
                fundsDetailsToDisplay={fundsDetailsToDisplay}
                toggleFundVisibility={toggleFundVisibility}
                setShowFunds={setShowFunds}
                numberOfFundsShown={numberOfFundsShown}
              />
              <tbody>
                <AccountTypeRows
                  accountType="Income"
                  balanceSheetObject={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                />
                <AccountTypeRows
                  accountType="Expenses"
                  balanceSheetObject={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                />
                <NetIncomeColumns
                  incomeStatementData={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                />
              </tbody>
            </table>
          </div>
        ) : null}
        <div style={{ height: "25px" }} />
      </PageWrapper>
    </div>
  );
};

const IncomeStatement = withSubscriptionProtection(IncomeStatementList);

export default IncomeStatement;
