import { Autocomplete, Modal, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../assets/user_context";
import { findContactNameById } from "../../general_util";
import { ModalBox, ModalInner } from "../../../locations/component_styles";
import StyledButton from "../../../assets/buttons";
import { addDoc, collection } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";
import styled from "@emotion/styled";

const StyledLoadingLogo = styled(LoadingLogo)`
  z-index: 5;
  width: 10rem;
`;
const SelectContact = ({
  transactionContactValue,
  txkey,
  setTransactionContact,
  error,
}) => {
  const { contacts, org } = useContext(UserContext);
  const db = useAuth().db;
  const [displayContact, setDisplayContact] = useState(
    transactionContactValue && transactionContactValue !== null
      ? findContactNameById({ contactId: transactionContactValue, contacts })
      : null,
  );
  const [tempContact, setTempContact] = useState(
    transactionContactValue || null,
  );
  const [addContactConfirmModalOpen, setAddContactConfirmModalOpen] =
    useState(false);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");

  useEffect(() => {
    console.log("Contact value changed to ", transactionContactValue);
    if (
      transactionContactValue !== null &&
      transactionContactValue !== undefined
    ) {
      const matchingContactName = findContactNameById({
        contactId: transactionContactValue,
        contacts,
      });
      setDisplayContact(matchingContactName);
    } else if (transactionContactValue === null) {
      setTempContact(null);
      setDisplayContact(null);
    }
    setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
  }, [contacts, transactionContactValue]);

  return (
    <React.Fragment>
      <Modal open={addContactConfirmModalOpen}>
        <ModalBox>
          <ModalInner>
            <h3>{`Confirm Adding ${displayContact} as a New Contact?`}</h3>
            {!submittingContact ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <StyledButton
                  primary
                  fontSize={"1.2rem"}
                  height={"2.5rem"}
                  onClick={async () => {
                    setSubmittingContact(true);
                    const contactsRef = collection(db, "orgs", org, "contacts");
                    const newContact = await addDoc(contactsRef, {
                      shortName: tempContact,
                      type: "Vendor",
                      companyName: tempContact,
                    });
                    setTempContact(newContact.id);
                    setTransactionContact({
                      transactionId: txkey,
                      contactId: newContact.id,
                    });
                    setSubmittingContact(false);
                    setAddContactConfirmModalOpen(false);
                    //TODO: Add the contact to the database
                  }}>
                  Yes
                </StyledButton>
                <div style={{ width: "30px" }} />
                <StyledButton
                  primary
                  fontSize={"1.2rem"}
                  height={"2.5rem"}
                  onClick={() => {
                    setTempContact(null);
                    setDisplayContact(null);
                    setAddContactConfirmModalOpen(false);

                    //TODO: set focus back to the contact field or clear it
                  }}>
                  No
                </StyledButton>
              </div>
            ) : (
              <React.Fragment>
                <StyledLoadingLogo />
                <h3>Adding Contact...</h3>
              </React.Fragment>
            )}
          </ModalInner>
        </ModalBox>
      </Modal>
      <Autocomplete
        fullWidth
        freeSolo
        autoSelect
        //   key={`${someUniqueKeyThatForcesReRender}-${0}`}
        label="Contact"
        name={`contact-${txkey}-${someUniqueKeyThatForcesReRender}`}
        key={`contact-${txkey}`}
        placeholder={txkey === "mass" ? "Unchanged" : "Select a Contact"}
        options={
          contacts
            ? contacts
                .filter(
                  (contact) =>
                    contact.shortName !== null &&
                    contact.shortName !== "" &&
                    contact.shortName !== undefined,
                )
                .map((contact) => contact.shortName)
            : []
        }
        value={displayContact || null}
        onInputChange={(event, newValue) => {
          const matchingContact = contacts.find(
            (contact) => contact.shortName === newValue,
          );

          const newlyEnteredContact = matchingContact
            ? matchingContact.id
            : newValue;

          setTempContact(newlyEnteredContact);

          // Also update the display value
          setDisplayContact(() => {
            const newDisplayContact = matchingContact
              ? matchingContact.shortName
              : newValue;
            return newDisplayContact;
          });
        }}
        renderInput={(params) => {
          // Determine if the entered value is in the list
          const isValueInList =
            contacts &&
            tempContact &&
            tempContact !== null &&
            tempContact !== "" &&
            contacts.some((contact) => contact.id === tempContact);
          const notFound =
            "Will add as New Contact - Doesn't match any you have";
          return (
            <TextField
              {...params}
              name={`contact-${0}`}
              label="Contact"
              variant="outlined"
              // color="secondary"
              // Change the helper text based on the entered value
              helperText={
                tempContact === null || tempContact === "" || isValueInList
                  ? ""
                  : notFound
              }
              required
              error={error}
              size="small"
              onBlur={() => {
                if (
                  tempContact !== null &&
                  tempContact !== "" &&
                  !isValueInList
                ) {
                  setAddContactConfirmModalOpen(true);
                } else if (
                  tempContact !== null &&
                  tempContact !== "" &&
                  tempContact !== transactionContactValue
                ) {
                  console.log(
                    `The transaction's contact changed from ${transactionContactValue} to ${tempContact}, updating...`,
                  );
                  setTransactionContact({
                    transactionId: txkey || null,
                    contactId: tempContact,
                  });
                } else {
                  console.log(
                    `The transaction's contact remained the same: ${tempContact}`,
                  );
                }
              }}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

export default SelectContact;
