import { Autocomplete, InputAdornment, Modal, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ModalBox, ModalInner, TitleText } from "../../component_styles";
import { DetailText } from "../../transactions/transactions_styles";
import UserContext from "../../../assets/user_context";
import {
  formatNumberWithCommas,
  returnCurrency,
  returnCurrencyInput,
} from "../../../utilities/general_util";
import { LineText } from "../../foundation/table_styles";
import StyledButton from "../../../assets/buttons";
import { useRecordJE } from "../../transactions/enter_transaction/record_journal_entry";
// import { Dayjs, isDayjs } from "dayjs";
import { useAuth } from "../../../services/use-auth";
import styled from "@emotion/styled";
import dayjs from "dayjs";

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const BalanceColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
`;

const CCPaymentTool = ({
  openPaymentTool,
  setOpenPaymentTool,
  paymentToProcess,
  selectedAccount,
  accountToPayFromInput,
  submitCallback,
  resetPaymentOptions,
}) => {
  const authHook = useAuth();
  const { transactions, funds, accounts, contacts, user, org } =
    useContext(UserContext);
  const [fundBalancesOfAccountOnDate, setFundBalancesOfAccountOnDate] =
    useState([]);
  const [remainingToAssign, setRemainingToAssign] = useState(null);
  const [balancesToPay, setBalancesToPay] = useState({});
  const [balancesToPayDisplay, setBalancesToPayDisplay] = useState({});
  const [accountToPayFrom, setAccountToPayFrom] = useState("");

  const findAccountById = (id) => {
    return accounts.find((account) => account.id === id);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    const { value, name } = event.target;
    console.log(event.target.name);

    if (name.split("-")[0] === "balancesToPay") {
      const fundId = name.split("-")[1];
      const isNumberOrDecimal = /^\d*\.?\d*$/; // Adjusted regex to match only non-negative numbers and decimals

      if (value === "" || isNumberOrDecimal.test(value)) {
        console.log("value", value);
        const { formattedDollarValue, centsInt } = returnCurrencyInput(value);

        setBalancesToPay((prevData) => ({
          ...prevData,
          [fundId]: centsInt,
        }));

        setBalancesToPayDisplay((prevDisplay) => ({
          ...prevDisplay,
          [fundId]: formattedDollarValue,
        }));
      }
    }
  };

  useEffect(() => {
    if (Object.keys(balancesToPay).length > 0 && paymentToProcess) {
      //Calculate the remaining to assign
      const totalAssigned = Object.values(balancesToPay).reduce((a, b) => {
        return a + parseFloat(b);
      }, 0);
      const remainingToAssign = paymentToProcess.amount * -1 - totalAssigned;
      setRemainingToAssign(remainingToAssign);
    }
  }, [balancesToPay, paymentToProcess]);

  useEffect(() => {
    setAccountToPayFrom(accountToPayFromInput);
  }, [accountToPayFromInput]);

  // Calculate the balance of each account by fund
  const calculateAccountBalances = () => {
    // Initialize fundBalances with each fund having a balance of 0
    const fundBalances = funds.map((fund) => {
      return { fundDatabaseId: fund.id, fundName: fund.fundName, balance: 0 };
    });
    const transactionsBeforeDate = transactions.filter((transaction) => {
      const transactionDate = dayjs.isDayjs(transaction.date)
        ? transaction.date.toDate()
        : new Date(transaction.date.$d);
      return transactionDate <= paymentToProcess.date.$d;
    });
    // Loop through all transactions
    transactionsBeforeDate.forEach((transaction) => {
      // Loop through all lines within a transaction
      transaction.lines.forEach((line) => {
        // If the line's account matches the selected account
        if (line.account === selectedAccount) {
          // Find the corresponding fund in fundBalances
          const thisFund = fundBalances.find(
            (fund) => fund.fundDatabaseId === line.fund,
          );
          // If the fund is found, update its balance
          if (thisFund) {
            // If the line is a debit, subtract the amount from the fund's balance
            if (line.sign === "debit") {
              thisFund.balance -= line.amount;
            } else {
              thisFund.balance += line.amount;
            }
          }
        }
      });
    });

    // Return the updated fundBalances
    return fundBalances;
  };

  useEffect(() => {
    if ((selectedAccount, funds, transactions, paymentToProcess)) {
      const fundBalances = calculateAccountBalances();
      console.log("fundBalances", fundBalances);
      setFundBalancesOfAccountOnDate(fundBalances);
    }
  }, [selectedAccount, funds, transactions, paymentToProcess]);

  const recordJE = useRecordJE();
  const submitPayment = () => {
    console.log("paymentToProcess", balancesToPay);
    const lines = [];
    Object.keys(balancesToPay).forEach((fundDatabaseId) => {
      const amount = parseFloat(balancesToPay[fundDatabaseId]);
      if (amount > 0) {
        lines.push({
          account: selectedAccount,
          debit: amount,
          fund: fundDatabaseId,
          credit: "",
          comment: `CC Payment ${paymentToProcess.date.$d.toLocaleDateString()}`,
          contact: null,
        });
        lines.push({
          account: accountToPayFrom,
          credit: amount,
          fund: fundDatabaseId,
          debit: "",
          comment: `CC Payment ${paymentToProcess.date.$d.toLocaleDateString()}`,
          contact: null,
        });
      }
    });
    console.log(paymentToProcess.date.$d);
    const metaData = {
      journalEntry: true,
      // date: new Date(paymentToProcess.date,
      checkNumber: paymentToProcess.checkNumber || false,
      date: dayjs.isDayjs(paymentToProcess?.date?.$d)
        ? paymentToProcess.date.$d.toDate()
        : dayjs(paymentToProcess.date.$d).toDate(),
      memo: `CC Payment ${paymentToProcess.date.$d.toLocaleDateString()}`,
      //Need to add this back in after implementing plaid again
      //plaidTx: paymentToProcess,
    };
    console.log("lines", lines);
    console.log("metaData", metaData);

    recordJE({
      entryData: lines,
      metaData,
      contacts,
      db: authHook.db,
      org,
      userId: user.uid,
    });
    if (resetPaymentOptions) resetPaymentOptions();
    setBalancesToPay({});
    setBalancesToPayDisplay({});
    setAccountToPayFrom("");
    setRemainingToAssign(null);
    if (submitCallback) submitCallback();
    setOpenPaymentTool(false);
  };

  return (
    <Modal
      open={openPaymentTool}
      onClose={() => {
        setOpenPaymentTool(false);
        //Reset all values in the states
        setBalancesToPay({});
        setBalancesToPayDisplay({});
        setAccountToPayFrom("");
        setRemainingToAssign(null);
        if (resetPaymentOptions) {
          resetPaymentOptions();
        }
      }}>
      <ModalBox>
        <ModalInner>
          <TitleText>Payment Tool</TitleText>
          {paymentToProcess?.amount &&
            paymentToProcess?.date?.$d &&
            funds.length > 0 && (
              <React.Fragment>
                <DetailText>
                  We found a payment for{" "}
                  {returnCurrency(paymentToProcess.amount * -1)} on{" "}
                  {paymentToProcess.date.$d.toLocaleDateString()}.
                </DetailText>
                <Autocomplete
                  name={`account`}
                  options={
                    accounts?.length > 0
                      ? accounts
                          .filter(
                            (account) =>
                              account.id !== selectedAccount &&
                              account.accountType === "Assets",
                          )
                          .sort(
                            //Sort by acccountType, then accountNumber within each type
                            (a, b) =>
                              a.accountType.localeCompare(b.accountType) ||
                              a.accountNumber.localeCompare(b.accountNumber),
                          )
                      : []
                  }
                  groupBy={(option) => option.accountType}
                  getOptionLabel={(option) =>
                    ` ${option.accountNumber} - ${option.accountName}`
                  }
                  value={findAccountById(accountToPayFrom) || null}
                  onChange={(event, newValue) => {
                    console.log(newValue); // This should give you the selected object

                    const newAccountVal = newValue ? newValue.id : null; // Set to the account's id or null
                    setAccountToPayFrom(newAccountVal);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={`accountToPayFrom`}
                      label="Asset payment came from"
                      variant="outlined"
                      color="secondary"
                      sx={{ width: 400 }}
                      // error={
                      // }
                      // // Change the helper text based on the entered value
                      // helperText={

                      // }
                    />
                  )}
                />
                {remainingToAssign && remainingToAssign !== 0 ? (
                  <DetailText>{`${returnCurrency(
                    remainingToAssign,
                  )} left to assign`}</DetailText>
                ) : null}
                {fundBalancesOfAccountOnDate.map((fund) => {
                  return (
                    <RowDiv>
                      <BalanceColumnDiv>
                        <LineText
                          style={{ marginTop: "8px", marginBottom: "2px" }}>{`${
                          fund.fundName
                        }: ${returnCurrency(fund.balance)}`}</LineText>
                        <p
                          style={{
                            textAlign: "center",
                          }}>{`Balance as of ${paymentToProcess.date.$d.toLocaleDateString()}`}</p>
                      </BalanceColumnDiv>

                      <TextField
                        name={`balancesToPay-${fund.fundDatabaseId}`}
                        label={`Assign to ${fund.fundName}`}
                        variant="outlined"
                        color="secondary"
                        style={{ width: "250px" }}
                        onChange={handleChange}
                        // value={balancesToPay[fund.fundDatabaseId] || ""}
                        value={balancesToPayDisplay[fund.fundDatabaseId] || ""}
                        InputProps={
                          balancesToPay[fund.fundDatabaseId] && {
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }
                        }
                        onBlur={() => {
                          if (balancesToPay[fund.fundDatabaseId] !== "") {
                            // Strip commas, format to two decimal places, and reapply comma formatting
                            const numericAmount = parseFloat(
                              balancesToPay[fund.fundDatabaseId] / 100,
                            ).toFixed(2);
                            const formattedAmount =
                              formatNumberWithCommas(numericAmount);

                            setBalancesToPayDisplay((prevData) => {
                              return {
                                ...prevData,
                                [fund.fundDatabaseId]: formattedAmount,
                              };
                            });
                          }
                        }}
                      />
                    </RowDiv>
                  );
                })}
                <StyledButton
                  disabled={remainingToAssign !== 0 || !accountToPayFrom}
                  primary
                  onClick={() => submitPayment()}>
                  Register Payment
                </StyledButton>
              </React.Fragment>
            )}
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

export default CCPaymentTool;
