import styled from "@emotion/styled";
import {
  primary_color,
  primary_dark_color,
  secondary_highlight_color,
} from "../../../constants/color_constants";
import { TextField } from "@mui/material";

export const CenteringDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.25rem;
`;

export const CreateLinkButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border: 3px solid ${primary_dark_color};
  border-radius: 15px;
`;

export const ButtonText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.2rem;
  color: ${primary_dark_color};
  margin: 10px;
`;

export const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 60vh;
  width: 100%;
`;

export const EnterTxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${secondary_highlight_color};
  padding: 1.25rem;
  border-radius: 20px;
  margin-top: 0.5rem;
  border: 1px solid ${primary_color};
  margin-bottom: 0.5rem;
  @media only screen and (max-height: 800px) {
    padding: 0.5rem;
  }
`;

export const BankingToolsWrapper = styled.div`
  margin-top: 0.25rem;
  display: flex;
  flex-direction: row;
  min-width: 85%;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-height: 800px) {
    margin-top: 0;
  }
`;

const WhiteTextField = styled(TextField)`
  background-color: white;
  /* margin: 5px;
  margin-right: 1rem; */
`;

export default WhiteTextField;
