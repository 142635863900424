import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { addJournalEntry } from "./starting_balance";
import {
  createNewHierarchy,
  removeHierarchyAccount,
  createNewFundHierarchy,
  removeFundHierarchy,
} from "./utils";
import { useContext } from "react";
import UserContext from "../../../assets/user_context";
import { generateRandomId } from "../../../utilities/general_util";
import { useAuth } from "../../../services/use-auth";

//Add account to database
export const useSubmitAccount = ({
  accountEntryData,
  editingAccountId,
  setShowModal,
  setAccountEntryData,
  accountDataToEdit,
  db,
  massAdd,
}) => {
  const {
    org,
    user,
    accounts,
    funds,
    accountsHierarchy,
    experimental,
    setAccounts,
    setAccountsHierarchy,
    setTransactions,
  } = useContext(UserContext);
  const handleSubmit = async () => {
    console.log("Adding Account", accountEntryData, "to org", org);
    const accountName = accountEntryData.accountName;
    const accountType = accountEntryData.type;
    const accountNumber = accountEntryData.accountNumber.toString();
    const accountData = {
      accountName: accountName,
      accountType: accountType,
      isRegister:
        accountType === "Assets" || accountType === "Liabilities"
          ? accountEntryData.isRegister
          : false,
      isPayableReceivable:
        accountType === "Assets" || accountType === "Liabilities"
          ? accountEntryData.isPayableReceivable
          : false,
      accountNumber: accountNumber,
      disabled: false,
    };
    let accountRef;
    //get all accounts of the same type from the database
    const updateAccountHierarchy = async (
      accountData,
      hierarchy,
      experimental,
      subAccountIds,
    ) => {
      console.log("Updating Account Hierarchy", accountData, hierarchy);
      const newHierarchy = createNewHierarchy(
        hierarchy,
        accountData.type,
        accountData.group,
        accountData.id,
        accountData.subAccount ? accountData.subAccountOf.id : null,
        subAccountIds ? subAccountIds : null,
      );
      console.log("New Hierarchy: ", newHierarchy);

      if (!experimental) {
        const hierachyRef = doc(
          db,
          "orgs",
          org,
          "organizationAndSettings",
          "accountsHierarchy",
        );
        await setDoc(hierachyRef, newHierarchy);
      } else {
        setAccountsHierarchy(newHierarchy);
      }
    };

    const setAccountDocument = async (accountData, id, experimental) => {
      console.log("Setting Account Document", accountData, id, experimental);
      if (!experimental) {
        const accountRef = doc(db, "orgs", org, "accounts", id);
        console.log("AccountRef:", accountRef);
        await setDoc(accountRef, accountData, { merge: true });
      } else {
        setAccounts((prevState) => {
          const updatedAccounts = prevState.map((account) => {
            if (account.id === id && account.accountType === accountData.type) {
              return { ...account, ...accountData };
            } else {
              return account;
            }
          });
          return updatedAccounts;
        });
      }
    };

    const createAccount = async (accountNumber) => {
      // Common setup
      accountData.accountNumber = accountNumber.toString();

      // Creating a new account
      if (!editingAccountId) {
        if (!experimental) {
          console.log("Adding Account: ", accountData);
          const accountsRef = collection(db, "orgs", org, "accounts");
          accountRef = await addDoc(accountsRef, accountData);
          console.log("Added Account: ", accountRef);
        } else {
          accountRef = { id: generateRandomId() };
          setAccounts((prevState) => [
            ...prevState,
            { ...accountData, id: accountRef.id },
          ]);
          console.log("Added Account: ", accountRef);
        }

        await updateAccountHierarchy(
          { ...accountEntryData, id: accountRef.id }, // Ensure ID is set here
          accountsHierarchy,
          experimental,
        );

        setShowModal(false);
        setAccountEntryData({ type: "", accountName: "" });
        return accountRef; // Exit the function early
      }

      // Editing an existing account
      if (accountEntryData.type !== accountDataToEdit.accountType) {
        // Handle the scenario where the account type cannot change
        console.error("Cannot change account type");
      } else {
        accountRef = editingAccountId;
        console.log(accountsHierarchy);
        // Grab the subAccount Id's of this account in the hierarchy, if it has any
        const subAccountIds = accountsHierarchy.types
          .find((type) => type.type === accountEntryData.type)
          .groups.find((group) => group.groupName === accountDataToEdit.group)
          .accounts.find(
            (account) => account.id === accountDataToEdit.accountDatabaseId,
          )
          ?.subAccounts?.map((subAccount) => subAccount);

        console.log("SubAccountIds: ", subAccountIds);
        // Remove this account from the hierarchy
        const newRemovedAccountHierarchy = removeHierarchyAccount(
          accountsHierarchy,
          accountDataToEdit.accountDatabaseId,
          experimental,
        );

        // Add it back with the updated information
        await updateAccountHierarchy(
          { ...accountEntryData, id: accountDataToEdit.accountDatabaseId },
          newRemovedAccountHierarchy,
          experimental,
          subAccountIds,
        );
        // console.log("accountEntryData:", accountEntryData);
        // subAccountIds.forEach(async (subAccountId) => {
        //   await updateAccountHierarchy(
        //     {
        //       type: accountEntryData.type,
        //       group: accountEntryData.group,
        //       id: subAccountId,
        //       subAccount: accountDataToEdit.accountDatabaseId,
        //     },
        //     newRemovedAccountHierarchy,
        //     experimental,
        //   );
        // });
        if (!experimental) {
          // Update the account document
          await setAccountDocument(
            accountData,
            accountDataToEdit.accountDatabaseId,
            experimental,
          );
        } else {
          setAccounts((prevState) => {
            const updatedAccounts = prevState.map((account) => {
              if (account.id === accountDataToEdit.accountDatabaseId) {
                return { ...account, ...accountData };
              } else {
                return account;
              }
            });
            return updatedAccounts;
          });
        }

        setShowModal(false);
      }
      console.log("Returning from createAccount:", accountRef);
      return accountRef;
    };

    if (accountEntryData.accountNumber !== "" && !massAdd) {
      //If the user entered an account number, check if it exists in the database
      const accountsWithNumber = accounts.filter(
        (account) =>
          account.accountNumber === accountEntryData.accountNumber.toString(),
      );

      if (accountsWithNumber.length > 0 && !editingAccountId) {
        //If the account number exists, check if the account name matches
        alert(
          "Account number already exists. Please enter a different account number.",
        );
      } else {
        //If the account number doesn't exist, create the account
        accountRef = await createAccount(accountEntryData.accountNumber);
        console.log("Received from createAccount:", accountRef);
        if (!accountRef) {
          console.log("Account not created");
          return;
        }
      }
    } else if (massAdd) {
      const accountsRef = collection(db, "orgs", org, "accounts");
      accountRef = await addDoc(accountsRef, accountData);
      console.log("Added Account: ", accountRef);
      return { [accountNumber]: accountRef.id };
    }
    if (accountEntryData.startingBalance > 0) {
      console.log("accountRef before addJournalEntry:", accountRef);
      addJournalEntry({
        accountEntryData,
        accounts,
        funds,
        accountId: accountRef.id,
        user,
        org,
        db,
        experimental,
        setTransactions,
        newAccountOverride: {
          accountType: accountEntryData.type,
          accountName: accountEntryData.accountName,
        },
      });
    }
  };
  return { handleSubmit };
};

export const useSubmitFund = ({
  fundEntryData,
  editingFundId,
  setShowModal,
  fundDataToEdit,
  setFundEntryData,
}) => {
  const {
    org,
    funds,
    accountsHierarchy,
    experimental,
    setFunds,
    setAccounts,
    setAccountsHierarchy,
    fundsHierarchy,
    setFundsHierarchy,
  } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  //Add fund to database
  const handleSubmit = async () => {
    console.log("Submitting Create Fund");

    const fundName = fundEntryData.fundName;
    const fundData = {
      fundName: fundName,
      fundNumber: fundEntryData.fundNumber.toString(),
      isRestricted: fundEntryData.isRestricted ? true : false,
    };

    const updateFundAndAccountHierarchies = async (
      fundID,
      fundEntryData,
      accountsHierarchy,
      fundsHierarchy,
      experimental,
      subFundIds,
    ) => {
      const parentFundId = fundEntryData.subFund
        ? fundEntryData.subFundOf.id
        : null;
      console.log("Parent Fund ID: ", parentFundId);
      const newFundHierarchy = createNewFundHierarchy(
        fundsHierarchy,
        fundEntryData.group,
        fundID,
        parentFundId,
        subFundIds ? subFundIds : null,
      );

      const newAccountHierarchy = createNewHierarchy(
        accountsHierarchy,
        "Equity",
        "Fund-Linked Accounts",
        fundID,
      );

      if (!experimental) {
        const hierarchyRef = doc(
          db,
          "orgs",
          org,
          "organizationAndSettings",
          "accountsHierarchy",
        );
        const fundHierarchyRef = doc(
          db,
          "orgs",
          org,
          "organizationAndSettings",
          "fundsHierarchy",
        );
        await setDoc(hierarchyRef, newAccountHierarchy);
        await setDoc(fundHierarchyRef, newFundHierarchy);
      } else {
        setAccountsHierarchy(newAccountHierarchy);
        setFundsHierarchy(newFundHierarchy);
      }
    };

    const setAccountDocument = async (accountData, accountId, experimental) => {
      if (!experimental) {
        const accountRef = doc(db, "orgs", org, "accounts", accountId);
        await setDoc(accountRef, accountData);
      } else {
        setAccounts((prevState) => {
          const updatedAccounts = prevState.map((account) => {
            if (account.id === accountId) {
              return { ...account, ...accountData };
            } else {
              return account;
            }
          });
          return updatedAccounts;
        });
      }
    };

    const createFund = async () => {
      let fundID;

      // Common setup
      if (!editingFundId) {
        if (!experimental) {
          console.log("Adding Fund: ", fundData);
          const fundsRef = collection(db, "orgs", org, "funds");
          const newFundRef = await addDoc(fundsRef, fundData);
          fundID = newFundRef.id;
          console.log("Added Fund: ", fundID);

          await setAccountDocument(
            {
              accountName: fundData.fundName,
              accountNumber: fundData.fundNumber,
              accountType: "Equity",
              group: "Fund-Linked Accounts",
            },
            fundID,
            experimental,
          );
        } else {
          fundID = generateRandomId();
          setFunds((prevState) => [...prevState, { ...fundData, id: fundID }]);
          setAccounts((prevState) => [
            ...prevState,
            {
              accountName: fundData.fundName,
              accountNumber: fundData.fundNumber,
              accountType: "Equity",
              group: "Fund-Linked Accounts",
              id: fundID,
              disabled: false,
            },
          ]);
        }

        await updateFundAndAccountHierarchies(
          fundID,
          { ...fundEntryData, id: fundID },
          accountsHierarchy,
          fundsHierarchy,
          experimental,
        );

        setShowModal(false);
        setFundEntryData({ fundName: "", fundNumber: "" });
        return;
      }

      // Editing an existing fund and its linked equity account
      if (editingFundId) {
        const fundRef = doc(db, "orgs", org, "funds", editingFundId);
        await setDoc(fundRef, fundData);
        console.log("HIERARCHY: ", fundsHierarchy, fundDataToEdit);

        const subFundIds = fundsHierarchy.groups
          .find((group) => group.groupName === fundDataToEdit.group)
          .funds.find((fund) => fund.id === fundDataToEdit.fundDatabaseId)
          ?.subFunds?.map((subFund) => subFund);
        console.log("SubFundIds: ", subFundIds);

        const newRemovedFundHierarchy = removeFundHierarchy(
          fundsHierarchy,
          fundDataToEdit.fundDatabaseId,
        );

        const newRemovedAccountHierarchy = removeHierarchyAccount(
          accountsHierarchy,
          fundDataToEdit.fundDatabaseId,
        );

        await updateFundAndAccountHierarchies(
          editingFundId,
          { ...fundEntryData, id: editingFundId },
          newRemovedAccountHierarchy,
          newRemovedFundHierarchy,
          experimental,
          subFundIds,
        );

        await setAccountDocument(
          {
            accountName: fundData.fundName,
            accountNumber: fundData.fundNumber,
            accountType: "Equity",
            group: "Fund-Linked Accounts",
          },
          editingFundId,
          experimental,
        );

        setShowModal(false);
      }
    };

    //If the account number doesn't exist, create the account
    createFund();
  };
  return { handleSubmit };
};
