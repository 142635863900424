import React, { useState, useContext } from "react";
import UserContext from "../../../assets/user_context";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  IconButton,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { ModalBox, ModalInner, ClickableDiv } from "./funds_list_styles";
import AddFund from "../manage_accounts/add_fund";
import {
  RowWrapper,
  TitleText,
  LineText,
  GroupCell,
  GroupText,
} from "../table_styles.jsx";
import RenderFundRow from "./render_fund_row";
import { Check, Close, Edit, NoAccountsOutlined } from "@mui/icons-material";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth.js";
import FundsRearrange from "./type_rearrage.jsx";
import RearrangeFundsUI from "./rearrange_funds.ui.jsx";

const FundsList = () => {
  const { funds, fundsHierarchy, orgRoles, org, userData } =
    useContext(UserContext);
  const authHook = useAuth();
  //Modal controls for adding a new fund
  const [showModalFundAdd, setShowModalFundAdd] = useState(false);
  const [showModalFundEdit, setShowModalFundEdit] = useState(false);
  const [fundToAddGroup, setFundToAddGroup] = useState("");
  const [fundToEditOptions, setFundToEditOptions] = useState({
    group: "",
    fundNumber: "",
    fundName: "",
    fundDatabaseId: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editingName, setEditingName] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");

  const changeGroupName = async () => {
    //Check to make sure the new group name is not already in use
    let nameExists = false;
    fundsHierarchy.groups.forEach((group) => {
      if (group.groupName === newGroupName) {
        nameExists = true;
      }
    });
    if (nameExists) {
      alert("Group name already exists");
    } else if (newGroupName !== "") {
      const newHierarchy = { ...fundsHierarchy };
      newHierarchy.groups.forEach((group) => {
        if (group.groupName === editingName) {
          group.groupName = newGroupName;
        }
      });
      //Update the fundsHierarchy in the database
      const fundsHierarchyRef = doc(
        authHook.db,
        "orgs",
        org,
        "organizationAndSettings",
        "fundsHierarchy",
      );
      await updateDoc(fundsHierarchyRef, newHierarchy);
      setEditingName(null);
      setNewGroupName("");
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={showModalFundAdd}
        onClose={() => {
          setShowModalFundAdd(false);
          setFundToAddGroup("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalBox>
          <ModalInner>
            <AddFund
              setShowModal={setShowModalFundAdd}
              thisGroup={fundToAddGroup}
            />
          </ModalInner>
        </ModalBox>
      </Modal>
      <Modal
        open={showModalFundEdit}
        onClose={() => setShowModalFundEdit(false)}>
        <ModalBox>
          <ModalInner>
            <AddFund
              setShowModal={setShowModalFundEdit}
              fundDataToEdit={fundToEditOptions}
            />
          </ModalInner>
        </ModalBox>
      </Modal>
      <RowWrapper style={{ width: "100%", justifyContent: "flex-start" }}>
        {orgRoles.some((role) => role === "admin" || role === "bookkeeper") &&
          !editMode && (
            <ClickableDiv
              style={{ marginRight: "2rem" }}
              onClick={() => setShowModalFundAdd(true)}>
              <AddCircleOutlineIcon />
              <div style={{ width: "1rem" }} />
              <LineText>Add Fund</LineText>
            </ClickableDiv>
          )}
      </RowWrapper>
      {!editMode &&
        (orgRoles.some((role) => role === "admin" || role === "bookkeeper") ||
          userData.superAdmin) && (
          <div
            style={{
              position: "absolute",
              top: "4rem",
              right: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Switch
              color="secondary"
              checked={editMode}
              onChange={() => setEditMode(!editMode)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <LineText>Rearrange Funds</LineText>
          </div>
        )}

      {fundsHierarchy?.groups?.length > 0 ? (
        <React.Fragment>
          {editMode ? (
            <React.Fragment>
              <RearrangeFundsUI setEditMode={setEditMode} editMode={editMode} />
            </React.Fragment>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Fund Number</TableCell>
                    <TableCell align="right">Fund Name</TableCell>
                    <TableCell align="right">Fund Attribute</TableCell>
                    <TableCell align="center">Edit Fund</TableCell>
                    <TableCell align="center">Enabled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fundsHierarchy.groups.map((groupObj) => (
                    <React.Fragment key={`${groupObj.groupName}-fragment`}>
                      <TableRow key={`${groupObj.groupName}-row`}>
                        <GroupCell
                          colSpan={5}
                          key={`${groupObj.groupName}-cell`}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}>
                            <div
                              style={{ width: "3rem" }}
                              key={`${groupObj.groupName}-spacerDiv`}
                            />
                            {editingName !== groupObj.groupName ? (
                              <React.Fragment>
                                {orgRoles.some(
                                  (role) =>
                                    role === "admin" || role === "bookkeeper",
                                ) && (
                                  <Tooltip
                                    title={`Add Fund to ${groupObj.groupName}`}>
                                    <AddCircleOutlineIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setFundToAddGroup(groupObj.groupName);
                                        setShowModalFundAdd(true);
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                <div style={{ width: "1rem" }} />
                                <GroupText>{groupObj.groupName}</GroupText>
                                <div style={{ width: "1rem" }} />
                                <Tooltip
                                  title={`Change Name of ${groupObj.groupName}`}>
                                  <Edit
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEditingName(groupObj.groupName);
                                      setNewGroupName(groupObj.groupName);
                                    }}
                                  />
                                </Tooltip>
                              </React.Fragment>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}>
                                <TextField
                                  label="Edit Name"
                                  value={newGroupName}
                                  onChange={(e) =>
                                    setNewGroupName(e.target.value)
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        onClick={() => changeGroupName()}>
                                        <Check
                                          style={{ color: "#61b561" }}
                                          fontSize="large"
                                        />
                                      </IconButton>
                                    ),
                                  }}
                                />
                                <br />
                                <Close
                                  onClick={() => {
                                    setEditingName(null);
                                    setNewGroupName("");
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </GroupCell>
                      </TableRow>
                      {groupObj.funds.map((fundObj) => (
                        <React.Fragment key={`${fundObj.id}-fragment`}>
                          <RenderFundRow
                            fund={fundObj}
                            funds={funds}
                            groupObj={groupObj}
                            setEditingData={setFundToEditOptions}
                            setShowEditModal={setShowModalFundEdit}
                            child={false}
                          />
                          {fundObj?.subFunds?.length > 0 &&
                            fundObj.subFunds.map((subFundObj) => (
                              <RenderFundRow
                                key={`${subFundObj.id}-fragment`}
                                fund={subFundObj}
                                funds={funds}
                                groupObj={groupObj}
                                setEditingData={setFundToEditOptions}
                                setShowEditModal={setShowModalFundEdit}
                                child={true}
                                parent={fundObj}
                              />
                            ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TitleText>Please Add Funds</TitleText>
          <button onClick={() => console.log(fundsHierarchy)}>Add Funds</button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FundsList;
