export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ACCOUNT = "/account";
export const TRANSACTIONS = "/transactions";
export const MANAGE_WORKOUTS = "/manage-workouts";
export const ADMIN = "/admin";
export const HOME = "/home";
export const ONBOARDING = "/onboarding";
export const BANKING = "/banking";
export const REPORTS = "/reports/balance-sheet";
