import {
  ComponentPageWrapper,
  NavBack,
  UnderConstruction,
} from "../component_styles.jsx";

const Preferences = () => {
  return (
    <ComponentPageWrapper>
      <NavBack>ACCOUNT</NavBack>
      <UnderConstruction>Preferences</UnderConstruction>
    </ComponentPageWrapper>
  );
};

export default Preferences;
