import React, { useRef, useState, createRef, useEffect } from "react";
import {
  LineTextBlack,
  ModalMasterBox,
  NoteText,
  TitleTextBlack,
} from "../mass_import_styles.jsx";
import AddAccount from "../../../locations/foundation/manage_accounts/add_account.jsx";
import styled from "@emotion/styled";
import StyledButton from "../../../assets/buttons.js";
import { Box } from "@mui/system";
import { collection, doc, writeBatch } from "firebase/firestore";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";

import { Checkbox, TextField, Tooltip } from "@mui/material";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  transform: translateY(15vh);
  width: 50vw;
  @media only screen and (max-width: 800px) {
    width: 60vw;
  }
  @media only screen and (max-width: 650px) {
    width: 70vw;
  }
  @media only screen and (max-width: 450px) {
    width: 85vw;
  }
`;

const CenteringDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
`;

const CurrentNameText = styled.p`
  font-family: "MontserratMed";
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  width: 300px;
  @media only screen and (max-width: 800px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const AlterLabelText = styled.p`
  font-family: "MontserratMed";
  text-align: right;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  margin-left: 20px;
  width: 200px;
  @media only screen and (max-width: 800px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const ScrollableContainer = styled.div`
  max-height: 70vh; // You can adjust this value
  overflow-y: auto;
`;

const HeaderText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  width: ${(props) =>
    props.first ? "350px" : props.second ? "200px" : "300px"};
  @media only screen and (max-width: 800px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

//Build a list of unique contacts to add from the transactions
const buildContactList = (transactionsArg) => {
  const uniqueContacts = new Set();
  const contactDataToAdd = transactionsArg.reduce(
    (accumulator, transaction) => {
      const contact = transaction.contact;
      if (!uniqueContacts.has(contact)) {
        uniqueContacts.add(contact);
        accumulator.push({ name: contact, alterName: false, newName: "" });
      }
      return accumulator;
    },
    [],
  );
  console.log("Contact Data to Add: ", contactDataToAdd);
  return contactDataToAdd;
};

const MassAddContacts = ({ org, db, transactionsArg, setShowModal }) => {
  const [submitting, setSubmitting] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [contactDataToAdd, setContactDataToAdd] = useState(
    buildContactList(transactionsArg),
  );

  const handleSubmitAll = async () => {
    setSubmitting(true);
    if (contactDataToAdd.length <= 500) {
      const batch = writeBatch(db);
      contactDataToAdd.forEach((contactData) => {
        const contactsRef = collection(db, "orgs", org, "contacts");
        if (contactData.alterName) {
          batch.set(doc(contactsRef), {
            shortName: contactData.newName,
            oldMatchName: contactData.name,
            type: "Vendor",
            companyName: contactData.newName,
          });
        } else {
          batch.set(doc(contactsRef), {
            shortName: contactData.name,
            oldMatchName: false,
            type: "Vendor",
            companyName: contactData.name,
          });
        }
      });
      try {
        await batch.commit();
        //If we get here, the batched writes were successful

        setShowModal(false);
        setSubmitting(false);
      } catch (e) {
        console.error("Error writing batched contacts: ", e);
      }

      return;
    } else {
      const successfulWrites = [];
      // Function to split the initialImportData into chunks of 500, since 500 is the max batch size
      const chunkArray = (array, chunkSize) => {
        const results = [];
        while (array.length) {
          results.push(array.splice(0, chunkSize));
        }
        return results;
      };

      const chunkedContacts = chunkArray(contactDataToAdd, 500);

      // Batched writes for initialImportData to the database
      let importSuccess = true; // assume success until proven otherwise
      for (let chunk of chunkedContacts) {
        const batch = writeBatch(db);
        chunk.forEach((contactData) => {
          const contactsRef = collection(db, "orgs", org, "contacts");
          if (contactData.alterName) {
            batch.set(doc(contactsRef), {
              shortName: contactData.newName,
              oldMatchName: contactData.name,
              companyName: contactData.newName,
              type: "Vendor",
            });
          } else {
            batch.set(doc(contactsRef), {
              shortName: contactData.name,
              oldMatchName: false,
              companyName: contactData.newName,
              type: "Vendor",
            });
          }
        });
        try {
          await batch.commit();
          successfulWrites.push(...chunk);
        } catch (e) {
          importSuccess = false;
          console.error("Error writing batched contacts: ", e);
          break;
        }
      }
      setImportSuccess(importSuccess);
      setShowModal(false);
      setSubmitting(false);
    }
  };

  return (
    <ModalMasterBox>
      <CenteringDiv>
        <h1>Mass Contact Add</h1>
      </CenteringDiv>
      {/* Adding a header row */}
      {!submitting ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "10px",
            }}>
            <HeaderText first>Imported Contact Name</HeaderText>
            <HeaderText second>Correct It?</HeaderText>
            <HeaderText>Corrected Name</HeaderText>
          </div>
          {/* Contact data rows */}{" "}
          <ScrollableContainer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {contactDataToAdd.map((contactData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      margin: "10px",
                    }}>
                    {contactData.name === "" ? (
                      <Tooltip title="Some of your transactions have no contact name, so we are adding a line for Empty to match these. These contacts can be changed later.">
                        <CurrentNameText style={{ color: "red" }}>
                          Contact Line Empty
                        </CurrentNameText>
                      </Tooltip>
                    ) : (
                      <CurrentNameText>{contactData.name}</CurrentNameText>
                    )}
                    <AlterLabelText>Alter Name</AlterLabelText>
                    <Checkbox
                      sx={{ marginRight: "20px" }}
                      checked={contactData.alterName}
                      color="secondary"
                      onChange={(e) => {
                        setContactDataToAdd((prevData) => {
                          const newData = prevData.map((data) => {
                            if (data.name === contactData.name) {
                              return {
                                ...data,
                                alterName: !data.alterName,
                                newName: !data.alterName ? "" : data.newName,
                              };
                            } else {
                              return data;
                            }
                          });
                          return newData;
                        });
                      }}
                    />
                    <div style={{ width: "30px" }} />
                    <TextField
                      sx={{ width: "400px", marginRight: "55px" }}
                      value={contactData.newName}
                      label="Altered Name"
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => {
                        setContactDataToAdd((prevData) => {
                          const newData = prevData.map((data) => {
                            if (data.name === contactData.name) {
                              return {
                                ...data,
                                newName: e.target.value,
                              };
                            } else {
                              return data;
                            }
                          });
                          return newData;
                        });
                      }}
                      disabled={!contactData.alterName}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollableContainer>
          <CenteringDiv>
            <StyledButton
              disabled={submitting}
              primary
              onClick={handleSubmitAll}>
              Submit All
            </StyledButton>
            <div style={{ height: "30px" }} />
          </CenteringDiv>
        </React.Fragment>
      ) : (
        <div style={{ transform: "translateY(-200px)", padding: "50px" }}>
          <LoadingLogoStyled />
        </div>
      )}
    </ModalMasterBox>
  );
};

export default MassAddContacts;
