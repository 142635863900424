export const primary_color = "#8fe7f5";
export const primary_light_color = "#d7f6f9";
export const primary_dark_color = "#204347";
export const primary_dark_color_lighter = "#285459";
export const secondary_color = "#fbf8da";

export const tertiary_color = "#ddf2dd";
export const light_grey = "#f5f5f5";

export const primary_highlight_color = "#8dd0d9";
export const secondary_highlight_color = "#dfeef0";
