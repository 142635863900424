import React, { useState, useContext, useEffect, useRef } from "react";
import UserContext from "../../../assets/user_context.jsx";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import {
  ComponentPageWrapper,
  TitleText,
  ModalBox,
  ModalInner,
} from "../../component_styles.jsx";
import { useAuth } from "../../../services/use-auth.js";
import {
  useListenForIncomingPayments,
  useListenForIncomingTx,
} from "../banking_utils.jsx";
import {
  BankingToolsWrapper,
  CenteringDiv,
  EnterTxWrapper,
} from "../banking/banking_styles.jsx";
import EnterBankingTransaction from "../banking/enter_bank_transaction.jsx";
import { InputAdornment, Modal, TextField } from "@mui/material";
import CCPaymentTool from "./cc_payment_tool.jsx";
import AccountSelector from "../account_selector.jsx";
import RegisterTransactions from "../register_transactions/register_transactions.jsx";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  formatNumberWithCommas,
  returnCurrencyInput,
} from "../../../utilities/general_util.jsx";
import StyledButton from "../../../assets/buttons.js";

const validatePaymentDetails = ({ date, amount, setErrorState }) => {
  const errors = {
    date: false,
    amount: false,
  };

  if (date === "") {
    errors.date = true;
  }
  if (amount === 0) {
    errors.amount = true;
  }

  setErrorState(errors);

  return errors;
};

const CreditCardsProtected = () => {
  const { accounts, org, user, experimental, orgRoles } =
    useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [creditCardAccounts, setCreditCardAccounts] = useState([]);
  // const [unlinkedAccounts, setUnlinkedAccounts] = useState([]);

  const [incomingTx, setIncomingTx] = useState([]);
  const incomingTxRef = useRef(incomingTx); // Create a ref to hold the current state

  const [incomingPayments, setIncomingPayments] = useState([]);
  const incomingPaymentsRef = useRef(incomingPayments);

  const [acceptedTx, setAcceptedTx] = useState([]);
  const [txToComplete, setTxToComplete] = useState(null);

  const [openEnterPlaidDetails, setOpenEnterPlaidDetails] = useState(false);
  const [openPlaidControlCenter, setOpenPlaidControlCenter] = useState(false);
  const [openPaymentTool, setOpenPaymentTool] = useState(false);

  const [paymentToProcess, setPaymentToProcess] = useState(null);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);
  const [showTxTable, setShowTxTable] = useState(true);

  const [paymentDate, setPaymentDate] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentAmountDisplay, setPaymentAmountDisplay] = useState("");
  const [recordPaymentModalOpen, setRecordPaymentModalOpen] = useState(false);
  const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({
    date: false,
    amount: false,
  });

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer
      const enterTxHeight =
        document.querySelector("#enter-transactions-wrapper")?.offsetHeight ||
        0;
      const bankingToolsHeight =
        document.querySelector("#banking-tools-wrapper")?.offsetHeight || 0;
      const titleHeight =
        document.querySelector("#page-title")?.offsetHeight || 0;
      const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
      const extra = experimental ? 230 : 190;
      const height =
        vh -
        (enterTxHeight +
          bankingToolsHeight +
          titleHeight +
          footerHeight +
          extra);
      const heightString = height.toString().concat("px");
      if (height <= 0) {
        setShowTxTable(false);
      } else {
        setShowTxTable(true);
      }

      // console.log(
      //   `Recalc Tx table height, viewHeight:${vh}, enterTxHeight:${enterTxHeight}, bankingToolsHeight:${bankingToolsHeight}, titleHeight:${titleHeight}, footerHeight:${footerHeight}, height:${heightString}`,
      // );
      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "enter-transactions-wrapper") {
          calculateHeight();
        }
      }
    });

    // Observe the target element
    const enterTxWrapper = document.querySelector(
      "#enter-transactions-wrapper",
    );
    if (enterTxWrapper) {
      resizeObserver.observe(enterTxWrapper);
    }

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
      if (enterTxWrapper) {
        resizeObserver.unobserve(enterTxWrapper);
      }
    };
  }, [experimental, creditCardAccounts, selectedAccount, orgRoles]);

  //log paymentDate when it changes
  useEffect(() => {
    console.log("Payment Date: ", paymentDate);
  }, [paymentDate]);

  // useEffect to set a listener for incoming transactions from Plaid with the slectedAccount
  //  This will update the incomingTx state and the acceptedTx state
  useListenForIncomingTx({
    selectedAccount,
    accounts,
    user,
    org,
    db,
    setIncomingTx,
    setAcceptedTx,
  });

  useListenForIncomingPayments({
    selectedAccount,
    accounts,
    user,
    org,
    db,
    setIncomingPayments,
  });

  useEffect(() => {
    incomingTxRef.current = incomingTx; // Update the ref whenever the state changes
  }, [incomingTx]);

  useEffect(() => {
    incomingPaymentsRef.current = incomingPayments;
  }, [incomingPayments]);

  useEffect(() => {
    const creditCards = accounts.filter(
      (account) =>
        account.isRegister &&
        !account.disabled &&
        account.accountType === "Liabilities",
    );

    const creditCardsSorted = creditCards.sort((a, b) => {
      if (a.accountNumber < b.accountNumber) {
        return -1;
      }
      if (a.accountNumber > b.accountNumber) {
        return 1;
      } else {
        return 0;
      }
    });
    if (creditCardsSorted.length > 0) {
      // console.log("Credit Cards: ", creditCards);
      setCreditCardAccounts(creditCardsSorted);
      setSelectedAccount(creditCardsSorted[0].id);
    } else {
      console.error("Bro, you don't have any credit cards.");
    }
  }, [accounts]);

  useEffect(() => {
    // console.log("Accepted tx: ", acceptedTx);
    if (acceptedTx.length > 0) {
      //TODO: Should filter this and handle it in a different spot earlier
      setTxToComplete(acceptedTx[0]);
      setOpenEnterPlaidDetails(true);
      // console.log("Queing up tx to edit: ", acceptedTx[0]);
    } else {
      // console.log("No accepted tx");
      setTxToComplete(null);
    }
  }, [acceptedTx]);

  useEffect(() => {
    // console.log("AcceptedPayment: ", incomingPayments);
    if (incomingPayments.length > 0) {
      setPaymentToProcess(incomingPayments[0]);
      setOpenPaymentTool(true);
      // console.log("Queing up payment to process: ", incomingPayments[0]);
    } else {
      // console.log("No accepted payment");
      setPaymentToProcess(null);
    }
  }, [incomingPayments]);

  //If the slected account has a plaidAccountId, then set the openPlaidControlCenter to false
  useEffect(() => {
    if (selectedAccount) {
      const thisAccountsPlaidId = accounts.find(
        (account) => account.id === selectedAccount,
      ).plaidAccountId;
      if (thisAccountsPlaidId) {
        setOpenPlaidControlCenter(false);
      }
    }
  }, [selectedAccount, accounts]);

  //Error style for date picker since prop is not working
  const errorStyleForDate =
    paymentDetailsErrors.date || paymentDetailsErrors.amount
      ? {
          color: "red",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "red",
            },
          },
          "& .MuiInputLabel-root": {
            // Custom style for the label
            color: "red",
          },
        }
      : {};

  const handleChange = (event) => {
    if (event.$d) {
      const value = event.$d;
      setPaymentDate(value);
    } else {
      const { name, value } = event.target;

      if (name === "paymentAmount") {
        const isNumberOrDecimal = /^\d*\.?\d*$/;
        if (value === "" || isNumberOrDecimal.test(value)) {
          // Format the display value, including any decimals accurately
          const { formattedDollarValue, centsInt } = returnCurrencyInput(value);

          setPaymentAmount(centsInt);
          setPaymentAmountDisplay(`${formattedDollarValue}`);
        }
      }
    }
  };

  //useEffect to re validate if there are any errors when values change
  useEffect(() => {
    if (
      (paymentDate !== "" || paymentAmount !== 0) &&
      (paymentDetailsErrors.date || paymentDetailsErrors.amount)
    ) {
      validatePaymentDetails({
        date: paymentDate,
        amount: paymentAmount,
        setErrorState: setPaymentDetailsErrors,
      });
    }
  }, [paymentDate, paymentAmount]);

  return (
    <ComponentPageWrapper>
      <CenteringDiv>
        {creditCardAccounts.length > 0 ? (
          <div style={{ width: "100%" }}>
            <BankingToolsWrapper id="banking-tools-wrapper">
              {creditCardAccounts.length > 0 ? (
                <React.Fragment>
                  <AccountSelector
                    accounts={creditCardAccounts}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    callback={() => {
                      setAcceptedTx([]);
                      setIncomingTx([]);
                    }}
                  />
                  {/* {!experimental ? (
                    <React.Fragment>
                      {accounts.find(
                        (account) => account.id === selectedAccount,
                      ).plaidAccountId ? (
                        acceptedTx.length > 0 ? (
                          <CreateLinkButton
                            onClick={() => setOpenEnterPlaidDetails(true)}>
                            <ButtonText>Register Tx</ButtonText>
                          </CreateLinkButton>
                        ) : (
                          <IncomingTx
                            incomingTx={incomingTx}
                            org={org}
                            selectedAccount={selectedAccount}
                            location="creditCards"
                          />
                        )
                      ) : (
                        <CreateLinkButton
                          onClick={() => setOpenPlaidControlCenter(true)}>
                          <ButtonText>Link Account</ButtonText>
                        </CreateLinkButton>
                      )}
                    </React.Fragment>
                  ) : (
                    //Experimental mode shouldn't have Plaid
                    <div />
                  )} */}
                </React.Fragment>
              ) : (
                <div />
              )}
              <StyledButton
                primary
                fontSize="1.2rem"
                onClick={() => setRecordPaymentModalOpen(true)}>
                Pay Card
              </StyledButton>
            </BankingToolsWrapper>
            {/* <PlaidControlCenter
              openPlaidControlCenter={openPlaidControlCenter}
              setOpenPlaidControlCenter={setOpenPlaidControlCenter}
              currentAccount={accounts.find(
                (account) => account.id === selectedAccount,
              )}
              /> */}
            <CCPaymentTool
              openPaymentTool={openPaymentTool}
              setOpenPaymentTool={setOpenPaymentTool}
              paymentToProcess={paymentToProcess}
              selectedAccount={selectedAccount}
              resetPaymentOptions={() => {
                setPaymentDate("");
                setPaymentAmount(0);
                setPaymentAmountDisplay("");
              }}
            />
            <Modal
              open={recordPaymentModalOpen}
              onClose={() => setRecordPaymentModalOpen(false)}>
              <ModalBox>
                <ModalInner>
                  <TitleText style={{ fontSize: "1.2rem" }}>
                    Payment Details
                  </TitleText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={<span>Payment Date *</span>}
                      name="paymentDate"
                      // sx={errorStyleForDate}
                      value={paymentDate || null}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // style={{ backgroundColor: "white" }}
                          // error={true}
                          variant="outlined"
                          color="secondary"
                          sx={{ p: "2rem" }}
                        />
                      )}
                      slotProps={{
                        textField: {
                          error: paymentDetailsErrors.date,
                          helperText: paymentDetailsErrors.date
                            ? "Please enter a valid date"
                            : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <TextField
                    name="paymentAmount"
                    value={paymentAmountDisplay}
                    onChange={handleChange}
                    sx={{ p: "2rem" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onBlur={() => {
                      if (paymentAmount !== "") {
                        // Strip commas, format to two decimal places, and reapply comma formatting
                        const numericAmount = parseFloat(
                          paymentAmount / 100,
                        ).toFixed(2);
                        const formattedAmount =
                          formatNumberWithCommas(numericAmount);

                        setPaymentAmountDisplay(formattedAmount);
                      }
                    }}
                    error={paymentDetailsErrors.amount}
                    helperText={
                      paymentDetailsErrors.amount
                        ? "Please enter a valid amount"
                        : ""
                    }
                  />
                  <StyledButton
                    primary
                    fontSize={"1.2rem"}
                    onClick={() => {
                      const errors = validatePaymentDetails({
                        date: paymentDate,
                        amount: paymentAmount,
                        setErrorState: setPaymentDetailsErrors,
                      });

                      if (Object.values(errors).some((error) => error)) {
                        return;
                      }

                      setOpenPaymentTool(true);
                      setRecordPaymentModalOpen(false);
                      const paymentDetails = {
                        date: { $d: paymentDate },
                        amount: paymentAmount * -1,
                      };
                      // console.log(paymentDetails);
                      setPaymentToProcess(paymentDetails);
                    }}>
                    Record Payment
                  </StyledButton>
                </ModalInner>
              </ModalBox>
            </Modal>
            {/*
            <Modal
              open={openEnterPlaidDetails}
              onClose={() => setOpenEnterPlaidDetails(false)}>
              <ModalBox>
                <ModalInner>
                  <TitleText>Enter Transation Details</TitleText>
                  <h2>{`This transaction was imported from your account by Plaid. You have ${acceptedTx.length} transactions left to register.`}</h2>
                  <EnterBankingTransaction
                    selectedAccount={selectedAccount}
                    txToComplete={txToComplete}
                    closeModal={() => setOpenEnterPlaidDetails(false)}
                    location={["plaidImport", "creditCards"]}
                  />
                </ModalInner>
              </ModalBox>
            </Modal>
            */}
            {orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ? (
              <EnterTxWrapper id="enter-transactions-wrapper">
                <EnterBankingTransaction
                  key={`credit-${selectedAccount}`}
                  selectedAccount={selectedAccount}
                  location={["creditCards"]}
                />
              </EnterTxWrapper>
            ) : (
              <div id="enter-transactions-wrapper">
                <TitleText style={{ color: "grey", padding: "30px" }}>
                  {`Unable to enter transactions as ${orgRoles.join(" ")}.`}
                </TitleText>
              </div>
            )}
            <RegisterTransactions
              height={tableWrapperHeight}
              filterAccount={selectedAccount}
            />
          </div>
        ) : (
          <TitleText>Please add a Credit Card...</TitleText>
        )}
      </CenteringDiv>
    </ComponentPageWrapper>
  );
};

const CreditCards = withSubscriptionProtection(CreditCardsProtected);

export default CreditCards;
