import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  primary_color,
  primary_dark_color,
  primary_light_color,
  tertiary_color,
  secondary_color,
  light_grey,
} from "../constants/color_constants";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

export const StyledColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  alight-items: center;
  height: 100%;
`;

export const StyledRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const ComponentTile = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) =>
    props.left && props.top
      ? primary_dark_color
      : props.top && !props.middle
      ? primary_light_color
      : props.left
      ? tertiary_color
      : props.middle && !props.top
      ? secondary_color
      : light_grey};
    color: ${(props) =>
      props.left && props.top ? primary_color : primary_dark_color};
  border-radius: 10px;
  min-width: ${(props) => (props.three ? "24vw" : "38vw")};
  min-height: 40vh;
  margin-right: ${(props) => (props.left ? "0" : "3vw")};
  margin-left: ${(props) => (props.right ? "0" : "3vw")};
  margin-top: 5vh;
  margin-bottom: ${(props) => (props.top ? "0" : "5vh")}};
  cursor: pointer;
`;

export const WhiteText = styled.p`
  color: white;
  weight: bold;
  font-size: 1.4rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
`;

export const WhiteButton = styled(Link)`
  text-decoration: none;
  border: 2px solid white;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const WhiteArrow = styled(ChevronRightOutlinedIcon)`
  color: white;
  margin-right: 1rem;
`;
