import styled from "@emotion/styled";
import { Switch, TextField } from "@mui/material";
import {
  primary_highlight_color,
  primary_dark_color,
  primary_light_color,
} from "../../constants/color_constants.jsx";

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${primary_highlight_color}; // Color of the label when focused
  }
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primary_highlight_color};
      border-width: 2px;
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${primary_highlight_color};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${primary_highlight_color};
  }
`;

export const LineText = styled.p`
  font-size: 1rem;
  font-family: "MontserratBold";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: left;
`;

export const StyledTableRow = styled.tr`
  padding-right: 10px;
  border-radius: 1rem;
  background-color: ${(props) =>
    props.index % 2 === 0
      ? `${primary_light_color}`
      : props.header
      ? `${primary_dark_color}`
      : `#FFFFF`};
`;

export const StyledHeaderText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  text-align: left;
  color: white;
  padding: 8px;
  margin: 0px;
`;

export const StyledHeader = styled.th`
  font-size: 1.6rem;
  font-family: "MontserratBold";
  text-align: left;
  color: white;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

export const StyledTableItem = styled.td`
  font-size: 1rem;
  font-family: "MontserratMed";
  text-align: left;
  color: ${primary_dark_color};
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  &:first-of-type {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse; /* Removes spacing between cells */
  border-radius: 4px; /* Adds a subtle rounded corner */
  overflow: hidden; /* Hides any overflow, allowing the border-radius to work */
  /* Add other styling here if needed */
`;
