//This function takes in that transactions array, the errors arrays, and some options such as how many transactions to display, and returns an array of index values for the transactions to display
export const filterTransactions = (
  initialImportData,
  unbalancedTX,
  transactionParsingErrors,
  transactionsMatchErrors,
  numberToDisplay,
  options,
) => {
  let transactionsToDisplay = [];
  let index = 0;

  // First, add transactions with fund errors in transactionsMatchErrors
  while (
    transactionsToDisplay.length < numberToDisplay &&
    index < initialImportData.length
  ) {
    if (transactionsMatchErrors[index].some((line) => line.fund)) {
      transactionsToDisplay.push(index);
    }
    index++;
  }

  // Reset index to consider other errors if the desired number is not yet reached
  index = 0;

  // Then, consider other errors
  while (
    transactionsToDisplay.length < numberToDisplay &&
    index < initialImportData.length
  ) {
    if (
      unbalancedTX[index] ||
      (transactionsMatchErrors[index].some((line) => line.account) &&
        !transactionsMatchErrors[index].some((line) => line.fund)) ||
      transactionParsingErrors[index]
    ) {
      // Avoid adding duplicate transactions
      if (!transactionsToDisplay.includes(index)) {
        transactionsToDisplay.push(index);
      }
    }
    index++;
  }

  if (transactionsToDisplay.length === 0) {
    transactionsToDisplay = initialImportData
      .slice(0, numberToDisplay)
      .map((_, index) => index);
  }

  return transactionsToDisplay;
};
