import React, { useEffect, useState, useContext } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/use-auth.js";
import {
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  LineText,
  StyledTableRow,
  StyledTableHeaderColumn,
  StyledHeaderText,
  StyledTableItem,
  ModalInner,
  StyledTitleText,
  StyledTbody,
  ModalBox,
  ModalBoxLight,
  StyledTable,
  TableWrapperDiv,
  TableWrapperInnerDiv,
  ExpandCollapsedButton,
} from "./register_transactions_styles.jsx";

import {
  convertToDate,
  findAccountById,
  returnCurrency,
} from "../../../utilities/general_util.jsx";
import UserContext from "../../../assets/user_context.jsx";
import { CircleOutlined, TaskAltOutlined } from "@mui/icons-material";
import SortIcon from "@mui/icons-material/Sort";
import { ConfirmAction } from "../../../assets/controls.jsx";
import { primary_dark_color } from "../../../constants/color_constants.jsx";
import EnterTransaction from "../../transactions/enter_transaction/enter_transaction.jsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { sortTransactions } from "../../transactions/sort_functions.js";
import InspectTransaction from "../banking/inspect_transaction.jsx";
import EnterBankingTransaction from "../banking/enter_bank_transaction.jsx";
import { TitleText } from "../../component_styles.jsx";
import {
  DateRangeWrapper,
  LabelText,
  TopOptionsWrapper,
} from "../../transactions/transactions_styles.jsx";
import { useWindowSize } from "../../../utilities/responsive_helpers.jsx";
import useFilterAndProcessTransactionsForDisplay from "./transactions_processing_for_display.js";

const RegisterTransactions = ({
  height,
  filterAccount,
  clearedTx,
  clickedClear,
  setAllClearedFunction,
}) => {
  const {
    user,
    org,
    showLoading,
    setIsChildDataLoaded,
    accounts,
    funds,
    contacts,
    transactions,
    dateRangeForTx,
    setDateRangeForTx,
    orgRoles,
    setDeleteQueue,
    deleteQueue,
    userData,
  } = useContext(UserContext);

  //Redirect to signin if props.authUser is null
  const redirect = useNavigate();
  useEffect(() => {
    if (!showLoading) {
      if (user === null) {
        // console.log("Should redirect to SIGN_IN");
        redirect("/signin");
      }
    }
  }, [user, showLoading, redirect]);

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [transactionIdToRemove, setTransactionIdToRemove] = useState({
    id: null,
    label: "none",
  });
  const [transactionToInspect, setTransactionToInspect] = useState(null);

  const [filteredTransactionsData, setFilteredTransactionsData] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [journalEntryToEdit, setJournalEntryToEdit] = useState({});
  const [journalEntryEditModal, setJournalEntryEditModal] = useState(false);
  const [
    bankingTransactionEntryEditModal,
    setBankingTransactionEntryEditModal,
  ] = useState(false);

  const [sortBy, setsortBy] = useState("date");

  const [transactionHovered, setTransactionHovered] = useState(null);
  const [collapsedSplits, setCollapsedSplits] = useState(true);
  const [expandedSplitId, setExpandedSplitId] = useState(null);

  const authHook = useAuth();

  // console.log("MonthlyAggs: ", monthlyAggregates); //For Testing

  useEffect(() => {
    if (transactions.length > 0) {
      setIsChildDataLoaded(true);
    }
  }, [setIsChildDataLoaded, transactions]);

  const handleSearch = (event) => {
    setSearchString(event.target.value);
  };

  useFilterAndProcessTransactionsForDisplay({
    filterAccount,
    dateRangeForTx,
    setFilteredTransactionsData,
    searchString,
  });

  let transactionsHeader = [];
  if (
    filterAccount?.length > 0 &&
    accounts.find((account) => account.id === filterAccount).accountType ===
      "Assets" &&
    accounts.find((account) => account.id === filterAccount).isRegister
  ) {
    transactionsHeader = [
      "Date",
      "Contact",
      "Account",
      "Fund",
      "Outflow",
      "Inflow",
      "Clear",
      "Balance",
    ];
  } else if (
    filterAccount &&
    accounts.find((account) => account.id === filterAccount).accountType ===
      "Liabilities" &&
    accounts.find((account) => account.id === filterAccount).isRegister === true
  ) {
    transactionsHeader = [
      "Date",
      "Contact",
      "Account",
      "Fund",
      "Charge",
      "Payment",
      "Clear",
      "Balance",
    ];
  }

  const recordRemoval = async (data) => {
    setDeleteQueue((prevState) => [...prevState, data.id]);
    setTransactionToInspect(null);
    const docRef = doc(authHook.db, "orgs", org, "journalEntries", data.id);
    await updateDoc(
      docRef,
      { toDelete: true, userMadeChange: user.uid },
      { merge: true },
    );
  };

  const { winHeight } = useWindowSize();
  const size = winHeight > 800 ? "medium" : "small";

  return (
    <div>
      {/* This is to edit journal entries, for complex transactions */}
      <Modal
        open={journalEntryEditModal}
        onClose={() => {
          setJournalEntryEditModal(false);
          setJournalEntryToEdit({});
        }}>
        <ModalBoxLight>
          <ModalInner>
            <EnterTransaction
              db={authHook.db}
              authUser={user}
              org={org}
              stillLoading={showLoading}
              accounts={accounts}
              funds={funds}
              entryToEdit={journalEntryToEdit}
              closeModal={() => setJournalEntryEditModal(false)}
              scrollable
            />
          </ModalInner>
        </ModalBoxLight>
      </Modal>

      {/* This is to edit banking transactions that only have one line for this register */}
      <Modal
        open={bankingTransactionEntryEditModal}
        onClose={() => {
          setBankingTransactionEntryEditModal(false);
          setJournalEntryToEdit({});
        }}>
        <ModalBoxLight>
          <ModalInner>
            <TitleText>Edit Banking Transaction</TitleText>
            <EnterBankingTransaction
              key={`enterTx-banking-account-${filterAccount}`}
              selectedAccount={filterAccount}
              txToEdit={journalEntryToEdit}
              closeModal={() => setBankingTransactionEntryEditModal(false)}
            />
          </ModalInner>
        </ModalBoxLight>
      </Modal>

      <Modal
        open={transactionToInspect !== null}
        onClose={() => setTransactionToInspect(null)}>
        <ModalInner>
          <ModalBox>
            <InspectTransaction
              transaction={transactionToInspect}
              contacts={contacts}
              accounts={accounts}
              funds={funds}
              filterAccount={filterAccount}
              setTransaction={setTransactionToInspect}
              setJournalEntryEditModal={setJournalEntryEditModal}
              setJournalEntryToEdit={setJournalEntryToEdit}
              setBankingTransactionEntryEditModal={
                setBankingTransactionEntryEditModal
              }
              setTransactionIdToRemove={setTransactionIdToRemove}
              setConfirmRemoveOpen={setConfirmRemoveOpen}
              orgRoles={orgRoles}
              userData={userData}
            />
          </ModalBox>
        </ModalInner>
      </Modal>

      {/* Modal for Adding many Transactions through import feature */}
      <ConfirmAction
        dataForAction={transactionIdToRemove}
        callback={(data) => recordRemoval(data)}
        openState={confirmRemoveOpen}
        handleCloseState={() => setConfirmRemoveOpen(false)}>
        Remove Transaction
      </ConfirmAction>

      <TopOptionsWrapper
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          maxWidth: "1750px",
        }}>
        <TextField
          sx={{ width: "350px", fontSize: "22px", margin: "5px" }}
          label="Search Transactions"
          name="search"
          variant="outlined"
          color="secondary"
          value={searchString}
          onChange={handleSearch}
          size={size}
        />
        {/* Checkbox with label for showing only journal entries */}
        <FormControlLabel
          control={
            <Checkbox
              checked={collapsedSplits}
              onChange={() => setCollapsedSplits(!collapsedSplits)}
              color="secondary"
              sx={
                winHeight > 800
                  ? { "& .MuiSvgIcon-root": { fontSize: 28 } }
                  : {
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }
              }
            />
          }
          label={
            <span style={{ fontFamily: "MontserratMed", whiteSpace: "nowrap" }}>
              <LabelText>Collapse Split Transactions</LabelText>
            </span>
          }
        />
        <DateRangeWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Transactions After"
              value={dateRangeForTx.firstDate}
              onChange={(newValue) => {
                setDateRangeForTx({
                  firstDate: newValue,
                  lastDate: dateRangeForTx.lastDate,
                });
              }}
              renderInput={(params) => (
                <TextField style={{ padding: "5px" }} {...params} />
              )}
              slotProps={{ textField: { size: size } }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Transactions Before"
              value={dateRangeForTx.lastDate}
              onChange={(newValue) => {
                setDateRangeForTx({
                  firstDate: dateRangeForTx.firstDate,
                  lastDate: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField x={{ padding: "5px" }} {...params} />
              )}
              slotProps={{ textField: { size: size } }}
            />
          </LocalizationProvider>
        </DateRangeWrapper>
      </TopOptionsWrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        {filteredTransactionsData.length > 0 ? (
          <TableWrapperDiv height={height}>
            <TableWrapperInnerDiv>
              <StyledTable>
                <thead>
                  <StyledTableRow header>
                    {filteredTransactionsData.length > 0 &&
                      transactionsHeader.map((header) => {
                        if (!(header === "Balance" && setAllClearedFunction)) {
                          return (
                            <StyledTableHeaderColumn
                              header={header}
                              isBankRec={setAllClearedFunction ? true : false}
                              key={`${header}-header`}
                              onClick={() => {
                                if (header === "Date") {
                                  setsortBy(
                                    sortBy === "date" ? "inverseDate" : "date",
                                  );
                                }
                                if (
                                  header === "Debit" ||
                                  header === "Inflow" ||
                                  header === "Payment"
                                ) {
                                  setsortBy(
                                    sortBy === "debit"
                                      ? "inverseDebit"
                                      : "debit",
                                  );
                                }
                                if (
                                  header === "Credit" ||
                                  header === "Outflow" ||
                                  header === "Charge"
                                ) {
                                  setsortBy(
                                    sortBy === "credit"
                                      ? "inverseCredit"
                                      : "credit",
                                  );
                                }
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}>
                                {!(
                                  header === "Balance" && setAllClearedFunction
                                ) && (
                                  <StyledHeaderText
                                    amount
                                    key={`${header}-headerText`}
                                    header={header}
                                    isBankRec={
                                      setAllClearedFunction ? true : false
                                    }>
                                    {header}
                                  </StyledHeaderText>
                                )}{" "}
                                {header === "Date" &&
                                  (sortBy === "date" ||
                                    sortBy === "inverseDate") && (
                                    <SortIcon
                                      style={
                                        sortBy === "date"
                                          ? {
                                              color: "white",
                                              cursor: "pointer",
                                            }
                                          : {
                                              color: "white",
                                              transform: "rotate(180deg)",
                                              cursor: "pointer",
                                            }
                                      }
                                      fontSize="large"
                                    />
                                  )}
                                {(header === "Debit" ||
                                  header === "Inflow" ||
                                  header === "Payment") &&
                                  (sortBy === "debit" ||
                                    sortBy === "inverseDebit") && (
                                    <SortIcon
                                      style={
                                        sortBy === "debit"
                                          ? {
                                              color: "white",
                                              cursor: "pointer",
                                            }
                                          : {
                                              color: "white",
                                              transform: "rotate(180deg)",
                                              cursor: "pointer",
                                            }
                                      }
                                      fontSize="large"
                                    />
                                  )}
                                {(header === "Credit" ||
                                  header === "Outflow" ||
                                  header === "Charge") &&
                                  (sortBy === "credit" ||
                                    sortBy === "inverseCredit") && (
                                    <SortIcon
                                      style={
                                        sortBy === "credit"
                                          ? {
                                              color: "white",
                                              cursor: "pointer",
                                            }
                                          : {
                                              color: "white",
                                              transform: "rotate(180deg)",
                                              cursor: "pointer",
                                            }
                                      }
                                      fontSize="large"
                                    />
                                  )}
                                {header === "Clear" &&
                                  setAllClearedFunction && (
                                    <Tooltip title="Clear all Transactions">
                                      <TaskAltOutlined
                                        onClick={() => setAllClearedFunction()}
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                        fontSize="medium"
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            </StyledTableHeaderColumn>
                          );
                        } else {
                          return null;
                        } //Don't show the balance column if we are in the reconciliation tool
                      })}
                  </StyledTableRow>
                </thead>
                <StyledTbody>
                  {filteredTransactionsData
                    .filter((transaction) => {
                      if (!clearedTx) {
                        //The component is not being use in reconcilliation
                        return true;
                      } else {
                        //The component is being used in reconcilliation, don't show previously cleared tx
                        return !transaction.lines
                          .filter((line) => line.account === filterAccount)
                          .some((line) => line.cleared);
                      }
                    })
                    .map((transaction, transactionIndex) => {
                      //Remove the lines for this account, since they are assumed with the user is viewing this account
                      let transactionLines = transaction.lines.filter(
                        (line) => {
                          return (
                            !filterAccount || line.account !== filterAccount
                          );
                        },
                      );
                      const transactionForDeletion =
                        deleteQueue.length > 0 &&
                        deleteQueue.includes(transaction.id);
                      let isCollapsed = false;

                      //Collapse the splits if there are more than one line for this transaction and the user has collapsed them
                      if (
                        collapsedSplits &&
                        transactionLines.length > 1 &&
                        expandedSplitId !== transaction.id
                      ) {
                        isCollapsed = true;
                        const inputTransactionLines = transactionLines;
                        const amountCredit = inputTransactionLines
                          .filter((line) => line.sign === "credit")
                          .reduce((acc, line) => acc + line.amount, 0);
                        const amountDebit = inputTransactionLines
                          .filter((line) => line.sign === "debit")
                          .reduce((acc, line) => acc + line.amount, 0);
                        // console.log(
                        //   "Amount Credit: ",
                        //   amountCredit,
                        //   "Amount Debit: ",
                        //   amountDebit,
                        // );
                        transactionLines = [
                          {
                            ...inputTransactionLines.slice(0, 1)[0],
                            sign:
                              amountCredit > amountDebit ? "credit" : "debit",
                            amount: Math.abs(amountCredit - amountDebit),
                            account: inputTransactionLines.every(
                              (line) =>
                                line.account ===
                                inputTransactionLines[0].account,
                            )
                              ? inputTransactionLines[0].account
                              : "split",
                            fund: inputTransactionLines.every(
                              (line) =>
                                line.fund === inputTransactionLines[0].fund,
                            )
                              ? inputTransactionLines[0].fund
                              : "split",
                            contact: inputTransactionLines.every(
                              (line) =>
                                line.contact ===
                                inputTransactionLines[0].contact,
                            )
                              ? inputTransactionLines[0].contact
                              : "split",
                          },
                        ];
                        // console.log(filterAccount, transaction);
                        // console.log("Split Input: ", inputTransactionLines);
                        // console.log("Split Output: ", transactionLines);
                      }

                      return transactionLines.map((line, lineIndex) => {
                        return (
                          <StyledTableRow
                            //Add hover action at some point, not performant, so need to memoize with useMemo on all children that use this
                            // onMouseEnter={() =>
                            //   setTransactionHovered(transaction.id)
                            // }
                            // onMouseLeave={() => setTransactionHovered(null)}
                            key={`${transaction.id}-transID-${lineIndex}`}
                            index={transactionIndex}
                            isFirst={lineIndex === 0}
                            isLast={lineIndex === transactionLines.length - 1}
                            isSingle={transactionLines.length === 1}
                            hovered={transaction.id === transactionHovered}
                            optimistic={transaction.optimistic}
                            onClick={() => {
                              if (!transaction.optimistic) {
                                const transactionFromOriginal =
                                  transactions.find(
                                    (transactionFromAll) =>
                                      transactionFromAll.id === transaction.id,
                                  );
                                console.log(
                                  filterAccount,
                                  transactionFromOriginal,
                                );
                                setTransactionToInspect(
                                  transactionFromOriginal,
                                );
                              }
                            }}>
                            {lineIndex === 0 && (
                              <StyledTableItem
                                attribute="Date"
                                isFirst={lineIndex === 0}
                                isLast={
                                  lineIndex === transactionLines.length - 1
                                }
                                //isSingle={transactionLines.length === 1}
                                isSingle={true} //Due to row span, we need to count all as singles now
                                rowSpan={transactionLines.length}>
                                {lineIndex === 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}>
                                    {isCollapsed ||
                                    transaction.id === expandedSplitId ? (
                                      <Tooltip title="Expand Split Transaction">
                                        <ExpandCollapsedButton
                                          isExpanded={
                                            expandedSplitId === transaction.id
                                          }
                                          fontSize="small"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (
                                              expandedSplitId === transaction.id
                                            ) {
                                              setExpandedSplitId(null);
                                            } else {
                                              setExpandedSplitId(
                                                transaction.id,
                                              );
                                            }
                                          }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <div
                                        style={
                                          collapsedSplits
                                            ? { width: "20px" }
                                            : { width: "5px" }
                                        }
                                      />
                                    )}

                                    <LineText
                                      hovered={
                                        transaction.id === transactionHovered
                                      }
                                      style={
                                        transactionForDeletion
                                          ? {
                                              textDecoration: "line-through",
                                              color: "grey",
                                              marginLeft: "0",
                                            }
                                          : transaction.optimistic
                                          ? {
                                              color: "grey",
                                              marginLeft: "0",
                                            }
                                          : { marginLeft: "0" }
                                      }>
                                      {convertToDate(transaction.date).format(
                                        "MM/DD/YYYY",
                                      )}
                                    </LineText>
                                  </div>
                                )}
                              </StyledTableItem>
                            )}
                            <Tooltip
                              title={
                                line.contact !== null &&
                                contacts.find(
                                  (contact) => contact.id === line.contact,
                                )?.shortName
                                  ? contacts.find(
                                      (contact) => contact.id === line.contact,
                                    ).shortName
                                  : "Journal Entry"
                              }>
                              <StyledTableItem attribute="Contact">
                                <LineText
                                  attribute="Contact"
                                  hovered={
                                    transaction.id === transactionHovered
                                  }
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {line.contact !== null &&
                                  contacts.find(
                                    (contact) => contact.id === line.contact,
                                  )?.shortName
                                    ? contacts.find(
                                        (contact) =>
                                          contact.id === line.contact,
                                      ).shortName
                                    : "Journal Entry"}
                                </LineText>
                              </StyledTableItem>
                            </Tooltip>

                            <Tooltip
                              title={
                                line.account === "split"
                                  ? "Split Transaction"
                                  : `${
                                      accounts.find(
                                        (account) =>
                                          account.id === line.account,
                                      ).accountNumber
                                    } - ${
                                      accounts.find(
                                        (account) =>
                                          account.id === line.account,
                                      ).accountName
                                    }`
                              }>
                              <StyledTableItem attribute="Account">
                                <LineText
                                  attribute="Account"
                                  hovered={
                                    transaction.id === transactionHovered
                                  }
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {line.account === "split"
                                    ? "Split Transaction"
                                    : `${
                                        accounts.find(
                                          (account) =>
                                            account.id === line.account,
                                        ).accountNumber
                                      } - ${
                                        accounts.find(
                                          (account) =>
                                            account.id === line.account,
                                        ).accountName
                                      }`}
                                </LineText>
                              </StyledTableItem>
                            </Tooltip>

                            <Tooltip
                              title={
                                line.fund === "split"
                                  ? "Split Transaction"
                                  : funds.find((fund) => fund.id === line.fund)
                                      .fundName
                              }>
                              <StyledTableItem attribute="Fund">
                                <LineText
                                  attribute="Fund"
                                  hovered={
                                    transaction.id === transactionHovered
                                  }
                                  style={
                                    transactionForDeletion
                                      ? {
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }
                                      : transaction.optimistic
                                      ? { color: "grey" }
                                      : null
                                  }>
                                  {line.fund === "split"
                                    ? "Split Transaction"
                                    : `${
                                        funds.find(
                                          (fund) => fund.id === line.fund,
                                        ).fundNumber
                                      } - ${
                                        funds.find(
                                          (fund) => fund.id === line.fund,
                                        ).fundName
                                      }`}
                                </LineText>
                              </StyledTableItem>
                            </Tooltip>

                            {line.sign === "credit" ? (
                              <React.Fragment>
                                <StyledTableItem attribute={"Amount"} />
                                {/* Blank div if there is only a debit for this line */}

                                <StyledTableItem attribute={"Amount"}>
                                  <LineText
                                    hovered={
                                      transaction.id === transactionHovered
                                    }
                                    key={`${transaction.id}-${line.amount}-${lineIndex}-Amount`}
                                    style={
                                      transactionForDeletion
                                        ? {
                                            textDecoration: "line-through",
                                            color: "grey",
                                          }
                                        : transaction.optimistic
                                        ? { color: "grey" }
                                        : null
                                    }>
                                    {returnCurrency(line.amount)}
                                  </LineText>
                                </StyledTableItem>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <StyledTableItem attribute={"Amount"}>
                                  <LineText
                                    hovered={
                                      transaction.id === transactionHovered
                                    }
                                    attribute={"Amount"}
                                    key={`${transaction.id}-${line.amount}-${lineIndex}-Amount`}
                                    style={
                                      transactionForDeletion
                                        ? {
                                            textDecoration: "line-through",
                                            color: "grey",
                                          }
                                        : transaction.optimistic
                                        ? { color: "grey" }
                                        : null
                                    }>
                                    {returnCurrency(line.amount)}
                                  </LineText>
                                </StyledTableItem>
                                <StyledTableItem attribute={"Amount"} />
                                {/* Blank div if there is only a credit for this line */}
                              </React.Fragment>
                            )}
                            <StyledTableItem
                              attribute="Clear"
                              isFirst={lineIndex === 0}
                              isLast={lineIndex === transactionLines.length - 1}
                              isSingle={transactionLines.length === 1}
                              isBankRec={setAllClearedFunction ? true : false}>
                              {clearedTx ? (
                                //clearedTx is present only when in the reconciliation tool
                                clearedTx.includes(transaction.id) ? (
                                  <TaskAltOutlined
                                    style={{
                                      color: primary_dark_color,
                                      cursor: "pointer",
                                    }}
                                    fontSize="medium"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      clickedClear({
                                        id: transaction.id,
                                        action: "unclear",
                                      });
                                    }}
                                  />
                                ) : (
                                  <CircleOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      clickedClear({
                                        id: transaction.id,
                                        action: "clear",
                                      });
                                    }}
                                    fontSize="medium"
                                    style={{
                                      color: primary_dark_color,
                                      cursor: "pointer",
                                    }}
                                  />
                                )
                              ) : transaction.lines.some(
                                  (line) =>
                                    line.account === filterAccount &&
                                    line.cleared === true,
                                ) ? (
                                <TaskAltOutlined
                                  style={{ color: primary_dark_color }}
                                  fontSize="medium"
                                />
                              ) : null}
                            </StyledTableItem>
                            {lineIndex === 0 && !setAllClearedFunction && (
                              <StyledTableItem
                                attribute={"runningTotal"}
                                isFirst={lineIndex === 0}
                                isLast={
                                  lineIndex === transactionLines.length - 1
                                }
                                // isSingle={transactionLines.length === 1}
                                isSingle={true} //Due to row span, we need to count all as singles now
                                rowSpan={transactionLines.length}>
                                {line.runningTotal !== null &&
                                  lineIndex === 0 &&
                                  !clearedTx?.length > 0 && (
                                    <LineText
                                      hovered={
                                        transaction.id === transactionHovered
                                      }
                                      attribute={"runningTotal"}
                                      key={`${transaction.id}-${line.amount}-${lineIndex}-Amount`}
                                      style={
                                        transactionForDeletion
                                          ? {
                                              textDecoration: "line-through",
                                              color: "grey",
                                            }
                                          : transaction.optimistic
                                          ? { color: "grey" }
                                          : line.runningTotal < 0
                                          ? { color: "red" }
                                          : null
                                      }>
                                      {returnCurrency(line.runningTotal)}
                                    </LineText>
                                  )}
                              </StyledTableItem>
                            )}
                          </StyledTableRow>
                        );
                      });
                    })}
                </StyledTbody>
              </StyledTable>
            </TableWrapperInnerDiv>
          </TableWrapperDiv>
        ) : (
          <StyledTitleText>No Transactions Found in Date Range</StyledTitleText>
        )}
      </div>
    </div>
  );
};

export default RegisterTransactions;
