import styled from "@emotion/styled";
import { TableHead, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {
  primary_highlight_color,
  primary_light_color,
  secondary_highlight_color,
} from "../../constants/color_constants";

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
`;

export const ClickableDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const LineText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: black;
  text-align: center;
`;

export const TypeText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: black;
  text-align: flex-start;
  margin-left: 1rem;
`;

export const AddDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
`;

export const GroupText = styled.p`
  font-size: 1rem;
  font-family: "MontserratBold";
  color: black;
  text-align: flex-start;
`;

export const TitleText = styled.p`
  font-size: 1.5rem;
  font-family: "MontserratBold";
  color: black;
  text-align: center;
`;

export const TypeCell = styled(TableCell)`
  background-color: ${primary_highlight_color};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
`;

export const GroupCell = styled(TableCell)`
  background-color: ${primary_light_color};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
`;

export const PageWrapper = styled.div`
  width: 91vw;
  margin-left: 1vw;
`;

export const HeaderText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: black;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #e8e8e8;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: #bbd7d9;
`;
