import React, { useContext, useState, useEffect } from "react";
import {
  DescriptiveText,
  ThemedTitleText,
  TitleText,
} from "./onboarding_styles";
import { CenteringDiv } from "../../assets/containers";
import UserContext from "../../assets/user_context";
import StartingBalance, {
  addJournalEntry,
} from "../foundation/manage_accounts/starting_balance";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StyledButton from "../../assets/buttons";
import { useAuth } from "../../services/use-auth";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { ReactComponent as LoadingLogo } from "../../assets/logos/animated-logo-light.svg";
import styled from "@emotion/styled";

export const LoadingLogoStyled = styled(LoadingLogo)`
  height: 22vh;
`;

const StartingBalancesOnboarding = () => {
  const { transactions, accounts, funds, user, org } = useContext(UserContext);
  const authHook = useAuth();
  const [entryData, setEntryData] = useState({
    startingBalance: "",
    startingBalanceDate: null,
  });
  const [entryErrors, setEntryErrors] = useState({ startingBalance: false });
  const [entryFeedback, setEntryFeedback] = useState({ startingBalance: "" });
  const [accountsWithoutSB, setAccountsWithoutSB] = useState(() => {
    //Find all accounts that have startingBalance === false or undefined
    const accountsWithoutSB = accounts.filter((account) => {
      console.log("account", account);
      return (
        (account.startingBalance === false ||
          account.startingBalance === undefined) &&
        (account.accountType === "Assets" ||
          account.accountType === "Liabilities")
      );
    });
    return accountsWithoutSB;
  });
  const [earliestDate, setEarliestDate] = useState(undefined);
  const [dayBeforeDate, setDayBeforeDate] = useState(undefined);
  const [earliestDateFormatted, setEarliestDateFormatted] = useState(undefined);
  const [dayBeforeDateFormatted, setDayBeforeDateFormatted] =
    useState(undefined);

  const [processingAggregates, setProcessingAggregates] = useState(false);
  const [textWhenProcessingAgg, setTextWhenProcessingAgg] = useState(
    "Putting everything in place...",
  );

  //TODO: Add validation and error handling, then when all starting balances are assigned we need to set onboarding=true for the org

  const darkTheme = createTheme({
    palette: { mode: "dark" },
  });

  useEffect(() => {
    if (entryErrors.startingBalance) {
      validateStartingBalance();
    }
  }, [entryData]);

  //Validate the starting balance of each fund summed is equal to the startingBalance, but only if startingBalance is > 0
  const validateStartingBalance = () => {
    if (entryData.startingBalance > 0) {
      const fundStartingBalances = Object.values(
        entryData.fundStartingBalances,
      );

      const sumOfFundStartingBalances = fundStartingBalances.reduce(
        (acc, curr) => {
          if (curr !== "") {
            return acc + parseFloat(curr || 0);
          } else {
            return acc;
          }
        },
        0,
      );
      console.log("Sum of Fund Starting Balances: ", sumOfFundStartingBalances);
      console.log("Starting Balance: ", parseFloat(entryData.startingBalance));
      if (sumOfFundStartingBalances !== parseFloat(entryData.startingBalance)) {
        setEntryErrors({
          ...entryErrors,
          startingBalance: true,
        });
        setEntryFeedback({
          ...entryFeedback,
          startingBalance: "Fund starting balances must equal starting balance",
        });
      } else {
        setEntryErrors({
          ...entryErrors,
          startingBalance: false,
        });
        setEntryFeedback({
          ...entryFeedback,
          startingBalance: "",
        });
      }
    } else {
      setEntryErrors({
        ...entryErrors,
        startingBalance: false,
      });
      setEntryFeedback({
        ...entryFeedback,
        startingBalance: "",
      });
    }
  };

  useEffect(() => {
    if (processingAggregates) {
      const timer = setTimeout(() => {
        setTextWhenProcessingAgg("You had a lot of transactions...");
      }, 10000);
      const timer2 = setTimeout(() => {
        setTextWhenProcessingAgg("Diving deeper...");
      }, 20000);
      const timer3 = setTimeout(() => {
        setTextWhenProcessingAgg("Just a little longer...");
      }, 30000);
      const timer4 = setTimeout(() => {
        setTextWhenProcessingAgg("Almost there...");
      }, 40000);
      const timer5 = setTimeout(() => {
        setTextWhenProcessingAgg("Diving in... 🚀");
      }, 50000);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
        clearTimeout(timer5);
      };
    }
  }, [processingAggregates]);

  const recordStartingBalance = async () => {
    console.log("entryData", entryData);
    if (entryData.startingBalance > 0) {
      const response = await addJournalEntry({
        accountEntryData: entryData,
        accounts,
        funds,
        accountId: accountsWithoutSB[0].id,
        user,
        org,
        db: authHook.db,
      });
      console.log("response", response);
      //remove it from the accountsWithoutSB state
      setAccountsWithoutSB((prevState) => {
        return prevState.filter(
          (account) => account.id !== accountsWithoutSB[0].id,
        );
      });
      setEntryData((prevState) => {
        return {
          startingBalanceDate: prevState.startingBalanceDate,
          startingBalance: "",
        };
      });
    } else if (
      entryData.startingBalance === 0 ||
      entryData.startingBalance === "0"
    ) {
      console.log("Zero balance");
      //remove it from the accountsWithoutSB state
      const accountRef = doc(
        authHook.db,
        "orgs",
        org,
        "accounts",
        accountsWithoutSB[0].id,
      );
      await updateDoc(
        accountRef,
        {
          startingBalance: true,
        },
        { merge: true },
      );
      setEntryData((prevState) => {
        return {
          startingBalanceDate: prevState.startingBalanceDate,
          startingBalance: "",
        };
      });

      setAccountsWithoutSB((prevState) => {
        return prevState.filter(
          (account) => account.id !== accountsWithoutSB[0].id,
        );
      });
    }
  };

  useEffect(() => {
    //Find the earliest transaction date
    if (transactions.length > 0) {
      const earliestDateFromTx = transactions
        .filter((t) => t.imported)
        .reduce((a, b) => {
          return a.date < b.date ? a : b;
        }).date;
      setEarliestDate(earliestDateFromTx);
      setDayBeforeDate(earliestDateFromTx.subtract(1, "day"));
      setEarliestDateFormatted(
        new Date(earliestDateFromTx).toLocaleDateString(),
      );
      setDayBeforeDateFormatted(
        new Date(earliestDateFromTx.subtract(1, "day")).toLocaleDateString(),
      );
    }
  }, [transactions]);

  useEffect(() => {
    //Change the entryData startingBalanceDate to the earliestDate
    setEntryData((prevState) => {
      return {
        ...prevState,
        //earliestDate is a Day.js object, subtract one day to get the night before
        startingBalanceDate: dayBeforeDate,
      };
    });
  }, [earliestDate]);

  //To be called when the user first finishes importing tx
  const createInitialAggregates = httpsCallable(
    authHook.functions,
    "createInitialMonthlyAggregate",
  );

  return (
    <React.Fragment>
      {accountsWithoutSB.length > 0 && (
        <CenteringDiv>
          <TitleText>Starting Balances</TitleText>
          <DescriptiveText>
            The earliest dated transaction you have is {earliestDateFormatted}.
            Please enter the balances for your accounts the night BEFORE this
            date. In your case that would be {dayBeforeDateFormatted}. These
            will be your starting balances for each account, and must be
            allocated by fund.
          </DescriptiveText>
          <ThemeProvider theme={darkTheme}>
            <ThemedTitleText>
              {`${accountsWithoutSB[0].accountNumber} - ${accountsWithoutSB[0].accountName}`}
            </ThemedTitleText>
            <StartingBalance
              accountEntryData={entryData}
              setAccountEntryData={setEntryData}
              entryErrors={entryErrors}
              entryFeedback={entryFeedback}
              date={earliestDate}
              location={"onboarding"}
              accountsWithoutSB={accountsWithoutSB}
            />
            <div style={{ height: "30px" }} />
            <StyledButton
              height={"40px"}
              fontSize={"1.6rem"}
              bright
              onClick={() => {
                if (entryData.startingBalance > 0) {
                  const fundStartingBalances = Object.values(
                    entryData.fundStartingBalances,
                  );

                  const sumOfFundStartingBalances = fundStartingBalances.reduce(
                    (acc, curr) => {
                      if (curr !== "") {
                        return acc + parseFloat(curr || 0);
                      } else {
                        return acc;
                      }
                    },
                    0,
                  );
                  console.log(
                    "Starting Balance: ",
                    entryData.startingBalance,
                    "Sum of Fund Starting Balances: ",
                    sumOfFundStartingBalances,
                  );
                  if (
                    parseFloat(entryData.startingBalance) !==
                    sumOfFundStartingBalances
                  ) {
                    console.error("Not balanced");
                    validateStartingBalance();
                    return;
                  }
                }
                recordStartingBalance();
              }}>
              Set Balance
            </StyledButton>
          </ThemeProvider>
        </CenteringDiv>
      )}
      {transactions && accountsWithoutSB.length === 0 && (
        <React.Fragment>
          <TitleText>Onboarding Complete</TitleText>
          {!processingAggregates && (
            <DescriptiveText>
              Thanks for walking through our onboarding process, now dive in and
              do some accounting. 🚀
            </DescriptiveText>
          )}
          {processingAggregates && (
            <React.Fragment>
              <DescriptiveText>{textWhenProcessingAgg}</DescriptiveText>
              <LoadingLogoStyled />
            </React.Fragment>
          )}
          <div style={{ height: "30px" }} />
          <StyledButton
            bright
            disabled={processingAggregates}
            onClick={async () => {
              setProcessingAggregates(true);
              try {
                // Call the cloud function and wait for its completion
                const aggregatesResponse = await createInitialAggregates({
                  orgId: org,
                });

                // Check if the response indicates success
                if (
                  aggregatesResponse.data &&
                  aggregatesResponse.data.success
                ) {
                  // Update the onboarded status only if the aggregate creation was successful
                  await updateDoc(
                    doc(authHook.db, "orgs", org),
                    {
                      onboarded: true,
                    },
                    { merge: true },
                  );
                  console.log(
                    "Aggregates created and onboarded status updated.",
                  );
                } else {
                  // Handle the case where aggregate creation failed
                  console.error(
                    "Failed to create initial aggregates:",
                    aggregatesResponse.data.message,
                  );
                }
              } catch (error) {
                // Handle any errors that occur during the process
                console.error(
                  "Error in creating initial aggregates or updating onboarded status:",
                  error,
                );
                setProcessingAggregates(false);
              }
            }}>
            Let's Go!
          </StyledButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StartingBalancesOnboarding;
