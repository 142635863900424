import React, { useContext, useState } from "react";
import { TableRow, TableCell, Tooltip, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { AccountNameText, AccountNumber } from "./chart_of_accounts_styles";
import UserContext from "../../../assets/user_context";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth";
import {
  AccountBalanceOutlined,
  NoAccountsOutlined,
  NotAccessible,
} from "@mui/icons-material";

const RenderAccountRow = ({
  account,
  accounts,
  typeObj,
  groupObj,
  setEditingData,
  setShowEditModal,
  child,
  parent,
}) => {
  const { org, experimental, setAccounts, orgRoles } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;
  const [changing, setChanging] = useState(false);

  const setAccountEnabled = async (newStatus) => {
    if (!experimental) {
      console.log("Account to Enable/Disable: ", accountData.id, org);
      await updateDoc(doc(db, "orgs", org, "accounts", accountData.id), {
        disabled: newStatus,
      });
      setChanging(false);
    } else {
      console.log(
        "EXPERIMENTAL Account to Enable/Disable: ",
        accountData.id,
        org,
      );
      setAccounts((prev) => {
        const newAccounts = prev.map((a) => {
          if (a.id === accountData.id) {
            return { ...a, disabled: !a.disabled };
          } else {
            return a;
          }
        });
        return newAccounts;
      });
      setChanging(false);
    }
  };

  const identifier = child ? account : account.id;
  // Find the account data once
  const accountData = accounts.find((a) => a.id === identifier);
  let parentAccount;
  if (child) {
    //Get Parent Account
    parentAccount = accounts.find((a) => a.id === parent.id);
  } else {
    parentAccount = null;
  }

  // Function to determine the account type
  const getAccountType = () => {
    if (accountData) {
      if (accountData.isRegister) {
        return (
          <Tooltip title="Banking Account">
            <AccountBalanceOutlined />
          </Tooltip>
        );
      }
      if (accountData.isPayableReceivable) {
        if (accountData.accountType === "Income") {
          return "Accounts Receivable";
        }
        if (accountData.accountType === "Expenses") {
          return "Accounts Payable";
        }
      }
      return "";
    }
  };

  return (
    <React.Fragment>
      {account && accountData && (
        <TableRow
          key={`${accountData.accountNumber}-account-row`}
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
          }}>
          <TableCell
            sx={{
              paddingLeft: child ? "110px" : "85px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            component="th"
            scope="row">
            {child ? (
              <Tooltip title={`Sub Account of ${parentAccount.accountName}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SubdirectoryArrowRightIcon style={{ marginRight: "10px" }} />

                  <AccountNumber>{accountData.accountNumber}</AccountNumber>
                </div>
              </Tooltip>
            ) : (
              <AccountNumber>{accountData?.accountNumber}</AccountNumber>
            )}
          </TableCell>

          <TableCell
            align="left"
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            <AccountNameText>{accountData?.accountName}</AccountNameText>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            {getAccountType()}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            {typeObj.type !== "Equity" &&
            orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ? (
              <Tooltip title={`Edit ${accountData?.accountName}`}>
                <EditIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const dataForAccountToEdit = {
                      accountType: typeObj.type,
                      group: groupObj.groupName,
                      accountNumber: accountData.accountNumber,
                      accountName: accountData.accountName,
                      accountDatabaseId: identifier,
                      isRegister: accountData.isRegister || false,
                      isPayableReceivable:
                        accountData.isPayableReceivable || false,
                      subAccount: child,
                      subAccountOf: child ? parentAccount : null,
                      disabled: accountData.disabled,
                    };
                    console.log("Account to Edit: ", dataForAccountToEdit);
                    setEditingData(dataForAccountToEdit);
                    setShowEditModal(true);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={`No editing permissions as ${orgRoles.join(" ")}.`}>
                <NoAccountsOutlined />
              </Tooltip>
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}>
            {orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ? (
              <Switch
                color="secondary"
                checked={!accountData.disabled}
                disabled={changing}
                onChange={() => {
                  setChanging(true);
                  setAccountEnabled(!accountData.disabled);
                }}
              />
            ) : (
              <Tooltip
                title={`No editing permissions as ${orgRoles.join(" ")}.`}>
                <NoAccountsOutlined />
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default RenderAccountRow;
