import React, { useState, useContext } from "react";
import UserContext from "../../assets/user_context.jsx";
import {
  ComponentWrapper,
  AddButton,
  StyledSearch,
  ModalInner,
  ModalBox,
  TopRowDiv,
} from "../component_styles.jsx";
import { Box, Modal, Tooltip } from "@mui/material";
import { useAuth } from "../../services/use-auth.js";
import {
  LineText,
  StyledTableRow,
  StyledTableItem,
  StyledTable,
  StyledHeaderText,
  StyledHeader,
} from "./contacts_styles.jsx";
import {
  Check,
  Close,
  PersonAddDisabled,
  Edit,
  NoAccountsOutlined,
} from "@mui/icons-material";
import AddDonor from "./add_donor.jsx";
import { primary_dark_color } from "../../constants/color_constants.jsx";
import { ConfirmAction } from "../../assets/controls.jsx";

function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length !== 10 || isNaN(phoneNumber)) {
    return "Invalid phone number";
  }

  const areaCode = phoneNumber.slice(0, 3);
  const prefix = phoneNumber.slice(3, 6);
  const line = phoneNumber.slice(6);

  return `(${areaCode}) ${prefix}-${line}`;
}

const Donors = () => {
  const { org, orgRoles, donors } = useContext(UserContext);

  const [search, setSearch] = React.useState("");
  const [openAddDonorModal, setOpenAddDonorModal] = useState(false);

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [donorIdToDisable, setDonorIdToDisable] = useState({
    id: "",
    label: "",
  });
  const [donorToEdit, setDonorToEdit] = useState({});

  const authHook = useAuth();

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const recordDisable = (data) => {
    console.log("Disable donor feature not complete yet:", data);

    setConfirmRemoveOpen(false);
    //Remove the donor from the database
    //Remove the donor from the donors array
  };

  return (
    <React.Fragment>
      <ConfirmAction
        dataForAction={donorIdToDisable}
        callback={(data) => recordDisable(data)}
        openState={confirmRemoveOpen}
        handleCloseState={() => setConfirmRemoveOpen(false)}>
        Disable Donor
      </ConfirmAction>
      <Modal
        open={openAddDonorModal}
        onClose={() => {
          setOpenAddDonorModal(false);
          setDonorToEdit({});
        }}>
        <ModalBox>
          <ModalInner>
            <AddDonor
              handleClose={() => {
                setOpenAddDonorModal(false);
                setDonorToEdit({});
              }}
              db={authHook.db}
              org={org}
              donorToEdit={donorToEdit}
            />
          </ModalInner>
        </ModalBox>
      </Modal>

      <TopRowDiv>
        <Box type="form">
          <StyledSearch
            id="search"
            name="search"
            label="Search"
            value={search}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            size="large"
            style={{ width: "100%" }}
          />
        </Box>
        {orgRoles.some((role) => role === "admin" || role === "bookkeeper") && (
          <AddButton onClick={() => setOpenAddDonorModal(true)}>
            Add Donor
          </AddButton>
        )}
      </TopRowDiv>
      <br />
      <ComponentWrapper style={{ alignItems: "flex-start" }}>
        <StyledTable>
          <thead>
            <StyledTableRow header>
              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Company Name
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Donor Name
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText style={{ marginRight: "40px" }}>
                  Email
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText
                  style={{
                    textAlign: "center",
                    marginLeft: "40px",
                    marginRight: "40px",
                  }}>
                  Phone
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText
                  style={{
                    textAlign: "left",
                    marginLeft: "40px",
                    marginRight: "40px",
                  }}>
                  Address
                </StyledHeaderText>
              </StyledHeader>

              <StyledHeader>
                <StyledHeaderText
                  style={{ marginLeft: "20px", marginRight: "20px" }}>
                  Edit or Delete
                </StyledHeaderText>
              </StyledHeader>
            </StyledTableRow>
          </thead>
          <br />
          <tbody>
            {donors?.length > 0 ? (
              donors
                .filter((donor) => {
                  const searchLower = search.toLowerCase();
                  const donorLower = donor.shortName.toLowerCase();
                  const companyLower = donor?.companyName?.toLowerCase();

                  return (
                    search === "" ||
                    companyLower.includes(searchLower) ||
                    donorLower.includes(searchLower)
                  );
                })
                .map((donor, index) => (
                  <StyledTableRow index={index} key={donor.email}>
                    <StyledTableItem>
                      <Tooltip title={donor.companyName}>
                        <LineText>{donor.shortName}</LineText>
                      </Tooltip>
                    </StyledTableItem>
                    <StyledTableItem>
                      {donor?.firstName && donor?.lastName && (
                        <LineText>
                          {donor.firstName + " " + donor.lastName}
                        </LineText>
                      )}
                    </StyledTableItem>
                    <StyledTableItem>
                      {donor?.email && <LineText>{donor.email}</LineText>}
                    </StyledTableItem>
                    <StyledTableItem>
                      {donor?.phone && (
                        <LineText>{formatPhoneNumber(donor.phone)}</LineText>
                      )}
                    </StyledTableItem>
                    <StyledTableItem>
                      {donor?.city &&
                        donor?.streetAddress &&
                        donor?.state &&
                        donor?.zip && (
                          <LineText
                            style={{
                              marginRight: "40px",
                              marginLeft: "40px",
                            }}>
                            {donor.streetAddress +
                              ", " +
                              donor.city +
                              ", " +
                              donor.state +
                              " " +
                              donor.zip}
                          </LineText>
                        )}
                    </StyledTableItem>

                    <StyledTableItem style={{ textAlign: "center" }}>
                      {orgRoles.some(
                        (role) => role === "admin" || role === "bookkeeper",
                      ) ? (
                        <div>
                          <Edit
                            fontSize="large"
                            sx={{
                              color: primary_dark_color,
                              cursor: "pointer",
                              transition: "transform 500ms", // This line sets up the transition
                              ":hover": {
                                transform: "rotate(360deg)", // This line sets the hover rotation
                              },
                            }}
                            onClick={() => {
                              console.log("Edit", donor);
                              setDonorToEdit(donor);
                              setOpenAddDonorModal(true);
                            }}
                          />
                          <PersonAddDisabled
                            fontSize="large"
                            sx={{
                              color: primary_dark_color,
                              cursor: "pointer",
                              transition: "transform 500ms", // This line sets up the transition
                              ":hover": {
                                transform: "rotate(360deg)", // This line sets the hover rotation
                              },
                            }}
                            onClick={() => {
                              setDonorIdToDisable({
                                id: donor.id,
                                label: `remove ${donor.shortName}`,
                              });
                              setConfirmRemoveOpen(true);
                            }}
                          />
                        </div>
                      ) : (
                        <Tooltip
                          title={`No editing permissions as ${orgRoles.join(
                            " ",
                          )}.`}>
                          <NoAccountsOutlined />
                        </Tooltip>
                      )}
                    </StyledTableItem>
                  </StyledTableRow>
                ))
            ) : (
              <tr>
                <td colSpan="5">
                  <LineText>Please add Donors</LineText>
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </ComponentWrapper>
    </React.Fragment>
  );
};

export default Donors;
