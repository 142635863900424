import React from "react";
import { returnDateForDisplay } from "./rec_helper_tools";
import {
  DetailText,
  HeaderText,
  ReportRow,
  RowHeader,
  SubSubTitleText,
  SubTitleText,
} from "./reconciliation_styles.jsx";
import {
  findContactNameById,
  returnCurrency,
} from "../../../utilities/general_util.jsx";
import { StyledTitleText } from "../../transactions/transactions_styles";
import { Close } from "@mui/icons-material";

export const RecDisplay = ({
  setRecToDisplay,
  recToDisplay,
  accounts,
  contacts,
}) => {
  const isBankRec =
    accounts.find((account) => account.id === recToDisplay.account)
      .accountType === "Assets";
  const isCreditCardRec = !isBankRec;

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: "85%",
        }}>
        <Close
          id="close-button"
          style={{
            position: "absolute",
            top: "1rem",
            right: "-2rem",
            cursor: "pointer",
          }}
          onClick={() => setRecToDisplay(null)}
          fontSize="large"
        />
        <StyledTitleText>{`Reconciliation through ${returnDateForDisplay(
          recToDisplay.closingDate,
        )}`}</StyledTitleText>
        <div
          id="bank-rec-report"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            minWidth: "85%",
          }}>
          <SubTitleText style={{ textAlign: "left" }}>Overview</SubTitleText>
          <ReportRow>
            <DetailText>Reconcile Date</DetailText>
            <DetailText right>
              {returnDateForDisplay(recToDisplay.completedDate)}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>Account</DetailText>
            <DetailText right>
              {`${
                accounts.find((account) => account.id === recToDisplay.account)
                  .accountNumber
              } - ${
                accounts.find((account) => account.id === recToDisplay.account)
                  .accountName
              }`}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>Statement End Date</DetailText>
            <DetailText right>
              {returnDateForDisplay(recToDisplay.closingDate)}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>Statement Closing Balance</DetailText>
            <DetailText right>
              {returnCurrency(recToDisplay.closingBalance)}
            </DetailText>
          </ReportRow>
          <SubTitleText style={{ textAlign: "left" }}>Summary</SubTitleText>
          <ReportRow>
            <DetailText>{`Opening ${
              isBankRec ? "Bank" : "Card"
            } Balance`}</DetailText>
            <DetailText right>
              {returnCurrency(recToDisplay.openingBalance)}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>Cleared Payments</DetailText>
            <DetailText right>
              {returnCurrency(recToDisplay.clearedOut * -1)}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>Cleared Deposits</DetailText>
            <DetailText right>
              {returnCurrency(recToDisplay.clearedIn)}
            </DetailText>
          </ReportRow>
          <ReportRow>
            <DetailText>
              {`Ending ${isBankRec ? "Bank" : "Card"} Balance`}
            </DetailText>
            <DetailText right lineAbove>
              {`${returnCurrency(recToDisplay.closingBalance)}`}
            </DetailText>
          </ReportRow>
          <SubTitleText>
            Cleared Transactions as of{" "}
            {returnDateForDisplay(recToDisplay.closingDate)}
          </SubTitleText>
          <RowHeader style={{ width: "100%" }}>
            <HeaderText>Date</HeaderText>
            {isBankRec && <HeaderText>Check Number</HeaderText>}
            <HeaderText>Contact</HeaderText>
            <HeaderText>Amount</HeaderText>
          </RowHeader>
          <SubSubTitleText>
            {isBankRec ? "Checks and Payments" : "Charges"}
          </SubSubTitleText>
          {recToDisplay?.clearedCredits?.length > 0 ? (
            recToDisplay.clearedCredits.map((tx) => (
              <ReportRow>
                <DetailText>{returnDateForDisplay(tx.date)}</DetailText>
                {isBankRec &&
                  (tx.checkNumber ? (
                    <DetailText>{tx.checkNumber}</DetailText>
                  ) : (
                    <div style={{ width: "15vw" }} />
                  ))}
                <DetailText right>
                  {tx.lines[0].contact === null
                    ? ""
                    : findContactNameById({
                        contactId: tx.lines[0].contact,
                        contacts,
                      })}
                </DetailText>
                <DetailText right>
                  {returnCurrency(tx.lines[0].amount * -1)}
                </DetailText>
              </ReportRow>
            ))
          ) : (
            <SubSubTitleText indent>
              {isBankRec
                ? "No cleared checks or payments"
                : "No cleared charges"}
            </SubSubTitleText>
          )}
          <ReportRow>
            <SubSubTitleText>Subtotal</SubSubTitleText>
            <DetailText right lineAbove>
              {returnCurrency(recToDisplay.clearedCreditTotal * -1)}
            </DetailText>
          </ReportRow>

          <SubSubTitleText>
            {isBankRec ? "Deposits and Other Transfers" : "Payments to Card"}
          </SubSubTitleText>
          {recToDisplay?.clearedDebits?.length > 0 ? (
            recToDisplay.clearedDebits.map((tx) => (
              <ReportRow>
                <DetailText>{returnDateForDisplay(tx.date)}</DetailText>
                {isBankRec && <div style={{ width: "15vw" }} />}
                <DetailText right>
                  {tx.lines[0].contact === null
                    ? ""
                    : findContactNameById({
                        contactId: tx.lines[0].contact,
                        contacts,
                      })}
                </DetailText>
                <DetailText right>
                  {returnCurrency(tx.lines[0].amount)}
                </DetailText>
              </ReportRow>
            ))
          ) : (
            <SubSubTitleText indent>
              {isBankRec
                ? "No cleared deposits or transfers"
                : "No cleared payments"}
            </SubSubTitleText>
          )}
          <ReportRow>
            <SubSubTitleText>Subtotal</SubSubTitleText>
            <DetailText right lineAbove>
              {returnCurrency(recToDisplay.clearedDebitTotal)}
            </DetailText>
          </ReportRow>
          <SubTitleText>
            Uncleared Transactions as of{" "}
            {returnDateForDisplay(recToDisplay.closingDate)}
          </SubTitleText>
          <SubSubTitleText>
            {isBankRec ? "Checks and Payments" : "Charges"}
          </SubSubTitleText>
          {recToDisplay?.unclearedCredits?.length > 0 ? (
            <React.Fragment>
              {recToDisplay.unclearedCredits.map((tx) => (
                <ReportRow>
                  <DetailText>{returnDateForDisplay(tx.date)}</DetailText>
                  <div style={{ width: "15vw" }} />
                  <DetailText right>
                    {tx.lines[0].contact === null
                      ? ""
                      : findContactNameById({
                          contactId: tx.lines[0].contact,
                          contacts,
                        })}
                  </DetailText>
                  <DetailText right>
                    {returnCurrency(tx.lines[0].amount)}
                  </DetailText>
                </ReportRow>
              ))}
              <ReportRow>
                <SubSubTitleText>Subtotal</SubSubTitleText>
                <DetailText right lineAbove>
                  {returnCurrency(recToDisplay.unclearedCreditTotal)}
                </DetailText>
              </ReportRow>
            </React.Fragment>
          ) : (
            <SubSubTitleText indent>
              {isBankRec
                ? "No uncleared checks or payments"
                : "No uncleared charges"}
            </SubSubTitleText>
          )}
          <SubSubTitleText>
            {isBankRec ? "Deposits and Other Transfers" : "Payments to Card"}
          </SubSubTitleText>
          {recToDisplay?.unclearedDebits?.length > 0 ? (
            <React.Fragment>
              {recToDisplay.unclearedDebits.map((tx) => (
                <ReportRow>
                  <DetailText>{returnDateForDisplay(tx.date)}</DetailText>
                  <div style={{ width: "15vw" }} />
                  <DetailText right>
                    {tx.lines[1].contact === null
                      ? ""
                      : findContactNameById({
                          contactId: tx.lines[1].contact,
                          contacts,
                        })}
                  </DetailText>
                  <DetailText right>
                    {returnCurrency(tx.lines[0].amount)}
                  </DetailText>
                </ReportRow>
              ))}
              <ReportRow>
                <SubSubTitleText>Subtotal</SubSubTitleText>
                <DetailText right lineAbove>
                  {returnCurrency(recToDisplay.unclearedDebitTotal)}
                </DetailText>
              </ReportRow>
            </React.Fragment>
          ) : (
            <SubSubTitleText indent>
              {isBankRec ? "No uncleared deposits or transfers" : "No payments"}
            </SubSubTitleText>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecDisplay;
