import { InputAdornment, Modal, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  ModalBox,
  ModalInner,
  TightRowWrapper,
  TitleText,
} from "../../component_styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StyledButton from "../../../assets/buttons";
import { DetailText } from "../../transactions/transactions_styles";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import {
  formatCurrency,
  returnCurrencyInput,
} from "../../../utilities/general_util";
import UserContext from "../../../assets/user_context";
import dayjs from "dayjs";

const ReconciliationStartingOptions = ({
  closingBalanceData,
  setClosingBalanceData,
  initialOptionsModal,
  setInitialOptionsModal,
  setInARec,
  alreadyBegun,
  lastRecDate,
}) => {
  //Function to format the amount to 2 decimal places when the input is blurred
  const formatAmount = (line) => {
    if (closingBalanceData.amount !== null) {
      const newEntryData = { ...closingBalanceData };
      const amount = closingBalanceData.amount;
      if (amount !== undefined) {
        const formattedAmount = parseInt(amount);
        newEntryData.amount = formattedAmount;
        setCurrencyDisplayValue(formatCurrency(formattedAmount));
      }
      setClosingBalanceData(newEntryData);
    }
  };

  const { setDateRangeForTx } = useContext(UserContext);

  const [currencyDisplayValue, setCurrencyDisplayValue] = useState("");

  const handleInputChange = (e) => {
    const { centsInt, formattedDollarValue } = returnCurrencyInput(
      e.target.value,
    );

    console.log("newCentsValue", centsInt, formattedDollarValue);

    setClosingBalanceData({
      ...closingBalanceData,
      amount: centsInt,
    });

    // Format the display value - converting cents back to a dollar amount for display
    setCurrencyDisplayValue(formattedDollarValue);
  };

  //useEffect to log lastRecDate when it changes
  useEffect(() => {
    console.log("lastRecDate", lastRecDate);
  }, [lastRecDate]);

  return (
    <Modal
      open={initialOptionsModal}
      onClose={() => alert("You must set initial options")}>
      <ModalBox>
        <ModalInner>
          <TitleText style={{ fontSize: "2rem" }}>
            Statement Closing Info
          </TitleText>
          <Close
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
            fontSize="large"
            onClick={() => {
              if (!alreadyBegun) {
                setInARec(false);
              } else {
                if (
                  closingBalanceData.amount !== null &&
                  closingBalanceData.amount !== "" &&
                  closingBalanceData.date !== null
                ) {
                  setInitialOptionsModal(false);
                }
              }
            }}
          />
          <DetailText>
            Please fill out the date and amount for the <strong>end</strong> of
            the period you'd like to reconcile.
          </DetailText>
          <TightRowWrapper>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Balance as of Date"
                minDate={
                  lastRecDate ? dayjs(lastRecDate.toDate()).add(1, "day") : null
                }
                value={closingBalanceData.date}
                onChange={(newValue) =>
                  setClosingBalanceData({
                    ...closingBalanceData,
                    date: newValue,
                  })
                }
                disableFuture={true}
                renderInput={(params) => (
                  <TextField {...params} sx={{ padding: "0.5rem" }} />
                )}
              />
            </LocalizationProvider>
          </TightRowWrapper>
          <TextField
            id="balance"
            name={`balance`}
            label={"Statement Closing Balance"}
            color="secondary"
            value={currencyDisplayValue}
            onChange={handleInputChange}
            InputProps={
              closingBalanceData.amount !== "" && {
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }
            }
            variant="outlined"
            sx={{
              width: "275px",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            onBlur={() => {
              formatAmount();
            }}
            helperText={"You can find this on your bank statement"}
          />
          <StyledButton
            fontSize={"1.5rem"}
            disabled={
              closingBalanceData.amount === null ||
              closingBalanceData.amount === ""
            }
            primary
            onClick={() => {
              if (
                closingBalanceData.amount !== null &&
                closingBalanceData.amount !== "" &&
                closingBalanceData.date !== null
              ) {
                //Get a date  called firstDate 6 months before closingBalanceData.date, and a date called lastDate that is 7 days after closingBalanceData.date
                let firstDate = new Date(closingBalanceData.date);
                firstDate.setMonth(firstDate.getMonth() - 6);
                let lastDate = new Date(closingBalanceData.date);
                lastDate.setDate(lastDate.getDate() + 7);

                setDateRangeForTx({
                  firstDate: dayjs(firstDate),
                  lastDate: dayjs(lastDate),
                });
                setInitialOptionsModal(false);
              }
            }}>
            Begin Reconcilliation
          </StyledButton>
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

export default ReconciliationStartingOptions;
