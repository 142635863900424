import styled from "@emotion/styled";
import { Box } from "@mui/material";
import {
  primary_color,
  primary_dark_color,
} from "../../../constants/color_constants";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const ClickableDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const HeaderText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: black;
`;

// export const AccountNumber = styled.p`
//   font-size: 1.3rem;
//   font-family: "MontserratMed";
//   color: ${primary_color};
//   text-shadow:
//     -1px -1px 0 #000,
//     1px -1px 0 #000,
//     -1px 1px 0 #000,
//     1px 1px 0 #000;
// `;

export const AccountNumber = styled.p`
  font-size: 1.3rem;
  font-family: "MontserratMed";
  color: ${primary_dark_color};
`;

export const AccountNameText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  color: black;
`;
