import styled from "@emotion/styled";
import { filterStyle } from "./controls";

export const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  ${filterStyle}
  filter: ${(props) => (props.blur ? "blur(10px)" : "none")};

  /* object-position: 0% 90%; */
  transform: scale(1.03);
  object-fit: cover;
  object-position: top;
  z-index: -2;
`;
export const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding-top: 15vh; */
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  transform: translate(0, 0);
  transform: scale(1.08);
  overflow: hidden;
  z-index: -1;
`;

export const ErrorText = styled.p`
  font-family: "MontserratMed";
  font-size: 14px;
  color: red;
  margin: 0;
`;
