import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useWindowSize } from "../../utilities/responsive_helpers";
import UserContext from "../../assets/user_context";
import {
  formatNumberWithCommas,
  generateRandomId,
  returnCurrencyInput,
} from "../../utilities/general_util";
import StyledButton from "../../assets/buttons";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { DetailText } from "../transactions/transactions_styles";
import { TitleText } from "../component_styles";
import dayjs from "dayjs";

const handlePressEnter = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
    console.log("Enter Pressed");
    // handleSubmit();
  }
};

const AddDonation = ({ db, donationToEdit }) => {
  const dateInputRef = useRef(null);
  const { donors, funds, org } = useContext(UserContext);
  const [donationData, setDonationData] = useState({});
  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");
  const [entryErrors, setEntryErrors] = useState({
    date: false,
    amount: false,
    reason: false,
    donor: false,
  });
  const [donorEntryErrors, setDonorEntryErrors] = useState({
    email: false,
    streetAddress: false,
    city: false,
    state: false,
    zip: false,
  });
  const [donorDisplay, setDonorDisplay] = useState("");
  const [displayAmount, setDisplayAmount] = useState("");
  const [donorEntry, setDonorEntry] = useState({
    email: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
  });
  const [newDonor, setNewDonor] = useState(false);
  const [bluredDonor, setBluredDonor] = useState(false);

  useEffect(() => {
    if (donationToEdit && Object.keys(donationToEdit)?.length > 0) {
      setDonationData({ ...donationToEdit, date: dayjs(donationToEdit.date) });
      setDonorDisplay(donationToEdit.donor);
      setDisplayAmount(
        formatNumberWithCommas(
          parseFloat(donationToEdit.amount / 100).toFixed(2),
        ),
      );
    }
  }, [donationToEdit]);

  useEffect(() => {
    if (bluredDonor) {
      if (
        donationData.donor &&
        (!donors ||
          donors.length === 0 ||
          donors.every((donor) => donor.shortName !== donorDisplay))
      ) {
        setNewDonor(true);
      } else {
        setNewDonor(false);
      }
    }
  }, [donationData.donor, donorDisplay, donors, bluredDonor]);

  const runValidationOnDonation = () => {
    let errors = {
      date: false,
      amount: false,
      reason: false,
      donor: false,
      cleared: false,
    };
    if (!donationData.date) {
      errors.date = true;
    }
    if (!donationData.amount) {
      errors.amount = true;
    }
    if (!donationData.reason) {
      errors.reason = true;
    }
    if (!donationData.donor) {
      errors.donor = true;
    }
    setEntryErrors(errors);
    return errors;
  };

  const runValidationOnDonorEntry = () => {
    let errors = {
      email: false,
      streetAddress: false,
      city: false,
      state: false,
      zip: false,
    };
    //Either the email or all the address elements must be filled out
    if (
      !donorEntry.email &&
      (!donorEntry.streetAddress ||
        !donorEntry.city ||
        !donorEntry.state ||
        !donorEntry.zip)
    ) {
      errors.email = true;
      errors.streetAddress = true;
      errors.city = true;
      errors.state = true;
      errors.zip = true;
    }
    console.log("Donor Entry Errors", errors);
    setDonorEntryErrors(errors);
    return errors;
  };

  useEffect(() => {
    if (dateInputRef.current) {
      // console.log("dateInputRef.current is not null");
      dateInputRef.current.focus();
    }
  }, [dateInputRef.current]);

  //useEffect to validate the donationData when it changes if there are entryErrors to remove them
  useEffect(() => {
    if (Object.values(entryErrors).some((error) => error === true)) {
      runValidationOnDonation();
    }
  }, [donationData]);

  //useEffect to validate the donorEntryErrors when it changes if there are entryErrors to remove them
  useEffect(() => {
    if (Object.values(donorEntryErrors).some((error) => error === true)) {
      runValidationOnDonorEntry();
    }
  }, [donorEntry, donorEntryErrors]);

  const handleSubmit = async () => {
    console.log("Donation Data", donationData);
    let donationDonorId =
      donationData?.length > 0 ? donationData.donor[0] : donationData.donor[0];
    //Validate the data
    const errors = runValidationOnDonation();
    console.log("Errors", errors);
    if (Object.values(errors).every((error) => error === false)) {
      if (
        !donors?.length > 0 ||
        donors.every((donor) => donor.shortName !== donorDisplay)
      ) {
        //Run the validation on the donor entry
        const donorErrors = runValidationOnDonorEntry();
        if (Object.values(donorErrors).some((err) => err === true)) return;
        //Add the donor to the list
        const donorsRef = doc(db, "orgs", org, "donorManagement", "donors");
        console.log("DonorsRef: ", donorsRef);
        //While the id is not unique, generate a new one
        let donorId = generateRandomId();
        let isIdUnique = false;

        while (!isIdUnique && donors?.length > 0) {
          isIdUnique = true;
          for (let donor of donors) {
            if (donor.id.includes(donorId)) {
              isIdUnique = false;
              donorId = generateRandomId();
              break; // Exit the for-loop early as we found a duplicate
            }
          }
        }
        donationDonorId = donorId;
        try {
          const docSnapshot = await getDoc(donorsRef);
          if (docSnapshot.exists()) {
            const currentDonors = docSnapshot.data().donors || [];
            await updateDoc(donorsRef, {
              donors: [
                ...currentDonors,
                { ...donorEntry, shortName: donationData.donor, id: [donorId] },
              ],
            });
            console.log("Donor added successfully");
          } else {
            console.log("Document does not exist!");
            await setDoc(donorsRef, {
              donors: [
                { ...donorEntry, shortName: donationData.donor, id: [donorId] },
              ],
            });
          }
        } catch (error) {
          console.error("Error adding donor: ", error);
        }
      }
      console.log("Data is good to go");
      //Send the data to the server
      //Get the year for the donation
      const year = new Date(donationData.date).getFullYear();
      console.log("Year", year);
      const donationsRef = doc(
        db,
        "orgs",
        org,
        `donorManagement`,
        `donations-${year}`,
      );
      //Check if the donation doc exists, if it does update it and if it doesn't create it
      const donationDoc = await getDoc(donationsRef);
      if (donationDoc.exists()) {
        console.log("Current year of donations:", donationDoc.data());
        const currentDonations = donationDoc.data();
        //generate a random id for the donation that doesn't exist in the current donations if there are any current donations
        let donationId = generateRandomId();
        let isIdUnique = false;
        while (!isIdUnique && currentDonations.donations?.length > 0) {
          isIdUnique = true;
          for (let donation of currentDonations.donations) {
            if (donation.id === donationId) {
              isIdUnique = false;
              donationId = generateRandomId();
              break; // Exit the for-loop early as we found a duplicate
            }
          }
        }
        donationData.id = donationId;
        console.log("Donation Data", donationData, donationId);
        const newDonations = [
          ...currentDonations.donations,
          {
            ...donationData,
            date: donationData.date.toDate(),
            donor: donationDonorId,
          },
        ];
        console.log("New Donations", newDonations);
        await updateDoc(donationsRef, {
          year: year,
          donations: newDonations,
        });
      } else {
        // doc.data() will be undefined in this case
        console.log(`No donations in year ${year}!`);
        let donationId = generateRandomId();
        donationData.id = donationId;
        await setDoc(donationsRef, {
          year: year,
          donations: [
            {
              ...donationData,
              date: donationData.date.toDate(),
              donor: donationDonorId,
            },
          ],
        });
      }
      setDonationData({
        date: "",
        amount: "",
        reason: "",
        donor: "",
      });
      setDonorDisplay("");
      setDisplayAmount("");
      setDonorEntry({
        email: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
      });
      setTimeout(() => {
        setSomeUniqueKeyThatForcesReRender((prevState) =>
          prevState.concat("a"),
        );
      }, 200);
    }
  };

  const handleAmountChange = (event) => {
    const { value, name } = event.target;
    //stip out commas and dollar signs

    console.log("value", value);
    const { formattedDollarValue, centsInt } = returnCurrencyInput(value);

    setDonationData((prevData) => ({
      ...prevData,
      amount: centsInt,
    }));

    setDisplayAmount(formattedDollarValue);
  };

  const { height } = useWindowSize();
  const size = height > 800 ? "medium" : "small";
  return (
    <div
      onKeyDown={handlePressEnter}
      style={{
        backgroundColor: "#e9e9e9",
        padding: "1rem",
        borderRadius: "1.5rem",
        width: "95%",
      }}>
      <TitleText style={{ fontSize: "1.8rem" }}>Record a Donation</TitleText>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={3}>
          <FormControl style={{ backgroundColor: "white" }} variant="filled">
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <DatePicker
                label="Donation Date"
                value={donationData.date}
                onChange={(newDate) =>
                  setDonationData({ ...donationData, date: newDate })
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" color="secondary" />
                )}
                slotProps={{
                  textField: {
                    inputRef: dateInputRef,
                    size: size,
                    fullWidth: true,
                    required: true,
                    error: entryErrors.date,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            style={{ backgroundColor: "white" }}
            variant="filled"
            fullWidth>
            <Autocomplete
              freeSolo
              autoSelect
              key={`${someUniqueKeyThatForcesReRender}`}
              label="Donor"
              name="Donor"
              options={donors ? donors.map((donor) => donor.shortName) : []}
              getOptionLabel={(option) => option} // Explicitly handle string options
              value={donorDisplay || null}
              onBlur={() => setBluredDonor(true)}
              onInputChange={(event, newInputValue) => {
                const newEntryData = { ...donationData };
                const matchingDonor = donors?.find(
                  (donor) => donor.shortName === newInputValue,
                );
                newEntryData.donor = matchingDonor
                  ? matchingDonor.id
                  : newInputValue;
                setDonationData(newEntryData);

                // Also update the display value
                setDonorDisplay(
                  matchingDonor ? matchingDonor.shortName : newInputValue,
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="donor"
                  variant="outlined"
                  color="secondary"
                  label="Donor"
                  error={entryErrors.donor}
                  required
                  size={size}
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            style={{ backgroundColor: "white" }}
            variant="filled"
            fullWidth>
            <Autocomplete
              autoSelect
              autoHighlight
              id="reason"
              key={`reason-${someUniqueKeyThatForcesReRender}`}
              name="reason"
              label="Reason"
              variant="outlined"
              color="secondary"
              size={size}
              options={funds.map((fund) => fund.fundName)}
              value={donationData.reason}
              onChange={(event, newValue) => {
                setDonationData({ ...donationData, reason: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} error={entryErrors.reason} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            style={{ backgroundColor: "white" }}
            variant="filled"
            fullWidth>
            <TextField
              label="Amount"
              name="amount"
              variant="outlined"
              color="secondary"
              size={size}
              value={displayAmount}
              onChange={handleAmountChange}
              error={entryErrors.amount}
              required
              fullWidth
              onBlur={() => {
                setDisplayAmount(() =>
                  formatNumberWithCommas(
                    parseFloat(donationData.amount / 100).toFixed(2),
                  ),
                );
              }}
              InputProps={
                displayAmount &&
                displayAmount !== "" && {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              }
            />
          </FormControl>
        </Grid>
        {/* Grids with text fields for email and address */}
        {newDonor && (
          <Grid item xs={12}>
            <DetailText>
              This donor isn't in your list, please enter their contact details.
            </DetailText>
          </Grid>
        )}
        {newDonor && (
          <Grid item xs={3}>
            <FormControl
              style={{ backgroundColor: "white" }}
              variant="filled"
              fullWidth>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                color="secondary"
                size={size}
                value={donationData.email}
                onChange={(event) => {
                  const { value, name } = event.target;
                  setDonorEntry((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));
                }}
                fullWidth
                error={donorEntryErrors.email}
                helperText={
                  donorEntryErrors.email &&
                  "Either Address or Email is Required"
                }
              />
            </FormControl>
          </Grid>
        )}
        {newDonor && (
          <Grid item xs={4}>
            <FormControl
              style={{ backgroundColor: "white" }}
              variant="filled"
              fullWidth>
              <TextField
                label="Address"
                name="address"
                variant="outlined"
                color="secondary"
                size={size}
                value={donationData.address}
                onChange={(event) => {
                  const { value, name } = event.target;
                  setDonorEntry((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));
                }}
                fullWidth
                error={donorEntryErrors.streetAddress}
                helperText={
                  donorEntryErrors.streetAddress &&
                  "Either Address or Email is Required"
                }
              />
            </FormControl>
          </Grid>
        )}
        {newDonor && (
          <Grid item xs={2}>
            <FormControl
              style={{ backgroundColor: "white" }}
              variant="filled"
              fullWidth>
              <TextField
                label="City"
                name="city"
                variant="outlined"
                color="secondary"
                size={size}
                value={donationData.city}
                onChange={(event) => {
                  const { value, name } = event.target;
                  setDonorEntry((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));
                }}
                fullWidth
                error={donorEntryErrors.city}
              />
            </FormControl>
          </Grid>
        )}
        {newDonor && (
          <Grid item xs={1}>
            <FormControl
              style={{ backgroundColor: "white" }}
              variant="filled"
              fullWidth>
              <TextField
                label="State"
                name="state"
                variant="outlined"
                color="secondary"
                size={size}
                value={donationData.state}
                onChange={(event) => {
                  const { value, name } = event.target;
                  setDonorEntry((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));
                }}
                fullWidth
                error={donorEntryErrors.state}
              />
            </FormControl>
          </Grid>
        )}
        {newDonor && (
          <Grid item xs={2}>
            <FormControl
              style={{ backgroundColor: "white" }}
              variant="filled"
              fullWidth>
              <TextField
                label="Zip"
                name="zip"
                variant="outlined"
                color="secondary"
                size={size}
                value={donationData.zip}
                onChange={(event) => {
                  const { value, name } = event.target;
                  setDonorEntry((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));
                }}
                fullWidth
                error={donorEntryErrors.zip}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <div style={{ height: "1rem" }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        <StyledButton
          primary
          fontSize="1.1rem"
          onClick={handleSubmit}
          style={{ marginTop: "1rem" }}>
          Record Donation
        </StyledButton>
      </div>
    </div>
  );
};

export default AddDonation;
