import React from "react";
import { Slider } from "@mui/material";

const SliderFilter = ({
  type,
  value,
  min,
  max,
  scale,
  valueLabelFormat,
  onChange,
  marks,
  label,
}) => {
  return (
    <Slider
      getAriaLabel={() => label}
      value={value}
      onChange={(event, newValue) => onChange(type, newValue)}
      disableSwap={true}
      min={min}
      max={max}
      scale={scale}
      marks={marks}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
    />
  );
};

export default SliderFilter;
