import styled from "@emotion/styled";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { primary_dark_color } from "../../../constants/color_constants";
import { FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";

//Title of the page text
export const PageTitle = styled.p`
  font-size: 2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: ${primary_dark_color};
  text-align: center;
`;

export const LineText = styled.p`
  font-size: 1.5rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: ${primary_dark_color};
  text-align: center;
`;

export const MasterErrorText = styled.p`
  font-size: 1.25rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: red;
  text-align: center;
  @media only screen and (max-height: 800px) {
    font-size: 1rem;
  }
`;

export const StyledSplitIcon = styled(CallSplitIcon)`
  color: ${primary_dark_color};
  font-size: 3rem;
  margin: 0.5rem;
  cursor: pointer;
  transform: rotate(90deg);
`;

export const TransactionLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const AutoBalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  cursor: pointer;
`;

export const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  max-height: ${(props) => props.height || "75vh"};
  overflow-y: auto;
`;

export const StyledFormControlGrowFaster = styled(FormControl)`
  flex: 2 1 100%; /* Increased initial width */
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "180px")};
  margin: 5px;

  @media (min-width: 1200px) {
    margin: 2px;
    min-width: ${(props) =>
      props.minWidth ? `${props.minWidth * 0.8}px` : "180px"};
  }
`;

export const StyledFormControlGrowSlower = styled(FormControl)`
  flex: 1 1 100%; /* Adjusted for a more balanced initial width */
  margin: 5px;
  min-width: ${(props) => {
    console.log("minWidth prop:", props.minWidth); // This will log the minWidth prop
    return props.minWidth ? `${props.minWidth}px` : "180px";
  }};
  @media (min-width: 1200px) {
    margin: 2px;
    min-width: ${(props) => {
      console.log("minWidth prop IN media query:", props.minWidth * 0.8); // This will log the minWidth prop
      return props.minWidth ? `${props.minWidth * 0.8}px` : "160px";
    }};
  }
`;

StyledFormControlGrowFaster.defaultProps = {
  variant: "outlined",
  color: "secondary",
  fullWidth: true,
};

StyledFormControlGrowSlower.defaultProps = {
  variant: "outlined",
  color: "secondary",
  fullWidth: true,
};

const WhiteTextField = styled(TextField)`
  background-color: white;

  /* .MuiOutlinedInput-root {
    fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  } */
`;

export default WhiteTextField;

export const ControlLabelText = styled.p`
  font-family: "MontserratMed";
  font-size: 1.2rem;
  color: ${primary_dark_color};
  margin: 0;
  margin-right: 0.25rem;
`;

export const ControlDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
