import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../assets/user_context";
import LostDiver from "../assets/lostDiver.png";

import * as ROUTES from "../constants/routes";
import styled from "@emotion/styled";
import { PageWrapper, TitleText } from "../locations/component_styles";

const LostImage = styled.img`
  /* width: 80vw; */
  height: 70vh;
  margin-top: 2rem;
  border-radius: 5rem;
  margin-right: 2rem;
`;

const NoMatch = () => {
  const { userStillLoading, user } = useContext(UserContext);

  const redirect = useNavigate();

  useEffect(() => {
    if (!userStillLoading) {
      setTimeout(() => {
        if (user !== null) {
          console.log("Should redirect to HOME");
          redirect("/");
        } else {
          console.log("Should redirect to SIGN_IN");
          redirect("/signin");
        }
      }, 5000);
    }
  }, [redirect, userStillLoading, user]);

  return (
    <PageWrapper style={{ position: "relative", width: "100%" }}>
      <div style={{ position: "absolute", left: "4rem", top: "4rem" }}>
        <TitleText style={{ textAlign: "left" }}>
          What a bummer, we can't find that page
        </TitleText>
        <TitleText style={{ textAlign: "left", fontSize: "1.5rem" }}>
          We'll take you somewhere that makes more sense in 5 seconds
        </TitleText>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}>
        <LostImage src={LostDiver} alt="Lost Diver" />
      </div>
    </PageWrapper>
  );
};

export default NoMatch;
