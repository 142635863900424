import React, { useState } from "react";
import TransactionTopRow from "./transaction_top_row";
import { createTheme, ThemeProvider } from "@mui/material";
import TransactionSplitRow from "./transaction_split_row";
import { doc, updateDoc } from "firebase/firestore";
import AddSplitRow from "./add_split";

const Transaction = ({
  transaction,
  txkey,
  db,
  org,
  focusedKey,
  setFocusedKey,
  focusedAspect,
  setFocusedAspect,
  cachedSplitLineAmount,
  setCachedSplitLineAmount,
  selectedTransactions,
  handleTransactionSelection,
}) => {
  const [activelySplitting, setActivelySplitting] = useState(false);
  const theme = createTheme({
    palette: {
      mode: "dark", // Set the theme type to dark
    },
  });

  const setSplitAccount = async ({ txkey, splitIndex, account }) => {
    console.log("Updating account in split", txkey, splitIndex, account);
    const splits = [...transaction.splits];
    splits[splitIndex] = {
      ...splits[splitIndex],
      account: account,
    };
    console.log("New splits", splits);
    try {
      await updateDoc(doc(db, "orgs", org, "importedTransactions", txkey), {
        splits: splits,
      });
      console.log(`Account updated in split ${splitIndex} in ${txkey}`);
      return true;
    } catch (e) {
      console.log(`Error updating account in split ${splitIndex} in ${txkey}`);
      return false;
    }
  };

  const changeSplitAmount = async ({ txkey, splitIndex, amount }) => {
    await updateDoc(doc(db, "orgs", org, "importedTransactions", txkey), {
      splits: [
        ...transaction.splits.slice(0, splitIndex),
        {
          ...transaction.splits[splitIndex],
          amount: amount,
        },
        ...transaction.splits.slice(splitIndex + 1),
      ],
    });
  };

  const addSplit = async ({ key, splitValue }) => {
    console.log("Adding split", splitValue, key);
    //This should update the splits array in the message, only if the splitValue is a number
    if (splitValue === undefined || isNaN(splitValue) || splitValue === "") {
      console.log("Invalid split value", isNaN(splitValue), splitValue);
      return false;
    }
    try {
      await updateDoc(
        doc(db, "orgs", org, "importedTransactions", key),
        transaction?.splits?.length > 0
          ? {
              splits: [
                ...transaction.splits,
                {
                  username: "User",
                  amount: splitValue,
                },
              ],
            }
          : {
              splits: [
                {
                  username: "User",
                  amount: splitValue,
                },
              ],
            },
      );
      //This is not executing, I believe because when the addReply succeeds in updating the doc, the component that is calling addReply is re-rendered?
      console.log(`Split added to ${key}`);
      setCachedSplitLineAmount("");
      return true;
    } catch (e) {
      console.log(`Error adding split to ${key}`);
      console.error(e);
      return false;
    }
  };

  const removeSplit = ({ txkey, splitIndex }) => {
    console.log("Removing split", txkey, splitIndex);
    const newSplits = transaction.splits.filter(
      (split, index) => index !== splitIndex,
    );
    if (newSplits.length === 0) {
      console.log("No splits left, removing transaction");
      //Remove splits from transaction
      updateDoc(doc(db, "orgs", org, "importedTransactions", txkey), {
        splits: [],
      });
      setActivelySplitting(false);
    } else {
      updateDoc(doc(db, "orgs", org, "importedTransactions", txkey), {
        splits: newSplits,
      });
    }
  };

  const totalSplits = transaction?.splits?.reduce(
    (acc, split) => acc + split.amount,
    0,
  );

  return (
    <ThemeProvider theme={theme}>
      <TransactionTopRow
        transaction={transaction}
        txkey={txkey}
        activelySplitting={activelySplitting}
        setActivelySplitting={setActivelySplitting}
        selectedTransactions={selectedTransactions}
        handleTransactionSelection={handleTransactionSelection}
      />
      {transaction?.splits?.length > 0 ? (
        <React.Fragment>
          {transaction.splits.map((split, index) => {
            return (
              <TransactionSplitRow
                key={`splitrow-${index}`}
                split={split}
                txkey={txkey}
                splitIndex={index}
                setAccount={setSplitAccount}
                txTopDebit={transaction.debit > 0}
                txTopCredit={transaction.credit > 0}
                changeSplitAmount={changeSplitAmount}
                selectedTransactions={selectedTransactions}
                handleTransactionSelection={handleTransactionSelection}
                splitError={transaction.errors?.splitsErrors?.[index]}
                removeSplit={removeSplit}
                lastSplit={
                  transaction?.splits.length === index + 1 &&
                  (totalSplits === transaction.debit ||
                    totalSplits === transaction.credit)
                }
              />
            );
          })}
          {!(
            totalSplits === transaction.debit ||
            totalSplits === transaction.credit
          ) && (
            <AddSplitRow
              txkey={txkey}
              transaction={transaction}
              setFocusedAspect={setFocusedAspect}
              setFocusedKey={setFocusedKey}
              focusedKey={focusedKey}
              splitValue={cachedSplitLineAmount}
              setSplitValue={setCachedSplitLineAmount}
              setSplitTx={setActivelySplitting}
              db={db}
              addSplit={addSplit}
            />
          )}
        </React.Fragment>
      ) : (
        activelySplitting && (
          <AddSplitRow
            txkey={txkey}
            transaction={transaction}
            setFocusedAspect={setFocusedAspect}
            setFocusedKey={setFocusedKey}
            focusedKey={focusedKey}
            splitValue={cachedSplitLineAmount}
            setSplitValue={setCachedSplitLineAmount}
            setSplitTx={setActivelySplitting}
            db={db}
            addSplit={addSplit}
          />
        )
      )}
    </ThemeProvider>
  );
};

export default Transaction;
