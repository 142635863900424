import { convertToDate } from "../../utilities/general_util";
import {
  processDeletedTransactions,
  processNewTransactions,
} from "./process_tx_changes";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";

export const setupNewTransactionsListener = ({
  authHook,
  org,
  enqueueUpdate,
  setTransactions,
  setDateToRecalcAfter,
  dateToRecalcAfter,
  initialResults,
  deleteQueue,
}) => {
  // Set up a listener for new transactions in the journalEntries collection
  const txCollectionRef = collection(
    authHook.db,
    "orgs",
    org,
    "journalEntries",
  );
  console.log("INITIAL RESULTS IN LISTENER: ", initialResults);
  let q;
  if (
    isNaN(initialResults.latestTimestampInSec) ||
    initialResults.latestTimestampInSec === 0 ||
    initialResults.transactions.length === 0
  ) {
    // If it's the first time fetching transactions and there are not aggregates, fetch all transactions
    console.log("First fetch, no aggregates, get 'em all");
    q = query(txCollectionRef, where("addTimestamp", "==", false));
  } else {
    // Otherwise, only fetch the new ones
    console.log(
      "LISTENING for transactions changed after, ",
      Date(initialResults.latestTimestampInSec * 1000),
    );
    const timestamp = new Date(initialResults.latestTimestampInSec * 1000);
    q = query(
      txCollectionRef,
      where("lastChangeTimestamp", ">", timestamp),
      where("addTimestamp", "==", false),
    );
  }
  return onSnapshot(q, async (snapshot) => {
    console.log("New Transactions Snapshot: ", snapshot);
    try {
      if (snapshot !== null && snapshot.docs.length > 0) {
        // console.log(
        //   `Got ${snapshot.docs.length} new transactions from the db after ${initialResults.latestTimestampInSeclatestTimestampInSec}`,
        // );
        console.log(
          "TODO: Should probably update the code to recalcAggregates on the server if this is too many transactions...",
        );
        const newTransactions = snapshot.docs.map((doc) => {
          // Loop over new transactions, creating objects with formatted dates so we can easily merge them with existing transactions
          const entryUnformattedDate = doc.data().date;
          const entryFormattedDate = convertToDate(entryUnformattedDate);
          const entryFormatted = {
            ...doc.data(),
            date: entryFormattedDate,
            id: doc.id,
          };
          if (!entryFormatted.date) {
            console.error(
              "Date issue in new Tx: ",
              entryFormatted.date,
              doc.data().date,
            );
          }
          return entryFormatted;
        });

        enqueueUpdate((currentTransactions) => {
          // console.log(
          //   "currentTransactions (in anonymous queued function): ",
          //   currentTransactions,
          // );
          processNewTransactions({
            currentTransactions,
            newTransactions,
            setTransactions,
            setDateToRecalcAfter,
            dateToRecalcAfter,
            deleteQueue,
          });
        });
      }
    } catch (error) {
      console.error("Error fetching new transactions: ", error);
    }
  });
};

export const setupDeletedTransactionsListener = ({
  authHook,
  org,
  enqueueUpdate,
  monthlyAggregates,
  setTransactions,
  setDateToRecalcAfter,
  dateToRecalcAfter,
}) => {
  const deletedTransactionsRef = doc(
    authHook.db,
    "orgs",
    org,
    "history",
    "deletedTransactions",
  );
  return onSnapshot(deletedTransactionsRef, (snapshot) => {
    console.log("Deleted Transactions Snapshot: ", snapshot);
    try {
      if (snapshot.exists()) {
        enqueueUpdate((currentTransactions) => {
          // console.log("This should be defined:", currentTransactions);
          processDeletedTransactions({
            deletedTransactionIds: snapshot.data().transactionIds,
            monthlyAggregates,
            currentTransactions,
            setTransactions,
            setDateToRecalcAfter,
            dateToRecalcAfter,
          });
        });
      } else {
        console.log("No deleted transactions");
      }
    } catch (error) {
      console.error("Error fetching deleted transactions: ", error);
    }
  });
};
