import React from "react";
import { AddOrChangeAccount } from "../../locations/foundation/manage_accounts/magange_accounts";
import {
  ModalMaster,
  ModalMasterBox,
  ModalMasterInner,
} from "./mass_import_styles";
import MassAddAccounts from "./account_import/mass_add_accounts";
import { AddOrChangeFund } from "../../locations/foundation/manage_accounts/manage_funds";
import RepairTransaction from "./transaction_import/repair_transaction";

// Higher-Order Component to inject common props using arrow functions
export const withCommonProps =
  (Component) =>
  ({ authUser, org, db, stillLoading, ...props }) => (
    <Component
      authUser={authUser}
      org={org}
      db={db}
      stillLoading={stillLoading}
      {...props}
    />
  );

// Original Modal Component
const AddMatchAccountModalComponent = ({
  accountNumberToAdd,
  updateAccountNumber,
  possibleAccountNameToAdd,
  accountsHierarchy,
  setAddAccountModalOpen,
  addAccountModalOpen,
  accounts,
  // Common props are now implicitly passed
  authUser,
  org,
  db,
  stillLoading,
}) => {
  return (
    <ModalMaster
      open={addAccountModalOpen}
      onClose={() => setAddAccountModalOpen(false)}>
      <ModalMasterBox>
        <ModalMasterInner>
          <AddOrChangeAccount
            authUser={authUser}
            org={org}
            db={db}
            stillLoading={stillLoading}
            accounts={accounts}
            setShowModal={setAddAccountModalOpen}
            accountNumberToAdd={accountNumberToAdd}
            updateAccountNumber={updateAccountNumber}
            possibleAccountNameToAdd={possibleAccountNameToAdd}
            accountsHierarchy={accountsHierarchy}
          />
        </ModalMasterInner>
      </ModalMasterBox>
    </ModalMaster>
  );
};

const AddMatchFundModalComponent = ({
  funds,
  setAddFundModalOpen,
  addFundModalOpen,
  fundNumberToAdd,
  updateFundNumber,
  possibleFundNameToAdd,
  accountsHierarchy,
  // Common props are now implicitly passed
  authUser,
  org,
  db,
  stillLoading,
}) => {
  return (
    <ModalMaster
      open={addFundModalOpen}
      onClose={() => setAddFundModalOpen(false)}>
      <ModalMasterBox>
        <ModalMasterInner>
          <AddOrChangeFund
            authUser={authUser}
            org={org}
            db={db}
            stillLoading={stillLoading}
            funds={funds}
            setShowModal={setAddFundModalOpen}
            fundNumberToAdd={fundNumberToAdd}
            updateFundNumber={updateFundNumber}
            possibleFundNameToAdd={possibleFundNameToAdd}
            accountsHierarchy={accountsHierarchy}
          />
        </ModalMasterInner>
      </ModalMasterBox>
    </ModalMaster>
  );
};

const MassAddAccountsModalComponent = ({
  massAddAccountsOpen,
  setShowModal,
  transactionsArg,
  // Common props are now implicitly passed
  authUser,
  org,
  db,
  stillLoading,
}) => {
  return (
    <ModalMaster open={massAddAccountsOpen}>
      <ModalMasterBox wideVersion="true">
        <ModalMasterInner>
          <MassAddAccounts
            authUser={authUser}
            org={org}
            db={db}
            stillLoading={stillLoading}
            setShowModal={setShowModal}
            transactionsArg={transactionsArg}
          />
        </ModalMasterInner>
      </ModalMasterBox>
    </ModalMaster>
  );
};

const RepairTransactionModalComponent = ({
  entryToEdit,
  setEntryToEdit,
  accounts,
  funds,
  setInitialImportData,
  // Common props are now implicitly passed
  authUser,
  org,
  db,
  stillLoading,
}) => {
  return (
    <ModalMaster
      open={Object.keys(entryToEdit)?.length > 0}
      onClose={() => {
        setEntryToEdit({});
      }}>
      <ModalMasterBox>
        <ModalMasterInner>
          <RepairTransaction
            authUser={authUser}
            org={org}
            db={db}
            stillLoading={stillLoading}
            entryToEdit={entryToEdit}
            setEntryToEdit={setEntryToEdit}
            accounts={accounts}
            funds={funds}
            setInitialImportData={setInitialImportData}
          />
        </ModalMasterInner>
      </ModalMasterBox>
    </ModalMaster>
  );
};

export const MassAddAccountsModal = withCommonProps(
  MassAddAccountsModalComponent,
);
export const AddMatchAccountModal = withCommonProps(
  AddMatchAccountModalComponent,
);
export const AddMatchFundModal = withCommonProps(AddMatchFundModalComponent);
export const RepairTransactionModal = withCommonProps(
  RepairTransactionModalComponent,
);
