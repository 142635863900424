import { useRef, useState, useEffect, useCallback } from "react";

// Custom hook to manage a queue of state updates.
// It ensures that state updates are processed sequentially, even if they are asynchronous.
const useUpdateQueue = (getCurrentState) => {
  // Queue to hold the state update functions.
  const [queue, setQueue] = useState([]);
  // Boolean to track if an update is currently being processed.
  const [processing, setProcessing] = useState(false);
  // Ref to store the current state. This is used to always get the latest state.
  const currentStateRef = useRef();

  // Update the ref with the latest state. This function is passed from the component using the hook.
  currentStateRef.current = getCurrentState();

  // Function to process the next update in the queue.
  const processQueue = useCallback(() => {
    // If already processing or if queue is empty, do nothing.
    if (processing || queue.length === 0) {
      return;
    }

    // Get the next update function from the queue.
    const nextUpdate = queue[0];
    setProcessing(true); // Mark as processing.

    try {
      // Execute the update function with the latest state.
      const result = nextUpdate(currentStateRef.current);
      Promise.resolve(result).then(() => {
        // After the update, remove it from the queue and mark processing as false.
        setQueue((currentQueue) => currentQueue.slice(1));
        setProcessing(false);
      });
    } catch (error) {
      // In case of an error, log it and mark processing as false.
      console.error("Error processing update:", error);
      setProcessing(false);
    }
  }, [processing, queue]);

  // Function to add a new update to the queue.
  const enqueueUpdate = useCallback((updateFunction) => {
    // Add a new function to the queue that, when called, executes the updateFunction with the latest state.
    setQueue((currentQueue) => [
      ...currentQueue,
      () => updateFunction(currentStateRef.current),
    ]);
  }, []);

  // Effect to process the queue whenever the queue or processing state changes.
  useEffect(() => {
    processQueue();
  }, [queue, processing, processQueue]);

  // Return the enqueueUpdate function so it can be used by the component.
  return enqueueUpdate;
};

export default useUpdateQueue;
