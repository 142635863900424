import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useUpdateQueue from "../update_queue";
import {
  generateContactToAccountFundMapping,
  recalcAggregates,
} from "./recalc_agg";
import {
  fetchDataInitially,
  nonOnboardedTxListener,
} from "./fetch_initial_tx_data";
import {
  setupDeletedTransactionsListener,
  setupNewTransactionsListener,
} from "./listener_setup";
import dayjs from "dayjs";
import { collection, query, where, getDocs } from "firebase/firestore";
import { removeDuplicatesByField } from "../../utilities/general_util";

function findLastTransactionDate(transactions) {
  if (!transactions || transactions.length === 0) {
    return null; // or some default value
  }

  return transactions.reduce((latestDate, transaction) => {
    const transactionDate = dayjs(transaction.date);
    return transactionDate.isAfter(latestDate) ? transactionDate : latestDate;
  }, dayjs(transactions[0].date));
}

const useFetchAndUpdateTransactions = ({
  // setIsChildDataLoaded,
  org,
  authHook,
  setTransactions,
  experimental,
  orgData,
  setMonthlyAggregates,
  monthlyAggregates,
  accounts,
  transactions,
  setIsChildDataLoaded,
  deleteQueue,
  setContactMapping,
}) => {
  const [dateToRecalcAfter, setDateToRecalcAfter] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Create a function to get the current transactions state and pass this into any queued updates
  const getCurrentTransactions = () =>
    transactions.filter((tx) => !deleteQueue.includes(tx.id)); // This function returns the current transactions state
  const enqueueUpdate = useUpdateQueue(getCurrentTransactions);

  // Now, when enqueueing updates, the current transactions state will be provided to the update functions

  //Fetch the transactions and merge them with existing transactions in the monthlyAggregates
  useEffect(() => {
    let unsubNewTransactions;
    let unsubDeletedTransactions;
    if (authHook?.db) {
      console.log("Fetching transactions");
      //If the org is onboarded, we can fetch the monthlyAggregates and transactions
      if (org && !experimental && orgData?.onboarded) {
        console.log(
          "Org is onboarded, fetch initial monthlyAggregates and setup listeners",
        );
        const normalFetchAndListeners = async () => {
          try {
            //Grabs monthlyAggregates and pulls the tx out of them, need to grab any tx seperately that have a date AFTER this last month
            const initialResults = await fetchDataInitially({
              db: authHook.db,
              org,
            });

            let q;
            const txCollectionRef = collection(
              authHook.db,
              "orgs",
              org,
              "journalEntries",
            );
            if (initialResults.monthlyAggregates === null) {
              console.log(
                "No aggregates for this org - it either doesn't have enough transactions or it's a new org",
              );
              setMonthlyAggregates([]);
              q = query(txCollectionRef);
            } else {
              console.log(
                "Initial state from monthlyAggregates: ",
                initialResults,
              );
              setMonthlyAggregates(initialResults.monthlyAggregates);
              const dateOfLastTx =
                initialResults.monthlyAggregates !== null
                  ? findLastTransactionDate(initialResults.transactions)
                  : null;
              console.log(
                "Date of last tx in aggregates, fetch any tx after: ",
                dateOfLastTx,
              );
              const jsDate = dateOfLastTx.toDate();
              q = query(txCollectionRef, where("date", ">=", jsDate));
            }

            const newTxSnapshot = await getDocs(q);
            const newTx = newTxSnapshot.docs.map((doc) => {
              // Loop over new transactions, creating objects with formatted dates so we can easily merge them with existing transactions
              const entryUnformattedDate = doc.data().date;
              const entryFormattedDate = dayjs(entryUnformattedDate.toDate());
              const entryFormatted = {
                ...doc.data(),
                date: entryFormattedDate,
                id: doc.id,
              };
              if (!entryFormatted.date) {
                console.error(
                  "Error formatting date for transaction: ",
                  entryFormatted,
                );
              }
              return entryFormatted;
            });
            console.log("New tx after last monthly agg: ", newTx);
            const mergedTransactions = [
              ...initialResults.transactions,
              ...newTx,
            ];
            console.log("Merged transactions: ", mergedTransactions);
            const uniqueMergedTransactions = removeDuplicatesByField(
              mergedTransactions,
              "id",
            );
            setTransactions(uniqueMergedTransactions);

            const unsubNewTransactions = setupNewTransactionsListener({
              authHook,
              org,
              enqueueUpdate,
              setTransactions,
              setDateToRecalcAfter,
              dateToRecalcAfter,
              initialResults,
              deleteQueue,
            });

            const unsubDeletedTransactions = setupDeletedTransactionsListener({
              authHook,
              org,
              enqueueUpdate,
              monthlyAggregates,
              setTransactions,
              setDateToRecalcAfter,
              dateToRecalcAfter,
            });

            setInitialLoadComplete(true);
            setIsChildDataLoaded(true);
            return { unsubNewTransactions, unsubDeletedTransactions };
          } catch (error) {
            console.error("Error fetching transactions: ", error);
          }
        };
        normalFetchAndListeners();

        //If the org is not onboarded, we need to setup a listener for new transactions to show the loading process
      } else if (org && !experimental && orgData && !orgData.onboarded) {
        console.log(
          "Org is not onboarded, setting up a different listener for tx",
        );
        setIsChildDataLoaded(true);
        nonOnboardedTxListener({ db: authHook.db, org, setTransactions });
      }
    } else {
      console.log("db not initialized");
    }
    // Cleanup function
    return () => {
      unsubNewTransactions?.();
      unsubDeletedTransactions?.();
    };
  }, [authHook?.db?.initialized, experimental, org, orgData]);

  // Setup a debounce timer to recalculate aggregates after a given date but only after no tx has been received for 3 seconds

  const debounceTimerRef = useRef(null);

  //TODO: This is where we need to recalc all monthlyAggregates after the dateToRecalcAfter changes OR needs to work after a tx is added from a listener
  const handleRecalculation = useCallback(async () => {
    console.log(
      "Recalculating aggregates from :",
      dateToRecalcAfter,
      monthlyAggregates,
    );
    const newMonthlyAgg = await recalcAggregates({
      transactions,
      monthlyAggregates,
      dateToRecalcAfter,
      accounts,
    });
    console.log("New monthlyAggregates AFTER recalc: ", newMonthlyAgg);
    setMonthlyAggregates(newMonthlyAgg);
    const mappedContactToAccountAndFund = generateContactToAccountFundMapping({
      transactions,
    });
    setContactMapping(mappedContactToAccountAndFund);
    setDateToRecalcAfter(null);
  }, [
    transactions,
    monthlyAggregates,
    dateToRecalcAfter,
    accounts,
    setMonthlyAggregates,
    setContactMapping,
  ]);

  const setDebounceTimer = useCallback(() => {
    const debounceDuration =
      monthlyAggregates && monthlyAggregates.length > 0 ? 3000 : 500;

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      handleRecalculation();
    }, debounceDuration);
  }, [monthlyAggregates, handleRecalculation]);

  useEffect(() => {
    if (dateToRecalcAfter !== null && initialLoadComplete) {
      setDebounceTimer();
    }

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [dateToRecalcAfter, initialLoadComplete, setDebounceTimer]);
};

export default useFetchAndUpdateTransactions;
