import { collection, doc, writeBatch } from "firebase/firestore";
import {
  findAccount,
  findContact,
  findFund,
} from "../../../locations/transactions/validation";
import { amountToCentsInt } from "../../general_util";

//Import the initialImportData into the database as journal entries
export const handleImport = async ({
  setSubmitting,
  setNumberToImport,
  initialImportData,
  user,
  setImportedTrans,
  setImportSuccess,
  db,
  accounts,
  contacts,
  funds,
  org,
}) => {
  setSubmitting(true);
  setNumberToImport(initialImportData.length);
  // Note isBatch here is a boolean to mark the last entry in a batch, so only that entry will fire onWrite functions in firestore
  if (initialImportData.length <= 500) {
    //Batched writes for initialImportData to the database if less than 500
    const batch = writeBatch(db);
    initialImportData.forEach((entry, index) => {
      const isLast = index === initialImportData.length - 1;
      const newEntry = {
        date: entry.date,
        checkNumber: entry.checkNumber ? entry.checkNumber : false,
        memo: entry.memo,
        userMadeChange: user.uid,
        addTimestamp: true,
        batch: !isLast,
        imported: true,
        journalEntry: false,
        lines: entry.lines.map((line) => {
          return {
            account: findAccount(line.account, accounts).id,
            contact: findContact(entry.contact, contacts).id,
            fund: findFund(line.fund, funds).id,
            comment: line.comment,
            amount: amountToCentsInt(line.amount),
            sign: line.sign,
          };
        }),
      };
      const collectionRef = collection(db, "orgs", org, "journalEntries");
      batch.set(doc(collectionRef), newEntry);
    });
    try {
      await batch.commit();
      //If we get here, the batched writes were successful
      setImportedTrans(initialImportData);
      setImportSuccess(true);
    } catch (error) {
      setImportSuccess(false);
      console.error("Error writing batched writes: ", error);
    }
  } else {
    const successfulWrites = [];
    // Function to split the initialImportData into chunks of 500, since 500 is the max batch size
    const chunkArray = (array, chunkSize) => {
      const results = [];
      while (array.length) {
        results.push(array.splice(0, chunkSize));
      }
      return results;
    };

    const chunkedInitialImportData = chunkArray(initialImportData, 500);
    const totalChunks = chunkedInitialImportData.length;
    // Batched writes for initialImportData to the database
    let importSuccess = true; // assume success until proven otherwise
    for (let [chunkIndex, chunk] of chunkedInitialImportData.entries()) {
      const batch = writeBatch(db);
      chunk.forEach((entry, entryIndex) => {
        const isLastChunk = chunkIndex === totalChunks - 1;
        const isLastEntry = entryIndex === chunk.length - 1;
        const isLast = isLastChunk && isLastEntry;
        const newEntry = {
          date: entry.date,
          memo: entry.memo,
          checkNumber: entry.checkNumber ? entry.checkNumber : false,
          userMadeChange: user.uid,
          addTimestamp: true,
          isBatch: !isLast,
          imported: true,
          journalEntry: false,
          lines: entry.lines.map((line) => {
            return {
              account: findAccount(line.account, accounts).id,
              contact: findContact(entry.contact, contacts).id,
              fund: findFund(line.fund, funds).id,
              comment: line.comment,
              amount: amountToCentsInt(line.amount),
              sign: line.sign,
            };
          }),
        };
        const collectionRef = collection(db, "orgs", org, "journalEntries");
        // console.log("Adding entry: ", newEntry);
        batch.set(doc(collectionRef), newEntry);
      });
      try {
        await batch.commit();
        // successfulWrites.push(...chunk);
        setImportedTrans((prevState) => {
          return [...prevState, ...chunk];
        });
      } catch (error) {
        importSuccess = false;
        console.error("Error writing batched writes: ", error);
        break; // stop writing remaining batches after first error
      }
    }
    // setImportedTrans(successfulWrites);
    setImportSuccess(importSuccess);
  }
};
