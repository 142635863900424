import { useContext, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore"; // Add missing imports
import UserContext from "../../../assets/user_context";

export const useSetRecs = ({
  selectedAccount,
  experimental,
  db,
  setMostRecentRec,
  setRecsForAcct,
  setRecInProgress,
  recInProgress,
  setProcessingRec,
}) => {
  const { experimentalReconcilliations, org } = useContext(UserContext);

  useEffect(() => {
    if (selectedAccount) {
      setRecInProgress({});
      console.log(
        `Checking for recs for ${selectedAccount} ${
          experimental ? "in experimental" : ""
        }`,
      );
      if (!experimental) {
        //Get completed reconcilliations
        const recsRef = collection(db, "orgs", org, "reconciliations");
        //query to get recs with the account === selectedAccount.id
        const recsQuery = query(
          recsRef,
          where("account", "==", selectedAccount),
        );
        //get the docs
        const unsubRecs = onSnapshot(recsQuery, async (snapshot) => {
          if (snapshot.docs.length > 0) {
            const recs = [];
            snapshot.docs.forEach((doc) => {
              console.log("Rec Doc:", doc.data());
              if (doc.data().complete) {
                recs.push({ ...doc.data(), id: doc.id });
                if (doc.id === recInProgress.id) {
                  console.log(
                    "clearing rec in progress, because it's complete",
                  );
                  setRecInProgress({});
                  // setProcessingRec(false);
                }
              } else if (doc.data().inProgress || !doc.data().complete) {
                console.log("setting recInProgress", doc.data());
                setRecInProgress({ ...doc.data(), id: doc.id });
              }
              if (
                snapshot.docs.every(
                  (doc) =>
                    doc.data().inProgress === false &&
                    doc.data().complete === true,
                )
              ) {
                setRecInProgress({});
                setProcessingRec(false);
              }
            });
            //Find the most recent rec (by closing date)
            const mostRecentRecFromListener =
              recs.length > 0
                ? recs.reduce((prev, current) => {
                    return prev.closingDate > current.closingDate
                      ? prev
                      : current;
                  })
                : null;
            console.log("mostRecentRec", mostRecentRecFromListener);
            setMostRecentRec(mostRecentRecFromListener);
            setRecsForAcct(recs);
          } else {
            console.log("no recs");
            setRecsForAcct([]);
            setMostRecentRec(null);
          }
        });
        return unsubRecs;
      } else {
        //set recs from the context of userContext
        if (experimentalReconcilliations?.length > 0) {
          const mostRectRecFromContext =
            experimentalReconcilliations.length > 0
              ? experimentalReconcilliations.reduce((prev, current) => {
                  return prev.closingDate > current.closingDate
                    ? prev
                    : current;
                })
              : null;
          console.log("mostRecentRec", mostRectRecFromContext);
          setMostRecentRec(mostRectRecFromContext);
          setRecsForAcct(experimentalReconcilliations);
        } else {
          setRecsForAcct([]);
        }
      }
    }
  }, [
    db,
    org,
    selectedAccount,
    experimentalReconcilliations,
    setRecInProgress,
    experimental,
    setMostRecentRec,
    setRecsForAcct,
    setProcessingRec,
  ]);
}; // Remove extra closing curly brace

export const useParsedRecForDisplay = ({ recToDisplay, setRecToDisplay }) => {
  const { accounts, transactions } = useContext(UserContext);
  useEffect(() => {
    if (recToDisplay && !recToDisplay.sorted) {
      //Sort all the tx that are cleared into credits and debits, calculate totals, and set the state
      const isAsset =
        accounts.find((account) => account.id === recToDisplay.account)
          .accountType === "Assets";

      const newRecToDisplayState = recToDisplay;
      newRecToDisplayState.sorted = true;

      const clearedTx = transactions.filter((tx) =>
        newRecToDisplayState.clearedTxIds.includes(tx.id),
      );
      let unclearedTx, unclearedDebits, unclearedCredits;

      if (newRecToDisplayState.unClearedTxIds.length > 0) {
        unclearedTx = transactions.filter((tx) =>
          newRecToDisplayState.unClearedTxIds.includes(tx.id),
        );
        unclearedDebits = unclearedTx.filter((tx) =>
          tx.lines
            .filter((line) => line.account === recToDisplay.account)
            .some((line) => line.sign === "debit"),
        );
        unclearedCredits = unclearedTx.filter((tx) =>
          tx.lines
            .filter((line) => line.account === recToDisplay.account)
            .some((line) => line.sign === "credit"),
        );
      } else {
        unclearedDebits = [];
        unclearedCredits = [];
      }

      const clearedDebitsLines = clearedTx.filter((tx) =>
        tx.lines
          .filter((line) => line.account === recToDisplay.account)
          .some((line) => line.sign === "debit"),
      );
      const clearedDebits = clearedDebitsLines.map((tx) => {
        const relLines = tx.lines.filter(
          (line) => line.account === recToDisplay.account,
        );
        const amount = relLines.reduce((prev, current) => {
          return prev + parseFloat(current.amount);
        }, 0);
        return {
          ...tx,
          lines: [
            {
              account: relLines[0].account,
              contact: relLines[0].contact,
              amount: amount,
              sign: relLines[0].sign,
              date: tx.date,
            },
          ],
        };
      });
      const clearedCreditsLines = clearedTx.filter((tx) =>
        tx.lines
          .filter((line) => line.account === recToDisplay.account)
          .some((line) => line.sign === "credit"),
      );

      const clearedCredits = clearedCreditsLines.map((tx) => {
        const relLines = tx.lines.filter(
          (line) => line.account === recToDisplay.account,
        );
        const amount = relLines.reduce((prev, current) => {
          return prev + parseFloat(current.amount);
        }, 0);
        return {
          ...tx,
          lines: [
            {
              account: relLines[0].account,
              contact: relLines[0].contact,
              amount: amount,
              sign: relLines[0].sign,
              date: tx.date,
            },
          ],
        };
      });

      const clearedDebitTotal = clearedDebits.reduce((prev, current) => {
        return prev + current.lines[0].amount;
      }, 0);
      const clearedCreditTotal = clearedCredits.reduce((prev, current) => {
        return prev + current.lines[0].amount;
      }, 0);
      let totalClearedMovement;
      if (isAsset) {
        totalClearedMovement = clearedDebitTotal - clearedCreditTotal;
      } else {
        totalClearedMovement = clearedCreditTotal - clearedDebitTotal;
      }
      const unclearedDebitTotal = unclearedDebits.reduce((prev, current) => {
        return (
          prev +
          current.lines
            .filter((line) => line.account === recToDisplay.account)
            .reduce((prev, current) => {
              return prev + parseFloat(current.amount);
            }, 0)
        );
      }, 0);
      const unclearedCreditTotal = unclearedCredits.reduce((prev, current) => {
        return (
          prev +
          current.lines
            .filter((line) => line.account === recToDisplay.account)
            .reduce((prev, current) => {
              return prev + parseFloat(current.amount);
            }, 0)
        );
      }, 0);
      const clearedIn = isAsset ? clearedDebitTotal : clearedCreditTotal;
      const clearedOut = isAsset ? clearedCreditTotal : clearedDebitTotal;
      setRecToDisplay({
        ...newRecToDisplayState,
        clearedDebits,
        clearedCredits,
        clearedOut: clearedOut,
        clearedIn: clearedIn,
        unclearedCredits,
        unclearedDebits,
        clearedDebitTotal,
        clearedCreditTotal,
        totalClearedMovement,
        unclearedDebitTotal,
        unclearedCreditTotal,
      });
    }
  }, [accounts, recToDisplay, transactions]);
};
