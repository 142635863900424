import React from "react";
import { SideBySideDiv } from "../account_styles";

import UserDetails from "./user_details";
import PasswordReset from "./password_reset";

const Settings = () => {
  return (
    <React.Fragment>
      <SideBySideDiv style={{ marginTop: "4rem" }}>
        <UserDetails />
        <PasswordReset />
      </SideBySideDiv>
    </React.Fragment>
  );
};

export default Settings;
