import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  primary_color,
  primary_dark_color,
} from "../constants/color_constants";
import FoundationOutlinedIcon from "@mui/icons-material/FoundationOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Key } from "@mui/icons-material";

const withFilteredProps = (WrappedComponent) => {
  return ({ isActive, ...props }) => {
    return <WrappedComponent {...props} />;
  };
};

const FilteredNavLink = withFilteredProps(NavLink);

const StyledNavLink = styled(FilteredNavLink)`
  color: ${primary_dark_color};
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-top: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    width: 40px;
    height: 40px;
    background-color: ${primary_color};
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 15px;
    opacity: ${(props) => (props.isActive ? "1" : "0")};
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
    left: -42px;
  }
`;

const NavLinkText = styled.span`
  opacity: ${(props) => (props.collapsed ? "0" : "1")};
  visibility: ${(props) => (props.collapsed ? "hidden" : "visible")};
  transition:
    opacity 0.3s ease,
    visibility 0s ease;
  width: ${(props) => (props.collapsed ? "0px" : "auto")};
`;

const StyledSpacerDiv = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const ParentNavLink = ({ to, collapsed, children }) => {
  const location = useLocation();
  const parentLocation = to.split("/")[1];
  const [isActive, setIsActive] = useState(
    location.pathname.includes(parentLocation),
  );

  useEffect(() => {
    setIsActive(location.pathname.split("/")[1].includes(parentLocation));
  }, [location]);

  return (
    <StyledNavLink isActive={isActive} to={to}>
      {children === "FOUNDATION" && <FoundationOutlinedIcon fontSize="large" />}
      {children === "UPKEEP" && <AccountBalanceOutlinedIcon fontSize="large" />}
      {children === "REPORTS" && <AssessmentOutlinedIcon fontSize="large" />}
      {children === "HISTORY" && <HistoryEduOutlinedIcon fontSize="large" />}
      {children === "CONTACTS" && <ContactsOutlinedIcon fontSize="large" />}
      {children === "ADMIN" && <Key fontSize="large" />}
      {children === "ACCOUNT" && <SettingsOutlinedIcon fontSize="large" />}
      <StyledSpacerDiv />
      <NavLinkText collapsed={collapsed}>{children}</NavLinkText>
    </StyledNavLink>
  );
};

export default ParentNavLink;
