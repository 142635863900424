import React from "react";
import styled from "@emotion/styled";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import AddCircle from "@mui/icons-material/AddCircle";
import {
  primary_highlight_color,
  primary_dark_color,
  primary_color,
} from "../constants/color_constants.jsx";
import { useNavigate } from "react-router-dom";
import ConstructionImage from "../assets/construction.png";
import { TextField, Box } from "@mui/material";

const NavBackWrapper = styled.div`
  text-decoration: none;
  color: ${primary_dark_color};
  margin-top: 2rem;
  margin-left: 1.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

//This will help clean up media queries
const mediaQuery = (maxWidth) => `
  @media (max-width: ${maxWidth}px)
`;

const BackArrow = styled(ArrowBackIosOutlinedIcon)`
  color: ${primary_dark_color};
  margin-right: 0.75rem;
  cursor: pointer;
`;

export const NavBack = ({ children }) => {
  const navigate = useNavigate();

  return (
    <NavBackWrapper onClick={() => navigate(-1)}>
      <BackArrow fontSize="large" />
      {children}
    </NavBackWrapper>
  );
};

export const ComponentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const UnderConstructionImage = styled.img`
  /* width: 80vw; */
  max-width: 100%;
  max-height: 100%;
  margin-top: 2rem;
  border-radius: 5rem;
`;

const UnderConstructionText = styled.p`
  position: absolute;
  top: 3rem;
  font-size: 2.8rem;
  font-weight: bold;
  margin-top: 2rem;
  color: ${primary_dark_color};
  @media (max-width: 1400px) {
    font-size: 2.5rem;
  }
  @media (max-width: 1200px) {
    font-size: 2.1rem;
  }
  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }
`;

const ComingSoonText = styled.p`
  position: absolute;
  bottom: 0;
  right: 12%;
  font-size: 2.8rem;
  font-weight: bold;
  margin-top: 2rem;
  color: ${primary_dark_color};
  @media (max-width: 1400px) {
    font-size: 2.5rem;
  }
  @media (max-width: 1200px) {
    font-size: 2.1rem;
  }
  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }
`;

const ConstructionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 85vh;
`;

export const UnderConstruction = ({ children }) => {
  return (
    <ConstructionWrapper>
      <UnderConstructionText>
        The {children} Feature is Under Construction
      </UnderConstructionText>
      <UnderConstructionImage src={ConstructionImage} />
      <ComingSoonText>Coming Soon...</ComingSoonText>
    </ConstructionWrapper>
  );
};

export const PageWrapper = styled.div`
  margin-left: 1vw;
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1vw;
`;

export const TitleText = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  color: ${primary_dark_color};
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
`;

export const SubtitleText = styled.p`
  font-size: 1.5rem;
  font-family: "MontserratBold";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: center;
`;

const ClickableDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding: 3px;
  border: 3px solid ${primary_dark_color};
  border-radius: 17px;
`;

const StyledAdd = styled(AddCircle)`
  color: ${primary_dark_color};
  margin-right: 0.3rem;
`;

export const AddButton = ({ children, onClick }) => {
  return (
    <ClickableDiv onClick={onClick}>
      <StyledAdd fontSize="large" />
      <SubtitleText>{children}</SubtitleText>
    </ClickableDiv>
  );
};

export const AddSearchWrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSearch = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border-width: 3px; // Change this for a thicker or thinner border
    }
    &:hover fieldset {
      border-width: 4px; // Change this for a thicker or thinner border on hover
    }
    &.Mui-focused fieldset {
      border-width: 4px; // Change this for a thicker or thinner border when focused
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      // Change this for a larger font size when the label is shrunk (floating above the box)
      font-size: 2rem;
    }
  }
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ wideVersion }) => wideVersion && "min-width: 90vw;"}
  background-color: white;
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
`;

export const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${primary_highlight_color}; // Color of the label when focused
  }
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primary_highlight_color};
      border-width: 2px;
    }
  }
`;

export const DefinedWidthDiv = styled.div`
  width: ${({ width }) => width};
`;

export const CenteringDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const SpecialTitleText = styled.p`
  font-size: 2.6rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: center;
  margin: 0;
  letter-spacing: 0.2rem;
  text-shadow:
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    -3px 0 0 #000,
    3px 0 0 #000,
    0 -3px 0 #000,
    0 3px 0 #000;
`;

export const TopRowDiv = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

export const TightRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

//Responsive Items organized here
export const LineText = styled.p`
  font-size: 1.5rem;
  font-family: "MontserratBold";
  margin: 0.3rem;
  color: ${primary_dark_color};
  text-align: left;
  ${mediaQuery(1800)} {
    font-size: 1.4rem;
  }
  ${mediaQuery(1400)} {
    font-size: 1.3rem;
  }
  ${mediaQuery(1200)} {
    font-size: 1.2rem;
    font-stretch: condensed;
  }
  ${mediaQuery(1000)} {
    font-size: 1rem;
  }
  ${mediaQuery(900)} {
    font-size: 0.9rem;
  }
  ${(props) =>
    props.attribute !== "Amount"
      ? `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`
      : null}
`;
