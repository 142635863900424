import React, { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import { FundNumber } from "./funds_list_styles";
import UserContext from "../../../assets/user_context";
import { useAuth } from "../../../services/use-auth";
import { doc, updateDoc } from "firebase/firestore";
import { NoAccountsOutlined } from "@mui/icons-material";

const RenderFundRow = ({
  fund,
  funds,
  groupObj,
  setEditingData,
  setShowEditModal,
  child,
  parent,
}) => {
  const { org, experimental, setFunds, orgRoles } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;
  const [changing, setChanging] = useState(false);
  const identifier = child ? fund : fund.id;
  const fundData = funds.find((f) => f.id === identifier);

  const setFundEnabled = async (newStatus) => {
    if (!experimental) {
      console.log("Fund to Enable/Disable: ", fundData.id, org);
      await updateDoc(doc(db, "orgs", org, "funds", fundData.id), {
        disabled: newStatus,
      });
      setChanging(false);
    } else {
      console.log("EXPERIMENTAL Fund to Enable/Disable: ", fundData.id, org);
      setFunds((prev) => {
        const newFunds = prev.map((f) => {
          if (f.id === fundData.id) {
            return { ...f, disabled: !f.disabled };
          } else {
            return f;
          }
        });
        return newFunds;
      });
      setChanging(false);
    }
  };

  let parentFund;
  if (child) {
    //Get Parent Fund
    parentFund = funds.find((f) => f.id === parent.id);
  } else {
    parentFund = null;
  }

  return (
    <React.Fragment>
      {fund && fundData && (
        <TableRow
          key={`${fundData.fundNumber}-fund-row`}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}>
          <TableCell
            sx={{ paddingLeft: child ? "40px" : null }}
            component="th"
            scope="row">
            {child ? (
              <Tooltip title={`Sub Fund of ${parentFund.fundName}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SubdirectoryArrowRightIcon style={{ marginRight: "10px" }} />

                  <FundNumber>{fundData.fundNumber}</FundNumber>
                </div>
              </Tooltip>
            ) : (
              <FundNumber>{fundData?.fundNumber}</FundNumber>
            )}
          </TableCell>

          <TableCell align="right">{fundData?.fundName}</TableCell>
          <TableCell align="right">
            {fundData?.restricted ? "Donor Restricted" : null}
          </TableCell>
          <TableCell align="center">
            {orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ? (
              <Tooltip title={`Edit ${fundData?.fundName}`}>
                <EditIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const dataForFundToEdit = {
                      group: groupObj.groupName,
                      fundNumber: fundData.fundNumber,
                      fundName: fundData.fundName,
                      fundDatabaseId: identifier,
                      subFund: child,
                      subFundOf: child ? parentFund : null,
                      isRestricted: fundData.isRestricted ? true : false,
                    };
                    console.log("Fund to Edit: ", dataForFundToEdit);
                    setEditingData(dataForFundToEdit);
                    setShowEditModal(true);
                  }}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip
                title={`No editing permissions as ${orgRoles.join(" ")}.`}>
                <NoAccountsOutlined />
              </Tooltip>
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
            {orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ? (
              <Switch
                color="secondary"
                checked={!fundData.disabled}
                onChange={() => {
                  setChanging(true);
                  console.log("Fund to Enable/Disable: ", fundData);
                  setFundEnabled(!fundData.disabled);
                }}
                disabled={changing}
              />
            ) : (
              <Tooltip
                title={`No editing permissions as ${orgRoles.join(" ")}.`}>
                <NoAccountsOutlined />
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default RenderFundRow;
