import React from "react";
import {
  StyledTableRow,
  StyledTableItem,
  TableText,
  TableTextSmall,
} from "../mass_import_styles";
import CheckIcon from "@mui/icons-material/Check";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Tooltip } from "@mui/material";
import { parseDate } from "./process-functions";
import dayjs from "dayjs";

const getOnlyNumber = (str) => {
  const result = str.replace(/\D/g, "");
  return result.length > 0 ? result : null;
};

export const TransactionRow = (props) => {
  return (
    <React.Fragment>
      {props.transaction.lines.map((line, index) => {
        return (
          <StyledTableRow
            key={`${index}-TransLine`}
            error={props.unbalancedTX[props.indexMaster]}>
            <StyledTableItem>
              {index === 0 ? (
                props.transaction.date ? (
                  props.transaction.date.toLocaleString(undefined, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                ) : (
                  <TableText
                    error={true}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.setEntryToEdit({
                        ...props.transaction,
                        arrPosition: props.indexMaster,
                      })
                    }>
                    Date Error
                  </TableText>
                )
              ) : null}
            </StyledTableItem>
            <StyledTableItem>
              <TableTextSmall>
                {index === 0
                  ? props.transaction.checkNumber !== null &&
                    props.transaction.checkNumber !== undefined &&
                    props.transaction.checkNumber !== ""
                    ? `Check-${props.transaction.checkNumber}, ${props.transaction.memo}`
                    : `${props.transaction.memo}`
                  : null}
              </TableTextSmall>
            </StyledTableItem>
            <StyledTableItem>
              {props.transactionsMatchErrors.some((tx) =>
                tx.some((line) => line.fund),
              ) ? (
                <TableText>Handle Fund Errors First</TableText>
              ) : props.transactionsMatchErrors[props.indexMaster][index]
                  .account ? (
                <Tooltip
                  title={
                    "No matching account, create one or match to current account?"
                  }>
                  <TableText
                    error={true}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setCurrentLineBeingEdited({
                        transactionArrPos: props.indexMaster,
                        lineArrPos: index,
                      });
                      props.setAccountNumberToAdd(line.account);
                      props.setPossibleAccountNameToAdd(
                        line.accountText ? line.accountText : "",
                      );
                      props.setAddAccountModalOpen(true);
                    }}>
                    {line.account}
                  </TableText>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`Matching account found: ${
                    props.accounts.filter(
                      (account) =>
                        account.accountNumber.toString() ===
                        line.account.toString(),
                    )[0].accountName
                  }`}>
                  <TableText error={false}>{line.account}</TableText>
                </Tooltip>
              )}
            </StyledTableItem>
            <StyledTableItem>
              {props.transactionsMatchErrors[props.indexMaster][index].fund ? (
                <Tooltip
                  title={
                    "No matching fund, create one or match to current fund?"
                  }>
                  {
                    <TableText
                      style={{ cursor: "pointer" }}
                      error={true}
                      onClick={() => {
                        props.setCurrentLineBeingEdited({
                          transactionArrPos: props.indexMaster,
                          lineArrPos: index,
                        });
                        props.setFundNumberToAdd(
                          getOnlyNumber(line.fund ? line.fund : line.fundText),
                        );
                        props.setPossibleFundNameToAdd(
                          line.fundText ? line.fundText : "",
                        );
                        props.setAddFundModalOpen(true);
                      }}>
                      {line.fund ? line.fund : line.fundText}
                    </TableText>
                  }
                </Tooltip>
              ) : (
                <Tooltip
                  title={`Matching fund found: ${
                    props.funds.filter(
                      (fund) =>
                        fund.fundNumber.toString() === line.fund.toString() ||
                        fund.fundName === line.fund,
                    )[0].fundName
                  }`}>
                  <TableText error={false}>{line.fund}</TableText>
                </Tooltip>
              )}
            </StyledTableItem>
            <StyledTableItem>
              <TableTextSmall>{props.transaction.contact}</TableTextSmall>
            </StyledTableItem>
            <StyledTableItem>
              <TableTextSmall>{line.comment}</TableTextSmall>
            </StyledTableItem>
            <StyledTableItem>
              {line.sign === "debit" ? line.amount.toFixed(2) : ""}
            </StyledTableItem>
            <StyledTableItem>
              {line.sign === "credit" ? line.amount.toFixed(2) : ""}
            </StyledTableItem>
            <StyledTableItem>
              {index === 0 ? (
                props.unbalancedTX[props.indexMaster] ? (
                  <Tooltip
                    title={
                      "These transactions are unbalanced. Click to reapir."
                    }>
                    <HandymanIcon
                      onClick={() => {
                        console.log(props.indexMaster);
                        props.setEntryToEdit({
                          ...props.transaction,
                          date: dayjs(props.transaction.date),
                          dateText: props.transaction.date.toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            },
                          ),
                          arrPosition: props.indexMaster,
                        });
                      }}
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Transactions Balance"}>
                    <CheckIcon style={{ color: "white" }} />
                  </Tooltip>
                )
              ) : null}
            </StyledTableItem>
          </StyledTableRow>
        );
      })}
    </React.Fragment>
  );
};
