import React, { useContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";

import ExportCSV from "./exportCSV";
import {
  validateAccountsAndFunds,
  validateAmountsBalance,
  validateDataTypes,
} from "../../locations/transactions/validation";

import {
  ModalMaster,
  ModalMasterBox,
  ModalMasterInner,
  ModalFooter,
  CenteringDiv,
  PageTitle,
  LineText,
  LoadingLogoStyled,
  DescriptiorText,
} from "./mass_import_styles";

import StyledButton from "../../assets/buttons";
import MassAddContacts from "./contact_import/mass_add_contacts";
import UserContext from "../../assets/user_context";
import { choosePowerStartingBalances } from "../../locations/onboarding/onboarding_logic";
import { Box, LinearProgress } from "@mui/material";
import { MassAddAccountsModal } from "./mass_import_modals";
import { handleImport } from "./transaction_import/handle_mass_import";
import CSVImporter from "./transaction_import/CSVImporter";
import TransactionErrorHandling from "./transaction_import/transaction_error_handling";
import { processTrans } from "./transaction_import/process-functions";

const InitialImportWorkflow = (props) => {
  const { user, transactions } = useContext(UserContext);

  const [initialImportData, setInitialImportData] = useState([]);
  const [unbalancedTX, setUnbalancedTX] = useState([]);
  const [transactionParsingErrors, setTransactionParsingErrors] = useState([]);
  const [transactionsMatchErrors, setTransactionsMatchErrors] = useState([]);
  const [allErrorsHandled, setAllErrorsHandled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [importedTrans, setImportedTrans] = useState([]);

  const [massAddAccountsOpen, setMassAddAccountsOpen] = useState(false);
  const [massAddContactsOpen, setMassAddContactsOpen] = useState(false);

  const [transactionsProcessed, setTransactionsProcessed] = useState([]);
  const [updateString, setUpdateString] = useState("");
  const [numberToImport, setNumberToImport] = useState(0);

  const setData = (data) => {
    setInitialImportData(processTrans(data, { funds: props.funds }));
  };

  //useEffect to set listener for orgs", org, "importedData","initialTransactions",
  useEffect(() => {
    const importedDataRef = doc(
      props.db,
      "orgs",
      props.org,
      "importedData",
      "initialTransactions",
    );
    const unsubscribe = onSnapshot(importedDataRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setTransactionsProcessed(data.csv);
        setUpdateString(data.dateString);
      }
    });
    return () => unsubscribe();
  }, [props.db, props.org]);

  //Set the massAddAccounts to true when the prop "initial" is passed in, since this means this is
  //the first time the user is importing data
  useEffect(() => {
    if (
      props.initial &&
      initialImportData.length > 0 &&
      !props.accounts?.length > 0
    ) {
      setMassAddAccountsOpen(true);
    }
  }, [initialImportData.length, props.accounts?.length, props.initial]);

  //Set the massAddContacts to true when the prop "initial" is passed in, since this means this is
  //the first time the user is importing data, but also once the accounts have been added if there are no accounts
  useEffect(() => {
    if (
      props.initial &&
      initialImportData.length > 0 &&
      props.accounts?.length > 0 && //accounts have been added
      !props.contacts?.length > 0 //contacts have not been added
    ) {
      setMassAddContactsOpen(true);
    }
  }, [
    initialImportData.length,
    props.accounts?.length,
    props.contacts?.length,
    props.initial,
  ]);

  // // log initialImportData when it changes
  // useEffect(() => {
  //   console.log("initialImportData changed: ", initialImportData);
  // }, [initialImportData]);

  //Check if all transactions balance by fund and each transaction balances credits and debits when the data, funds and accounts are updated
  useEffect(() => {
    //Validate initialImportData to make sure all journal entries balance credits and debits by fund, and each journal entry balances credits and debits
    if (initialImportData.length > 0) {
      const { errors, errorsDetails } = validateDataTypes(initialImportData);
      setTransactionParsingErrors(errors);
      setUnbalancedTX(validateAmountsBalance(initialImportData));
      setTransactionsMatchErrors(
        validateAccountsAndFunds(
          initialImportData,
          props.accounts,
          props.funds,
        ),
      );
    }
  }, [initialImportData, props.accounts, props.funds]);

  //Check if all errors have been handled whenever the errrors JSON objects are updated
  useEffect(() => {
    if (
      unbalancedTX.length !== 0 &&
      (transactionsMatchErrors.length !== transactionParsingErrors.length) !== 0
    ) {
      const allBalanced = unbalancedTX.every((transaction) => {
        return !transaction;
      });
      const allMatch = transactionsMatchErrors.every((transaction) => {
        const transOK = transaction.every(
          (line) => !line.account && !line.fund,
        );
        return transOK;
      });
      const allParsed = transactionParsingErrors.every((transaction) => {
        return !transaction;
      });
      if (allBalanced && allMatch && allParsed) {
        setAllErrorsHandled(true);
      }
    }
  }, [unbalancedTX, transactionsMatchErrors, transactionParsingErrors]);

  // useEffect(() => {
  //   console.log("Loaded component 'MassImport'", initialImportData);
  // }, []);

  return (
    <div>
      <PageTitle style={{ marginTop: "0.25rem", paddingTop: "0" }}>
        Import Transactions
      </PageTitle>
      {initialImportData.length === 0 &&
        !importSuccess &&
        !submitting &&
        transactionsProcessed?.length === 0 && (
          <CSVImporter
            setData={setData}
            headerOptions={[
              "Date",
              "Contact",
              "Account",
              "Fund",
              "Check Number",
              "Credit",
              "Debit",
              "Memo",
              "Comment",
            ]}
            requiredHeaders={[
              ["Date", "Contact", "Account", "Fund", "Credit", "Debit"],
            ]}
            confirmButtonText={"Transaction Format"}
          />
        )}

      {/* Modal for Adding all accounts in tx */}
      <MassAddAccountsModal
        {...props}
        massAddAccountsOpen={massAddAccountsOpen}
        setShowModal={setMassAddAccountsOpen}
        transactionsArg={initialImportData}
      />

      <ModalMaster open={massAddContactsOpen}>
        <ModalMasterBox wideVersion="false">
          <ModalMasterInner>
            <MassAddContacts
              authUser={props.authUser}
              org={props.org}
              db={props.db}
              stillLoading={props.stillLoading}
              setShowModal={setMassAddContactsOpen}
              transactionsArg={initialImportData}
            />
          </ModalMasterInner>
        </ModalMasterBox>
      </ModalMaster>

      {/* 
      Modal for Editing a Transaction
      <RepairTransactionModal
        entryToEdit={entryToEdit}
        setEntryToEdit={setEntryToEdit}
        registerChanges={registerChanges}
        accounts={props.accounts}
        funds={props.funds}
        setInitialImportData={setInitialImportData}
        initialImportData={initialImportData}
        initial={props.initial}
      /> */}

      {initialImportData.length > 0 &&
        !importSuccess &&
        !submitting &&
        (!props.initial || props.accounts) && (
          <div>
            <DescriptiorText style={{ textAlign: "center" }}>
              Please review the transactions below and confirm that they are
              correct.
            </DescriptiorText>
            <TransactionErrorHandling
              setInitialImportData={setInitialImportData}
              accounts={props.accounts}
              funds={props.funds}
              initialImportData={initialImportData}
              initial={props.initial}
              accountsHierarchy={props.accountsHierarchy}
              setAllErrorsHandled={setAllErrorsHandled}
            />

            {allErrorsHandled && (
              <ModalFooter>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                  <StyledButton
                    bright
                    height={"40px"}
                    fontSize={"1.6rem"}
                    onClick={(event) => {
                      event.preventDefault();
                      handleImport({
                        setSubmitting,
                        setNumberToImport,
                        initialImportData,
                        user,
                        setImportedTrans,
                        setImportSuccess,
                        db: props.db,
                        accounts: props.accounts,
                        contacts: props.contacts,
                        funds: props.funds,
                        org: props.org,
                      });
                    }}>
                    Import
                  </StyledButton>
                </div>
              </ModalFooter>
            )}
          </div>
        )}

      {(transactionsProcessed?.length > 0 ||
        (importSuccess && importedTrans.length !== 0)) &&
        !waitingForServer && (
          <div>
            <CenteringDiv>
              <LineText>Transactions Imported!</LineText>
              <LineText>
                Please click below to get an updated .csv file with any
                changes/alterations you made during the import process.
              </LineText>
              <ExportCSV
                transactionsData={importedTrans}
                transactionsProcessed={transactionsProcessed}
                db={props.db}
                org={props.org}
                dateString={updateString}
              />
              <div style={{ height: "25px" }} />
              <LineText>
                Our server is processing your transactions, please wait for the
                process to finish before proceeding.
              </LineText>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((transactions.length
                      ? transactions.filter(
                          (transaction) => !transaction.addTimestamp,
                        ).length
                      : 0) /
                      importedTrans.length) *
                    100
                  }
                />
                <LineText>{`${
                  transactions.length
                    ? transactions.filter(
                        (transaction) => !transaction.addTimestamp,
                      ).length
                    : 0
                }/${importedTrans.length} transactions processed`}</LineText>
              </Box>
              {transactions.length >= importedTrans.length && (
                <StyledButton
                  bright
                  height={"40px"}
                  fontSize={"1.6rem"}
                  onClick={() => {
                    choosePowerStartingBalances({
                      db: props.db,
                      org: props.org,
                    });
                    setWaitingForServer(true);
                  }}>
                  Next Step
                </StyledButton>
              )}
            </CenteringDiv>
          </div>
        )}

      {submitting && !importSuccess && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignContent: "center",
          }}>
          <LoadingLogoStyled
            style={{ transform: "translate(0, 25px)", height: "25vh" }}
          />
          <Box sx={{ width: "100%" }}>
            <LineText>{`${
              importedTrans?.length > 0 ? importedTrans.length : 0
            }/${numberToImport} transactions imported`}</LineText>
          </Box>
          <LineText>Importing...</LineText>
        </div>
      )}
    </div>
  );
};

export default InitialImportWorkflow;
