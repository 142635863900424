import {
  ComponentPageWrapper,
  NavBack,
  UnderConstruction,
} from "../component_styles.jsx";

const Labels = () => {
  return (
    <ComponentPageWrapper>
      <NavBack>FOUNDATION</NavBack>
      <UnderConstruction>Labels</UnderConstruction>
    </ComponentPageWrapper>
  );
};

export default Labels;
