import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  findAccountById,
  findContactNameById,
  findFundById,
} from "../../utilities/general_util";

const exportTransactionsToExcel = async ({
  transactions,
  funds,
  accounts,
  contacts,
  orgData,
}) => {
  // Get today's date and format it as a string
  const todaysDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Transactions");

  // Define columns
  worksheet.columns = [
    { header: "Date", key: "date", width: 15 },
    { header: "Check #", key: "checkNumber", width: 10 },
    { header: "Contact", key: "contact", width: 25 },
    { header: "Memo", key: "memo", width: 30 },
    { header: "Comment", key: "comment", width: 30 },
    { header: "Account", key: "account", width: 25 },
    { header: "Fund", key: "fund", width: 25 },
    { header: "Debit", key: "debit", width: 10 },
    { header: "Credit", key: "credit", width: 10 },
    { header: "T3 Transaction ID", key: "transactionId", width: 20 },
  ];

  // Process each transaction
  transactions.forEach((transaction) => {
    const { date, checkNumber, memo, id: transactionId, lines } = transaction;

    // Create rows for each line item
    lines.forEach((line, index) => {
      const { amount, fund, contact, sign, comment, account } = line;
      // Lookup the fund and account details only once per line
      const fundDetails = findFundById({ fundId: fund, funds });
      const accountDetails = findAccountById({ accountId: account, accounts });

      // Format fund and account strings
      const fundFormatted = fundDetails
        ? `${fundDetails.fundNumber || ""} - ${fundDetails.fundName || ""}`
        : "Unknown Fund";
      const accountFormatted = accountDetails
        ? `${accountDetails.accountNumber || ""} - ${
            accountDetails.accountName || ""
          }`
        : "Unknown Account";

      // Determine debit and credit values based on sign
      const debit = sign === "debit" ? amount / 100 : null;
      const credit = sign === "credit" ? amount / 100 : null;

      // Set memo to "Exported by T3 on {todaysDate}" if it's empty and it's the first line of the transaction
      const memoFormatted =
        index === 0
          ? memo === ""
            ? `Exported by T3 on ${todaysDate}`
            : memo
          : "";

      // Add row to the worksheet
      const row = worksheet.addRow({
        date: index === 0 ? new Date(date).toLocaleDateString() : "", // Date only on the first line of each transaction
        checkNumber: index === 0 && checkNumber ? checkNumber : "", // Check # only on the first line of each transaction
        contact: findContactNameById({ contactId: contact, contacts }), // Contact is always included
        memo: memoFormatted, // Memo only on the first line of each transaction
        comment, // Comment is always included
        account: accountFormatted, // Account formatted string
        fund: fundFormatted, // Fund formatted string
        debit, // Debit amount, if applicable
        credit, // Credit amount, if applicable
        transactionId: index === 0 ? transactionId : "", // Transaction ID only on the first line of each transaction
      });

      // Apply currency formatting to debit and credit cells
      if (debit !== null) {
        row.getCell("debit").numFmt = '"$"#,##0.00';
      }
      if (credit !== null) {
        row.getCell("credit").numFmt = '"$"#,##0.00';
      }
    });
  });

  // Generate Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Create the file name using the organization name and today's date
  const fileName = `T3 Transactions Export from ${orgData.orgName} on ${todaysDate}.xlsx`;

  // Save the file using file-saver
  saveAs(new Blob([buffer]), fileName);
};

export default exportTransactionsToExcel;
