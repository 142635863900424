import {
  calcBalanceSheet,
  calcIncomeStatement,
} from "../../utilities/transaction_parser";

// Utility function to format a month number to "MM" format
const formatMonth = (monthNumber) => {
  return monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
};

const combineAccountTotals = ({ newSheetAccounts, existingSheetAccounts }) => {
  //Check if there are any existing accounts
  if (
    (existingSheetAccounts === null ||
      existingSheetAccounts === undefined ||
      existingSheetAccounts.length === 0) &&
    (newSheetAccounts === null ||
      newSheetAccounts === undefined ||
      newSheetAccounts.length === 0)
  ) {
    //There weren't any accounts before, and there still aren't any, so just return an empty array
    return [];
  } else if (
    existingSheetAccounts === null ||
    existingSheetAccounts === undefined ||
    existingSheetAccounts.length === 0
  ) {
    //There weren't any accounts before, but there are now, so just return the newSheetAccounts
    return newSheetAccounts;
  } else if (
    newSheetAccounts === null ||
    newSheetAccounts === undefined ||
    newSheetAccounts.length === 0
  ) {
    //There were accounts before, but there aren't any now, so just return the existingSheetAccounts
    return existingSheetAccounts;
  } else {
    //There were accounts before, and there are now, so we need to combine them
    //Loop over newSheetAccounts and check by id if each account exists in existingSheetAccounts. If it does, add the two together. If it doesn't, add the newSheetAccount to the existingSheetAccounts
    const newSheetAccountIds = newSheetAccounts.map((account) => account.id);
    const existingSheetAccountIds = existingSheetAccounts.map(
      (account) => account.id,
    );
    const combinedSheetAccounts = [];
    //Loop over newSheetAccounts and add to combinedSheetAccounts
    newSheetAccounts.forEach((account) => {
      //Check if the account exists in existingSheetAccounts
      if (existingSheetAccountIds.includes(account.id)) {
        //It exists, so add the two together
        const existingAccount = existingSheetAccounts.find(
          (acc) => acc.id === account.id,
        );
        const combinedAccount = {
          id: account.id,
          accountName: account.accountName,
          accountType: account.accountType,
          accountNumber: account.accountNumber,
          total: account.intTotal + existingAccount.intTotal,
          intTotal: account.intTotal + existingAccount.intTotal,
        };
        console.log(
          "Combined account: ",
          combinedAccount,
          "from: ",
          account,
          existingAccount,
        );
        combinedSheetAccounts.push(combinedAccount);
      } else {
        //It doesn't exist, so add the newSheetAccount to combinedSheetAccounts
        combinedSheetAccounts.push(account);
      }
    });
    //Loop over existingSheetAccounts and add any that aren't in combinedSheetAccounts
    existingSheetAccounts.forEach((account) => {
      if (!newSheetAccountIds.includes(account.id)) {
        combinedSheetAccounts.push(account);
      }
    });
    return combinedSheetAccounts;
  }
};

const combineBalSheets = ({ newSheet, existingSheet }) => {
  //TODO: this is adding the totals, but is it pushing the accounts?
  if (existingSheet === null || existingSheet === undefined) {
    //There wasn't a sheet before, and there still isn't one, so just return the newSheet
    return newSheet;
  } else {
    //There eighter was a sheet before, or there is now, or there are old and new so we need to combine them
    const newSheetKeys = Object.keys(newSheet);
    const existingSheetKeys = Object.keys(existingSheet);
    const combinedSheet = {};
    //Loop over newSheet and add to combinedSheet
    newSheetKeys.forEach((key) => {
      //Keys are funds, so loop over the funds and calc new totals
      if (existingSheetKeys.includes(key)) {
        //It (Fund) exists in last month, add the two together. Need to add overall totals but also account totals for each account
        combinedSheet[key] = {
          assets: combineAccountTotals({
            newSheetAccounts: newSheet[key].assets ? newSheet[key].assets : [],
            existingSheetAccounts: existingSheet[key].assets
              ? existingSheet[key].assets
              : [],
          }),
          liabilities: combineAccountTotals({
            newSheetAccounts: newSheet[key].liabilities
              ? newSheet[key].liabilities
              : [],
            existingSheetAccounts: existingSheet[key].liabilities
              ? existingSheet[key].liabilities
              : [],
          }),
          assetsTotal:
            newSheet[key].assetsTotal + existingSheet[key].assetsTotal,
          liabilitiesTotal:
            newSheet[key].liabilitiesTotal +
            existingSheet[key].liabilitiesTotal,
          total: newSheet[key].total + existingSheet[key].total,
        };
      } else {
        //Add the newSheet value to combinedSheet
        combinedSheet[key] = newSheet[key];
      }
    });
    //Loop over existingSheet and add any that aren't in combinedSheet
    existingSheetKeys.forEach((key) => {
      if (!newSheetKeys.includes(key)) {
        combinedSheet[key] = existingSheet[key];
      }
    });
    console.log("Combined sheet: ", combinedSheet);
    return combinedSheet;
  }
};

async function callCreateMonthlyAggregateFunction({
  month,
  transactions,
  accounts,
  lastFundResults,
}) {
  // console.log("Calling createMonthlyAggregate with month: ", month);
  // console.log(
  //   "And last funds: ",
  //   lastFundResults !== null && lastFundResults !== undefined
  //     ? Object.keys(lastFundResults).length
  //     : null,
  // );
  // Grab all transactions that have date that matches this month within this org
  const monthsTx = transactions.filter((tx) => {
    const txMonth = tx.date.toDate().getMonth() + 1;
    const txYear = tx.date.toDate().getFullYear();
    const txMonthString = txMonth < 10 ? `0${txMonth}` : `${txMonth}`;
    const txDateString = `${txYear}-${txMonthString}`;
    return txDateString === month;
  });

  // console.log(`We have ${monthsTx.length} transactions for ${month}`);
  const balanceSheet = await calcBalanceSheet(monthsTx, accounts);
  // Need to add the lastFundResults to the balanceSheet to get accurate balance
  const combinedBalanceSheet = combineBalSheets({
    newSheet: balanceSheet.fundResultsObj,
    existingSheet: lastFundResults,
  });

  const incomeStatement = calcIncomeStatement(
    monthsTx,
    accounts,
  ).fundResultsObj;

  // console.log(
  //   "Returning balance sheet: ",
  //   Object.keys(combinedBalanceSheet).length,
  // );
  return {
    balanceSheet: combinedBalanceSheet,
    incomeStatement,
    transactions: monthsTx,
    id: month,
  };
}

const buildMonthsToRecalc = ({ dateToRecalcAfter }) => {
  // console.log("Build months starting on date: ", dateToRecalcAfter);
  if (dateToRecalcAfter === null || dateToRecalcAfter === undefined) return;
  //Convert the date to a string in the form YYYY-MM as this is the format of the monthlyAggregates ids
  const earliestTxDateMonth = formatMonth(dateToRecalcAfter.getMonth() + 1);
  const earliestDateForNewTxYear = dateToRecalcAfter.getFullYear();
  const firstStaleMonthString = `${earliestDateForNewTxYear}-${earliestTxDateMonth}`;
  console.log("Earliest Tx Date: ", firstStaleMonthString);
  //Build a list of months that are stale, i.e. after and including the earliestTxDate and up to last month using date
  const monthsToRecalc = [];
  const now = new Date();
  const currentMonth = formatMonth(now.getMonth() + 1);
  const currentYear = now.getFullYear();

  let monthToCreate = firstStaleMonthString;
  while (monthToCreate !== `${currentYear}-${currentMonth}`) {
    monthsToRecalc.push(monthToCreate);
    let [year, month] = monthToCreate.split("-").map(Number);
    month = month === 12 ? 1 : month + 1;
    year = month === 1 ? year + 1 : year;
    monthToCreate = `${year}-${formatMonth(month)}`;
  }
  // console.log("Number of months to recalc: ", monthsToRecalc.length);
  return monthsToRecalc;
};

const getFundBalForMonthBeforeFirst = async ({
  monthsToRecalc,
  monthlyAggregates,
}) => {
  if (monthsToRecalc.length > 0) {
    // console.log("Months in to find starting bal: ", monthlyAggregates);
    //Get the month before the first month to create, since this will be used to calculate the opening balances
    const firstMonthToCreate = monthsToRecalc[0];
    const firstMonthToCreateYear = firstMonthToCreate.split("-")[0];
    const firstMonthToCreateMonth = firstMonthToCreate.split("-")[1];
    const monthBeforeFirstToFectch =
      firstMonthToCreateMonth === "01" ? "12" : firstMonthToCreateMonth - 1;
    const yearBeforeFirstToFetch =
      firstMonthToCreateMonth === "01"
        ? firstMonthToCreateYear - 1
        : firstMonthToCreateYear;
    const monthBeforeFirstToFetchString = `${yearBeforeFirstToFetch}-${formatMonth(
      monthBeforeFirstToFectch,
    )}`;
    //FIXME: Error here when the last FreshMonth doesn't exist
    if (
      !monthlyAggregates ||
      monthlyAggregates.length === 0 ||
      !monthlyAggregates.find
    ) {
      console.error("No monthly aggregates found");
      return null;
    }
    // console.log("Current aggregates to start with", monthlyAggregates); //This is an array, with one of the keys being the id of the month (YYYY-MM)
    // console.log(monthBeforeFirstToFetchString);
    const lastFreshMonth = monthlyAggregates.find(
      (month) => month.id === monthBeforeFirstToFetchString,
    );
    if (lastFreshMonth) {
      // console.log("monthsToCreate: ", monthsToRecalc);
      // console.log("lastFreshMonth: ", lastFreshMonth);
      let lastFundResults =
        lastFreshMonth !== null ? lastFreshMonth.balanceSheet : null;
      return lastFundResults;
    } else {
      // console.log("No last fresh month, need to start fresh");
      return null;
    }
  }
};

// Async function to recalculate aggregates based on the given transactions, monthly aggregates, date, and accounts
export const recalcAggregates = async ({
  transactions,
  monthlyAggregates,
  dateToRecalcAfter,
  accounts,
}) => {
  // Await the resolution of the monthlyAggregates promise
  const resolvedMonthlyAggregates = monthlyAggregates;
  const resolvedMonthlyAggregatesAsArray = Array.isArray(
    resolvedMonthlyAggregates,
  )
    ? resolvedMonthlyAggregates
    : Object.entries(resolvedMonthlyAggregates).map(([id, agg]) => {
        return { ...agg, id: id };
      });
  // console.log("Recalcing aggregates after/including date: ", dateToRecalcAfter);
  //console.log("Monthly Aggregates in: ", resolvedMonthlyAggregates);

  // Build a list of months that need recalculation based on the given date
  const monthsToRecalc = buildMonthsToRecalc({ dateToRecalcAfter });

  // Get the fund balance for the month before the first month to be recalculated
  const firstFundResults = await getFundBalForMonthBeforeFirst({
    monthsToRecalc,
    monthlyAggregates: resolvedMonthlyAggregates,
  });

  // Recalculate aggregates for each month in parallel using Promise.all
  // const results = await Promise.all(
  //   monthsToRecalc.map((month, index) =>
  //   { const lastFundResultsIn = index === 0 ? firstFundResults : mostRecentFundResults;
  //     return callCreateMonthlyAggregateFunction({
  //       month,
  //       transactions,
  //       accounts,
  //       lastFundResults: lastFundResultsIn,
  //     })}
  //   ),
  // );
  const calculateMonthlyAggregates = async (
    monthsToRecalc,
    transactions,
    accounts,
    firstFundResults,
  ) => {
    let mostRecentFundResults = firstFundResults;
    const results = [];

    for (const month of monthsToRecalc) {
      console.log(
        "Recalculating month: ",
        month,
        "with last fund: ",
        mostRecentFundResults,
      );
      const result = await callCreateMonthlyAggregateFunction({
        month,
        transactions,
        accounts,
        lastFundResults: mostRecentFundResults,
      });

      // Update mostRecentFundResults with the new balanceSheet for the next iteration
      mostRecentFundResults = result.balanceSheet;

      results.push(result);
    }

    return results;
  };

  // Usage
  const results = await calculateMonthlyAggregates(
    monthsToRecalc,
    transactions,
    accounts,
    firstFundResults,
  );

  // console.log("Results: ", results);

  // Update the resolvedMonthlyAggregates array with the new results
  // Find and replace each month in the array with the new element from results, using the id as the key
  results.forEach((result) => {
    const index = resolvedMonthlyAggregatesAsArray?.findIndex(
      (month) => month.id === result.id,
    );
    if (index !== -1) {
      // Update existing month with new data
      resolvedMonthlyAggregatesAsArray[index] = result;
    } else {
      // Add new month if it doesn't exist in the array
      resolvedMonthlyAggregatesAsArray.push(result);
    }
  });
  // console.log("Monthly Aggregates out: ", resolvedMonthlyAggregates);

  // Return the updated list of monthly aggregates
  return resolvedMonthlyAggregatesAsArray;
};

export const generateContactToAccountFundMapping = ({ transactions }) => {
  let contactToAccountMapping = {};
  let contactToFundMapping = {};

  // Populate account and fund mappings
  transactions.forEach((tx) => {
    tx.lines.forEach((line) => {
      if (line.contact) {
        if (!contactToAccountMapping[line.contact]) {
          contactToAccountMapping[line.contact] = {};
        }
        if (!contactToAccountMapping[line.contact][line.account]) {
          contactToAccountMapping[line.contact][line.account] = 0;
        }
        contactToAccountMapping[line.contact][line.account] += line.amount;

        if (!contactToFundMapping[line.contact]) {
          contactToFundMapping[line.contact] = {};
        }
        if (!contactToFundMapping[line.contact][line.fund]) {
          contactToFundMapping[line.contact][line.fund] = 0;
        }
        contactToFundMapping[line.contact][line.fund] += line.amount;
      }
    });
  });

  // Function to find highest correlation that passes 70% threshold
  const findHighestCorrelation = (mapping) => {
    const result = {};
    Object.keys(mapping).forEach((contact) => {
      const items = mapping[contact];
      const totalAmount = Object.values(items).reduce(
        (acc, val) => acc + val,
        0,
      );
      let highestCorrelation = { item: "", amount: 0 };

      Object.keys(items).forEach((item) => {
        if (items[item] > highestCorrelation.amount) {
          highestCorrelation = { item, amount: items[item] };
        }
      });

      if (highestCorrelation.amount / totalAmount >= 0.5) {
        result[contact] = highestCorrelation.item;
      } else {
        result[contact] = ""; // Empty string if no item meets the threshold
      }
    });
    return result;
  };

  // Apply threshold check and separation logic
  const accountWithHighestCorrelation = findHighestCorrelation(
    contactToAccountMapping,
  );
  const fundWithHighestCorrelation =
    findHighestCorrelation(contactToFundMapping);

  // Combine account and fund mappings
  const contactToAccountFundMappingWithHighestCorrelationSplit = {};
  Object.keys(accountWithHighestCorrelation).forEach((contact) => {
    contactToAccountFundMappingWithHighestCorrelationSplit[contact] = {
      account: accountWithHighestCorrelation[contact],
      fund: fundWithHighestCorrelation[contact],
    };
  });

  console.log(
    "Contact to Account Fund Mapping with highest correlation split: ",
    contactToAccountFundMappingWithHighestCorrelationSplit,
  );

  return contactToAccountFundMappingWithHighestCorrelationSplit;
};
