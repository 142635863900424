import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../assets/user_context";
import CSVImporter from "./transaction_import/CSVImporter";
import { findMatchingContact } from "./transaction_import/process-functions";
import { findAccountById } from "../general_util";
import {
  Checkbox,
  createTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { ModalBox, ModalInner } from "../../locations/component_styles";
import AddContact from "../../locations/contacts/add_contact";
import { useAuth } from "../../services/use-auth";
import { collection, doc, writeBatch } from "firebase/firestore";
import { useListenForImportedTransactions } from "../../locations/banking/banking_utils";
import { DetailText, StyledHeaderText, TitleText } from "./mass_import_styles";
import Transaction from "./handle_banking_transactions/transaction";
import StyledButton, { OverlayedButton } from "../../assets/buttons";
import SelectContact from "./handle_banking_transactions/contact_chooser";
import SelectAccount from "./handle_banking_transactions/account_chooser";
import SelectFund from "./handle_banking_transactions/fund_chooser";
import { ReactComponent as LoadingLogo } from "../../assets/logos/animated-logo-light.svg";
import styled from "@emotion/styled";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

const StyledLoadingLogo = styled(LoadingLogo)`
  z-index: 5;
  width: 10rem;
`;

export const HandleTransactionsNormalFlow = ({
  selectedAccount,
  closeModal,
}) => {
  const authHook = useAuth();
  const db = authHook.db;
  const { accounts, org, user } = useContext(UserContext);
  const [importedToHandle, setImportedToHandle] = useState({});

  const [focusedKey, setFocusedKey] = useState(null);
  const [focusedAspect, setFocusedAspect] = useState(null);
  const [cachedSplitLineAmount, setCachedSplitLineAmount] = useState("");

  //Mass Change Options

  const [selectedTransactions, setSelectedTransactions] = useState({});
  const [massChangesModalOpen, setMassChangesModalOpen] = useState(false);
  const [contactForMassChange, setContactForMassChange] = useState(null);
  const [accountForMassChange, setAccountForMassChange] = useState(null);
  const [fundForMassChange, setFundForMassChange] = useState(null);
  const [massSubmitState, setMassSubmitState] = useState(false);
  const [massRemoveState, setMassRemoveState] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tempCurrentItemsPerPage, setTempCurrentItemsPerPage] = useState(25);
  const inputRef = useRef(null);

  const [selectAll, setSelectAll] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Object.entries(importedToHandle).slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  //When the page is changed, set the selectAll to false if not all on the new page are selected

  useEffect(() => {
    if (selectAll) {
      const selectedKeys = Object.keys(selectedTransactions);
      const selectedKeysOnPage = selectedKeys.filter((key) =>
        currentItems.map(([key, value]) => key).includes(key),
      );
      if (selectedKeysOnPage.length < currentItems.length) {
        setSelectAll(false);
      }
    }
  }, [currentItems, currentPage, selectedTransactions]);

  const selectedAccountType = findAccountById({
    accountId: selectedAccount,
    accounts,
  }).accountType;

  useListenForImportedTransactions({
    selectedAccount,
    accounts,
    user,
    org,
    db,
    setImportedToHandle,
  });

  //useEffect to log cachedSplitLineAmount to the console when it changes
  useEffect(() => {
    console.log(cachedSplitLineAmount);
  }, [cachedSplitLineAmount]);

  // useEffect(() => {
  //   const focusElementByKey = (key) => {
  //     if (focusedKey !== null) {
  //       let selector;
  //       if (Array.isArray(focusedKey) && focusedKey[1] !== -1) {
  //         if (focusedAspect === "accountselect") {
  //           // Focus a specific split account select
  //           selector = `select[name="accountselect-${focusedKey[0]}-${focusedKey[1]}"]`;
  //         } else {
  //           // Focus a specific split input
  //           selector = `input[name="splitamount-${focusedKey[0]}-${focusedKey[1]}"]`;
  //         }
  //       } else if (Array.isArray(focusedKey) && focusedKey[1] === -1) {
  //         // Focus the new split input
  //         selector = `input[name="splitamount-${focusedKey[0]}-new"]`;
  //         //set the splitValue and tempAccountValue to empty
  //         setCachedSplitLineAmount("");
  //       } else if (
  //         typeof focusedKey === "string" &&
  //         focusedKey !== null &&
  //         focusedKey !== ""
  //       ) {
  //         // Focus the main message amount input
  //         selector = `input[name="${focusedAspect}-${focusedKey}"]`;
  //         console.log(selector);
  //       }
  //       const element = document.querySelector(selector);
  //       if (element) {
  //         element.focus();
  //       }
  //     }
  //   };
  //   focusElementByKey(focusedKey);
  // }, [focusedKey]);

  const handleTransactionSelection = ({ key, e, index }) => {
    console.log("handleTransactionSelection", key, index, e.target.checked);
    if (index !== undefined) {
      setSelectedTransactions((prev) => {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [index]: e.target.checked,
          },
        };
      });
    } else
      setSelectedTransactions((prev) => {
        return {
          ...prev,
          [key]: e.target.checked,
        };
      });
  };

  // Function to update a transaction account in a batch
  const updateTransactionsBatch = (batch, key, update) => {
    console.log("Batch updating", update);
    batch.update(doc(db, "orgs", org, "importedTransactions", key), update);
    console.log(`Transaction updated in ${key}`);
  };

  // Function to update splits in a batch
  const updateTransactionSplitsBatch = (batch, key, newSplits) => {
    console.log("Batch updating splits", newSplits);
    batch.update(doc(db, "orgs", org, "importedTransactions", key), {
      splits: newSplits,
    });
    console.log(`Splits updated in ${key}`);
  };

  const massChanges = async ({ account, fund, contact, submit, remove }) => {
    console.log(
      `Making changes to account:${account}, fund:${fund}, contact:${contact}, submit:${submit}, remove:${remove}`,
    );
    const selectedKeys = Object.keys(selectedTransactions);
    const batch = writeBatch(db);

    selectedKeys.forEach((key) => {
      if (selectedTransactions[key] === true) {
        // Collect updates for account, fund, contact, submit, and remove at transaction level
        const updates = {};
        if (account) updates.account = account;
        if (fund) updates.fund = fund;
        if (contact) updates.contact = contact;
        if (submit) updates.submit = true;
        if (remove) updates.remove = true;
        if (submit) updates.userMadeChange = user.uid;

        if (Object.keys(updates).length > 0) {
          updateTransactionsBatch(batch, key, updates);
        }
      } else {
        // Collect all split updates for this document
        const splits = importedToHandle[key].splits;
        const splitKeys = Object.keys(selectedTransactions[key]);
        const newSplits = splits?.length > 0 ? [...splits] : [];
        let hasUpdates = false;

        splitKeys.forEach((splitKey) => {
          if (selectedTransactions[key][splitKey] === true) {
            if (account)
              newSplits[splitKey] = {
                ...newSplits[splitKey],
                account: account,
              };
            if (fund)
              newSplits[splitKey] = { ...newSplits[splitKey], fund: fund };
            if (remove)
              newSplits[splitKey] = { ...newSplits[splitKey], remove: true };

            hasUpdates = true;
          }
        });

        if (hasUpdates) {
          updateTransactionSplitsBatch(batch, key, newSplits);
        }
      }
    });

    try {
      await batch.commit();
      console.log("All selected transactions have been updated.");
      setSelectedTransactions({});
      setContactForMassChange(null);
      setAccountForMassChange(null);
      setFundForMassChange(null);
      setMassSubmitState(false);
      setMassRemoveState(false);
    } catch (e) {
      console.log("Error updating transactions in batch:", e);
    }
  };

  //Log the selected transactions to the console when they change
  useEffect(() => {
    //if all false, set to empty object
    if (Object.keys(selectedTransactions).length === 0) {
      //already cleared, quit to avoid loop
      return;
    }
    if (Object.values(selectedTransactions).every((value) => value === false)) {
      setSelectedTransactions({});
    }
    console.log(selectedTransactions);
  }, [selectedTransactions]);

  const handleMassContactStateChange = ({ transactionId, contactId }) => {
    setContactForMassChange(contactId);
  };

  const handleMassAccountStateChange = ({ transactionId, accountId }) => {
    setAccountForMassChange(accountId);
  };

  const handleMassFundStateChange = ({ transactionId, fundId }) => {
    console.log("Setting fund for mass change", fundId);
    setFundForMassChange(fundId);
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <>
      <Modal
        open={massChangesModalOpen}
        onClose={() => {
          setMassChangesModalOpen(false);
          setContactForMassChange(null);
        }}>
        <ModalBox>
          <ModalInner>
            <h2>Change All Selected</h2>
            {!massSubmitState && !massRemoveState && (
              <SelectContact
                transactionContactValue={contactForMassChange}
                setTransactionContact={handleMassContactStateChange}
                txkey={"mass"}
              />
            )}
            <div style={{ height: "1rem" }} />
            {!massSubmitState && !massRemoveState && (
              <SelectAccount
                transactionAccountValue={accountForMassChange}
                setAccount={handleMassAccountStateChange}
                txkey={"mass"}
                thisAccount={selectedAccount}
              />
            )}
            <div style={{ height: "1rem" }} />
            {!massSubmitState && !massRemoveState && (
              <SelectFund
                transactionFundValue={fundForMassChange}
                setFund={handleMassFundStateChange}
                txkey={"mass"}
              />
            )}
            {/* <FormGroup>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={massSubmitState}
                    onChange={() => {
                      setMassSubmitState(!massSubmitState);
                      setMassRemoveState(false);
                      setContactForMassChange(null);
                      setAccountForMassChange(null);
                      setFundForMassChange(null);
                    }}
                  />
                }
                label="Submit Selected"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={massRemoveState}
                    onChange={() => {
                      setMassRemoveState(!massRemoveState);
                      setMassSubmitState(false);
                      setContactForMassChange(null);
                      setAccountForMassChange(null);
                      setFundForMassChange(null);
                    }}
                  />
                }
                label="Remove Selected"
              />
            </FormGroup> */}

            <div style={{ height: "1rem" }} />
            <StyledButton
              primary
              fontSize={"1.2rem"}
              height={"2.5rem"}
              onClick={() => {
                massChanges({
                  account: accountForMassChange,
                  fund: fundForMassChange,
                  contact: contactForMassChange,
                  submit: massSubmitState,
                  remove: massRemoveState,
                });
                setMassChangesModalOpen(false);
              }}>
              Make Changes
            </StyledButton>
          </ModalInner>
        </ModalBox>
      </Modal>
      <ThemeProvider theme={theme}>
        <TitleText style={{ fontSize: "1.6rem", margin: "0" }}>
          Imported Transactions
        </TitleText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <KeyboardDoubleArrowLeft
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
          />
          <p style={{ color: "white" }}>
            {indexOfFirstItem + 1} - {indexOfLastItem} of{" "}
            {Object.keys(importedToHandle).length}
          </p>
          <KeyboardDoubleArrowRight
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              console.log("Current Items: ", currentItems.length, itemsPerPage);
              if (currentItems.length === itemsPerPage) {
                console.log("Current Page: ", currentPage);
                setCurrentPage(currentPage + 1);
              }
            }}
          />
          <div style={{ width: "1rem" }} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              inputRef={inputRef}
              sx={{
                p: 0,
                "& .MuiInputBase-root": { padding: "0 0 0 4px" },
              }}
              variant="standard"
              value={tempCurrentItemsPerPage}
              onChange={(e) => {
                setTempCurrentItemsPerPage(e.target.value);
              }}
              style={{ width: "3rem", marginRight: "0.25rem" }}
              onBlur={() => {
                if (
                  tempCurrentItemsPerPage === "" ||
                  isNaN(tempCurrentItemsPerPage)
                ) {
                  setTempCurrentItemsPerPage(itemsPerPage);
                  return;
                } else if (tempCurrentItemsPerPage > 100) {
                  setTempCurrentItemsPerPage(100);
                } else {
                  setItemsPerPage(parseInt(tempCurrentItemsPerPage, 10));
                  setCurrentPage(1);
                }
              }}
              onFocus={() => {
                if (inputRef.current) {
                  inputRef.current.select();
                }
              }}
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  style: {
                    textAlign: "right",
                    fontFamily: "MontserratSemiBold",
                  },
                },
              }}
            />
            <DetailText style={{ marginLeft: 0 }}>items per page</DetailText>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: "2rem", padding: 0 }}>
                <Checkbox
                  style={{ color: "white" }}
                  onClick={() => {
                    setSelectAll(!selectAll);
                    currentItems.forEach(([key, value]) => {
                      setSelectedTransactions((prev) => {
                        return {
                          ...prev,
                          [key]: !selectAll,
                        };
                      });
                    });
                  }}
                  checked={selectAll}
                />
              </TableCell>
              <TableCell>
                <StyledHeaderText>Date</StyledHeaderText>
              </TableCell>
              <TableCell>
                <StyledHeaderText>Contact</StyledHeaderText>
              </TableCell>
              <TableCell>
                <StyledHeaderText>Account</StyledHeaderText>
              </TableCell>
              <TableCell>
                <StyledHeaderText>Fund</StyledHeaderText>
              </TableCell>
              <TableCell>
                {/* Debit */}
                <StyledHeaderText>
                  {selectedAccountType === "Assets" ? "Inflow" : "Payment"}
                </StyledHeaderText>
              </TableCell>
              <TableCell>
                {/* Credit */}
                <StyledHeaderText>
                  {selectedAccountType === "Assets" ? "Outflow" : "Charge"}
                </StyledHeaderText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.length > 0 &&
              currentItems.map(([key, value]) => (
                <Transaction
                  transaction={value}
                  txkey={key}
                  key={key}
                  org={org}
                  db={db}
                  focusedKey={focusedKey}
                  setFocusedKey={setFocusedKey}
                  focusedAspect={focusedAspect}
                  setFocusedAspect={setFocusedAspect}
                  cachedSplitLineAmount={cachedSplitLineAmount}
                  setCachedSplitLineAmount={setCachedSplitLineAmount}
                  selectedTransactions={selectedTransactions}
                  handleTransactionSelection={handleTransactionSelection}
                />
              ))}
          </TableBody>
        </Table>
        {Object.keys(selectedTransactions)?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              position: "absolute",
              bottom: "25px",
            }}>
            <OverlayedButton
              onClick={() => {
                setMassChangesModalOpen(true);
              }}>
              <p>Make Changes</p>
            </OverlayedButton>
            <div style={{ width: "5rem" }} />
            <OverlayedButton
              onClick={() => {
                massChanges({ remove: true });
              }}>
              <p>Remove</p>
            </OverlayedButton>
            <div style={{ width: "5rem" }} />
            <OverlayedButton
              onClick={() => {
                massChanges({ submit: true });
              }}>
              <p>Submit</p>
            </OverlayedButton>
          </div>
        ) : null}
      </ThemeProvider>
    </>
  );
};

const ImportRegisterTransactionNormalFlow = ({
  closeModal,
  selectedAccount,
}) => {
  const authHook = useAuth();
  const db = authHook.db;
  const { accounts, funds, transactions, contacts, org } =
    useContext(UserContext);
  const [incomingTransactions, setIncomingTransactions] = useState([]);
  const [allContactsMatched, setAllContactsMatched] = useState(false);
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const [suggestedContactName, setSuggestedContactName] = useState("");
  const [importing, setImporting] = useState(false);

  const setData = async (data) => {
    setImporting(true);
    const selectedAccountType = findAccountById({
      accountId: selectedAccount,
      accounts,
    }).accountType;

    console.log(data);
    //Iterate through the data and change each key to all lower case and replace any spaces in keys with underscores
    data = data.map((entry) => {
      const newEntry = {};
      for (const key in entry) {
        newEntry[key.toLowerCase().replace(/\s/g, "_")] = entry[key];
      }
      const parseAmount = (value) => {
        // Remove all non-numeric characters except for '-' and '.'
        try {
          const cleanedValue = value.replace(/[^\d.-]/g, "");
          return Math.abs(parseInt(parseFloat(cleanedValue) * 100));
        } catch (error) {
          console.error("Error parsing amount: ", error);
          console.error("Value: ", value);
          return 0;
        }
      };

      if (selectedAccountType === "Assets") {
        if (entry.Inflow) {
          newEntry["debit"] = parseAmount(entry.Inflow);
          newEntry["credit"] = 0;
        } else if (entry.Amount) {
          if (entry.Amount.includes("-")) {
            newEntry["credit"] = parseAmount(entry.Amount);
            newEntry["debit"] = 0;
          } else {
            newEntry["debit"] = parseAmount(entry.Amount);
            newEntry["credit"] = 0;
          }
        } else {
          newEntry["credit"] = parseAmount(entry.Outflow);
          newEntry["debit"] = 0;
        }
      } else {
        if (entry.Inflow) {
          newEntry["credit"] = parseAmount(entry.Inflow);
          newEntry["debit"] = 0;
        } else if (entry.Amount) {
          if (entry.Amount.includes("-")) {
            newEntry["debit"] = parseAmount(entry.Amount);
            newEntry["credit"] = 0;
          } else {
            newEntry["credit"] = parseAmount(entry.Amount);
            newEntry["debit"] = 0;
          }
        } else {
          newEntry["debit"] = parseAmount(entry.Outflow);
          newEntry["credit"] = 0;
        }
      }

      newEntry["contactRawText"] = entry.contact || entry.Contact || "";
      //remove the entry.contact key from the object
      delete newEntry["contact"];
      newEntry["dateORIGINAL"] = entry["date"] || entry["Date"] || "";
      newEntry["debitORIGINAL"] = newEntry["debit"] || "";
      newEntry["creditORIGINAL"] = newEntry["credit"] || "";
      newEntry["intoAccount"] = selectedAccount;
      console.log(newEntry);
      return newEntry;
    });
    // const transactionsFromCSV = processTransactionsFromSingleEntry({
    //   data,
    //   contacts,
    //   accountType: findAccountById({ accountId: selectedAccount, accounts })
    //     .accountType,
    // });
    // console.log(transactionsFromCSV);

    // setIncomingTransactions(transactionsFromCSV);

    console.log("Selected Account Type: ", selectedAccountType);

    console.log(data);

    //batch transactions to be added to the database under the incomingTransactions collection
    //Check if more than 500 transactions are being imported
    const importedTransactionsRef = collection(
      db,
      "orgs",
      org,
      "importedTransactions",
    );
    if (data.length > 500) {
      const chunkedData = [];
      while (data.length > 0) {
        chunkedData.push(data.splice(0, 500));
      }

      const processChunk = async (chunk, attempt = 0) => {
        const batch = writeBatch(db);
        chunk.forEach((entry) => {
          batch.set(doc(importedTransactionsRef), entry);
        });
        try {
          await batch.commit();
        } catch (error) {
          console.error("Error writing batched writes: ", error);
          if (attempt < 3) {
            // Retry up to 3 times
            console.log(`Retrying batch... Attempt ${attempt + 1}`);
            const delay = Math.pow(2, attempt) * 1000; // Exponential backoff: 1s, 2s, 4s
            await new Promise((resolve) => setTimeout(resolve, delay));
            return processChunk(chunk, attempt + 1);
          }
          throw error; // After retries are exhausted, re-throw the error
        }
      };

      const commitPromises = chunkedData.map((chunk) => processChunk(chunk));

      Promise.allSettled(commitPromises).then((results) => {
        const failed = results.filter((r) => r.status === "rejected");
        if (failed.length === 0) {
          setImporting(false);
          closeModal();
        } else {
          console.error(
            "Some transactions failed to commit after retries:",
            failed,
          );
        }
      });
    } else {
      try {
        const batch = writeBatch(db);
        data.forEach((entry) => {
          batch.set(doc(importedTransactionsRef), entry);
        });
        await batch.commit();
        setImporting(false);
        closeModal();
      } catch (error) {
        console.error("Error committing batch: ", error);
      }
    }
  };

  //useEffect to check if all incoming transactions have contacts added/matched
  useEffect(() => {
    if (incomingTransactions.length > 0) {
      const allContactsMatched = incomingTransactions.every((transaction) => {
        return transaction.lines.every((line) => {
          return line.contact !== "" && line.contact !== null;
        });
      });
      if (allContactsMatched) {
        setAllContactsMatched(true);
      }
    }
  }, [incomingTransactions]);

  //When a contact is added or updated, look for any other transactions to have matches as well
  useEffect(() => {
    if (incomingTransactions.length > 0) {
      console.log("Contacts updated, checking for matches");
      const updatedTransactions = incomingTransactions.map((transaction) => {
        transaction.lines.map((line) => {
          if (line.contact === "" || line.contact === null) {
            console.log("Checking for matches to ", line.contactRawText);
            const newMatchingContactId = findMatchingContact({
              contacts,
              incomingContactName: line.contactRawText,
            });
            if (newMatchingContactId) {
              console.log("Match found: ", newMatchingContactId);
              line.contact = newMatchingContactId;
            }
          }
          return line;
        });
        return transaction;
      });
      console.log(updatedTransactions);
      setIncomingTransactions(updatedTransactions);
    }
  }, [contacts]);

  //log the incoming transactions to the console when they change
  useEffect(() => {
    console.log(incomingTransactions);
  }, [incomingTransactions]);

  return (
    <>
      {/* Add Contact Modal */}
      <Modal
        open={addContactModalOpen}
        onClose={() => {
          setAddContactModalOpen(false);
          setSuggestedContactName("");
        }}>
        <ModalBox>
          <ModalInner>
            <AddContact
              handleClose={() => {
                setAddContactModalOpen(false);
                setSuggestedContactName("");
              }}
              db={db}
              org={org}
              suggestedName={suggestedContactName}
              contactToEdit={false}
            />
          </ModalInner>
        </ModalBox>
      </Modal>

      {!incomingTransactions?.length > 0 && !importing && (
        <CSVImporter
          setData={setData}
          headerOptions={[
            "Date",
            "Contact",
            "Inflow",
            "Outflow",
            "Amount",
            "Memo",
            "Check Number",
          ]}
          requiredHeaders={[
            ["Date", "Amount"],
            ["Date", "Inflow", "Outflow"],
          ]}
          exclusiveHeaders={[["Amount"], ["Inflow", "Outflow"]]}
          confirmButtonText={"Transaction Format"}
        />
      )}
      {importing && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <StyledLoadingLogo />{" "}
          <TitleText style={{ fontSize: "1.6rem" }}>
            Bringing in transactions...
          </TitleText>
        </div>
      )}
      {/* {incomingTransactions?.length > 0 && !importSuccess && !submitting && (
        <HandleBankingTransactions
          transactions={incomingTransactions}
          setCompleteHandling={setHandledDetails}
          closeModal={closeModal}
          setAddContactModalOpen={setAddContactModalOpen}
          setSuggestedContactName={setSuggestedContactName}
          allContactsMatched={allContactsMatched}
          selectedAccount={selectedAccount}
        />
      )} */}
    </>
  );
};

export default ImportRegisterTransactionNormalFlow;
