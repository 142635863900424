import React, { useState, useEffect, useContext, useCallback } from "react";
import { ModalBox, ModalInner, TitleText } from "./onboarding_styles";
import { AddOrg } from "./add_org";
import { useAuth } from "../../services/use-auth";
import InitialImportWorkflow from "../../utilities/mass_import/onboard_import_workflow";
import UserContext from "../../assets/user_context";
import { Modal } from "@mui/material";
import ChooseAPath from "./choose_a_path";
import StartingBalancesOnboarding from "./starting_balances_onboarding";
import { CenteringDiv } from "../../assets/containers";
import { Close } from "@mui/icons-material";
import { doc, updateDoc } from "firebase/firestore";

const Onboarding = React.forwardRef(
  ({ closeModal, openOnboardingModal, closeOnboardingModal }, ref) => {
    const {
      org,
      userData,
      user,
      accounts,
      accountsHierarchy,
      funds,
      contacts,
      transactions,
      orgData,
      openSubscribe,
      experimental,
      loaded,
    } = useContext(UserContext);
    const [onboardingStep, setOnboardingStep] = useState(undefined);

    const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

    const openOnboarding = useCallback(() => {
      if (!openSubscribe) {
        setOnboardingModalOpen(true);
      }
    }, [openSubscribe]);

    const closeOnboarding = () => {
      setOnboardingModalOpen(false);
    };

    useEffect(() => {
      if (orgData?.onboarded || experimental) {
        console.log("ORG HAS ONBOARDED");
        closeOnboarding();
      }
    }, [orgData]);

    const authHook = useAuth();
    const db = authHook.db;

    const userHasOrg = userData?.orgs?.length > 0;
    const userIsOnboarded = userData?.onboarded;
    const orgIsOnboarded = orgData?.onboarded;
    const atConfirmationWindow =
      window.location.pathname === "/account/welcome-to-the-club";
    const chosenPath = orgData?.chosenPath;

    useEffect(() => {
      // console.log("ONBOARDING STATUS CHECK");
      if (atConfirmationWindow || !loaded) {
        //Don't check for onboarding status if user is at confirmation window
        // console.log(
        //   "At confirmation window or not loaded, so aborting status check",
        // );
        return;
      } else if (user) {
        //user is logged in
        // console.log("User is logged in");
        if (userHasOrg) {
          //user has an org attached to their account
          // console.log("User has org");
          if (!orgData) {
            // console.log("No org data yet, need to wait for that");
            return;
          }
          if (orgIsOnboarded || experimental) {
            //org is onboarded
            // console.log("Org is onboarded, ONBOARDING COMPLETE");
            closeOnboarding();
          } else if (chosenPath) {
            //user has selected onboarding path
            // console.log("There is a chosen path", chosenPath);
            if (chosenPath === "power") {
              setOnboardingStep("importInitialData");
              openOnboarding();
            } else if (
              chosenPath === "power-startingBalance"
              // &&
              // transactions?.length > 0 &&
              // accounts?.length > 0
            ) {
              setOnboardingStep("startingBalances");
              openOnboarding();
            }
          } else {
            //user has not selected an onboarding path for this org
            // console.log("User has org, but org is not onboarded");
            setOnboardingStep("chooseYourPath");
            openOnboarding();
          }
        } else {
          // console.log("User has no org and loaded:", loaded);
          //user has no org attached to their account
          // console.log("User has no org");
          setOnboardingStep("addOrg");
          openOnboarding();
        }
      }
    }, [
      accounts?.length,
      atConfirmationWindow,
      chosenPath,
      closeOnboardingModal,
      openOnboarding,
      openOnboardingModal,
      orgIsOnboarded,
      user,
      userHasOrg,
      userIsOnboarded,
      loaded,
      userData?.orgs?.length,
      experimental,
      org,
      orgData,
    ]);

    useEffect(() => {
      if (experimental) {
        closeOnboarding();
      }
    }, [experimental]);

    return (
      <Modal
        open={onboardingModalOpen}
        onClose={() => alert("Please complete onboarding")}>
        <ModalBox ref={ref} tabIndex={-1}>
          <ModalInner>
            {!accounts?.length > 0 &&
              !contacts?.length > 0 &&
              !transactions?.length > 0 &&
              chosenPath === "power" &&
              loaded && (
                <Close
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    await updateDoc(
                      doc(db, "orgs", org),
                      {
                        chosenPath: false,
                      },
                      { merge: true },
                    );
                  }}
                  fontSize="large"
                />
              )}
            {onboardingStep === "addOrg" && (
              <AddOrg
                db={db}
                userData={userData}
                user={user}
                closeModal={closeOnboarding}
              />
            )}
            {onboardingStep === "importInitialData" && (
              <InitialImportWorkflow
                initial
                db={db}
                org={org}
                accounts={accounts}
                funds={funds}
                accountsHierarchy={accountsHierarchy}
                contacts={contacts}
              />
            )}
            {onboardingStep === "chooseYourPath" && <ChooseAPath />}
            {onboardingStep === "startingBalances" &&
              accounts.length > 0 &&
              transactions.length > 0 && <StartingBalancesOnboarding />}
            {onboardingStep === "confirmTransactions" && (
              <CenteringDiv>
                <TitleText>{`You already have ${transactions.length} transactions imported.`}</TitleText>
              </CenteringDiv>
            )}
          </ModalInner>
        </ModalBox>
      </Modal>
    );
  },
);

export default Onboarding;
