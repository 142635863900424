import React, { useContext, createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const configDirect = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBSE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const authContext = createContext();

// Global variable to hold the Firebase app instance
let firebaseAppInstance;
let isEmulatorConnected = false;
let db, auth, functions, storage;

//Wraps the app and makes auth object available to any child calling useAuth()
export const ProvideAuth = ({ children }) => {
  const authHook = useProvideAuth();
  return (
    <authContext.Provider value={authHook}>{children}</authContext.Provider>
  );
};

//Hook for child compoenets to get the auth object
//and re-render upon change.
export const useAuth = () => {
  return useContext(authContext);
};

//Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const [isLoadingFirebase, setIsLoadingFirebase] = useState(true);
  console.log("Triggering ProvideAuth Hook to run");

  useEffect(() => {
    if (!firebaseAppInstance) {
      console.log("Initializing Firebase App");
      firebaseAppInstance = initializeApp(configDirect);
    }
    db = getFirestore(firebaseAppInstance);
    auth = getAuth(firebaseAppInstance);
    functions = getFunctions(firebaseAppInstance);
    storage = getStorage(firebaseAppInstance);

    if (window.location.href.split("/")[2].split(":")[0] === "localhost") {
      console.log(`Connecting emulators since the address is localhost`);
      connectFirestoreEmulator(db, "localhost", 8080);
      connectAuthEmulator(auth, "http://localhost:9099");
      connectFunctionsEmulator(functions, "localhost", 5001);
      connectStorageEmulator(storage, "localhost", 9199);
      isEmulatorConnected = true;
    }
    setIsLoadingFirebase(false);
  }, []);

  return {
    firebaseAppInstance,
    db,
    auth,
    functions,
    storage,
    isLoadingFirebase,
  };
};
