import {
  findFundById,
  removeCommasAndReturnCents,
} from "../../../utilities/general_util";

//Check if all lines have funds selected
const checkFundsBalance = ({
  entryDataIn,
  setFundBalanceStatus,
  setEntryErrors,
  allFunds,
}) => {
  console.log("Checking Funds Balance for Journal Entry", entryDataIn);
  // Eliminate any lines after the second that have all fields empty
  const entryData = entryDataIn.filter((line, index) => {
    if (index > 1) {
      return (
        line.account !== null ||
        line.credit !== "" ||
        line.debit !== "" ||
        line.fund !== ""
      );
    } else {
      return true;
    }
  });

  const allFundsSelected = entryData.every(
    (line) => line.fund !== "" && line.fund !== null,
  );
  const allLinesHaveAmounts = entryData.every(
    (line) => line.credit !== "" || line.debit !== "",
  );

  if (allFundsSelected && allLinesHaveAmounts) {
    let funds = [];
    entryData.forEach((entry) => {
      if (entry.fund !== "" && !funds.includes(entry.fund)) {
        funds.push(entry.fund);
      }
    });

    const fundsStatus = {};
    const tolerance = 0.9; // 1 cent tolerance

    funds.forEach((fund) => {
      let totalDebit = 0;
      let totalCredit = 0;

      entryData.forEach((entry) => {
        if (entry.fund === fund) {
          let amount =
            entry.credit !== ""
              ? Math.round(
                  parseFloat(entry.credit.replace(/,/g, "")).toFixed(2) * 100,
                )
              : Math.round(
                  parseFloat(entry.debit.replace(/,/g, "")).toFixed(2) * 100,
                );

          if (entry.debit !== "") {
            totalDebit += amount;
          } else if (entry.credit !== "") {
            totalCredit += amount;
          }
        }
      });
      console.log("Total Debit: ", totalDebit);
      console.log("Total Credit: ", totalCredit);

      const differenceInCents = Math.abs(totalDebit - totalCredit);
      console.log("Difference in Cents: ", differenceInCents);

      fundsStatus[fund] = {
        difference: differenceInCents / 100, // Convert back to original unit
        lower: totalDebit < totalCredit ? "debit" : "credit",
        balanced: differenceInCents <= tolerance,
      };
    });

    // Check if all funds are balanced
    if (Object.values(fundsStatus).every((fund) => fund.balanced)) {
      setFundBalanceStatus(true);
      setEntryErrors([]);
      return fundsStatus;
    } else {
      setFundBalanceStatus(false);
      let errorsAndFeedback = [];

      entryData.map((entry) => {
        if (entry.fund !== "" && !fundsStatus[entry.fund].balanced) {
          const errorFeedback = `Fund ${
            findFundById({ fundId: entry.fund, funds: allFunds }).fundNumber
          } unbalanced, needs +$${fundsStatus[entry.fund].difference.toFixed(
            2,
          )} ${fundsStatus[entry.fund].lower}`;
          errorsAndFeedback.push({
            error: true,
            errorFeedback: errorFeedback,
            quantity: fundsStatus[entry.fund].difference,
          });
        } else {
          errorsAndFeedback.push({
            error: false,
            errorFeedback: "",
            quantity: 0,
          });
        }
        return entry;
      });
      setEntryErrors(errorsAndFeedback);
      return fundsStatus;
    }
  } else {
    setFundBalanceStatus(false);
    setEntryErrors([]);
    return {};
  }
};

// Function to handle final validation before submitting the journal entry
const validateJournalEntry = ({
  journalEntryMetaData,
  setMetaDataEntryErrors,
  setMetaDataEntryMessages,
  entryDataIn,
  entryErrors,
  setEntryErrors,
  setErrorFeedback,
  setFundBalanceStatus,
  funds,
}) => {
  //Eliminate any lines after the second that have all fields empty, as these have only been
  //added to allow the user to add more lines
  console.log("Validating Journal Entry");
  const entryData = entryDataIn.filter((line, index) => {
    if (index > 1) {
      return (
        line.account !== null ||
        line.credit !== "" ||
        line.debit !== "" ||
        line.fund !== ""
        // ||
        // (line.contact !== null && line.contact !== "")
      );
    } else {
      return true;
    }
  });

  if (journalEntryMetaData.date !== null) {
    //Check to see if every field in the metaData is filled out
    const metaDataEntryErrors = {
      date: journalEntryMetaData.date === null,
      memo: journalEntryMetaData.memo === "",
    };
    const newMetaDataEntryMessages = {
      date: journalEntryMetaData.date === null ? "Date is Required" : "",
      memo: journalEntryMetaData.memo === "" ? "Memo is Required" : "",
    };
    setMetaDataEntryErrors(metaDataEntryErrors);
    setMetaDataEntryMessages(newMetaDataEntryMessages);
    //Check to see if every account in the entryData is filled out, if not, set the error to true
    const accountEntryErrors = entryData.map((entry) => {
      return entry.account === "" || entry.account === null;
    });
    // //Check to see if every contact in the entryData is filled out, if not, set the error to true
    // const contactEntryErrors = entryData.map((entry) => {
    //   return entry.contact === "" || entry.contact === null;
    // });
    //Check to see if every amount in the entryData is filled out, if not, set the error to true
    const amountEntryErrors = entryData.map((entry) => {
      const amount = entry.credit !== "" ? entry.credit : entry.debit;
      return (
        amount === "" || isNaN(parseFloat(amount)) || parseFloat(amount) === 0
      );
    });
    //Check to see if every fund in the entryData is filled out, if not, set the error to true
    const fundEntryErrors = entryData.map((entry) => {
      return entry.fund === "";
    });
    //If there are already entryErrors in the state, cycle through them and add the current errors to each line
    const newEntryErrors = accountEntryErrors.map((entry, index) => {
      return {
        account: accountEntryErrors[index],
        // contact: contactEntryErrors[index],
        amount:
          amountEntryErrors[index] ||
          (entryErrors > 0 && entryErrors[index].amount),
        fund: fundEntryErrors[index],
        errorFeedback:
          entryErrors.length > 0 &&
          entryErrors[index] &&
          entryErrors[index].errorFeedback
            ? entryErrors[index].errorFeedback
            : false,
      };
    });

    //Set the entryErrors state to the newEntryErrors
    setEntryErrors(newEntryErrors);

    //If there are no errors, record the journal entry
    if (
      newEntryErrors.every(
        (entry) =>
          !entry.account && !entry.amount && !entry.fund && !entry.contact,
      ) && //Check to see if there are any errors in the metaData
      !Object.values(metaDataEntryErrors).some((error) => error)
    ) {
      console.log(
        "All fields are filled out, checking fund balance with entryData",
        entryData,
      );
      const currentFundStatus = checkFundsBalance({
        entryDataIn: entryData,
        setFundBalanceStatus,
        setEntryErrors,
        allFunds: funds,
      });
      const fundStatusArray = Object.values(currentFundStatus);
      if (
        fundStatusArray.length > 0 &&
        fundStatusArray.every((fund) => fund.balanced)
      ) {
        //Check if credits/debits are balanced overall, should be if they are balanced by fund
        const credDebDiff = entryData.reduce((acc, entry) => {
          console.log("Accumulator: ", acc);
          if (entry.debit !== "") {
            console.log("Adding Debit: ", entry.debit);
            return acc + removeCommasAndReturnCents(entry.debit);
          } else if (entry.credit !== "") {
            console.log("Subtracting Credit: ", entry.credit);
            return acc - removeCommasAndReturnCents(entry.credit);
          } else {
            return acc;
          }
        }, 0);
        const credDebBalanced = credDebDiff === 0;

        if (credDebBalanced) {
          //RECORD THE JOURNAL ENTRY
          return true;
        } else {
          setErrorFeedback(
            `Credits/Debits are off by $${parseFloat(credDebDiff / 100).toFixed(
              2,
            )}`,
          );
        }
      } else {
        setFundBalanceStatus(false);
        return false;
      }
    } else {
      setErrorFeedback(
        "Please fix the errors then retry sumbitting the journal entry",
      );
      return false;
    }
  } else {
    setErrorFeedback("You have not entered a date, this is required");
    setEntryErrors([{ date: true }]);
    return false;
  }
};

export { checkFundsBalance, validateJournalEntry };
