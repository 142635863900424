import { useState } from "react";
import withSubscriptionProtection from "../../services/with_subscription";
import { CurrencyInput } from "../../utilities/general_util";
import { UnderConstruction, ComponentPageWrapper } from "../component_styles";

const ProtectedHome = () => {
  const [currencyValueCents, setCurrencyValueCents] = useState("");
  return (
    // <CurrencyInput
    //   currencyValueCents={currencyValueCents}
    //   setCurrencyValueCents={setCurrencyValueCents}
    // />
    <ComponentPageWrapper>
      <UnderConstruction>Dashboard</UnderConstruction>
    </ComponentPageWrapper>
  );
};

const Home = withSubscriptionProtection(ProtectedHome);

export default Home;
