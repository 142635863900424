import React, { useContext } from "react";
import { useMediaQuery } from "@mui/material";
import UserContext from "../assets/user_context";
import DesktopNav from "./desktopNav.js";
import MobileNav from "./mobileNav.js";
import LoadingScreen from "./loading_screen.jsx";

import { TitleText } from "../locations/component_styles";

import Onboarding from "../locations/onboarding/onboarding";
import SubscribeWarning, {
  FreeTrialNotification,
} from "../services/subscribe_warning";
import { useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import SelectOrg from "./select_org.jsx";

const Nav = ({ collapsed, setCollapsed }) => {
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const { loaded, experimental, setExperimental } = useContext(UserContext);
  const navigate = useNavigate();

  const location = useLocation();

  const showNav = !["/signin", "/signup"].includes(location.pathname);

  return (
    <React.Fragment>
      {experimental && (
        <React.Fragment>
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              backgroundColor: "#fbf992",
              height: "75px",
              width: "100vw",
              zIndex: 4,
            }}>
            <TitleText style={{ textAlign: "center" }}>
              Experimental Mode - No Changes Will be Saved
            </TitleText>
            <Close
              onClick={() => {
                navigate("/");
                setExperimental(false);
              }}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
              }}
              fontSize="large"
            />
          </div>
        </React.Fragment>
      )}
      {isDesktop ? (
        <React.Fragment>
          {showNav && (
            <DesktopNav collapsed={collapsed} setCollapsed={setCollapsed} />
          )}
        </React.Fragment>
      ) : (
        <MobileNav />
      )}
      {!loaded && <LoadingScreen />}
      <SubscribeWarning />
      <FreeTrialNotification />
      <Onboarding />
      <SelectOrg />
    </React.Fragment>
  );
};

export default Nav;
