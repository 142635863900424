import React, { useState, useContext, useEffect, useRef } from "react";
import UserContext from "../../../assets/user_context.jsx";
import { ComponentPageWrapper, TitleText } from "../../component_styles.jsx";
import {
  BankingToolsWrapper,
  CenteringDiv,
  EnterTxWrapper,
} from "./banking_styles.jsx";

import { useAuth } from "../../../services/use-auth.js";

import EnterBankingTransaction from "./enter_bank_transaction.jsx";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";

import { useListenForIncomingTx } from "../banking_utils.jsx";
import AccountSelector from "../account_selector.jsx";
import RegisterTransactions from "../register_transactions/register_transactions.jsx";
import { useWindowSize } from "../../../utilities/responsive_helpers.jsx";
import { ModalBox, ModalInner } from "../../onboarding/onboarding_styles.jsx";
import { Close, Edit, UploadFileOutlined } from "@mui/icons-material";
import ImportRegisterTransactionNormalFlow, {
  HandleTransactionsNormalFlow,
} from "../../../utilities/mass_import/import_tool_normal_flow.jsx";
import Modal from "@mui/material/Modal";
import { primary_dark_color } from "../../../constants/color_constants.jsx";
import { LineText } from "../register_transactions/register_transactions_styles.jsx";
import { collection, onSnapshot, query, where } from "firebase/firestore";

const BankAccountsProtected = () => {
  const { accounts, org, user, experimental, orgRoles, userData } =
    useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [incomingTx, setIncomingTx] = useState([]);
  const incomingTxRef = useRef(incomingTx); // Create a ref to hold the current state

  const [acceptedTx, setAcceptedTx] = useState([]);
  const [txToComplete, setTxToComplete] = useState(null);
  const [importedToHandle, setImportedToHandle] = useState([]);

  const [openEnterPlaidDetails, setOpenEnterPlaidDetails] = useState(false);
  const [openPlaidControlCenter, setOpenPlaidControlCenter] = useState(false);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);
  const [showTxTable, setShowTxTable] = useState(true);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openHandleModal, setOpenHandleModal] = useState(false);

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer
      const enterTxHeight =
        document.querySelector("#enter-transactions-wrapper")?.offsetHeight ||
        0;
      const bankingToolsHeight =
        document.querySelector("#banking-tools-wrapper")?.offsetHeight || 0;
      const titleHeight =
        document.querySelector("#page-title")?.offsetHeight || 0;
      const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
      const extra = experimental ? 230 : 190;
      const height =
        vh -
        (enterTxHeight +
          bankingToolsHeight +
          titleHeight +
          footerHeight +
          extra);
      const heightString = height.toString().concat("px");
      if (height <= 0) {
        setShowTxTable(false);
      } else {
        setShowTxTable(true);
      }

      // console.log(
      //   `Recalc Tx table height, viewHeight:${vh}, enterTxHeight:${enterTxHeight}, bankingToolsHeight:${bankingToolsHeight}, titleHeight:${titleHeight}, footerHeight:${footerHeight}, height:${heightString}`,
      // );
      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "enter-transactions-wrapper") {
          calculateHeight();
        }
      }
    });

    // Observe the target element
    const enterTxWrapper = document.querySelector(
      "#enter-transactions-wrapper",
    );
    if (enterTxWrapper) {
      resizeObserver.observe(enterTxWrapper);
    }

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
      if (enterTxWrapper) {
        resizeObserver.unobserve(enterTxWrapper);
      }
    };
  }, [experimental, bankAccounts, selectedAccount, orgRoles]);

  // useEffect to set a listener for incoming transactions from Plaid with the slectedAccount
  //  This will update the incomingTx state and the acceptedTx state
  useListenForIncomingTx({
    selectedAccount,
    accounts,
    user,
    org,
    db,
    setIncomingTx,
    setAcceptedTx,
  });

  useEffect(() => {
    //Set a listener to see how many transactions are imported to handle
    const txQuery = query(
      collection(db, "orgs", org, "importedTransactions"),
      where("intoAccount", "==", selectedAccount),
      where("processingComplete", "==", true),
    );
    const unsubscribe = onSnapshot(txQuery, (querySnapshot) => {
      const incomingTransactionsNumber = querySnapshot?.docs?.length || 0;
      setImportedToHandle(incomingTransactionsNumber);
    });

    return () => {
      unsubscribe();
    };
  }, [selectedAccount, accounts, db, org]);

  useEffect(() => {
    incomingTxRef.current = incomingTx; // Update the ref whenever the state changes
  }, [incomingTx]);

  useEffect(() => {
    const bankAccounts = accounts.filter(
      (account) => account.isRegister && account.accountType === "Assets",
    );
    const bankAccountsSorted = bankAccounts.sort((a, b) => {
      if (a.accountNumber < b.accountNumber) {
        return -1;
      }
      if (a.accountNumber > b.accountNumber) {
        return 1;
      } else {
        return 0;
      }
    });
    if (bankAccounts.length > 0) {
      // console.log("Bank accounts: ", bankAccounts);
      setBankAccounts(bankAccountsSorted);
      setSelectedAccount(bankAccountsSorted[0].id);
    } else {
      console.error("Bro, you don't have any bank accounts");
    }
  }, [accounts]);

  useEffect(() => {
    // console.log("Accepted tx: ", acceptedTx);
    if (acceptedTx.length > 0) {
      setTxToComplete(acceptedTx[0]);
      setOpenEnterPlaidDetails(true);
      // console.log("Queing up tx to edit: ", acceptedTx[0]);
    } else {
      // console.log("No accepted tx");
      setTxToComplete(null);
    }
  }, [acceptedTx]);

  //If the slected account has a plaidAccountId, then set the openPlaidControlCenter to false
  useEffect(() => {
    if (selectedAccount) {
      const thisAccountsPlaidId = accounts.find(
        (account) => account.id === selectedAccount,
      ).plaidAccountId;
      if (thisAccountsPlaidId) {
        setOpenPlaidControlCenter(false);
      }
    }
  }, [selectedAccount, accounts]);

  const { height } = useWindowSize();

  return (
    <ComponentPageWrapper>
      <CenteringDiv>
        {bankAccounts.length > 0 ? (
          <div style={{ width: "100%" }}>
            {bankAccounts.length > 0 ? (
              <BankingToolsWrapper id="banking-tools-wrapper">
                <div>
                  <AccountSelector
                    accounts={bankAccounts}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    callback={() => {
                      setAcceptedTx([]);
                      setIncomingTx([]);
                    }}
                    height={height}
                  />
                </div>
                {importedToHandle > 0 ? (
                  <div
                    style={{
                      border: `2px solid ${primary_dark_color}`,
                      padding: "3px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                    onClick={() => {
                      if (setOpenHandleModal) setOpenHandleModal(true);
                    }}>
                    <Edit
                      style={{ color: primary_dark_color }}
                      fontSize="medium"
                    />
                    <LineText style={{ fontSize: "1rem", margin: "0.25rem" }}>
                      {`Handle ${importedToHandle} Transactions`}
                    </LineText>
                  </div>
                ) : (
                  <div style={{ width: "15vw" }} />
                )}
                <div
                  style={{
                    border: `2px solid ${primary_dark_color}`,
                    padding: "3px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                  onClick={() => {
                    if (setOpenUploadModal) setOpenUploadModal(true);
                  }}>
                  <UploadFileOutlined
                    style={{ color: primary_dark_color }}
                    fontSize="medium"
                  />
                  <LineText style={{ fontSize: "1rem", margin: "0.25rem" }}>
                    Import
                  </LineText>
                </div>
                {/* 
                PLAID STUFF, UNCOMMENT IN FUTURE
                {!experimental ? (
                  <div>
                    {accounts.find((account) => account.id === selectedAccount)
                      .plaidAccountId ? (
                      acceptedTx.length > 0 ? (
                        <CreateLinkButton
                          onClick={() => setOpenEnterPlaidDetails(true)}>
                          <ButtonText>Register Tx</ButtonText>
                        </CreateLinkButton>
                      ) : (
                        <IncomingTx
                          incomingTx={incomingTx}
                          org={org}
                          selectedAccount={selectedAccount}
                          location="bankAccounts"
                        />
                      )
                    ) : (
                      <CreateLinkButton
                        onClick={() => setOpenPlaidControlCenter(true)}>
                        <ButtonText>Link Account</ButtonText>
                      </CreateLinkButton>
                    )}
                  </div>
                ) : (
                  //Experimental mode shouldn't have Plaid
                  <div />
                )} */}
              </BankingToolsWrapper>
            ) : (
              <div />
            )}
            {/* <PlaidControlCenter
              openPlaidControlCenter={openPlaidControlCenter}
              setOpenPlaidControlCenter={setOpenPlaidControlCenter}
              currentAccount={accounts.find(
                (account) => account.id === selectedAccount,
              )}
            />
            <Modal
              open={openEnterPlaidDetails}
              onClose={() => setOpenEnterPlaidDetails(false)}>
              <ModalBox>
                <ModalInner>
                  <TitleText>Enter Transation Details</TitleText>
                  <h2>{`This transaction was imported from your account by Plaid. You have ${acceptedTx.length} transactions left to register.`}</h2>
                  <EnterBankingTransaction
                    selectedAccount={selectedAccount}
                    txToComplete={txToComplete}
                    closeModal={() => setOpenEnterPlaidDetails(false)}
                    location={["plaidImport"]}
                  />
                </ModalInner>
              </ModalBox>
            </Modal> */}

            {!bankAccounts.find((account) => selectedAccount === account.id)
              .disabled ? (
              <>
                {orgRoles.some(
                  (role) => role === "admin" || role === "bookkeeper",
                ) || userData.superAdmin ? (
                  <>
                    <Modal
                      open={openUploadModal}
                      onClose={() => setOpenUploadModal(false)}>
                      <ModalBox>
                        <ModalInner>
                          <Close
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              color: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenUploadModal(false);
                            }}
                            fontSize="large"
                          />
                          <ImportRegisterTransactionNormalFlow
                            selectedAccount={selectedAccount}
                            closeModal={() => setOpenUploadModal(false)}
                          />
                        </ModalInner>
                      </ModalBox>
                    </Modal>
                    <Modal
                      open={openHandleModal}
                      onClose={() => setOpenHandleModal(false)}>
                      <ModalBox style={{ width: "90vw" }}>
                        <ModalInner>
                          <Close
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              color: "white",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenHandleModal(false);
                            }}
                            fontSize="large"
                          />
                          <HandleTransactionsNormalFlow
                            selectedAccount={selectedAccount}
                            closeModal={() => setOpenHandleModal(false)}
                          />
                        </ModalInner>
                      </ModalBox>
                    </Modal>
                    <EnterTxWrapper id="enter-transactions-wrapper">
                      <EnterBankingTransaction
                        // key={`bank-${selectedAccount}`}
                        selectedAccount={selectedAccount}
                      />
                    </EnterTxWrapper>
                  </>
                ) : (
                  <div id="enter-transactions-wrapper">
                    <TitleText style={{ color: "grey", padding: "30px" }}>
                      {`Unable to enter transactions as ${orgRoles.join(" ")}.`}
                    </TitleText>
                  </div>
                )}
              </>
            ) : (
              <TitleText style={{ color: "grey", padding: "30px" }}>
                This account is currently disabled
              </TitleText>
            )}
            {showTxTable && (
              <RegisterTransactions
                height={tableWrapperHeight}
                filterAccount={selectedAccount}
              />
            )}
          </div>
        ) : (
          <TitleText>Please add a bank account...</TitleText>
        )}
      </CenteringDiv>
    </ComponentPageWrapper>
  );
};

const BankAccounts = withSubscriptionProtection(BankAccountsProtected);

export default BankAccounts;
