import React, { useContext, useEffect, useState } from "react";
import UserContext from "../assets/user_context";
import { NavLink } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import styled from "@emotion/styled";
import Logo from "../assets/logos/LogoDark.svg";
import SmallLogo from "../assets/logos/SimpleLogo.svg";
import SignOutModal from "./signOut";
import ParentNavLink from "./parent_nav_link";
import { CorporateFare } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Intercom from "@intercom/messenger-js-sdk";

const primary_color = "#8fe7f5";

const StyledLogo = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 40px;
  width: ${(props) => (props.collapsed ? "48px" : "140px")};
  margin-left: ${(props) => (props.collapsed ? "5px" : "20px")};
  margin-top: 15px;
`;

const LogoLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const LogoutText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 5px;
  font-size: 1.2rem;
`;

const SideNavWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Align items to the top
  align-items: flex-start; // Align items to the left
  padding: 1rem;
  background-color: #f5f5f5;
  /* position: fixed; // Makes the nav stay in place when scrolling */
  top: 0;
  left: 0;
  height: 98vh; // Makes the nav take the full height of the view
  width: ${(props) =>
    props.collapsed
      ? "60px"
      : "200px"}; // Adjust width based on the collapsed state
  transition: width 0.3s ease; // Smooth transition for collapsing/expanding
  z-index: 5;
`;

const StyledSpacerDiv = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const ExpandButton = styled.div`
  position: absolute;
  right: 0; // Stick to the right side
  top: 7%; // Position can be adjusted
  transform: ${(props) =>
    props.collapsed
      ? "translateX(100%)"
      : "translateX(0)"}; // Adjust for perfect centering
  width: 30px; // Square dimensions
  height: 60px;
  background-color: ${primary_color};
  cursor: pointer;
  border-radius: ${(props) =>
    props.collapsed ? "0 10px 10px 0" : "10px 0 0 10px"};
  //before with a small arrow
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    ${(props) =>
      !props.collapsed
        ? "transform : translate(-50%, -50%) rotate(45deg);"
        : "transform: translate(-80%, -50%) rotate(225deg);"}
    width: 10px;
    height: 10px;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    transition: transform 0.3s ease-in-out;
  }
`;

const OneCharTitle = styled.p`
  font-size: 2rem;
  font-family: "MontserratBold";
  text-align: center;
  margin: 0;
`;

const DesktopNav = ({ collapsed, setCollapsed }) => {
  const {
    user,
    userData,
    orgData,
    setSelectOrgOpen,
    setIsChildDataLoaded,
    experimental,
  } = useContext(UserContext);
  // const [delayHandler, setDelayHandler] = useState(null); // Store the timeout handler
  const [open, setOpen] = useState(false);

  //All commented code below is for the nav expand/collapse on hover
  // // Function to handle mouse entering
  // const handleMouseEnter = () => {
  //   // Clear any existing timeout when the mouse enters (in case it was waiting to collapse)
  //   if (delayHandler) {
  //     clearTimeout(delayHandler);
  //     setDelayHandler(null);
  //   }
  //   // Expand immediately
  //   setCollapsed(false);
  // };

  // // Function to handle mouse leaving
  // const handleMouseLeave = () => {
  //   // Wait 2 seconds before collapsing
  //   const handler = setTimeout(() => {
  //     setCollapsed(true);
  //   }, 1000);
  //   setDelayHandler(handler);
  // };

  const leaveOrg = async () => {
    console.log("Leaving Org");
    setSelectOrgOpen(true);
    setIsChildDataLoaded(false);
  };

  const handleProperNameLength = (name) => {
    //If the name is longer than 10 characters, return the first 10 characters unless it has spaces,
    // then return the first letter of each word
    if (name.length > 10) {
      if (name.includes(" ")) {
        return name
          .split(" ")
          .map((word) => word[0])
          .join("")
          .toUpperCase()
          .substring(0, 4);
      } else {
        return name.substring(0, 10).toUpperCase();
      }
    } else {
      return name.toUpperCase();
    }
  };

  const handleTwoLetterOrg = (name) => {
    //If the name starts with The, return the first two letters of the second word. Otherwise return the first two letters of the first word
    if (name.includes(" ")) {
      if (
        name.split(" ")[0].toLowerCase() === "the" ||
        name.split(" ")[0].toLowerCase() === "a" ||
        name.split(" ")[0].toLowerCase() === "an"
      ) {
        return name
          .split(" ")[1]
          .substring(0, 1)
          .toUpperCase()
          .concat(name.split(" ")[1].substring(1, 2).toLowerCase());
      } else {
        return name
          .split(" ")[0]
          .substring(0, 1)
          .toUpperCase()
          .concat(name.split(" ")[0].substring(1, 2).toLowerCase());
      }
    } else {
      return name
        .split(" ")[0]
        .substring(0, 1)
        .toUpperCase()
        .concat(name.split(" ")[0].substring(1, 2).toLowerCase());
    }
  };

  if (user) {
    Intercom({
      app_id: "qjdop6c1",
      user_id: user.uid,
      name: userData.username,
      email: userData.email,
      // created_at: user.createdAt,
    });
  }

  useEffect(() => {
    //Log out userData and orgData when they change
    console.log("userData", userData?.orgs?.length > 1, userData?.orgs);
    console.log("orgData", orgData?.orgName?.length > 0, orgData?.orgName);
  }, [userData, orgData]);

  return (
    <SideNavWrapper
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      collapsed={collapsed}>
      <ExpandButton
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
      <div>
        <LogoLink to="/">
          <StyledLogo
            src={collapsed ? SmallLogo : Logo}
            alt="logo"
            collapsed={collapsed}
          />
        </LogoLink>
        {user && (
          <React.Fragment>
            <ParentNavLink
              to="/foundation/chart-of-accounts"
              collapsed={collapsed}>
              FOUNDATION
            </ParentNavLink>
            <ParentNavLink to="/upkeep/bank-accounts" collapsed={collapsed}>
              UPKEEP
            </ParentNavLink>
            <ParentNavLink to="/reports/balance-sheet" collapsed={collapsed}>
              REPORTS
            </ParentNavLink>
            <ParentNavLink to="/contacts/contacts" collapsed={collapsed}>
              CONTACTS
            </ParentNavLink>
            <ParentNavLink to="/history" collapsed={collapsed}>
              HISTORY
            </ParentNavLink>
            {!experimental && (
              <ParentNavLink to="/account/settings" collapsed={collapsed}>
                ACCOUNT
              </ParentNavLink>
            )}
            {user && userData.superAdmin === true && (
              <ParentNavLink to="/admin-orgs" collapsed={collapsed}>
                ADMIN
              </ParentNavLink>
            )}
          </React.Fragment>
        )}
      </div>
      {user && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: collapsed ? "center" : "flex-start",
          }}>
          {(userData?.orgs?.length > 1 ||
            // && orgData?.orgName?.length > 0
            (userData?.superAdmin && orgData?.orgName)) && (
            <Tooltip
              title={`Change from ${
                orgData?.orgName ? orgData.orgName : "None"
              }`}>
              <ButtonDiv onClick={() => leaveOrg()} collapsed={collapsed}>
                {collapsed ? (
                  <OneCharTitle>
                    {orgData?.orgName?.length > 1
                      ? handleTwoLetterOrg(orgData.orgName)
                      : "ORG"}
                  </OneCharTitle>
                ) : (
                  <CorporateFare fontSize="large" />
                )}
                {collapsed ? null : (
                  <LogoutText>
                    {orgData?.orgName?.length > 1
                      ? handleProperNameLength(orgData.orgName)
                      : "ORG"}
                  </LogoutText>
                )}
              </ButtonDiv>
            </Tooltip>
          )}

          <ButtonDiv onClick={() => setOpen(true)}>
            <LogoutOutlinedIcon fontSize="large" />
            <StyledSpacerDiv />
            {collapsed ? null : <LogoutText>LOGOUT</LogoutText>}
          </ButtonDiv>
        </div>
      )}
      <SignOutModal open={open} handleClose={() => setOpen(false)} />
    </SideNavWrapper>
  );
};

export default DesktopNav;
