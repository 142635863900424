import React from "react";
import { Modal, Box } from "@mui/material";
import styled from "@emotion/styled";
import StyledButton from "../../../assets/buttons";
import { primary_color } from "../../../constants/color_constants";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 650px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
  padding: 20px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const TitleText = styled.p`
  font-size: 2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: center;
  margin: 1rem;
`;

export const ThemedTitleText = styled.p`
  font-size: 1.7rem;
  font-family: "MontserratBold";
  color: ${primary_color};
  text-align: center;
  margin: 1rem;
  /* border-bottom: 2px solid ${primary_color}; */
`;

export const DescriptiveText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: left;
  margin: 1rem;
`;

const DayAlert = ({ closingDate, setModalOpen, modalOpen }) => {
  return (
    <Modal open={modalOpen}>
      <ModalBox>
        <ModalInner>
          <TitleText>Whoooops!</TitleText>
          <DescriptiveText>
            This transaction is currently <strong>AFTER</strong> your closing
            date of your reconcilliation.
          </DescriptiveText>
          <DescriptiveText>
            You can either change the closing date of your reconcilliation, or
            you can move this transaction's date to on or before {closingDate}
          </DescriptiveText>
          <StyledButton
            bright
            onClick={() => {
              setModalOpen(false);
            }}>
            I understand. 🤓
          </StyledButton>
        </ModalInner>
      </ModalBox>
    </Modal>
  );
};

export default DayAlert;
