import styled from "@emotion/styled";
import {
  primary_color,
  primary_dark_color,
} from "../../../constants/color_constants";

export const RecButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4rem;
  padding: 0.25rem;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;

  &:hover {
    background-color: ${primary_color};
    color: white;
    transition: 0.3s ease-in-out;
  }
`;

export const SubTitleText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.3rem;
  margin-right: 1rem;
  color: ${primary_dark_color};
  border-bottom: 2px solid ${primary_dark_color};
`;

export const SubSubTitleText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.1rem;
  margin-right: 1rem;
  color: ${primary_dark_color};
  margin-left: ${(props) => (props.indent ? "2.5rem" : null)};
`;

export const DetailText = styled.p`
  font-family: "MontserratLight";
  font-size: 1.3rem;
  margin-left: 1rem;
  text-align: left;
  color: ${(props) => (props.light ? primary_color : primary_dark_color)};
  margin: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30vw;
  ${(props) => (props.right ? "text-align: right;" : null)}
  ${(props) => (props.right ? "margin-right: 1rem;" : null)}
  ${(props) =>
    props.lineAbove ? `border-top: 2px solid ${primary_dark_color};` : null}
`;

export const ReportRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${(props) => (props.margin ? "margin-top: 1.5rem;" : null)}
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  border-radius: 4rem;
  background-color: ${primary_dark_color};
`;

export const HeaderText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.3rem;
  margin-left: 1rem;
  text-align: left;
  color: ${primary_color};
  margin: 0.3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${(props) => (props.right ? "text-align: right;" : null)}
`;

export const CompleteRecButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#bfbfbf" : "#8fd18f")};
  border-radius: 4rem;
  padding: 0.5rem;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 100;
  bottom: 8rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const CompleteText = styled.p`
  font-family: "MontserratBold";
  font-size: 1.8rem;
  margin-right: 1rem;
`;
