import React, { useContext, useEffect, useState, useRef } from "react";
import UserContext from "../../assets/user_context.jsx";
import { useAuth } from "../../services/use-auth.js";
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";

import {
  LineText,
  ClickableLineText,
  Row,
  EmphasizedLineText,
  NumberText,
  ModalBox,
  ModalInner,
  LightTitleText,
  ChangesCompareDiv,
  TransactionDetailsDiv,
  LightText,
  LightBoldText,
  DetailsDiv,
  ComponentWrapper,
} from "./history_styles.jsx";
import { Modal } from "@mui/material";
import {
  findAccountById,
  findContactNameById,
  findFundById,
  returnCurrency,
} from "../../utilities/general_util.jsx";
import withExperimental from "../../services/withExperimental.jsx";
import {
  ChangedTransactionItem,
  ChangedReconciliationItem,
} from "./changed_item.jsx";

const convertSecondsToDate = (seconds) => {
  const date = new Date(seconds * 1000);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};

function formatDateWithTime({ date, withTime }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (withTime) {
    return `${monthNames[monthIndex]} ${day}, ${year} at ${hours}:${minutes}:${seconds}`;
  } else {
    return `${monthNames[monthIndex]} ${day}`;
  }
}

const History = () => {
  const { org, accounts, funds, contacts } = useContext(UserContext);
  const authHook = useAuth();

  const [historyDocs, setHistoryDocs] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const loadingRef = useRef(false); // to prevent multiple requests at once

  const [changeToExamine, setChangeToExamine] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [openExamineChange, setOpenExamineChange] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const getChanges = (before, after) => {
    const changes = [];

    if (!before || !after) {
      console.log("Either before or after is undefined");
      return changes;
    }

    for (const key in before) {
      if (!after.hasOwnProperty(key)) {
        // Handle this case
        continue;
      }

      if (before[key] instanceof Object && !(before[key] instanceof Date)) {
        if (after[key]) {
          const nestedChanges = getChanges(before[key], after[key]);
          changes.push(...nestedChanges);
        } else {
          // Handle the case where after[key] doesn't exist
          console.log(`after[${key}] doesn't exist`);
        }
      } else if (before[key] !== after[key]) {
        let change = {
          key,
          before: before[key],
          after: after[key],
        };

        // Apply returnCurrency if the key is 'amount'
        if (key === "amount") {
          change.before = returnCurrency(before[key]);
          change.after = returnCurrency(after[key]);
        }

        changes.push(change);
      }
    }

    const filteredChanges = changes.filter(
      (change) =>
        change.key !== "userMadeChange" && change.key !== "addTimestamp",
    );
    console.log("changes", filteredChanges);
    return filteredChanges;
  };

  //useEffect to get the history data stored under the org from the database
  useEffect(() => {
    if (org) {
      const fetchData = async () => {
        console.log("Building query history");
        const historyQuery = query(
          collection(authHook.db, "orgs", org, "history"),
          orderBy("timestamp", "desc"),
          limit(30),
        );
        console.log("fetching history query", historyQuery);
        try {
          const snapshot = await getDocs(historyQuery);
          setLoaded(true);
          const docs = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              changes: getChanges(doc.data().before, doc.data().after),
            };
          });
          console.log(historyDocs);
          setHistoryDocs(docs);
          setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
        } catch (e) {
          console.log(e);
        }
      };

      fetchData();
    }
  }, [authHook.db, org]);

  const bottomBoundaryRef = useRef(null);

  useEffect(() => {
    if (org) {
      const handleIntersection = async (entries) => {
        const entry = entries[0];
        console.log("Intersection event:", entry.isIntersecting);
        if (entry.isIntersecting && !loadingRef.current) {
          console.log("Fetching more documents...");
          loadingRef.current = true;

          const transactionsQuery = query(
            collection(authHook.db, "orgs", org, "history"),
            orderBy("timestamp", "desc"),
            startAfter(lastVisibleDoc),
            limit(50),
          );
          console.log("fetching moreDocs query", transactionsQuery);
          try {
            const snapshot = await getDocs(transactionsQuery);
            console.log("Received snapshot:", snapshot);

            if (!snapshot.empty) {
              const newDocs = snapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  changes: getChanges(doc.data().before, doc.data().after),
                };
              });
              setHistoryDocs((prevDocs) => [...prevDocs, ...newDocs]);
              setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
            } else {
              console.log("No more documents to load.");
            }
          } catch (e) {
            console.log(e);
          }
          loadingRef.current = false;
        }
      };

      const observer = new IntersectionObserver(handleIntersection);

      if (bottomBoundaryRef.current) {
        observer.observe(bottomBoundaryRef.current);
      }

      return () => {
        if (bottomBoundaryRef.current) {
          observer.unobserve(bottomBoundaryRef.current);
        }
      };
    }
  }, [authHook.db, lastVisibleDoc, org]);

  const findContactNames = ({ contacts, lines }) => {
    let uniqueNames = new Set(); // Use a Set to store unique names

    // Loop through each line object in changeObj.after.lines
    for (let line of lines) {
      const contactId = line.contact; // Assuming line object has a contact field
      const name = findContactNameById({ contacts, contactId });

      // Add the name to the Set. Sets only store unique values.
      uniqueNames.add(name);
    }

    // Convert the Set to an array
    const uniqueNamesArray = Array.from(uniqueNames);

    // Handle different number of unique names for proper English
    switch (uniqueNamesArray.length) {
      case 0:
        return "";
      case 1:
        return uniqueNamesArray[0];
      case 2:
        return `${uniqueNamesArray[0]} and ${uniqueNamesArray[1]}`;
      default:
        const last = uniqueNamesArray.pop(); // Remove the last name
        return `${uniqueNamesArray.join(", ")}, and ${last}`;
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={openExamineChange}
        onClose={() => {
          setOpenExamineChange(false);
          setChangeToExamine({});
        }}>
        <ModalBox>
          <ModalInner>
            <LightTitleText>
              {changeToExamine.docType === "transaction"
                ? changeToExamine.action === "Edited"
                  ? "Transaction Changes"
                  : changeToExamine.action === "Created"
                  ? "New Transaction"
                  : changeToExamine.action === "Deleted"
                  ? "Deleted Transaction"
                  : "Error"
                : changeToExamine.docType === "reconciliation"
                ? null
                : "Error"}
            </LightTitleText>
            <ChangesCompareDiv>
              {changeToExamine.action === "Edited" ||
              changeToExamine.action === "Deleted" ||
              changeToExamine.action === "deleted" ? (
                changeToExamine.docType === "transaction" ? (
                  <ChangedTransactionItem
                    changeToExamine={changeToExamine}
                    timeState={"before"}
                  />
                ) : (
                  <ChangedReconciliationItem
                    changeToExamine={changeToExamine}
                  />
                )
              ) : null}
              <TransactionDetailsDiv>
                {changeToExamine.action === "Edited" ? (
                  <LightTitleText>After</LightTitleText>
                ) : null}
                {changeToExamine.action === "Edited" ||
                changeToExamine.action === "Created" ||
                changeToExamine.action === "created" ? (
                  changeToExamine.docType === "transaction" ? (
                    <ChangedTransactionItem
                      changeToExamine={changeToExamine}
                      timeState={"after"}
                    />
                  ) : (
                    <ChangedReconciliationItem
                      changeToExamine={changeToExamine}
                    />
                  )
                ) : // <LightBoldText>No Longer Exists</LightBoldText>
                null}
              </TransactionDetailsDiv>
            </ChangesCompareDiv>
          </ModalInner>
        </ModalBox>
      </Modal>

      <ComponentWrapper>
        {historyDocs.map((changeObj, indexMaster) =>
          changeObj.action === "Edited" ? (
            <React.Fragment key={`${indexMaster}-fragment`}>
              {changeObj.changes
                .filter((change) => change.key !== "userMadeChange")
                .map((change, index) => (
                  <Row key={`${index}-row`}>
                    {index === 0 && <NumberText>{indexMaster + 1}</NumberText>}
                    <React.Fragment>
                      {index === 0 ? (
                        <ClickableLineText>
                          {changeObj.username}
                        </ClickableLineText>
                      ) : (
                        <div style={{ width: "100px" }} />
                      )}
                    </React.Fragment>
                    <LineText>
                      {index === 0 ? ` changed a ` : `and a `}
                    </LineText>
                    <ClickableLineText
                      onClick={() => {
                        setChangeToExamine(changeObj);
                        setOpenExamineChange(true);
                      }}>
                      {changeObj.docType}
                    </ClickableLineText>
                    <LineText>{` ${
                      change.key === "seconds" ? "transaction time" : change.key
                    } from`}</LineText>
                    {change.key === "account" ? (
                      <EmphasizedLineText>
                        {findAccountById({
                          accountId: change.before,
                          accounts: accounts,
                        })
                          ? findAccountById({
                              accountId: change.before,
                              accounts: accounts,
                            }).accountName
                          : "No Longer Exists"}
                      </EmphasizedLineText>
                    ) : change.key === "seconds" ? (
                      <EmphasizedLineText>
                        {convertSecondsToDate(change.before)}
                      </EmphasizedLineText>
                    ) : change.key === "fund" ? (
                      <EmphasizedLineText>
                        {findFundById({ fundId: change.before, funds: funds })
                          ? findFundById({
                              fundId: change.before,
                              funds: funds,
                            }).fundName
                          : "No Longer Exists"}
                      </EmphasizedLineText>
                    ) : (
                      <EmphasizedLineText>
                        {change.before === "" ? "blank" : change.before}
                      </EmphasizedLineText>
                    )}
                    <LineText>to</LineText>
                    {change.key === "account" ? (
                      <EmphasizedLineText>
                        {findAccountById({
                          accountId: change.after,
                          accounts: accounts,
                        })
                          ? findAccountById({
                              accountId: change.after,
                              accounts: accounts,
                            }).accountName
                          : "No Longer Exists"}
                      </EmphasizedLineText>
                    ) : change.key === "seconds" ? (
                      <EmphasizedLineText>
                        {convertSecondsToDate(change.after)}
                      </EmphasizedLineText>
                    ) : change.key === "fund" ? (
                      <EmphasizedLineText>
                        {findFundById({ fundId: change.after, funds: funds })
                          ? findFundById({
                              fundId: change.after,
                              funds: funds,
                            }).fundName
                          : "No Longer Exists"}
                      </EmphasizedLineText>
                    ) : (
                      <EmphasizedLineText>
                        {change.after === "" ? "blank" : change.after}
                      </EmphasizedLineText>
                    )}
                    <LineText
                      onMouseEnter={() => setHoveredIndex(indexMaster)} // indexMaster is the index in map
                      onMouseLeave={() => setHoveredIndex(null)}>
                      {` on ${formatDateWithTime({
                        date: changeObj.timestamp.toDate(),
                        withTime: hoveredIndex === indexMaster,
                      })}`}
                    </LineText>
                  </Row>
                ))}
            </React.Fragment>
          ) : (
            <React.Fragment key={`${indexMaster}-fragment`}>
              {changeObj.docType === "transaction" ? (
                <Row key={`${indexMaster}-row`}>
                  <NumberText>{indexMaster + 1}</NumberText>
                  <ClickableLineText>{changeObj.username}</ClickableLineText>
                  <LineText>{` ${
                    changeObj.action === "Created" ? "created a" : "deleted"
                  } `}</LineText>
                  <ClickableLineText
                    onClick={() => {
                      setChangeToExamine(changeObj);
                      setOpenExamineChange(true);
                    }}>
                    {changeObj.docType}
                  </ClickableLineText>
                  <LineText>{` with contact(s) ${
                    changeObj.action === "Created" &&
                    changeObj.after?.lines[0]?.contact &&
                    contacts?.length > 0
                      ? findContactNames({
                          contacts,
                          lines: changeObj.after.lines,
                        })
                      : changeObj.action === "Deleted" &&
                        changeObj.before?.lines[0]?.contact &&
                        contacts?.length > 0
                      ? findContactNames({
                          contacts,
                          lines: changeObj.before.lines,
                        })
                      : "Journal Entry"
                  }`}</LineText>
                  <LineText
                    onMouseEnter={() => setHoveredIndex(indexMaster)} // indexMaster is the index in map
                    onMouseLeave={() => setHoveredIndex(null)}>
                    {` on ${formatDateWithTime({
                      date: changeObj.timestamp.toDate(),
                      withTime: hoveredIndex === indexMaster,
                    })}`}
                  </LineText>
                </Row>
              ) : (
                <Row key={`${indexMaster}-row`}>
                  <NumberText>{indexMaster + 1}</NumberText>
                  <ClickableLineText>{changeObj.username}</ClickableLineText>
                  <LineText>{` ${
                    changeObj.action.toLowerCase() === "created"
                      ? "created a"
                      : "deleted"
                  } `}</LineText>
                  <ClickableLineText
                    onClick={() => {
                      setChangeToExamine(changeObj);
                      setOpenExamineChange(true);
                    }}>
                    {changeObj.docType}
                  </ClickableLineText>
                  <LineText>{` in `}</LineText>
                  <ClickableLineText>
                    {
                      findAccountById({
                        accountId:
                          changeObj.action.toLowerCase() === "created"
                            ? changeObj.after.account
                            : changeObj.before.account,
                        accounts: accounts,
                      })?.accountName
                    }
                  </ClickableLineText>
                  <LineText
                    onMouseEnter={() => setHoveredIndex(indexMaster)} // indexMaster is the index in map
                    onMouseLeave={() => setHoveredIndex(null)}>
                    {` on ${formatDateWithTime({
                      date: changeObj.timestamp.toDate(),
                      withTime: hoveredIndex === indexMaster,
                    })}`}
                  </LineText>
                </Row>
              )}
            </React.Fragment>
          ),
        )}

        <LineText
          style={{ height: "30px" }}
          ref={bottomBoundaryRef}
          id="page-bottom-boundry">
          {historyDocs.length > 0
            ? `Fetching More...`
            : loaded
            ? `No History Yet...`
            : `Loading History...`}
        </LineText>
      </ComponentWrapper>
    </React.Fragment>
  );
};

export default withExperimental(History);
