import styled from "@emotion/styled";
import { css } from "styled-components";
import { Box, Modal } from "@mui/material";

import { ReactComponent as LoadingLogo } from "../../assets/logos/animated-logo-light.svg";

export const TitleText = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  margin-top: 2.5rem;
  color: white;
  text-align: center;
`;

export const TitleTextBlack = styled.p`
  font-size: 2.2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  margin-top: 2.5rem;
  color: black;
  text-align: center;
`;

export const LineTextBlack = styled.p`
  font-size: 1.8rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: black;
  text-align: center;
`;

export const StyledText = styled.p`
  font-size: 22px;
  font-family: "MontserratMed";
  color: white;
`;

export const StyledHeaderText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  text-align: center;
  color: white;
  margin: 0px;
`;

export const StyledTableHeaderDiv = styled.th`
  width: 200px;
  @media (max-width: 1800px) {
    width: 150px;
  }
  @media (max-width: 1450px) {
    width: 125px;
  }
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledTableItem = styled.td`
  font-size: 20px;
  font-family: "MontserratMed";
  text-align: center;
  color: white;
  width: 200px;
  @media (max-width: 1800px) {
    width: 150px;
  }
  @media (max-width: 1450px) {
    width: 125px;
  }
  padding-left: 5px;
  padding-right: 5px;
  /* border: 1px solid white; */
  ${({ error }) =>
    error &&
    css`
      border: 3px solid #ff0000;
    `}
`;

export const TableText = styled.p`
  font-size: 1rem;
  font-family: "MontserratMed";
  color: white;
  text-align: center;
  ${({ error }) =>
    error &&
    css`
      border: 3px solid #ff0000;
      border-radius: 10px;
      padding: 1px;
      padding-left: 3px;
      padding-right: 3px;
    `}
`;

export const TableTextSmall = styled.p`
  font-size: 0.8rem;
  font-family: "MontserratMed";
  color: white;
  text-align: center;
`;

export const StyledTableRow = styled.tr`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BrowseLabel = styled.label`
  padding: 4px 6px;
  font-size: 1.4rem;
  font-family: "MontserratBold";
  color: black;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, white, #dddddd);
  border-radius: 0.5rem;
  width: 195px;
  height: 30px;
  cursor: pointer;
  text-align: center;

  &:hover {
    transition: 0.3s ease-in-out;
    color: white;
    background-image: linear-gradient(to right, #3b8fd0, #70c8b8);
  }
`;

export const ImportButton = styled.button`
  padding: 4px 6px;
  font-size: 22px;
  font-family: "MontserratMed";
  color: black;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, white, #dddddd);
  border-radius: 6px;
  width: 200px;
  height: 35px;
  cursor: pointer;

  &:hover {
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(to right, #3b8fd0, #70c8b8);
    color: white;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ModalMaster = styled(Modal)``;

export const ModalMasterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const ModalMasterBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* ${({ wideVersion }) => wideVersion && "width: 90vw;"} */
  padding-left: 40px;
  padding-right: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: black 0px 0px 10px 0px;
`;

export const ScrollableContainer = styled.div`
  max-height: 64vh;
  overflow-y: auto;
`;

export const ModalHeader = styled.div`
  /* styles for your header */
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalFooter = styled.div`
  height: 3vh;
  /* styles for your footer */
`;

export const NoteText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: #9c27b0;
  text-align: center;
  margin: 0px;
`;

export const DescriptiorText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratMed";
  color: white;
  text-align: left;
  margin: 20px;
`;

export const StrongText = styled.p`
  font-size: 1.2rem;
  font-family: "MontserratBold";
  color: white;
  text-align: center;
  margin: 0px;
`;

export const CenteringDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ExampleImg = styled.img`
  width: 850px;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
`;

//Title of the page text
export const PageTitle = styled.p`
  font-size: 2rem;
  font-family: "MontserratBold";
  margin: 1rem;
  color: white;
  text-align: center;
`;

export const LineText = styled.p`
  font-size: 1.5rem;
  font-family: ${(props) =>
    props.medium ? "MontserratMed" : "MontserratBold"};
  margin: 1rem;
  color: white;
  text-align: center;
`;

export const DetailText = styled(LineText)`
  font-size: 0.9rem;
  font-family: "MontserratMed";
  margin: 0.5rem;
`;

export const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  transform: translateY(-15vh);
  width: 50vw;
  @media only screen and (max-width: 800px) {
    width: 60vw;
  }
  @media only screen and (max-width: 650px) {
    width: 70vw;
  }
  @media only screen and (max-width: 450px) {
    width: 80vw;
  }
`;
