import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";

import { fadeInUp } from "react-animations";
import { primary_dark_color } from "../constants/color_constants";

const fadeUpAnimation = keyframes`${fadeInUp}`;

const OverlayedButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  z-index: 5;
  bottom: 10px;
  background-color: white;
  padding: 1rem;
  border-radius: 0.7rem;
  cursor: pointer;
  background-image: linear-gradient(to right, #fcfafb, #eeeeee);

  &:hover {
    background-image: linear-gradient(to right, #ee3189, #f36d21);
    transition: all 0.3s ease-in-out;
    color: white;
    font-weight: bold;
  }
`;

export const OverlayedButton = ({ children, onClick }) => {
  return <OverlayedButtonDiv onClick={onClick}>{children}</OverlayedButtonDiv>;
};

const primaryStyle = ({ primary, fontSize }) =>
  primary &&
  css`
    font-size: ${fontSize};
    background-image: linear-gradient(to right, ${primary_dark_color}, #021821);
    &:hover {
      transition: 0.3s ease-in-out;
      //background-image: linear-gradient(to right, #ee3189, #f36d21);
      background-image: linear-gradient(to right, #3b8fd0, #70c8b8);
    }
  `;

const secondaryStyle = ({ secondary, fontSize }) =>
  secondary &&
  css`
    font-size: ${fontSize};
    background-image: linear-gradient(to right, ${primary_dark_color}, black);
    &:hover {
      transition: 0.3s ease-in-out;
      background-image: linear-gradient(to right, #469dd7, #70c8b8);
    }
  `;

const tertiaryStyle = ({ tertiary, fontSize }) =>
  tertiary &&
  css`
    font-size: ${fontSize};

    &:hover {
      transition: 0.3s ease-in-out;
      background-image: linear-gradient(to right, #454545, black);
    }
  `;

const brightStyle = ({ bright, fontSize }) =>
  bright &&
  css`
    font-size: ${fontSize};
    color: black;
    background-image: linear-gradient(to right, white, #dddddd);
    &:hover {
      color: white;
      background-image: linear-gradient(to right, #469dd7, #70c8b8);
      transition: all 0.3s ease-in-out;
    }
  `;

const createStyle = ({ create, fontSize }) =>
  create &&
  css`
    font-size: ${fontSize};
    &:hover {
      background-image: linear-gradient(to right, #3b8fd0, #a3d3f8);
      transition: all 0.3s ease-in-out;
    }
  `;

const maskImage = encodeURIComponent(
  `<svg width="70%" height="140%" xmlns="http://www.w3.org/2000/svg">
  <path d="M0,0 L0,50 Q50,30 100,50 T200,50 L200,0 Z" fill="black"/>
</svg>`,
);

const twoToneStyle = ({ twoTone, fontSize }) =>
  twoTone &&
  css`
    background-color: #3b82f6;
    background-image: linear-gradient(to top right, #2d6dd5, #3f79d7);
    border: 1px solid black;
    color: white;
    font-size: ${(props) => props.fontSize || "1.25rem"};
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 2rem;
    display: inline-block;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to top, #73e7d0, #02aaaf);
      mask: url("data:image/svg+xml;utf8,${maskImage}") no-repeat center / cover;
      -webkit-mask: url("data:image/svg+xml;utf8,${maskImage}") no-repeat center /
        cover;
    }
  `;

const badStyle = ({ bad, fontSize }) =>
  bad &&
  css`
    font-size: ${fontSize};
    &:hover {
      transition: all 0.3s ease-in-out;
      background-image: linear-gradient(to right, #ff0000, #ff0000);
    }
  `;

const disabledStyle = ({ disabled }) =>
  disabled &&
  css`
    opacity: 0.5;
    cursor: not-allowed;
    background-image: linear-gradient(to right, #7a7785, #6b6c79);
  `;

const adaptiveSize = ({ adaptiveSize }) =>
  adaptiveSize &&
  css`
    font-size: 1.5rem;
    @media (max-width: 1024px) {
      font-size: 1.4rem;
    }
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  `;

const InitialStyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: ${({ padding }) => (padding ? padding : "0.4rem 1.5rem")};
  font-size: 1.8rem;
  font-family: "MontserratSemiBold";
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #1e242b, black);
  border-radius: 0.6rem;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  cursor: pointer;
  ${({ handleClick }) =>
    handleClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};

  ${primaryStyle}
  ${secondaryStyle}
  ${tertiaryStyle}
  ${brightStyle}
  ${badStyle}
  ${disabledStyle}
  ${createStyle}
  ${twoToneStyle}

  ${({ small }) =>
    small &&
    css`
      padding: 0.1rem 0.4rem;
    `}
    
${adaptiveSize}

  ${({ slideUp }) =>
    slideUp &&
    css`
      animation: 1s ${fadeUpAnimation};
    `}
`;
//#4f5354, #211f21
const StyledButton = ({
  oneClick,
  primary,
  secondary,
  tertiary,
  bright,
  twoTone,
  create,
  slideUp,
  fontSize,
  width,
  height,
  children,
  disabled,
  type,
  margin,
  onClick,
  ml,
  adaptiveSize,
  bad,
  padding,
}) => {
  const [pressed, setPressed] = useState(false);

  return (
    <InitialStyledButton
      disabled={disabled || (oneClick && pressed)}
      oneClick={oneClick}
      type={type}
      onClick={(e) => {
        // console.log("clicked", e);
        if (oneClick) {
          setPressed(true);
        }
        if (onClick) {
          onClick(e);
        }
      }}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      bright={bright}
      twoTone={twoTone}
      create={create}
      slideUp={slideUp}
      fontSize={fontSize}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      marginLeft={ml}
      adaptiveSize={adaptiveSize}
      bad={bad}>
      {oneClick && pressed ? (
        "Loading..."
      ) : twoTone ? (
        <span style={{ position: "relative" }}>{children}</span>
      ) : (
        children
      )}
    </InitialStyledButton>
  );
};

export default StyledButton;
