import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../assets/user_context";
import * as ROUTES from "../constants/routes";
import { httpsCallable } from "firebase/functions";
import dayjs from "dayjs";

export const openBillingPortal = ({ org, user, authHook, setLoaded }) => {
  // console.log(`Open portal for user:
  // ${user.uid} with orgId: ${org}`);
  setLoaded(false);
  const createPortalSession = httpsCallable(
    authHook.functions,
    "createPortalSession",
  );

  createPortalSession({ UID: user.uid, orgID: org }) // Assuming org is an object and has an id field.
    .then((result) => {
      // Use `result.data` here to get the session information and do what you need
      // console.log(result.data);
      window.location.href = result.data.url; // Redirect to Stripe portal
    })
    .catch((error) => {
      // Handle the error here
      // console.log(error);
      setLoaded(true);
    });
};

export const withAuthentication = (Component) => {
  return (props) => {
    const { user } = useContext(UserContext);
    const redirect = useNavigate();

    useEffect(() => {
      if (!user) {
        // console.log("Should redirect to SIGN_IN");
        redirect(ROUTES.SIGN_IN);
      }
    }, [user, redirect]);

    return <Component {...props} />;
  };
};

const withSubscriptionProtection = (Component) => {
  return (props) => {
    const {
      user,
      orgData,
      setOpenSubscribe,
      showLoading,
      freeTrialNotified,
      setFreeTrialNotified,
      setOpenTrialNotification,
    } = useContext(UserContext);
    const redirect = useNavigate();

    useEffect(() => {
      // If user is not loaded yet, redirect to sign in
      if (!user) {
        redirect(ROUTES.SIGN_IN);
        return; // Stop execution to avoid setting up unnecessary timeout
      }

      // Setup a variable to hold the timeout, so it can be cleared later
      let timeoutId;

      // Check for subscription status only if loading is finished
      if (!showLoading && orgData && orgData.onboarded) {
        if (
          orgData.subscriptionCurrent === undefined ||
          !orgData.subscriptionCurrent
        ) {
          const today = new Date();
          const creationDate = orgData.creationDate?.toDate();
          //using dayjs, check if it has been more than 15 days since the org was created
          const daysSinceCreation = dayjs(today).diff(creationDate, "day");
          if (daysSinceCreation < 15) {
            // Org is less than 15 days old, don't show subscription
            setOpenSubscribe(false);
            if (!freeTrialNotified) {
              setOpenTrialNotification(true);
              setFreeTrialNotified(true);
            }
            return;
          } else {
            // Org subscription not current, redirect to subscribe after a delay
            timeoutId = setTimeout(() => {
              setOpenSubscribe(true);
            }, 2500);
          }
        } else {
          // Subscription verified, current
          setOpenSubscribe(false);
        }
      }

      // Cleanup function to clear the timeout if the effect re-runs before the timeout finishes
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [user, orgData, redirect, setOpenSubscribe, showLoading]);

    return <Component {...props} />;
  };
};

export default withSubscriptionProtection;
