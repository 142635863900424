import React, { useContext } from "react";
import UserContext from "../assets/user_context";
import { StyledColumnDiv, StyledRowDiv, ComponentTile } from "./parent_styles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/use-auth";
import { openBillingPortal } from "../services/with_subscription";

const Account = () => {
  const { user, org, setIsChildDataLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  const authHook = useAuth();

  return (
    <StyledColumnDiv>
      <StyledRowDiv>
        <ComponentTile
          left
          top
          onClick={() => navigate("/account/org-management")}>
          <OrgInfoTile />
        </ComponentTile>
        <ComponentTile top onClick={() => navigate("/account/users")}>
          <UsersTile />
        </ComponentTile>
      </StyledRowDiv>
      <StyledRowDiv>
        <ComponentTile left onClick={() => navigate("/account/settings")}>
          <SettingsTile />
        </ComponentTile>
        <ComponentTile
          onClick={() =>
            openBillingPortal({
              org,
              user,
              authHook,
              setLoaded: setIsChildDataLoaded,
            })
          }>
          <PreferencesTile />
        </ComponentTile>
      </StyledRowDiv>
    </StyledColumnDiv>
  );
};

const OrgInfoTile = () => {
  return <h1>Org Management</h1>;
};

const UsersTile = () => {
  return <h1>Users</h1>;
};

const SettingsTile = () => {
  return <h1>Settings</h1>;
};

const PreferencesTile = () => {
  return <h1>Manage Subscription</h1>;
};

export default Account;
