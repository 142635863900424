import React, { useContext, useEffect, useState } from "react";
import { MenuItem, Modal, Select } from "@mui/material";
import { ModalBox, ModalInner } from "../locations/component_styles";
import StyledButton from "../assets/buttons";
import UserContext from "../assets/user_context";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../services/use-auth";

const SelectOrg = () => {
  const {
    setOrg,
    userData,
    selectOrgOpen,
    setSelectOrgOpen,
    setIsChildDataLoaded,
    org,
    user,
  } = useContext(UserContext);
  const authHook = useAuth();
  const [selectOrg, setSelectOrg] = useState(
    userData?.orgs?.length > 0 ? userData.orgs[0].orgID : null,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.orgs?.length > 0) {
      setSelectOrg(userData.orgs[0].orgID);
    }
  }, [userData.orgs]);

  const setWorkingOrg = async () => {
    if (selectOrg === org) {
      //Selected the same org that is already loaded
      setIsChildDataLoaded(true);
      setSelectOrgOpen(false);
    } else {
      //Changed org to selectOrg

      navigate("/");
      setOrg(selectOrg);
      //Save the org as the last org used
      await updateDoc(doc(authHook.db, "users", user.uid), {
        lastOrgUsed: selectOrg,
      });
      setSelectOrgOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={selectOrgOpen}
        onClose={() => {
          setSelectOrgOpen(false);
          setIsChildDataLoaded(true);
        }}>
        <ModalBox>
          <ModalInner>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}>
              <h2>Select an Org</h2>
              {userData?.orgs?.length > 0 ? (
                <React.Fragment>
                  <Select
                    labelId="org-select-label"
                    id="org-select"
                    value={selectOrg || ""}
                    onChange={(event) => {
                      setSelectOrg(event.target.value);
                    }}>
                    {userData.orgs.map((org) => (
                      <MenuItem key={org.orgID} value={org.orgID}>
                        {org.orgName}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={{ height: "20px" }} />
                  <StyledButton
                    primary
                    fontSize={"1.2rem"}
                    onClick={setWorkingOrg}>
                    Select
                  </StyledButton>
                </React.Fragment>
              ) : (
                <div>No orgs available</div>
              )}
            </div>
          </ModalInner>
        </ModalBox>
      </Modal>
    </React.Fragment>
  );
};

export default SelectOrg;
