import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../assets/user_context";
import { useAuth } from "../../services/use-auth";
import { collection, getDocs } from "firebase/firestore";
import { returnCurrency } from "../../utilities/general_util";
import { Grid } from "@mui/material";

const DonationStatements = () => {
  const authHook = useAuth();
  const { org } = useContext(UserContext);
  const [donors, setDonors] = useState({});
  const [donationYears, setDonationYears] = useState({});
  const [yearToDisplay, setYearToDisplay] = useState(null);
  const [message, setMessage] = useState("");

  const buildStatements = ({ donorData, donationYears }) => {
    const years = {};
    //Iterate through the years and and build a list of donors who have a donation for that year as keys, then build a list of their donations as an array as the value
    Object.keys(donationYears).forEach((year) => {
      donationYears[year].forEach((donation) => {
        if (!years[year]) {
          years[year] = {};
        }
        if (!years[year][donation.donor]) {
          years[year][donation.donor] = [];
        }
        years[year][donation.donor].push({
          ...donation,
          date: donation.date.toDate(),
        });
      });
    });

    //Since the donor id is an array, we need to find any donors that have more than one id and merge those key/value pairs under the first id in the array
    Object.values(donorData).forEach((donor) => {
      //TODO: Need to test this to make sure it works
      if (donor.id.length > 1) {
        donor.id.forEach((id, index) => {
          if (index !== 0) {
            years.forEach((year) => {
              if (years[year][id]) {
                years[year][donor.id[0]] = years[year][donor.id[0]].concat(
                  years[year][id],
                );
                delete years[year][id];
              }
            });
          }
        });
      }
    });

    //Sort the donations within each year and within each donor by date
    Object.keys(years).forEach((year) => {
      Object.keys(years[year]).forEach((donor) => {
        years[year][donor].sort((a, b) => a.date - b.date);
      });
    });

    // //Sort the years by year in decending order
    // const yearsArray = Object.keys(years)
    //   .sort((a, b) => b - a)
    //   .reverse();
    // const sortedYears = {};
    // yearsArray.forEach((year) => {
    //   sortedYears[year] = years[year];
    // });

    return years;
  };

  useEffect(() => {
    const fetchDonationStatements = async () => {
      try {
        const donationManagementSnap = await getDocs(
          collection(authHook.db, "orgs", org, "donorManagement"),
        );
        const donationManagementData = donationManagementSnap.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          }),
        );
        const donationStatements = donationManagementData.filter(
          (doc) => doc.id !== "donors",
        );
        console.log("Donation Statements:", donationStatements); // Debugging log
        const donors = donationManagementData.find(
          (doc) => doc.id === "donors",
        ).donors;
        console.log("Donors:", donors); // Debugging log
        const donorData = {};
        donors.forEach((donor) => {
          //Rememer that the id is an array since it may have come from merged donors
          donorData[donor.id[0]] = donor;
        });
        const donationYears = {};
        donationStatements.forEach((statement) => {
          donationYears[statement.year] = statement.donations;
        });
        return { donationYears, donorData };
      } catch (error) {
        console.error("Error fetching donation statements:", error);
      }
    };
    if (org && authHook?.db) {
      fetchDonationStatements().then((response) => {
        console.log("Donations Data Response:", response); // Debugging log
        const statements = buildStatements({
          donorData: response.donorData,
          donationYears: response.donationYears,
        });
        console.log("Statements:", statements); // Debugging log
        setDonors(response.donorData);
        setDonationYears(statements);
      });
    }
  }, [authHook.db, org]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {Object.keys(donationYears)
          .sort(
            //Sort the years by year in decending order
            (a, b) => b - a,
          )
          //Remove any years more than 12
          .slice(0, 12)
          .map((year) => (
            <button onClick={() => setYearToDisplay(year)}>{year}</button>
          ))}
      </div>
      <div>
        {yearToDisplay && yearToDisplay !== null && (
          <div>
            <h2>{yearToDisplay}</h2>
            <div>
              {Object.keys(donationYears[yearToDisplay]).map((donor) => (
                <div>
                  <h3>{donors[donor].shortName}</h3>
                  <Grid
                    rowSpacing={1}
                    columnSpacing={1}
                    container
                    width={"500px"}>
                    {donationYears[yearToDisplay][donor].map((donation) => (
                      <React.Fragment>
                        <Grid item xs={6}>
                          <p>{donation.date.toDateString()}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p>{returnCurrency(donation.amount)}</p>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonationStatements;
