import React, { useState, useEffect, useContext } from "react";
import { findFundsGroupInHierarchy, findIfSubFundInHierarchy } from "./utils";
import {
  TextField,
  Box,
  FormControl,
  Autocomplete,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import StyledButton from "../../../assets/buttons";
import { TitleText } from "../../component_styles";
import { LineText } from "../table_styles";
import UserContext from "../../../assets/user_context";
import {
  useFundNumberValidation,
  validateFundNumber,
  validateFundName,
  validateGroup,
  validateSubAccount,
} from "./validation";
import { useSubmitFund } from "./add_to_db_logic";
import { ReactComponent as LoadingLogo } from "../../../assets/logos/animated-logo.svg";
import styled from "@emotion/styled";

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 50%;
`;

const AddFund = ({
  fundNumberToAdd,
  possibleFundNameToAdd,
  setShowModal,
  db,
  thisGroup,
  options,
  fundDataToEdit,
  clickedAdd,
  reenableSubmit,
  disabledAddButton,
}) => {
  const { funds, accounts, accountsHierarchy, fundsHierarchy } =
    useContext(UserContext);
  const [fundEntryData, setFundEntryData] = useState({
    fundName: possibleFundNameToAdd
      ? possibleFundNameToAdd
      : fundDataToEdit?.fundName
      ? fundDataToEdit.fundName
      : "",
    fundNumber: fundNumberToAdd
      ? fundNumberToAdd
      : fundDataToEdit?.fundNumber
      ? fundDataToEdit.fundNumber
      : "",
    isRegister: fundDataToEdit?.isRegister ? fundDataToEdit.isRegister : false,
    isPayableReceivable: fundDataToEdit?.isPayableReceivable
      ? fundDataToEdit.isPayableReceivable
      : false,
    group: thisGroup
      ? thisGroup
      : options?.group
      ? options.group
      : fundDataToEdit?.group
      ? fundDataToEdit.group
      : "",
    subFund: fundDataToEdit?.subFund ? fundDataToEdit.subFund : false,
    subFundOf: fundDataToEdit?.subFundOf ? fundDataToEdit.subFundOf : null,
    isRestricted: fundDataToEdit?.isRestricted
      ? fundDataToEdit.isRestricted
      : false,
  });
  const matchOption = fundNumberToAdd && funds.length > 0;
  const [fundEntryErrors, setFundEntryErrors] = useState({
    fundName: false,
    fundNumber: false,
    group: false,
    subFund: false,
  });
  const [fundEntryFeedback, setFundEntryFeedback] = useState({
    fundName: "",
    fundNumber: "",
    group: "",
    subFund: "",
  });

  const [existingFundNumbers, setExistingFundNumbers] = useState(
    funds ? funds.map((fund) => fund.fundNumber) : [],
  );

  const editingFundId = fundDataToEdit?.fundDatabaseId
    ? fundDataToEdit.fundDatabaseId
    : null;

  useFundNumberValidation({
    number: fundEntryData.fundNumber,
    setFundEntryErrors,
    fundEntryErrors,
    setFundEntryFeedback,
    fundEntryFeedback,
    existingFundNumbers,
    funds,
    fundDataToEdit,
    fundEntryData,
  });

  useEffect(() => {
    setExistingFundNumbers(funds ? funds.map((fund) => fund.fundNumber) : []);
  }, [funds]);

  //log out the fundEntryFeedback and fundEntryErrors when they change
  useEffect(() => {
    console.log("Fund Entry Feedback: ", fundEntryFeedback);
    console.log("Fund Entry Errors: ", fundEntryErrors);
    console.log("Fund entry data: ", fundEntryData, possibleFundNameToAdd);
  }, [fundEntryFeedback, fundEntryErrors]);

  const handleChange = (event) => {
    console.log(fundEntryData);
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    if (name === "fundNumber" && value !== "") {
      if (/^[1-9][0-9]*\.?[0-9]*$/.test(inputValue)) {
        setFundEntryData({
          ...fundEntryData,
          [name]: inputValue,
        });
      }
    } else {
      setFundEntryData({
        ...fundEntryData,
        [name]: inputValue,
      });
    }
  };

  //TODO: This is a dumb workaround to the fact that the account number is not set on initial load
  // useEffect(() => {
  //     if (accountDataToEdit) {
  //         setTimeout(() => {
  //             validateAccountNumber({
  //                 accountEntryData,
  //                 setAccountEntryErrors,
  //                 accountEntryErrors,
  //                 accountEntryFeedback,
  //                 setAccountEntryFeedback,
  //                 accountDataToEdit,
  //             });
  //         }, 10);
  //     }
  // }, []);

  //useEffect to validate subAccount if entryData changes and it is already an error
  useEffect(() => {
    if (fundEntryErrors.subFund) {
      validateSubAccount({
        accountEntryData: fundEntryData,
        setAccountEntryErrors: setFundEntryErrors,
        accountEntryErrors: fundEntryErrors,
        setAccountEntryFeedback: setFundEntryFeedback,
        accountEntryFeedback: fundEntryFeedback,
      });
    }
  }, [fundEntryData]);

  const { handleSubmit } = useSubmitFund({
    fundEntryData,
    editingFundId,
    setShowModal,
    setFundEntryData,
    fundDataToEdit,
    db,
  });

  const handleClick = (event) => {
    event.preventDefault();
    if (clickedAdd) clickedAdd();
    console.log(fundEntryData);
    // Validate group (same validation works as account)
    validateFundNumber({
      fundEntryData,
      setFundEntryErrors,
      fundEntryErrors,
      fundEntryFeedback,
      setFundEntryFeedback,
      fundDataToEdit,
    });
    validateFundName({
      fundEntryData,
      setFundEntryErrors,
      fundEntryErrors,
      fundEntryFeedback,
      setFundEntryFeedback,
    });
    validateGroup({
      accountEntryData: fundEntryData,
      setAccountEntryErrors: setFundEntryErrors,
      setAccountEntryFeedback: setFundEntryFeedback,
    });

    // Validate sub-fund
    validateSubAccount({
      accountEntryData: fundEntryData,
      setAccountEntryErrors: setFundEntryErrors,
      accountEntryErrors: fundEntryErrors,
      setAccountEntryFeedback: setFundEntryFeedback,
      accountEntryFeedback: fundEntryFeedback,
    });

    // Final validation before submission
    if (
      (!fundEntryData.subFund || fundEntryData.subFundOf) &&
      fundEntryData.group !== "" &&
      fundEntryData.fundNumber !== "" &&
      fundEntryData.fundName !== ""
    ) {
      handleSubmit();
    } else {
      reenableSubmit();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      {editingFundId ? (
        <TitleText>Edit Fund Details</TitleText>
      ) : !matchOption ? (
        <TitleText>Add New Fund</TitleText>
      ) : null}

      {!disabledAddButton ? (
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
            pb: 3,
            pl: 2,
            pr: 2,
          }}
          noValidate
          autoComplete="off">
          {matchOption && <h2>Create New Fund</h2>}
          <FormControl>
            <TextField
              sx={{
                width: "350px",
                marginRight: "0px",
                fontSize: "22px",
                marginBottom: "20px",
              }}
              label="Fund Name"
              name="fundName"
              variant="outlined"
              color="secondary"
              value={fundEntryData.fundName}
              onChange={handleChange}
              error={fundEntryErrors.fundName}
              helperText={fundEntryFeedback.fundName}
              disabled={possibleFundNameToAdd ? true : false}
              required
              onBlur={() => {
                validateFundName({
                  fundEntryData,
                  setFundEntryErrors,
                  fundEntryErrors,
                  fundEntryFeedback,
                  setFundEntryFeedback,
                });
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              sx={{
                width: "350px",
                fontSize: "22px",
                marginRight: "0px",
                marginLeft: "0px",
              }}
              label="Fund Number"
              name="fundNumber"
              variant="outlined"
              color="secondary"
              value={fundEntryData.fundNumber}
              onChange={handleChange}
              error={fundEntryErrors.fundNumber}
              helperText={fundEntryFeedback.fundNumber}
              required
              onBlur={() => {
                validateFundNumber({
                  fundEntryData,
                  setFundEntryErrors,
                  fundEntryErrors,
                  fundEntryFeedback,
                  setFundEntryFeedback,
                  fundDataToEdit,
                });
              }}
              disabled={fundNumberToAdd ? true : false}
            />
          </FormControl>

          {funds?.length > 0 && (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Switch
                  checked={fundEntryData.subFund}
                  color="secondary"
                  onChange={(event) => {
                    setFundEntryData({
                      ...fundEntryData,
                      subFund: event.target.checked,
                      subFundOf: null,
                    });
                  }}
                  name="subFund"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <LineText>
                  This {fundEntryData.subFund ? "is" : "isn't"} a Sub Fund
                </LineText>
              </div>

              {fundEntryData.subFund && (
                <Autocomplete
                  key={`subFund-${fundEntryData.fundNumber}-selector`}
                  id="subFund"
                  name="subFund"
                  value={fundEntryData.subFundOf}
                  options={funds.filter(
                    (fund) =>
                      !findIfSubFundInHierarchy(fundsHierarchy, fund.id) &&
                      fund.id !== editingFundId,
                  )}
                  getOptionLabel={(option) => option.fundName}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    console.log("New Value: ", newValue);
                    let foundFundGroup = null;
                    if (newValue !== null && newValue?.id) {
                      foundFundGroup = findFundsGroupInHierarchy(
                        fundsHierarchy,
                        newValue.id,
                      );
                    }

                    // Update the subFundOf state
                    setFundEntryData({
                      ...fundEntryData,
                      subFundOf: newValue,
                      group: foundFundGroup?.groupName || "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "350px",
                        fontSize: "22px",
                      }}
                      label="Sub Fund of..."
                      name="subFund"
                      color="secondary"
                      variant="outlined"
                      error={fundEntryErrors.subFund}
                      helperText={fundEntryFeedback.subFund}
                      disabled={!fundEntryData.subFund}
                      required
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.fundName}
                    </li>
                  )}
                />
              )}
            </React.Fragment>
          )}

          <Autocomplete
            freeSolo
            id="group"
            name="group"
            value={fundEntryData.group}
            onInputChange={(event, newValue) => {
              setFundEntryData({
                ...fundEntryData,
                group: newValue,
              });
            }}
            options={(fundsHierarchy?.groups || []).map(
              (group) => group.groupName,
            )}
            disabled={fundEntryData.subFund || options?.group}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "350px",
                  fontSize: "22px",
                }}
                label="Group"
                name="group"
                color="secondary"
                variant="outlined"
                onBlur={() => {
                  validateGroup({
                    accountEntryData: fundEntryData,
                    setAccountEntryErrors: setFundEntryErrors,
                    setAccountEntryFeedback: setFundEntryFeedback,
                  });
                }}
                error={fundEntryErrors.group}
                helperText={fundEntryFeedback.group}
                disabled={fundEntryData.subFund || options?.group}
                required
              />
            )}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              required
              variant="outlined"
              control={
                <Checkbox
                  color="secondary"
                  checked={fundEntryData.isRestricted}
                  name="isRestricted"
                  onChange={handleChange}
                />
              }
              label={"Donor Restricted Fund"}
            />
          </div>

          <StyledButton
            disabled={
              Object.values(fundEntryErrors).some((err) => err) ||
              disabledAddButton
            }
            primary
            fontSize="1.3rem"
            onClick={handleClick}>
            {fundDataToEdit?.fundDatabaseId ? "Edit Fund" : "Add Fund"}
          </StyledButton>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <LoadingLogoStyled />
          <LineText>Adding Fund...</LineText>
        </div>
      )}
    </div>
  );
};

export default AddFund;
