import React, { useContext, useEffect, useState } from "react";
import {
  CenteringDiv,
  LineText,
  LoadingLogoStyled,
  ModalFooter,
  PageTitle,
} from "../mass_import_styles";
import CSVImporter from "./CSVImporter";
import { processTrans } from "./process-functions";
import UserContext from "../../../assets/user_context";
import TransactionErrorHandling from "./transaction_error_handling";
import StyledButton from "../../../assets/buttons";
import { useAuth } from "../../../services/use-auth";
import { Box } from "@mui/material";
import { handleImport } from "./handle_mass_import";
import ExportCSV from "../exportCSV";
import { ButtonText } from "../../../locations/banking/banking/banking_styles";

const ImportTransactionsWorkflow = ({ location, closeModal }) => {
  const { funds, accounts, accountsHierarchy, user, contacts, org } =
    useContext(UserContext);
  const authHook = useAuth();
  const [initialImportData, setInitialImportData] = useState([]);
  const [allErrorsHandled, setAllErrorsHandled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [numberToImport, setNumberToImport] = useState(0);
  const [importedTrans, setImportedTrans] = useState([]);
  const [importSuccess, setImportSuccess] = useState(false);

  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Pad with leading zero if needed
  const day = date.getDate().toString().padStart(2, "0"); // Pad with leading zero if needed
  const year = date.getFullYear();
  const dateString = `${month}-${day}-${year}`;

  const setData = (data) => {
    setInitialImportData(processTrans(data, { funds: funds }));
  };

  useEffect(() => {
    if (importSuccess) {
      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  }, [importSuccess]);

  return (
    <div>
      <PageTitle>Import Transactions</PageTitle>
      {initialImportData.length === 0 && (
        <CSVImporter
          setData={setData}
          headerOptions={[
            "Date",
            "Contact",
            "Account",
            "Fund",
            "Check Number",
            "Credit",
            "Debit",
            "Memo",
            "Comment",
          ]}
          requiredHeaders={[
            "Date",
            "Contact",
            "Account",
            "Fund",
            "Credit",
            "Debit",
          ]}
          confirmButtonText={"Transaction Format"}
        />
      )}
      {initialImportData.length > 0 && accounts?.length > 0 && !submitting && (
        <>
          <TransactionErrorHandling
            setInitialImportData={setInitialImportData}
            accounts={accounts}
            funds={funds}
            initialImportData={initialImportData}
            accountsHierarchy={accountsHierarchy}
            setAllErrorsHandled={setAllErrorsHandled}
          />
          {allErrorsHandled && (
            <ModalFooter>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <StyledButton
                  bright
                  height={"40px"}
                  fontSize={"1.6rem"}
                  onClick={(event) => {
                    event.preventDefault();
                    handleImport({
                      setSubmitting,
                      setNumberToImport,
                      initialImportData,
                      user,
                      setImportedTrans,
                      setImportSuccess,
                      db: authHook.db,
                      accounts: accounts,
                      contacts: contacts,
                      funds: funds,
                      org: org,
                    });
                  }}>
                  Import
                </StyledButton>
              </div>
            </ModalFooter>
          )}
        </>
      )}
      {submitting && !importSuccess && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignContent: "center",
          }}>
          <LoadingLogoStyled
            style={{ transform: "translate(0, 25px)", height: "25vh" }}
          />
          <Box sx={{ width: "100%" }}>
            <LineText>{`${
              importedTrans?.length > 0 ? importedTrans.length : 0
            }/${numberToImport} transactions imported`}</LineText>
          </Box>
          <LineText>Importing...</LineText>
        </div>
      )}
      {importSuccess && (
        <CenteringDiv>
          <LineText>{`${importedTrans.length} Transactions Imported!`}</LineText>
          {/* <LineText>
            Please click below to get an updated .csv file with any
            changes/alterations you made during the import process.
          </LineText> */}
          {/* <ExportCSV
            transactionsData={importedTrans}
            transactionsProcessed={importedTrans}
            db={authHook.db}
            org={org}
            dateString={dateString}
          /> */}
          <StyledButton bright fontSize="1.3rem" onClick={() => closeModal()}>
            Done
          </StyledButton>
        </CenteringDiv>
      )}
    </div>
  );
};

export default ImportTransactionsWorkflow;
