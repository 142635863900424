import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../assets/user_context.jsx";
import ReconciliationTool from "./reconciliation_tool.jsx";
import { useAuth } from "../../../services/use-auth.js";

import { useLocation } from "react-router-dom";
import CompletedRecs from "./complete_recs_list.jsx";
import { useSetRecs } from "./reconciliation_effects.jsx";

const Reconciliation = () => {
  const authHook = useAuth();
  const db = authHook.db;
  const { accounts, experimental } = useContext(UserContext);
  const location = useLocation();

  const [inARec, setInARec] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [registerAccounts, setRegisterAccounts] = useState([]); //accounts that are registered for reconciliation
  const [mostRecentRec, setMostRecentRec] = useState(null); //most recent reconciliation for the selected account
  const [recsForAcct, setRecsForAcct] = useState([]); //reconciliations for the selected account
  const [isInReports, setIsInReports] = useState(false);
  const [recInProgress, setRecInProgress] = useState({});
  const [processingRec, setProcessingRec] = useState(false);

  useSetRecs({
    selectedAccount,
    experimental,
    db,
    setMostRecentRec,
    setRecsForAcct,
    setRecInProgress,
    recInProgress,
    setProcessingRec,
  });

  //useEffect to get all accounts that are registers for reconciliation
  useEffect(() => {
    if (accounts) {
      console.log("setting register accounts from accounts:", accounts);
      const newRegisterAccounts = [];
      accounts.forEach((account) => {
        //TODO: Fix this
        // console.error(
        //   "Is this finding registers that have been added in experimental???",
        // );
        // console.log("Found register:", account);
        if (account.isRegister) {
          console.log("Found register:", account);
          newRegisterAccounts.push(account);
        }
      });
      newRegisterAccounts.sort((a, b) => {
        return a.accountNumber - b.accountNumber;
      });
      setRegisterAccounts(newRegisterAccounts);
    }
  }, [accounts]);

  useEffect(() => {
    // Check if the current pathname contains 'reports'
    const checkInReports = location.pathname.includes("reports");
    setIsInReports(checkInReports);
  }, [location]);

  useEffect(() => {
    //When we have new register accounts, set the selected account to the first one
    if (registerAccounts.length > 0) {
      setSelectedAccount(registerAccounts[0].id);
    }
  }, [registerAccounts]);

  return (
    <React.Fragment>
      {inARec ? (
        <React.Fragment>
          <ReconciliationTool
            account={registerAccounts.find(
              (account) => account.id === selectedAccount,
            )}
            mostRecentRec={mostRecentRec}
            setInARec={setInARec}
            selectedAccount={selectedAccount}
            registerAccounts={registerAccounts}
            recInProgress={recInProgress}
            setRecInProgress={setRecInProgress}
            processingRec={processingRec}
            setProcessingRec={setProcessingRec}
          />
        </React.Fragment>
      ) : (
        <CompletedRecs
          recsForAcct={recsForAcct}
          setInARec={setInARec}
          selectedAccount={selectedAccount}
          registerAccounts={registerAccounts}
          isInReports={isInReports}
          setSelectedAccount={setSelectedAccount}
          recInProgress={recInProgress}
          db={db}
          processingRec={processingRec}
        />
      )}
    </React.Fragment>
  );
};

export default Reconciliation;
