import React, { useEffect, useState } from "react";

import {
  validateAccountsAndFunds,
  validateAmountsBalance,
  validateDataTypes,
} from "../../../locations/transactions/validation";
import { TransactionRow } from "./transaction_row";
import { filterTransactions } from "./build-transactions-to-display";

import {
  StyledHeaderText,
  StyledTableRow,
  ScrollableContainer,
  StyledTableHeaderDiv,
} from "../mass_import_styles";

import {
  AddMatchAccountModal,
  AddMatchFundModal,
  RepairTransactionModal,
} from "../mass_import_modals";

const TransactionErrorHandling = (props) => {
  const [transactionIndeciesToDisplay, setTransactionIndeciesToDisplay] =
    useState([]);
  const [unbalancedTX, setUnbalancedTX] = useState([]);
  const [transactionParsingErrors, setTransactionParsingErrors] = useState([]);
  const [transactionsMatchErrors, setTransactionsMatchErrors] = useState([]);

  const [addAccountModalOpen, setAddAccountModalOpen] = useState(false);
  const [addFundModalOpen, setAddFundModalOpen] = useState(false);
  const [repairEntryModalOpen, setRepairEntryModalOpen] = useState(false);

  //Temp state set by mass import for adding accounts and funds
  const [accountNumberToAdd, setAccountNumberToAdd] = useState(undefined);
  const [possibleAccountNameToAdd, setPossibleAccountNameToAdd] =
    useState(undefined);
  const [possibleFundNameToAdd, setPossibleFundNameToAdd] = useState(undefined);
  const [fundNumberToAdd, setFundNumberToAdd] = useState(undefined);
  const [currentLineBeingEdited, setCurrentLineBeingEdited] = useState({
    transactionArrPos: null,
    lineArrPos: null,
  });
  const [entryToEdit, setEntryToEdit] = useState({});

  const headersJE = [
    "Date",
    "Memo",
    "Account",
    "Fund",
    "Contact",
    "Comment",
    "Debit",
    "Credit",
    "Valid?",
  ];

  //log possibleFundNameToAdd when it changes
  useEffect(() => {
    console.log("possibleFundNameToAdd changed: ", possibleFundNameToAdd);
  }, [possibleFundNameToAdd]);

  // // log initialImportData when it changes
  // useEffect(() => {
  //   console.log("initialImportData changed: ", initialImportData);
  // }, [initialImportData]);

  //Check if all transactions balance by fund and each transaction balances credits and debits when the data, funds and accounts are updated
  useEffect(() => {
    //Validate initialImportData to make sure all journal entries balance credits and debits by fund, and each journal entry balances credits and debits
    if (props.initialImportData?.length > 0) {
      const { errors, errorsDetails } = validateDataTypes(
        props.initialImportData,
      );
      setTransactionParsingErrors(errors);
      setUnbalancedTX(validateAmountsBalance(props.initialImportData));
      setTransactionsMatchErrors(
        validateAccountsAndFunds(
          props.initialImportData,
          props.accounts,
          props.funds,
        ),
      );
    }
  }, [props.initialImportData, props.accounts, props.funds]);

  //Update the transactionsToDisplay when the unbalancedTX and transactionsMatchErrors are updated
  useEffect(() => {
    console.log("unbalancedTX: ", unbalancedTX);
    if (
      unbalancedTX.length !== 0 &&
      transactionsMatchErrors.length !== 0 &&
      props.initialImportData.length !== 0
    ) {
      const transactionsToDisplay = filterTransactions(
        props.initialImportData,
        unbalancedTX,
        transactionParsingErrors,
        transactionsMatchErrors,
        10,
        {},
      );
      console.log(transactionsToDisplay);
      setTransactionIndeciesToDisplay(transactionsToDisplay);
    }
  }, [
    unbalancedTX,
    transactionsMatchErrors,
    props.initialImportData,
    transactionParsingErrors,
  ]);

  //Check if all errors have been handled whenever the errrors JSON objects are updated
  useEffect(() => {
    if (
      unbalancedTX.length !== 0 &&
      (transactionsMatchErrors.length !== transactionParsingErrors.length) !== 0
    ) {
      const allBalanced = unbalancedTX.every((transaction) => {
        return !transaction;
      });
      const allMatch = transactionsMatchErrors.every((transaction) => {
        const transOK = transaction.every(
          (line) => !line.account && !line.fund,
        );
        return transOK;
      });
      const allParsed = transactionParsingErrors.every((transaction) => {
        return !transaction;
      });
      if (allBalanced && allMatch && allParsed) {
        props.setAllErrorsHandled(true);
      }
    }
  }, [unbalancedTX, transactionsMatchErrors, transactionParsingErrors]);

  // useEffect(() => {
  //   console.log("Loaded component 'MassImport'", initialImportData);
  // }, []);

  //Update the initialImportData with the new account number
  const updateAccountNumber = (accountNumber, changeAllBool) => {
    //Check if any other accounts have the same account number
    const moreThanOneAccount = props.initialImportData.some((transaction) => {
      return transaction.lines.some(
        (line) => line.account === accountNumberToAdd,
      );
    });

    if (moreThanOneAccount && changeAllBool) {
      //Change all accounts with the same account number
      const newInitialImportData = [...props.initialImportData];
      newInitialImportData.forEach((transaction) => {
        transaction.lines.forEach((line) => {
          if (line.account === accountNumberToAdd) {
            line.account = accountNumber.toString();
          }
        });
      });
      props.setInitialImportData(newInitialImportData);
    } else {
      //Just change this account number on this line to the matched number
      const newInitialImportData = [...props.initialImportData];
      newInitialImportData[currentLineBeingEdited.transactionArrPos].lines[
        currentLineBeingEdited.lineArrPos
      ].account = accountNumber.toString();
      props.setInitialImportData(newInitialImportData);
    }
  };

  //Update the initialImportData with the new fund number
  const updateFundNumber = (fundNumber, changeAllBool) => {
    //Check if any other funds have the same fund number
    const moreThanOneFund = props.initialImportData.some((transaction) => {
      return transaction.lines.some((line) => line.fund === fundNumberToAdd);
    });

    if (moreThanOneFund && changeAllBool) {
      //Change all funds with the same fund number
      const newInitialImportData = [...props.initialImportData];
      newInitialImportData.forEach((transaction) => {
        transaction.lines.forEach((line) => {
          if (line.fund === fundNumberToAdd) {
            line.fund = fundNumber;
          }
        });
      });
      props.setInitialImportData(newInitialImportData);
    } else {
      const newInitialImportData = [...props.initialImportData];
      newInitialImportData[currentLineBeingEdited.transactionArrPos].lines[
        currentLineBeingEdited.lineArrPos
      ].fund = fundNumber;
      props.setInitialImportData(newInitialImportData);
    }
  };

  const resetEntryToEdit = () => {
    setEntryToEdit({});
  };

  //Reset entryToEdit to none when the repairEntryModal is closed
  useEffect(() => {
    if (Object.keys(entryToEdit).length !== 0 && !repairEntryModalOpen) {
      resetEntryToEdit();
    }
  }, [repairEntryModalOpen]);

  //Once the Entry has been repaired, update the initialImportData with the new entry
  const registerChanges = (entryData, journalEntryMetaData) => {
    const newEntry = {
      ...journalEntryMetaData,
      lines: [...entryData],
    };
    newEntry.date = newEntry.date.toDate();
    const newInitialImportData = [...props.initialImportData];
    newInitialImportData[entryToEdit.index] = newEntry;
    props.setInitialImportData(newInitialImportData);
    resetEntryToEdit();
    setRepairEntryModalOpen(false);
  };

  return (
    <>
      {/* Modal for Editing a Transaction */}
      <RepairTransactionModal
        entryToEdit={entryToEdit}
        setEntryToEdit={setEntryToEdit}
        registerChanges={registerChanges}
        accounts={props.accounts}
        funds={props.funds}
        setInitialImportData={props.setInitialImportData}
      />
      {/* Modal for Adding an Account */}
      <AddMatchAccountModal
        {...props}
        setShowModal={setAddAccountModalOpen}
        accountNumberToAdd={accountNumberToAdd}
        updateAccountNumber={updateAccountNumber}
        possibleAccountNameToAdd={possibleAccountNameToAdd}
        accountsHierarchy={props.accountsHierarchy}
        setAddAccountModalOpen={setAddAccountModalOpen}
        addAccountModalOpen={addAccountModalOpen}
        accounts={props.accounts}
      />
      {/* Modal for Adding a Fund */}
      <AddMatchFundModal
        {...props}
        funds={props.funds}
        setAddFundModalOpen={setAddFundModalOpen}
        addFundModalOpen={addFundModalOpen}
        fundNumberToAdd={fundNumberToAdd}
        updateFundNumber={updateFundNumber}
        possibleFundNameToAdd={possibleFundNameToAdd}
        accountsHierarchy={props.accountsHierarchy}
      />

      {props.initialImportData?.length > 0 && (
        <React.Fragment>
          <table style={{ borderCollapse: "separate" }}>
            <thead>
              <StyledTableRow key={"header"}>
                {headersJE.map((header, index) => {
                  return (
                    <StyledTableHeaderDiv
                      // style={{ position: "sticky" }}
                      key={`${index}-headerDiv`}>
                      <StyledHeaderText key={`${index}-header`}>
                        {header}
                      </StyledHeaderText>
                    </StyledTableHeaderDiv>
                  );
                })}
              </StyledTableRow>
            </thead>
          </table>
          <ScrollableContainer>
            <table style={{ borderCollapse: "separate" }}>
              <tbody>
                {props.initialImportData.map((transaction, indexMaster) => {
                  if (transactionIndeciesToDisplay.includes(indexMaster)) {
                    return (
                      <TransactionRow
                        transaction={transaction}
                        indexMaster={indexMaster}
                        unbalancedTX={unbalancedTX}
                        transactionsMatchErrors={transactionsMatchErrors}
                        setAccountNumberToAdd={setAccountNumberToAdd}
                        setPossibleAccountNameToAdd={
                          setPossibleAccountNameToAdd
                        }
                        setPossibleFundNameToAdd={setPossibleFundNameToAdd}
                        setAddAccountModalOpen={setAddAccountModalOpen}
                        setFundNumberToAdd={setFundNumberToAdd}
                        setAddFundModalOpen={setAddFundModalOpen}
                        setCurrentLineBeingEdited={setCurrentLineBeingEdited}
                        setRepairEntryModalOpen={setRepairEntryModalOpen}
                        setEntryToEdit={setEntryToEdit}
                        accounts={props.accounts}
                        funds={props.funds}
                        key={`${indexMaster}-transaction`}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </ScrollableContainer>
        </React.Fragment>
      )}
    </>
  );
};

export default TransactionErrorHandling;
