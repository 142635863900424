import React, { useState } from "react";
import { primary_color } from "../../../constants/color_constants";
import {
  ItemDiv,
  ItemLineHeader,
  ItemText,
  ItemTitleText,
  ItemsRow,
} from "../register_transactions/register_transactions_styles";
import {
  convertToDate,
  findContactNameById,
  formatCurrency,
  returnCurrency,
} from "../../../utilities/general_util";
import { CenteringDiv } from "./banking_styles";
import StyledButton from "../../../assets/buttons";
import { useEffect } from "react";
import { Close } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";

const InspectTransaction = ({
  transaction,
  contacts,
  accounts,
  funds,
  filterAccount,
  setTransaction,
  setJournalEntryToEdit,
  setJournalEntryEditModal,
  setBankingTransactionEntryEditModal,
  setTransactionIdToRemove,
  setConfirmRemoveOpen,
  orgRoles,
  userData,
  setJournalEntryData,
}) => {
  const [journalEntryFormat, setJournalEntryFormat] = useState(false);

  const [contact, setContact] = useState("none");

  const [hasClearedLine, setHasClearedLine] = useState(false);

  //Use to determine if URL location is the journal-entry or not, to show the duplicate button
  const location = useLocation();

  const findNameInTx = (transaction, contacts) => {
    for (let line of transaction.lines) {
      const name = findContactNameById({
        contactId: line.contact,
        contacts,
      });
      if (name !== undefined) {
        setContact(name);
        console.log(name);
        return;
      }
    }
    return "none"; // or any default value you prefer
  };

  //Add logic to determine if this is a journal entry or not. Best accomplished by checking if the transaction
  // has more debits if it has a debit on the line that is the filterAccount, or more credits if it has a credit
  // on the filterAccount line
  useEffect(() => {
    console.log(
      "Transaction: ",
      transaction,
      filterAccount,
      transaction.lines.filter((line) => line.account === filterAccount),
      transaction.lines.find((line) => line.account === filterAccount),
    );
    if (
      !filterAccount ||
      transaction.lines.filter((line) => line.account === filterAccount)
        .length > 1 ||
      (transaction.lines.find((line) => line.account === filterAccount).sign ===
        "debit" &&
        transaction.lines.filter(
          (line) => line.account !== filterAccount && line.sign === "debit",
        ).length > 0) ||
      (transaction.lines.find((line) => line.account === filterAccount).sign ===
        "credit" &&
        transaction.lines.filter(
          (line) => line.account !== filterAccount && line.sign === "credit",
        ).length > 0)
    ) {
      setJournalEntryFormat(true);
    }
    console.log("Transaction: ", transaction);
    console.log("Filter Account: ", filterAccount);

    findNameInTx(transaction, contacts);
    // console.log(
    //   transaction.lines.find((line) => line.account === filterAccount).sign ===
    //     "debit",
    // );
    // console.log(
    //   transaction.lines.find((line) => line.account === filterAccount).sign ===
    //     "credit",
    // );
    if (transaction?.lines?.length > 0) {
      //Check if any line is cleared=true
      const clearedLine = transaction.lines.find(
        (line) => line.cleared === true,
      );
      if (clearedLine) {
        setHasClearedLine(true);
      }
    }
  }, [transaction, filterAccount]);

  return (
    <div style={{ position: "relative" }}>
      <Close
        style={{
          position: "absolute",
          color: primary_color,
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={() => setTransaction(null)}
        fontSize="large"
      />
      <CenteringDiv>
        <h1
          style={{
            color: primary_color,
          }}>{`Transaction Details`}</h1>
      </CenteringDiv>
      {journalEntryFormat && filterAccount && (
        <CenteringDiv>
          <ItemText>
            Please note: this is a more complex transaction, and must be
            displayed as a journal entry.
          </ItemText>
        </CenteringDiv>
      )}
      <ItemsRow>
        <ItemDiv>
          <ItemTitleText>Date:</ItemTitleText>
          <ItemText>
            {convertToDate(transaction.date).format("MM/DD/YYYY")}
          </ItemText>
        </ItemDiv>
        {transaction.checkNumber && (
          <ItemDiv>
            <ItemTitleText>Check Number:</ItemTitleText>
            <ItemText>{transaction.checkNumber}</ItemText>
          </ItemDiv>
        )}
        {transaction.memo && (
          <ItemDiv>
            <ItemTitleText>Memo:</ItemTitleText>
            <ItemText>{transaction.memo}</ItemText>
          </ItemDiv>
        )}
        {transaction.journalEntry && (
          <ItemDiv>
            <ItemTitleText>Journal Entry #:</ItemTitleText>
            <ItemText>{transaction.journalEntry}</ItemText>
          </ItemDiv>
        )}
      </ItemsRow>

      {!journalEntryFormat && (
        <React.Fragment>
          <table>
            <thead>
              <tr>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Contact</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Account</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Fund</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Outflow</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Inflow</span>
                </ItemLineHeader>
              </tr>
            </thead>
            <tbody>
              {transaction.lines
                .filter((line) => line.account !== filterAccount)
                .map((line) => (
                  <tr>
                    <td>
                      <ItemText>
                        {line.contact !== null &&
                        contacts.find((contact) => contact.id === line.contact)
                          ?.shortName
                          ? contacts.find(
                              (contact) => contact.id === line.contact,
                            ).shortName
                          : "Journal Entry"}
                      </ItemText>
                    </td>
                    <td>
                      <ItemText>{`${
                        accounts.find((account) => account.id === line.account)
                          .accountNumber
                      } - ${
                        accounts.find((account) => account.id === line.account)
                          .accountName
                      }`}</ItemText>
                    </td>
                    <td>
                      <ItemText>
                        {funds.find((fund) => fund.id === line.fund).fundName}
                      </ItemText>
                    </td>
                    <td>
                      <ItemText>
                        {" "}
                        {line.sign === "debit"
                          ? returnCurrency(line.amount)
                          : null}
                      </ItemText>
                    </td>
                    <td>
                      <ItemText>
                        {" "}
                        {line.sign === "credit"
                          ? returnCurrency(line.amount)
                          : null}
                      </ItemText>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {orgRoles?.length > 0 &&
            (orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ||
              userData.superAdmin) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-around",
                  paddingBottom: "20px",
                }}>
                <StyledButton
                  bright
                  fontSize={"1.3rem"}
                  onClick={() => {
                    console.log("Edit in banking form");
                    setJournalEntryToEdit({
                      ...transaction,
                      lines: transaction.lines.map((line) => ({
                        ...line,
                        amount: line.amount,
                      })),
                    });
                    setBankingTransactionEntryEditModal(true);
                    setTransaction(null);
                  }}>
                  Edit Transaction
                </StyledButton>
                <StyledButton
                  bright
                  fontSize={"1.3rem"}
                  disabled={hasClearedLine}
                  onClick={() => {
                    setTransactionIdToRemove(() => {
                      return {
                        id: transaction.id,
                        label: `with ${contact} on ${transaction.date.format(
                          "MM/DD/YYYY",
                        )}`,
                      };
                    });
                    setConfirmRemoveOpen(true);
                  }}>
                  Delete Transaction
                </StyledButton>
              </div>
            )}
        </React.Fragment>
      )}
      {journalEntryFormat && (
        <React.Fragment>
          <table>
            <thead>
              <tr>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Contact</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Account</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Fund</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Debit</span>
                </ItemLineHeader>
                <ItemLineHeader>
                  <span style={{ textDecoration: "underline" }}>Credit</span>
                </ItemLineHeader>
              </tr>
            </thead>
            <tbody>
              {transaction.lines.map((line) => (
                <tr>
                  <td>
                    <ItemText>
                      {line.contact !== null &&
                      contacts.find((contact) => contact.id === line.contact)
                        ?.shortName
                        ? contacts.find(
                            (contact) => contact.id === line.contact,
                          ).shortName
                        : "Journal Entry"}
                    </ItemText>
                  </td>
                  <td>
                    <ItemText>{`${
                      accounts.find((account) => account.id === line.account)
                        .accountNumber
                    } - ${
                      accounts.find((account) => account.id === line.account)
                        .accountName
                    }`}</ItemText>
                  </td>
                  <td>
                    <ItemText>
                      {funds.find((fund) => fund.id === line.fund).fundName}
                    </ItemText>
                  </td>
                  <td>
                    <ItemText>
                      {" "}
                      {line.sign === "debit"
                        ? `$${formatCurrency(line.amount)}`
                        : null}
                    </ItemText>
                  </td>
                  <td>
                    <ItemText>
                      {" "}
                      {line.sign === "credit"
                        ? `$${formatCurrency(line.amount)}`
                        : null}
                    </ItemText>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {orgRoles?.length > 0 &&
            (orgRoles.some(
              (role) => role === "admin" || role === "bookkeeper",
            ) ||
              userData.superAdmin) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-around",
                  paddingBottom: "20px",
                }}>
                <StyledButton
                  bright
                  fontSize={"1.3rem"}
                  onClick={() => {
                    console.log("Edit in journal entry form");
                    setJournalEntryToEdit({
                      ...transaction,
                      lines: transaction.lines.map((line) => ({
                        ...line,
                        amount: line.amount,
                      })),
                    });
                    setJournalEntryEditModal(true);
                    setTransaction(null);
                  }}>
                  Edit Transaction
                </StyledButton>
                {location.pathname.includes("journal-entry") && (
                  <Tooltip title="Load transaction data into the journal entry">
                    <StyledButton
                      onClick={() => {
                        console.log("Duplicating: ", transaction);
                        setJournalEntryData(transaction);
                        setTransaction(null);
                      }}
                      bright
                      fontSize={"1.3rem"}>
                      Duplicate
                    </StyledButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={`${
                    hasClearedLine
                      ? "Cleared, cannot be deleted"
                      : "Click to Delete the entire transaction"
                  }`}>
                  <StyledButton
                    bright
                    fontSize={"1.3rem"}
                    disabled={hasClearedLine}
                    onClick={() => {
                      setTransactionIdToRemove(() => {
                        return {
                          id: transaction.id,
                          label: `with ${contact} on ${transaction.date.format(
                            "MM/DD/YYYY",
                          )}`,
                        };
                      });
                      setConfirmRemoveOpen(true);
                    }}>
                    Delete Transaction
                  </StyledButton>
                </Tooltip>
              </div>
            )}
        </React.Fragment>
      )}
    </div>
  );
};

export default InspectTransaction;
