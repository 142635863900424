import React, { useContext, useEffect, useState } from "react";
import { ModalBox, ModalInner, PageWrapper } from "../component_styles";
import { useAuth } from "../../services/use-auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import UserContext from "../../assets/user_context";
import styled from "@emotion/styled";
import { httpsCallable } from "firebase/functions";
import {
  findAccountById,
  findContactNameById,
  findFundById,
  firebaseTimestampToString,
} from "../../utilities/general_util";
import {
  FormControl,
  FormLabel,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { calcBalanceSheet } from "../../utilities/transaction_parser";
import axios from "axios";

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const OrgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem;
  margin-bottom: 2rem;
  gap: 1rem;
`;

const Admin = () => {
  const { user, setOrg, setIsChildDataLoaded, loaded } =
    useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;
  const [orgs, setOrgs] = useState([]);
  const [pendingOrgId, setPendingOrgId] = useState(null);
  const [erroredTx, setErroredTx] = useState([]);
  const [attemptingChange, setAttemptingChange] = useState(false);
  const [goodNumber, setGoodNumber] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState({ orgName: "", orgId: "" });
  const [confirmText, setConfirmText] = useState("");

  //Listener for orgs
  useEffect(() => {
    if (user) {
      const orgRef = collection(db, "orgs");
      const unSubOrgs = onSnapshot(orgRef, (snapshot) => {
        setAttemptingChange(false);
        const orgs = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        console.log(orgs);
        const subs = orgs.reduce((acc, org) => {
          if (org.subscriptionCurrent && !org.subscriptionGivenByAdmin) {
            acc++;
          }
          return acc;
        }, 0);
        setGoodNumber(subs);
        setOrgs(orgs);
      });

      return () => {
        unSubOrgs();
      };
    }
  }, [user]);

  const giveSub = async (orgId) => {
    const orgRef = doc(db, "orgs", orgId);
    await updateDoc(orgRef, {
      subscriptionCurrent: true,
      subscriptionGivenByAdmin: true,
    });
  };

  const removeSub = async (orgId) => {
    const orgRef = doc(db, "orgs", orgId);
    await updateDoc(orgRef, {
      subscriptionCurrent: false,
      subscriptionGivenByAdmin: false,
    });
  };

  // const recalcAllOrgs = httpsCallable(
  //   authHook.functions,
  //   "manualRecalcAllOrgsAggregates",
  // );

  const takeControl = async (orgId) => {
    setIsChildDataLoaded(false);
    console.log("Taking control of org: ", orgId, "MUAHAHAHA!");
    setPendingOrgId(orgId);
  };

  const compareEachMonthBalance = async (orgId) => {
    console.log("Comparing each month balance for org: ", orgId);
    console.log(
      "Any month with a difference will be reported below, please note that if aggregates have not been recalculated, all transactions since will be reported as differences",
    );
    const orgRef = doc(db, "orgs", orgId);
    const orgDoc = await getDoc(orgRef);
    console.log("Org:", orgDoc);
    const monthlyAggregatesRef = collection(
      db,
      "orgs",
      orgId,
      "monthlyAggregates",
    );
    const monthlyAggregatesDocs = await getDocs(monthlyAggregatesRef);
    const monthlyAggregates = monthlyAggregatesDocs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    console.log("Aggregates from Server, by fund", monthlyAggregates);
    const transactionsRef = collection(db, "orgs", orgId, "journalEntries");
    const transactionsDocs = await getDocs(transactionsRef);
    const transactions = transactionsDocs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    console.log("Number of current transactions:", transactions.length);
    const accountDocs = await getDocs(
      collection(db, "orgs", orgId, "accounts"),
    );
    const accounts = accountDocs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    console.log("Accounts:", accounts);
    monthlyAggregates.forEach((aggregate) => {
      //Find the date of the aggregate by splitting the id which is (YYYY-MM)
      const date = aggregate.id.split("-");
      const year = parseInt(date[0]);
      const month = parseInt(date[1]) - 1;
      //Create a date object for last day of the month
      const lastDay = new Date(year, month + 1, 0);
      //Filter transactions for everything before and including the last day of the month
      const filteredTransactions = transactions.filter((tx) => {
        return tx.date.toDate() <= lastDay;
      });
      const balSheet = calcBalanceSheet(filteredTransactions, accounts);
      // console.log(balSheet.fundResultsObj, aggregate.balanceSheet);
      //Compare the balance sheet from the aggregate to the balance sheet from the transactions
      const localBalanceSheetCalc = balSheet.fundResultsObj;
      const aggregateBalanceSheet = aggregate.balanceSheet;
      Object.keys(localBalanceSheetCalc).forEach((fund) => {
        if (localBalanceSheetCalc[fund].assets) {
          localBalanceSheetCalc[fund].assets.forEach((account) => {
            const accountId = account.id;
            const totalFromLocal = account.intTotal;
            const totalFromAggregate = aggregateBalanceSheet[fund].assets.find(
              (asset) => asset.id === accountId,
            )?.total;
            if (
              totalFromLocal !== totalFromAggregate &&
              totalFromLocal !== 0 &&
              totalFromAggregate
            ) {
              console.error(
                `DIFFERENCE FOUND!
                Date:${date}, Local: ${totalFromLocal} Aggregate: ${totalFromAggregate} Difference: ${
                  totalFromLocal - totalFromAggregate
                } Fund: ${fund} Account: ${
                  findAccountById({
                    accountId,
                    accounts,
                  }).accountNumber
                }`,
              );
            }
          });
        } else {
          // console.log("No assets for fund: ", fund);
          // console.log(localBalanceSheetCalc[fund]);
          //There are no assets for this fund, since we get assets from the txs, this is expected
        }
      });
    });
  };

  useEffect(() => {
    console.log("isChildDataLoaded: ", loaded);
    console.log("pendingOrgId: ", pendingOrgId);
    if (!loaded && pendingOrgId !== null) {
      setOrg(pendingOrgId);
      console.log(`----------- Org set to: ${pendingOrgId} ---------------`);
      setPendingOrgId(null);
    }
  }, [loaded, pendingOrgId, setIsChildDataLoaded, setOrg]);

  const recalcThisOrg = httpsCallable(
    authHook.functions,
    "createInitialMonthlyAggregate",
  );

  const createInitialAccountFundMatches = async ({ org }) => {
    const functions = authHook.functions;
    const createInitialAccountFundMatches = httpsCallable(
      functions,
      "createInitialAccountFundMatches",
    );

    try {
      const response = await createInitialAccountFundMatches({ orgId: org });
      console.log(response.data);
    } catch (error) {
      console.error("Error when calling:", error);
      alert("Failed to create matching.");
    }
  };

  return (
    <PageWrapper>
      <Modal open={erroredTx.length > 0} onClose={() => setErroredTx([])}>
        <ModalBox style={{ width: "90vw" }}>
          <ModalInner>
            <Close
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                cursor: "pointer",
              }}
              onClick={() => setErroredTx([])}
            />
            <div style={{ overflowY: "auto", maxHeight: "80vh" }}>
              {erroredTx.map((tx) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "4px",
                    border: "1px solid black",
                    borderRadius: "4px",
                    padding: "4px",
                  }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>
                      <strong>Date:</strong>
                      {tx.date ? tx.date : "ERROR"}
                    </p>
                    <p>
                      <strong>Memo:</strong>
                      {tx.memo}
                    </p>
                    <p>
                      <strong>Action:</strong>
                      {tx.action}
                    </p>
                    <p>{tx.error}</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {tx.movementLines.map((line) => (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p
                          style={{
                            padding: "3px",
                          }}>{`${line.amount} cents`}</p>
                        <p style={{ padding: "3px" }}>{line.sign}</p>
                        <p style={{ padding: "3px" }}>
                          <strong>Acct:</strong>
                          {line.account ? line.account : "ERROR"}
                        </p>
                        <p style={{ padding: "3px" }}>
                          <strong>Fund:</strong>
                          {line.fund ? line.fund : "ERROR"}
                        </p>
                        <p style={{ padding: "3px" }}>
                          <strong>Contact:</strong>
                          {line.contact ? line.contact : "ERROR"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </ModalInner>
        </ModalBox>
      </Modal>
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <ModalBox>
          <ModalInner>
            <h2>Are you sure you want to un-Onboard {orgToDelete.orgName}?</h2>
            <h4>Type: "DeleteAllTransactions"</h4>
            <TextField
              label="Confirm"
              variant="outlined"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                style={{
                  fontSize: "1.2rem",
                  margin: "1rem",
                  padding: "0.3rem",
                }}
                disabled={confirmText !== "DeleteAllTransactions"}
                onClick={async () => {
                  const orgRef = doc(db, "orgs", orgToDelete.orgId);
                  const todayString = new Date().toISOString().split("T")[0];
                  const orgSaveStateRef = doc(
                    db,
                    "orgs",
                    orgToDelete.orgId,
                    "archivedState",
                    todayString,
                  );

                  try {
                    await runTransaction(db, async (transaction) => {
                      // Set the archived state
                      transaction.set(orgSaveStateRef, {
                        saveState: "beforeUnOnboarding",
                      });

                      // Update the organization document
                      transaction.update(orgRef, {
                        onboarded: false,
                        orgClearedBy: user.uid,
                        chosenPath: null,
                      });
                    });
                    setOpenDeleteModal(false);
                  } catch (e) {
                    console.error("Transaction failed: ", e);
                    // Optionally handle the error (e.g., show an error message)
                  }
                }}>
                Yes
              </button>
              <button
                style={{
                  fontSize: "1.2rem",
                  margin: "1rem",
                  padding: "0.3rem",
                }}
                onClick={() => setOpenDeleteModal(false)}>
                No
              </button>
            </div>
          </ModalInner>
        </ModalBox>
      </Modal>
      <h2>Active Subs: {goodNumber}</h2>
      <TextField
        label="Search"
        variant="outlined"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      {orgs?.length > 0 &&
        orgs
          .filter(
            (org) => searchString === "" || org.orgName.includes(searchString),
          )
          .map((org, index) => (
            <OrgWrapper key={`org-${index}`}>
              <RowWrapper>
                <p style={{ fontFamily: "montserratBold" }}>{org.orgName}</p>
                <p>{org.OrgType}</p>
                {org.subscriptionCurrent ? (
                  <p
                    style={
                      org.subscriptionGivenByAdmin
                        ? {
                            backgroundColor: "red",
                            padding: "0.3rem",
                            color: "white",
                            fontWeight: "bold",
                          }
                        : {
                            backgroundColor: "green",
                            padding: "0.3rem",
                            color: "white",
                            fontWeight: "bold",
                          }
                    }>
                    Subscription Current
                  </p>
                ) : (
                  <p
                    style={{
                      borderBottom: "solid red 2px",
                      margin: "0.3rem",
                    }}>
                    No Subscription
                  </p>
                )}
                {!org.subscriptionCurrent && (
                  <button onClick={() => giveSub(org.id)}>
                    Create Subscription
                  </button>
                )}
                {org.subscriptionCurrent && (
                  <button onClick={() => removeSub(org.id)}>
                    Remove Subscription
                  </button>
                )}
                <button
                  onClick={async () => {
                    await updateDoc(doc(db, "orgs", org.id), {
                      lastProcessed: null,
                    });
                    const resp = await recalcThisOrg({ orgId: org.id });
                    console.log(resp);
                  }}>
                  Recalc Aggregates
                </button>
                <button onClick={() => takeControl(org.id)}>
                  Take Control
                </button>
                <button
                  style={{ color: "red", border: "1px solid red" }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setOrgToDelete({ orgName: org.orgName, orgId: org.id });
                  }}>
                  Un-Onboard
                </button>
                <button
                  onClick={async () => {
                    const erroredTxDocs = await getDocs(
                      query(
                        collection(db, "erroredTransactions"),
                        where("orgId", "==", org.id),
                      ),
                    );
                    console.log(erroredTxDocs.docs.length);
                    const accountsDocs = await getDocs(
                      collection(db, "orgs", org.id, "accounts"),
                    );
                    const fundsDocs = await getDocs(
                      collection(db, "orgs", org.id, "funds"),
                    );
                    const contactsDocs = await getDocs(
                      collection(db, "orgs", org.id, "contacts"),
                    );
                    const accounts = accountsDocs.docs.map((doc) => {
                      return { id: doc.id, ...doc.data() };
                    });
                    const funds = fundsDocs.docs.map((doc) => {
                      return { id: doc.id, ...doc.data() };
                    });
                    const contacts = contactsDocs.docs.map((doc) => {
                      return { id: doc.id, ...doc.data() };
                    });
                    const erroredTxDecoded = erroredTxDocs.docs.map((doc) => {
                      const txWithIds = doc.data();
                      const transaction = {
                        id: txWithIds.transactionId,
                        error: txWithIds.errorMessage,
                        action: txWithIds.action,
                        date: firebaseTimestampToString(
                          txWithIds.afterData.date,
                        ),
                        memo: txWithIds.afterData.memo,
                        movementLines: txWithIds.afterData.lines.map((line) => {
                          return {
                            amount: line.amount,
                            sign: line.sign,
                            account: `${
                              findAccountById({
                                accountId: line.account,
                                accounts,
                              }).accountNumber
                            } - ${
                              findAccountById({
                                accountId: line.account,
                                accounts,
                              }).accountName
                            }`,
                            fund: `${
                              findFundById({ fundId: line.fund, funds })
                                .fundNumber
                            } - ${
                              findFundById({ fundId: line.fund, funds })
                                .fundName
                            }`,
                            contact: findContactNameById({
                              contactId: line.contact,
                              contacts,
                            }),
                          };
                        }),
                      };
                      return transaction;
                    });
                    console.log(erroredTxDecoded);
                    setErroredTx(erroredTxDecoded);
                  }}>
                  Log Errored Tx
                </button>
                <button onClick={() => compareEachMonthBalance(org.id)}>
                  Log Balances
                </button>
                <button
                  onClick={async () => {
                    const isLocalhost =
                      window.location.hostname === "localhost";
                    const url = isLocalhost
                      ? "http://127.0.0.1:5001/dive-dev-f9d3f/us-central1/createOrgCopyForQa"
                      : "https://us-central1-dive-dev-f9d3f.cloudfunctions.net/createOrgCopyForQa";

                    try {
                      const response = await axios.post(url, {
                        orgId: org.id,
                      });
                      alert(
                        `Org copied successfully to ${response.data.newOrgId}!`,
                      );
                    } catch (error) {
                      console.error("Error copying org:", error);
                      alert("Failed to copy org.");
                    }
                  }}>
                  Copy Org
                </button>
                {/* button to call http://127.0.0.1:5001/dive-dev-f9d3f/us-central1/createInitialAccountFundMatches */}
                <button
                  onClick={() =>
                    createInitialAccountFundMatches({ org: org.id })
                  }>
                  Create Initial Account Fund Matches
                </button>
              </RowWrapper>
              <RowWrapper style={{ paddingLeft: "1rem" }}>
                <p>{`${org.onboarded ? "Onboarded" : "Not onbaorded"} ${
                  org.onboarded && !org.chosenPath
                    ? "using experimental"
                    : !org.onboarded && !org.chosenPath
                    ? "and no path chosen"
                    : org.chosenPath
                    ? `using ${org.chosenPath.split("-")[0]}`
                    : `ANOTHER OPTION`
                }`}</p>
                <p>{`Users: ${Object.values(org.users)
                  .map((user) =>
                    user.username.concat("(").concat(user.email).concat(")"),
                  )
                  .join(", ")}`}</p>
              </RowWrapper>
              <RowWrapper style={{ paddingLeft: "1rem" }}>
                <FormControl>
                  <FormLabel>Donor Management</FormLabel>
                  <Switch
                    checked={org?.features?.donorManagement}
                    disabled={attemptingChange}
                    onClick={async () => {
                      setAttemptingChange(true);
                      const orgRef = doc(db, "orgs", org.id);
                      await updateDoc(orgRef, {
                        features: {
                          donorManagement: !org?.features?.donorManagement,
                        },
                      });
                    }}
                  />
                </FormControl>
              </RowWrapper>
              <RowWrapper style={{ paddingLeft: "1rem" }}></RowWrapper>
            </OrgWrapper>
          ))}

      {/* <button
        onClick={async () => {
          const resp = await recalcAllOrgs();
          console.log(resp);
        }}>
        Call Aggregates ALL ORGS
      </button> */}
    </PageWrapper>
  );
};

export default Admin;
