import Transactions from "../transactions/transactions.js";
import withSubscriptionProtection from "../../services/with_subscription.jsx";
import { useState, useEffect } from "react";

const TransactionsProtected = () => {
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer
      const headerHeight =
        document.querySelector("#page-title")?.offsetHeight || 0;
      const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
      const height = vh - headerHeight - footerHeight - 140;
      console.log(height);
      const heightString = height.toString().concat("px");
      console.log(heightString);

      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <div>
      <Transactions height={tableWrapperHeight} />
    </div>
  );
};
const TransactionsList = withSubscriptionProtection(TransactionsProtected);

export default TransactionsList;
