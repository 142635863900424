// balanceTransaction function balances the credit and debit amounts when a form input "amount" loses focus
export const balanceTransaction = (line, setEntryData, entryErrors) => {
  // This function sets the new state of the entryData, while preserving the old state
  setEntryData((previousEntryData) => {
    // Copying the existing entry data into a new array for processing
    let newEntryData = [...previousEntryData];

    // Parsing the amount of the current line to a float
    let currentAmount = parseFloat(
      newEntryData[line].credit !== ""
        ? newEntryData[line].credit
        : newEntryData[line].debit,
    );

    // Check if the current amount is not a number and if it is not an empty string.
    // If it is not a number and it's not empty, return the old state to avoid calculations
    if (
      isNaN(currentAmount) &&
      (newEntryData[line].credit !== "" || newEntryData[line].debit !== "")
    ) {
      return previousEntryData;
    }

    // Initializing totals for debit and credit amounts
    let totalDebit = 0.0,
      totalCredit = 0.0;

    // Initialize arrays to hold various types of lines based on their entry status and errors
    let unenteredCredits = [],
      unenteredDebits = [],
      enteredErroredCredits = [],
      enteredErroredDebits = [],
      enteredUnerroredCredits = [],
      enteredUnerroredDebits = [];

    // Iterate over all the entries
    newEntryData.forEach((entry, index) => {
      let amount, sign;

      // If the amount of entry is an empty string, consider it as zero. Otherwise, parse it as a float
      if (entry.credit !== "") {
        amount = parseFloat(entry.credit);
        sign = "credit";
      } else if (entry.debit !== "") {
        amount = parseFloat(entry.debit);
        sign = "debit";
      } else {
        amount = 0.0;
      }

      // Check if the parsed amount is a valid number
      if (!isNaN(amount)) {
        // If the entry is a debit, add it to the total debit and classify it into one of the debit line categories
        if (sign === "debit") {
          totalDebit += amount;

          // Check if the entry is entered or not.
          // If it's entered, check for errors, and classify as "entered and errored" or "entered and not errored".
          // If it's not entered, classify it as "unentered"
          entry.entered
            ? entryErrors.length > 0 && entryErrors[index].error
              ? enteredErroredDebits.push(index)
              : enteredUnerroredDebits.push(index)
            : unenteredDebits.push(index);
        }
        // If the entry is a credit, add it to the total credit and classify it into one of the credit line categories
        else if (sign === "credit") {
          totalCredit += amount;

          // Similar classification for credit entries as above
          entry.entered
            ? entryErrors.length > 0 && entryErrors[index].error
              ? enteredErroredCredits.push(index)
              : enteredUnerroredCredits.push(index)
            : unenteredCredits.push(index);
        }
      }
    });

    // If the total debits and credits are not equal, find the lines to balance
    if (totalDebit !== totalCredit) {
      let linesToUpdate = [];
      let diff;

      // If the current line is a debit, calculate the difference and prioritize the lines to update in a specific order
      if (newEntryData[line].debit !== "") {
        diff = totalDebit - totalCredit;
        console.log("For Debit Lines, diff is: ", diff);

        linesToUpdate = [
          ...enteredErroredDebits.filter((index) => index !== line),
          ...enteredErroredCredits,
          ...unenteredDebits.filter((index) => index !== line),
          ...unenteredCredits,
          ...enteredUnerroredDebits.filter((index) => index !== line),
          ...enteredUnerroredCredits,
        ];
      } else {
        diff = totalCredit - totalDebit;
        console.log("For Credit Lines, diff is: ", diff);

        linesToUpdate = [
          ...enteredErroredCredits.filter((index) => index !== line),
          ...enteredErroredDebits,
          ...unenteredCredits.filter((index) => index !== line),
          ...unenteredDebits,
          ...enteredUnerroredCredits.filter((index) => index !== line),
          ...enteredUnerroredDebits,
        ];
      }

      // If there are lines to update, iterate over them and adjust their amounts to balance the transaction
      if (linesToUpdate.length) {
        for (let i = 0; i < linesToUpdate.length; i++) {
          let lineToUpdate = linesToUpdate[i];
          let currentLineAmount =
            newEntryData[lineToUpdate].debit !== ""
              ? parseFloat(newEntryData[lineToUpdate].debit)
              : parseFloat(newEntryData[lineToUpdate].credit);

          if (isNaN(currentLineAmount)) {
            currentLineAmount = 0.0;
          }

          let newAmount =
            newEntryData[line].debit !== ""
              ? currentLineAmount - diff
              : currentLineAmount + diff;

          // If the new amount becomes negative, adjust the diff to be this amount and set the amount to zero, since that's the lowest this line could go
          if (newAmount < 0) {
            //Check if we are updating the opposite line of the current line
            if (
              (newEntryData[line].debit !== "" &&
                newEntryData[lineToUpdate].credit !== "") ||
              (newEntryData[line].credit !== "" &&
                newEntryData[lineToUpdate].debit !== "")
            ) {
              diff = newAmount;
            } else {
              //Since we are updating the same type as the current line, the change to the diff must be opposite
              diff = -newAmount;
            }

            if (newEntryData[lineToUpdate].debit !== "") {
              newEntryData[lineToUpdate].debit = "0";
            } else {
              newEntryData[lineToUpdate].credit = "0";
            }
          } else {
            // Update the correct debit or credit field for the line
            if (newEntryData[lineToUpdate].debit !== "") {
              newEntryData[lineToUpdate].debit = newAmount.toFixed(2);
            } else {
              newEntryData[lineToUpdate].credit = newAmount.toFixed(2);
            }
            break;
          }
        }
      }
    }

    // Return the updated entries after balancing the credits and debits
    return newEntryData;
  });
};

export const checkFundBalance = (entryData) => {
  let funds = [];
  entryData.forEach((entry) => {
    if (entry.fund !== "" && !funds.includes(entry.fund)) {
      funds.push(entry.fund);
    }
  });

  const fundsStatus = {};
  const tolerance = 0.9; // 1 cent tolerance

  funds.forEach((fund) => {
    let totalDebit = 0;
    let totalCredit = 0;

    entryData.forEach((entry) => {
      console.log("Entry: ", entry);
      if (entry.fund === fund) {
        // Assuming entry.debit and entry.credit are already integers in cents
        totalDebit +=
          entry.debit !== ""
            ? Math.round(parseFloat(entry.debit.replace(/,/g, "")) * 100)
            : 0;
        totalCredit +=
          entry.credit !== ""
            ? Math.round(parseFloat(entry.credit.replace(/,/g, "")) * 100)
            : 0;
      }
    });

    console.log("Total Debit: ", totalDebit);
    console.log("Total Credit: ", totalCredit);

    const difference = Math.abs(totalDebit - totalCredit);
    fundsStatus[fund] = {
      difference: parseFloat(difference / 100), // Convert back to dollars for the difference display
      lower: totalDebit < totalCredit ? "debit" : "credit",
      balanced: difference <= tolerance, // Changed to allow for a small tolerance
    };
  });
  console.log("Funds Status: ", fundsStatus);

  return fundsStatus;
};
