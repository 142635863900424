import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoMobile from "../assets/logos/LogoLight.svg";
import styled from "@emotion/styled";

const StyledMenu = styled.nav`
  position: absolute;
  transform: ${({ open }) => (open ? "translateX(5%)" : "translateX(+100%)")};
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  background: linear-gradient(160deg, #1d242c, #13181d);
  padding: 2rem;

  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 4;

  width: 100vw;

  a {
    font-family: "MontserratMed";
    text-transform: uppercase;
    padding: 1.5rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    font-size: 2.3rem;
    @media (max-width: 430px) {
      font-size: 1.8rem;
    }
    text-align: center;

    &:hover {
      background: -webkit-linear-gradient(left, #469dd7, #70c8b8);
      background: -webkit-linear-gradient(left, #ee3189, #f36d21);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const TextClickable = styled.p`
  font-family: "MontserratMed";
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s linear;
  font-size: 2.3rem;
  @media (max-width: 430px) {
    font-size: 1.8rem;
  }
  text-align: center;
  cursor: pointer;

  &:hover {
    background: -webkit-linear-gradient(left, #469dd7, #70c8b8);
    background: -webkit-linear-gradient(left, #ee3189, #f36d21);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Logo = styled.img`
  z-index: 3;
  margin-top: 5vh;
  @media (max-height: 849px) {
    margin-top: 2vh;
  }
  width: 50vw;
  margin-bottom: 5vh;
`;

const MenuWrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vh;
  overflow: hidden;
  z-index: ${({ menuBack }) => (menuBack ? "-1" : "2")};
`;

const MobileMenu = ({ openSignOut, authUser, userData, open, setOpen }) => {
  const [menuBack, setMenuBack] = useState(true);
  //This useEffect is to hide the menu to make other items "clickable" AFTER the animation hides it
  useEffect(() => {
    if (open) {
      setMenuBack(false);
    }
    setTimeout(() => {
      if (!open) {
        setMenuBack(true);
      }
    }, 300);
  }, [open]);

  return (
    <MenuWrapper menuBack={menuBack}>
      <StyledMenu open={open}>
        <Link onClick={() => setOpen(!open)} to={"/foundation"}>
          FOUNDATION
        </Link>
        <Link onClick={() => setOpen(!open)} to={"/banking"}>
          BANKING
        </Link>
        <Link onClick={() => setOpen(!open)} to={"/transactions"}>
          TRANSACTIONS
        </Link>
        {/* <Link onClick={() => setOpenAssessmentsSubmenu(true)}>Assessments</Link> */}
        <Link onClick={() => setOpen(!open)} to={"/reports"}>
          REPORTS
        </Link>
        <Link onClick={() => setOpen(!open)} to={"/contacts"}>
          CONTACTS
        </Link>
        <Link onClick={() => setOpen(!open)} to={"/history"}>
          HISTORY
        </Link>
        {authUser ? (
          <TextClickable onClick={() => console.log("Signing out")}>
            SIGN OUT
          </TextClickable>
        ) : (
          <Link onClick={() => setOpen(!open)} to={"/signin"}>
            SIGN IN
          </Link>
        )}
        <Link onClick={() => setOpen(!open)} to={"/"}>
          <Logo src={LogoMobile} alt="Logo" />
        </Link>
      </StyledMenu>
    </MenuWrapper>
  );
};

export default MobileMenu;
