import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // Ensure file-saver is installed: npm install file-saver

export const convertToJSON = ({ reportData }) => {
  let output = [];

  const processColumns = ({ columns, row, rowType }) => {
    row.rowType = rowType;
    columns.forEach((fundGroupColumn, index) => {
      if (index < columns.length - 1) {
        fundGroupColumn.funds.forEach((fund) => {
          if (fund.type === "parentFund") {
            row[fund.fundName] = fund.total ? fund.total / 100 : 0;
            fund.subFunds.forEach((subFund) => {
              row[subFund.fundName] = subFund.total ? subFund.total / 100 : 0;
            });
            row[fund.fundName.concat(" Subtotal")] = fund.withSubFundsTotal
              ? fund.withSubFundsTotal / 100
              : 0;
          } else if (fund.type === "fund") {
            row[fund.fundName] = fund.total ? fund.total / 100 : 0;
          } else {
            console.error("Invalid fund type");
          }
        });
        row[fundGroupColumn.fundGroupName] = fundGroupColumn.total
          ? fundGroupColumn.total / 100
          : 0;
      } else {
        row["Total"] = fundGroupColumn.total ? fundGroupColumn.total / 100 : 0;
      }
    });
    output.push(row);
  };

  const processGroups = (groups) => {
    groups.forEach((group) => {
      if (group.accounts) {
        group.accounts.forEach((account) => {
          let accountRow = {
            Account: `${account.accountName} - ${account.accountNumber}`,
          };
          let parentAccountRow = {
            Account: `Subtotal: ${account.accountName} - ${account.accountNumber}`,
          };
          if (
            account.type === "parentAccount" &&
            account.subAccounts?.length > 0
          ) {
            processColumns({
              columns: account.columns,
              row: accountRow,
              rowType: "account",
            });
            account.subAccounts.forEach((subAccount) => {
              const subAccountRow = {
                Account: `${subAccount.accountName} - ${subAccount.accountNumber}`,
              };
              processColumns({
                columns: subAccount.columns,
                row: subAccountRow,
                rowType: "subAccount",
              });
            });
            processColumns({
              columns: account.totalRowColumns,
              row: parentAccountRow,
              rowType: "accountSubtotal",
            });
          } else {
            processColumns({
              columns: account.columns,
              row: accountRow,
              rowType: "account",
            });
          }
        });
        const groupSubtotalRow = {
          Account: `Subtotal: ${group.accountGroupName}`,
        };
        processColumns({
          columns: group.columns,
          row: groupSubtotalRow,
          rowType: "groupSubtotal",
        });
      }
    });
  };

  // Filter out equity or net income sections based on the report type
  const isBalanceSheet = reportData.some(
    (accountType) =>
      accountType.accountTypeName.toLowerCase() === "assets" ||
      accountType.accountTypeName.toLowerCase() === "liabilities",
  );

  reportData
    .filter((accountType) =>
      isBalanceSheet
        ? accountType.accountTypeName.toLowerCase() !== "equity"
        : accountType.accountTypeName.toLowerCase() !== "net income",
    )
    .forEach((accountType) => {
      let typeHeaderRow = {
        Account: accountType.accountTypeName,
      };
      typeHeaderRow.rowType = "header"; // Mark as header
      output.push(typeHeaderRow);

      processGroups(accountType.groups);

      let typeSubtotalRow = {
        Account: `${accountType.accountTypeName} Total`,
      };
      typeSubtotalRow.rowType = "typeSubtotal"; // Mark as type subtotal
      if (accountType.columns.length > 0) {
        processColumns({
          columns: accountType.columns,
          row: typeSubtotalRow,
          rowType: "typeSubtotal",
        });
      }
    });

  // Add the equity or net income section based on the report type
  const equityOrNetIncomeSection = reportData.find((accountType) =>
    isBalanceSheet
      ? accountType.accountTypeName.toLowerCase() === "equity"
      : accountType.accountTypeName.toLowerCase() === "net income",
  );

  if (equityOrNetIncomeSection) {
    let headerRow = {
      Account: isBalanceSheet ? "Equity" : "Net Income",
    };
    headerRow.rowType = "header";
    output.push(headerRow);

    const equityOrNetIncomeColumns = equityOrNetIncomeSection.columns;
    let subtotalRow = {
      Account: isBalanceSheet ? "Equity Accounts" : "Net Income",
    };
    subtotalRow.rowType = "typeSubtotal";
    processColumns({
      columns: equityOrNetIncomeColumns,
      row: subtotalRow,
      rowType: "typeSubtotal",
    });
  }

  return output;
};

export const exportReportXlsx = async ({
  JSONReport,
  reportName,
  dateRange,
  orgName,
}) => {
  const dateNow = new Date().toLocaleDateString("en-US");
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(reportName);

  // Extract headers, keeping 'rowType' for formatting but excluding it from the actual headers
  const allHeaders = Array.from(
    new Set(JSONReport.flatMap((row) => Object.keys(row))),
  );

  // Set worksheet columns without the 'rowType'
  worksheet.columns = allHeaders
    .filter((header) => header !== "rowType") // Exclude 'rowType' from headers
    .map((header) => ({
      header,
      key: header,
      width: 20, // Set a default width for all columns
    }));

  // Add rows to the worksheet, excluding 'rowType' from each row's data
  JSONReport.forEach((row) => {
    const rowValues = allHeaders
      .filter((header) => header !== "rowType") // Exclude 'rowType' from data
      .map((header) => (row[header] !== undefined ? row[header] : "")); // Retain zeros and replace undefined with an empty string
    const addedRow = worksheet.addRow(rowValues);
    addedRow.rowType = row.rowType; // Retain the rowType information for formatting
  });

  // Set the first column (usually "Account") width to fit the longest text it contains
  worksheet.getColumn(1).width = 30; // Set manually to a reasonable width

  // Format the header row: wrap text, center it horizontally and vertically
  worksheet.getRow(1).eachCell((cell) => {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    cell.font = { bold: true };
  });

  // Set the height of the header row to make it three lines high
  worksheet.getRow(1).height = 45; // Roughly three lines high
  //set the border of the header row
  worksheet.getRow(1).border = {
    top: { style: "medium", color: { argb: "000000" } },
  };

  // Define header patterns to apply specific colors
  const subtotalHeaders = allHeaders.filter((header) =>
    header.includes("Subtotal"),
  );
  const groupHeaders = allHeaders.filter((header) => header.includes("Group"));
  const totalHeaders = allHeaders.filter((header) => header.includes("Total"));

  // Apply formatting to rows based on their rowType and column headers
  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      const header = worksheet.getColumn(colNumber).key;

      // Determine if the cell is in a highlighted row and column
      const isHighlightedRow =
        row.rowType === "groupSubtotal" ||
        row.rowType === "accountSubtotal" ||
        row.rowType === "typeSubtotal";

      const isHighlightedColumn =
        subtotalHeaders.includes(header) ||
        groupHeaders.includes(header) ||
        totalHeaders.includes(header);

      // Apply grey background and border if both row and column are highlighted
      if (isHighlightedRow && isHighlightedColumn) {
        cell.numFmt = '"$"#,##0.00_);("$"#,##0.00)'; // Accounting format ensures that 0 is displayed

        cell.font = { bold: true };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D9D9D9" }, // Light grey fill color
        };
        if (row.rowType === "typeSubtotal") {
          cell.border = {
            top: { style: "medium", color: { argb: "000000" } },
          };
        }
      } else {
        // Apply accounting format to all numerical cells, except first row and column
        if (rowNumber > 1 && colNumber > 1 && typeof cell.value === "number") {
          cell.numFmt = '"$"#,##0.00_);("$"#,##0.00)'; // Accounting format ensures that 0 is displayed
        }

        // Indent sub-account cells in the first column based on rowType
        if (colNumber === 1) {
          if (row.rowType === "subAccount") {
            cell.alignment = { indent: 2, horizontal: "left" }; // Apply indentation to sub-account rows
          } else if (
            row.rowType === "accountSubtotal" ||
            row.rowType === "groupSubtotal" ||
            row.rowType === "typeSubtotal"
          ) {
            cell.font = { bold: true }; // Bold formatting for subtotal account names
            cell.alignment = { horizontal: "right" }; // Align subtotal account names to the right
          } else if (row.rowType === "totalRow") {
            cell.font = { bold: true }; // Bold formatting for total row account names
            cell.alignment = { horizontal: "right" }; // Align total row account names to the right
          }
        }

        // Make the final column bold
        if (colNumber === worksheet.columnCount) {
          cell.font = { bold: true }; // Bold formatting for totals column
        }

        // Apply specific colors to columns based on header names
        if (subtotalHeaders.includes(header)) {
          // Light green for "Subtotal" headers
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ececec" }, // Light green fill color
          };
        } else if (groupHeaders.includes(header)) {
          // Light blue for "Group" headers
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ececec" }, // Light blue fill color
          };
        } else if (totalHeaders.includes(header)) {
          // Light yellow for "Total" headers
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "dadada" }, // Light yellow fill color
          };
        }

        // Apply light blue fill to all cells in groupSubtotal rows
        if (row.rowType === "groupSubtotal") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ececec" }, // Light blue fill color
          };
        }

        // Apply light green fill to all cells in accountSubtotal rows
        if (row.rowType === "accountSubtotal") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ececec" }, // Light green fill color
          };
        }

        // Apply light yellow fill to all cells in typeSubtotal rows
        if (row.rowType === "typeSubtotal") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "dadada" }, // Light yellow fill color
          };
          cell.border = {
            top: { style: "medium", color: { argb: "000000" } },
          };
        }
      }
    });
  });

  // Generate Excel file buffer and create a Blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Use FileSaver to trigger a download in the browser
  const fileName = `T3-${reportName} for ${orgName}-(${dateRange})-Pulled(${dateNow}).xlsx`;
  saveAs(blob, fileName);
  console.log(`File saved as ${fileName}`);
};
