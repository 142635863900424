import { FormControl, InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import StyledButton from "../../../assets/buttons";
import { checkFundBalance } from "../../../locations/transactions/enter_transaction/balance_transaction_auto";

const RepairTransaction = ({
  authUser,
  org,
  db,
  stillLoading,
  entryToEdit,
  setEntryToEdit,
  accounts,
  funds,
  setInitialImportData,
}) => {
  const [journalEntryMetaData, setJournalEntryMetaData] = useState({
    date: entryToEdit.date ? entryToEdit.date : dayjs(new Date(0)),
    cleared: entryToEdit.cleared,
    memo: entryToEdit.memo,
    checkNumber: entryToEdit.checkNumber,
    account: entryToEdit.account,
    fund: entryToEdit.fund,
    contact: entryToEdit.contact,
  });

  const [txLinesData, setTxLinesData] = useState(
    entryToEdit.lines.map((line) => {
      return {
        ...line,
        debit: line.sign === "debit" ? parseFloat(line.amount).toFixed(2) : "",
        credit:
          line.sign === "credit" ? parseFloat(line.amount).toFixed(2) : "",
      };
    }),
  );
  const [errors, setErrors] = useState({
    date: entryToEdit.date ? false : true,
  });

  const [fundBalanceStatus, setFundBalanceStatus] = useState({});

  //Function to handle the change of the date input
  const handleDateChange = (event) => {
    const value = event.$d;
    const newJournalEntryMetaData = { ...journalEntryMetaData };
    newJournalEntryMetaData.date = value;
    setJournalEntryMetaData(newJournalEntryMetaData);
  };

  //Function to handle the change of the memo input
  const handleChangeText = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const newJournalEntryMetaData = { ...journalEntryMetaData };
    newJournalEntryMetaData[name] = value;
    setJournalEntryMetaData(newJournalEntryMetaData);
  };

  //Function to handle the change of the amount input
  const handleChangeAmount = (event) => {
    const { value, name } = event.target;
    const [field, index] = name.split("-");
    const idx = parseInt(index, 10); // Ensure index is a number

    const updatedTxLines = txLinesData.map((line, i) => {
      if (i === idx) {
        const updatedLine = {
          ...line,
          [field]: value,
        };
        // Reset the corresponding field (credit or debit)
        if (field === "debit") {
          updatedLine.credit = "";
        } else {
          updatedLine.debit = "";
        }
        return updatedLine;
      }
      return line;
    });

    setTxLinesData(updatedTxLines);
  };

  //useEffect to run validateFundBal when txLinesData changes if errors for any of the lines is true
  useEffect(() => {
    if (
      Object.entries(errors).some(
        ([key, value]) => key !== "date" && value === true,
      )
    ) {
      validateFundBal();
    }
  }, [txLinesData]);

  //useEffect to check the date when it changes, and if !Date(0) remove the error
  useEffect(() => {
    if (!dayjs(journalEntryMetaData.date).isSame(dayjs(new Date(0)), "day")) {
      const newErrors = { ...errors };
      newErrors.date = false;
      setErrors(newErrors);
    }
  }, [journalEntryMetaData.date]);

  const validateFundBal = () => {
    const balResults = checkFundBalance(txLinesData);
    console.log("BAL RESULTS: ", balResults);
    setFundBalanceStatus(balResults);
    if (Object.values(balResults).some((result) => result.difference >= 0.01)) {
      const fundsErrored = [];
      Object.entries(balResults).forEach(([key, value]) => {
        if (value.difference >= 0.01) {
          fundsErrored.push(key);
        }
      });
      console.log("FUNDS ERRORED: ", fundsErrored);
      const newErrors = { ...errors };
      entryToEdit.lines.forEach((line, index) => {
        if (fundsErrored.includes(line.fund)) {
          newErrors[index] = true;
        } else {
          newErrors[index] = false;
        }
      });
      setErrors(newErrors);
    } else {
      const newErrors = { ...errors };
      entryToEdit.lines.forEach((line, index) => {
        newErrors[index] = false;
      });
      setErrors(newErrors);
    }
  };

  const formatCreditsAndDebits = () => {
    const newTxLinesData = txLinesData.map((line) => {
      return {
        ...line,
        debit: line.debit ? parseFloat(line.debit).toFixed(2) : "",
        credit: line.credit ? parseFloat(line.credit).toFixed(2) : "",
      };
    });
    setTxLinesData(newTxLinesData);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl fullWidth sx={{ m: 1, width: "300px" }} variant="filled">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Transaction Date *"
              value={journalEntryMetaData.date}
              onChange={handleDateChange}
              //   components={{
              //     OpenPickerIcon: "your-icon-component", // Replace with your icon component if needed
              //   }}
              slotProps={{
                textField: {
                  error: errors?.date,
                  variant: "outlined",
                  color: "secondary",
                  helperText: `The text in this cell was: ${entryToEdit.dateText}`,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            m: 1,
          }}
          variant="filled">
          <TextField
            id="memo"
            name="memo"
            label="Memo"
            variant="outlined"
            value={journalEntryMetaData.memo}
            onChange={handleChangeText}
            color="secondary"
          />
        </FormControl>
      </div>

      {txLinesData?.length > 0 &&
        txLinesData.map((line, index) => (
          <div
            key={`txRow-${index}`}
            style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              sx={{ m: 1, width: "300px" }}
              name="account"
              id="account"
              label="Account"
              value={`${line.account} - ${line.accountText}`}
              disabled
            />
            <TextField
              sx={{ m: 1, width: "250px" }}
              name="fund"
              id="fund"
              label="Fund"
              value={`${line.fund}`}
              disabled
            />
            <TextField
              sx={{ m: 1, width: "270px" }}
              name="comment"
              id="comment"
              label="Comment"
              value={`${line.comment}`}
            />
            <TextField
              sx={{ m: 1, width: "150px" }}
              name={`debit-${index}`}
              id="debit"
              label="Debit"
              value={line.debit}
              onChange={handleChangeAmount}
              onBlur={() => {
                validateFundBal();
                formatCreditsAndDebits();
              }}
              InputProps={
                line.credit === ""
                  ? null
                  : {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }
              }
              helperText={
                fundBalanceStatus[line.fund]?.difference >= 0.01 &&
                line.debit !== ""
                  ? `Fund balance is off: ${
                      fundBalanceStatus[line.fund].lower
                    } is lower by $${fundBalanceStatus[line.fund].difference}`
                  : null
              }
              error={errors[index] && line.debit !== ""}
            />
            <TextField
              sx={{ m: 1, width: "150px" }}
              name={`credit-${index}`}
              id="credit"
              label="Credit"
              value={line.credit}
              onChange={handleChangeAmount}
              onBlur={() => {
                validateFundBal();
                formatCreditsAndDebits();
              }}
              InputProps={
                line.debit === ""
                  ? null
                  : {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }
              }
              helperText={
                fundBalanceStatus[line.fund]?.difference >= 0.01 &&
                line.credit !== ""
                  ? `Fund balance is off: ${
                      fundBalanceStatus[line.fund].lower
                    } is lower by $${fundBalanceStatus[line.fund].difference}`
                  : null
              }
              error={errors[index] && line.credit !== ""}
            />
          </div>
        ))}
      <StyledButton
        primary
        onClick={() => {
          setInitialImportData((prevState) => {
            console.log(txLinesData);
            const transactions = [...prevState];
            transactions[entryToEdit.arrPosition] = {
              date: dayjs(journalEntryMetaData.date).toDate(),
              dateText: entryToEdit.dateText,
              contact: journalEntryMetaData.contact,
              checkNumber: journalEntryMetaData.checkNumber,
              memo: journalEntryMetaData.memo,
              lines: txLinesData.map((line) => {
                return {
                  amount: line.debit
                    ? parseFloat(line.debit)
                    : line.credit
                    ? parseFloat(line.credit)
                    : line.amount,

                  sign: line.debit
                    ? "debit"
                    : line.credit
                    ? "credit"
                    : line.sign,
                  fund: line.fund,
                  account: line.account,
                  comment: line.comment,
                  accountText: line.accountText,
                  fundText: line.fundText,
                };
              }),
            };
            console.log("TRANSACTIONS: ", transactions);
            return transactions;
          });
          setEntryToEdit({});
        }}>
        Repair Transaction
      </StyledButton>
    </React.Fragment>
  );
};

export default RepairTransaction;
