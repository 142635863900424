import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../assets/user_context";
import { Autocomplete, TextField } from "@mui/material";
import { findFundById } from "../../general_util";

const SelectFund = ({
  transactionFundValue,
  setFund,
  txkey,
  splitIndex,
  error,
}) => {
  const { funds } = useContext(UserContext);
  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");
  const [tempFund, setTempFund] = useState(transactionFundValue || null);

  useEffect(() => {
    console.log("Fund value changed to ", transactionFundValue);
    if (transactionFundValue !== null && transactionFundValue !== undefined) {
      const matchingFundName = findFundById({
        fundId: transactionFundValue,
        funds,
      }).fundName;
      console.log("Matching fund name: ", matchingFundName);
      setTempFund(transactionFundValue);
    } else if (transactionFundValue === null) {
      setTempFund(null);
    }
    setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
  }, [transactionFundValue]);

  return (
    <Autocomplete
      key={`fund-${txkey}-${someUniqueKeyThatForcesReRender}`}
      id={`fund-${txkey}`}
      fullWidth
      autoSelect
      autoHighlight
      options={funds
        .filter((fund) => !fund.disabled)
        .sort((a, b) => {
          // Sort by fundNumber, then fundName
          if (a.fundNumber < b.fundNumber) {
            return -1;
          } else if (a.fundNumber > b.fundNumber) {
            return 1;
          } else {
            return a.fundName.localeCompare(b.fundName);
          }
        })}
      getOptionLabel={(option) => `${option.fundNumber} - ${option.fundName}`}
      value={
        tempFund && tempFund !== null
          ? findFundById({ fundId: tempFund, funds })
          : null
      }
      onChange={(event, newValue) => {
        console.log(newValue);
        const newFund = newValue ? newValue.id : null;
        setTempFund(newFund);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Fund"
          required
          variant="outlined"
          color="secondary"
          error={error}
          size="small"
          onBlur={() => {
            if (
              tempFund !== null &&
              tempFund !== "" &&
              tempFund !== transactionFundValue
            ) {
              console.log(
                `The transaction's account changed from ${transactionFundValue} to ${tempFund}, updating...`,
              );
              setFund({
                transactionId: txkey,
                fundId: tempFund,
                splitIndex,
              });
            } else if (tempFund !== null && tempFund !== "") {
              console.log(`The transaction's account is null or blank`);
            } else {
              console.log(
                `The transaction's account remained the same: ${tempFund}`,
              );
            }
          }}
        />
      )}
    />
  );
};

export default SelectFund;
