import React, { useContext, useEffect, useState } from "react";
import { findAccountById, sortAccountsByType } from "../../general_util";
import UserContext from "../../../assets/user_context";
import { Autocomplete, TextField } from "@mui/material";

const SelectAccount = ({
  transactionAccountValue,
  setAccount,
  txkey,
  thisAccount,
  splitIndex,
  error,
}) => {
  const { accounts } = useContext(UserContext);
  const [someUniqueKeyThatForcesReRender, setSomeUniqueKeyThatForcesReRender] =
    useState("Autocomplete");
  const [tempAccount, setTempAccount] = useState(
    transactionAccountValue || null,
  );

  useEffect(() => {
    console.log("Account value changed to ", transactionAccountValue);
    if (
      transactionAccountValue !== null &&
      transactionAccountValue !== undefined
    ) {
      setTempAccount(transactionAccountValue);
    }
    setSomeUniqueKeyThatForcesReRender((prevState) => prevState.concat("a"));
  }, [transactionAccountValue]);

  return (
    // <React.Fragment>
    //   <p style={{ color: "white" }}>
    //     {findAccountById({ accountId: transactionAccountValue, accounts })
    //       ?.accountName || "No account"}
    //   </p>
    // </React.Fragment>

    <Autocomplete
      autoSelect
      autoHighlight
      fullWidth
      id={`account-${txkey}`}
      key={`account-${txkey}-${someUniqueKeyThatForcesReRender}`}
      name={`account-${txkey}`}
      options={
        accounts?.length > 0
          ? sortAccountsByType(
              accounts,
              ["expenses", "income", "assets", "liabilities"],
              thisAccount,
              "Equity",
            )
          : []
      }
      groupBy={(option) => option.accountType}
      getOptionLabel={(option) =>
        ` ${option.accountNumber} - ${option.accountName}`
      }
      value={
        tempAccount && tempAccount !== null
          ? findAccountById({ accountId: tempAccount, accounts })
          : null
      }
      onChange={(event, newValue) => {
        console.log(newValue); // This should give you the selected object
        const newAccount = newValue ? newValue.id : null; // Set to the account's id or null
        setTempAccount(newAccount);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={`account-${txkey}`}
          label="Account"
          variant="outlined"
          color="secondary"
          required
          size="small"
          error={error}
          onBlur={() => {
            if (
              tempAccount !== null &&
              tempAccount !== "" &&
              tempAccount !== transactionAccountValue
            ) {
              console.log(
                `The transaction's account changed from ${transactionAccountValue} to ${tempAccount}, updating...`,
              );
              setAccount({
                transactionId: txkey,
                splitIndex: splitIndex,
                accountId: tempAccount,
              });
            } else if (tempAccount !== null && tempAccount !== "") {
              console.log(`The transaction's account is null or blank`);
            } else {
              console.log(
                `The transaction's account remained the same: ${tempAccount}`,
              );
            }
          }}
        />
      )}
    />
  );
};

export default SelectAccount;
