import React, { useCallback, useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import { useAuth } from "../../../services/use-auth";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { updatePassword } from "firebase/auth";
import StyledButton from "../../../assets/buttons";
import UserContext from "../../../assets/user_context";

const MajorText = styled.div`
  font-size: 1.5rem;
  font-family: "MontserratMed";
  margin-bottom: 10px;
  cursor: pointer;
`;

const ChangePasswordDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #e9e9e9f0;
  border-radius: 10px;
  padding: 2rem;
`;

const PasswordReset = () => {
  const { user } = useContext(UserContext);

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [reAuthError, setReAuthError] = useState(false);
  const [passwordIssueMessage, setPasswordIssueMessage] = useState(null);

  const handleChange = (event) => {
    console.log(event.target.value);
    switch (event.target.name) {
      case "currentPassword":
        setCurrentPassword(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "passwordConfirm":
        setPasswordConfirm(event.target.value);
        break;
      default:
        break;
    }
  };

  const validatePassword = useCallback(() => {
    if (password === "") {
      setPasswordIssueMessage("You must enter a password");
      return false;
    } else {
      const longerThan8 = password.length > 8;
      if (!longerThan8) {
        setPasswordIssueMessage("Passwords must be longer than 8 characters");
        return false;
      } else {
        setPasswordIssueMessage(null);
        return true;
      }
    }
  }, [password]);

  const validatePasswordConfirm = useCallback(() => {
    if (passwordConfirm === "") {
      setPasswordMatchError(true);
      return false;
    } else {
      const match = password === passwordConfirm;
      if (!match) {
        setPasswordMatchError(true);
        return false;
      } else {
        setPasswordMatchError(false);
        return true;
      }
    }
  }, [password, passwordConfirm]);

  const reauthenticate = async (currentPassword) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword,
    );

    try {
      await reauthenticateWithCredential(user, credential);
      return true; // Re-authentication successful
    } catch (error) {
      console.error("Error in re-authentication:", error);
      return false; // Re-authentication failed
    }
  };

  const changePassword = async (newPassword) => {
    try {
      await updatePassword(user, newPassword);
      console.log("Password updated successfully.");
      // Clear form and alert user
      setCurrentPassword("");
      setPassword("");
      setPasswordConfirm("");
      setPasswordMatchError(false);
      setReAuthError(false);
      setPasswordIssueMessage(null);
      alert("Password updated successfully.");
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const handleChangePassword = async () => {
    const isReauthenticated = await reauthenticate(currentPassword);

    if (
      isReauthenticated &&
      password === passwordConfirm &&
      validatePassword() &&
      validatePasswordConfirm()
    ) {
      await changePassword(password);
      // Additional steps like informing the user of the success
    }
    if (!isReauthenticated) {
      // Inform user that the current password is incorrect
      setReAuthError(true);
    }
    if (password !== passwordConfirm) {
      // Inform user that the passwords don't match
      setPasswordMatchError(true);
    }
  };

  useEffect(() => {
    if (passwordMatchError) {
      validatePasswordConfirm();
    }
    if (passwordIssueMessage !== null) {
      validatePassword();
    }
  }, [
    passwordConfirm,
    password,
    passwordMatchError,
    validatePasswordConfirm,
    validatePassword,
  ]);

  return (
    <ChangePasswordDiv>
      <MajorText>Change Password</MajorText>
      <TextField
        label="Current Password"
        name="currentPassword"
        type="password"
        variant="outlined"
        value={currentPassword}
        onChange={handleChange}
        error={reAuthError}
        helperText={reAuthError ? "Incorrect Password" : null}
      />
      <br />
      <TextField
        label="New Password"
        variant="outlined"
        name="password"
        type="password"
        value={password}
        onChange={handleChange}
        error={passwordIssueMessage !== null}
        helperText={passwordIssueMessage}
      />
      <br />
      <TextField
        label="Confirm New Password"
        variant="outlined"
        name="passwordConfirm"
        type="password"
        value={passwordConfirm}
        onChange={handleChange}
        error={passwordMatchError}
        helperText={passwordMatchError ? "Passwords do not match" : null}
      />
      <br />
      <StyledButton primary fontSize="1.2rem" onClick={handleChangePassword}>
        Change Password
      </StyledButton>
    </ChangePasswordDiv>
  );
};

export default PasswordReset;
